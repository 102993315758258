import React from 'react';
import TextParagraph from '../molecules/TextParagraph';
import {buttonifyFromProps} from '../hocs';
import Row from '../Row';
import Grid from '@material-ui/core/Grid';
import Img from '../nucleons/Img';
import {WithHeading} from '../types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {convertPrice} from '../utils/convertPrice';
import {useHasMounted, useLuniiUser, useShoppingCart, useLuniiPage, useLuniiTranslation} from '../hooks';

const useStyles = makeStyles(theme => ({
    image: {
        alignSelf: 'stretch',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    content: {
        padding: theme.spacing(8, 3),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(4, 2),
        },
    },
}));

export function HeadingCentered({product, buttonLabel, buttonTarget, buttonType, title, overline, description, text, image, image2, ...props}: HeadingCenteredProps) {
    const {t} = useLuniiTranslation();
    const page = useLuniiPage();
    const classes = useStyles();
    const {ownsPackReference} = useLuniiUser();
    const [{packInCart}] = useShoppingCart();
    const hasMounted = useHasMounted();
    const _product: any = product || {};
    const _title = title || _product.name || _product.title;
    const {Buttons} = buttonLabel
        ? buttonifyFromProps({buttonTarget, buttonLabel, ...props}, [`${buttonType ? buttonType : 'primary'}`, `tertiary`])
        : product && !ownsPackReference(product?.reference) && !packInCart(product) ? buttonifyFromProps({
            buttonTarget: product.stock && `https://lunii/add_to_cart${page?.pageModel ? `?libelle=${page?.pageModel}_apres_chapeau` : ''}`,
            buttonLabel: product.stock ? (product.price === 0 ? t('product_price_free') : convertPrice(product.price, product.currency)) : undefined,
            ...props,
        }, [`primary,startIcon=cart`, `tertiary`])
            : buttonifyFromProps({});
    return (
        <Row margins={'none'} padding={'none'}>
            <Grid item container className={classes.image} lg={3} md={3}>
                {image && <Img {...image} objectFit={'contain'} objectPosition={'bottom right'}/>}
            </Grid>
            <Grid item container lg={6} md={6} xs={12} className={classes.content}>
                {hasMounted && <TextParagraph overline={overline} title={_title} text={description} align={'center'} footerText={text} buttonsComponent={Buttons}/>}
            </Grid>
            <Grid item container className={classes.image} lg={3} md={3}>
                {image2 && <Img {...image2} objectFit={'contain'} objectPosition={'bottom left'}/>}
            </Grid>
        </Row>
    );
}

export interface HeadingCenteredProps extends WithHeading {}

export default HeadingCentered;
