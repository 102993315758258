import React, {useState} from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import {cssBorder, cssBox, cssText} from '@ohoareau/css-utils';
import {useLuniiTranslation, Spinner, Illustration, useLuniiNavigation, Clickable, useHasMounted, Img, DynamicIcon,} from '..';
import {Device} from '../types';
import {MyDevicesModal} from './MyDevicesModal';
import {Alert, useUserDevices} from '../..';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        position: 'relative',
        boxSizing: 'border-box',
    },
    title: {
        marginTop: 0,
        ...cssText(theme, 'standard', 'title_2'),
        [theme.breakpoints.up('sm')]: {
            ...cssText(theme, 'standard', 'title_1'),
        },
    },
    body: {
        minHeight: 70,
        height: 'auto',
        width: '100%',
        position: 'relative',
    },
    device: {
        ...cssBox(theme, undefined, 'clear', 'level_02'),
        ...cssBorder(theme, 'level_02'),
        backgroundColor: '#ffffff',
        borderRadius: 16,
        padding: theme.spacing(2),
        width: '100%',
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: theme.spacing(0, 0, 1, 0),
    },
    name: {
        ...cssText(theme, 'standard', 'title_2', undefined, undefined, '#423F37'),
        margin: theme.spacing(0, 1.5, 0, 1.5),
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        width: '90%',
        maxWidth: '90%',
        textOverflow: 'ellipsis',
        [theme.breakpoints.down('sm')]: {
            ...cssText(theme, 'standard', 'secondary_body_thick', undefined, undefined, '#423F37'),
        },
    },
    bottomNote: {
        ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#7C878B'),
        margin: theme.spacing(4, 0, 0, 0),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(2, 0, 0, 0),
            ...cssText(theme, 'standard', 'caption', undefined, undefined, '#7C878B'),
        },
    },
    noDevices: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'stretch',
        alignItems: 'center',
        width: '100%',
        margin: 0,
    },
    noDevicesIllustration: {
        width: 160,
        height: 'auto',
        margin: theme.spacing(0, 0, 3),
    },
    deviceImage: {
        marginLeft: 5,
        marginRight: 16,
        width: '100%',
        maxWidth: 60,
        height: 'auto',
        marginTop: 3,
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            marginRight: 5,
        },
    },
    svgSettings: {
        color: '#2762E9',
        cursor: 'pointer',
    },
    link: {
        marginTop: 8,
        ...cssText(theme, 'standard', 'button_2', undefined, undefined, 'link'),
        textDecoration: 'underline',
        '&:hover': {
            textDecoration: 'underline',
        },
        [theme.breakpoints.down('sm')]: {
            ...cssText(theme, 'standard', 'button_3', undefined, undefined, 'link'),
            textTransform: 'none',
        },
    },
}));

export function MyDevices({className = '', title = undefined, ...props}: MyDevicesProps) {
    const classes = useStyles();
    const {t, exists} = useLuniiTranslation();
    const {devices, loading, refetch, called} = useUserDevices();
    const {goSupport} = useLuniiNavigation();
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [selectedDevice, setSelectedDevice] = useState<Device | undefined>(undefined);
    const hasMounted = useHasMounted();

    const handleCloseModal = (success: boolean) => {
        setModalOpen(false);
        setSelectedDevice(undefined);
        if (success) {
            refetch();
        }
    };

    const handleClickEditDevice = (selected: string) => {
        const foundDevice = devices.find((device: Device) => device.id === selected);
        setSelectedDevice({...foundDevice});
        setModalOpen(true);
    };

    if (!hasMounted || !called) return null;

    const devicesBody =
        devices && devices?.length > 0 ? (
            <>
                {devices.map((device: Device) => (
                    <div className={classes.device} key={`device_${device.id}`}>
                        {device.illustrationUrl && (<div><Img className={classes.deviceImage} url={device.illustrationUrl} alt={device.name}/></div>)}
                        {device.name && <div className={classes.name}>{device.name.length > 25 && `${device.name.slice(0, 25)}...` || device.name}</div> || false}
                        <DynamicIcon
                            onClick={() => handleClickEditDevice(device.id)}
                            className={classes.svgSettings}
                            width={32}
                            height={32}
                            type='gear-32'/>
                    </div>
                ))}
                <div className={classes.bottomNote}>
                    {exists('my_devices_bottom_note') && <div>{t('my_devices_bottom_note')}</div>}
                    <Clickable className={classes.link} onClick={(e: any) => goSupport && goSupport(e, 'articles/10765266418973')}>
                        {t('my_devices_no_fah_link')}
                    </Clickable>
                    <Clickable className={classes.link} onClick={(e: any) => goSupport && goSupport(e, 'articles/13127726827037')}>
                        {t('my_devices_no_flam_link')}
                    </Clickable>
                </div>
            </>
        ) : (
            <div className={classes.noDevices}>
                <Illustration
                    mt={0}
                    className={classes.noDevicesIllustration}
                    name='my_devices_empty'
                />
                <Alert
                    description={t('my_devices_no_devices')}
                    linkLabel={t('my_devices_no_fah_link')}
                    linkUrl={(e: any) => goSupport && goSupport(e, 'articles/10765266418973')}
                    link2Label={t('my_devices_no_flam_link')}
                    link2Url={(e: any) => goSupport && goSupport(e, 'articles/13127726827037')}
                />
            </div>
        );
    return (
        <div
            className={clsx(classes.root, className)}
            {...props}
        >
            <h3 className={classes.title}>{title}</h3>

            <div className={classes.body}>
                {loading && <Spinner />}
                {devicesBody}
            </div>

            <MyDevicesModal
                open={modalOpen}
                onClose={handleCloseModal}
                device={selectedDevice}
            />
        </div>
    );
}

export interface MyDevicesProps {
    title?: string;
    className?: string;
}

export default MyDevices;
