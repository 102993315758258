import React from 'react';
import {Grid, makeStyles, useTheme} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {SubscriptionProduct, Spinner} from '../../../../ui';
import {PickerCard} from './PickerCard';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        marginBottom: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(3),
        },
    },
    cards: {
        display: 'flex',
        flex: 1,
        '&>:nth-child(2n+1)': {
            margin: theme.spacing(0, 2, 0, 0),
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            '&>:nth-child(2n+1)': {
                margin: 0,
            },
            '&>:not(:last-child)': {
                marginBottom: theme.spacing(3),
            },
        },
    },
    card: {
        flex: 1,
    },
}));

export interface SubscriptionPickerProps {
    items: Array<SubscriptionProduct & {title: string; pretitle?: string; subtitle?: string; body?: any, bottomNote?: string}>;
    onItemPicked: Function;
    picked: string | undefined;
    loading?: boolean;
}

export function SubscriptionPicker({
    items,
    onItemPicked,
    picked = undefined,
    loading = false,
}: SubscriptionPickerProps) {
    const classes = useStyles();
    const handleClick = (id: string) => {
        onItemPicked(id);
    };
    return (
        <div className={classes.root}>
            {loading && <Spinner/>}
            <div className={classes.cards}>
                {items.map(({reference, title, pretitle, subtitle, body, bottomNote}) => (
                    <div key={reference} className={classes.card}>
                        <PickerCard
                            id={reference}
                            title={title}
                            pretitle={pretitle}
                            subtitle={subtitle}
                            bottomNote={bottomNote}
                            body={body}
                            handleClick={handleClick}
                            active={picked === reference}
                       />
                   </div>
                ))}
            </div>
        </div>
    );
}
