import useLuniiMutationApi from '../useLuniiMutationApi';
import {useCallback} from 'react';

export function useFahSerialUpdateSubmit(callback: Function | undefined = () => {}) {
    const [updateFahSerial, {loading, error, ...infos}] = useLuniiMutationApi('UPDATE_FAH_SERIAL');
    const onSubmit = useCallback(async (fahId, fahSerialNumber) => {
        try {
            const response = await updateFahSerial({
                variables: {
                    data: {
                        fahId,
                        fahSerialNumber,
                    },
                },
            });
            const data = response?.data?.updateFahSerial || {};
            callback && callback(data);
            return data;
        } catch (e) {
            // console.error(e);
        }
    }, [updateFahSerial, callback]);
    return [
        onSubmit,
        {
            loading: Boolean(loading),
            error: error || false,
            ...infos,
        },
    ] as [(fahId: string, fahSerialNumber: string) => Promise<any>, any];
}

export default useFahSerialUpdateSubmit;
