import React, {SVGProps, memo} from 'react';
import {capitalize} from '@material-ui/core';
import Loadable from '@loadable/component';

const aliases = {
    cart: 'shopping-bag',
    box: 'package',
    wish_empty: 'wish',
};

const getShareIconTypeByUrl = (url: string) => {
    switch (true) {
        case url.indexOf('facebook') !== -1: return 'facebook';
        case url.indexOf('linkedin') !== -1: return 'linkedin';
        case url.indexOf('youtube') !== -1: return 'youtube';
        case url.indexOf('instagram') !== -1: return 'instagram';
        case url.indexOf('twitter') !== -1: return 'twitter';
        case url.indexOf('tous-pour-un') !== -1: return 'touspourun';
        default: return 'facebook';
    }
};

export const DynamicIcon = memo(({type, url = undefined, id, ...props}: DynamicIconProps) => {
    type = type || 'share';
    type = aliases[type] || type;
    ('share' === type) && (type = getShareIconTypeByUrl(url || ''));
    const name = (type || '').split('-').map(capitalize).join('');
    const TheIcon = Loadable(
        () => import(`../images/icons/${name}`)
            .catch(
                () => () => <div className={props.className} style={{width: props.width, height: props.height}} id={id}/>,
            ),
    );
    return <TheIcon {...props} id={id}/>;
});

export interface DynamicIconProps extends SVGProps<SVGSVGElement> {
    type?: string,
    url?: string,
}

export default DynamicIcon;
