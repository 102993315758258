import * as React from 'react';
function SvgLetter(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            viewBox="0 0 48 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width={props.width || 24}
            height={props.height || 24}
            {...props}
        >
            <path
                d="M42.851 16.073S23.983 3.294 22.757 2.506c-.95-.622-1.563-.773-2.544.136-.981.91-14.975 16.765-15.174 17.144-.2.394-1.502 1.046-.46 2.41 1.042 1.365 17.197 8.64 17.197 8.64l8.476-.317c.015-.016 13.412-13.9 12.6-14.446z"
                fill="#B7B7B7"
            />
            <path
                d="M42.898 15.906L4.273 21.62l1.042 3.76 17.044 7.063 10.806-4.865 9.733-11.672z"
                fill="#CECDCD"
            />
            <path
                d="M39.401 19.453s.66-10.46-.52-10.399C37.7 9.115 8.01 12.89 8.01 12.89s-2.16 0-1.38 2.41c.782 2.41 1.227 8.883 1.227 8.883l1.272 4.563 16.554 5.063 10.315-4.29L39.4 19.453z"
                fill="#FCEDD4"
            />
            <path
                d="M41.762 16.603c-1.057.834-14.76 11.687-15.358 12.93-.598 1.228 14.776 10.323 14.776 10.323s2.82.394 2.82-.713c0-1.106-.66-21.752-.98-22.54-.338-.788.076-1.061-1.258 0zM22.328 30.245s-15.817-7.533-16.92-8.2c-1.227-.758-1.58-.591-1.334 1.182.214 1.622 1.9 22.738 2.237 23.768.322 1.046.2 1.425 1.84.319 1.624-1.092 15.005-16.705 14.177-17.069z"
                fill="#B7B7B7"
            />
            <path
                d="M43.924 39.416c-1.088-1.03-15.235-9.186-16.477-9.565-1.241-.394-4.537-1.758-7.556 1.622-3.02 3.38-15.88 16.902-12.982 16.507 2.897-.394 37.95-7.685 37.015-8.564z"
                fill="#909090"
            />
        </svg>
    );
}
export default SvgLetter;
