import React from 'react';
import useTheme from '@material-ui/core/styles/useTheme';
import {useMediaQuery} from '@material-ui/core';
import LoginForm, {LoginFormProps} from '../molecules/LoginForm';
import FormPanel from '../atoms/FormPanel';

export function LoginPanel({
    form = undefined,
    dense = false,
    titleInHeader = false,
    titleSize,
    disableClose = false,
    header = undefined,
    footer = undefined,
    logoPosition = 'bottom',
    onClose = undefined,
    title = undefined,
    description = undefined,
    formProps,
}: LoginPanelProps) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    if (!titleSize) {
        titleSize = isMobile ? 'default' : 'large';
    }

    return (
        <FormPanel
            onClose={onClose}
            disableClose={disableClose}
            form={form || LoginForm}
            formProps={{...{loggedInGuest: false}, ...formProps}}
            title={title}
            titleSize={titleSize}
            description={description}
            logoPosition={logoPosition as any}
            header={header}
            footer={footer}
            dense={dense}
            titleInHeader={titleInHeader}
        />
    );
}

export interface LoginPanelProps {
    form?: any;
    dense?: boolean;
    titleInHeader?: boolean;
    titleSize?: 'large' | 'default';
    disableClose?: boolean;
    header?: any;
    footer?: any;
    logoPosition?: string | boolean;
    onClose?: Function;
    title?: any;
    description?: any;
    formProps?: LoginFormProps;
}

export default LoginPanel;
