import React from 'react';
import {cssText} from '@ohoareau/css-utils';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {DynamicIcon, Clickable, useLuniiEventTracking} from '..';

const useStyles = makeStyles(theme => ({
    title: {
        ...cssText(theme, 'standard', 'body_thick', undefined, undefined, 'title_1'),
        marginBottom: 10,
    },
    items: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    item: {
        display: 'inline-block',
        margin: theme.spacing(1.5, 2.5, 1.5, 0),
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(1, 2, 1, 0),
        },
    },
    svg: {
        height: 48,
        [theme.breakpoints.down('sm')]: {
            height: 32,
        },
    },
}));

export function FooterSocialMenu({menu}: FooterSocialMenuProps) {
    const classes = useStyles();
    const handleEventTracking = useLuniiEventTracking();
    const {label, items = []} = (menu && menu[0]) || {};
    if (!items.length) return null;
    return (
        <div>
            {label && <div className={classes.title}>{label}</div>}
            <div className={classes.items}>
                {items.map((item: any, i) => (
                    <Clickable
                        key={i}
                        onClick={item.target}
                        className={classes.item}
                        onClickCb={() => handleEventTracking({categorie:'navigation', action:'menu', libelle: item.target})}>
                        <DynamicIcon url={item.target} className={classes.svg} width={'auto'}/>
                    </Clickable>
                ))}
            </div>
        </div>
    );
}

export interface FooterSocialMenuProps {
    menu?: any,
}

export default FooterSocialMenu;
