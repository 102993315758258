import MuiLink from '@material-ui/core/Link';
import {navigation_context_value} from '../types';

export const createDefaultLuniiNavigationContextValue = (defaultValue: navigation_context_value|undefined = undefined): navigation_context_value => {
    return defaultValue || {
        InternalLink: MuiLink,
        ExternalLink: MuiLink,
        goHomeRegister: () => {},
        goHomePassword: () => {},
        goHomeLogin: () => {},
        goUserHome: () => {},
        goCart: () => {},
        goCheckout: () => {},
        goHome: () => {},
        goPageByModel: () => {},
        goWishlist: () => {},
        goLibrary: () => {},
        goCatalog: () => {},
        goSubscriptionGift: () => {},
        goSubscription: () => {},
        goSupport: () => {},
        rootNavigate: () => {},
        homeLocation: '/',
        loginLocation: '/login',
        userHomeLocation: '/my/',
        cartLocation: '/checkout/cart',
        checkoutLocation: '/checkout',
        wishlistLocation: '/wishlist',
        userLibrary: '/my/library',
    };
};

export default createDefaultLuniiNavigationContextValue;
