import React from 'react';
import {basic_product} from '../../ui';

export function UnknownProduct({product}: UnknownProductProps) {
    return (
        <>
            <>
                UNKNOWN - {product.id} - {product.title}
            </>
            <pre>{JSON.stringify(product, null, 4)}</pre>
        </>
    );
}

export interface UnknownProductProps {
    product: basic_product;
}

export default UnknownProduct;
