import React from 'react';
import Push from '../molecules/Push';
import {cssText} from '@ohoareau/css-utils';
import {extractButtonsProps} from '../hocs';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Row from '../Row';
import Grid from '@material-ui/core/Grid';
import ForceTheme from '../ForceTheme';
import {
    module_image_position,
    module_mobile_image_position,
    row_padding_values,
    WithPush,
} from '../types';

const useStyles = makeStyles((theme) => ({
    root: {
        ...cssText(theme, 'standard', 'body'),
        paddingTop: 8,
        paddingBottom: 8,
    },
}));

export function PushLarge({
    product,
    title,
    overline,
    label,
    text,
    image,
    imagePosition = 'left',
    contentPadding,
    rowPadding = 'medium',
    ...props
}: PushLargeProps) {
    const classes = useStyles();
    const {buttonsProps} = extractButtonsProps(props);
    return (
        <Row
            padding={rowPadding}
            contentPadding={contentPadding}
            className={classes.root}
        >
            <Grid
                item
                container
                lg={12}
                md={12}
                xs={12}
            >
                <ForceTheme theme={'default'}>
                    <Push
                        product={product}
                        title={title}
                        overline={overline}
                        label={label}
                        text={text}
                        {...buttonsProps}
                        image={image}
                        imagePosition={imagePosition}
                    />
                </ForceTheme>
            </Grid>
        </Row>
    );
}

export interface PushLargeProps extends WithPush {
    imagePosition?: module_image_position;
    mobileImagePosition?: module_mobile_image_position;
    rowPadding?: row_padding_values;
    contentPadding?: 'none' | 'large' | undefined;
}

export default PushLarge;
