import * as React from 'react';
function SvgCross24(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.707 6.293a1 1 0 010 1.414l-10 10a1 1 0 01-1.414-1.414l10-10a1 1 0 011.414 0z"
                fill={props.color || 'currentColor'}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.707 17.707a1 1 0 01-1.414 0l-10-10a1 1 0 011.414-1.414l10 10a1 1 0 010 1.414z"
                fill={props.color || 'currentColor'}
            />
        </svg>
    );
}
export default SvgCross24;
