/* eslint-disable no-param-reassign */
const serverUrl = 'https://downloads.lunii.com/';
const baseUri = 'v1/luniistore-installers/';
const createUrl = (url: string | undefined) => url && `${serverUrl}${baseUri}${url}`;

enum OSName {
    MacOS = 'macintosh',
    Linux = 'linux',
    Windows = 'windows',
}
enum OSType {
    NotFound = -1,
    MacOS = 0,
    Windows32 = 1,
    Windows64 = 2,
    Linux32 = 3,
    Linux64 = 4,
    iOS = 5,
    Android = 6,
    MacOSYosemite = 7,
    Windows32Vista = 8,
    Windows64Vista = 9,
}

const MAC_OS_COMPATIBILITIES: string[] = [
    '10.10', // Yosemite
    '10.11', // El Capitan
    '10.12', // Sierra
    '10.13', // High Sierra
    '10.14', // Mojave
    '10.15', // Catalina
    '11.0', // Big Sur
    '11.1', // Big Sur
];
const WINDOWS_COMPATIBILITIES: string[] = [
    '6', // Windows 6
    '7', // Windows 7
    '8', // Windows 8
    '8.1', // Windows 8.1
    '10', // Windows 10
    '11', // Windows 11
];

const THE_64_BITS_SIGNATURES: string[] = [
    'x86_64',
    'x86-64',
    'Win64',
    'x64;',
    'amd64',
    'AMD64',
    'WOW64',
    'x64_64',
    'ia64',
    'sparc64',
    'ppc64',
    'IRIX64',
    'MacIntel',
];

const getWindowNavigator = (window: any) => (window?.navigator as any) || {};

const getArchitecture = (window: any): number => {
    const toCheck: string[] = [];
    const windowNavigator = getWindowNavigator(window);
    if (windowNavigator.cpuClass) toCheck.push(windowNavigator.cpuClass.toString().toLowerCase());
    if (windowNavigator.platform) toCheck.push(windowNavigator.platform.toString().toLowerCase());
    if (windowNavigator.userAgent) toCheck.push(windowNavigator.userAgent.toString().toLowerCase());
    let bits: number = 32;
    toCheck.forEach((toCheckValue: any) => {
        THE_64_BITS_SIGNATURES.forEach((THE_64_BITS_SIGNATURES_VALUE: any) => {
            if (toCheckValue.indexOf(THE_64_BITS_SIGNATURES_VALUE.toLowerCase()) !== -1) {
                bits = 64;
            }
        });
    });
    return bits;
};

const getOS = (window: any): any => {
    const windowNavigator = getWindowNavigator(window);
    const module: any = {
        options: [],
        header: [
            windowNavigator.platform,
            windowNavigator.userAgent,
            windowNavigator.appVersion,
            windowNavigator.vendor,
            windowNavigator.opera,
        ].filter((x) => !!x),
        dataos: [
            {name: 'Windows Phone', value: 'Windows Phone', version: 'OS'},
            {name: 'Windows', value: 'Win', version: 'NT'},
            {name: 'iPhone', value: 'iPhone', version: 'OS'},
            {name: 'iPad', value: 'iPad', version: 'OS'},
            {name: 'Kindle', value: 'Silk', version: 'Silk'},
            {name: 'Android', value: 'Android', version: 'Android'},
            {name: 'PlayBook', value: 'PlayBook', version: 'OS'},
            {name: 'BlackBerry', value: 'BlackBerry', version: '/'},
            {name: 'Macintosh', value: 'Mac', version: 'OS X'},
            {name: 'Linux', value: 'Linux', version: 'rv'},
            {name: 'Palm', value: 'Palm', version: 'PalmOS'},
        ],
        databrowser: [
            {name: 'Chrome', value: 'Chrome', version: 'Chrome'},
            {name: 'Firefox', value: 'Firefox', version: 'Firefox'},
            {name: 'Safari', value: 'Safari', version: 'Version'},
            {name: 'Internet Explorer', value: 'MSIE', version: 'MSIE'},
            {name: 'Opera', value: 'Opera', version: 'Opera'},
            {name: 'BlackBerry', value: 'CLDC', version: 'CLDC'},
            {name: 'Mozilla', value: 'Mozilla', version: 'Mozilla'},
        ],
        init(): any {
            const agent: string = this.header.join(' ');
            const os: object = this.matchItem(agent, this.dataos);
            const browser: object = this.matchItem(agent, this.databrowser);
            return {os, browser};
        },
        matchItem(str: string, data: any[]) {
            let i: number = 0;
            let j: number = 0;
            let regex: RegExp;
            let regexv: RegExp;
            let match: boolean;
            let matches: any;
            let version: string;
            for (i = 0; i < data.length; i += 1) {
                regex = new RegExp(data[i].value, 'i');
                match = regex.test(str);
                if (match) {
                    regexv = new RegExp(`${data[i].version}[- /:;]([\\d._]+)`, 'i');
                    matches = str.match(regexv);
                    version = '';
                    if (matches) {
                        if (matches[1]) {
                            // eslint-disable-next-line prefer-destructuring
                            matches = matches[1];
                        }
                    }
                    if (matches) {
                        matches = matches.split(/[._]+/);
                        for (j = 0; j < matches.length; j += 1) {
                            if (j === 0) {
                                version += `${matches[j]}.`;
                            } else {
                                version += matches[j];
                            }
                        }
                    } else {
                        version = '0';
                    }
                    return {
                        name: data[i].name,
                        version: parseFloat(version),
                    };
                }
            }
            return {name: 'unknown', version: 0};
        },
    };
    const e: any = module.init();
    return {
        name: (e?.os?.name || '').toLowerCase(),
        version: (e?.os?.version || '').toString(),
    };
};

export const retrieveOsAndArch = (window: any): OSType => {
    const architecture: number = getArchitecture(window) || -1;
    const os: any = getOS(window) || {};
    const osVersion: string[] = (os.version || '').split('.') || [];
    // If Linux, we check only the architecture
    if (os.name === OSName.Linux) {
        return architecture.toString() === '64' ? OSType.Linux64 : OSType.Linux32;
    }
    if (os.name === OSName.MacOS) {
        // if MacOS, we need to check the version
        const macOsversion: string = `${osVersion[0]}.${(osVersion[1] || '0').substr(0, 2)}`;
        if (MAC_OS_COMPATIBILITIES.indexOf(macOsversion) === -1) {
            // Mac OS not compatible
            return OSType.NotFound;
        }
        if (macOsversion === '10.10') {
            return OSType.MacOSYosemite;
        }
        return OSType.MacOS;
    }
    if (os.name === OSName.Windows) {
        // if Windows, we need to check both version and architecture
        const windowsVersion: string = osVersion[0];
        if (WINDOWS_COMPATIBILITIES.indexOf(windowsVersion) === -1) {
            // Windows not compatible
            return OSType.NotFound;
        }
        if (windowsVersion === '6') {
            return architecture.toString() === '64' ? OSType.Windows64Vista : OSType.Windows32Vista;
        }
        return architecture.toString() === '64' ? OSType.Windows64 : OSType.Windows32;
    }
    // Os is neither Linux, MacOS nor Windows, no compatibility, return Not found
    return OSType.NotFound;
};

const urls = {
    all: {
        macos: 'ln-ins-default-mac-os',
        macosyosemite: 'ln-ins-default-mac-os-yosemite',

        windows32: 'ln-ins-default-windows-32',
        windows64: 'ln-ins-default-windows-64',
        windows32vista: 'ln-ins-default-windows-32-vista',
        windows64vista: 'ln-ins-default-windows-64-vista',

        /**
        for linux, let the default target provided in the button,
        no specific url as there are 2 different links for
        rpm and deb and we are not able to autodetect which one to provide
        linux32deb: 'ln-ins-default-linux-32-deb',
        linux64deb: 'ln-ins-default-linux-64-deb',
        linux32rpm: 'ln-ins-default-linux-32-rpm',
        linux64rpm: 'ln-ins-default-linux-64-rpm',
         */
    },
    es_es: {
        macos: 'ln-ins-es-mac-os',

        windows32: 'ln-ins-es-windows-32',
        windows64: 'ln-ins-es-windows-64',
        windows32vista: 'ln-ins-es-windows-32-vista',
        windows64vista: 'ln-ins-es-windows-64-vista',

        /**
        for linux, let the default target provided in the button,
         no specific url as there are 2 different links for
        rpm and deb and we are not able to autodetect which one to provide
        linux32deb: 'ln-ins-es-linux-32-deb',
        linux64deb: 'ln-ins-es-linux-64-deb',
        linux32rpm: 'ln-ins-es-linux-32-rpm',
        linux64rpm: 'ln-ins-es-linux-64-rpm',
        */
    },
};

export default {
    button: (props: any) => {
        let os;
        const rawLocale = props.locale || 'fr-fr';
        const locale = rawLocale.replace('-', '_').toLowerCase();
        switch (
            typeof window === 'undefined' ? -1 : retrieveOsAndArch(window) // be SSR-compatible
        ) {
            case -1:
                os = 'NotFound';
                break;
            case 0:
                os = 'MacOS';
                break;
            case 1:
                os = 'Windows32';
                break;
            case 2:
                os = 'Windows64';
                break;
            case 3:
                os = 'Linux32';
                break;
            case 4:
                os = 'Linux64';
                break;
            case 5:
                os = 'iOS';
                break;
            case 6:
                os = 'Android';
                break;
            case 7:
                os = 'MacOSYosemite';
                break;
            case 8:
                os = 'Windows32Vista';
                break;
            case 9:
                os = 'Windows64Vista';
                break;
            default:
                os = 'NotFound';
                break;
        }
        os = os.toLowerCase();
        props.onClick = '/';
        // @ts-ignore
        props.onClickDeferred =
            createUrl((urls[locale] || {})[os] || (urls.all || {})[os]) || 'none';
        props.onClickCb = () => {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            typeof window !== 'undefined' &&
                // @ts-ignore
                window?.dataLayer?.push({
                    event: 'ga_evenement',
                    categorie: 'interaction',
                    action: 'telechargement',
                    libelle: 'luniistore',
                    valeur: undefined,
                    non_interaction: false,
                    eventCallback() {
                        // @ts-ignore
                        window?.dataLayer?.push({
                            donnees_ecommerce: undefined,
                            categorie: undefined,
                            action: undefined,
                            libelle: undefined,
                            valeur: undefined,
                        });
                    },
                });
        };
    },
};
