import React from 'react';
import {Button} from "../atoms";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useLuniiTranslation from "../hooks/useLuniiTranslation";

const useStyles = makeStyles({
    root: {
    }
});

export function RegisterButton() {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    // todo handle register
    return (
        <Button className={classes.root} color={"secondary"} size={"small"}>{t('buttons_register_label')}</Button>
    );
}

export default RegisterButton
