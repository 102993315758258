import React, {ChangeEvent} from 'react';
import {Accordion, AccordionSummary, AccordionDetails, makeStyles} from '@material-ui/core';
import clsx from 'clsx';
import {cssText} from '@ohoareau/css-utils';
import {DynamicIcon, useLuniiTranslation} from '../../../../ui';

interface SingleFlapAccordionProps {
    children: React.ReactNode;
    className: string;
}

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        position: 'relative',
    },
}));
const useStylesAccordion = makeStyles(() => ({
    root: {
        width: '100%',
        boxShadow: 'none',
        marginTop: 0,
        '&.Mui-expanded': {
            margin: 0,
        },
    },
}));
const useStylesAccordionSummary = makeStyles((theme) => ({
    root: {
        ...cssText(theme, 'standard', 'button_2', undefined, undefined, '#2762E9'),
        height: 24,
        minHeight: 24,
        padding: 0,
        margin: 0,
        '& .expandIcon': {
            transition: 'transform .35s ease-out',
        },
        '&.Mui-expanded': {
            margin: 0,
            minHeight: 24,
        },
    },
    expanded: {
        margin: 0,
        '& .expandIcon': {
            transform: 'rotate(180deg)',
        },
    },
    content: {
        display: 'flex',
        alignItems: 'center',
        '&.Mui-expanded': {
            margin: 0,
        },
    },
}));
const useStylesAccordionDetails = makeStyles((theme) => ({
    root: {
        padding: '16px 16px 0',
    },
}));

export function SingleFlapAccordion({children, className = ''}: SingleFlapAccordionProps) {
    const classes = useStyles();
    const accordionClasses = useStylesAccordion();
    const accordionSummaryClasses = useStylesAccordionSummary();
    const accordionDetailsClasses = useStylesAccordionDetails();
    const {t} = useLuniiTranslation();

    const [expanded, setExpanded] = React.useState<string | boolean>(false);

    const handleChange = (panel: string) => (event: ChangeEvent<{}>, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <div className={clsx(classes.root, className)}>
            <Accordion
                classes={accordionClasses}
                square
                expanded={expanded === 'panel'}
                onChange={handleChange('panel')}
            >
                <AccordionSummary
                    classes={accordionSummaryClasses}
                    aria-controls="paneld-content"
                    id="paneld-header"
                >
                    {expanded ? t('singleflapaccordion_less') : t('singleflapaccordion_more')}{' '}
                    <DynamicIcon
                        className="expandIcon"
                        type="ArrowDown"
                        color="#2762E9"
                    />
                </AccordionSummary>
                <AccordionDetails classes={accordionDetailsClasses}>
                    <div>{children}</div>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

export default SingleFlapAccordion;
