import * as React from 'react';
function SvgSearch(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width={props.width || 24}
            height={props.height || 24}
            {...props}
        >
            <path
                fill="none"
                d="M0 0h24v24H0z"
            />
            <path
                d="M18 11a7 7 0 11-14 0 7 7 0 0114 0z"
                stroke={props.color || 'currentColor'}
                strokeWidth={2}
            />
            <path
                d="M20 20l-2-2"
                stroke={props.color || 'currentColor'}
                strokeWidth={2}
                strokeLinecap="round"
            />
            <path
                d="M11 8a3 3 0 013 3"
                stroke={props.color || 'currentColor'}
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
export default SvgSearch;
