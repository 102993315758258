import React, {ReactNode} from 'react';
import {makeStyles} from '@material-ui/core';
import {cssBg, cssText} from '@ohoareau/css-utils';
import clsx from 'clsx';
import {BulletNumber, BulletIcon} from '../atoms';
import {buttonifyFromProps, image_value, Img} from '../../ui';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
            padding: 'unset',
        },
    },
    imageWrapper: {
        borderRadius: 16,
        aspectRatio: '1.7',
        width: '100%',
        height: 184,
        [theme.breakpoints.up('sm')]: {
            height: 165,
        },
        [theme.breakpoints.up('md')]: {
            height: 205,
        },
    },
    content: {
        width: '100%',
        display: 'flex',
        alignItems: 'start',
        marginTop: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: theme.spacing(0),
        },
    },
    bullet: {
        minHeight: 32,
        minWidth: 32,
        [theme.breakpoints.up('md')]: {
            marginTop: theme.spacing(3),
        },
    },
    textWrapper: {
        textAlign: 'left',
        marginLeft: theme.spacing(1),
        [theme.breakpoints.up('md')]: {
            textAlign: 'center',
            marginLeft: 'unset',
        },
    },
    title: {
        ...cssText(theme, 'standard', 'body_thick'),
        marginTop: 4,
        [theme.breakpoints.up('md')]: {
            marginTop: theme.spacing(2),
        },
    },
    text: {
        ...cssText(theme, 'standard', 'body'),
        marginTop: theme.spacing(0.5),
    },
    actions: {
        marginTop: theme.spacing(3),
        width: '100%',
        display: 'flex',
        alignItems: 'stretch',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row',
        },
    },
}));

export interface NumberedCardProps {
    bulletMode: 'icon' | 'numeric';
    bulletContent: number | string;
    image?: image_value;
    title?: ReactNode;
    text?: ReactNode;
    buttonLabel?: ReactNode;
    buttonTarget?: string | Function | undefined;
    buttonType?: string;
    className?: string;
}

export function BulletCard({
    bulletMode,
    bulletContent,
    image,
    title,
    text,
    buttonLabel,
    buttonTarget,
    buttonType,
    className = '',
}: NumberedCardProps) {
    const classes = useStyles();

    const {Button} = buttonifyFromProps(
        {
            buttonTarget,
            buttonLabel,
            buttonType,
        },
        [`${buttonType || 'plain'}`],
    );

    return (
        <div className={clsx(classes.root, className)}>
            {image && (
                <div className={classes.imageWrapper}>
                    <Img
                        {...image}
                        objectFit="contain"
                    />
                </div>
            )}
            <div className={classes.content}>
                {bulletMode === 'numeric' ? (
                    <BulletNumber
                        number={bulletContent.toString()}
                        className={classes.bullet}
                    />
                ) : (
                    <BulletIcon
                        iconType={bulletContent.toString()}
                        className={classes.bullet}
                    />
                )}
                <div className={classes.textWrapper}>
                    {title && <div className={classes.title}>{title}</div>}
                    {text && <div className={classes.text}>{text}</div>}
                </div>
            </div>
            {Button && (
                <div className={classes.actions}>
                    <Button />
                </div>
            )}
        </div>
    );
}

export default BulletCard;
