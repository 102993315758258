import React from 'react';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {Step, isActive} from '..';

const useStyles = makeStyles({
    root: {
        display: 'flex',
    },
    vertical: {
        flexDirection: 'column',
    },
});

export function Steppers({vertical = false, step = '', steps = [], onClick = undefined, color}: SteppersProps) {
    const classes = useStyles();
    return (
        <div className={clsx(classes.root, vertical && classes.vertical)}>
            {steps.map((s, index) => (
                <div key={`steppers_${Math.random()}`}>
                    <Step vertical={vertical} index={index} step={s} active={isActive(s, step, index)} onClick={onClick} color={color}/>
                </div>
            ))}
        </div>
    );
}

export interface SteppersProps {
    step?: string;
    steps?: string[];
    onClick?: Function;
    vertical?: boolean;
    color: string;
}

export default Steppers
