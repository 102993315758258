import React, {useCallback, useState} from 'react';
import clsx from 'clsx';
import Button from '../atoms/Button';
import EmailField from '../atoms/fields/EmailField';
import FieldSet from '../atoms/FieldSet';
import {useForm} from 'react-hook-form';
import useForgotPasswordSubmit from '../hooks/useForgotPasswordSubmit';
import useResendValidationEmailSubmit from '../hooks/useResendValidationEmailSubmit';
import ErrorPanel from '../atoms/ErrorPanel';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useLuniiTranslation from '../hooks/useLuniiTranslation';
import {cssText} from '@ohoareau/css-utils';
import {UneditableTextField} from '../atoms';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    button1: {
        width: '100%',
        marginTop: theme.spacing(2),
    },
    button2: {
        width: '100%',
        marginTop: theme.spacing(2),
    },
    verifyInboxText: {
        ...cssText(theme, 'standard', 'body'),
    },
    input: {
        minWidth: '70%',
    },
}));

export function ForgotPasswordForm({className = undefined, onFormUpdate, onCancelClick, inputFullWidth = true}) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const [formData, setFormData] = useState<{ email: string } | undefined>();
    const {handleSubmit, register, errors} = useForm();
    const [handleForgotPassword, {loading, error, called}] = useForgotPasswordSubmit(onFormUpdate);
    const [handleResendValidationEmail, {
        loading: resendLoading,
        error: resendError,
        called: resendCalled,
    }] = useResendValidationEmailSubmit(() => onFormUpdate('validation'));

    const onSubmit = useCallback((data) => {
        handleForgotPassword(data);
        setFormData(data);
    }, [handleForgotPassword, setFormData]);

    const onResendClick = useCallback(() => {
        handleResendValidationEmail(formData);
    }, [handleResendValidationEmail, formData]);

    const x = {errors, register};

    return (
        <div className={clsx(classes.root, className)}>
            <form onSubmit={handleSubmit(onSubmit)}>
                {!resendCalled && <ErrorPanel error={error} group={'forgotPassword'}/>}
                {resendError && resendCalled && <ErrorPanel error={resendError} group={'resendValidationEmail'}/>}
                {(!called && !error) && <>
                    <FieldSet>
                        <EmailField className={classes.input} required autoFocus {...x} fullWidth={inputFullWidth}/>
                    </FieldSet>
                    <Button type={'submit'} disabled={loading} className={classes.button1} color={'primary'}>
                        {t('forms_forgotpassword_buttons_submit_label')}
                    </Button>
                </>}
                {(called && error) && <>
                    <FieldSet>
                        <UneditableTextField name={'email'} value={formData?.email} className={classes.input}/>
                    </FieldSet>
                    <Button disabled={resendLoading} className={classes.button1} color={'primary'} onClick={onResendClick}>
                        {t('forms_forgotpassword_buttons_resend_label')}
                    </Button>
                </>}
                <Button disabled={loading} className={classes.button2} color={'plain'} onClick={onCancelClick}>
                    {t('forms_forgotpassword_buttons_cancel_label')}
                </Button>

            </form>
        </div>
    );
}

export default ForgotPasswordForm;
