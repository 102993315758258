import {useEffect, useState} from 'react';
import {s3} from '../../configs/site';
import {linkResolver} from '../utils';

export const useLiveElement = (locale: string, uid: string): any => {
    const [data, setData] = useState<any>(null);

    useEffect(() => {
        try {
            const apiUrl = `${s3.prismicCache}live_element/${locale}/${uid}/data.json`;
            fetch(apiUrl).then(async data => {
                if (data.status === 200) {
                    const json = await data.json();
                    const first = (json || []).shift();
                    const primary = first?.primary;
                    setData({
                        type: first?.slice_type,
                        overline: primary?.overline,
                        title: primary?.title,
                        text: primary?.text,
                        image: primary?.image,
                        buttonLabel: primary?.button_label,
                        buttonTarget: linkResolver(primary?.button_link),
                        buttonType: primary?.button_type,
                    });
                }
            }).catch(err => {
                console.error(err);
            });
        } catch (err) {
            console.error(err);
        }
    }, [locale, uid]);

    return data;
};

export default useLiveElement;
