import React from 'react';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import {cssText} from '@ohoareau/css-utils';
import clsx from 'clsx';
import {Row} from '../../../ui';
import {getThemeColor} from '../../../../configs';
import {heading_universe} from '../../../types';

const getColorFromTheme = (universe: heading_universe | undefined, nuance: string = '800'): string => {
    if (universe === 'blue') {
        return getThemeColor('brand-secondary', nuance);
    }
    if (universe === 'yellow') {
        return getThemeColor('brand-primary', nuance);
    }
    return getThemeColor('ui', nuance);
};

const useStyles = makeStyles((theme) => ({
    root: (props: any) => ({
        textAlign: props.position,
        backgroundColor: getColorFromTheme(props.universe, props.universe === 'default' ? 'white' : '50'),
    }),
    content: {
        '&>:last-child': {
            marginTop: theme.spacing(0.5),
        },
    },
    overline_large: {
        ...cssText(theme, 'standard', 'overline_large_title'),
        [theme.breakpoints.down('sm')]: {
            ...cssText(theme, 'standard', 'overline_title_1'),
        },
    },
    overline_medium: {
        ...cssText(theme, 'standard', 'overline_title_1'),
        [theme.breakpoints.down('sm')]: {
            ...cssText(theme, 'standard', 'overline_title_2'),
        },
    },
    overline: (props: any) => ({
        color: getColorFromTheme(props.universe, '800'),
    }),
    title_large: {
        ...cssText(theme, 'standard', 'large_title'),
        [theme.breakpoints.down('sm')]: {
            ...cssText(theme, 'standard', 'title_1'),
        },
    },
    title_medium: {
        ...cssText(theme, 'standard', 'title_1'),
        [theme.breakpoints.down('sm')]: {
            ...cssText(theme, 'standard', 'title_2'),
        },
    },
    title: (props: any) => ({
        color: getColorFromTheme(props.universe, '900'),
    }),
    text: (props: any) => ({
        ...cssText(theme, 'standard', 'body'),
        color: getColorFromTheme(props.universe, '800'),
    }),
}));

export function HeadingSection({universe = 'default', position = 'left', variant = 'large', overline = undefined, title = undefined, text = undefined}: HeadingSectionProps) {
    const classes = useStyles({ position, universe });
    return (
        <Row className={classes.root} padding='none' justify={position === 'left' ? 'flex-start' : 'center'}>
            <Grid lg={6} md={6} xs={12}>
                <div className={classes.content}>
                    {overline && <div className={clsx(classes.overline, classes[`overline_${variant}`])}>{overline}</div>}
                    {title && <Typography variant={variant === 'large' ? 'h1' : 'h2'} className={clsx(classes.title, classes[`title_${variant}`])}>
                        {title}
                    </Typography>}
                    {text && <div className={classes.text}>{text}</div>}
                </div>
            </Grid>
        </Row>
    );
}

export interface HeadingSectionProps {
    universe?: heading_universe;
    position?: 'left' | 'center';
    variant?: 'large' | 'medium';
    overline?: string;
    title?: string ;
    text?: string;
}

export default HeadingSection;
