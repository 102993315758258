import React, {useEffect, useMemo, useState} from 'react';
import clsx from 'clsx';
import {cssText} from '@ohoareau/css-utils';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import {useForm} from 'react-hook-form';
import {
    Spinner,
    Button,
    ErrorPanel,
    FieldSet,
    FirstNameField,
    LastNameField,
    UneditableTextField,
    DividingLine,
    Checkbox,
} from '../atoms';
import useLuniiTranslation from '../hooks/useLuniiTranslation';
import useUserUpdateSubmit from '../hooks/useUserUpdateSubmit';
import {useAuthentifiedNewsletterSubscribeSubmit, useLuniiUser} from '../hooks';
import useLuniiPasswordUpdateModal from '../hooks/useLuniiPasswordUpdateModal';
import {DynamicIcon} from '../nucleons';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
    },
    head: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(4),
    },
    title: {
        ...cssText(theme, 'standard', 'title_1'),
        [theme.breakpoints.down('sm')]: {
            ...cssText(theme, 'standard', 'title_2'),
        },
    },
    actions: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    actionIcon: {
        marginRight: theme.spacing(1),
    },
    fieldset: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
    },
    fieldsetTitle: {
        ...cssText(theme, 'standard', 'body', undefined),
        marginBottom: theme.spacing(0.5),
    },
    fieldsetNote: {
        ...cssText(theme, 'standard', 'footnote', undefined),
        marginTop: 8,
    },
    fieldsetAddress: {
        flex: 1,
        flexDirection: 'column',
    },
    field: {
        marginRight: theme.spacing(4),
    },
    input: {
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(1),
        },
    },
    validateUserInfos: {
        marginLeft: 8,
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(2, 0, 0, 0),
        },
    },
    checkbox: {
        margin: theme.spacing(3, 0, 0, 0),
    },
}));

export function MyUserInformations ({className, title, ...props}) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const {open} = useLuniiPasswordUpdateModal();
    const [editable, setEditable] = useState(false);
    const {user, refreshUserPartial} = useLuniiUser();
    const defaultUserValues = useMemo(
        () => ({
            firstName: user?.firstName,
            lastName: user?.lastName,
        }),
        [user],
    );
    const {handleSubmit, register, errors, reset} = useForm({defaultValues: defaultUserValues});
    const [onSubmit, {loading, error}] = useUserUpdateSubmit(() => {
        refreshUserPartial({user: true});
        setEditable(!editable);
    });
    const [subscribeNewsletterAuthentified] = useAuthentifiedNewsletterSubscribeSubmit(() =>
        refreshUserPartial({user: true}),
    );
    useEffect(() => {
        reset({...defaultUserValues});
    }, [defaultUserValues]);
    const onToggleClick = (e) => {
        e.preventDefault();
        if (editable) reset();
        setEditable(!editable);
    };
    const onUpdateClick = (e) => {
        e.preventDefault();
        handleSubmit((formData) => {
            onSubmit(formData);
        })();
    };
    const newsletterSubscriptionStatus = !!user?.newsletter?.active;
    const updateNewsletterStatus = () =>
        subscribeNewsletterAuthentified(newsletterSubscriptionStatus);
    const x = {errors, register};
    const form = (
        <form onSubmit={handleSubmit(onSubmit)}>
            <ErrorPanel error={error} />
            <FieldSet className={classes.fieldset}>
                <Grid container item lg={12} md={12} xs={12}>
                    <Grid className={classes.field} item lg={4} md={4} xs={12}>
                        <FirstNameField
                            disabled={loading}
                            className={classes.input}
                            autoFocus
                            required
                            min={1}
                            max={100}
                            {...x}
                        />
                    </Grid>
                    <Grid className={classes.field} item lg={4} md={4} xs={12}>
                        <LastNameField
                            disabled={loading}
                            className={classes.input}
                            required
                            min={1}
                            max={100}
                            {...x}
                        />
                    </Grid>
                </Grid>
            </FieldSet>
        </form>
    );
    const placeHolder = (
        <div className={classes.fieldset}>
            <Grid container>
                <Grid item lg={4} md={4} xs={12}>
                    <UneditableTextField
                        name={'firstName'}
                        value={user?.firstName}
                        className={classes.input}
                    />
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                    <UneditableTextField
                        name={'lastName'}
                        value={user?.lastName}
                        className={classes.input}
                    />
                </Grid>
            </Grid>
        </div>
    );
    return (
        <div className={clsx(classes.root, className)} {...props}>
            {loading && <Spinner />}
            <ErrorPanel error={error} />

            <div className={classes.head}>
                <Typography variant={'h3'} className={classes.title}>
                    {title && title}
                </Typography>
                <div className={classes.actions}>
                    <Button
                        color={'plain'}
                        size={'small'}
                        startIcon={<DynamicIcon type={'edit'} />}
                        onClick={onToggleClick}
                    >
                        {!editable
                            ? t('form_user_information_edit')
                            : t('form_user_information_cancel_edit')}
                    </Button>
                    {editable && (
                        <Button
                            className={classes.validateUserInfos}
                            color={'primary'}
                            size={'small'}
                            onClick={onUpdateClick}
                        >
                            {t('form_user_information_update')}
                        </Button>
                    )}
                </div>
            </div>

            {editable ? form : placeHolder}

            <DividingLine spacing={2} />

            <div className={clsx(classes.fieldset, classes.fieldsetAddress)}>
                <UneditableTextField name='email' value={user?.email} className={classes.input} />
                <div className={classes.fieldsetNote}>
                    {t('form_user_information_address_note')}
                </div>
            </div>

            <DividingLine spacing={3} />

            <Grid>
                <Button onClick={open} color={'secondary'} size={'small_mobile_only'}>
                    {t('form_user_information_password_edit')}
                </Button>
                <div className={classes.checkbox}>
                    <Checkbox
                        status={newsletterSubscriptionStatus}
                        alignItems={'flex-start'}
                        id={'newsletter'}
                        content={t('newsletter_subtitle')}
                        name={'newsletter'}
                        linkTarget={updateNewsletterStatus}
                        onChange={updateNewsletterStatus}
                    />
                </div>
            </Grid>
        </div>
    );
}

export interface MyUserInformationsProps extends React.HTMLProps<HTMLDivElement> {
    title?: string;
    className?: string;
}

export default MyUserInformations;
