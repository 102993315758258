import {useCallback, useContext} from 'react';
import {address as address_type, catalog_context_item_value, product, ProductTableNameEnum} from '../types';
import LuniiTrackingContext from '../contexts/LuniiTrackingContext';
import LuniiProductContext from '../contexts/LuniiProductContext';
import useLuniiUser from './useLuniiUser';
import {sha256} from '../../utils';

function formatListViewType (types: any[], key: string): string {
    return types
        .filter(type => !!type)
        .filter(type => type.includes(key))
        .map(entry => entry.split('-').slice(1).join(' '))
        .join('-');
}

function formatPrice (price: number|undefined) {
    return price && (price / 100);
}

const productViewEventEnvModel: string[] = [
    'nos_histoires_produit',
    'mfah',
    'octave',
    'pochette',
    'flam',
    'coque-odile',
    'coque-flam',
    'calendrier',
];

const listViewEventEnvModel: string[] = [
    'recherche',
    'nos_histoires_luniistore',
    'nos_histoires_papier',
    'catalogue_flam',
    'a_la_une',
];


export const formatEnvModel = (envModel: string) => {
    if ([
        'accueil',
        'mfah',
        'octave',
        'coque-odile',
        'coque-flam',
        'pochette',
        'flam',
        'carte_cadeau',
        'abonnement_presentation',
        'abonnement_selection',
        'abonnement_paiement',
        'abonnement_confirmation',
        'banniere_',
        'abonnement_offrir_selection',
        'abonnement_offrir_paiement',
        'abonnement_offrir_confirmation',
        'mon_studio_lunii',
        'recherche',
        'a_la_une',
        'nos_histoires_luniistore',
        'catalogue_flam',
        'nos_histoires_papier',
        'nos_histoires_produit',
        'luniistore',
        'liste_souhaits',
        'ecole',
        'panier',
        'identification',
        'livraison_paiement',
        'confirmation_achat',
        'mon_compte',
        'connexion',
        'inscription',
        'bibliotheque',
        'landing_page',
    ].indexOf(envModel) !== -1 || envModel.indexOf('banniere_') === 0) return envModel;
    return 'autres';
}


export function useLuniiPageTracking(params: {envModel: string}) {

    const tracking = useContext(LuniiTrackingContext);
    const product = useContext(LuniiProductContext);
    const {user} = useLuniiUser();

    const gtmUser = useCallback(async () => {
        if (user) {
            const hashedPhone = await sha256(((user.deliveryInfos || []).find((address: address_type) => user.locale === address.locale) || {}).phone || '');
            const hashedEmail = await sha256(user?.email || '');
            return {
                utilisateur_connecte: 'oui',
                utilisateur_id: user?.id,
                utilisateur_telephone: hashedPhone,
                utilisateur_email: hashedEmail,
                utilisateur_nbr_commandes: user?.purchaseCount || 0,
                utilisateur_statut: user?.purchaseCount ?
                    user?.purchaseCount > 1 ? 'client' : 'nouveau_client'
                    : 'prospect',
                utilisateur_abonne: user?.subscription ? 'oui' : 'non',
            }
        }
        return {
            utilisateur_connecte: 'non',
        };
    }, [user]);

    const productViewEventData = useCallback((envModel: string) => {
        let data: {} | undefined = undefined;
        if (product && productViewEventEnvModel.includes(envModel)) {
            const {reference, catalog, name, price, priceExclTax, types, themes, ageRecommendations, type, subtype, locale} = product;
            let produit_type = '';
            switch (type) {
                case ProductTableNameEnum.Packs:
                    produit_type = 'audio';
                    break;
                case ProductTableNameEnum.GiftCards:
                    produit_type = 'carte_cadeau';
                    break;
                case ProductTableNameEnum.Hardware:
                    produit_type = subtype === 'paperbook' ? 'papier' : 'materiel';
                    break;
                default:
                    break;
            }
            data = {
                produit_id: reference,
                produit_type,
                produit_bundle: undefined, // TODO @bundles
                produit_nom: name,
                produit_prix_ht: formatPrice(priceExclTax), // TODO update products formatting
                produit_prix_ttc: formatPrice(price),
                produit_thematique: (themes || []).map((theme: any) => (theme?.name)).join('-').toLowerCase(),
                produit_age: (ageRecommendations || []).map((age: any) => (age?.name)).join('-').toLowerCase(),
                produit_type_album: types?.name?.toLowerCase(),
                produit_langue_etrangere: locale !== catalog ? locale : undefined,
            };
        }
        return data;
    }, [product]);

    const listViewEventData = useCallback((envModel: string, productList: product[], searchQuery: string | undefined) => {

        let data: {} | undefined = undefined;

        if (listViewEventEnvModel.includes(envModel)) {

            const liste_produits = productList.map((item: catalog_context_item_value, index: number) => {

                const {reference, name, priceExclTax, price, types, locale, catalog, type, subtype} = item;

                const ageRecommendations = types && formatListViewType(types, 'age-') || undefined;
                const themes = types && formatListViewType(types, 'theme-') || undefined;
                const albumType = types && formatListViewType(types, 'type-') || undefined;
                const foreignLanguages = (locale !== catalog) && formatListViewType(types, 'lang-');
                let produit_type = '';
                switch (type) {
                    case ProductTableNameEnum.Packs:
                        produit_type = 'audio';
                        break;
                    case ProductTableNameEnum.GiftCards:
                        produit_type = 'carte_cadeau';
                        break;
                    case ProductTableNameEnum.Hardware:
                        produit_type = subtype === 'paperbook' ? 'papier' : 'materiel';
                        break;
                    default:
                        break;
                }
                return {
                    produit_id: reference,
                    produit_bundle: undefined, // TODO @bundles
                    produit_nom: name,
                    produit_type,
                    produit_prix_ht: formatPrice(priceExclTax), // TODO update products formatting
                    produit_prix_ttc: formatPrice(price),
                    produit_thematique: themes,
                    produit_age: ageRecommendations,
                    produit_type_album: albumType,
                    produit_langue_etrangere: foreignLanguages ? foreignLanguages.replace(' ', '-') : undefined,
                    produit_position: index + 1,
                };
            });

            data = {
                liste_produits,
                liste_quantite: productList.length,
                liste_nom: envModel,
            };

            if (envModel === 'recherche') {
                data['recherche_mot_cle'] = searchQuery;
            }

        }

        return data;

    }, [formatListViewType]);

    return async (productList: product[] = [], searchQuery: string | undefined = undefined) => {

        const {dataLayer, env: gtmEnv} = tracking;
        const envModel = formatEnvModel(params?.envModel);
        const gtm = await gtmUser();

        dataLayer && setTimeout && setTimeout(() => {
            dataLayer.push({
                event: 'datalayer',
                env_modele: envModel,
                ...gtmEnv,
                ...gtm,
                ...productViewEventData(envModel),
                ...(productList && listViewEventData(envModel, productList, searchQuery)),
                _clear: true,
            });
        }, 150);
        /*
         Pageview event is emitted with a 50ms delay from the gatsby gtm module
         and dataLayer event must be emitted afterwards
         */

    };

}

export default useLuniiPageTracking;
