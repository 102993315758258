import React from 'react';
import {Trans} from 'react-i18next';
import {useLuniiNavigation, useLuniiTranslation, useUserError} from '../hooks';
import {Link} from '../..';

export function ErrorItem({className = '', error, group, locale = undefined}: {className?: string, error: any, group: string, locale?: string}) {
    const {t} = useLuniiTranslation();
    const {goSupport} = useLuniiNavigation();
    const {message, options} = useUserError(error, group, locale) || {};
    return (
        <Trans
            classes={className}
            i18nKey={message}
            values={options}
            components={{devComponent: <Link className={className} url={(e: any) => goSupport && goSupport(e, 'requests/new')} text={t('global_support_name')} theme='error'/>}}
        />
    );
}

export default ErrorItem;
