import React, {ReactNode} from 'react';
import {Grid, makeStyles} from '@material-ui/core';
import {cssBgColor, cssText} from '@ohoareau/css-utils';
import {
    buttonifyFromProps,
    Row,
    module_item,
    LuniiProductProvider,
    product_item,
} from '../../../ui';
import {BulletCard} from '../..';

const useStyles = makeStyles((theme) => ({
    root: {
        ...cssBgColor(theme, 'clear'),
        position: 'relative',
        [theme.breakpoints.up('md')]: {},
    },
    content: {
        textAlign: 'center',
    },
    title: {
        ...cssText(theme, 'standard', 'title_1'),
        [theme.breakpoints.up('md')]: {
            ...cssText(theme, 'standard', 'large_title'),
        },
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left',
        },
    },
    text: {
        ...cssText(theme, 'standard', 'body'),
        marginTop: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left',
        },
    },
    items: {
        marginTop: theme.spacing(4),
        width: '100%',
    },
    actions: {
        marginTop: theme.spacing(4),
        width: '100%',
        display: 'flex',
        alignItems: 'stretch',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row',
        },

        '&:empty': {
            display: 'none',
        },
    },
}));

export interface CardsOnboardingItemsModuleProps {
    title: ReactNode;
    text: ReactNode;
    product: product_item;
    buttonLabel?: ReactNode;
    buttonTarget?: string | Function | undefined;
    buttonType?: string;
    items: module_item[];
}

export function CardsOnboardingItemsModule({
    title,
    text,
    product: moduleProduct,
    buttonLabel,
    buttonTarget,
    buttonType,
    items,
    ...props
}: CardsOnboardingItemsModuleProps) {
    const classes = useStyles();

    const {Buttons} = moduleProduct
        ? buttonifyFromProps(
              {
                  buttonTarget: moduleProduct.buttonTarget,
                  buttonLabel,
                  ...props,
              },
              [`primary,startIcon=addToCart`],
          )
        : buttonifyFromProps(
              {
                  buttonTarget,
                  buttonLabel,
                  buttonType,
                  ...props,
              },
              [`${buttonType || 'primary'}`],
          );

    if (items.length > 3 || items.length < 2) return null;

    return (
        <LuniiProductProvider value={moduleProduct}>
            <Row
                padding="none"
                direction="column"
                alignItems="center"
                className={classes.root}
            >
                <Grid
                    item
                    xs={12}
                    classes={{root: classes.content}}
                >
                    <div className={classes.title}>{title}</div>
                    <div className={classes.text}>{text}</div>
                    <div className={classes.items}>
                        <Grid
                            container
                            spacing={3}
                        >
                            {items.map((item: module_item, index: number) => (
                                <Grid
                                    key={`${index}_numbered_card`}
                                    item
                                    sm={12}
                                    md={items.length > 2 ? 4 : 6}
                                >
                                    <BulletCard
                                        bulletMode={item.label ? 'icon' : 'numeric'}
                                        bulletContent={
                                            item.label ? (item.label as string) : index + 1
                                        }
                                        image={item.image}
                                        title={item.title}
                                        text={item.text}
                                        buttonLabel={item.buttonLabel}
                                        buttonTarget={item.buttonTarget}
                                        buttonType={item.buttonType}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                    {Buttons && (
                        <div className={classes.actions}>
                            <Buttons />
                        </div>
                    )}
                </Grid>
            </Row>
        </LuniiProductProvider>
    );
}

export default CardsOnboardingItemsModule;
