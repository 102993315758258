import React from 'react';
import Push from '../molecules/Push';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Row from "../Row";
import ForceTheme from "../ForceTheme";
import Grid from "@material-ui/core/Grid";
import {WithItems} from "../types";

const useStyles = makeStyles(theme => ({
    root: {
    },
    container: {
        display: 'flex',
        width: '100%',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    item: {
        width: '49%',
        margin: theme.spacing(2, 0),
        alignSelf: 'stretch',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
}));

export function PushSmall({items = []}: PushSmallProps) {
    const classes = useStyles();
    if (items.length === 0) return null;
    return (
        <Row padding={'medium'} className={classes.root}>
            <Grid item container lg={12} md={12} xs={12}>
                <ForceTheme theme={'default'}>
                    <div className={classes.container}>
                        {items.map((item, index) => (
                            <Push {...item} imagePosition={'bottom'} mobileImagePosition={'bottom'} key={index} className={classes.item}/>
                        ))}
                    </div>
                </ForceTheme>
            </Grid>
        </Row>
    );
}

export interface PushSmallProps extends WithItems {
}

export default PushSmall
