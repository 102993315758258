import React from 'react';
import {cssBgColor, cssFgColor, cssText} from '@ohoareau/css-utils';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import HiddenCss from '@material-ui/core/Hidden/HiddenCss';
import {DynamicIcon, useLuniiTranslation, buttonifyFromProps} from '../../ui';
import CatalogMenuSubMenu from './CatalogMenuSubMenu';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            position: 'fixed',
            top: 0,
            left: 0,
            height: '100vh',
            width: '100vw',
            zIndex: 9999,
            ...cssBgColor(theme, 'clear'),
            display: 'none',
        },
    },
    opened: {
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        },
    },
    menu: {
        position: 'relative',
    },
    menuContent: {
        [theme.breakpoints.down('sm')]: {
            height: '75vh',
            overflow: 'scroll',
            padding: '32px 16px',
        },
    },
    menuTitle: {
        width: '100%',
        marginBottom: 36,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        ...cssFgColor(theme, 'standard', '#423F37'),
    },
    title: {
        paddingLeft: 10,
        ...cssText(theme, 'standard', 'title_2'),
    },
    mobileBottom: {
        position: 'fixed',
        bottom: 0,
        left: 16,
        right: 16,
        height: '20vh',
        width: 'inherit',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
    },
    reinitialize: {
        ...cssText(theme, 'standard', 'button_1', undefined, undefined, 'link'),
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    svg: {
        marginBottom: -3,
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            marginRight: 8,
            marginBottom: 2,
        },
    },
}));

export function CatalogMenu({
    menu = [],
    nbItemsActive = 0,
    filters = [],
    opened = false,
    onMenuItemClick,
    onReinitialize,
    onFinishClick,
}: CatalogMenuProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();

    const {Button} = buttonifyFromProps(
        {
            buttonLabel: t('catalog_finish'),
            buttonType: 'primary',
            buttonTarget: onFinishClick,
        },
        [],
    );

    const subtitle: string = nbItemsActive > 0 ? `(${nbItemsActive})` : '';

    if (menu.length === 0) return null;
    return (
        <div className={clsx(classes.root, opened && classes.opened)}>
            <div className={classes.menu}>
                <div className={classes.menuContent}>
                    <div className={classes.menuTitle}>
                        <div className={classes.title}>{`${t(
                            'catalog_menu_title',
                        )} ${subtitle}`}</div>
                        <HiddenCss smDown>
                            <DynamicIcon
                                type="refresh"
                                className={classes.svg}
                                width={18}
                                height={18}
                                onClick={onReinitialize}
                            />
                        </HiddenCss>
                    </div>
                    {menu.map((item, index) => (
                        <CatalogMenuSubMenu
                            {...item}
                            key={index}
                            filters={filters}
                            expandedByDefault={menu.length === 1}
                            onMenuItemClick={onMenuItemClick}
                            onResize={onFinishClick}
                        />
                    ))}
                </div>
                <HiddenCss mdUp>
                    <div className={classes.mobileBottom}>
                        <div
                            className={classes.reinitialize}
                            onClick={onReinitialize}
                        >
                            <DynamicIcon
                                type="refresh"
                                className={classes.svg}
                                width={22}
                                height={22}
                            />
                            <div>{t('catalog_reinitialize')}</div>
                        </div>
                        {Button && <Button />}
                    </div>
                </HiddenCss>
            </div>
        </div>
    );
}

export interface CatalogMenuProps {
    menu?: any[];
    onMenuChange?: any;
    onFinishClick?: any;
    onMenuItemClick?: any;
    onReinitialize?: any;
    state?: any;
    opened?: boolean;
    nbItemsActive?: number;
    filters?: any[];
}

export default CatalogMenu;
