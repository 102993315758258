import React from 'react';
import {cssText} from '@ohoareau/css-utils';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {Modal} from '../../organisms';
import {useLuniiEventTracking, useLuniiTranslation} from '../../hooks';
import {buttonifyFromProps} from '../../hocs';
import clsx from 'clsx';
import {Trans} from 'react-i18next';
import {getLongDate} from '../../utils';
import {ErrorPanel} from '../../atoms';
import useSubscriptionReactivate from '../../hooks/subscription/useSubscriptionReactivate';

const useStyles = makeStyles(theme => ({
    root: {
    },
    body: {
        ...cssText(theme, 'standard', 'body', undefined, undefined, '#716C5E'),
        [theme.breakpoints.down('sm')]: {
            ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#716C5E'),
        },
    },
}));

export function ReactivateModal({className, open = false, onClose = () => {}, onConfirm = () => {}, renewalPlannedAt, productReference, locale, ...props}: ReactivateModalProps) {
    const classes = useStyles();
    const {t,i18n} = useLuniiTranslation();
    const handleTrackingEvent = useLuniiEventTracking();
    const [execute, {loading, error}] = useSubscriptionReactivate({
        options: {
            onCompleted: () => {
                handleTrackingEvent({
                    categorie: 'abonnement',
                    action: 'reactivation',
                    libelle: productReference === 'monthly_subscription' ? 'mensuel' : 'annuel',
                });
                onConfirm();
            },
        },
    });
    const handleAction = () => execute();
    const {Buttons} = buttonifyFromProps({
        buttonLabel: t(`my_subscription_reactivate_modal_negative`),
        buttonTarget: onClose,
        button2Label: t(`my_subscription_reactivate_modal_positive`),
        button2Target: handleAction,
    }, [`plain,disabled=${loading}`, `primary,disabled=${loading}`]);
    const body = (
        <div className={classes.body}>
            <p>
                <Trans i18n={i18n} i18nKey={`my_subscription_reactivate_modal_body`} values={{
                    date1: getLongDate(renewalPlannedAt, locale),
                }}>
                    ...<strong>...</strong>...
                </Trans>
            </p>
            {error && <ErrorPanel error={error} key={'subscription'}/>}
        </div>
    );
    return (
        <>
            <Modal
                open={open}
                className={clsx(classes.root, className)}
                onCloseCallback={onClose}
                title={t('my_subscription_reactivate_modal_title')}
                body={body}
                loading={loading}
                actions={Buttons && <Buttons/>}/>
        </>
    );
}

export interface ReactivateModalProps {
    className?: string,
    open?: boolean,
    onClose?: any,
    onConfirm?: any,
    renewalPlannedAt: any,
    productReference: string,
    locale: string,
}

export default ReactivateModal;
