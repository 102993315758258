import React from 'react';
import TextField, {TextFieldProps} from './TextField';

export function GiftCardField(props: GiftCardFieldProps) {
    return (
        <TextField name={'giftCard'} {...props} />
    );
}

export interface GiftCardFieldProps extends TextFieldProps {}

export default GiftCardField