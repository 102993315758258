import React from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import {cssText} from '@ohoareau/css-utils';
import {Grid, GridSize} from '@material-ui/core';
import {
    DynamicIcon,
    getLongDate,
    Illustration,
    subscription,
    subscriptionPlannedEvents,
    SubscriptionStatusEnum,
    useLuniiTranslation,
} from '../index';
import convertPrice from '../utils/convertPrice';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2),
        opacity: 1,
        transition: 'opacity .35s ease-out',
    },
    gridTile: {
        padding: theme.spacing(0.5, 0),
        '&:first-child': {
            paddingTop: 0,
        },
        '&:last-child': {
            paddingBottom: 0,
        },
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(0, 1),
            '&:first-child': {
                padding: theme.spacing(0, 1, 0, 0),
            },
            '&:last-child': {
                paddingRight: 0,
                padding: theme.spacing(0, 0, 0, 1),
            },
        },
    },
    tile: {
        backgroundColor: '#FAF9F8',
        boxSizing: 'border-box',
        padding: theme.spacing(0.5, 1),
        borderRadius: theme.spacing(1),
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '100%',
        ...cssText(theme, 'standard', 'title_2'),
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(1),
            marginBottom: 0,
            flexFlow: 'column nowrap',
        },
        [theme.breakpoints.up('lg')]: {
            padding: theme.spacing(2),
        },
        '&:last-of-type': {
            [theme.breakpoints.up('sm')]: {
                marginRight: 0,
            },
        },
    },
    illu: {
        height: 80,
        width: 'auto',
        maxWidth: '100%',
    },

    content: {
        flex: '1 1 auto',
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        alignItems: 'center',
    },
    line: {
        display: 'block',
        textAlign: 'center',
    },
    label: {
        ...cssText(theme, 'standard', 'caption'),
        marginTop: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
        },
    },
    value: {
        ...cssText(theme, 'standard', 'body_thick', undefined, undefined, '#423F37'),
        display: 'flex',
        marginTop: theme.spacing(0.5),
    },
    inlineIcon: {
        display: 'inline',
    },
    labelError: {
        ...cssText(theme, 'standard', 'body_thick', undefined, undefined, '#C9310C'),
    },
}));

export function MySubscriptionTiles({
    subscription,
    locale,
    nextEvent,
    ...props
}: MySubscriptionTilesProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const {
        productReference,
        wallet,
        renewalPlannedAt,
        status,
        subscriptionCategory,
        subscriptionPrice,
        subscriptionCurrency,
    } = subscription;
    const monthlyAnnualStatus = [
        SubscriptionStatusEnum.Trialing,
        SubscriptionStatusEnum.Active,
        SubscriptionStatusEnum.Widthdrawal,
        SubscriptionStatusEnum.Pastdue,
        SubscriptionStatusEnum.Unpaid,
    ];
    const tiles = [
        {
            illustration: 'my_subscription_credits_remaining',
            label: t('my_subscription_credits_remaining', {count: wallet?.balance}),
            value: (
                <>
                    <DynamicIcon type={'token'} className={classes.inlineIcon} />
                    &nbsp;{wallet?.balance?.toString()}{' '}
                    {t('my_subscription_credits', {count: wallet?.balance})}
                </>
            ),
        },
        ...(((status !== SubscriptionStatusEnum.Termination || Date.now() < renewalPlannedAt) && [
            {
                illustration: `my_subscription_sub_${status}`,
                label: t(`my_subscription_sub_${status}`),
                // todo warning numberOfMonths is hardcoded
                value: (
                    ([SubscriptionStatusEnum.Pastdue, SubscriptionStatusEnum.Unpaid].indexOf(status) !== -1) && (
                        <>
                            <DynamicIcon width={18} type={'cross-badge'} className={classes.inlineIcon} />&nbsp;
                            <span className={classes.labelError}>{t(`my_subscription_${status}`)}</span>
                        </>
                    ) ||
                    (status === SubscriptionStatusEnum.Free) &&
                        t('my_subscription_number_of_months', {numberOfMonths: 12}) ||
                    (renewalPlannedAt && getLongDate(renewalPlannedAt, locale)) || ''
                ),
            },
        ]) ||
            []),
        // if subscription status is not free, add another tile
        ...((status !== SubscriptionStatusEnum.Free && [
            {
                illustration: `my_subscription_sub_type_${status}`,
                label: t(
                    `my_subscription_sub_type_${
                        (monthlyAnnualStatus.indexOf(status) !== -1 && productReference) || status
                    }`,
                ),
                value:
                    (monthlyAnnualStatus.indexOf(status) !== -1 &&
                        t(`my_subscription_sub_type_${subscriptionCategory}_pricing`, {
                            price: convertPrice(subscriptionPrice, subscriptionCurrency),
                        })) ||
                    (nextEvent && getLongDate(nextEvent.date, locale)),
            },
        ]) ||
            []),
    ];
    return (
        <Grid container className={classes.root} {...props}>
            {tiles.map((tile) => (
                <Grid
                    item
                    md={(12 / tiles.length) as GridSize}
                    sm={(12 / tiles.length) as GridSize}
                    xs={12}
                    key={tile.illustration}
                    classes={{root: classes.gridTile}}
                >
                    <div className={classes.tile}>
                        <Illustration
                            className={classes.illu}
                            name={tile.illustration}
                            objectFit={'contain'}
                            mt={1}
                        />
                        <div className={classes.content}>
                            <span className={clsx(classes.line, classes.label)}>{tile.label}</span>
                            <span className={clsx(classes.line, classes.value)}>{tile.value}</span>
                        </div>
                    </div>
                </Grid>
            ))}
        </Grid>
    );
}

export interface MySubscriptionTilesProps {
    subscription: subscription;
    nextEvent?: subscriptionPlannedEvents;
    locale: string;
}

export default MySubscriptionTiles;
