import React from 'react';
import {PushSticky} from '../../../ui';

export function PushStickyPushModule(props: PushStickyPushModuleProps) {
    return <PushSticky {...props} />;
}

export interface PushStickyPushModuleProps {}

export default PushStickyPushModule;
