import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import ShoppingCartNavBarItem from '../atoms/ShoppingCartNavBarItem';
import LoginButton from '../atoms/LoginButton';
import HorizontalMenu from './HorizontalMenu';
import SvgUser from '../images/icons/User';
import useLuniiUser from '../hooks/useLuniiUser';
import Clickable from '../Clickable';
import useHasMounted from '../hooks/useHasMounted';
import SvgLibrary from '../images/icons/Library';
import {NavBarRootModeEnum} from '../types';
import {SubscriptionNavBarItem} from './SubscriptionNavBarItem';
import {Img} from '../nucleons';
import SvgLogo from '../images/Logo';
import SvgWish from '../images/icons/Wish';
import {useLuniiPage} from '../hooks';

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        maxWidth: `calc(100vw - ${theme.spacing(8)}px)`,
        justifyContent: 'space-between',
    },
    left: {
        alignItems: 'center',
        display: 'flex',
        flexShrink: 0,
        position: 'relative',

        '& >*': {
            // Sets properties for the clickable wrapper around the logo
            height: 'fit-content',
            display: 'block',
        },

        '& img': {
            display: 'block',
            height: 40,
        },

        '& svg': {
            // ensures the clickable wrapper has the same size as the svg image inside
            height: 40,
            display: 'block',
        },
    },
    center: {
        flex: '1 2',
        display: 'flex',
        justifyContent: 'center',
        overflow: 'hidden',
    },
    right: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'right',
        flexShrink: 0,
    },
    buttons: {
        marginLeft: 24,
    },
    menu: {
        display: 'inline-block',
    },
    shadow: {
        height: 2,
        background: '#E4E1DA',
    },
    iconFirst: {
        marginLeft: 'unset',
    },
    searchContent: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
    },
    icons: {
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: theme.spacing(2),
        transition: 'background-color .35s ease-out',
        padding: theme.spacing(0.5),
        cursor: 'pointer',
        borderRadius: '100%',
        '@media not all and (hover: none)': {
            '&:hover': {
                backgroundColor: '#FEF0CE',
            },
        },
    },
    subscriptionIcon: {
        marginLeft: 0,
        '@media all': {
            '&:hover': {
                backgroundColor: 'unset',
            },
        },
    },
    shoppingCartIcon: {
        padding: 0,
        '@media all': {
            '&:hover': {
                backgroundColor: 'unset',
            },
        },
    },
    iconActive: {
        cursor: 'default !important',
        backgroundColor: '#FEF0CE',
    },
    clickableWrapper: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    leftIcon: {
        height: 40,
        width: 'auto',
    },
}));

export function DesktopNavBar({
    rootMode = NavBarRootModeEnum.Default,
    mode,
    menu,
    section,
    menuImage,
    onLogoClick,
    onUserClick,
    onLibraryClick,
    onWishlistClick,
    onSearchClick,
    onModeClose,
    page,
}: DesktopNavBarProps) {
    const classes = useStyles();
    const {isLogged} = useLuniiUser();
    const hasMounted = useHasMounted();
    const {locale} = useLuniiPage() || {};
    const filteredMenu = menu.filter((m: any) => {
        const excludedLocales = (m.excludedLocales || '').split(',');
        return excludedLocales.indexOf((locale || '').slice(-2)) === -1;
    });
    return (
        <>
            {mode !== 'search' && (
                <div className={classes.content}>
                    <div className={classes.left}>
                        <Clickable
                            onClick={onLogoClick}
                            className={classes.clickableWrapper}
                        >
                            {menuImage?.url ? (
                                <Img
                                    className={classes.leftIcon}
                                    {...menuImage}
                                    loading="eager"
                                />
                            ) : (
                                <SvgLogo />
                            )}
                        </Clickable>
                    </div>
                    <div className={classes.center}>
                        <div className={classes.menu}>
                            <HorizontalMenu
                                items={filteredMenu}
                                current={section}
                                currentPageUid={page?.uid}
                            />
                        </div>
                    </div>
                    <div className={classes.right}>
                        <div className={classes.icons}>
                            {/* <div className={clsx(classes.icon, classes.iconFirst)}><SvgSearch className={classes.searchIcon} onClick={onSearchClick} /></div> */}
                            <div className={clsx(classes.icon, classes.subscriptionIcon)}>
                                <SubscriptionNavBarItem />
                            </div>
                            <div
                                className={clsx(
                                    classes.icon,
                                    rootMode === NavBarRootModeEnum.Checkout &&
                                        classes.shoppingCartIcon,
                                )}
                            >
                                <ShoppingCartNavBarItem
                                    active={rootMode === NavBarRootModeEnum.Checkout}
                                />
                            </div>
                            {isLogged && hasMounted && (
                                <>
                                    <div
                                        className={clsx(
                                            classes.icon,
                                            rootMode === NavBarRootModeEnum.Library &&
                                                classes.iconActive,
                                        )}
                                    >
                                        <SvgLibrary
                                            onClick={
                                                rootMode === NavBarRootModeEnum.Library
                                                    ? undefined
                                                    : onLibraryClick
                                            }
                                        />
                                    </div>
                                    <div
                                        className={clsx(
                                            classes.icon,
                                            rootMode === NavBarRootModeEnum.Wishlist &&
                                                classes.iconActive,
                                        )}
                                    >
                                        <SvgWish
                                            onClick={
                                                rootMode === NavBarRootModeEnum.Wishlist
                                                    ? undefined
                                                    : onWishlistClick
                                            }
                                        />
                                    </div>
                                    <div
                                        className={clsx(
                                            classes.icon,
                                            rootMode === NavBarRootModeEnum.My &&
                                                classes.iconActive,
                                        )}
                                    >
                                        <SvgUser
                                            onClick={
                                                rootMode === NavBarRootModeEnum.My
                                                    ? undefined
                                                    : onUserClick
                                            }
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                        {(!isLogged || !hasMounted) && (
                            <div className={classes.buttons}>
                                <LoginButton />
                            </div>
                        )}
                    </div>
                </div>
            )}
            {/* ('search' === mode) && (
                <div className={classes.searchContent}>
                    <SearchBar placeholder={t('navbar_search_placeholder')} defaultFocus={true} onClear={onModeClose} />
                </div>
            ) */}
        </>
    );
}

export interface DesktopNavBarProps {
    mode?: any;
    menu?: any;
    menuImage?: any;
    section?: any;
    onLogoClick?: any;
    onUserClick?: any;
    onLibraryClick?: any;
    onWishlistClick?: any;
    onSearchClick?: any;
    onModeClose?: any;
    rootMode?: NavBarRootModeEnum;
    page?: any;
}

export default DesktopNavBar;
