import React, {useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {cssText} from '@ohoareau/css-utils';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {Trans} from 'react-i18next';
import clsx from 'clsx';
import {registerLocale} from 'react-datepicker';
import {addMonths} from 'date-fns';
import fr from 'date-fns/locale/fr';
import {
    Button,
    DateField,
    DynamicIcon,
    FieldSet,
    TextField,
    useLuniiTranslation,
    WarningPanel,
} from '../../../../ui';

const useStyles = makeStyles((theme) => ({
    root: {
        '& form': {
            '& >*:last-child': {
                marginBottom: 0,
            },
        },
    },
    fieldSetTitle: {
        ...cssText(theme, 'standard', 'body_thick', undefined, undefined, '#423F37'),
        '& span': {
            ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#716C5E'),
            marginTop: 8,
            display: 'block',
        },
    },
    field: {},
    fieldCharCount: {
        ...cssText(theme, 'standard', 'caption', undefined, undefined, '#908977'),
        width: '100%',
        textAlign: 'end',
    },
    textField: {
        position: 'relative',
        marginTop: 4,
        '&>div': {
            transition: 'background-color .35s easeout',
            willChange: 'background-color',
            backgroundColor: '#fff',
        },
    },
    dateField: {
        height: 59,
        '& .placeholder': {
            ...cssText(theme, 'standard', 'body_thick', undefined, undefined, '#908977'),
        },
        '&.active': {
            backgroundColor: '#F00',
            ...cssText(theme, 'standard', 'body_thick', undefined, undefined, '#2C3637'),
        },
        '& svg>*': {
            stroke: '#',
        },
    },
    dateFieldClear: {
        pointerEvents: 'none',
        position: 'absolute',
        right: '-8px',
        top: '50%',
        transform: 'translate3d(-100%, -50%, 0)',
        opacity: 0,
        transition: 'opacity .35s ease-out !important',
        padding: 12,
        minWidth: 24,
        boxShadow: 'none !important',
        color: '#2C3637',
        '&.show': {
            opacity: 1,
            pointerEvents: 'auto',
        },
    },
    dateFieldError: {
        width: '100%',
        backgroundColor: '#FEE9E4',
        boxShadox: '0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: theme.spacing(2),
        ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#C04020'),
        margin: theme.spacing(2, 0),
        padding: theme.spacing(2),
    },
    warningPanel: {
        width: '100%',
        margin: '40px 0 0',
        borderRadius: 16,
        padding: 8,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: '#FEE9E4 !important',
        color: '#C04020',
        '& :first-child': {
            flexShrink: 0,
            marginRight: 8,
        },
    },
}));

export interface SubscriptionGiftDeliveryEmailFormProps {
    className?: string;
}

export function SubscriptionGiftDeliveryEmailForm({
    className = '',
}: SubscriptionGiftDeliveryEmailFormProps) {
    const classes = useStyles();
    const {t, i18n} = useLuniiTranslation();
    const {register, errors, setValue, formState, watch} = useFormContext();
    const fieldFormInfos = {
        errors,
        register,
    };
    registerLocale('fr', fr);
    const message = watch('subscriptiongift_message');
    const now: Date = new Date();
    register('subscriptiongift_sending_date');
    const sendingDate = watch('subscriptiongift_sending_date');
    const [dateError, setDateError] = useState<any>(undefined);

    return (
        <div className={clsx(classes.root, className)}>
            <form>
                <FieldSet>
                    <div className={classes.fieldSetTitle}>
                        <Trans
                            i18n={i18n}
                            i18nKey="subscriptiongift_form_fieldset_title_reciever"
                        >
                            ...<span>...</span>...
                        </Trans>
                    </div>
                    <TextField
                        required
                        name="subscriptiongift_reciever_name"
                        className={clsx(
                            classes.textField,
                            formState.dirtyFields.subscriptiongift_reciever_name && 'dirty',
                        )}
                        definition={{
                            required: t('subscriptiongift_form_reciever_name_field_error'),
                        }}
                        {...fieldFormInfos}
                    />
                    <TextField
                        required
                        name="subscriptiongift_reciever_email"
                        className={clsx(
                            classes.textField,
                            formState.dirtyFields.subscriptiongift_reciever_email && 'dirty',
                        )}
                        definition={{
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: t('subscriptiongift_form_reciever_email_field_error'),
                            },
                            required: t('subscriptiongift_form_reciever_email_field_error'),
                        }}
                        {...fieldFormInfos}
                    />
                </FieldSet>
                <FieldSet>
                    <div className={classes.fieldSetTitle}>
                        <Trans
                            i18n={i18n}
                            i18nKey="subscriptiongift_form_fieldset_title_message"
                        >
                            ...<span>...</span>...
                        </Trans>
                    </div>
                    <TextField
                        name="subscriptiongift_message"
                        className={clsx(
                            classes.textField,
                            formState.dirtyFields.subscriptiongift_message && 'dirty',
                        )}
                        definition={{
                            maxLength: 200,
                            message: t('constraints_fahName'),
                        }}
                        minRows={2}
                        maxRows={10}
                        domInputProps={{
                            maxLength: 200,
                        }}
                        multiline
                        {...fieldFormInfos}
                    />
                    <div className={classes.fieldCharCount}>
                        {message?.length || 0}/200 {t('characters')}
                    </div>
                </FieldSet>
                <FieldSet>
                    <div className={classes.fieldSetTitle}>
                        <Trans
                            i18n={i18n}
                            i18nKey="subscriptiongift_form_fieldset_title_sender"
                        >
                            ...<span>...</span>...
                        </Trans>
                    </div>
                    <TextField
                        required
                        name="subscriptiongift_sender_name"
                        className={clsx(classes.textField)}
                        definition={{
                            required: t('subscriptiongift_form_sender_name_field_error'),
                        }}
                        {...fieldFormInfos}
                    />
                </FieldSet>
                <FieldSet>
                    <div className={classes.fieldSetTitle}>
                        <Trans
                            i18n={i18n}
                            i18nKey="subscriptiongift_form_fieldset_title_sendingdate"
                        >
                            ...<span>...</span>...
                        </Trans>
                    </div>
                    <div className={classes.textField}>
                        <DateField
                            name="subscriptiongift_sending_date"
                            locale="fr"
                            className={classes.dateField}
                            value={sendingDate}
                            onValueChange={(value: any) =>
                                setValue('subscriptiongift_sending_date', value)
                            }
                            minDate={now}
                            onError={setDateError}
                            maxDate={addMonths(now, 1)}
                        />
                        <Button
                            className={clsx(classes.dateFieldClear, sendingDate && 'show')}
                            startIcon={<DynamicIcon type="close" />}
                            onClick={() => {
                                setValue('subscriptiongift_sending_date', null);
                            }}
                            type="primary"
                        />
                        {dateError && (
                            <div className={classes.dateFieldError}>
                                {t(`date_field_error_giftcards_${dateError.toLowerCase()}`)}
                            </div>
                        )}
                    </div>
                </FieldSet>
            </form>
            {formState.isSubmitted && !formState.isValid && (
                <WarningPanel
                    type="danger"
                    className={classes.warningPanel}
                    transKey="subscriptiongift_select_choice_view_error"
                >
                    <DynamicIcon
                        type="warning"
                        height={24}
                        width={24}
                    />
                </WarningPanel>
            )}
        </div>
    );
}

export default SubscriptionGiftDeliveryEmailForm;
