import React from 'react';
import {cssText} from '@ohoareau/css-utils';
import clsx from 'clsx';
import makeStyles from "@material-ui/core/styles/makeStyles";
import useLuniiTranslation from "../../hooks/useLuniiTranslation";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    label: {
        ...cssText(theme, 'standard', 'caption'),
        color: "#908977"
    },
    placeholder: {
        ...cssText(theme, 'standard', 'body_thick'),
        color: "#2A2928"
    }
}));

export function UneditableTextField({className, name, label, placeholder, value}: UneditableTextFieldProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    label = label || t(`fields_${(name || '').toLowerCase()}_label`);
    return (
        <div className={clsx(classes.root, className)}>
            <span className={classes.label}>{label}</span>
            <div className={classes.placeholder}>{value || placeholder}</div>
        </div>
    );
}

export interface UneditableTextFieldProps {
    className?: string,
    name: string,
    value?: string,
    label?: string,
    placeholder?: string,
}

export default UneditableTextField
