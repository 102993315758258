import {LazyQueryHookOptions, LazyQueryResult} from '@apollo/client';
import {
    useLuniiApi,
    RETRIEVE_SUBSCRIPTION_PRODUCT,
    RETRIEVE_SUBSCRIPTION_PRODUCTS,
} from '../..';

interface useSubscriptionPurchaseOptions {
    retrieveProductsOptions?: LazyQueryHookOptions;
    retrieveProductOptions?: LazyQueryHookOptions;
}

export function useSubscriptionItems(
    {retrieveProductsOptions, retrieveProductOptions}: useSubscriptionPurchaseOptions = {
        retrieveProductsOptions: {},
        retrieveProductOptions: {},
    },
): [
    {
        retrieveSubscriptionProductsExecute: (
            options?: LazyQueryHookOptions,
        ) => Promise<LazyQueryResult<any, any>>;
        retrieveSubscriptionProductExecute: (
            options?: LazyQueryHookOptions,
        ) => Promise<LazyQueryResult<any, any>>;
    },
    {
        retrieveSubscriptionProductsResult: LazyQueryResult<any, any>;
        retrieveSubscriptionProductResult: LazyQueryResult<any, any>;
    },
] {
    const {gql, useLazyQuery} = useLuniiApi();
    const [retrieveSubscriptionProductsExecute, retrieveSubscriptionProductsResult] = useLazyQuery(
        RETRIEVE_SUBSCRIPTION_PRODUCTS(gql),
        {
            notifyOnNetworkStatusChange: true,
            ...retrieveProductsOptions,
        },
    );
    const [retrieveSubscriptionProductExecute, retrieveSubscriptionProductResult] = useLazyQuery(
        RETRIEVE_SUBSCRIPTION_PRODUCT(gql),
        {
            notifyOnNetworkStatusChange: true,
            ...retrieveProductOptions,
        },
    );

    return [
        {
            retrieveSubscriptionProductsExecute: retrieveSubscriptionProductsExecute as (
                options?: LazyQueryHookOptions,
            ) => Promise<LazyQueryResult<any, any>>,
            retrieveSubscriptionProductExecute: retrieveSubscriptionProductExecute as (
                options?: LazyQueryHookOptions,
            ) => Promise<LazyQueryResult<any, any>>,
        },
        {
            retrieveSubscriptionProductsResult: retrieveSubscriptionProductsResult as LazyQueryResult<
                any,
                any
            >,
            retrieveSubscriptionProductResult: retrieveSubscriptionProductResult as LazyQueryResult<
                any,
                any
            >,
        },
    ];
}

export default useSubscriptionItems;
