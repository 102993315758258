import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';
import {cssText} from '@ohoareau/css-utils';
import {useFormContext} from 'react-hook-form';
import {convertPrice, Img, useLuniiTranslation} from '../../ui';

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
    },
    container: {
        width: '100%',
        boxShadow: '0px 2px 8px rgba(66, 63, 55, 0.1)',
        borderRadius: theme.spacing(2.5),
        padding: theme.spacing(3),
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    title: {
        ...cssText(theme, 'standard', 'overline_title_2'),
        textTransform: 'uppercase',
        marginBottom: theme.spacing(0.5),
    },
    receiver: {
        ...cssText(theme, 'standard', 'title_2'),
        margin: theme.spacing(0.5, 0, 1, 0),
        display: 'flex',
        alignItems: 'flex-start',
        gap: theme.spacing(0, 1),
        justifyContent: 'space-between',
    },
    message: {
        ...cssText(theme, 'standard', 'body'),
        marginBottom: theme.spacing(1),
        wordWrap: 'break-word',
    },
    sender: {
        ...cssText(theme, 'standard', 'body_thick', undefined, undefined, '#716C5E'),
        margin: theme.spacing(0.5, 0, 1, 0),
        display: 'flex',
        alignItems: 'flex-start',
        gap: theme.spacing(0, 1),
        justifyContent: 'space-between',
    },
    image: {
        width: '100%',
        height: 'auto',
        borderRadius: theme.spacing(1.5),
        marginTop: theme.spacing(1),
    },
    left: {
        maxWidth: '70%',
        wordWrap: 'break-word',
        [theme.breakpoints.down('xs')]: {
            maxWidth: '60%',
        },
    },
    right: {
        maxWidth: '30%',
        wordWrap: 'break-word',
        [theme.breakpoints.down('xs')]: {
            maxWidth: '40%',
        },
    },
}));

export function GiftCardPreview({className = '', locale, currency}: GiftCardPreviewProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const {getValues} = useFormContext();
    const {
        giftCardTheme,
        giftCardAmount,
        giftCardReceiverName,
        giftCardMessage,
        giftCardSenderName,
        giftCardDate,
    } = getValues();
    const amount = giftCardAmount?.proposed || giftCardAmount?.custom;
    if (
        !giftCardReceiverName &&
        !giftCardMessage &&
        !giftCardSenderName &&
        !giftCardTheme &&
        !giftCardAmount?.proposed &&
        !giftCardAmount?.custom
    )
        return null;
    return (
        <div className={clsx(classes.root, className)}>
            <div className={classes.container}>
                <div className={classes.title}>{t('gift_cards_summary_title')}</div>
                <div className={classes.receiver}>
                    <div className={classes.left}>
                        {t('gift_cards_summary_receiver')}&nbsp;{giftCardReceiverName || '...'}
                    </div>
                    {amount && !isNaN(amount) && Number(amount) > 0 && (
                        <div className={classes.right}>{convertPrice(Math.round(amount * 100), currency)}</div>
                    )}
                </div>
                {giftCardMessage && <div className={classes.message}>{giftCardMessage}</div>}
                <div className={classes.sender}>
                    {giftCardSenderName && <div className={classes.left}>{giftCardSenderName}</div>}
                    {giftCardDate && (
                        <div className={classes.right}>
                            {new Intl.DateTimeFormat(locale).format(giftCardDate)}
                        </div>
                    )}
                </div>
                <Img
                    className={classes.image}
                    {...giftCardTheme?.image}
                    loading="eager"
                />
            </div>
        </div>
    );
}

export interface GiftCardPreviewProps {
    className?: string;
    locale: string;
    currency: string;
}

export default GiftCardPreview;
