import React, {useCallback, useState} from 'react';
import {cssText} from '@ohoareau/css-utils';
import Button from '../atoms/Button';
import SvgPlay from '../images/icons/Play';
import ReactPlayer from 'react-player';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Img from "../nucleons/Img";
import Row from "../Row";
import Grid from "@material-ui/core/Grid";
import useLuniiTranslation from "../hooks/useLuniiTranslation";
import {WithVideo} from "../types";

const useStyles = makeStyles(theme => ({
    root: {
        ...cssText(theme, 'standard', 'body'),
        position: 'relative',
    },
    video: {
        position: 'absolute',
        top: 0,
        height: '100%',
        width: '100%',
        backgroundColor: 'unset',
        display: 'flex',
        alignItems: 'center',
    },
    placeholderImage: {
        [theme.breakpoints.down('sm')]: {
            height: 540,
        },
    },
    image: {
        width: '90%',
        maxWidth: 200,
    },
    title: {
        ...cssText(theme, 'standard', 'extra_large_title'),
        marginBottom: 20,
        [theme.breakpoints.down('sm')]: {
            ...cssText(theme, 'standard', 'extra_large_title_mobile'),
        }
    },
}));

export function ImmersiveVideo({title, image, placeholderImage, url}: ImmersiveVideoProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const [showVideo, setShowVideo] = useState(false);
    const hideVideo = useCallback(() => setShowVideo(false), [setShowVideo]);
    if (!url) return null;
    return (
        <div className={classes.root}>
            <div className={classes.placeholderImage}>
                <Img {...placeholderImage} objectFit={'cover'} />
            </div>
            {!showVideo && (
                <Row rootClassName={classes.video} justify={'flex-start'}>
                    <Grid item container lg={12} md={12} xs={12} direction={'column'}>
                        <div>
                            <div className={classes.image}>
                                <Img {...image} />
                            </div>
                            <div className={classes.title}>{title}</div>
                            <Button onClick={setShowVideo} startIcon={<SvgPlay />} color={'primary'}>
                                {t('module_video_immersive_button_label') || ''}
                            </Button>
                        </div>
                    </Grid>
                </Row>
            )}
            {showVideo && (
                <div className={classes.video}>
                    <ReactPlayer url={url} width={'100%'} height={'100%'} playing={true} controls onEnded={hideVideo} />
                </div>
            )}
        </div>
    );
}

export interface ImmersiveVideoProps extends WithVideo {
}

export default ImmersiveVideo
