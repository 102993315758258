import React from 'react';
import {cssBox, cssText} from '@ohoareau/css-utils';
import buttonify from '../hocs/buttonify';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    root: {
        ...cssBox(theme, undefined, 'clear'),
        width: '100%',
    },
    align_left: {
        textAlign: 'left',
    },
    align_center: {
        textAlign: 'center',
    },
    align_right: {
        textAlign: 'right',
    },
    align_justify: {
        textAlign: 'justify',
    },
    title: {
        ...cssText(theme, 'standard', 'large_title', undefined),
        marginBottom: (props: TextParagraphProps) =>
            props.text && !props.label ? theme.spacing(2) : undefined,
        [theme.breakpoints.down('sm')]: {
            ...cssText(theme, 'standard', 'title_1'),
            marginBottom: (props: TextParagraphProps) =>
                props.text && !props.label ? theme.spacing(1) : undefined,
        },
    },
    subtitle: {
        ...cssText(theme, 'standard', 'title_1', undefined),
    },
    text: {
        ...cssText(theme, 'standard', 'body', undefined),
        display: 'inline-flex',
    },
    textCta: {
        ...cssText(theme, 'standard', 'body', undefined, undefined, '#2762E9'),
        display: 'inline-flex',
    },
    label: {
        ...cssText(theme, 'standard', 'title_2', undefined),
        marginBottom: (props: TextParagraphProps) =>
            props.text || props.buttons || props.buttonsComponent ? theme.spacing(2) : undefined,
    },
    overline: {
        ...cssText(theme, 'standard', `overline_large_title`, undefined),
        [theme.breakpoints.down('sm')]: {
            ...cssText(theme, 'standard', 'overline_title_1'),
        },
    },
    buttons: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        marginTop: (props: TextParagraphProps) =>
            props.buttonsComponent || (props.buttons && props.buttons.length)
                ? theme.spacing(2)
                : 'unset',
        '& > *': {
            margin: (props: TextParagraphProps) =>
                props.buttonsComponent ? theme.spacing(1, 1, 0, 0) : 'unset',
        },
        '& > :last-child': {
            marginRight: 'unset',
        },
    },
    button: {
        marginRight: (props: TextParagraphProps) =>
            props.buttons && props.buttons.length > 1 ? theme.spacing(3) : 'unset',
    },
    footerText: {
        ...cssText(theme, 'standard', 'body', undefined),
        marginTop: theme.spacing(3),
    },
    texts: {},
}));

export function TextParagraph({
    className,
    overline,
    title,
    label,
    subtitle,
    text,
    textCta,
    textCtaLink,
    footerText,
    align = 'left',
    buttonsClassName,
    buttons = [],
    buttonsComponent: Buttons,
}: TextParagraphProps) {
    const classes = useStyles({text, label, buttonsComponent: Buttons, buttons});
    return (
        <div className={clsx(classes.root, classes[`align_${align}`], className)}>
            <div className={classes.texts}>
                {overline && <div className={classes.overline}>{overline}</div>}
                {title && <div className={classes.title}>{title}</div>}
                {label && <div className={classes.label}>{label}</div>}
                {subtitle && <div className={classes.subtitle}>{subtitle}</div>}
                {text && <div className={classes.text}>{text}</div>}
                {text && textCta && ' '}
                {textCta && (
                    <a
                        className={classes.textCta}
                        href={textCtaLink}
                    >
                        {textCta}
                    </a>
                )}
            </div>
            <div className={clsx(classes.buttons, buttonsClassName)}>
                {(buttons || [])
                    .map((b, i) => {
                        const Button = buttonify(b.label, b.type, b.target, {
                            type: 'color=primary',
                        });
                        return Button ? (
                            <Button
                                className={classes.button}
                                key={i}
                                variant={b.variant || 'contained'}
                            />
                        ) : undefined;
                    })
                    .filter((x) => !!x)}
                {Buttons && <Buttons />}
            </div>
            {footerText && <div className={classes.footerText}>{footerText}</div>}
        </div>
    );
}

export interface TextParagraphProps {
    className?: any;
    buttonsClassName?: any;
    overline?: any;
    title?: any;
    subtitle?: any;
    label?: any;
    text?: any;
    textCta?: any;
    textCtaLink?: any;
    footerText?: any;
    align?: 'left' | 'right' | 'center' | 'justify';
    buttons?: {
        label?: string;
        type?: 'primary' | 'secondary' | 'tertiary' | 'default';
        variant?: 'contained' | 'outlined' | 'text';
        target?: any;
    }[];
    buttonsComponent?: any;
}

export default TextParagraph;
