import React from 'react';
import ArgumentBlock from '../molecules/ArgumentBlock';
import ItemSlideshow1 from '../molecules/ItemSlideshow1';
import clsx from 'clsx';
import {extractButtonsProps} from "../hocs/buttonify";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Row from "../Row";
import Grid from "@material-ui/core/Grid";
import {module_text, module_image_position, WithItems, WithButtons} from "../types";

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
    },
    container: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    right: {
        flexDirection: 'row-reverse',
    },
    asideContent: {
        flex: 1,
    },
    textContent: {
        flex: 1.5,
        position: 'relative',
    },
    slideshow: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 60,
        [theme.breakpoints.down('md')]: {
            bottom: 48,
        },
        [theme.breakpoints.down('sm')]: {
            bottom: 44,
        },
    },
}));

export function ArgumentModuleSlideshow({text, items = [], imagePosition = 'left', ...props}: ArgumentModuleSlideshowProps) {
    const classes = useStyles();
    const {buttonsProps} = extractButtonsProps(props);
    return (
        <Row className={classes.root} padding={'default--tablet-as-mobile'} margins={imagePosition === 'left' ? 'right-except-mobile' : 'left-except-mobile'}>
            <Grid item container lg={12} md={12} xs={12}>
                <div className={clsx(classes.container, (imagePosition === 'right' && classes.right))}>
                    <div className={classes.asideContent}>
                    </div>
                    <div className={classes.textContent}>
                        <ArgumentBlock text={text} noImage={true} {...buttonsProps} />
                    </div>
                </div>
            </Grid>
            <div className={classes.slideshow}>
                <ItemSlideshow1 items={items} desktopItems={5.5} tabletItems={5.4} mobileItems={3.8}/>
            </div>
        </Row>
    );
}

export interface ArgumentModuleSlideshowProps extends WithItems, WithButtons {
    text?: module_text,
    imagePosition?: module_image_position,
}

export default ArgumentModuleSlideshow
