import {useCallback} from 'react';
import useLuniiUserRegisterApi from './useLuniiUserRegisterApi';
import useLuniiEventTracking from "./useLuniiEventTracking";

export function useRegisterSubmit(callback: Function|undefined) {
    const [register, {loading, error, ...infos}] = useLuniiUserRegisterApi();
    const handleEventTracking = useLuniiEventTracking();
    const onSubmit = useCallback(async values => {
        try {
            const data = await register({
                variables: {
                    data: {
                        email: values.email,
                        password: values.password,
                        country: values.country,
                        newsletterSubscribed: values.newsletter_subscribed
                    },
                },
            });

            handleEventTracking({categorie: 'interaction', action: 'inscription', libelle: 'compte'});

            callback && callback(((data || {}).data || {}).register);
        } catch (e) {
            console.error(e);
        }
    }, [register, callback, handleEventTracking]);
    return [onSubmit, {loading: Boolean(loading), error: error || false, ...infos}] as [any, any];
}

export default useRegisterSubmit
