import React from 'react';
import {cssText} from '@ohoareau/css-utils';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {Modal} from '../../organisms';
import {useLuniiEventTracking, useLuniiTranslation, useLuniiUser} from '../../hooks';
import {buttonifyFromProps} from '../../hocs';
import clsx from 'clsx';
import {ErrorPanel} from '../../atoms';
import useSubscriptionCancel from '../../hooks/subscription/useSubscriptionCancel';

const useStyles = makeStyles((theme) => ({
    root: {},
    body: {
        ...cssText(theme, 'standard', 'body', undefined, undefined, '#716C5E'),
        [theme.breakpoints.down('sm')]: {
            ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#716C5E'),
        },
    },
}));

export function RetractModal({
    className,
    open = false,
    onClose = () => {},
    onConfirm = () => {},
    productReference,
    locale,
    ...props
}: RetractModalProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const handleTrackingEvent = useLuniiEventTracking();
    const {user} = useLuniiUser();
    const [execute, {loading, error}] = useSubscriptionCancel({
        options: {
            onCompleted: () => {
                handleTrackingEvent({
                    categorie: 'abonnement',
                    action: 'retractation',
                    libelle: productReference === 'monthly_subscription' ? 'mensuel' : 'annuel',
                });
                onConfirm();
            },
        },
    });
    const handleAction = () => execute({variables: {data: {email: user?.email}}});
    const {Buttons} = buttonifyFromProps(
        {
            buttonLabel: t(`my_subscription_retract_modal_negative`),
            buttonTarget: onClose,
            button2Label: t(`my_subscription_retract_modal_positive`),
            button2Target: handleAction,
        },
        [`plain,disabled=${loading}`, `destructive,disabled=${loading}`],
    );
    const body = (
        <div className={classes.body}>
            {t('my_subscription_retract_modal_body')}
            {error && <ErrorPanel error={error} key={'subscription'} />}
        </div>
    );
    return (
        <>
            <Modal
                open={open}
                className={clsx(classes.root, className)}
                onCloseCallback={onClose}
                title={t('my_subscription_retract_modal_title')}
                body={body}
                loading={loading}
                actions={Buttons && <Buttons />}
            />
        </>
    );
}

export interface RetractModalProps {
    className?: string;
    open?: boolean;
    onClose?: any;
    onConfirm?: any;
    productReference?: string;
    locale: string;
}

export default RetractModal;
