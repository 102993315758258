import React from 'react';
import {
    cssClickBox,
    cssClickBoxClicked,
    cssClickBoxDisabled,
    cssClickBoxHover,
} from '@ohoareau/css-utils';
import {LinkProps as MuiLinkProps} from '@material-ui/core/Link/Link';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import withStyles from '@material-ui/core/styles/withStyles';
import {useTheme} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        textDecoration: 'unset',
        '&:hover': {
            textDecoration: 'unset',
            cursor: 'pointer',
        },
        transition: 'color .35s ease-out, background-color .35s ease-out, box-shadow .35s ease-out',
    },
    smallMobileOnly: {
        [theme.breakpoints.down('sm')]: {
            padding: '5.5px 16px',
        },
    },
    disabled: {
        pointerEvents: 'none',
        cursor: 'not-allowed',
        textDecoration: 'none',
    },
}));

const useStyles2 = makeStyles({
    root: {
        textDecoration: 'unset',
        '&:hover': {
            textDecoration: 'unset',
            cursor: 'pointer',
        },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'color .35s ease-out, background-color .35s ease-out, box-shadow .35s ease-out',
    },
    startIcon: {
        display: 'flex',
        alignItems: 'center',
        marginRight: 0,
        marginTop: -1.5,
        marginBottom: -1.5,
    },
    startIconLabel: {
        marginRight: 4,
    },
    endIcon: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 0,
        marginTop: -1.5,
        marginBottom: -1.5,
    },
    endIconLabel: {
        marginLeft: 4,
    },
});

export const linkify = ({
    size = 'large',
    component: ForcedComponent = undefined,
    componentProps = {},
    color,
}: {
    size?: string;
    component?: any;
    componentProps?: any;
    color;
}) => {
    const k = size === 'small' ? 'button_2' : 'button_1';
    const tertiartyAlertButtonCss = {
        boxShadow: 'none',
        color: '#f25129',
        '&:hover': {
            textDecoration: 'underline',
            backgroundColor: 'unset',
            color: '#fc7958',
        },
    };
    return withStyles((theme) => ({
        root: {
            ...cssClickBox(theme, k, 'buttons'),
            marginRight: 4,
            [theme.breakpoints.down('sm')]: {
                ...cssClickBox(theme, size === 'small_mobile_only' ? 'button_2' : k, 'buttons'),
                ...(color === 'tertiary_alert' && {...tertiartyAlertButtonCss}),
                ...(size === 'small_mobile_only' && {padding: '5.5px 16px'})
            },
            ...(color === 'tertiary_alert' && {...tertiartyAlertButtonCss}),
            transition:
                'color .35s ease-out, background-color .35s ease-out, box-shadow .35s ease-out',
            padding: size === 'small' ? '5.5px 16px' : '12px 24px',

        },
        disabled: {
            ...cssClickBoxDisabled(theme, k),
            [theme.breakpoints.down('sm')]: {
                ...cssClickBoxDisabled(theme, size === 'small_mobile_only' ? 'button_2' : k),
            },
        },
        hover: {
            ...cssClickBoxHover(theme, k),
            [theme.breakpoints.down('sm')]: {
                ...cssClickBoxHover(theme, size === 'small_mobile_only' ? 'button_2' : k),
            },
        },
        clicked: {
            ...cssClickBoxClicked(theme, k, 'buttons'),
            [theme.breakpoints.down('sm')]: {
                ...cssClickBoxClicked(
                    theme,
                    size === 'small_mobile_only' ? 'button_2' : k,
                    'buttons',
                ),
            },
        },
    }))(
        ({
            classes,
            active,
            disabled,
            className,
            hover,
            clicked,
            color,
            children,
            linkComponent: LinkComponent,
            to,
            href,
            onClick,
            icon,
            startIcon,
            endIcon,
            noPadding = false,
            ...props
        }: LinkButtonProps) => {
            const theme = useTheme();
            const hasLabel = !!children;
            const extraClasses = useStyles();
            const contentClasses = useStyles2({hasLabel});
            const {
                disabled: disabledClass,
                hover: hoverClass,
                clicked: clickedClass,
                ...keptClasses
            } = classes;
            const Component = ForcedComponent || LinkComponent;
            startIcon = startIcon || icon;
            const {type} = componentProps || {};
            const style: any = {};
            if (disabled || (color === 'plain' && !hover && !clicked) || color === 'destructive')
                style.boxShadow = 'none';
            if (noPadding) style.padding = 0;
            return (
                <Component
                    classes={keptClasses}
                    size={size}
                    className={clsx(
                        className,
                        extraClasses.root,
                        disabled && disabledClass,
                        hover && hoverClass,
                        clicked && clickedClass,
                        size === 'small' && extraClasses.small,
                        size === 'small_mobile_only' && extraClasses.smallMobileOnly,
                    )}
                    style={style}
                    {...(type === 'external'
                        ? {
                              target: '_blank',
                              rel: `noopener noreferrer ${
                                  href?.match(/.+\.(pdf|jpg|png)$/i) ? 'nofollow' : ''
                              }`,
                          }
                        : {})}
                    {...(to ? {to} : {})}
                    {...(onClick ? {onClick} : {})}
                    {...(href ? {href} : {})}
                    {...props}
                    {...componentProps}
                >
                    <div className={contentClasses.root}>
                        {startIcon && (
                            <div
                                className={clsx(
                                    contentClasses.startIcon,
                                    hasLabel && contentClasses.startIconLabel,
                                )}
                            >
                                {startIcon}
                            </div>
                        )}
                        {children || ''}
                        {endIcon && (
                            <div
                                className={clsx(
                                    contentClasses.endIcon,
                                    hasLabel && contentClasses.endIconLabel,
                                )}
                            >
                                {endIcon}
                            </div>
                        )}
                    </div>
                </Component>
            );
        },
    );
};

export interface LinkButtonProps extends Omit<MuiLinkProps, 'color'> {
    classes?: any;
    t?: Function;
    active?: boolean;
    tReady?: boolean;
    i18n?: any;
    disabled?: boolean;
    color?:
        | 'default'
        | 'primary'
        | 'secondary'
        | 'tertiary'
        | 'plain'
        | 'destructive'
        | 'tertiary_alert';
    hover?: boolean;
    clicked?: boolean;
    icon?: any;
    startIcon?: any;
    endIcon?: any;
    size?: 'default' | 'large' | 'small' | 'small_mobile_only';
    linkComponent: any;
    href?: string;
    to?: string;
    noPadding?: boolean;
}

export default linkify;
