import React from 'react';
import SelectField, {SelectFieldProps} from './SelectField';

export function StateField({options, defaultValue = '', required, ...props}: StateFieldProps) {
    return (
        <SelectField
            name={'state'}
            options={options}
            defaultValue={undefined}
            required={required}
            {...props}
        />
    );
}

export interface StateFieldProps extends SelectFieldProps {}

export default StateField;
