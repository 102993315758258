import {useCallback} from 'react';
import useLuniiShoppingCartDiscountCodeActivateApi from './useLuniiShoppingCartDiscountCodeActivateApi';

export function useShoppingCartDiscountCodeActivateSubmit(callback: Function|undefined = undefined) {
    const [activate, {loading, error, ...infos}] = useLuniiShoppingCartDiscountCodeActivateApi();
    const onSubmit = useCallback(async values => {
        const promoCode = (values?.promoCode || '').trim();
        if (promoCode.length > 0) {
            try {
                const data = await activate({
                    variables: {
                        data: {
                            code: promoCode,
                        },
                    },
                });
                callback && callback(((data || {}).data || {}).activateCartDiscountCode);
            } catch (e) {
                console.error(e);
            }
        }
    }, [activate, callback]);
    return [onSubmit, {loading: Boolean(loading), error: error || false, ...infos}] as [any, any];
}

export default useShoppingCartDiscountCodeActivateSubmit;
