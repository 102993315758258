import {useCallback} from 'react';
import {cart, useLuniiSpa} from '../ui';

export function useFreePayment(createPayment: Function): Function[] {
    const useCreatePayment = useCallback<(purchaseId: string) => any>(
        (purchaseId: string) =>
            // past this line the remote cart has been deleted and any action concerning the cart must be done using the local one
            createPayment({
                purchase: purchaseId,
            }),

        [],
    );

    const {navigate} = useLuniiSpa();
    const onSuccess = useCallback(
        async (confirmationHistoryState: {cart: cart; purchaseId: string}) => {
            await navigate('/confirmation', {state: confirmationHistoryState});
        },
        [navigate],
    );

    const useValidatePayment = useCallback(
        async (purchaseId: string, cart: cart, payment, onError: Function) => {
            if (payment.success) {
                const confirmationHistoryState = {
                    cart,
                    purchaseId,
                };

                await onSuccess(confirmationHistoryState);
            } else {
                await onError();
            }
        },
        [onSuccess],
    );

    return [useCreatePayment, useValidatePayment];
}

export default useFreePayment;
