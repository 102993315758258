import React, {ReactNode} from 'react';
import ElevatedPanel from './ElevatedPanel';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
    root: {
        boxShadow: '0px -2px 8px rgba(66, 63, 55, 0.02), 0px 4px 8px rgba(66, 63, 55, 0.1)',
    },
});

/**
 * @deprecated use ElevatedPanel component with the `lower` prop to achieve the same effect
 * @param className
 * @param children
 * @constructor
 */
export function ShadowedPanel({className, children}: ShadowedPanelProps) {
    const classes = useStyles();
    return (
        <ElevatedPanel className={clsx(classes.root, className)}>
            {children || ''}
        </ElevatedPanel>
    );
}

export interface ShadowedPanelProps {
    className?: string;
    children?: ReactNode;
}

export default ShadowedPanel;
