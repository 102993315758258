import React from 'react';
import TextField, {TextFieldProps} from './TextField';

export function FirstNameField(props: FirstNameFieldProps) {
    return (
        <TextField name={'firstName'} {...props} />
    );
}

export interface FirstNameFieldProps extends TextFieldProps {}

export default FirstNameField