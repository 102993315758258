import React from 'react';
import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';
import SvgAdd from '../images/icons/Add';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        '& > *': {
            marginRight: theme.spacing(1),
        },
    },
}));

export function StripePaymentImages({className, props}: any) {
    const classes = useStyles();
    return (
        <div className={clsx(classes.root, className)}>
            <SvgAdd height={24} width={24} {...props}/>
        </div>
    );
}

export default StripePaymentImages;
