import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import {cssText} from '@ohoareau/css-utils';
import {DynamicIcon} from '../../../ui';
import {getThemeColor} from '../../../../configs';
import {tag_theme} from '../../../types';

const useStyles = makeStyles((theme) =>({
    root: {
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 8,
    },
    info: {
        backgroundColor: getThemeColor('brand-secondary', '100'),
        color: getThemeColor('brand-secondary', '800'),
    },
    tips: {
        backgroundColor: getThemeColor('brand-primary', '100'),
        color: getThemeColor('brand-primary', '800'),
    },
    success: {
        backgroundColor: getThemeColor('positive', '100'),
        color: getThemeColor('positive', '800'),
    },
    error: {
        backgroundColor: getThemeColor('danger', '100'),
        color: getThemeColor('danger', '800'),
    },
    neutral: {
        backgroundColor: getThemeColor('ui', 'white'),
        color: getThemeColor('ui', '800'),
    },
    flam: {
        backgroundColor: getThemeColor('brand-flam', '100'),
        color: getThemeColor('brand-flam', '800'),
    },
    mediumRoot: {
        height: 40,
        padding: '0px 16px 0px 16px',
    },
    smallRoot: {
        height: 34,
        padding: '0px 12px 0px 12px',
    },
    xsmallRoot: {
        height: 26,
        padding: '0px 8px 0px 8px',
    },
    mediumIconRoot: {
        padding: '0px 16px 0px 12px',
    },
    smallIconRoot: {
        padding: '0px 12px 0px 10px',
    },
    xsmallIconRoot: {
        padding: '0px 8px 0px 6px',
    },
    label: {
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': 1,
        overflow: 'hidden',
    },
    mediumLabel: {
        ...cssText(theme, 'standard', 'button_2', undefined, undefined, 'inherit'),
        textTransform: 'none',
    },
    smallLabel: {
        ...cssText(theme, 'standard', 'button_3', undefined, undefined, 'inherit'),
        textTransform: 'none',
    },
    xsmallLabel: {
        ...cssText(theme, 'standard', 'button_3', undefined, undefined, 'inherit'),
        textTransform: 'none',
    },
    mediumSvg: {
        marginRight: 8,
    },
    smallSvg: {
        marginRight: 4,
    },
    xsmallSvg: {
        marginRight: 4,
    }
}));

export function Tag({className = '', theme = 'info', size = 'medium', icon = undefined, label = undefined}: TagProps) {
    const classes = useStyles();
    const width = size === 'medium' && 24 || 16;
    return (
        <div className={clsx(classes.root, classes[theme], classes[`${size}Root`], icon?.length && classes[`${size}IconRoot`], className)}>
            {icon?.length && <DynamicIcon type={`${icon}-${width}`} className={clsx(label && classes[`${size}Svg`])}/> || false  /* only show if icon is given */}
            {label && <div className={clsx(classes.label, classes[`${size}Label`])}>{label}</div> || false}
        </div>
    );
}

export interface TagProps {
    className?: any;
    theme?: tag_theme;
    size?: 'medium' | 'small' | 'xsmall';
    icon?: any;
    label?: string;
}

export default Tag;
