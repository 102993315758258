import React from 'react';

export function StandardShareModule(props: StandardShareModuleProps) {
    return (
        <>TODO SHARE (STANDARD)</>
    )
}

export interface StandardShareModuleProps {
}

export default StandardShareModule