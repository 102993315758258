import {createContext, Reducer, Dispatch} from 'react';
import {
    SubscriptionGiftMetadata,
    SubscriptionGiftProduct,
    SubscriptionSubmitOptionsEnum,
} from '../definitions/subscriptionGift';

export interface SubscriptionGiftCheckoutState {
    selected: {
        product: SubscriptionGiftProduct | undefined;
        submitMode: SubscriptionSubmitOptionsEnum | undefined;
        metadata: SubscriptionGiftMetadata | undefined;
    };
    success: boolean;
}

export type SubsctiptionGiftCheckoutContextValue = {
    state: SubscriptionGiftCheckoutState;
    reducer: Reducer<any, any>;
};

export enum SubscriptionGiftCheckoutActionType {
    UPDATE_SUBMIT_MODE = 'update_submit_mode',
    UPDATE_METADATA = 'update_metadata',
    CHECKOUT_SUCCESS = 'checkout_success',
    UPDATE_SELECTED_PRODUCT = 'update_selected_product',
}

export type SubscriptionGiftCheckoutAction =
    {
        type: SubscriptionGiftCheckoutActionType.UPDATE_METADATA;
        payload: {
            metadata: SubscriptionGiftMetadata;
        };
    } |
    {
        type: SubscriptionGiftCheckoutActionType.UPDATE_SELECTED_PRODUCT;
        payload: {
            product: SubscriptionGiftProduct;
        };
    } |
    {
        type: SubscriptionGiftCheckoutActionType.UPDATE_SUBMIT_MODE;
        payload: {
            submitMode: SubscriptionSubmitOptionsEnum;
        };
    } |
    { type: SubscriptionGiftCheckoutActionType.CHECKOUT_SUCCESS };

export type SubscriptionGiftCheckoutContext = [
    state: SubscriptionGiftCheckoutState,
    dispatch: Dispatch<SubscriptionGiftCheckoutAction>
];

const createDefaultLuniiSubscriptionGiftCheckoutContextValue = (
    defaultValue: SubsctiptionGiftCheckoutContextValue = {
        state: {
            selected: {
                product: undefined,
                submitMode: undefined,
                metadata: undefined,
            },
            success: false,
        },
        reducer: () => undefined,
    },
): SubscriptionGiftCheckoutContext => ([
    defaultValue.state,
    () => null,
]);

export const LuniiSubscriptionGiftCheckoutContext = createContext<SubscriptionGiftCheckoutContext>(
    createDefaultLuniiSubscriptionGiftCheckoutContextValue(),
);
export const LuniiSubscriptionGiftCheckoutProvider = LuniiSubscriptionGiftCheckoutContext.Provider;
export const LuniiSubscriptionGiftCheckoutConsumer = LuniiSubscriptionGiftCheckoutContext.Consumer;
LuniiSubscriptionGiftCheckoutContext.displayName = 'LuniiSubscriptionGiftCheckoutContext';

export default LuniiSubscriptionGiftCheckoutContext;

export function subscriptionGiftCheckoutReducer (
    state: SubscriptionGiftCheckoutState,
    action: SubscriptionGiftCheckoutAction,
) {
    switch (action.type) {
        case SubscriptionGiftCheckoutActionType.UPDATE_SUBMIT_MODE:
            return {
                ...state,
                selected: {
                    ...state.selected,
                    submitMode: action.payload.submitMode,
                },
            };
        case SubscriptionGiftCheckoutActionType.UPDATE_METADATA:
            return {
                ...state,
                selected: {
                    ...state.selected,
                    metadata: action.payload.metadata,
                },
            };
        case SubscriptionGiftCheckoutActionType.CHECKOUT_SUCCESS:
            return {
                ...state,
                success: true,
            };
        case SubscriptionGiftCheckoutActionType.UPDATE_SELECTED_PRODUCT:
            return {
                ...state,
                selected: {
                    ...state.selected,
                    product: action.payload.product,
                },
            };
        default:
            return state;
    }
}
