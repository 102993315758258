import React from 'react';
import {cssText} from '@ohoareau/css-utils';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    root: {
        ...cssText(theme, 'standard', 'overline_title_1'),
        padding: theme.spacing(0.5, 1),
        borderRadius: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            ...cssText(theme, 'standard', 'overline_title_2'),
        },
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        height: '24px',
        position: 'relative',
        '& >*': {
            height: '100%',
            width: 'auto',
        },
    },
    variant_alert: {
        color: '#5A1605',
        backgroundColor: '#F5E5E4',
    },
    variant_positive: {
        color: '#157B5C',
        backgroundColor: '#E7FFF8',
    },
    variant_default: {
        color: '#0C4745',
        backgroundColor: '#D6F3F2',
    },
}));

export function StatusTag({
    label,
    icon,
    variant = 'default',
    labelClassName = '',
    className = '',
}: StatusTagProps) {
    const classes = useStyles();
    return (
        <div
            className={clsx(
                classes.root,
                classes[`variant_${variant}`] || classes[`variant_default`],
                className,
            )}
        >
            {!!icon && <div className={classes.icon}>{icon}</div>}
            <div className={labelClassName}>{`${icon ? '\u00A0' : ''}${label}`}</div>
        </div>
    );
}

export interface StatusTagProps {
    label?: string;
    variant?: 'message' | 'alert' | 'positive' | 'default';
    icon?: React.ReactNode;
    labelClassName?: string;
    className?: string;
}

export default StatusTag;
