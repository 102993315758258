import {MutationHookOptions} from '@apollo/client';
import {CANCEL_SUBSCRIPTION, useLuniiApi, mutationInfos} from '../..';

interface useSubscriptionCancelOptions {
    options?: MutationHookOptions;
}

export default function useSubscriptionCancel({options}: useSubscriptionCancelOptions = {}): [execute: Function, result: mutationInfos] {
    const {gql, useMutation} = useLuniiApi();
    const [execute, result] = useMutation(CANCEL_SUBSCRIPTION(gql), {
        notifyOnNetworkStatusChange: true,
        ...options,
    });
    return [execute, result];
}
