import React from 'react';
import TextField, {TextFieldProps} from './TextField';

export function CardOwnerField(props: CardOwnerFieldProps) {
    return (
        <TextField name={'cardOwner'} {...props} />
    );
}

export interface CardOwnerFieldProps extends TextFieldProps {}

export default CardOwnerField
