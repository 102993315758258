import * as React from 'react';
function SvgBackward(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width={props.width || 24}
            height={props.height || 24}
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.707 6.707a1 1 0 00-1.414-1.414l-6 6a1 1 0 000 1.414l6 6a1 1 0 001.414-1.414L12.414 12l5.293-5.293zM16 11a1 1 0 100 2 1 1 0 000-2zM7 5a1 1 0 011 1v12a1 1 0 11-2 0V6a1 1 0 011-1z"
                fill={props.color || 'currentColor'}
            />
        </svg>
    );
}
export default SvgBackward;
