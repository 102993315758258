import React from 'react';
import {PicturesMosaic} from '../../../ui';
import useLuniiItemsTracking from '../../../hooks/useItemsTracking';

export function MosaicCardSmallItemsModule({items, itemsAreTracked, ...props}: any) {
    const itemsTracked = useLuniiItemsTracking(itemsAreTracked, items);

    return (
        <PicturesMosaic
            items={itemsTracked}
            {...props}
        />
    );
}

export default MosaicCardSmallItemsModule;
