import React from 'react';
import clsx from 'clsx';
import {cssText} from '@ohoareau/css-utils';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MuiTextField from '@material-ui/core/TextField';
import {useLuniiTranslation} from '../../hooks';
import CircularProgress from '@material-ui/core/CircularProgress';

const useInputStyles = makeStyles({
    root: {
        color: '#423F37 !important',
        backgroundColor: '#FAF9F8',
        padding: '16px 0px 0px 0px',
        borderRadius: 8,
        '& fieldset': {
            borderRadius: 8,
            borderColor: '#D6D3CA !important',
            borderWidth: 2,
        },
    },
    disabled: {
        cursor: 'not-allowed',
        '& fieldset': {
            borderColor: '#D6D3CA !important',
            backgroundColor: '#D6D3CA',
            zIndex: -1,
        },
    },
    error: {
        '& fieldset': {
            borderColor: '#F25129 !important',
        },
        '& input': {
            color: '#F25129 !important',
        },
    },
    focused: {
        '& fieldset': {
            borderColor: '#FBBD2A !important',
        },
    },
    input: {
        borderRadius: 8,
        paddingRight: 20,
    },
    inputAdornedEnd: {
        color: '#7C878B',
        marginRight: 20,
    },
});

const useInputInputStyles = makeStyles(theme => ({
    root: {
        ...cssText(theme, 'standard', 'body_thick', undefined, undefined, '#423f37'),
        borderRadius: 8,
        '&::placeholder': {
            opacity: 1,
        },
        '&:disabled': {
            color: '#908977 !important',
        },
    },
    rootDisabled: {
        color: '#908977 !important',
    },
    multiline: {
        margin: '14px 0 0 14px',
    },
}));

const useInputLabelStyles = makeStyles(theme => ({
    root: {
        ...cssText(theme, 'standard', 'body_thick', undefined, undefined, '#423f37'),
        color: '#908977 !important',
        marginTop: 8,
    },
    error: {
        color: '#F25129 !important',
    },
    focused: {
    },
    shrink: {
        marginTop: 14,
    },
}));

const useFormHelperTextStyles = makeStyles({
    root: {
        marginLeft: 0,
    },
    error: {
        color: '#F25129 important',
    },
    disabled: {
    },
    focused: {
    },
});

const useSelectStyles = makeStyles({
    root: {
    },
    select: {
        '&:focus': {
            backgroundColor: 'unset',
        },
    },
});

export function TextField({className, inputClassName, domInputProps, InputProps, name, subName, definition, register, label, placeholder, errors, helperText, product, loader = false, autoComplete = undefined, multiline, ...props}: TextFieldProps) {
    const {t} = useLuniiTranslation();
    const i18nPrefix = `fields_${(name || '').toLowerCase()}${subName ? `_${subName}` : ''}`;
    label = t([label || '', `${i18nPrefix}_label`, label || '']);
    placeholder = t([placeholder || '', `${i18nPrefix}_placeholder`, placeholder || '']);
    const inputInputStyles = useInputInputStyles();
    const inputInputProps = {className: clsx(inputInputStyles['root'], props.disabled && inputInputStyles['rootDisabled'], multiline && inputInputStyles['multiline'], inputClassName), autoComplete, ...domInputProps};
    const inputProps = {classes: useInputStyles(), /* disableUnderline: true, <= seems to be buggy, console warning */notched: false, ...InputProps};
    const inputLabelProps = {classes: useInputLabelStyles()};
    const formHelperTextProps = {classes: useFormHelperTextStyles()};
    const selectProps = {classes: useSelectStyles()};
    const error = !!(errors && name && errors[name] && (!subName || errors[name][subName]));
    helperText = error
        ? t([(subName && errors[name] && errors[name][subName].message) || errors[name].message || 'error'])
        : t([helperText || '', `${i18nPrefix}_helper`, helperText || '']);
    const _name = subName ? `${name}.${subName}` : name;
    const inputClasses = useInputStyles();
    return (
        <>
            <MuiTextField name={_name} label={label} placeholder={placeholder}
                fullWidth
                size={'small'}
                margin={'dense'}
                variant={'outlined'}
                FormHelperTextProps={formHelperTextProps}
                InputLabelProps={inputLabelProps}
                inputProps={inputInputProps}
                InputProps={{...inputProps, ...(loader && {endAdornment: <div className={inputClasses.inputAdornedEnd}><CircularProgress size={24} color={'inherit'} /></div>})}}
                SelectProps={selectProps}
                error={error}
                helperText={helperText}
                className={className}
                multiline={multiline}
                inputRef={register && !props.inputRef
                    ? register({
                        required: props.required ? t('field_required_error') : undefined,
                        ...(definition || {}),
                    })
                    : undefined
                }
                {...props}
            />
        </>
    );
}

export interface TextFieldProps {
    className?: string,
    inputClassName?: string,
    autoComplete?: string,
    InputProps?: any,
    errors?: any,
    helperText?: any,
    product?: any,
    subName?: any,
    loader?: boolean,
    [key: string]: any,
}

export default TextField;
