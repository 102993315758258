import React from 'react';
import TextField, {TextFieldProps} from './TextField';
import useLuniiTranslation from '../../hooks/useLuniiTranslation';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {cssText} from '@ohoareau/css-utils';

const useStyles = makeStyles(theme => ({
    fieldWrapper: {
        width: '100%',
    },
    input: {
        width: '100%',
    },
    charCount: {
        display: 'block',
        width: '100%',
        textAlign: 'right',
        ...cssText(theme, 'standard', 'caption'),
        margin: theme.spacing(.5, 0, 0, 0),
    },
}));

export function DeviceNameField(props: DeviceNameFieldProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const {charCount, ...cleanedProps} = props;
    return (
        <div className={classes.fieldWrapper}>
            <TextField
                inputClassName={classes.input}
                name={'deviceName'}
                fulllwidth={'true'}
                definition={{
                    minLength: 1,
                    maxLength: 40,
                    message: t('constraints_fahName'),
                }}
                domInputProps={{
                    maxLength: 40,
                }}
                {...cleanedProps}/>
            <span className={classes.charCount}>{charCount}/40 {t('char_count')}</span>
        </div>
    );
}

export interface DeviceNameFieldProps extends TextFieldProps {
    charCount: number,
}

export default DeviceNameField;
