export function matchLocaleWithCurrency(locale: string = ''): string {
    const countryCode = locale.split('-')[1];
    switch (countryCode) {
        case 'us':
            return 'USD';
        case 'ca':
            return 'CAD';
        default:
            return 'EUR';
    }
}
