import convertClickFunction from '../utils/convertClickFunction';
import useLuniiProduct from './useLuniiProduct';
import useShoppingCart from './useShoppingCart';
import useLuniiPage from './useLuniiPage';

export function useOnClick(v, submit = false) {
    const product = useLuniiProduct();
    const cartHelpers = useShoppingCart();
    const page = useLuniiPage();
    if (submit) return {type: 'submit'};
    if (!v || typeof v === 'function') return {type: 'action', onClick: v || (() => {})};
    if (typeof v === 'string') {
        v = {type: 'url', url: v};
    }
    if (typeof v !== 'object') return {type: 'action', onClick: () => {}};
    switch (v.type) {
        case 'url':
            if (v.url.slice(0, 14) === 'https://lunii/') return convertClickFunction(v.url.slice(14), {product, cartHelpers, page});
            if (/^http[s]?:\/\//.test(v.url)) return {type: 'external', href: v.url, rel: 'noopener noreferrer', target: '_blank'};
            return {type: 'internal', to: v.url};
        case 'link':
            return {type: 'action', onClick: v.factory()};
        default:
            return {type: 'action', onClick: () => {}};
    }
}

export default useOnClick;
