import React, {useMemo} from 'react';
import {cssShadow, cssText} from '@ohoareau/css-utils';
import {buttonifyFromProps} from '../hocs';
import Clickable from '../Clickable';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {Img} from '../nucleons';
import {convertPrice} from '../utils';
import {useLuniiTranslation} from '../hooks';
import {LuniiProductProvider} from '../contexts';

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
        paddingBottom: theme.spacing(2),
    },
    contentTop: {
        position: 'relative',
        width: '100%',
    },
    clickable: {
        maxWidth: '100%',
    },
    imageFrame: {
        backgroundColor: 'white',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: 320,
        width: 267,
        boxSizing: 'border-box',
        padding: 16,
        borderRadius: 16,
        ...cssShadow(theme, 'level_02'),
        [theme.breakpoints.down('md')]: {
            height: 257,
            width: 216,
        },

        [theme.breakpoints.down('sm')]: {
            height: 190,
            width: 160,
        },
    },
    image: {
        width: '100%',
    },
    infos: {
        marginTop: '16px',
    },
    title: {
        ...cssText(theme, 'standard', 'title_2'),
    },
    label: {
        ...cssText(theme, 'standard', 'body'),
    },
    button: {
        alignSelf: 'flex-start',
        marginTop: 10,
    },
    priceContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    oldPrice: {
        ...cssText(theme, 'standard', 'body', undefined, undefined, '#A79F8E'),
        textDecoration: 'line-through',
        marginRight: 10,
    },
    reduction: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexWrap: 'wrap',
        margin: '4px 0',
    },
    reductionPercentage: {
        position: 'relative',
        ...cssText(theme, 'standard', 'secondary_body_thick', undefined, undefined, '#722613'),
        padding: '2px 10px 2px 7px',
        backgroundColor: '#FDB19E',
        clipPath: 'polygon(0 0, 0 100%, 85% 100%, 102% 50%, 85% 0)',
        borderRadius: 5,
        margin: '4px 0 2px 0',
    },
    reductionOldPrice: {
        ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#A79F8E'),
        textDecoration: 'line-through',
        marginLeft: 8,
        margin: '4px 0 2px 0',
    },
    outOfStock: {
        marginTop: theme.spacing(1),
        ...cssText(theme, 'standard', 'caption', undefined, undefined, 'status_alert'),
    },
}));

export function ItemCarrousselSlide({
    product,
    title,
    label,
    image,
    imageLoading,
    buttonLabel,
    buttonTarget,
    button2Target,
    buttonType,
    ...props
}: ItemCarrousselSlideProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();

    const buttonifyProps = useMemo(() => {
        if (buttonLabel && buttonTarget) {
            return {
                buttonTarget,
                buttonLabel,
                buttonType: buttonType ? `${buttonType},size=small` : 'plain,size=small,endIcon=next'
            }
        } else if (product) {
            return {
                buttonTarget: product.stock && product.buttonTarget,
                buttonLabel: product.stock
                    ? product.price === 0
                        ? t('product_price_free')
                        : convertPrice(product.price, product.currency)
                    : undefined,
                buttonType: 'primary,size=small,startIcon=cart'
            }
        } else {
            return {}
        }
    }, [buttonLabel, buttonTarget, buttonType, product, t]);
    const {Button} = buttonifyFromProps(buttonifyProps, [])
    if (product && product.notFound) return null; // hide module if product is defined but not found (prismic deoptimization)
    const _product = product || {};
    const _img = image || _product.image;
    const _title = title || _product.name;
    const _target = button2Target || _product.target;
    const _alt = (_img && _img.alt) || _product.name;
    const showOutOfStock = (!buttonLabel || !buttonTarget) && product && !product.stock;
    return (
        <LuniiProductProvider value={product}>
            <div className={classes.content}>
                <Clickable onClick={_target} className={classes.clickable}>
                    <div className={classes.contentTop}>
                        <div className={classes.imageFrame}>
                            {_img && (
                                <Img
                                    className={classes.image}
                                    objectFit={'contain'}
                                    {..._img}
                                    loading={imageLoading}
                                    alt={_alt}
                                />
                            )}
                        </div>
                        <div className={classes.infos}>
                            <div className={classes.title}>{_title}</div>
                            <div className={classes.label}>{label}</div>
                            {product &&
                                product.stock &&
                                product.reduction &&
                                (!buttonLabel || !buttonTarget) && (
                                    <div className={classes.reduction}>
                                        <div className={classes.reductionPercentage}>
                                            {product.reduction}
                                        </div>
                                        <div className={classes.reductionOldPrice}>
                                            {convertPrice(product.oldPrice, product.currency)}
                                        </div>
                                    </div>
                                )}
                        </div>
                    </div>
                </Clickable>
                {Button && <Button className={classes.button} />}
                {showOutOfStock && (
                    <div className={classes.outOfStock}>{t('product_out_of_stock')}</div>
                )}
            </div>
        </LuniiProductProvider>
    );
}

export interface ItemCarrousselSlideProps {
    product?: any;
    title?: any;
    buttonLabel?: string;
    buttonTarget?: string;
    button2Target?: string; // temporary used as target
    buttonType?: string;
    label?: any;
    image?: any;
    imageLoading?: 'eager' | 'lazy';
}

export default ItemCarrousselSlide;
