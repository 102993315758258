import * as React from 'react';
function SvgRefresh(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width={props.width || 24}
            height={props.height || 24}
            {...props}
        >
            <path
                fill="none"
                d="M0 0h24v24H0z"
            />
            <path
                d="M15 8.562l4.521.097.94-4.47"
                stroke={props.color || 'currentColor'}
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M20.5 15a.5.5 0 11-1 0 .5.5 0 011 0z"
                fill="#D8D8D8"
                stroke={props.color || 'currentColor'}
            />
            <path
                d="M17.255 18.032A8 8 0 1118.62 7.508"
                stroke={props.color || 'currentColor'}
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
export default SvgRefresh;
