import React from 'react';
import Clickable from '../Clickable';
import DynamicIcon from '../nucleons/DynamicIcon';
import DropDown from './DropDown';
import useLuniiTranslation from '../hooks/useLuniiTranslation';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {useLuniiEventTracking} from '../hooks';
import {cssText} from '@ohoareau/css-utils';

const useStyles = makeStyles(theme => ({
    item: {
        display: 'flex',
        ...cssText(theme, 'standard', 'button_2'),
        justifyContent: 'center',
        alignItems: 'center',
    },
    choicesSizing: {
        maxHeight: 330,
        overflow: 'auto',
        [theme.breakpoints.down('sm')]: {
            maxHeight: 300,
            maxWidth: 300,
        },
    },
    icon: {
        marginRight: theme.spacing(.5),
    },
}));

export function FooterLanguageSelector({languages = [], currentLanguage, defaultOpened = false}: FooterLanguageSelectorProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const handleEventTracking = useLuniiEventTracking();
    let currentCountry = languages.find((language) => language.id === currentLanguage);
    if (!currentCountry) {
        if (!languages.length) return null;
        currentCountry = {id: 'xx-fr', target: '/', label: t('countries_unknown_label') || ''};
    }

    if (languages.length <= 1 ) {
        return null;
    }

    const currentChoice = (
        <>
            <DynamicIcon type={`flag-${currentCountry.id.substr(3)}`} id={currentCountry.id} width={26} height={26} className={classes.icon}/>
            <div>{currentCountry.label}</div>
        </>
    );

    const choices = languages.map(({id, target, label}, index) => (
        <Clickable key={`langItem-${index}`} onClick={target} noMapping onClickCb={() => handleEventTracking({categorie: 'navigation', action: 'menu', libelle: target})} className={classes.item}>
            <DynamicIcon type={`flag-${id.substr(3)}`} id={id} width={26} height={26} className={classes.icon}/>
            <div>{label}</div>
        </Clickable>
    ));

    return (
        <DropDown currentChoice={currentChoice} choices={choices} rounded={true} big={true} defaultOpened={defaultOpened} choicesClassName={classes.choicesSizing} />
    );
}

export interface FooterLanguageSelectorProps {
    defaultOpened?: boolean,
    currentLanguage: string,
    languages: langItem[]
}

export type langItem = {
    id: string,
    label: any,
    target: any,
}

export default FooterLanguageSelector;
