import React, {useCallback, useState} from 'react';
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {cssBgColor, cssText} from '@ohoareau/css-utils';
import clsx from 'clsx';
import {IconButton} from '@material-ui/core';
import {
    useHasMounted,
    useLuniiEventTracking,
    useLuniiNavigation,
    useLuniiPage,
    useLuniiUser,
} from '../hooks';
import {availableForSubscription} from '../../../configs/site';
import SvgToken from '../images/icons/Token';
import {SubscriptionWidget} from './index';
import StatusTag from '../atoms/StatusTag';
import Clickable from '../Clickable';
import {SubscriptionStatusEnum} from '../types';

const useStyles = makeStyles((theme) => ({
    '@keyframes bounce': {
        '0%': {transform: 'translateY(0)'},
        '2%': {transform: 'translateY(-9px)'},
        '4%': {transform: 'translateY(0)'},
        '6%': {transform: 'translateY(-9px)'},
        '8%': {transform: 'translateY(0)'},
        '10%': {transform: 'translateY(-9px)'},
        '12%': {transform: 'translateY(0)'},
        '100%': {transform: 'translateY(0)'},
    },
    root: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
    },
    content: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        cursor: 'pointer',
    },
    status: {
        padding: theme.spacing(0.5, 1, 0.3),
    },
    alert: {
        color: '#7F1F08',
    },
    statusLabel_default: {
        ...cssText(theme, 'standard', 'button_1', undefined, undefined, '#0C4745'),
    },
    statusLabel_alert: {
        ...cssText(theme, 'standard', 'button_1', undefined, undefined, '#7F1F08'),
    },
    opened: {
        backgroundColor: '#FEF0CE',
    },
    disabled: {
        cursor: 'default !important',
    },
    cart: {
        marginTop: 8,
    },
    badge: {
        ...cssBgColor(theme, 'primary'),
        cursor: 'pointer',
    },
    popper: {
        marginTop: theme.spacing(1),
    },
    coin: {
        animation: `$bounce 15s ease 6`,
        padding: theme.spacing(1 / 2),
        '&:hover': {
            animation: 'none',
            backgroundColor: '#FEF0CE',
        },
    },
}));

export function SubscriptionNavBarItem({
    defaultOpened = false,
    active = false,
}: SubscriptionNavbarItemProps) {
    const classes = useStyles();
    const handleTrackingEvent = useLuniiEventTracking();
    const [anchorEl, setAnchorEl] = useState(null);
    const [opened, setOpened] = useState(defaultOpened);
    const {user} = useLuniiUser();
    const hasMounted = useHasMounted();
    const {goPageByModel} = useLuniiNavigation();
    const page: any = useLuniiPage();
    const {locale, pagesByModel} = page;
    const handleToggleOpened = useCallback(
        (event) => {
            if (active) {
                return;
            }
            setOpened(!opened);
            setAnchorEl(anchorEl ? null : event.currentTarget);
            !opened &&
                handleTrackingEvent({
                    categorie: 'navigation',
                    action: 'menu',
                    libelle: 'abonnement',
                });
        },
        [active, opened, setOpened, setAnchorEl, anchorEl],
    );

    const handleClickCoinIcon = () => {
        handleTrackingEvent({
            categorie: 'navigation',
            action: 'menu',
            libelle: `/${locale}/${pagesByModel?.abonnement_presentation?.[locale] || ''}/`,
        });
        goPageByModel && goPageByModel('abonnement_presentation');
    };

    const handleClickAway = useCallback(() => {
        setOpened(false);
        setAnchorEl(null);
    }, [setOpened]);
    const open = Boolean(anchorEl);
    const id = open ? 'transitions-popper-subscription' : undefined;

    if (!availableForSubscription(user?.locale || locale)) return null;

    if (!hasMounted) return null;

    if (!user || !user.subscription || Object.keys(user.subscription || {}).length === 0)
        return (
            <div className={classes.root}>
                <div className={clsx(classes.content, active && classes.disabled)}>
                    <IconButton
                        className={classes.coin}
                        onClick={handleClickCoinIcon}
                    >
                        <SvgToken />
                    </IconButton>
                </div>
            </div>
        );

    const {subscription} = user;
    const balance = user.subscription.wallet?.balance || 0;

    const messageVariant =
        (([
            SubscriptionStatusEnum.Termination,
            SubscriptionStatusEnum.Pastdue,
            SubscriptionStatusEnum.Unpaid,
        ].indexOf(subscription.status) !== -1 ||
            balance === 0) &&
            'alert') ||
        'default';

    return (
        <div className={classes.root}>
            <ClickAwayListener onClickAway={handleClickAway}>
                <div className={clsx(classes.content, active && classes.disabled)}>
                    <Clickable onClick={handleToggleOpened}>
                        <StatusTag
                            className={clsx(
                                classes.status,
                                messageVariant === 'alert' && classes.alert,
                            )}
                            labelClassName={clsx(classes[`statusLabel_${messageVariant}`])}
                            variant={messageVariant}
                            icon={<SvgToken aria-describedby={id} />}
                            label={`${user?.subscription?.wallet?.balance}`}
                        />
                    </Clickable>
                    <Popper
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        transition
                        style={{zIndex: 1200}}
                    >
                        {({TransitionProps}) => (
                            <Fade
                                {...TransitionProps}
                                timeout={350}
                            >
                                <div className={classes.popper}>
                                    <SubscriptionWidget subscription={user.subscription!!} />
                                </div>
                            </Fade>
                        )}
                    </Popper>
                </div>
            </ClickAwayListener>
        </div>
    );
}

export interface SubscriptionNavbarItemProps {
    defaultOpened?: boolean;
    active?: boolean; // allows to set active mode and to disable click
}

export default SubscriptionNavBarItem;
