import {red} from '@material-ui/core/colors';
import {createTheme} from '@material-ui/core/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#FBBD2A',
        },
        secondary: {
            main: '#2663E9',
        },
        error: {
            main: red.A400,
        },
        background: {
            default: '#FFFFFF',
        },
        action: {
            active: '#20272C',
        },
    },
    overrides: {
        MuiButton: {
            root: {
                padding: '12px 24px',
            },
            sizeSmall: {
                padding: '5.5px 16px',
            },
        },
    },
    props: {
        MuiButtonBase: {
            disableRipple: true,
            disableTouchRipple: true,
        },
        MuiIconButton: {
            disableRipple: true,
            disableFocusRipple: true,
            disableTouchRipple: true,
        },
    },
});

export default theme;
