import React, {useMemo} from 'react';

export function Spacers({variant = 'default', size = 1, unitSize = 4}) {
    const styles = useMemo(() => {
        const styles = {};
        switch (variant) {
            case 'vertical':
                styles['display'] = 'inline-block';
                styles['width'] = size * unitSize;
                styles['height'] = '100%';
                break;
            default:
            case 'default':
            case 'horizontal':
                styles['height'] = size * unitSize;
                break;
        }
        return styles;
    }, [variant, size, unitSize]);

    return (
        <div style={styles} />
    );
}

export interface SpacersProps {
    size?: number,
    unitSize?: number,
    variant?: 'horizontal' | 'vertical' | 'default'
}

export default Spacers