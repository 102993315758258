import React, {useEffect, useState} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {cssText} from '@ohoareau/css-utils';
import clsx from 'clsx';
import {convertPrice, digital_album_product, useHasMounted, useLuniiNavigation, useLuniiPage,
    useLuniiTranslation, useLuniiUser, useShoppingCart, WishlistToggle} from '../../../ui';
import {Button, Tag, ProductSubscriptionModal} from '.';
import {useDeleteUserWishlist, usePostUserWishlist} from '../../../hooks';
import {availableForSubscription} from '../../../../configs/site';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    reduction: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.spacing(2),
    },
    reductionLabel: {
        ...cssText(theme, 'standard', 'secondary_body_thick', undefined, undefined, '#9C2609'),
        marginBottom: 4,
    },
    reductionInfos: {
        display: 'flex',
        alignItems: 'center',
    },
    reductionPrice: {
        ...cssText(theme, 'standard', 'title_2', undefined, undefined, '#2C3637'),
    },
    reductionOldPrice: {
        ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#7C878B'),
        textDecoration: 'line-through',
        marginLeft: theme.spacing(1),

    },
    actions: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        '&>:first-child': {
            flex: 1,
        }
    },
    wishlist: {
        marginLeft: theme.spacing(2),
    },
    subscription: {
        '&>:first-child': {
            width: '100%',
            marginBottom: theme.spacing(1),
        }
    }
}));

export function AudiobookProductButton({className = '', product = undefined, wishlist = [], onWishlistChangeCallback = () => {}}: AudiobookProductButtonProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const [{packInCart}] = useShoppingCart();
    const {goCheckout} = useLuniiNavigation();
    const {user, ownsPackReference} = useLuniiUser();
    const hasMounted = useHasMounted();
    const {...p} = useLuniiPage();
    const [isInWishlist, setIsInWishlist] = useState<boolean>(false);
    useEffect(() => setIsInWishlist(!!wishlist.find((audiobook: any) => audiobook.reference === product?.reference)),[wishlist, product?.reference])
    const [addToUserWishlist] = usePostUserWishlist(() => {
        setIsInWishlist(true);
        onWishlistChangeCallback({added: true});
    });
    const [deleteFromUserWishlist] = useDeleteUserWishlist(() => {
        setIsInWishlist(false);
        onWishlistChangeCallback({deleted: true});
    });
    const [openSubscriptionModal, setOpenSubscriptionModal] = useState<boolean>(false);
    if (!product || !hasMounted) return null;
    const {price, currency, reduction, oldPrice} = product;
    const subscriptionBalance = user?.subscription?.wallet?.balance || 0;
    const addToCartProps: any = {
        text: `${t('product_add_to_cart')} ${price === 0 || price === undefined ? ` - ${t('product_price_free')}` : convertPrice(price, currency)}`,
        url: `https://lunii/add_to_cart?forceAdd=true&forcedQuantity=${1}`,
        icon: 'basket',
    };
    const inCartProps: any = {
        text: t(`cartmodal_go_cart_button_label`),
        url: goCheckout as any,
        theme: 'tertiary',
        icon: 'next',
        iconPosition: 'right',
    };
    const useCreditsProps: any = {
        text: t(`product_open_subscription_modal`, {
            credit: 1,
        }),
        url: () => setOpenSubscriptionModal(true),
        icon: 'token',
    };
    const isInCart = packInCart(product);
    const isInUserLibrary = ownsPackReference(product.reference);
    const showSubscription = user?.subscription && availableForSubscription(p?.locale) && product.price && product.canPurchaseWithSubscription; // if free, do not show
    const handleWishlistClick = () => {
        isInWishlist ? deleteFromUserWishlist({audiobookId: product.objectId, locale: p?.locale}) : addToUserWishlist({audiobookId: product.objectId, locale: p?.locale});
    }
    return (
        <div className={clsx(classes.root, className)}>
            {(reduction && !isInCart && !isInUserLibrary) && <div className={classes.reduction}>
                <div className={classes.reductionLabel}>{t('product_reduction_content')} {reduction}</div>
                <div className={classes.reductionInfos}>
                    <div className={classes.reductionPrice}>{convertPrice(price, currency)}</div>
                    <div className={classes.reductionOldPrice}>{convertPrice(oldPrice, currency)}</div>
                </div>
            </div> || false}
            {/* basic case : not in user library, not in cart => add to cart button */}
            {!isInUserLibrary && !isInCart && !showSubscription && (
                <div className={classes.actions}>
                    <Button {...addToCartProps} disabled={isInCart}/>
                    {user && <WishlistToggle className={classes.wishlist} toggled={isInWishlist} onClick={handleWishlistClick}/>|| false}
                </div>
            ) || false}
            {/* specific case : in cart => redirection to cart screen */}
            {!isInUserLibrary && isInCart && !showSubscription && (
                <div className={classes.actions}>
                    <Button {...inCartProps}/>
                    {user && <WishlistToggle className={classes.wishlist} toggled={isInWishlist} onClick={handleWishlistClick}/>|| false}
                </div>
            ) || false}
            { /* specific case : in user library => owned message */}
            {isInUserLibrary && <Tag theme='success' label={t('product_owned')}/> || false}
            { /* specific case : user has subscription => use credits button */ }
            {!isInUserLibrary && showSubscription && (
                <div className={classes.subscription}>
                    <Button {...useCreditsProps} disabled={subscriptionBalance <= 0}/>
                    <div className={classes.actions}>
                        {!isInCart && <Button {...addToCartProps} theme='secondary' disabled={isInCart}/> || false}
                        {isInCart && <Button {...inCartProps}/> || false}
                        {user && <WishlistToggle className={classes.wishlist} toggled={isInWishlist} onClick={handleWishlistClick}/>|| false}
                    </div>
                </div>
            ) || false}
            <ProductSubscriptionModal product={product} open={openSubscriptionModal} onClose={() => setOpenSubscriptionModal(false)}/>
        </div>
    );
}

export interface AudiobookProductButtonProps {
    className?: string;
    product?: digital_album_product;
    wishlist?: any;
    onWishlistChangeCallback?: Function;
}

export default AudiobookProductButton;
