import * as React from 'react';
function SvgCheck(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width={props.width || 24}
            height={props.height || 24}
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21 6a1 1 0 100-2 1 1 0 000 2zm-2.26 2.673a1 1 0 10-1.48-1.346L7.966 17.551l-4.259-4.258a1 1 0 00-1.414 1.414l5 5a1 1 0 001.447-.034l10-11z"
                fill={props.color || 'currentColor'}
            />
        </svg>
    );
}
export default SvgCheck;
