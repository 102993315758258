import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';
import {cssText} from '@ohoareau/css-utils';
import {item_metadata, useLuniiPage, useLuniiTranslation, useLuniiUser} from '../../ui';

const useStyles = makeStyles((theme) => ({
    root: {},
    content: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
        margin: theme.spacing(0.5, 0),
    },
    info: {
        ...cssText(theme, 'standard', 'body_thick', undefined, undefined, '#716C5E'),
        marginRight: 5,
    },
    subInfo: {
        ...cssText(theme, 'standard', 'body_thick', undefined, undefined, '#908977'),
    },
    body: {
        ...cssText(theme, 'standard', 'body', undefined, undefined, '#908977'),
    },
}));

export function GiftCardMetadata({
    className = '',
    metadata: {sender, receiver, sending_date, message},
}: GiftCardMetadataProps) {
    const classes = useStyles();
    const {user} = useLuniiUser();
    const {...p} = useLuniiPage();
    const {t} = useLuniiTranslation();
    return (
        <div className={clsx(classes.root, className)}>
            <div className={classes.content}>
                <span className={classes.info}>{t('gift_cards_gift_in_cart_for')} : </span>
                <div>
                    <span className={classes.subInfo}>{receiver?.name} </span>
                    {receiver?.email && <span className={classes.body}>({receiver?.email})</span>}
                </div>
            </div>
            {sender?.name && (
                <div className={classes.content}>
                    <span className={classes.info}>{t('gift_cards_gift_in_cart_from')} : </span>
                    <div>
                        <span className={classes.subInfo}>{sender?.name} </span>
                        {user?.email && <span className={classes.body}>({user?.email})</span>}
                    </div>
                </div>
            )}
            {message && (
                <div className={classes.content}>
                    <span className={classes.info}>{t('gift_cards_gift_in_cart_message')} : </span>
                    <span className={classes.body}>{message}</span>
                </div>
            )}
            <div className={classes.content}>
                <span className={classes.info}>{t('gift_cards_gift_in_cart_date')} : </span>
                <span className={classes.body}>
                    {new Intl.DateTimeFormat(p?.locale).format(sending_date)}
                </span>
            </div>
        </div>
    );
}

export interface GiftCardMetadataProps {
    className?: string;
    metadata: item_metadata;
}

export default GiftCardMetadata;
