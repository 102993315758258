import * as React from 'react';
function SvgArrowRight(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width={props.width || 24}
            height={props.height || 24}
            {...props}
        >
            <path
                fill="none"
                d="M0 0h24v24H0z"
            />
            <path
                d="M9 6l6 6-6 6"
                stroke={props.color || 'currentColor'}
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <rect
                x={9}
                y={11}
                width={2}
                height={2}
                rx={1}
                fill={props.color || 'currentColor'}
            />
            <path
                fill="#F8FCFF"
                fillOpacity={0.01}
                d="M0 0h24v24H0z"
            />
        </svg>
    );
}
export default SvgArrowRight;
