import React from 'react';
import SvgCardPaypal from '../images/icons/CardPaypal';
import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        '& > *': {
            marginRight: theme.spacing(1),
        },
    },
}));

export function PaypalPaymentImages({className}: any) {
    const classes = useStyles();
    return (
        <div className={clsx(classes.root, className)}>
            <SvgCardPaypal height={24} width={24}/>
        </div>
    );
}

export default PaypalPaymentImages;
