import useLuniiMutationApi from '../useLuniiMutationApi';
import {useCallback} from 'react';

export function useFahNameUpdateSubmit(callback: Function | undefined = () => {}) {
    const [updateFahName, {loading, error, ...infos}] = useLuniiMutationApi('UPDATE_FAH_NAME');
    const onSubmit = useCallback(async (fahId, fahName) => {
        try {
            const response = await updateFahName({
                variables: {
                    data: {
                        fahId,
                        fahName,
                    },
                },
            });
            const data = response?.data?.updateFahName || {};
            callback && callback(data);
            return data;
        } catch (e) {
            // console.error(e);
        }
    }, [updateFahName, callback]);
    return [
        onSubmit,
        {
            loading: Boolean(loading),
            error: error || false,
            ...infos,
        },
    ] as [(fahId: string, fahName: string) => Promise<any>, any];
}

export default useFahNameUpdateSubmit;
