import React, {createContext, Reducer, Dispatch, ReactNode} from 'react';

export interface SubscriptionCheckoutState {
    selected: any,
    modal: {
        open: boolean,
        title: ReactNode | null,
        body: ReactNode | null,
        actions: ReactNode | null,
    },
    success: boolean
}

export type SubsctiptionCheckoutContextValue = {
    state: SubscriptionCheckoutState,
    reducer: Reducer<any, any>
}

export enum SubscriptionCheckoutActionType {
    UPDATE_SELECTED_SUBSCRIPTION = 'update_selected_subscription',
    OPEN_MODAL = 'open_modal',
    CLOSE_MODAL = 'close_modal',
    CHECKOUT_SUCCESS = 'checkout_success',
}
export type SubscriptionCheckoutAction =
    | {type: SubscriptionCheckoutActionType.UPDATE_SELECTED_SUBSCRIPTION, payload: { selected: SubscriptionCheckoutState['selected'] }}
    | {type: SubscriptionCheckoutActionType.OPEN_MODAL, payload: {modal: SubscriptionCheckoutState['modal']}}
    | {type: SubscriptionCheckoutActionType.CLOSE_MODAL}
    | {type: SubscriptionCheckoutActionType.CHECKOUT_SUCCESS}

export type SubscriptionCheckoutContext = {state: SubscriptionCheckoutState, dispatch: Dispatch<SubscriptionCheckoutAction>}

const createDefaultLuniiSubscriptionCheckoutContextValue = (defaultValue: SubsctiptionCheckoutContextValue = {
    state: {
        selected: null,
        modal: {
            open: false,
            title: null,
            body: null,
            actions: null,
        },
        success: false,
    },
    reducer: (state: SubscriptionCheckoutState, action: SubscriptionCheckoutAction) => undefined,
}): SubscriptionCheckoutContext => {
    return {
        state: defaultValue.state,
        dispatch: (action: SubscriptionCheckoutAction) => null,
    };
};

export const LuniiSubscriptionCheckoutContext = createContext<SubscriptionCheckoutContext>(createDefaultLuniiSubscriptionCheckoutContextValue());
export const LuniiSubscriptionCheckoutProvider = LuniiSubscriptionCheckoutContext.Provider;
export const LuniiSubscriptionCheckoutConsumer = LuniiSubscriptionCheckoutContext.Consumer;
LuniiSubscriptionCheckoutContext.displayName = 'LuniiSubscriptionCheckoutContext';

export default LuniiSubscriptionCheckoutContext;
