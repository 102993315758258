import {useLocation, WindowLocation} from '@reach/router';
import {useEffect, useRef} from 'react';
import useLuniiPageTracking from '../ui/hooks/useLuniiPageTracking';

const usePreviousLocation = (value: WindowLocation) => {
    const ref = useRef<WindowLocation>();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

export function usePageTracking(
    pageTracking: boolean | undefined,
    model: string,
    extraProps?: {productList?: any[]} | undefined,
) {
    const location = useLocation();
    const prevLocation = usePreviousLocation(location);
    const dataLayerPageEvent = useLuniiPageTracking({envModel: model});
    const dataLayerPaperBooksPageEvent = useLuniiPageTracking({envModel: 'nos_histoires_papier'});
    const lastTrackedPath = useRef<string | undefined>(undefined);

    // Possible refacto using
    // https://github.com/reach/router/issues/43
    // or
    // https://github.com/reach/router/issues/262

    useEffect(() => {
        if (
            !prevLocation ||
            location.pathname !== prevLocation.pathname ||
            location.href !== prevLocation.href
        ) {
            if (
                pageTracking !== false &&
                model === 'nos_histoires_papier' &&
                extraProps?.productList
            ) {
                if (location.pathname !== lastTrackedPath.current) {
                    dataLayerPaperBooksPageEvent(extraProps.productList);
                }
            } else if (pageTracking !== false) {
                if (location.pathname !== lastTrackedPath.current) {
                    dataLayerPageEvent(extraProps?.productList);
                    lastTrackedPath.current = location.pathname;
                }
            }
        }
    }, [
        dataLayerPageEvent,
        dataLayerPaperBooksPageEvent,
        extraProps?.productList,
        location,
        model,
        prevLocation,
        pageTracking,
    ]);
}

export default usePageTracking;
