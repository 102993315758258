import React, {useCallback, useEffect, useState} from 'react';
import {cssText} from '@ohoareau/css-utils';
import Collapse from '@material-ui/core/Collapse';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {useMediaQuery, useTheme} from '@material-ui/core';
import SvgArrowDown from '../../ui/images/icons/ArrowDown';
import CatalogMenuItem from './CatalogMenuItem';
import {useLuniiTranslation, Clickable} from '../../ui';

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: 45,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 0,
        },
    },
    parentItem: {
        display: 'flex',
        justifyContent: 'space-between',
        minWidth: '100%',
        maxWidth: '100%',
        ...cssText(theme, 'standard', 'overline_title_2'),
        marginBottom: 8,
        cursor: 'pointer',
    },
    label: {
        paddingLeft: 10,
    },
    arrowIcon: {
        width: 16,
        maxWidth: 16,
        height: 16,
        maxHeight: 16,
        transition: 'transform .2s',
        [theme.breakpoints.down('sm')]: {
            width: 24,
            maxWidth: 24,
            height: 24,
            maxHeight: 24,
        },
    },
    expandedArrowIcon: {
        transform: 'rotate(-180deg)',
    },
    separator: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            height: 1,
            backgroundColor: '#E3E1DB',
            margin: '8px 0 20px 5px',
        },
    },
}));

export function CatalogMenuSubMenu({
    id = undefined,
    label = undefined,
    filters = [],
    items = [],
    expandedByDefault = false,
    onMenuItemClick = () => {},
    onResize = () => {},
}: CatalogMenuSubMenuProps) {
    const classes = useStyles();
    const theme = useTheme();
    const {t} = useLuniiTranslation();
    const [expanded, setExpanded] = useState(true);
    // @warn: using useMediaQuery (Material-UI), because did not find yet a faster way
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const handleClick = useCallback(() => setExpanded(!expanded), [expanded, setExpanded]);
    useEffect(() => {
        setExpanded(!isMobile || expandedByDefault);
        onResize();
    }, [isMobile, setExpanded, onResize, expandedByDefault]);
    return (
        <div className={classes.root}>
            <Clickable
                className={clsx(classes.parentItem)}
                onClick={handleClick}
            >
                <div className={classes.label}>{t(`catalog_menu_${label}`)}</div>
                <SvgArrowDown className={clsx(classes.arrowIcon, expanded && classes.expandedArrowIcon)}/>
            </Clickable>
            <Collapse
                in={expanded}
                timeout="auto"
            >
                {items.map((item: any, i: number) => (
                    <CatalogMenuItem
                        type={id}
                        item={item}
                        key={item.id || i}
                        active={filters?.some((f) => f.id === item.id)}
                        onItemClick={onMenuItemClick}
                    />
                ))}
            </Collapse>
            <div className={classes.separator}/>
        </div>
    );
}

export interface CatalogMenuSubMenuProps {
    id?: string;
    label?: any;
    items?: any[];
    onMenuItemClick?: any;
    expandedByDefault?: boolean;
    filters?: any[];
    onResize?: any;
}

export default CatalogMenuSubMenu;
