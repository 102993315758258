import React from 'react';
import {makeStyles} from '@material-ui/core';
import {cssText} from '@ohoareau/css-utils';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    main: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '70%',
        },
    },
    heading: {
        marginBottom: theme.spacing(1),
        '& > *': {
            marginBottom: theme.spacing(.5),
        },
    },
    aside: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
            height: 'auto',
            maxHeight: '100%',
            width: '30%',
            '& >*': {
                height: '100%',
            },
        },
    },
    title: {
        ...cssText(theme, 'standard', 'title_2'),
    },
    subtitle: {
        ...cssText(theme, 'standard', 'body_thick'),
    },
    body: {
        width: '100%',
        marginBottom: theme.spacing(1),
    },
    actions: {
    },
}));

interface PickedSubscriptionProps {
    title?: React.ReactNode,
    subtitle?: React.ReactNode,
    body?: React.ReactNode,
    aside?: React.ReactNode,
    actions?: React.ReactNode,
}

export function SubscriptionRecap ({title, subtitle, body, actions, aside}: PickedSubscriptionProps) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.main}>
                <div className={classes.heading}>
                    {title && <div className={classes.title}>{title}</div>}
                    {subtitle && <div className={classes.subtitle}>{subtitle}</div>}
                </div>
                {body && <div className={classes.body}>
                    {body}
                </div>}
                {actions && <div className={classes.actions}>{actions}</div>}
            </div>
            {aside && <div className={classes.aside}>
                {aside}
            </div>
            }
        </div>
    );
}

export default SubscriptionRecap;
