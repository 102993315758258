import React from 'react';
import TextParagraph from "../molecules/TextParagraph";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Row from "../Row";
import Grid from "@material-ui/core/Grid";
import {WithHeading} from "../types";

const useStyles = makeStyles({
    root: {
        display: 'flex',
        justifyContent: 'center',
        maxWidth: 507,
    },
});

export function HeadingLeft({overline, title, description}: HeadingLeftProps) {
    const classes = useStyles();
    return (
        <Row className={classes.root}>
            <Grid item container lg={12} md={12} xs={12}>
                <TextParagraph overline={overline} title={title} text={description} align={'left'} />
            </Grid>
        </Row>
    );
}

export interface HeadingLeftProps extends WithHeading {
}

export default HeadingLeft
