import React, {memo} from 'react';
import {cssText} from '@ohoareau/css-utils';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import {useLuniiTranslation, convertPrice, ProductTableNameEnum} from '../../ui';
import {GiftCardMetadata, DeviceTag} from '.';
import {Alert} from './v2';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
    },
    itemWrapper: {
        display: 'flex',
        flexDirection: 'column',
        margin: theme.spacing(3, 0),
        '&:last-child': {
            marginBottom: 0,
        },
    },
    item: {
        display: 'flex',
        position: 'relative',
        width: '100%',
        justifyContent: 'space-between',
    },
    itemThumb: {
        borderRadius: 4,
        width: 56,
        marginRight: 16,
    },
    image: {
        width: 56,
    },
    itemInfos: {
        width: '100%',
    },
    itemName: {
        ...cssText(theme, 'standard', 'body_thick', undefined, undefined, '#063646'),
        margin: 0,
    },
    quantity: {
        marginLeft: 5,
    },
    locale: {
        ...cssText(theme, 'standard', 'caption', undefined, undefined, '#1A6F8E'),
    },
    priceContainer: {
        display: 'flex',
        justifyContent: 'flexStart',
        alignItems: 'center',
        marginTop: 2,
    },
    oldPrice: {
        ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#2DAAD7'),
        textDecoration: 'line-through',
        marginRight: 8,
    },
    price: {
        ...cssText(theme, 'standard', 'body_thick', undefined, undefined, '#1A6F8E'),
    },
    priceExclTax: {
        display: 'block',
        ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#A79F8E'),
    },
    discount: {
        marginTop: theme.spacing(1),
    },
    tag: {
        marginTop: theme.spacing(1),
    },
}));

export const CheckoutCartProducts = memo(
    ({className = '', items = [], locale}: CheckoutCartProductsProps) => {
        const classes = useStyles();
        const {t, exists} = useLuniiTranslation();
        if (items.length === 0 || !locale) return null;
        const showTaxes = ['us', 'ca'].indexOf(locale.substring(3, 5).toLowerCase()) !== -1;
        return (
            <div className={clsx(classes.root, className)}>
                {items.map((item: any, i: number) => {
                    const reference = item.reference?.split(' ').join('_').toLowerCase();
                    return (
                        <div
                            key={item.id || i}
                            className={classes.itemWrapper}
                        >
                            <div className={classes.item}>
                                <div className={classes.itemThumb}>
                                    <img
                                        className={classes.image}
                                        src={item.thumbnailUrl}
                                        alt={item.name}
                                    />
                                </div>
                                <div className={classes.itemInfos}>
                                    <div className={classes.itemName}>
                                        {item.type === ProductTableNameEnum.GiftCards
                                            ? t('gift_cards_gift_in_cart_name')
                                            : item.name}
                                        {item.quantity > 1 && (
                                            <span className={classes.quantity}>
                                                (x{item.quantity})
                                            </span>
                                        ) || false}
                                    </div>
                                    {item.locale && item.type === ProductTableNameEnum.Packs && (
                                        <div className={classes.locale}>
                                            {t(
                                                `locale_${item.locale.toLowerCase()}_product_language`,
                                            )}
                                        </div>
                                    ) || false}
                                    <div className={classes.priceContainer}>
                                        {item.oldPrice && (
                                            <div className={classes.oldPrice}>
                                                {convertPrice(
                                                    item.oldPrice * item.quantity,
                                                    item.currency,
                                                )}
                                            </div>
                                        ) || false}
                                        <div className={classes.price}>
                                            {item.price === 0
                                                ? t('product_price_free')
                                                : convertPrice(
                                                      item.price * item.quantity,
                                                      item.currency,
                                                  )}
                                        </div>
                                    </div>
                                    {item.type === ProductTableNameEnum.GiftCards && item.metadata && (
                                        <GiftCardMetadata metadata={item.metadata} />
                                    ) || false}
                                    {showTaxes &&
                                        item.priceExclTax !== undefined &&
                                        item.priceExclTax !== item.price && (
                                            <div className={classes.priceExclTax}>
                                                {' '}
                                                ({t('checkout_cart_excl_taxes')}:{' '}
                                                {convertPrice(
                                                    item.priceExclTax * item.quantity,
                                                    item.currency,
                                                )}
                                                )
                                            </div>
                                        ) || false}
                                    {item.deviceTarget?.length && <div className={classes.tag}><DeviceTag size='xsmall' deviceTarget={item.deviceTarget}/></div> || false}
                                </div>
                            </div>
                            {exists(`checkout_cart_product_info_${reference}`)
                                && (t(`checkout_cart_product_info_${reference}`) || '').length > 0 && (
                                <Alert
                                    description={t(`checkout_cart_product_info_${reference}`)}
                                    theme='tips'
                                />
                            ) || false}
                        </div>
                    );
                })}
            </div>
        );
    },
);

export interface CheckoutCartProductsProps {
    className?: string;
    items?: any;
    locale: any;
}

export default CheckoutCartProducts;
