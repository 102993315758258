import {useCallback} from 'react';
import useLuniiNewsletterUnsubscribeApi from './useLuniiNewsletterUnsubscribeApi';

export function useNewsletterUnsubscribeSubmit(callback: Function|undefined = undefined) {
    const [unsubscribe, {loading, error, ...infos}] = useLuniiNewsletterUnsubscribeApi();
    const onSubmit = useCallback(async values => {
        try {
            const data = await unsubscribe({
                variables: {
                    data: {
                        email: values.email,
                    },
                },
            });
            callback && callback(((data || {}).data || {}).unsubscribe);
        } catch (e) {
            console.error(e);
        }
    }, [unsubscribe, callback]);
    return [onSubmit, {loading: Boolean(loading), error: error || false, ...infos}] as [any, any];
}

export default useNewsletterUnsubscribeSubmit
