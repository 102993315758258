import React, {useCallback, useState} from 'react';
import Clickable from "../Clickable";
import DynamicIcon from '../nucleons/DynamicIcon';
import DropDown from './DropDown'
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    item: {
        display: 'flex',
    },
    choicesSizing: {
        maxHeight: 330,
        overflow: 'auto',
        [theme.breakpoints.down('sm')]: {
            maxHeight: 300,
            maxWidth: 300
        },
    }
}));

export function LocaleSelector({items = [], currentItem, defaultOpened = false, onChange}: LocaleSelectorProps) {
    const classes = useStyles();
    const [currentChoice, setCurrentChoice] = useState(currentItem);
    const handleChange = useCallback((id) => {
        setCurrentChoice(id);
        onChange && onChange(id);
    }, [onChange]);
    let currentLocale = items.find(item => item.id === currentChoice);
    if (!items.length) return null;
    const currentChoiceComp = (
        <>
            <DynamicIcon type={`flag-${currentLocale.id.substr(3)}`} id={currentLocale.id} width={26} height={26}/>
            <div>{currentLocale.label}</div>
        </>
    );

    const choices = items && items.map(({id, target, label}, index) => (
        <Clickable key={`langItem-${index}`} onClick={() => handleChange(id)} className={classes.item}>
            <DynamicIcon type={`flag-${id.substr(3)}`} id={id} width={26} height={26}/>
            <div>{label}</div>
        </Clickable>)
    )

    return (
        <DropDown currentChoice={currentChoiceComp} choices={choices} rounded={true} big={true} defaultOpened={defaultOpened} choicesClassName={classes.choicesSizing} />
    );
}

export interface LocaleSelectorProps {
    defaultOpened?: boolean,
    currentItem?: string,
    items: any[],
    onChange?: Function
}

export default LocaleSelector
