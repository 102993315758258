import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
    root: {
        color: '#FCCD5F',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: (props: any) => props.centered ? 'center' : 'flex-start',
        alignItems: 'center',
        zIndex: 10,
        position: (props: any) => props.fixed ? 'fixed' : 'absolute',
        overflow: 'hidden',
        '&:before': {
            content: '""',
            position: (props: any) => props.fixed ? 'fixed' : 'absolute',
            height: '100%',
            width: '100%',
            backgroundColor: (props: any) => props.backgroundColor || '#ffffff',
            opacity: (props: any) => props.opacity || 0.77,
            zIndex: -1,
        },
        '@supports (backdrop-filter: blur(2px))': {
            backdropFilter: 'blur(1.5px)',
        },
    },
    content: ({centered}: any) => ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        ...(!centered && {marginTop: theme.spacing(24)}),
    }),
    children: {
        color: '#423f37',
        marginTop: theme.spacing(2),
        padding: theme.spacing(2),
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
            marginTop: theme.spacing(3),
            padding: theme.spacing(4),
        },
    },
    overlay: {
        position: (props: any) => props.fixed ? 'fixed' : 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

/**
 * Spinner component to use as loading indicator.
 * The parent may be required to use a `overflow: 'hidden'` to contain the overlay.
 */
export function Spinner({className = '', bgColor = undefined, children = undefined, size = 40, overlay = false, opacity = undefined, centered = true, fixed = false, ...props}: SpinnerProps) {
    const classes = useStyles({backgroundColor: bgColor, opacity, centered, fixed});
    return (
        <div className={clsx(classes.root, overlay && classes.overlay, className)} {...props}>
            <div className={classes.content}>
                <CircularProgress size={size} color='inherit' />
                {children && <div className={classes.children}>
                    {children}
                </div> || false}
            </div>
        </div>
    );
}

export interface SpinnerProps {
    className?: any;
    bgColor?: string;
    children?: any;
    size?: number;
    overlay?: boolean;
    opacity?: number;
    centered?: boolean;
    fixed?: boolean;
}

export default Spinner;
