import React, {useCallback, useEffect, useState} from 'react';
import clsx from 'clsx';
import {cssFgColor, cssText} from '@ohoareau/css-utils';
import MuiCheckbox from '@material-ui/core/Checkbox';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    root: {
        ...cssText(theme, 'standard', 'secondary_body'),
    },
    label: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: (props) => props['labelAlignItems'] || 'center',
        cursor: 'pointer',
    },
    input: {
        color: '#D6D3CA',
        backgroundColor: '#FAF9F8',
        padding: 0,
        '&.Mui-checked': {
            color: '#1AB5B0',
            position: 'relative',
            zIndex: 0,
        },
        '&.Mui-checked:after': {
            content: '""',
            left: 4,
            top: 4,
            height: 15,
            width: 15,
            position: 'absolute',
            backgroundColor: '#074847',
            zIndex: -1,
        },
    },
    textContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    span: {
        marginLeft: 8,
    },
    btn: {
        cursor: 'pointer',
        padding: 0,
        marginTop: -1,
        marginLeft: 4,
        ...cssFgColor(theme, 'secondary_body', 'link'),
    },
}));

export function Checkbox({className, textClassName, inputClassName, content, linkLabel, linkTarget, id, name, status = false, required, register, onChange = () => {}, alignItems = 'center', reinitState = false}: CheckboxProps) {
    const classes = useStyles({labelAlignItems: alignItems});
    const [checked, setChecked] = useState(status);
    const onClick = useCallback(e => {
        setChecked(e.target.checked);
        onChange(e);
    }, [onChange, setChecked, checked]);
    useEffect(() => {
        if (reinitState && checked) {
            setChecked(false);
            onChange(undefined);
        }
    }, [reinitState]);
    return (
        <div className={clsx(classes.root, className)}>
            <label className={classes.label} htmlFor={id}>
                <MuiCheckbox id={id} name={name} checked={checked} onClick={onClick} className={clsx(classes.input, inputClassName)} color={'default'} required={required || false} inputRef={register ? register({required}) : undefined}/>
                <div className={clsx(classes.textContainer, textClassName)}>
                    <span className={classes.span}>{content}{linkLabel && linkTarget && <span onClick={linkTarget} className={classes.btn}>{linkLabel}</span>}</span>
                </div>
            </label>
        </div>
    );
}

export interface CheckboxProps {
    className?: string,
    alignItems?: 'flex-start' | 'center' | 'flex-end' | 'stretch' | 'baseline' | undefined,
    textClassName?: string,
    inputClassName?: string,
    linkLabel?: string,
    linkTarget?: any,
    content: any,
    id: string,
    name: string,
    status?: boolean,
    onChange?: Function,
    required?: boolean,
    register?: Function,
    reinitState?: boolean,
}

export default Checkbox;
