import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';
import {cssText} from '@ohoareau/css-utils';
import {useFormContext} from 'react-hook-form';
import {useLuniiTranslation, FieldSet, TextField} from '../../ui';

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
    },
    title: {
        ...cssText(theme, 'standard', 'title_2'),
        marginBottom: theme.spacing(1),
    },
    charactersInfos: {
        ...cssText(theme, 'standard', 'caption', undefined, undefined, '#908977'),
        width: '100%',
        textAlign: 'end',
    },
}));

export function GiftCardSenderChoice({
    className = '',
    emptyValueErrors,
    errorClassName = '',
}: GiftCardSenderChoiceProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const {register, errors, watch} = useFormContext();
    const x = {errors, register};
    const giftCardSenderName = watch('giftCardSenderName') || '';
    const giftCardNoReceiver = watch('giftCardNoReceiver');
    if (giftCardNoReceiver) return null;
    return (
        <div className={clsx(classes.root, className)}>
            <div className={classes.title}>{t('gift_cards_sender_title')}</div>
            <FieldSet>
                <TextField
                    name="giftCardSenderName"
                    {...x}
                    fullWidth
                    definition={{
                        pattern: {
                            value: /^.{0,20}$/i,
                            message: t('constraints_too_many_characters'),
                        },
                    }}
                />
                <div className={classes.charactersInfos}>
                    {giftCardSenderName.length}/20 {t('characters')}
                </div>
            </FieldSet>
            {emptyValueErrors?.senderName && (
                <div className={errorClassName}>{t('gift_cards_error_giftcardsendername')}</div>
            )}
        </div>
    );
}

export interface GiftCardSenderChoiceProps {
    className?: string;
    emptyValueErrors?: any;
    errorClassName?: string;
}

export default GiftCardSenderChoice;
