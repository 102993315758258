import React, {useState} from 'react';
import {cssBox, cssShadow, cssText} from '@ohoareau/css-utils';
import MosaicCardItem from '../molecules/MosaicCardItem';
import {A11y, Navigation, FreeMode, Mousewheel} from 'swiper';
import 'swiper/swiper.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/modules/a11y/a11y.min.css';
import 'swiper/modules/free-mode/free-mode.min.css';
import {Swiper, SwiperProps, SwiperSlide} from 'swiper/react/swiper-react.js';
import {Swiper as SwiperInstance} from 'swiper/types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Row from '../Row';
import Grid from '@material-ui/core/Grid';
import {module_title, module_text} from '../types';
import clsx from 'clsx';
import {IconButton, useMediaQuery} from '@material-ui/core';
import ArrowLeft from '../images/icons/ArrowLeft';
import useSwiperRef from '../hooks/swiper/useSwiperRef';

const useStyles = makeStyles(theme => ({
    root: {
        ...cssText(theme, 'standard', 'body'),
        ...cssBox(theme, undefined, props => theme[`background_color_${props.backgroundColor}`] || props.backgroundColor || theme['clear']),
        position: 'relative',
        padding: theme.spacing(4, 0),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(3, 0),
        },
    },
    row: {
        ...cssBox(theme, undefined, props => theme[`background_color_${props.backgroundColor}`] || props.backgroundColor || theme['clear']),
    },
    header: {
        marginBottom: theme.spacing(2.5),
    },
    title: {
        ...cssText(theme, 'standard', 'large_title', undefined, undefined, '#2C3637'),
        marginBottom: theme.spacing(1),
    },
    text: {
        ...cssText(theme, 'standard', 'body', undefined, undefined, '#5F6769'),
    },
    swiperWrapper: {
        position: 'relative',
        paddingTop: theme.spacing(1),
        marginTop: theme.spacing(-1),
        width: '100%',
    },
    slideWrapper: {
        width: 170,
        [theme.breakpoints.down('md')]: {
            width: 216,
        },
        [theme.breakpoints.down('sm')]: {
            width: 160,
        },
    },
    control: {
        display: 'none',
        position: 'absolute',
        zIndex: 100,
        ...cssShadow(theme, 'level_02'),
        backgroundColor: '#ffffff',
        border: '1px solid #efedea',
        transform: 'translate3d(0, calc(-50% + 12px), 0)',
        color: '#2762e9',
        transition: 'color .35s ease-out, box-shadow .35s ease-out, background-color .35s ease-out, opacity .35s ease-out',
        top: 160 / 2,
        [theme.breakpoints.up('sm')]: {
            top: 216 / 2,
        },
        [theme.breakpoints.up('md')]: {
            top: 170 / 2,
        },
        '&:hover': {
            backgroundColor: '#ffffff',
        },
        '&.hidden': {
            opacity: 0,
            display: 'none !important',
            pointerEvents: 'none',
        },
    },
    controlDisabled: {
        'pointer-events': 'unset !important',
        opacity: '0 !important',
    },

    '@media (hover: hover)': {
        root: {
            '&:hover $control': {
                opacity: 1,
                ...cssShadow(theme, 'level_01'),
            },
        },
        control: {
            opacity: 0,
            display: 'block',
        },
    },
    prev: {
        left: 10,
        [theme.breakpoints.up('md')]: {
            left: 20,
        },
    },
    next: {
        right: 10,
        [theme.breakpoints.up('md')]: {
            right: 20,
        },
    },
}));

export function AlbumCarroussel({className = '', title, mode = 'small', text, items = [], backgroundColor, ...props}: AlbumCarrousselProps) {
    const classes = useStyles({backgroundColor});
    const hasHover = useMediaQuery('(hover: hover)');
    const [scrollLock, setScrollLock] = useState<boolean>(true);
    const [canGoPrev, setCanGoPrev] = useState<boolean>(false);
    const [canGoNext, setCanGoNext] = useState<boolean>(false);

    const [nextEl, nextElRef] = useSwiperRef<HTMLButtonElement>();
    const [prevEl, prevElRef] = useSwiperRef<HTMLButtonElement>();

    const handleNav = (swiper: SwiperInstance) => {
        if (!hasHover || scrollLock) return;

        if (swiper.isEnd) {
            if (canGoNext) setCanGoNext(false);
        } else if (swiper.isBeginning) {
            if (canGoPrev) setCanGoPrev(false);
            if (!canGoNext) setCanGoNext(true);
        } else {
            if (!canGoPrev) setCanGoPrev(true);
            if (!canGoNext) setCanGoNext(true);
        }
    };

    const swiperProps: SwiperProps = {
        modules: [A11y, Navigation, FreeMode, Mousewheel],
        slidesPerView: 'auto',
        slidesOffsetAfter: 16,
        slidesOffsetBefore: 16,
        spaceBetween: 8,
        breakpoints: {
            960: {
                slidesOffsetAfter: 24,
                slidesOffsetBefore: 24,
                spaceBetween: 16,
            },
            1280: {
                slidesOffsetAfter: 150,
                slidesOffsetBefore: 150,
                spaceBetween: 24,
            },
        },
        freeMode: true,
        mousewheel: {
            invert: false,
            forceToAxis: true,
            releaseOnEdges: true,
        },
        navigation: {
            prevEl,
            nextEl,
        },
        grabCursor: true,
        resizeObserver: true,
        watchSlidesProgress: true,
        roundLengths: true,
        onResize: handleNav,
        onProgress: handleNav,
        onUpdate: handleNav,
        onBreakpoint: (swiper) => {
            swiper.setProgress(0);
        },
        onLock: (swiper) => {
            // Slider has more space than provided slides, should hide controls
            if (!scrollLock) setScrollLock(true);
            if (setTimeout) {
                setTimeout(() => {
                    swiper.setProgress(0);
                }, 0);
            }
        },
        onUnlock: () => {
            // Slider has less space than provided slides, should display controls
            if (scrollLock) setScrollLock(false);
        },
    };

    if (items.length === 0 ) return null;

    return (
        <div className={clsx(classes.root, className)}>
            <Row padding='none' justify='flex-start' rootClassName={classes.row}>
                <Grid lg={6} md={9} xs={12} className={classes.header}>
                    {title && <div className={classes.title}>{title}</div> || false}
                    {text && <div className={classes.text}>{text}</div> || false}
                </Grid>
            </Row>
            <Swiper
                {...swiperProps}
                className={classes.swiperWrapper}>
                {items.map((item,index) => (
                    <SwiperSlide
                        key={`itm-${index}`}
                        className={classes.slideWrapper}
                    >
                        <MosaicCardItem
                            {...item}
                            imageLoading={index < 8 ? 'eager' : 'lazy'}
                        />
                    </SwiperSlide>
                ))}
                <IconButton
                    classes={{root: classes.control, disabled: classes.controlDisabled}}
                    className={clsx(classes.prev, scrollLock && 'hidden')}
                    slot="container-start"
                    disabled={!canGoPrev}
                    ref={prevElRef}>
                    <ArrowLeft/>
                </IconButton>
                <IconButton
                    classes={{root: classes.control, disabled: classes.controlDisabled}}
                    className={clsx(classes.control, classes.next, scrollLock && 'hidden')}
                    slot="container-end"
                    disabled={!canGoNext}
                    ref={nextElRef}>
                    <ArrowLeft style={{transform: 'rotate(180deg)'}}/>
                </IconButton>
            </Swiper>
        </div>
    );
}

export interface AlbumCarrousselProps {
    className?: string;
    items?: any,
    backgroundColor?: string,
    title?: module_title,
    text?: module_text,
    mode?: 'small' | 'large' | undefined,
}

export default AlbumCarroussel;
