import React, {useCallback} from 'react';
import {cssText} from '@ohoareau/css-utils';
import Demo from '../molecules/Demo';
import {Stickyroll} from '@stickyroll/stickyroll';
import {makeStyles} from "@material-ui/core/styles";
import {WithItems} from "../types";

const useStyles = makeStyles(theme => ({
    root: {
        ...cssText(theme, 'standard', 'body'),
    },
}));

export function DemoModule({items = [], ...props}: DemoModuleProps) {
    const classes = useStyles();
    const handleClick = useCallback(index => {
        const target = ('undefined' !== typeof document) && document.getElementById(`${index+1}`);
        target && target.scrollIntoView(true);
    }, []);
    if (items.length === 0) return null;
    return(
        <div className={classes.root}>
            <Stickyroll {...props} pages={items} className={classes.root} anchors="">
                {({pageIndex}) => {
                    const {title, overline, image, image2} = items[pageIndex];
                    return (
                        <Demo step={`${pageIndex}`} key={pageIndex} steps={items.map((item, index) => `${index}`)} onChange={handleClick} title={title} overline={overline} image={image} image2={image2} />
                    );
                }}
            </Stickyroll>
        </div>
    );
}

export interface DemoModuleProps extends WithItems {
}

export default DemoModule
