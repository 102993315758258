import React, {useCallback, useEffect, useState} from 'react';
import {nanoid} from 'nanoid';
import {
    useDatasource,
    AlbumCarroussel,
    useWishlist,
    wishlist_item,
    useLuniiPage,
    useLuniiEventTracking,
    digital_album_product,
} from '../../../ui';

export function CarrousselCardSmallDynamicItemsModule({dataSource, page, ...props}: any) {
    const [items, setItems] = useState<any[]>([]);
    const {...p} = useLuniiPage();
    const handleEventTracking = useLuniiEventTracking();

    const [getItems] = useDatasource(
        dataSource,
        useCallback(
            (d: any) => {
                const firstQueryName: string | undefined = (Object.keys(d || {}) || []).shift();
                if (firstQueryName && d[firstQueryName] && d[firstQueryName]?.items) {
                    setItems(d[firstQueryName]?.items.map((item: any) => ({product: item})));
                }
            },
            [setItems],
        ),
    );

    useEffect(() => {
        getItems();
    }, [getItems]);

    const [{addToWishlistExecute: addToWishlist, removeFromWishlistExecute: removeFromWishlist}] =
        useWishlist(); // todo warning should use new useUserWishlist, usePostUserWishlist and userDeleteUserWishlist hooks

    const handleWishlistClick = useCallback(
        async (packInWishlist: boolean, product: wishlist_item) => {
            const packId = product.objectId;
            if (packInWishlist) {
                await removeFromWishlist({
                    variables: {
                        data: {
                            packId,
                        },
                    },
                    optimisticResponse: {
                        __typename: 'Mutation',
                        removeFromWishlist: {
                            ...product,
                            _removed: true,
                            __typename: 'WishlistItem',
                        },
                    },
                });
            } else {
                await addToWishlist({
                    variables: {
                        data: {
                            packId,
                        },
                    },
                    optimisticResponse: {
                        __typename: 'Mutation',
                        addToWishlist: {
                            ...product,
                            id: nanoid(),
                            __typename: 'WishlistItem',
                        },
                    },
                });
            }
        },
        [addToWishlist, removeFromWishlist],
    );
    const handleWishlistTracking = (
        packInWishlist: boolean,
        product: wishlist_item | digital_album_product,
    ) => {
        p.pageModel &&
            handleEventTracking(
                {
                    categorie: 'ecommerce',
                    action: packInWishlist ? 'retrait_liste_souhaits' : 'ajout_liste_souhaits',
                    libelle: p.pageModel,
                    valeur: Math.round(product.priceExclTax / 100) * 100,
                },
                product,
            );
    };

    const enrichedItems = items.map((item) => ({
        ...item,
        onWishlistToggleClick: handleWishlistClick,
        onWishlistToggleClickCb: handleWishlistTracking,
    }));

    return (
        <AlbumCarroussel
            items={enrichedItems}
            {...props}
        />
    );
}

export default CarrousselCardSmallDynamicItemsModule;
