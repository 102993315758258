import React from 'react';
import {cssBox, cssText} from '@ohoareau/css-utils';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import {Trans} from 'react-i18next';
import {useLuniiTranslation} from '../hooks';
import {getThemeColor} from '../../../configs';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1),
        margin: theme.spacing(1, 0),
    },
    interactive: {
        ...cssBox(theme, 'standard', 'interactive'),
        ...cssText(theme, 'standard', 'caption', undefined, undefined, 'white'),
    },
    message: {
        ...cssBox(theme, 'standard', 'status_message'),
        ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#503C0D'),
    },
    warning: {
        backgroundColor: '#FFF8F6',
        ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#722613'),
    },
    danger: {
        ...cssBox(theme, 'standard', 'status_message'),
        backgroundColor: getThemeColor('danger', '100'),
        ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, getThemeColor('danger', '800')),
    },
}));

export function WarningPanel({className = '', type = 'interactive', message, transKey = undefined, description, children}: WarningPanelProps) {
    const classes = useStyles();
    const {i18n, t} = useLuniiTranslation();
    if (!message && !t(transKey)) return null;
    return (
        <div className={clsx(classes.root, classes[type], className)}>
            {children || ''}
            {!!transKey && <Trans i18nKey={transKey} i18n={i18n} values={{description}}/>}
            {message && <div>{message}</div>}
        </div>
    );
}

export interface WarningPanelProps {
    className?: any,
    children?: any,
    message?: string,
    description?: string,
    transKey?: string
    type?: 'interactive' | 'message' | 'warning' | 'danger'
}

export default WarningPanel;
