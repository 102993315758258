import {useCallback} from 'react';
import {cart} from '../ui';

export function usePaypalPayment(createPayment: Function): Function[] {
    const useCreatePayment = useCallback(
        (purchaseId: string, cart: cart) =>
            // past this line the remote cart has been deleted and any action concerning the cart must be done using the local one
            createPayment({
                purchase: purchaseId,
                locale: (cart || {}).country,
                paymentType: 'paypal',
            }),

        [],
    );

    const useValidatePayment = useCallback(
        (payment) => {
            const url = payment.redirectToUrl;
            typeof window !== 'undefined' && window.location.replace(url);
        },
        [window],
    );

    return [useCreatePayment, useValidatePayment];
}

export default usePaypalPayment;
