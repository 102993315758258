import {useCallback, useContext} from 'react';
import {address as address_type, cart_item, price_details, purchase, purchase_shipping} from '../types';
import LuniiTrackingContext from '../contexts/LuniiTrackingContext';
import useLuniiUser from './useLuniiUser';
import useLuniiCart from './useLuniiCart';
import {sha256} from '../../utils';
import {formatEnvModel} from "./useLuniiPageTracking";

function formatPrice (price: number|undefined) {
    return price && (price / 100);
}

export function useLuniiCheckoutTracking (params: {envModel: string}) {

    const tracking = useContext(LuniiTrackingContext);
    const {user} = useLuniiUser();
    const [cart] = useLuniiCart();

    const gtmUser = useCallback(async () => {
        if (user) {
            const hashedPhone = await sha256(((user.deliveryInfos || []).find((address: address_type) => user.locale === address.locale) || {}).phone || '');
            const hashedEmail = await sha256(user?.email || '');
            return {
                utilisateur_connecte: 'oui',
                utilisateur_id: user.id,
                utilisateur_telephone: hashedPhone,
                utilisateur_email: hashedEmail,
                utilisateur_nbr_commandes: user.purchaseCount || 0,
                utilisateur_statut: user.purchaseCount ?
                    user.purchaseCount > 1 ? 'client' : 'nouveau_client'
                    : 'prospect',
            }
        }
        return {
            utilisateur_connecte: 'non',
        };
    }, [user]);

    let eventData = useCallback((envModel, cartItems?: cart_item[], purchase?: purchase) => {

        let items: cart_item[] | undefined;
        let priceDetails: price_details | undefined;
        let shipping: purchase_shipping | undefined;
        let paymentMethod: string | undefined;
        let purchaseNumber: any | undefined;
        let coupon: string | undefined;

        if (['panier', 'identification', 'inscription', 'livraison_paiement'].includes(envModel)) {

            items = cart?.items;
            priceDetails = {
                total: 0,
                totalWithVat: 0,
                taxTotal: 0,
            };

            (items || []).map(item => {
                // @ts-ignore
                priceDetails.total += item.priceExclTax * item.quantity;
                // @ts-ignore
                priceDetails.totalWithVat += item.price * item.quantity;
            });

            priceDetails.taxTotal = priceDetails.totalWithVat - priceDetails.total;

        } else if (envModel === 'confirmation_achat') {

            items = cartItems;
            priceDetails = purchase?.priceDetails;
            shipping = purchase?.shipping;
            if (purchase?.paymentMethod) {
                paymentMethod = purchase.paymentMethod;
                if (purchase.giftCard || purchase.accountBalance) {
                    paymentMethod += ' - carte_cadeau';
                }
            } else if (purchase?.giftCard || purchase?.accountBalance) {
                paymentMethod = 'carte_cadeau';
            } else {
                paymentMethod = 'free';
            }
            purchaseNumber = purchase?.purchaseNumber;
            coupon = [
                purchase?.discount?.code,
                ...(purchase?.productsDiscounts?.map((discount: any) => discount.codeCode) || []),
            ].filter(Boolean).join('-'); // Join all discount codes;

        }

        const commande_produits = (items || []).map(item => {
            return {
                produit_id: item.reference,
                produit_bundle: undefined, // TODO
                produit_nom: item.name,
                produit_prix_ht: formatPrice(item.priceExclTax),
                produit_prix_ttc: formatPrice(item.price),
                produit_type: item.productType,
                produit_thematique: item.theme,
                produit_age: item.age,
                produit_type_album: item.albumType,
                produit_langue_etrangere: item.foreignLang,
                produit_quantite: item.quantity,
                produit_liste_souhaits: item.productFromWishlist ? 'oui' : 'non',
            };
        });

        return {
            commande_produits: commande_produits,
            commande_panier_id: user?.hashedUserId,
            commande_montant_ht: formatPrice(priceDetails?.total),
            commande_montant_ttc: formatPrice(priceDetails?.totalWithVat),
            commande_montant_taxes: formatPrice(priceDetails?.taxTotal),
            commande_coupon: coupon === '' ? undefined : coupon,
            commande_id: purchaseNumber,
            commande_frais_livraison: formatPrice(shipping?.price),
            commande_mode_livraison: shipping?.reference,
            commande_mode_paiement: paymentMethod,
        };

    }, [cart, user]);

    return async (cartItems?, purchase?) => {

        const {dataLayer, env: gtmEnv} = tracking;
        const envModel = formatEnvModel(params?.envModel);
        const gtm = await gtmUser();

        dataLayer && setTimeout && setTimeout(() => {
            dataLayer.push({
                event: 'datalayer',
                env_modele: envModel,
                ...gtmEnv,
                ...gtm,
                ...eventData(envModel, cartItems, purchase),
                _clear: true,
            });
        }, 150);
        /*
         Pageview event is emitted with a 50ms delay from the gatsby gtm module
         and dataLayer event must be emitted afterwards
         */

    };

}

export default useLuniiCheckoutTracking;
