import React from 'react';
import {Content1} from '../../../ui';

export function ContentLeftFullHeightPushModule(props: any) {
    return (
        <Content1
            {...props}
            fullHeightImg
            imagePosition="left"
        />
    );
}

export default ContentLeftFullHeightPushModule;
