import React from 'react';
import clsx from 'clsx';
import {Typography} from '@material-ui/core';
import {cssText} from '@ohoareau/css-utils';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {useLuniiTranslation} from '../../ui';

const useStyles = makeStyles((theme) => ({
    root: {
        /* Utiliser un code du thème pour la couleur de police, introuvable dans le thème pour le moment */
        ...cssText(theme, 'standard', 'caption', undefined, undefined, '#AE821D'),
        display: 'flex',
        flexDirection: 'column',
        /* Utiliser un code du thème pour la couleur de police, introuvable dans le thème pour le moment */
        backgroundColor: '#fff9ec',
        borderRadius: 8,
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
        width: 296,
        [theme.breakpoints.only('xs')]: {
            width: 'auto',
        },
    },
    row: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: theme.spacing(1 / 2),
    },
    rowBalance: {
        ...cssText(
            theme,
            'standard',
            'secondary_body_thick',
            undefined,
            undefined,
            'button_1_primary',
        ),
        marginTop: 0,
    },
    amount: {
        marginLeft: theme.spacing(1),
        whiteSpace: 'nowrap',
    },
}));

export function ShoppingCartCheckoutGiftCard({
    balance = '',
    willSpend = '',
    willStay = '',
}: ShoppingCartCheckoutGiftCardProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    return (
        <div className={classes.root}>
            <div className={clsx(classes.row, classes.rowBalance)}>
                <Typography
                    variant="inherit"
                    noWrap
                >
                    {t('checkout_cart_gift_balance')}
                </Typography>
                <Typography
                    variant="inherit"
                    className={classes.amount}
                >
                    {balance}
                </Typography>
            </div>
            <div className={classes.row}>
                <Typography
                    variant="inherit"
                    noWrap
                >
                    {t('checkout_cart_gift_will_spend')}
                </Typography>
                <Typography
                    variant="inherit"
                    className={classes.amount}
                >
                    {willSpend}
                </Typography>
            </div>
            <div className={classes.row}>
                <Typography
                    variant="inherit"
                    noWrap
                >
                    {t('checkout_cart_gift_will_stay')}
                </Typography>
                <Typography
                    variant="inherit"
                    className={classes.amount}
                >
                    {willStay}
                </Typography>
            </div>
        </div>
    );
}

export interface ShoppingCartCheckoutGiftCardProps {
    className?: string;
    items?: any;
    balance?: string;
    willSpend?: string;
    willStay?: string;
}

export default ShoppingCartCheckoutGiftCard;
