import React, {useContext, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core';
import {useLuniiTranslation, AudioContext, DynamicIcon} from '../';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#E6EEFF',
        padding: theme.spacing(.5, 2),
        borderRadius: theme.spacing(3),
        border: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        color: '#2762E9',
    },
    text: {
        marginLeft: theme.spacing(.5),
    },
}));

export function AudioPlayer({url}: AudioPlayerProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const audioPlayer = useRef<any>(null); // audio player ref
    const [play, setPlay] = useState<boolean>(false);
    const {activeSourceUrl, onPlay, onEnded, enabled} = useContext(AudioContext);
    const toggleAudio = () => {
        audioPlayer?.current.paused ? audioPlayer?.current?.play() : audioPlayer?.current?.pause();
        setPlay(!play);
    };
    if (enabled) {
        url === activeSourceUrl ? audioPlayer?.current?.play() : audioPlayer?.current?.pause();
        if (audioPlayer?.current && url !== activeSourceUrl && activeSourceUrl !== null) {
            audioPlayer.current.currentTime = 0;
        }
    }
    const isPlaying = url === activeSourceUrl || play;
    return (
        <div key={`audio-${url}`}>
            <button className={classes.root} onClick={() => enabled ? onPlay(url) : toggleAudio()}>
                <DynamicIcon type={isPlaying ? 'pause' : 'play'} width={24} height={24} color={'currentColor'}/>
                <div className={clsx('button_2', classes.text)}>{isPlaying ? t('audio_player_pause') : t('audio_player_play')}</div>
            </button>
            <audio hidden ref={audioPlayer} onEnded={() => enabled ? onEnded() : setPlay(false)}>
                <source src={url} type={'audio/mp3'}/>
            </audio>
        </div>
    );
}

export interface AudioPlayerProps {
    url: string,
}

export default AudioPlayer;
