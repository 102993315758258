import React from 'react';
import QuoteSlide from './QuoteSlide';
import {Swiper, SwiperProps, SwiperSlide} from 'swiper/react/swiper-react.js';
import {EffectFade} from 'swiper';
import 'swiper/swiper.min.css';
import 'swiper/modules/effect-fade/effect-fade.min.css';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {SwiperModule, Swiper as SwiperInstance} from 'swiper/types';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '66%',
        [theme.breakpoints.down('md')]: {
            width: '75%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
}));

export function QuoteSwiperTexts({controller, onSwiper, items = [], modules}: QuoteSwiperTextsProps) {
    const classes = useStyles();
    const params: SwiperProps = {
        modules: [EffectFade, ...modules],
        loop: true,
        loopAdditionalSlides: 5,
        slidesPerView: 1,
        centeredSlides: true,
        initialSlide: 0,
        controller: {
            control: controller,
        },
        onSwiper: onSwiper,
        effect: 'fade',
        fadeEffect: {
            crossFade: true,
        },
        autoplay: {
            delay: 4000,
        },
    };
    return (
        <Swiper
            {...params}
            className={classes.root}>
            {items.map(({image, ...text}, index) => (
                <SwiperSlide key={index}>
                    <QuoteSlide
                        {...text}
                        noImage/>
                </SwiperSlide>
            ))}
        </Swiper>
    );
}

export interface QuoteSwiperTextsProps {
    controller?: any,
    onSwiper?: ((swiper: SwiperInstance) => void) | undefined,
    items?: any[],
    modules: SwiperModule[]
}

export default QuoteSwiperTexts;
