import React from 'react';
import {cssText} from '@ohoareau/css-utils';
import {List} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {Illustration, Button, ShoppingCartWidgetItem, useLuniiEventTracking, useLuniiTranslation} from '..';

const useStyles = makeStyles(theme => ({
    root: {
        flex: 1,
    },
    list: {
        padding: theme.spacing(0),
    },
    btnGoCheckout: {
        margin: theme.spacing(2, 0, 1, 0),
    },
    moreItems: {
        ...cssText(theme, 'standard', 'caption', 'center'),
        marginTop: theme.spacing(1),
    },
    emptyContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    image: {
        width: '100%',
        maxWidth: 130,
    },
    empty: {
        ...cssText(theme, 'standard', 'caption'),
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(1),
    },
}));

export function ShoppingCartWidgetContent({items = [], maxItems, onDeleteItem}: ShoppingCartWidgetContentProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const handleEventTracking = useLuniiEventTracking();
    const handleGoCheckoutCallback = () => {
        handleEventTracking({
            categorie: 'navigation',
            action: 'menu',
            libelle: 'aller_au_panier',
        });
    };
    const nbItems = items.reduce((q: number, item: any) => item.quantity + q, 0);
    return (
        <div className={classes.root}>
            {items.length > 0 ?
                <>
                    <List className={classes.list}>{items.slice(0, maxItems).map((item) => <ShoppingCartWidgetItem key={item.id} item={item} onDelete={onDeleteItem} />)}</List>
                    {items.length > maxItems && <div className={classes.moreItems}>{t('cart_other_item', {count: items.length - maxItems})}</div>}
                    <Button fullWidth size='small' color='primary' className={classes.btnGoCheckout} onClick={'/checkout/cart'} onClickCb={handleGoCheckoutCallback}>{t('cart_go_checkout', {nbItems})}</Button>
                </> :
                <div className={classes.emptyContainer}>
                    <Illustration className={classes.image} mt={0} name='checkout_empty_cart_v2'/>
                    <div className={classes.empty}>{t('cart_empty_text')}</div>
                </div>}
        </div>
    );
}

export interface ShoppingCartWidgetContentProps {
    items?: any,
    onDeleteItem?: Function,
    maxItems: number,
}

export default ShoppingCartWidgetContent;
