import React, {useEffect, useState} from 'react';
import Button from './Button';

export const ButtonDeferred = ({onClick: onClickOrigin, onClickDeferred, ...props}) => {

    const [onClick, setOnClick] = useState(onClickOrigin);
    const [visibility, setVisibility] = useState<string>('visible');

    useEffect(() => {
        if (onClickDeferred && onClickDeferred !== 'none') {
            setOnClick(onClickDeferred);
        } else {
            setVisibility('hidden');
        }
    }, [onClickDeferred, setOnClick, setVisibility]);

    return (
        <Button {...props} onClick={onClick} style={{visibility: visibility}} /> // add spinner while waiting for useEffect
    );

}

export default ButtonDeferred
