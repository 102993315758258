import {useCallback} from 'react';
import useLuniiAddressUpdateApi from './useLuniiAddressUpdateApi';

export function useAddressUpdateSubmit(callback: Function|undefined = undefined) {
    const [submit, {loading, error, ...infos}] = useLuniiAddressUpdateApi();
    const onSubmit = useCallback(async values => {
        try {
            const data = await submit({
                variables: {
                    data: {
                        type: values.type,
                        address: {
                            address1: values.address.address1,
                            address2: values.address.address2,
                            firstName:values.address.firstName,
                            lastName:values.address.lastName,
                            city:values.address.city,
                            state: values.address.state,
                            zipCode:values.address.zipCode,
                            country:values.address.country,
                            locale:values.address.locale,
                            phone:values.address.phone,
                        },
                    },
                },
            });
            callback && callback(((data || {}).data || {}).updateAddress);
        } catch (e) {
            console.error(e);
        }
    }, [submit, callback]);
    return [onSubmit, {loading: Boolean(loading), error: error || false, ...infos}] as [any, any];
}

export default useAddressUpdateSubmit;
