import React, {useState, useCallback, useEffect, useRef, useMemo} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
    useLuniiEventTracking,
    useShoppingCart,
    useLuniiNavigation,
    useLuniiTranslation,
    useLuniiRecommendations,
    Img,
    MosaicCardItem,
    Button,
    buttonifyFromProps,
    product_item,
    ProductTableNameEnum,
    convertPrice,
    DropDownQuantity,
} from '..';
import {cssText, cssShadow} from '@ohoareau/css-utils';
import clsx from 'clsx';
import SvgCross24 from '../images/icons/Cross24';
import {CircularProgress, useMediaQuery, useTheme, Modal} from '@material-ui/core';
import {DeviceTag, Tag} from '../..';
import SvgCheck24 from "../images/icons/Check24";

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    root: {
        ...cssText(theme, 'standard', 'body', 'left'),
        position: 'absolute',
        width: 622,
        backgroundColor: 'white',
        border: 'none',
        borderRadius: theme.spacing(2),
        outline: 'none',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '95vh',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            left: 0,
            bottom: 0,
            top: 'unset',
            transform: 'unset',
            maxHeight: '75vh',
        },
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        '-ms-overflow-style': 'none',
        scrollbarWidth: 'none',
    },
    rootSuccessMessage: {
        width: 744,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        overflow: 'auto',
    },
    main: {
        padding: theme.spacing(4),
    },
    successMessage: {
        color: '#018B88 !important',
        display: 'flex',
        alignItems: 'center',
    },
    svg: {
        marginRight: 8,
    },
    titleContainer: {
        display: 'flex',
        ...cssText(theme, 'standard', 'title_2', undefined),
        marginBottom: theme.spacing(2),
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    close: {
        cursor: 'pointer',
    },
    item: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(2.5),
    },
    itemImage: {
        width: 96,
        maxWidth: 96,
        display: 'flex',
        alignItems: 'center',
    },
    image: {
        width: 96,
        maxWidth: 96,
        backgroundColor: '#faf9f8',
        ...cssShadow(theme, 'level_02'),
        height: 'auto',
    },
    itemProperties: {
        flex: 1,
        padding: theme.spacing(0, 2),
    },
    productTitle: {
        ...cssText(theme, 'standard', 'body_thick', undefined, undefined, '#2C3637'),
        display: '-webkit-box',
        overflow: 'hidden',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
    },
    locale: {
        ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#7C878B'),
        margin: '2px 0',
    },
    priceContainer: {
        display: 'flex',
        justifyContent: 'flexStart',
        alignItems: 'center',
    },
    oldPrice: {
        ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#A6B1B5'),
        textDecoration: 'line-through',
        marginRight: 8,
    },
    price: {
        ...cssText(theme, 'standard', 'body_thick', undefined, undefined, '#7C878B'),
    },
    tag: {
      marginTop: 4,
    },
    outOfStock: {
        ...cssText(theme, 'standard', 'secondary', undefined, undefined, 'status_alert'),
        marginBottom: theme.spacing(2),
    },
    buttons: {
        display: 'flex',
        marginTop: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
        '&>:last-child': {
            marginLeft: theme.spacing(2),
            [theme.breakpoints.down('sm')]: {
                margin: theme.spacing(2, 0, 0, 0),
            },
        },
    },
    btn: {
        width: '100%',
    },
    recommendations: {
        backgroundColor: '#FAF9F8',
        paddingTop: theme.spacing(1),
        width: '100%',
        minHeight: 420,
        [theme.breakpoints.down('sm')]: {
            minHeight: 200,
        },
    },
    recoLoader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    recoProducts: {
        padding: theme.spacing(4),
        backgroundColor: '#FAF9F8',
        borderBottomLeftRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
        },
    },
    recoTitle: {
        ...cssText(theme, 'standard', 'title_2', undefined, undefined, '#2C3637'),
        marginBottom: theme.spacing(2),
    },
    recoMosaic: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    cardItem: {
        alignSelf: 'stretch',
        width: 158,
        [theme.breakpoints.down('sm')]: {
            minWidth: '47%',
            maxWidth: '47%',
        },
        margin: theme.spacing(1),
        '&:first-child': {
            marginLeft: 0,
        },
        '&:last-child': {
            marginRight: 0,
        },
    },
}));

export function AddToCartModal({product = {}, isOpen = false, close, className, libelle, pageModel, forceAdd = false, forcedQuantity = 1, ...props}: AddToCartModalProps) {
    const classes = useStyles();
    const {t, exists} = useLuniiTranslation();
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const {goCheckout} = useLuniiNavigation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [recommendations, setRecommendations] = useState<product_item[]|undefined>(undefined);
    const [findProductRecommendations, {loading: loadingRecommendations}] = useLuniiRecommendations((data) => {
        setRecommendations(data.map((reco: product_item) => ({
            ...reco,
            buttonTarget: `${reco.buttonTarget}?libelle=cross_selling_ajout_panier`,
        })));
    });
    const handleTrackingEvent = useLuniiEventTracking();
    const [{addCartItem}] = useShoppingCart();
    const [quantity, setQuantity] = useState<number>(Number(forcedQuantity) || 1);
    const handleClose = useCallback(() => {
        close(false);
    }, [close]);
    const handleAddToCart = useCallback(() => {
        addCartItem(product.id, product.reference, quantity, product, libelle, pageModel);
        setShowSuccessMessage(true);
    }, [addCartItem, setShowSuccessMessage, product, quantity]);
    useEffect(() => {
        showSuccessMessage && product && findProductRecommendations(product.id, product.catalog);
    }, [showSuccessMessage, product]);
    const addFirstRender = useRef(true);
    useEffect(() => {
        if (addFirstRender.current) {
            addFirstRender.current = false;
            if (product.type === 'packs' || forceAdd) {
                handleAddToCart();
            }
        }
    }, [addFirstRender, handleAddToCart, forceAdd]);
    // 2 recommendations only for mobile, 4 for desktop/tablet
    const actualRecommendations = useMemo(() => {
        return recommendations ? recommendations.filter((reco: product_item) => reco.stock).slice(0, isMobile ? 2 : 4) : [];
    }, [isMobile, recommendations]);
    const {Button1, Button2, Button3} = buttonifyFromProps({
        buttonLabel: t('cartmodal_go_cart_button_label'),
        buttonTarget: goCheckout,
        button2Label: t('cartmodal_buy_button_label'),
        button2Target: handleAddToCart,
        button3Label: t('cartmodal_continue_button_label'),
        button3Target: handleClose,
    }, ['primary,size=large,endIcon=next-24',
        'primary,size=large,startIcon=basket-24',
        'plain,size=large']);
    const recoClickCallback = (product) => {
        product.type !== ProductTableNameEnum.Hardware && handleTrackingEvent({
            categorie: 'navigation',
            action: 'cross_selling',
            libelle: product.name || product.title,
        });
    };
    return (
        <Modal
            className={classes.modal}
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description">
            <div className={clsx(classes.root, showSuccessMessage && classes.rootSuccessMessage)}>
                <div className={classes.main}>
                    {product.stock && <>
                        <div className={classes.titleContainer}>
                            {showSuccessMessage ?
                                <div className={classes.successMessage}>
                                    <SvgCheck24 className={classes.svg} width={24} height={24}/>
                                    {t('cartmodal_success_message')}
                                </div>
                                : t('cartmodal_title')}
                            <SvgCross24 className={classes.close} onClick={handleClose}/>
                        </div>
                        <div className={classes.item}>
                            {product.image && (
                                <div className={classes.itemImage}>
                                    <Img className={classes.image} rounded={false} {...product.image}/>
                                </div>
                            ) || false}
                            <div className={classes.itemProperties}>
                                <div className={classes.productTitle}>
                                    {product.name}
                                    {showSuccessMessage && quantity > 1 && <span> x{quantity}</span> || false}
                                </div>
                                {product.locale && product.type === ProductTableNameEnum.Packs && <div className={classes.locale}>{t(`locale_${product.locale.toLowerCase()}_product_language`)}</div> || false}
                                <div className={classes.priceContainer}>
                                    {product.oldPrice && <div className={classes.oldPrice}>{convertPrice(product.oldPrice * quantity, product.currency)}</div> || false}
                                    <div className={classes.price}>{product.price === 0 ? t('product_price_free') : convertPrice(product.price * quantity, product.currency)}</div>
                                </div>
                                {product?.deviceTarget?.length && <div className={classes.tag}><DeviceTag size='xsmall' deviceTarget={product.deviceTarget}/></div> || false}
                                {!showSuccessMessage && product.subtype && product.version && exists(`cartmodal_product_infos_${product.subtype}_${product.version}`) &&
                                    <div className={classes.tag}>
                                        <Tag theme='tips' size='xsmall' label={t(`cartmodal_product_infos_${product.subtype}_${product.version}`)}/>
                                    </div>
                                || false}
                            </div>
                        </div>
                        {!showSuccessMessage && <DropDownQuantity quantitiesAvailable={4} quantity={quantity} onChange={setQuantity}/>}
                        <div className={classes.buttons}>
                            {showSuccessMessage
                                ? <>
                                    {Button1 && <Button1 className={classes.btn}/>}
                                    {Button3 && <Button3 className={classes.btn}/>}
                                </>
                                : Button2 && <Button2 className={classes.btn}/>
                            }
                        </div>
                    </> || false}
                    {!product.stock && <>
                        <div className={classes.outOfStock}>{t('product_out_of_stock')}</div>
                        <Button color='plain' onClick={handleClose}>{t('cartmodal_continue_button_label')}</Button>
                    </> || false}

                </div>
                {showSuccessMessage &&
                    <>
                        {loadingRecommendations && <div className={clsx(classes.recommendations, classes.recoLoader)}><CircularProgress/></div> || false}
                        {!loadingRecommendations && (recommendations || []).length !== 0 &&
                            <div className={classes.recommendations}>
                                <div className={classes.recoProducts}>
                                    <div className={classes.recoTitle}>{t('cartmodal_linkedProducts_title')}</div>
                                    <div className={classes.recoMosaic}>
                                        {actualRecommendations.map((reco: product_item) => (
                                            <div key={`${reco.id}-${reco.catalog}`} className={classes.cardItem}>
                                                <MosaicCardItem product={reco} onClickCb={() => {recoClickCallback(reco);}}/>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        || false}
                    </>
                }
            </div>
        </Modal>
    );
}

export interface AddToCartModalProps {
    product: any,
    className?: string,
    isOpen?: boolean,
    close: any,
    libelle?: string,
    pageModel?: string,
    forceAdd?: boolean,
    forcedQuantity?: number,
}

export default AddToCartModal;
