import * as React from 'react';
function SvgQuestion(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width={props.width || 24}
            height={props.height || 24}
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 12a8 8 0 11-16 0 8 8 0 0116 0zm2 0c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10zm-10.928 2.272c.139.16.325.24.56.24a.724.724 0 00.48-.16.83.83 0 00.272-.48c.053-.31.181-.587.384-.832.203-.256.507-.57.912-.944.363-.352.65-.645.864-.88.213-.245.395-.528.544-.848.16-.33.24-.693.24-1.088 0-.512-.15-.97-.448-1.376-.288-.416-.699-.741-1.232-.976-.523-.235-1.115-.352-1.776-.352-.619 0-1.237.107-1.856.32A5.224 5.224 0 008.4 7.76c-.15.117-.261.245-.336.384a1.069 1.069 0 00-.096.48c0 .277.075.517.224.72.16.192.352.288.576.288.203 0 .459-.085.768-.256.49-.288.88-.501 1.168-.64.299-.139.619-.208.96-.208.373 0 .667.09.88.272.224.17.336.405.336.704a1.4 1.4 0 01-.208.752 6.181 6.181 0 01-.624.816 7.207 7.207 0 00-.88 1.2c-.192.363-.288.816-.288 1.36 0 .267.064.48.192.64zm-.384 3.44c.256.256.581.384.976.384.384 0 .704-.128.96-.384.256-.267.384-.597.384-.992s-.128-.72-.384-.976a1.276 1.276 0 00-.96-.4c-.395 0-.72.133-.976.4-.256.256-.384.581-.384.976s.128.725.384.992z"
                fill={props.color || 'currentColor'}
            />
        </svg>
    );
}
export default SvgQuestion;
