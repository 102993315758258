import React, {useState} from 'react';
import clsx from 'clsx';
import {cssText} from '@ohoareau/css-utils';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import {Button, AddCreditCardForm, ForceTheme, CreditCardMy, useLuniiTranslation, Spinner, useCreditCardsFind,
    credit_card, ErrorPanel, useSubscriptionStripeCustomerPortal, useLuniiPage} from '../index';
import {getCurrentDateElements} from '../utils';
import SvgEdit from '../images/icons/Edit';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'stretch',
    },
    title: {
        ...cssText(theme, 'standard', 'title_1'),
        [theme.breakpoints.down('sm')]: {
            ...cssText(theme, 'standard', 'title_2'),
        },
    },
    content: {
        width: '100%',
        marginTop: theme.spacing(2),
        '& >*:not(:first-child)': {
            marginTop: theme.spacing(2),
        },
    },
    noCreditCards: {
        display: 'block',
        ...cssText(theme, 'standard', 'body'),
        [theme.breakpoints.down('sm')]: {
            ...cssText(theme, 'standard', 'secondary_body'),
        },
    },
    editCardButton: {
        padding: 0,
        marginTop: theme.spacing(3),
    },
    form: {
        marginTop: theme.spacing(2),
    },
    error: {
        margin: theme.spacing(3, 0, 0, 0),
    },
}));

export function MyCreditCards({title, className}: MyCreditCardsProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const {locale} = useLuniiPage() || {};
    const [hideInfos, setHideInfos] = useState<boolean>(false);
    const {creditCards, loading, refetch, error} = useCreditCardsFind();
    const [generateStripeCustomerPortalSession, {error: customerPortalError}] = useSubscriptionStripeCustomerPortal();
    const {currentMonth, currentYear} = getCurrentDateElements();
    const creditCardsContent = creditCards?.length > 0 ?
        creditCards.map(({card}: credit_card, index: number) => {
            const cardIsExpired = currentYear > card.expYear || currentYear === card.expYear && currentMonth > card.expMonth;
            return cardIsExpired ?
                (<ForceTheme theme={'danger-50'} key={card.id}>
                    <CreditCardMy {...card} number={index + 1} expired/>
                </ForceTheme>) :
                (<CreditCardMy {...card} number={index + 1} key={card.id}/>);
        }) :
        <span className={classes.noCreditCards}>{t('mycreditcards_no_creditcards')}</span>;
    const customerId = (creditCards || [])[0]?.card?.customerId;
    const handleStripeCustomerPortalSession = async () => {
        const {url} = await generateStripeCustomerPortalSession(customerId, locale);
        if (url) window.location.href = url;
    };
    return (
        <div className={clsx(classes.root, className)}>
            {title && <Typography variant={'h3'} className={classes.title}>{title}</Typography>}
            {!hideInfos && <div className={classes.content}>{loading ? <Spinner/> : <>{creditCardsContent}</>}</div>}
            {!hideInfos && customerId && (
                <Button
                    className={classes.editCardButton}
                    onClick={handleStripeCustomerPortalSession}
                    startIcon={<SvgEdit/>}
                    color={'tertiary'}
                    noPadding>
                    {t('mycreditcards_scp_link')}
                </Button>)
            }
            <AddCreditCardForm className={classes.form} onCardAdded={refetch} onFormToggle={setHideInfos} buttonMode={creditCards?.length > 0 ? 'variant' : 'default'}/>
            {customerPortalError && <ErrorPanel className={classes.error} error={customerPortalError} group={'customerPortalError'}/>}
            {!hideInfos && <ErrorPanel className={classes.error} error={error} group={'creditCard'}/>}
        </div>
    );
}

export interface MyCreditCardsProps {
    title?: string,
    className?: string
}

export default MyCreditCards;
