import React from 'react';
import {PushArgument} from '../../../ui';

export function ArgumentRightPushModule(props: any) {
    return (
        <PushArgument
            {...props}
            imagePosition="right"
        />
    );
}

export default ArgumentRightPushModule;
