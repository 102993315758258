import React, {useEffect} from 'react';
import {cssShadow, cssText} from '@ohoareau/css-utils';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {Tooltip} from '@material-ui/core';
import clsx from 'clsx';
import Clickable from '../Clickable';
import useLuniiTranslation from '../hooks/useLuniiTranslation';
import {
    convertPrice,
    Img,
    ItemStatusButton,
    LuniiProductProvider,
    MosaicCardItemDisplayEnum,
    OwnedStatusesEnum,
    ProductTableNameEnum,
    useHasMounted, useLuniiPage,
    useLuniiUser,
    useShoppingCart,
    useWishlist,
    wishlist_item,
} from '..';
import WishlistToggle from '../atoms/WishlistToggle';
import {useDeleteUserWishlist, usePostUserWishlist} from '../../hooks';

const useStyles = makeStyles((theme) => ({
    root: {
        ...cssText(theme, 'standard', 'body'),
        paddingBottom: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
    },
    rootRow: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingBottom: 0,
        lineHeight: 0,
        '& $illustration': {
            width: '50%',
            maxWidth: '50%',
            padding: theme.spacing(0, 1, 0, 0),
        },
        '& $infos': {
            width: '50%',
            maxWidth: '50%',
            padding: theme.spacing(0, 0, 0, 1),
        },
    },
    illustration: {
        width: '100%',
        maxWidth: '100%',
    },
    infos: {
        width: '100%',
        maxWidth: '100%',
    },

    disabled: {
        '&:hover': {
            cursor: 'default',
        },
    },
    titleLink: {
        maxWidth: '100%',
    },
    title: {
        ...cssText(theme, 'standard', 'body_thick'),
        marginTop: theme.spacing(1),
        position: 'relative',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxHeight: '56px',
    },
    titleRow: {
        marginTop: 0,
    },
    '@supports (-webkit-line-clamp: 1)': {
        title: {
            whiteSpace: 'unset',
            display: '-webkit-box',
            '-webkit-line-clamp': 1,
            '-webkit-box-orient': 'vertical',
            overflow: 'hidden',
        },
        titleRow: {
            '-webkit-line-clamp': 2,
        },
    },
    popper: {
        backgroundColor: '#423F37',
    },
    arrow: {
        color: '#423F37',
    },
    label: {
        ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#716C5E'),
        marginTop: theme.spacing(0.5),
    },
    buttons: {
        marginTop: theme.spacing(0.5),
        display: 'flex',
        alignItems: 'center',
        '& > :not(:first-child)': {
            margin: theme.spacing(0, 0, 0, 1),
        },
    },
    defaultButtons: {
        marginTop: theme.spacing(1),
    },
    img: {
        backgroundColor: 'white',
        height: 'auto !important',
        filter: 'drop-shadow(0px 2px 8px rgba(66, 63, 55, 0.1))',
        transition: 'box-shadow .35s ease',
        maxWidth: '100%',
        '&:hover': {
            ...cssShadow(theme, 'level_04'),
        },
    },
    svg: {
        marginLeft: 8,
    },
    price: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    priceTag: {
        ...cssText(theme, 'standard', 'body_thick'),
        marginRight: theme.spacing(1),
        margin: theme.spacing(0.5, 0),
    },
    reductionPercentage: {
        position: 'relative',
        ...cssText(theme, 'standard', 'secondary_body_thick', undefined, undefined, '#722613'),
        padding: '0 10px 0 7px',
        backgroundColor: '#FDB19E',
        clipPath: 'polygon(0 0, 0 100%, 85% 100%, 102% 50%, 85% 0)',
        borderRadius: 5,
        margin: theme.spacing(0.5, 1, 0.5, 0),
    },
    reductionOldPrice: {
        ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#A79F8E'),
        textDecoration: 'line-through',
        marginBottom: 1,
    },
}));

export function MosaicCardItem({
    className = '',
    product,
    target,
    buttonTarget,
    buttonLabel,
    buttonType,
    title,
    label,
    image,
    imageLoading,
    onClickCb,
    display = MosaicCardItemDisplayEnum.Column,
    onWishlistToggleClick,
    onWishlistToggleClickCb,
}: MosaicCardItemProps) {
    const hasMounted = useHasMounted();
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const {ownsPackReference, isLogged} = useLuniiUser();
    const [{packInCart}] = useShoppingCart();
    const page = useLuniiPage();
    const [
        {retrieveWishlistExecute: retrieveWishlist},
        {
            retrieveWishlistResult: {data: dataRetrieveWishlist},
        },
    ] = useWishlist(); // todo warning should use new useUserWishlist, usePostUserWishlist and userDeleteUserWishlist hooks
    const [addToUserWishlist] = usePostUserWishlist(retrieveWishlist);
    const [deleteFromUserWishlist] = useDeleteUserWishlist(retrieveWishlist);
    useEffect(() => {
        hasMounted && isLogged && retrieveWishlist();
    }, [hasMounted, isLogged, retrieveWishlist]);

    const showWishlistToggle = isLogged && product?.type === ProductTableNameEnum.Packs;
    const productWishlistIndex = dataRetrieveWishlist?.retrieveWishlist?.items?.findIndex(
        (item) => item?.objectId === product?.objectId,
    );
    const productInWishlist =
        isLogged &&
        productWishlistIndex > -1 &&
        !dataRetrieveWishlist?.retrieveWishlist?.items[productWishlistIndex]?._removed;

    const _product = product || {};
    const _img = image || _product.image || {};
    const _title = title || _product.title;
    const _target = target || buttonTarget || _product.target;
    const _label = label || _product.label;
    const _alt = (_img && _img.alt) || _product.name || _title;

    const ownedStatus = ownsPackReference(product?.reference)
        ? OwnedStatusesEnum.Owned
        : packInCart(product)
        ? OwnedStatusesEnum.InCart
        : OwnedStatusesEnum.ToBuy;

    const handleToggleClick = async (event: React.MouseEvent<any>) => {
        event.preventDefault();
        if (onWishlistToggleClick) {
            await onWishlistToggleClick(productInWishlist, product);
        }
        else {
            productInWishlist ? deleteFromUserWishlist({audiobookId: product.objectId, locale: page?.locale}) : addToUserWishlist({audiobookId: product.objectId, locale: page?.locale});
        }
        onWishlistToggleClickCb && onWishlistToggleClickCb(productInWishlist, product);
    };
    return (
        <LuniiProductProvider value={product}>
            <div
                className={clsx(
                    classes.root,
                    display === MosaicCardItemDisplayEnum.Row && classes.rootRow,
                    className,
                )}
            >
                <Clickable
                    onClick={_target}
                    onClickCb={onClickCb}
                    className={clsx(classes.illustration, !_target && classes.disabled)}
                >
                    {_img && (
                        <Img
                            className={classes.img}
                            {..._img}
                            alt={_alt}
                            loading={imageLoading}
                            objectFit="contain"
                        />
                    )}
                </Clickable>
                <div className={classes.infos}>
                    <Clickable
                        onClick={_target}
                        onClickCb={onClickCb}
                        className={clsx(classes.titleLink, !_target && classes.disabled)}
                    >
                        {_title && (
                            <Tooltip
                                title={_title}
                                placement="top"
                                arrow
                                classes={{tooltip: classes.popper, arrow: classes.arrow}}
                            >
                                <div
                                    className={clsx(
                                        classes.title,
                                        display === MosaicCardItemDisplayEnum.Row &&
                                            classes.titleRow,
                                    )}
                                >
                                    {_title}
                                </div>
                            </Tooltip>
                        )}
                        <div className={classes.label}>{_label}</div>
                        {product && (!buttonTarget || !buttonLabel) && (
                            <div className={classes.price}>
                                <div className={classes.priceTag}>
                                    {product.price === 0
                                        ? t('product_price_free')
                                        : convertPrice(product.price, product.currency)}
                                </div>
                                {product.reduction && (
                                    <>
                                        <div className={classes.reductionPercentage}>
                                            {product.reduction}
                                        </div>
                                        <div className={classes.reductionOldPrice}>
                                            {convertPrice(product.oldPrice, product.currency)}
                                        </div>
                                    </>
                                )}
                            </div>
                        )}
                    </Clickable>

                    {((buttonLabel && buttonTarget) || product) && (
                        <div className={clsx(classes.buttons, !product && classes.defaultButtons)}>
                            {hasMounted && (
                                <ItemStatusButton
                                    ownedStatus={ownedStatus}
                                    product={_product}
                                    buttonLabel={buttonLabel}
                                    buttonTarget={buttonTarget}
                                    buttonType={buttonType}
                                />
                            )}
                            {hasMounted &&
                                showWishlistToggle &&
                                ownedStatus !== OwnedStatusesEnum.Owned && (
                                    <WishlistToggle
                                        toggled={productInWishlist}
                                        onClick={handleToggleClick}
                                    />
                                )}
                        </div>
                    )}
                </div>
            </div>
        </LuniiProductProvider>
    );
}

export interface MosaicCardItemProps {
    className?: string;
    product?: any;
    target?: any;
    buttonTarget?: string;
    buttonLabel?: string;
    buttonType?: string;
    title?: any;
    label?: any;
    image?: any;
    imageLoading?: 'eager' | 'lazy';
    onClickCb?: Function;
    display?: MosaicCardItemDisplayEnum;
    onWishlistToggleClick?: (packInWishlist: boolean, p: wishlist_item) => Promise<any>;
    onWishlistToggleClickCb?: (packInWishlist: boolean, p: wishlist_item) => void;
}

export default MosaicCardItem;
