import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {pack_album_infos} from '../types';
import {cssShadow, cssText} from '@ohoareau/css-utils';
import {Img} from '../nucleons';
import {FormControlLabel, Radio, RadioGroup} from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        margin: theme.spacing(0, 0, 2),
    },
    formControlLabelRoot: {
        borderRadius: 8,
        ...cssShadow(theme, 'level_02'),
        position: 'relative',
        margin: theme.spacing(0, 0, 1),
        boxSizing: 'border-box',
        padding: theme.spacing(0, 1, 0, 0),
        height: 88,
        [theme.breakpoints.up('sm')]: {
            height: 96,
        },
        backgroundColor: '#fff',
        border: '2px solid transparent',
        transition: 'all .35s ease-out',
    },
    formControlLabelLabel: {
        display: 'block',
        boxSizing: 'border-box',
        width: '100%',
        height: '100%',
        padding: theme.spacing(1, 0, 1, 1),
        position: 'relative',
    },
    productContainer: {
        width: '100%',
        height: '100%',
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        position: 'relative',
    },
    itemImageWrapper: {
        margin: theme.spacing(0, 2, 0, 0),
        ...cssShadow(theme, 'level_02'),
        width: 72,
        [theme.breakpoints.up('sm')]: {
            width: 80,
        },
        lineHeight: 0,
    },
    itemTitle: {
        ...cssText(theme, 'standard', 'secondary_body_thick'),
        color: '#423F37',
    },
    itemText: {
        ...cssText(theme, 'standard', 'secondary_body'),
        color: '#716C5E',
    },
    itemSelected: {
        ...cssShadow(theme, 'level_03'),
        backgroundColor: '#FFF9EC',
        border: '2px solid #FBBD2A',
        zIndex: 1,
    },
}));

export function PackSelectList({items = [], onAlbumSelected, className = ''}: PackSelectListProps) {
    const classes = useStyles();
    const [canChoose, setCanChoose] = useState<boolean>(items?.length > 1);
    const [activeIdx, setActiveIdx] = useState<number | undefined>();
    useEffect(() => {
        if (items.length === 1) {
            onAlbumSelected(0);
        } else if (items.length > 1 && !canChoose) {
            setCanChoose(true);
        }
    }, [items, canChoose]);
    const handlePackSelect = (e) => {
        const parsedActiveIndex = parseInt(e.target.value);
        setActiveIdx(parsedActiveIndex);
        onAlbumSelected(parsedActiveIndex);
    };
    const firstItem = items && items.length === 1 && items[0];
    return (
        <div className={clsx(classes.root, className)}>
            {items.length > 1 ? (
                <RadioGroup onChange={handlePackSelect} value={`${activeIdx}`}>
                    {items.map((item, idx) => (
                        <FormControlLabel
                            classes={{
                                root: classes.formControlLabelRoot,
                                label: classes.formControlLabelLabel,
                            }}
                            className={clsx(idx === activeIdx && classes.itemSelected)}
                            value={`${idx}`}
                            control={<Radio color={'primary'} />}
                            labelPlacement={'start'}
                            key={item.productId}
                            label={
                                <div
                                    className={classes.productContainer}
                                    key={`${idx}_${item.productId}`}
                                >
                                    <div className={classes.itemImageWrapper}>
                                        <Img {...item.image} />
                                    </div>
                                    <div>
                                        <div className={classes.itemTitle}>{item.title}</div>
                                        <div
                                            className={classes.itemText}
                                        >{`${item.duration} | ${item.ageMin}+`}</div>
                                    </div>
                                </div>
                            }
                        />
                    ))}
                </RadioGroup>
            ) : (
                firstItem && (
                    <div className={classes.productContainer}>
                        <div className={classes.itemImageWrapper}>
                            <Img {...firstItem.image} />
                        </div>
                        <div>
                            <div className={classes.itemTitle}>{firstItem.title}</div>
                            <div
                                className={classes.itemText}
                            >{`${firstItem.duration} |  ${firstItem.ageMin}+`}</div>
                        </div>
                    </div>
                )
            )}
        </div>
    );
}

export interface PackSelectListProps {
    items: pack_album_infos[];
    onAlbumSelected: (index: number) => void;
    className?: string;
}

export default PackSelectList;
