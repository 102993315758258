import iconify from "../hocs/iconify";
import * as featureHooks from "../features";

export function describeButtonFromDefinition({label = undefined, type = undefined, target = undefined}: {label?: string|undefined, type?: string|undefined, target?: string|undefined} = {}) {
    let props = {};
    if (type) {
        // noinspection SuspiciousTypeOfGuard
        if ('string' === typeof type) {
            const parts = type.split(/\s*,\s*/g);
            const allowedKeys = {
                hover: true,
                clicked: true,
                color: true,
                disabled: true,
                icon: true,
                endIcon: true,
                startIcon: true,
                size: true,
                features: true,
                locale: true,
                removeIconIfNoLabel: true,
            }
            props = parts.reduce((acc, p) => {
                if (0 < p.indexOf('=')) {
                    const [a, b] = p.split('=');
                    const cleanedB = b.trim();
                    let x;
                    switch (cleanedB.toLowerCase()) {
                        case 'true':
                            x = true;
                            break;
                        case 'false':
                            x = false;
                            break;
                        case 'null':
                            x = null;
                            break;
                        case 'undefined':
                            x = undefined;
                            break;
                        case '[]':
                            x = [];
                            break;
                        case '{}':
                            x = {};
                            break;
                        default:
                            x = cleanedB;
                    }
                    if (allowedKeys[a]) acc[a] = x;
                } else {
                    if (allowedKeys['color']) {
                        switch (p.toLowerCase()) {
                            case 'primary':
                            case 'secondary':
                            case 'tertiary':
                            case 'plain':
                            case 'destructive':
                            case 'tertiary_alert':
                            case 'default':
                                acc['color'] = p.toLowerCase();
                                break;
                            default:
                                acc['color'] = 'primary';
                                break;
                        }
                    }
                }
                return acc;
            }, {});
            if (label) props['children'] = label;
            if (target) props['onClick'] = target;
            if (props['icon']) props['icon'] = iconify(props['icon'])({});
            if (props['startIcon']) props['startIcon'] = iconify(props['startIcon'])({});
            if (props['endIcon']) props['endIcon'] = iconify(props['endIcon'])({});
            if (props['features']) {
                const features = props['features'].split(/\s*;\s*/g);
                delete props['features'];
                features.forEach(feature => {
                    const hook = (featureHooks[feature] || {})['button'] || undefined;
                    hook && hook(props);
                });
                if (!props['onClick']) props['hide'] = 'true';
            }
            delete props['locale']; // temp prop not provided after.
            return props;
        }
        if (!label) {
            if (!props['icon'] && !props['startIcon'] && !props['endIcon']) {
                return undefined;
            }
            return {};
        }
        return {children: label};
    }
    if (!label) {
        if (!target) {
            if (!props['icon'] && !props['startIcon'] && !props['endIcon']) {
                return undefined;
            }
            return {};
        }
        return {onClick: target};
    }
    if (!target) {
        return {children: label};
    }
    return {children: label, onClick: target};
}

export default describeButtonFromDefinition
