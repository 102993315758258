import React, {useCallback} from 'react';
import {
    useLuniiProduct,
    ProductNavBar,
    useLuniiNavigation,
    useLuniiUser,
    useLuniiPageSections,
    useLuniiEventTracking,
} from '../../../ui';

export function ProductPageAppBarModule(props: ProductPageAppBarModuleProps) {
    const {user, logout} = useLuniiUser();
    const {goHome, goUserHome, goLibrary} = useLuniiNavigation();
    const product = useLuniiProduct();
    const {sections, section} = useLuniiPageSections();
    const handleEventTracking = useLuniiEventTracking();
    const handleLogoClick = useCallback(() => {
        goHome && goHome();
        handleEventTracking({categorie: 'navigation', libelle: 'accueil', action: 'menu'});
    }, [goHome, handleEventTracking]);
    const handleUserClick = useCallback(() => {
        user && goUserHome ? goUserHome() : logout;
        handleEventTracking({categorie: 'navigation', libelle: 'mon_compte', action: 'menu'});
    }, [user, goUserHome, logout, handleEventTracking]);
    const handleLibraryClick = useCallback(() => {
        user && goLibrary ? goLibrary() : logout;
        handleEventTracking({categorie: 'navigation', libelle: 'bibliotheque', action: 'menu'});
    }, [user, goLibrary, logout, handleEventTracking]);
    return (
        <ProductNavBar
            {...props}
            sections={sections}
            section={section}
            onLogoClick={handleLogoClick}
            onUserClick={handleUserClick}
            product={product}
            onLibraryClick={handleLibraryClick}
        />
    );
}

export interface ProductPageAppBarModuleProps {}

export default ProductPageAppBarModule;
