import React from 'react';
import {cssText} from '@ohoareau/css-utils';
import SvgExternalLink from '../images/icons/ExternalLink';
import Clickable from "../Clickable";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    root: {
        ...cssText(theme, 'standard', 'body'),
        flex: 1,
    },
    header: {
        ...cssText(theme, 'standard', 'overline_title_2'),
    },
    content: {
        display: 'flex',
        justifyContent: 'space-between',
        cursor: 'pointer',
        ...cssText(theme, 'standard', 'title_2', undefined, undefined, 'link'),
    },
}));

export function Job({header, title, url}: JobProps) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div className={classes.header}>{header}</div>
            <Clickable onClick={url} className={classes.content}>
                <div>{title}</div>
                <SvgExternalLink />
            </Clickable>
        </div>
    );
}

export interface JobProps {
    header?: any,
    title?: any,
    url?: string,
    icon?: any,
}

export default Job
