import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Popper from '@material-ui/core/Popper';
import Badge from '@material-ui/core/Badge';
import Fade from '@material-ui/core/Fade';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {cssBgColor} from '@ohoareau/css-utils';
import SvgShoppingBag from '../images/icons/ShoppingBag';
import {ShoppingCartWidget} from '../molecules/ShoppingCartWidget';
import {useShoppingCart} from '../hooks/useShoppingCart';
import {useHasMounted, useLuniiEventTracking} from '../hooks';
import {DynamicIcon} from '../nucleons';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
    },
    content: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        borderRadius: '100%',
        transition: 'background-color .35s ease-out',
        cursor: 'pointer',
    },
    opened: {
        backgroundColor: '#FEF0CE',
    },
    disabled: {
        cursor: 'default !important',
    },
    cart: {
        marginTop: 8,
    },
    badge: {
        ...cssBgColor(theme, 'primary'),
        userSelect: 'none',
        '-webkit-user-select': 'none',
    },
}));

export function ShoppingCartNavBarItem({
    defaultOpened = false,
    active = false,
}: ShoppingCartNavBarItemProps) {
    const classes = useStyles();
    const handleTrackingEvent = useLuniiEventTracking();
    const [{cart}] = useShoppingCart();
    const [anchorEl, setAnchorEl] = useState(null);
    const [opened, setOpened] = useState(defaultOpened);
    const hasMounted = useHasMounted();

    const handleToggleOpened = useCallback(
        (event) => {
            if (active) {
                return;
            }
            setOpened(!opened);
            setAnchorEl(anchorEl ? null : event.currentTarget);
            if (!opened) {
                handleTrackingEvent({categorie: 'navigation', action: 'menu', libelle: 'panier'});
            }
        },
        [active, opened, anchorEl, handleTrackingEvent],
    );
    const handleClickAway = useCallback(() => {
        setOpened(false);
        setAnchorEl(null);
    }, [setOpened]);

    const open = Boolean(anchorEl);
    const id = open ? 'shopping-cart' : undefined;

    const itemsQuantity = useMemo(
        () => (cart?.items || []).reduce((q: number, item: any) => item.quantity + q, 0),
        [cart],
    );

    useEffect(() => {
        if (opened) {
            document.addEventListener(
                'scroll',
                () => {
                    handleClickAway();
                },
                {once: true},
            );
        }
    }, [opened, handleClickAway]);

    if (!hasMounted) return null;

    return (
        <div className={classes.root}>
            <ClickAwayListener onClickAway={handleClickAway}>
                <div
                    className={clsx(
                        classes.content,
                        (opened || active) && classes.opened,
                        active && classes.disabled,
                    )}
                >
                    <Badge
                        badgeContent={itemsQuantity}
                        classes={{badge: classes.badge}}
                        onClick={handleToggleOpened}
                        overlap="rectangular"
                    >
                        {open ? (
                            <DynamicIcon type="close" height={24} width={24} />
                        ) : (
                            <SvgShoppingBag aria-describedby={id} />
                        )}
                    </Badge>
                    <Popper
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        transition
                        placement="bottom-end"
                        style={{zIndex: 1200}}
                        modifiers={{
                            offset: {
                                offset: '0px 30px',
                                enabled: true
                            },
                        }}
                    >
                        {({TransitionProps}) => (
                            <Fade {...TransitionProps} timeout={350}>
                                <div className={classes.cart}>
                                    <ShoppingCartWidget />
                                </div>
                            </Fade>
                        )}
                    </Popper>
                </div>
            </ClickAwayListener>
        </div>
    );
}

export interface ShoppingCartNavBarItemProps {
    defaultOpened?: boolean;
    active?: boolean; // allows to set active mode and to disable click
}

export default ShoppingCartNavBarItem;
