import {useLocation, WindowLocation} from '@reach/router';
import {useEffect, useRef} from 'react';
import {useLuniiCheckoutTracking, useLuniiCart, purchase} from '../ui';

const usePreviousLocation = (value: WindowLocation) => {
    const ref = useRef<WindowLocation>();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

export function useCheckoutTracking(
    pageTracking: boolean | undefined,
    model: string,
    trackingArgs?: {purchase: purchase},
) {
    const location = useLocation();
    const prevLocation = usePreviousLocation(location);
    const dataLayerCheckoutEvent = useLuniiCheckoutTracking({envModel: model});
    const [cart] = useLuniiCart();

    useEffect(() => {
        if (
            pageTracking !== false &&
            (!prevLocation || location.pathname !== prevLocation.pathname)
        ) {
            dataLayerCheckoutEvent(cart?.items, trackingArgs?.purchase);
        }
    }, [dataLayerCheckoutEvent, location, prevLocation, trackingArgs]);
}

export default useCheckoutTracking;
