import React from 'react';
import * as JsSearch from 'js-search';
import {useLuniiCatalog, catalog_context_item_value} from '../../../ui';
import {Catalog} from '../../organisms';

export function CatalogCustomModule(props: any) {
    const {fahProducts = [], fahMenu = []} = useLuniiCatalog() || {};

    // Search Engine Front side
    fahProducts
        .map((product: catalog_context_item_value) => ({
        ...product,
        titleN: product.title.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
        nameN: (product.name || '').normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
    }));

    const JsSearchEngine = new JsSearch.Search('id');
    JsSearchEngine.indexStrategy = new JsSearch.AllSubstringsIndexStrategy();
    JsSearchEngine.addIndex('title');
    JsSearchEngine.addIndex('titleN');
    JsSearchEngine.addIndex('name');
    JsSearchEngine.addIndex('nameN');
    JsSearchEngine.addDocuments(fahProducts);

    return (
        <Catalog
            {...props}
            mode='fah'
            items={fahProducts}
            searchEngine={JsSearchEngine}
            menu={fahMenu}
        />
    );
}

export default CatalogCustomModule;
