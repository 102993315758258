import {useCallback} from 'react';
import useLuniiUserPasswordUpdateApi from './useLuniiUserPasswordUpdateApi';

export function usePasswordUpdateSubmit(callback: Function|undefined = undefined) {
    const [passwordUpdate, {loading, error, ...infos}] = useLuniiUserPasswordUpdateApi();
    const onSubmit = useCallback(async values => {
        try {
            const loggedIn = values.loggedIn !== undefined ? values.loggedIn : true
            const data = await passwordUpdate({
                variables: {
                    data: {
                        loggedIn,
                        oldPassword: values.password,
                        newPassword: values.newPassword,
                        email: values.email
                    },
                },
            })
            callback && callback(((data || {}).data || {}).updatePassword, values.newPassword);
        } catch (e) {
            console.error(e);
        }
    }, [passwordUpdate, callback]);
    return [onSubmit, {loading: Boolean(loading), error: error || false, ...infos}] as [any, any];
}

export default usePasswordUpdateSubmit
