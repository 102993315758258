import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {cssFont, cssText} from '@ohoareau/css-utils';
import clsx from 'clsx';
import {DynamicIcon, ErrorPanel, useLuniiTranslation} from '../../../ui';
import {getThemeColor} from '../../../../configs';
import {useShoppingCartDiscountCodeDeleteSubmit} from '../../../ui/hooks';
import {Illustration} from '.';

const useStyles = makeStyles((theme) => ({
    root: {
        ...cssText(theme, 'standard', 'caption'),
        backgroundColor: getThemeColor('brand-primary', '50'),
        color:  getThemeColor('brand-primary', '800'),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: 8,
        padding: 0,
    },
    content: {
        display: 'flex',
        alignItems: 'center',
    },
    image: {
        alignSelf: 'end',
        height: 59,
        width: 48,
        minWidth: 48,
        marginRight: theme.spacing(2),
    },
    infos: {
        padding: '12px 0',
    },
    title: {
        ...cssFont(theme, 'standard', 'secondary_body_thick'),
        marginBottom: theme.spacing(.5),
    },
    discountIcon: {
        height: 24,
        minHeight: 24,
        maxHeight: 24,
        width: 24,
        minWidth: 24,
        maxWidth: 24,
        cursor: 'pointer',
        margin: theme.spacing(0, 2),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(0, 1, 0, 2),
        },
    },
    error: {
      marginTop: theme.spacing(1),
    },
}));

export function DiscountContainer({className = '', code, mandatory = false, onDelete = () => {}}: DiscountContainerProps) {
    const classes = useStyles();
    const [deleteProductDiscountCode, {error}] = useShoppingCartDiscountCodeDeleteSubmit(onDelete);
    const {t} = useLuniiTranslation();
    return (
        <div>
            <div className={clsx(classes.root, className)}>
                <div className={classes.content}>
                    <Illustration className={classes.image} name='cart_discount' width={48} height={59} loading='eager'/>
                    <div className={classes.infos}>
                        <div className={classes.title}>{t('checkout_cart_total_discount')}</div>
                        <div>{code}</div>
                    </div>
                </div>
                {!mandatory && <DynamicIcon
                    type='cross-24'
                    className={classes.discountIcon}
                    onClick={() => deleteProductDiscountCode({code})}
                /> || false}
            </div>
            {error && <ErrorPanel className={classes.error} error={error} group='deleteDiscountCode'/> || false}
        </div>
    );
}

export interface DiscountContainerProps {
    className?: string;
    code: string;
    onDelete?: Function;
    mandatory?: boolean;
}

export default DiscountContainer;
