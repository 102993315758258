import React, {useCallback, useState} from 'react';
import {Clickable, DropDown, useLuniiTranslation, convertLocaleToPrismicTranslation} from '../';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
    currentChoice: {
        position: 'relative',
        display: 'flex',
        alignItems: 'flex-start',
    },
    icon: {
        marginRight: 10,
        marginTop: '-3px',
    },
});

export function DropDownLanguage({locale, localesAvailable, onChange, disabled = false}: DropDownLanguageProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const [currentLocale, setLocale] = useState(locale);
    const handleClick = useCallback((e, locale) => {
        e.preventDefault();
        setLocale(locale);
        onChange(locale);
    }, [onChange, setLocale]);

    const currentChoice = (
        <div className={classes.currentChoice}>
            <div>{t(`locale_${convertLocaleToPrismicTranslation(currentLocale)}_product_language`)}</div>
        </div>
    );

    const choices = localesAvailable.map((locale, i) => (
        <Clickable key={`${locale}-${i}`} onClick={(e)=>handleClick(e, locale)}>
            <div>{t(`locale_${convertLocaleToPrismicTranslation(locale)}_product_language`)}</div>
        </Clickable>
    ));

    return (
        <DropDown currentChoice={currentChoice} choices={choices} disabled={disabled} popperPlacement={'bottom'} />
    );
}

export interface DropDownLanguageProps {
    disabled?: boolean,
    onChange: Function,
    locale: string,
    localesAvailable: string[],
}

export default DropDownLanguage;
