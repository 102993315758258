import React from 'react';
import {cssText} from '@ohoareau/css-utils';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import {mapPrismicTarget} from '../../utils';
import {Clickable, useLuniiEventTracking, useLuniiPage} from '..';

const useStyles = makeStyles(theme => ({
    menuItem: {
        marginBottom: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
    },
    menuTitle: {
        ...cssText(theme, 'standard', 'body_thick', undefined, undefined, 'title_1'),
        margin: theme.spacing(.5, 0),
    },
    childItem: {
        ...cssText(theme, 'standard', 'button_2', undefined, undefined, 'body'),
        cursor: 'pointer',
        margin: theme.spacing(.5, 0),
        textDecoration: 'none',
        '&:hover': {
            color: '#6CA8BE',
        },
    },
}));

export function FooterMainMenu({className = '', menu = [], col = undefined}: FooterMainMenuProps) {
    const classes = useStyles();
    const {locale} = useLuniiPage() || {};
    const handleEventTracking = useLuniiEventTracking();
    const handleMenuItemClick = (item: any, target: any) => {
        handleEventTracking({categorie: 'navigation', action: 'menu', libelle: target || item.label});
    };
    const items: any[] = menu.filter((item: any) => (item.priority > (2 * ((col || 1) - 1))) && (item.priority <= (2 * (col || 1))));
    if (!items.length) return null;
    return (
        <div className={clsx(className)}>
            {items.map((menuItem) => {
                const excludedLocales = (menuItem.excludedLocales || '').split(',');
                if (excludedLocales.indexOf((locale || '').slice(-2)) !== -1) return false;
                return (
                    <div className={classes.menuItem} key={Math.random().toString()}>
                        <div className={classes.menuTitle}>{menuItem.label}</div>
                        {(menuItem.items || []).map((childItem: any) => {
                            const t = mapPrismicTarget(childItem.target, locale);
                            const childExcludedLocales = (childItem.excludedLocales || '').split(',');
                            if (childExcludedLocales.indexOf((locale || '').slice(-2)) !== -1) return false;
                            return (
                                <Clickable
                                    key={Math.random().toString()}
                                    onClick={t}
                                    onClickCb={() => handleMenuItemClick(childItem, t)}>
                                    <div className={classes.childItem}>{childItem.label}</div>
                                </Clickable>
                            )
                        })}
                    </div>
                )
            })}
        </div>
    );
}

export interface FooterMainMenuProps {
    className?: string,
    menu?: any,
    col?: number,
}

export default FooterMainMenu;
