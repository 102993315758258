import React, {useState} from 'react';
import {cssBox, cssShadow, cssText} from '@ohoareau/css-utils';
import {A11y, FreeMode, Mousewheel, Navigation} from 'swiper';
import 'swiper/swiper.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/modules/a11y/a11y.min.css';
import 'swiper/modules/free-mode/free-mode.min.css';
import {Swiper, SwiperProps, SwiperSlide} from 'swiper/react/swiper-react.js';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {IconButton, useMediaQuery} from '@material-ui/core';
import Row from '../Row';
import Grid from '@material-ui/core/Grid';
import {module_text, module_title, WithItems} from '../types';
import {ContentItemMosaic1} from '../molecules';
import {Swiper as SwiperInstance} from 'swiper/types';
import clsx from 'clsx';
import ArrowLeft from '../images/icons/ArrowLeft';
import useSwiperRef from '../hooks/swiper/useSwiperRef';

const useStyles = makeStyles((theme) => ({
    root: {
        ...cssText(theme, 'standard', 'body'),
        ...cssBox(theme, undefined, (props) => theme[`background_color_${props.color || 'clear'}`]),
        position: 'relative',
        paddingBottom: 56,
        [theme.breakpoints.down('sm')]: {
            paddingBottom: theme.spacing(3),
        },
    },
    heading: {
        paddingBottom: theme.spacing(2),
    },
    title: {
        ...cssText(theme, 'standard', 'large_title'),
        [theme.breakpoints.down('sm')]: {
            ...cssText(theme, 'standard', 'title_1'),
        },
    },
    text: {
        ...cssText(theme, 'standard', 'body'),
        width: '100%',
        margin: theme.spacing(1, 0, 0),
        [theme.breakpoints.up('md')]: {
            width: '50%',
        },
        [theme.breakpoints.up('lg')]: {
            width: '33%',
        },
    },
    swiperWrapper: {
        position: 'relative',
        width: '100%',
        padding: theme.spacing(1, 0, 2),
    },
    slideWrapper: {
        height: 'auto',
        width: 244,
        [theme.breakpoints.up('sm')]: {
            width: 332,
        },
        [theme.breakpoints.up('md')]: {
            width: 276,
        },
    },
    control: {
        display: 'none',
        position: 'absolute',
        zIndex: 100,
        ...cssShadow(theme, 'level_02'),
        backgroundColor: '#ffffff',
        border: '1px solid #efedea',
        transform: 'translate3d(0, calc(-50% + 12px), 0)',
        color: '#2762e9',
        transition:
            'color .35s ease-out, box-shadow .35s ease-out, background-color .35s ease-out, opacity .35s ease-out',
        top: `calc(50% - ${theme.spacing(1)}px)`, // remove half of the bottom bottom padding from the swiperWrapper as it is twice the top padding
        '&:hover': {
            backgroundColor: '#ffffff',
        },
        '&.hidden': {
            opacity: 0,
            display: 'none !important',
            pointerEvents: 'none',
        },
    },
    controlDisabled: {
        'pointer-events': 'unset !important',
        opacity: '0 !important',
    },

    '@media (hover: hover)': {
        swiperWrapper: {
            '&:hover $control': {
                opacity: 1,
                ...cssShadow(theme, 'level_01'),
            },
        },
        control: {
            opacity: 0,
            display: 'block',
        },
    },
    prev: {
        left: 10,
        [theme.breakpoints.up('md')]: {
            left: 20,
        },
    },
    next: {
        right: 10,
        [theme.breakpoints.up('md')]: {
            right: 20,
        },
    },
}));

export function ItemsMosaic({
    title,
    text,
    mode = 'large',
    itemsImagePosition = 'top',
    items = [],
}: ItemsMosaicProps) {
    const classes = useStyles();
    const hasHover = useMediaQuery('(hover: hover)');
    const [scrollLock, setScrollLock] = useState<boolean>(true);
    const [canGoPrev, setCanGoPrev] = useState<boolean>(false);
    const [canGoNext, setCanGoNext] = useState<boolean>(false);

    const [nextEl, nextElRef] = useSwiperRef<HTMLButtonElement>();
    const [prevEl, prevElRef] = useSwiperRef<HTMLButtonElement>();

    const handleNav = (swiper: SwiperInstance) => {
        if (!hasHover || scrollLock) return;

        if (swiper.isEnd) {
            if (canGoNext) setCanGoNext(false);
        } else if (swiper.isBeginning) {
            if (canGoPrev) setCanGoPrev(false);
            if (!canGoNext) setCanGoNext(true);
        } else {
            if (!canGoPrev) setCanGoPrev(true);
            if (!canGoNext) setCanGoNext(true);
        }
    };

    const swiperProps: SwiperProps = {
        modules: [A11y, Navigation, FreeMode, Mousewheel],
        slidesPerView: 'auto',
        slidesOffsetAfter: 16,
        slidesOffsetBefore: 16,
        spaceBetween: 8,
        breakpoints: {
            960: {
                slidesOffsetAfter: 24,
                slidesOffsetBefore: 24,
                spaceBetween: 16,
            },
            1280: {
                slidesOffsetAfter: 150,
                slidesOffsetBefore: 150,
                spaceBetween: 24,
            },
        },
        freeMode: true,
        draggable: true,
        grabCursor: true,
        mousewheel: {
            invert: false,
            forceToAxis: true,
            releaseOnEdges: true,
        },
        navigation: {
            prevEl,
            nextEl,
        },
        resizeObserver: true,
        watchSlidesProgress: true,
        roundLengths: true,
        onResize: handleNav,
        onProgress: handleNav,
        onUpdate: handleNav,
        onBreakpoint: (swiper) => {
            swiper.setProgress(0);
        },
        onLock: (swiper) => {
            // Slider has more space than provided slides, should hide controls
            if (!scrollLock) setScrollLock(true);
            if (setTimeout) {
                setTimeout(() => {
                    swiper.setProgress(0);
                }, 0);
            }
        },
        onUnlock: () => {
            // Slider has less space than provided slides, should display controls
            if (scrollLock) setScrollLock(false);
        },
    };

    if (items.length === 0) return null;

    return (
        <div className={classes.root}>
            <Row padding={'custom1'} rootClassName={classes.heading}>
                <Grid item container lg={12} md={12} xs={12} direction={'column'}>
                    <div className={classes.title}>{title}</div>
                    {text && <div className={classes.text}>{text}</div>}
                </Grid>
            </Row>
            <Swiper {...swiperProps} className={classes.swiperWrapper}>
                {items.map((item, index) => (
                    <SwiperSlide key={`itm-${index}`} className={classes.slideWrapper}>
                        <ContentItemMosaic1 {...item} imagePosition={itemsImagePosition} />
                    </SwiperSlide>
                ))}
                <IconButton
                    classes={{root: classes.control, disabled: classes.controlDisabled}}
                    className={clsx(classes.prev, scrollLock && 'hidden')}
                    slot="container-start"
                    disabled={!canGoPrev}
                    ref={prevElRef}
                >
                    <ArrowLeft />
                </IconButton>
                <IconButton
                    classes={{root: classes.control, disabled: classes.controlDisabled}}
                    className={clsx(classes.control, classes.next, scrollLock && 'hidden')}
                    slot="container-end"
                    disabled={!canGoNext}
                    ref={nextElRef}
                >
                    <ArrowLeft style={{transform: 'rotate(180deg)'}} />
                </IconButton>
            </Swiper>
        </div>
    );
}

export interface ItemsMosaicProps extends WithItems {
    title?: module_title;
    text?: module_text;
    mode?: 'small' | 'large' | undefined;
    itemsImagePosition?: 'top' | 'bottom' | undefined;
}

export default ItemsMosaic;
