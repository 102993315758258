import React, {useCallback, useState} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {useCreditCardAddSubmit, useLuniiTranslation} from '../hooks';
import {DynamicIcon} from '../nucleons/DynamicIcon';
import {Button} from '../atoms/Button';
import CreditCardForm from './CreditCardForm';
import {FormProvider, useForm} from 'react-hook-form';
import clsx from 'clsx';
import {useStripe, useElements} from '@stripe/react-stripe-js';
import {StripeCardElement} from '@stripe/stripe-js';
import {ErrorPanel} from '../atoms/ErrorPanel';
import {Spinner} from '../atoms/Spinner';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
    },
    buttons: {
        display: 'flex',
        '& > :first-child': {
            marginRight: theme.spacing(2),
        },
        '& > *': {
            marginTop: theme.spacing(2),
        },
    },
    error: {
        marginTop: theme.spacing(2),
    },
    variantButton: {
        padding: 0,
        margin: 0,
    },
}));

export function AddCreditCardForm({className = {}, onCardAdded, onFormToggle, buttonMode = 'default'}: AddCreditCardFormProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const stripe = useStripe();
    const elements = useElements();
    const [openForm, setOpenForm] = useState<boolean>(false);
    const [cardComplete, setCardComplete] = useState<boolean>(false);
    const onOpenFormClick = useCallback(() => {
        onFormToggle(!openForm);
        setOpenForm(!openForm);
        setCardComplete(false);
    }, [setOpenForm, openForm]);
    const [onSubmit, {loading, error}] = useCreditCardAddSubmit(async () => {
        onOpenFormClick();
        onCardAdded();
    });
    const formCreditCardOwner = useForm({mode: 'onChange'});
    const {watch, handleSubmit, formState: {isSubmitting}} = formCreditCardOwner;
    const cardOwner = watch('cardOwner');
    const onFinalClick = useCallback(async() => {
        const cards = elements?.getElement('card') as StripeCardElement;
        cardOwner && cards && stripe?.createToken(cards).then(({error, token}: any) => {
            if (!error) {
                onSubmit({
                    stripeCardToken: token,
                    name: cardOwner,
                });
            }
        });
    }, [cardOwner, cardComplete]);
    return (
        <FormProvider {...formCreditCardOwner}>
            <div className={clsx(classes.root, className)}>
                {!openForm ?
                    <Button
                        className={clsx(buttonMode === 'variant' && classes.variantButton)}
                        onClick={onOpenFormClick}
                        color={buttonMode === 'variant' ? 'tertiary' : 'plain'}
                        disabled={loading}
                        noPadding={buttonMode === 'variant'}
                        startIcon={<DynamicIcon type={'add'}/>}>
                        {t(`forms_addcreditcard_button_open_form${buttonMode === 'variant' ? '_variant' : ''}`)}
                    </Button> :
                    <>
                        {loading && <Spinner overlay size={32}/>}
                        <CreditCardForm onCardComplete={setCardComplete}/>
                        <ErrorPanel className={classes.error} error={error} group={'creditCard'}/>
                        <div className={classes.buttons}>
                            <Button
                                onClick={handleSubmit(onFinalClick)}
                                size={'small_mobile_only'}
                                color={'primary'}
                                disabled={isSubmitting || loading || !cardComplete || !cardOwner}>
                                {t('button_label_save')}
                            </Button>
                            <Button
                                onClick={onOpenFormClick}
                                size={'small_mobile_only'}
                                color={'plain'}
                                disabled={isSubmitting || loading}>
                                {t('button_label_cancel')}
                            </Button>
                        </div>
                    </>
                }
            </div>
        </FormProvider>
    );
}

export interface AddCreditCardFormProps {
    className?: any,
    onCardAdded: any,
    onFormToggle: any,
    buttonMode?: 'variant' | 'default',
}

export default AddCreditCardForm;
