import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import {cssText} from '@ohoareau/css-utils';
import {audiobook_news} from '../../../types';
import {Button, DeviceTag} from '../..';
import {purifyHtml} from '../../../utils';

const useStyles = makeStyles((theme) => ({
    root: (props: any) => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        padding: '32px 32px 68px 32px',
        backgroundImage: `url(${props.backgroundImage})`,
        backgroundSize: 'contain',
        [theme.breakpoints.down('sm')]: {
            backgroundSize: 'cover',
            alignItems: 'flex-start',
            borderRadius: 16,
            padding: 32,
            height: '100%',
            boxSizing: 'border-box',
        },
    }),
    backgroundImage: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        height: '100%',
        zIndex: 10,
        objectFit: 'contain',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    leftImage: {
        left: 0,
    },
    rightImage: {
        right: 0
    },
    content: {
        position: 'relative',
        zIndex: 100,
        height: 205,
        width: 980,
        borderRadius: 16,
        backgroundColor: 'white',
        padding: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('md')]: {
            height: 181,
            width: 758
        },
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
            flexDirection: 'column',
            width: '100%',
            alignItems: 'flex-start',
        },
    },
    innerImage: {
        objectFit: 'fill',
        minWidth: 600,
        width: 600,
        height: 200,
        borderRadius: 8,
        margin: '0 16px 0 0',
        [theme.breakpoints.down('md')]: {
            minWidth: 450,
            width: 450,
            height: 180,
        },
        [theme.breakpoints.down('sm')]: {
            minWidth: '100%',
            width: '100%',
            maxWidth: '100%',
            height: 'auto',
            margin: '0 0 16px 0',
        },
    },
    infos: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexDirection: 'column',
    },
    tag: {
        marginBottom: 8,
    },
    texts: {
        marginBottom: 16,
    },
    title: {
        ...cssText(theme, 'standard', 'body_thick', undefined, undefined, '#423F37'),
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': 2,
        overflow: 'hidden',
        marginBottom: 4,
        [theme.breakpoints.down('md')]: {
            '-webkit-line-clamp': 1,
        },
        [theme.breakpoints.down('sm')]: {
            '-webkit-line-clamp': 2,
        },
    },
    description: {
        ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#716C5E'),
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': 3,
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            '-webkit-line-clamp': 5,
        },
    },
    actions: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));
export function NewsSlide({item}: NewsSlideProps) {
    const classes = useStyles({backgroundImage: item.backgroundImage});
    return (
        <div className={classes.root}>
            {item.leftImage && <img className={clsx(classes.backgroundImage, classes.leftImage)} src={item.leftImage} alt=''/> || false}
            {item.rightImage && <img className={clsx(classes.backgroundImage, classes.rightImage)} src={item.rightImage} alt=''/> || false}
            <div className={classes.content}>
                {item.innerImage && <img className={classes.innerImage} src={item.innerImage} alt=''/> || false}
                <div className={classes.infos}>
                    {/* use by priority : product target or news target */}
                    <DeviceTag className={classes.tag} size='xsmall' deviceTarget={item.product?.deviceTarget || item.target}/>
                    <div className={classes.texts}>
                        {item.title && <div className={classes.title} dangerouslySetInnerHTML={{__html: purifyHtml(item.title || '')}}/> || false}
                        {item.description && <div className={classes.description} dangerouslySetInnerHTML={{__html: purifyHtml(item.description || '')}}/> || false}
                    </div>
                    {item.product && <div className={classes.actions}>
                        <Button
                            text={item.button}
                            url={item.product?.target as string|Function}
                            theme='secondary'
                            size='small'/>
                    </div> || false}
                </div>
            </div>
        </div>
    );
}

export interface NewsSlideProps {
    item: audiobook_news;
}

export default NewsSlide;
