import React, {useCallback} from 'react';
import {cssBgColor} from '@ohoareau/css-utils';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {DynamicIcon} from '..';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    play: {
        width: 40,
        height: 40,
        padding: 4,
        margin: '0 24px',
        ...cssBgColor(theme, 'primary'),
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
    },
    svg: {
        cursor: 'pointer',
        width: 32,
        maxWidth: 32,
        height: 32,
        maxHeight: 32,
        color: '#4F3B0C',
    },
}));

export function ExtractCardAudioControls({onPlayClick = () => {}, onArrowClick = () => {}, playing = false}: ExtractCardAudioControlsProps) {
    const classes = useStyles();
    const handleClickBackward = useCallback(() => {
        onArrowClick(-1);
    }, [onArrowClick]);
    const handleClickForward = useCallback(() => {
        onArrowClick(1);
    }, [onArrowClick]);
    const playSvgType = playing ? 'pause-32' : 'play-32';
    return (
        <div className={classes.root}>
            <DynamicIcon type='backward-32' className={classes.svg} onClick={handleClickBackward}/>
            <div className={classes.play} onClick={onPlayClick}>
                <DynamicIcon type={playSvgType} className={classes.svg}/>
            </div>
            <DynamicIcon type='skip-32' className={classes.svg} onClick={handleClickForward}/>
        </div>
    );
}

export interface ExtractCardAudioControlsProps {
    playing?: boolean,
    onPlayClick?: any,
    onArrowClick?: any,
}

export default ExtractCardAudioControls
