import React, {createContext, useState} from 'react';

export const AudioContext = createContext<AudioContextInterface>({});

export function AudioController({children}: AudioControllerProps) {
    const [activeSourceUrl, setActiveSourceUrl] = useState<string|null>(null);
    const onPlay = (url) => {
        setActiveSourceUrl(url === activeSourceUrl ? null : url);
    };
    const onEnded = () => {
        setActiveSourceUrl(null);
    };
    const value = {activeSourceUrl, onPlay, onEnded, enabled: true};
    return (
        <AudioContext.Provider value={value}>{children}</AudioContext.Provider>
    );
}

export interface AudioContextInterface {
    activeSourceUrl?: any,
    onPlay?: any,
    onEnded?: any,
    enabled?: boolean,
}

export interface AudioControllerProps {
    children: any,
}

export default AudioController;
