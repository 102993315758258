import React from 'react';
import {cssText} from '@ohoareau/css-utils';
import {Typography, makeStyles} from '@material-ui/core';
import {Trans} from 'react-i18next';
import {Button, Clickable, useLuniiEventTracking, useLuniiTranslation} from '../index';
import SvgAdd from '../images/icons/Add';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        position: 'relative',
        boxSizing: 'border-box',
    },
    heading: {
        ...cssText(theme, 'standard', 'title_1', undefined, undefined, '#2C3637'),
        [theme.breakpoints.down('sm')]: {
            ...cssText(theme, 'standard', 'title_2', undefined, undefined, '#2C3637'),
        },
    },
    subtitle: {
        ...cssText(theme, 'standard', 'body_thick', undefined, undefined, '#2C3637'),
        marginTop: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            ...cssText(theme, 'standard', 'secondary_body_thick', undefined, undefined, '#2C3637'),
        },
    },
    list: {
        ...cssText(theme, 'standard', 'body', undefined, undefined, '#5F6769'),
        margin: theme.spacing(2, 0, 3, 0),
        paddingLeft: 25,
        [theme.breakpoints.down('sm')]: {
            ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#5F6769'),
            paddingLeft: 20,
        },
    },
    buttons: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
            '&>:not(:last-child)': {
                marginRight: theme.spacing(2),
            },
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            '&>:not(:first-child)': {
                marginTop: theme.spacing(2),
            },
        },
    },
}));

export function MyEmptySubscriptionInfos({
    className = '',
    onActivateClick = () => {},
}: MyEmptySubscriptionInfosProps) {
    const classes = useStyles();
    const {t, i18n} = useLuniiTranslation();
    const handleTrackingEvent = useLuniiEventTracking();
    const handleSubscriptionPresentationTracking = () => {
        handleTrackingEvent({
            categorie: 'interaction',
            action: 'abonnement',
            libelle: 'decouverte',
        });
    };
    const handleActivateSubscriptionTracking = () => {
        handleTrackingEvent({
            categorie: 'interaction',
            action: 'abonnement',
            libelle: 'activer_abonnement_cadeau',
        });
    };
    return (
        <div className={clsx(classes.root, className)}>
            <Typography variant='h3' className={classes.heading}>
                {t('my_subscription_discover_activate_infos_heading')}
            </Typography>
            <div className={classes.subtitle}>{t('my_subscription_discover_infos_subtitle')}</div>
            <ul className={classes.list}>
                <Trans i18n={i18n} i18nKey='my_subscription_discover_infos_text'>
                    ...
                    <li>...</li>
                    ...
                </Trans>
            </ul>
            <div className={classes.buttons}>
                <Clickable onClickCb={handleSubscriptionPresentationTracking}>
                    <Button
                        onClick='https://lunii/go_to_subscription_presentation'
                        size='small_mobile_only'
                        color='primary'
                    >
                        {t('my_subscription_discover_cta_text')}
                    </Button>
                </Clickable>
                <Clickable onClickCb={handleActivateSubscriptionTracking}>
                    <Button
                        onClick={onActivateClick}
                        size='small_mobile_only'
                        startIcon={<SvgAdd />}
                        color='plain'
                    >
                        {t('my_subscription_activate_infos_cta_text')}
                    </Button>
                </Clickable>
            </div>
        </div>
    );
}

export interface MyEmptySubscriptionInfosProps {
    className?: string;
    onActivateClick?: Function;
}

export default MyEmptySubscriptionInfos;
