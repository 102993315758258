import React from 'react';
import TextField, {TextFieldProps} from './TextField';

export function LastNameField(props: LastNameFieldProps) {
    return (
        <TextField name={'lastName'} {...props} />
    );
}

export interface LastNameFieldProps extends TextFieldProps {}

export default LastNameField