import React, {MouseEventHandler, useMemo} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';

const defaultImage = 'https://via.placeholder.com/350x250?text=%20';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        width: '100%',
        lineHeight: 0,
        borderRadius: 'unset',
    },
    rounded: {
        borderRadius: 16,
        [theme.breakpoints.down('sm')]: {
            borderRadius: 8,
        },
    },
    picture: {
        lineHeight: 0,
    },
}));

export function Img({className, url, loading = 'lazy', rounded = false, alt, tablet, mobile, objectFit = 'cover', objectPosition = undefined, height = undefined, width = undefined, onClick}: ImgProps) {
    const classes = useStyles();
    const style = useMemo(() => ({objectFit, objectPosition}), [objectFit, objectPosition]);
    const imgSrc = url || defaultImage;
    const srcAttr = loading === 'lazy' ? {'data-src': imgSrc} : {'src': imgSrc};
    const tabletSrcAttr = tablet && (loading === 'lazy' ? {'data-srcset': tablet.url} : {'srcSet': tablet.url});
    const mobileSrcAttr = mobile && (loading === 'lazy' ? {'data-srcset': mobile.url} : {'srcSet': mobile.url});
    const hw = !height && !width ? {height: 100, width: 100} : {height, width};
    const onClickProp = onClick ? {onClick: onClick as MouseEventHandler<HTMLElement>} : {};
    return (
        <picture className={classes.picture}>
            {mobile && mobile.url && <source media="(max-width: 960px)" {...mobileSrcAttr}/>}
            {tablet && tablet.url && <source media="(max-width: 1279px)" {...tabletSrcAttr}/>}
            <img
                className={clsx(classes.root, rounded && classes.rounded, loading === 'lazy' && 'lazyload', className)}
                src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
                alt={alt}
                style={style}
                {...srcAttr}
                loading={loading}
                {...onClickProp}
                {...hw}
            />
        </picture>
    );
}

export interface ImgProps {
    className?: any,
    url?: string,
    alt?: string,
    height?: number,
    width?: number,
    tablet?: any,
    mobile?: any,
    objectFit?: any,
    objectPosition?: any,
    rounded?: boolean,
    loading?: 'lazy' | 'eager',
    onClick?: Function,
}

export default Img;
