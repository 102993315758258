import {useContext} from "react";
import LuniiCartContext from "../contexts/LuniiCartContext";
import {cart} from "../types";

export const useLuniiCart = (): [cart|undefined, (cart) => any|undefined, () => {}] => {
    const {cart, setCart, resetCart} = useContext(LuniiCartContext);
    return [cart, setCart, resetCart];
};

export default useLuniiCart
