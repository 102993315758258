import * as React from 'react';
function SvgCheck24(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.707 6.293a1 1 0 010 1.414l-9 9a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L10 14.586l8.293-8.293a1 1 0 011.414 0z"
                fill={props.color || 'currentColor'}
            />
        </svg>
    );
}
export default SvgCheck24;
