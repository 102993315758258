import React, {useCallback} from 'react';
import {cssText} from '@ohoareau/css-utils';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {DropDownLanguage} from "../atoms";
import useLuniiTranslation from "../hooks/useLuniiTranslation";
import {useLuniiEventTracking} from '../hooks';
import {navigate} from 'gatsby-link';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    item: {
        display: 'flex',
        flexDirection: 'column',
        margin: 24,
        '&:first-child': {
            marginLeft: 0,
        },
        '&:last-child': {
            marginRight: 0,
        },
    },
    title: {
        ...cssText(theme, 'standard', 'overline_title_2', undefined, undefined, '#908977'),
        marginBottom: 4,
    },
    content: {
        ...cssText(theme, 'standard', 'title_2', undefined, undefined, '#908977'),
        [theme.breakpoints.down('sm')]: {
            ...cssText(theme, 'standard', 'body_thick', undefined, undefined, '#908977'),
        },
    },
}));

export function ProductDescription({items = [], locales}: ProductDescriptionProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const handleEventTracking = useLuniiEventTracking();
    const handleChange = useCallback((locale: any) => {
        handleEventTracking({
            categorie: 'interaction',
            action: 'selecteur',
            libelle: t(`locale_${locale}_product_language`)
        });
        if (!locales.languages) return;
        const url = locales.languages.find(l => locale === l.locale).url;
        if (url) navigate(url);
    }, [handleEventTracking, locales]);
    if (!locales) return null;
    const locale = locales.locales[0];
    return (
        <div className={classes.root}>
            {items.map(({title, content}, index) => (
                <div className={classes.item} key={index}>
                    <div className={classes.title}>{title}</div>
                    <div className={classes.content}>{content}</div>
                </div>
            ))}
            {Object.keys(locales || {}).length > 0 && (
                <div className={classes.item}>
                    <div className={classes.title}>{locales.title}</div>
                    <div className={classes.content}>
                        {locales.languages &&
                            <DropDownLanguage locale={locale} localesAvailable={locales.languages.map(elt => elt.locale)} onChange={handleChange}/>
                        }
                        {!locales.languages && <div>{t(`locale_${locale}_product_language`)}</div>}
                    </div>
                </div>
            )}
        </div>
    );
}

export interface ProductDescriptionProps {
    items?: any[],
    locales?: any,
}

export default ProductDescription
