import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import clsx from "clsx";

const useStyles = makeStyles({
    root: {
        textDecoration: 'unset',
        width: 'max-content',
        '&:hover': {
            textDecoration: 'unset',
            cursor: 'pointer',
        }
    }
});

export function InternalLink({children, className, linkComponent: LinkComponent, ...props}) {
    const classes = useStyles();
    return (
        <LinkComponent className={clsx(className, classes.root)} {...props}>
            {children}
        </LinkComponent>
    );
}

export default InternalLink
