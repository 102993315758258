import {useState, useEffect} from 'react';
import {useLuniiEventTracking, useLuniiPage, wishlist_item} from '../ui';

export const useItemsTracking = (tracking: boolean, items: any[]) => {
    const [itemsTracked, setItemsTracked] = useState(items);
    const handleEventTracking = useLuniiEventTracking();
    const {...p} = useLuniiPage();

    useEffect(() => {
        const pageModel = p?.pageModel;
        tracking &&
            setItemsTracked(
                (items || []).map((item: any, i: number) => ({
                    ...item,
                    onClickCb: () =>
                        handleEventTracking(
                            {
                                categorie: 'ecommerce',
                                action: 'clic_produit',
                            },
                            {
                                ...item.product,
                                position: i + 1,
                            },
                        ),
                    onWishlistToggleClickCb: (
                        productInWishlist: boolean,
                        product: wishlist_item,
                    ) => {
                        handleEventTracking(
                            {
                                categorie: 'ecommerce',
                                action: productInWishlist
                                    ? 'retrait_liste_souhaits'
                                    : 'ajout_liste_souhaits',
                                libelle: `${pageModel}`,
                                valeur: Math.round(product.priceExclTax / 100) * 100,
                            },
                            product,
                        );
                    },
                })),
            );
    }, [handleEventTracking]);

    return itemsTracked;
};

export default useItemsTracking;
