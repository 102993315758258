import React from 'react';
import TextField, {TextFieldProps} from './TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {cssText} from '@ohoareau/css-utils';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
    },
    input: {
        '&::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
        '&::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
        '&[type=number]': {
            '-moz-appearance': 'textfield',
        },
    },
    suffix: {
        ...cssText(theme, 'standard', 'body_thick', undefined, undefined, '#423f37'),
        transform: 'scale(1.2)',
        position: 'absolute',
        top: 28,
        right: 20,
    },
}));

export function PriceField({name, currency, suffixClassName = '', onBlur, min, max, ...props}: PriceFieldProps) {
    const classes = useStyles();
    const handleOnKeyPress = (e: any) => {
        if (/^[eE+-]$/i.test(e?.key)) e?.preventDefault();
    };
    const handleOnBlur = (e: any) => {
        const value = e?.target?.value || '';
        let amount: any = undefined;
        if (min && parseFloat(value) <= min) amount = parseFloat(min.toString()).toFixed(2);
        else if (max && parseFloat(value) >= max) amount = parseFloat(max.toString()).toFixed(2);
        else if (value.length > 0) amount = parseFloat(value).toFixed(2);
        onBlur(amount);
    };
    return (
        <div className={classes.root}>
            <TextField
                inputClassName={classes.input}
                name={name}
                type='number'
                {...props}
                onKeyPress={handleOnKeyPress}
                onBlur={handleOnBlur}/>
            <span className={clsx(classes.suffix, suffixClassName)}>{currency}</span>
        </div>
    );
}

export interface PriceFieldProps extends TextFieldProps {
    name: string,
    currency: string
    suffixClassName?: string,
    onBlur?: any
    min?: string|number,
    max?: string|number
}

export default PriceField;
