import {SEND_SUBSCRIPTION_INFOS_FREE_MONTH, useLuniiApi} from '../..';

interface params {
    options?: any
}
export function useSubscriptionFreeMonth({options}:params = {}) {
    const {gql, useMutation} = useLuniiApi();
    return useMutation(
        SEND_SUBSCRIPTION_INFOS_FREE_MONTH(gql),
        {
            notifyOnNetworkStatusChange: true,
            ...options,
        },
    );
}

export default useSubscriptionFreeMonth;
