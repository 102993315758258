import React from 'react';
import {cssFont} from '@ohoareau/css-utils';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import {StripeError} from '@stripe/stripe-js';
import {ApolloError} from '@apollo/client';
import {Alert} from '../../components';
import {ErrorItem} from '..';

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(2, 0), // bad practice, should need refacto
    },
    error: {
        ...cssFont(theme, 'standard', 'secondary_body'),
    }
}));

export function ErrorPanel({className = '', error = undefined, group = 'default', locale = undefined}: ErrorPanelProps) {
    const classes = useStyles();
    if (!error) return null;
    let displayedErrors: any;
    if (error?.graphQLErrors?.length) {
        displayedErrors = (<div>{error.graphQLErrors.map((e) => <ErrorItem className={classes.error} key={Math.random()} error={e} group={group} locale={locale}/>)}</div>);
    } else if (error && error.code) {
        displayedErrors = <div><ErrorItem className={classes.error} error={error} group={group} locale={locale}/></div>
    } else {
        return null;
    }
    return <Alert className={clsx(classes.root, className)} description={displayedErrors} theme='error'/>
}

export interface ErrorPanelProps {
    className?: string,
    group?: string,
    error?: {graphQLErrors?: any[], code?: string} | Partial<StripeError> & ApolloError,
    locale?: string,
}

export default ErrorPanel;
