import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {cssBgColor, cssText} from '@ohoareau/css-utils';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    root: ({size}: Partial<BulletNumberProps>) => ({
        ...cssBgColor(theme, 'secondary'),
        ...cssText(theme, 'standard', size === 'big' ? 'title_2' : 'body_thick', undefined, 'bullet', 'bullet'),
        height: size === 'big' ? 32 : 24,
        width: size === 'big' ? 32 : 24,
        borderRadius: size === 'big' ? 16 : 12,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }),
}));

export interface BulletNumberProps extends React.HTMLAttributes<HTMLElement> {
    number: string,
    size?: 'big' | 'small',
}

export function BulletNumber ({number, size = 'big', className = '', ...props}: BulletNumberProps) {
    const classes = useStyles({size});
    return (
        <div
            className={clsx(classes.root, className)}
            {...props}
        >
            {number}
        </div>
    );
}

export default BulletNumber;
