import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core';
import {useLegacyGiftCardMerge, useLegacyGiftCardFind, useLuniiTranslation} from '../hooks';
import {Modal} from './Modal';
import {Trans} from 'react-i18next';
import {cssText} from '@ohoareau/css-utils';
import {buttonifyFromProps} from '../hocs';
import ErrorPanel from '../atoms/ErrorPanel';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: 560,
        [theme.breakpoints.down('md')]: {
            maxWidth: 450,
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
        },
    },
    body: {
        ...cssText(theme, 'standard', 'body', undefined, undefined, '#716C5E'),
    },
    infos: {
        ...cssText(theme, 'standard', 'body_thick', undefined, undefined, '#716C5E'),
    },
    error: {
        margin: theme.spacing(2, 0, 0, 0),
    },
}));

export function LegacyGiftCardModal({onMergeSuccess}: LegacyGiftCardModalProps) {
    const classes = useStyles();
    const {t, i18n} = useLuniiTranslation();
    const [open, setOpen] = useState<boolean>(true);
    const {legacyGiftCard: {legacy}} = useLegacyGiftCardFind();
    const [onSubmit, {loading: mergeLoading, error: mergeError}] = useLegacyGiftCardMerge(() => {
        setOpen(false);
        onMergeSuccess();
    });
    const handleClose = () => {
        setOpen(false);
    };
    const {Buttons: ModalActions} = buttonifyFromProps({
        buttonLabel: t('button_label_cancel'),
        buttonTarget: handleClose,
        button2Label: t('legacy_gift_card_validate_button'),
        button2Target: onSubmit,
    }, [`plain,disabled=${mergeLoading}`, `primary,disabled=${mergeLoading}`]);
    if (!legacy) return null;
    return (
        <Modal
            className={classes.root}
            open={open}
            onCloseCallback={handleClose}
            title={t('legacy_gift_card_modal_title')}
            body={<div className={classes.body}><Trans i18n={i18n} i18nKey={'legacy_gift_card_modal_body'} components={{1: <span className={classes.infos}/>}}/></div>}
            actions={ModalActions && <ModalActions/>}
            errors={<ErrorPanel className={classes.error} error={mergeError} group={'giftCard'}/>}/>

    );
}

export interface LegacyGiftCardModalProps {
    onMergeSuccess?: any,
}

export default LegacyGiftCardModal;
