import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
    root: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
    }
});

export function FullScreenLayout({children}: any) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            {children || ''}
        </div>
    );
}

export default FullScreenLayout