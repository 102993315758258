import {useCallback, useState} from 'react';
import {useElements, useStripe} from '@stripe/react-stripe-js';
import {StripeCardElement, StripeError} from '@stripe/stripe-js';
import {StripeBillingDetails} from "../types";

export function useCreditCardRetrievePaymentMethod(callback, billingDetails: StripeBillingDetails) {
    const [stripeError, setStripeError] = useState<StripeError|undefined>();
    const [loading, setLoading] = useState(false);
    const stripe = useStripe();
    const elements = useElements();
    const onCreatePaymentMethod = useCallback(async () => {
        if (!elements || !stripe) return;
        try {
            setLoading(true);
            const {error, paymentMethod} = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement('card') as StripeCardElement,
                billing_details: billingDetails
            });
            setLoading(false);
            if (error) {
                setStripeError(error);
            } else if (!error && stripeError) {
                setStripeError(undefined);
            }
            callback && callback(paymentMethod);
        } catch (e) {
            console.error(e)
        }
    }, [callback, billingDetails, stripe, stripeError, setStripeError, setLoading]);
    return [onCreatePaymentMethod, { loading, error: stripeError || false }];
}

export default useCreditCardRetrievePaymentMethod
