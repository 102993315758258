import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import clsx from "clsx";

const useStyles = makeStyles({
    root: {
        textDecoration: 'unset',
        width: 'max-content',
    }
});

export function SubmitButton({active, children, className, linkComponent, ...props}) {
    const classes = useStyles();
    return (
        <button className={clsx(classes.root, className)} type={'submit'} {...props}>
            {children}
        </button>
    );
}

export default SubmitButton