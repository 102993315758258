import React, {useEffect, useMemo, useReducer} from 'react';
import {NavigateFn, NavigateOptions, Redirect, useNavigate, Router} from '@reach/router';
import {makeStyles} from '@material-ui/core';
import {cssFont, cssText} from '@ohoareau/css-utils';
import {
    buttonifyFromProps,
    ForceTheme,
    LuniiPaymentProvider,
    LuniiSpaProvider,
    Modal,
    page_context_value,
} from '../ui';
import {usePageContext} from '../hooks';
import {page} from '../types';
import {routes} from '../configs/routes/subscription';
import * as screens from './screens/subscription';
import {PrivateRoute, PublicRoute, Page} from '.';
import {availableForSubscription, langs, stripePk, stripePkUS} from '../../configs/site';
import {
    LuniiSubscriptionCheckoutProvider,
    SubscriptionCheckoutAction,
    SubscriptionCheckoutActionType,
    SubscriptionCheckoutState,
} from '../contexts';
import {PageAppBarModule} from './modules/app_bar/PageAppBarModule';
import {SpaLayout} from './layouts/SpaLayout';
import {menuClean} from '../services/property-mappers/menu';

const subscriptionCheckoutInitialState = {
    selected: null,
    modal: {
        open: false,
        title: null,
        body: null,
        actions: null,
    },
    success: false,
};

function subscriptionCheckoutReducer(
    state: SubscriptionCheckoutState,
    action: SubscriptionCheckoutAction,
) {
    switch (action.type) {
        case SubscriptionCheckoutActionType.UPDATE_SELECTED_SUBSCRIPTION:
            return {
                ...state,
                selected: action.payload.selected,
            };
        case SubscriptionCheckoutActionType.OPEN_MODAL:
            return {
                ...state,
                modal: {
                    ...action.payload.modal,
                },
            };
        case SubscriptionCheckoutActionType.CLOSE_MODAL:
            return {
                ...state,
                modal: {
                    ...subscriptionCheckoutInitialState.modal,
                },
            };
        case SubscriptionCheckoutActionType.CHECKOUT_SUCCESS:
            return {
                ...state,
                success: true,
            };
        default:
            return state;
    }
}

const useClasses = makeStyles((theme) => ({
    errorModalTitle: {
        display: 'flex',
        cursor: 'default',
        alignItems: 'center',
        '& >*': {
            display: 'block',
        },
        ...cssText(theme, 'standard', 'title_2'),
    },
    errorModalBody: {
        ...cssText(theme, 'standard', 'body', undefined, undefined, '#5F6769'),
    },
}));

export function Subscription(props: SubscriptionProps) {
    const classes = useClasses();
    const prefix = '/subscription';
    const baseNavigate: NavigateFn = useNavigate();
    const navigate = async (path: string, options?: NavigateOptions<{}>) =>
        baseNavigate(`${prefix}${path}`, options);
    const pageContext = useMemo(
        () => ({
            page: {
                uid: 'subscription',
                theme: 'default',
                lang: '@cart',
                product: undefined,
                head: {
                    link: [
                        {
                            rel: 'preload',
                            as: 'script',
                            href: 'https://cdnjs.cloudflare.com/ajax/libs/lazysizes/5.3.2/lazysizes.min.js',
                        },
                    ],
                    script: [
                        {
                            src: 'https://cdnjs.cloudflare.com/ajax/libs/lazysizes/5.3.2/lazysizes.min.js',
                            type: 'text/javascript',
                            async: true,
                        },
                    ],
                    title: 'Lunii - Abonnement',
                },
                favicon: undefined,
                modules: [],
                alernateLangs: [],
                language: undefined,
                languages: langs.map((lang) => ({
                    id: lang,
                    label: `locale_${lang.replace('-', '_').toLowerCase()}_label`,
                    target: `/${lang}/`,
                })),
                pageModel: undefined,
                features: {
                    pageTracking: false,
                },
                menu: props?.pageContext?.page?.menu || [],
                menuImage: props?.pageContext?.page?.menuImage || {},
                pagesByModel: props?.pageContext?.page?.pagesByModel || {},
                env: props?.pageContext?.page?.env,
            },
        }),
        [langs, props.pageContext],
    );

    const {
        locale,
        navigation,
        user: userContext,
        i18n,
    } = usePageContext(pageContext as unknown as page);

    const menu = menuClean((pageContext?.page as any).menu[locale || '']);
    const menuImage = ((pageContext?.page.menuImage as any) || {})[locale || ''];

    const stripe = useMemo(() => {
        if (locale) {
            const stripeUS = ['US', 'CA', 'MX'];
            const country = (locale.split('-')[1] || '').toUpperCase();
            const pk = stripeUS.indexOf(country) !== -1 ? stripePkUS : stripePk;
            return pk
                ? {
                      pk,
                  }
                : undefined;
        }
    }, [locale]);

    const [subscriptionCheckoutState, subscriptionCheckoutDispatch] = useReducer(
        subscriptionCheckoutReducer,
        subscriptionCheckoutInitialState,
    );
    const luniiSubscriptionCheckoutProviderValue = {
        state: subscriptionCheckoutState,
        dispatch: subscriptionCheckoutDispatch,
    };

    const onModalCloseCallback = () => {
        navigation.goHome && navigation.goHome();
        subscriptionCheckoutDispatch({type: SubscriptionCheckoutActionType.CLOSE_MODAL});
    };

    const {Buttons: ErrorModalActions} = buttonifyFromProps({
        buttonLabel: i18n.t('subscription_already_owned_gohome_button_label'),
        buttonType: 'primary',
        buttonTarget: (e: any) => {
            navigation.goUserHome && navigation.goUserHome(e, {category: 'subscription'});
            subscriptionCheckoutDispatch({type: SubscriptionCheckoutActionType.CLOSE_MODAL});
        },
    });
    useEffect(() => {
        if (
            userContext.user?.subscription &&
            !subscriptionCheckoutState.success &&
            !subscriptionCheckoutState.modal.open
        ) {
            const title = i18n.t<string>('subscription_already_owned_modal_title');
            const body = i18n.t<string>('subscription_already_owned_modal_body');

            subscriptionCheckoutDispatch({
                type: SubscriptionCheckoutActionType.OPEN_MODAL,
                payload: {
                    modal: {
                        open: true,
                        title,
                        body,
                        actions: ErrorModalActions && <ErrorModalActions />,
                    },
                },
            });
        }
    }, [
        subscriptionCheckoutState.success,
        subscriptionCheckoutState.modal.open,
        userContext.user?.subscription,
        classes.errorModalTitle,
        classes.errorModalBody,
        i18n,
        ErrorModalActions,
    ]);

    // Prevent spa acces to users from locales other than available countries
    if (!availableForSubscription(locale)) navigation?.goHome();

    return (
        <LuniiSpaProvider
            value={{
                prefix,
                navigate,
                screens,
            }}
        >
            <LuniiPaymentProvider
                stripe={stripe}
                locale={locale}
            >
                <LuniiSubscriptionCheckoutProvider value={luniiSubscriptionCheckoutProviderValue}>
                    <Page pageContext={pageContext}>
                        <ForceTheme theme="default">
                            <PageAppBarModule
                                menu={menu || []}
                                menuImage={menuImage}
                            />
                        </ForceTheme>
                        <SpaLayout>
                            <Router basepath={prefix}>
                                {routes.map((route: any, i: number) => {
                                    const key = `${i}-${route.path}`;
                                    if (route.redirect) {
                                        return (
                                            <Redirect
                                                noThrow
                                                key={key}
                                                from={route.path}
                                                to={`${prefix}/${route.redirect}`}
                                            />
                                        );
                                    }
                                    if (route.secured) {
                                        return (
                                            <PrivateRoute
                                                key={key}
                                                {...route}
                                                prefix={prefix}
                                            />
                                        );
                                    }
                                    return (
                                        <PublicRoute
                                            key={key}
                                            {...route}
                                            prefix={prefix}
                                        />
                                    );
                                })}
                            </Router>
                            <Modal
                                titleClassName={classes.errorModalTitle}
                                bodyClassName={classes.errorModalBody}
                                {...subscriptionCheckoutState.modal}
                                onCloseCallback={onModalCloseCallback}
                            />
                        </SpaLayout>
                    </Page>
                </LuniiSubscriptionCheckoutProvider>
            </LuniiPaymentProvider>
        </LuniiSpaProvider>
    );
}

export interface SubscriptionProps {
    pageContext?: page_context_value & {
        page: page;
    };
}

export default Subscription;
