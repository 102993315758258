import React from 'react';

export function UnknownModeUnknownModule({module, mode}: UnknownModuleProps) {
    return null;
}

export interface UnknownModuleProps {
    module: string,
    mode: string,
}

export default UnknownModeUnknownModule