import React from 'react';
import {ArgumentModuleSlideshow} from '../../../ui';

export function ArgumentSlideshowLeftItemsModule(props: any) {
    return (
        <ArgumentModuleSlideshow
            {...props}
            imagePosition="left"
        />
    );
}

export default ArgumentSlideshowLeftItemsModule;
