import useLuniiMutationApi from '../useLuniiMutationApi';
import {useCallback} from 'react';

export function useActivateCodeAlbum(callback: Function | undefined = () => {}) {
    const [activateActivateCodeAlbum, {loading, error, ...infos}] = useLuniiMutationApi('ACTIVATE_CODE_ALBUM');

    const onSubmit = useCallback(async ({codeAlbumId, countryCode, currency, productId}: ActivateCodeAlbumParams) => {
        try {
            const response = await activateActivateCodeAlbum({
                variables: {
                    data: {
                        countryCode,
                        productId,
                        codeAlbumId,
                        currency,
                    },
                },
            });
            const data = response?.data?.activateCodeAlbum || {};
            callback && callback(data);
            return data;
        } catch (e) {
            console.error(e);
        }
    }, [activateActivateCodeAlbum, callback]);
    return [
        onSubmit,
        {
            loading: Boolean(loading),
            error: error || false,
            ...infos,
        },
    ] as [(arg: ActivateCodeAlbumParams) => Promise<any>, any];
}

type ActivateCodeAlbumParams = {
    codeAlbumId: string,
    countryCode: string,
    currency: string,
    productId: string
}

export default useActivateCodeAlbum;
