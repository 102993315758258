import {useContext, useMemo} from "react";
import LuniiUserContext from "../contexts/LuniiUserContext";

export const useLuniiUserTokens = () => {
    const {user, getTokens, setTokens} = useContext(LuniiUserContext);
    return useMemo(() => ({
        token: user?.token,
        refreshToken: user?.refreshToken,
        getCurrentUserTokens: getTokens,
        setCurrentUserTokens: setTokens,
    }), [user, getTokens, setTokens]);
};

export default useLuniiUserTokens