import React from 'react';
import {makeStyles} from '@material-ui/core';
import {cssText} from '@ohoareau/css-utils';
import {buttonifyFromProps, useLuniiTranslation} from '../../../../ui';

interface SubscriptionGiftTunnelHeaderProps {
    stepIndex: number;
    stepCount: number;
    returnClickHandler: null | ((e: React.MouseEvent<HTMLElement>) => void);
    returnButtonDisabled?: boolean;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column-reverse',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            alignItems: 'center',
        },
    },
    return: {
        display: 'block',
        '&>*': {
            paddingLeft: 0,
        },
    },
    index: {
        marginTop: 8,
        ...cssText(theme, 'standard', 'overline_large_title', undefined, undefined, '#2C3637'),
        [theme.breakpoints.up('sm')]: {
            position: 'absolute',
            width: '100%',
            textAlign: 'center',
            top: '50%',
            transform: 'translate3d(0, -50%, 0)',
            margin: 0,
        },
    },
}));

export function SubscriptionGiftTunnelHeader({
    returnClickHandler = null,
    returnButtonDisabled = false,
    stepIndex = 0,
    stepCount = 1,
}: SubscriptionGiftTunnelHeaderProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const {Button} = buttonifyFromProps(
        {
            buttonLabel: t('go_back'),
            buttonType: 'tertiary,size=small,startIcon=back',
            buttonTarget: returnClickHandler,
        },
        [`disabled=${returnButtonDisabled}`],
    );

    return (
        <div className={classes.root}>
            <div className={classes.index}>
                {t('step')} {stepIndex}/{stepCount}
            </div>
            <div className={classes.return}>{returnClickHandler && Button && <Button />}</div>
        </div>
    );
}

export default SubscriptionGiftTunnelHeader;
