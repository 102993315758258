import convertFunction from './convertFunction';

export function convertClickFunction(s, ctx) {
    let [functionName, b = undefined] = s.split('?');
    functionName = functionName.toLowerCase().replace(/[^a-z0-9_]+/g, '');
    if (b) {
        b = b.split(/&/g).reduce((acc, x) => {
            const [c, d = 'true'] = x.split('=');
            acc[c] = d;
            return acc;
        }, {});
    } else {
        b = {};
    }
    return convertFunction(functionName, b, ctx);
}

export default convertClickFunction;
