import React from 'react';
import {Section} from 'react-scroll-section';
import * as modules from './modules';
import {module} from '../types';
import {convertModuleDataToProps} from '../utils';
import {ForceTheme, ErrorBoundary, LuniiProductProvider} from '../ui';

/**
 * This React component will dynamically render the appropriate Design System Module based
 * on the component name provided (actually a list of names as an array, to try).
 * If no component is found, we use a default module which is UnknownModeUnknownModule.
 *
 * Additionnally the props are computed from the 'data x config' merging using appropriate
 * property mappers.
 *
 * @param component string[]
 * @param module module
 * @param page page
 *
 * @return JSX
 */
export function Module({module, page}: ModuleProps) {
    const {component, config = {}, itemConfig = {}, data = {}} = module;
    const found = [...(component || []), 'UnknownModeUnknownModule'].find(
        (c) => !!(modules as any)[c],
    );
    if (!found) return <></>;
    const Comp = (modules as any)[found];
    let content = (
        <Comp
            {...convertModuleDataToProps(data, config, itemConfig)}
            page={page}
        />
    );
    data.theme && (content = <ForceTheme theme={data.theme}>{content}</ForceTheme>);
    data.product &&
        (content = (
            <LuniiProductProvider value={data.product}>
                {' '}
                {/* for product-related hooks on the module level */}
                {content}
            </LuniiProductProvider>
        ));
    data.sectionId && (content = <Section id={data.sectionId}>{content}</Section>);
    return (
        <ErrorBoundary
            module={module}
            page={page}
        >
            {content}
        </ErrorBoundary>
    );
}

export interface ModuleProps {
    module: module;
    page: any;
}

export default Module;
