/**
 * Format date like: 15 octobre 2022
 * @param locale
 * @param date
 */
export function literaryDateFormat (locale: string | undefined, date: number | Date | undefined): string {
    return new Intl.DateTimeFormat(locale || 'fr-fr', {timeZone: 'UTC', year: 'numeric', month: 'long', day: '2-digit'}).format(date);
}

export function dateOneMonthFromToday () {
    const now = new Date();
    return now.setMonth(now.getMonth() + 1);
}

export default {
    literaryDateFormat,
    dateOneMonthFromToday,
};
