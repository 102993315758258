import React, {useCallback, useState} from 'react';
import {cssText} from '@ohoareau/css-utils';
import NewsletterForm from '../molecules/NewsletterForm';
import Button from '../atoms/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Row from '../Row';
import Grid from '@material-ui/core/Grid';
import Img from '../nucleons/Img';
import {module_title, module_image} from '../types';
import useLuniiTranslation from '../hooks/useLuniiTranslation';
import {useLuniiNavigation} from '../hooks';

const useStyles = makeStyles(theme => ({
    root: {},
    container: {
        flex: 1,
        backgroundColor: '#FEECC3',
        borderRadius: 16,
        padding: 48,
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            padding: '32px 16px',
        },
        justifyContent: 'center',
    },
    centerContent: {
        textAlign: 'center',
    },
    title: {
        ...cssText(theme, 'standard', 'title_1'),
        marginBottom: 8,
    },
    text1: {
        ...cssText(theme, 'standard', 'body'),
        marginBottom: 16,
    },
    text2: {
        ...cssText(theme, 'standard', 'caption'),
        marginBottom: 5,
    },
    success: {
        ...cssText(theme, 'standard', 'body_thick'),
    },
}));

export function Newsletter({sectionTitle, image}: NewsletterProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const {goPageByModel} = useLuniiNavigation();
    const [success, setSuccess] = useState(null);
    const handleButtonClick = useCallback(() => {
        goPageByModel && goPageByModel('mentions_legales');
    }, [goPageByModel]);
    return (
        <Row className={classes.root}>
            <Grid container item direction={'column'} lg={12} md={12} xs={12}>
                {image && <Img {...image} objectFit={'contain'} objectPosition={'center bottom'}/>}
                <div className={classes.container}>
                    <Grid justifyContent={'center'} item container direction={'column'} lg={4} md={7} xs={12} className={classes.centerContent}>
                        <div className={classes.title}>{sectionTitle}</div>
                        <div className={classes.text1}>{t('newsletter_subtitle')}</div>
                        {success && <div className={classes.success}>{t('newsletter_subscribe_success')}</div>}
                        {!success && <>
                            <NewsletterForm onSuccess={setSuccess}/>
                            <div className={classes.text2}>{t('newsletter_privacy_policy_text')}</div>
                            <Button color={'tertiary'} size={'small'} onClick={handleButtonClick}>{t('newsletter_privacy_policy_link_label')}</Button>
                        </>}
                    </Grid>
                </div>
            </Grid>
        </Row>
    );
}

export interface NewsletterProps {
    sectionTitle?: module_title,
    image?: module_image,
}

export default Newsletter;
