import React, {useState} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import {Steppers, Img, image_value, DynamicIcon} from '..';
import {colors} from '../../../configs';
import {multi_product_universe} from '../../types';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(2),
        },
    },
    mainImg: {
        width: '100%',
        aspectRatio: '4/3',
        borderRadius: theme.shape.borderRadius * 4,
        overflow: 'hidden',
        lineHeight: 0,
        userSelect: 'none',
    },
    navigation: {
        flex: 1,
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(1),
            flexDirection: 'column-reverse',
        },
    },
    steppers: {
        margin: theme.spacing(2, 'auto'),
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    thumbs: {
        display: 'flex',
        width: '95%',
        margin: '0 auto',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(1),
        },
    },
    navigationInThumbs: {
        cursor: 'pointer',
        display: 'inline',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    thumbsShowed: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        aspectRatio: '6/1',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        margin: theme.spacing(0, 5),
        [theme.breakpoints.down('md')]: {
            margin: theme.spacing(0, 1),
        },
        [theme.breakpoints.down('sm')]: {
            margin: 0,
        },
    },
    thumb: {
        aspectRatio: '4/3',
        cursor: 'pointer',
        userSelect: 'none',
        borderRadius: theme.shape.borderRadius * 2,
        overflow: 'hidden',
        boxSizing: 'border-box',
        border: '2px solid transparent',
        position: 'relative',
        margin: theme.spacing(0, 1),
        '&:first-child': {
            marginLeft: 0,
        },
        '&:last-child': {
            marginRight: 0,
        },
        transition: '.35s border-color ease-out',
    },
    activeThumb: {
        borderColor: '#FBBD2A',
    },
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255, 255, 255, .6)',
        opacity: 0.01,
        transition: '.35s opacity ease-out',
    },
    '@media (hover: hover)': {
        overlay: {
            '&:hover': {
                opacity: 0.3,
            },
        },
    },
    overlayActive: {
        opacity: 1,
    },
}));

// @todo add videos
export function ECommerceCarousel({className = '', items = [], universe = 'default'}: ECommerceCarouselProps) {
    const slicedItems = items.slice(0, 4); // use only 4 items
    const nbImages: number = slicedItems.length;
    const classes = useStyles();
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [currentImage, setCurrentImage] = useState<any>(slicedItems[currentStep]?.image);
    const go = (index: number): void => {
        const newImage = slicedItems[index]?.image;
        if (newImage && newImage !== currentImage) {
            setCurrentStep(index);
            setCurrentImage(newImage);
        }
    };
    const prev = (): void => {
        const newIndex = currentStep === 0 ? nbImages - 1 : currentStep - 1;
        go(newIndex);
    };
    const next = (): void => {
        const newIndex = currentStep === nbImages - 1 ? 0 : currentStep + 1;
        go(newIndex);
    };
    if (nbImages === 0) return null;
    return (
        <div className={clsx(classes.root, className)}>
            <div className={classes.mainImg}>
                <Img
                    {...currentImage}
                    loading='eager'/>
            </div>
            {nbImages > 1 && <div className={classes.navigation}>
                <div className={classes.steppers}>
                    <Steppers
                        color={colors.multiProductUniverse[universe].main}
                        onClick={(i: number) => go(i)}
                        step={`step${currentStep}`}
                        steps={slicedItems.map((_, index) => `step${index}`)}/>
                </div>
                <div className={classes.thumbs}>
                    <DynamicIcon
                        type='arrow-left-32'
                        onClick={prev}
                        className={classes.navigationInThumbs}/>
                    <div className={classes.thumbsShowed}>
                        {slicedItems.map((item, index) => (
                            <div
                                className={clsx(classes.thumb, index === currentStep && classes.activeThumb)}
                                key={`thumb-${Math.random()}`}
                                onClick={() => go(index)}>
                                <Img {...(item.image2 || item.image)} loading='eager'/>
                                <div className={clsx(classes.overlay, index === currentStep && classes.overlayActive)}/>
                            </div>
                        ))}
                    </div>
                    <DynamicIcon type='arrow-right-32' onClick={next} className={classes.navigationInThumbs}/>
                </div>
            </div> || false}
        </div>
    );
}

export interface ECommerceCarouselProps {
    universe?: multi_product_universe;
    className?: string,
    items: {image: image_value, image2?: image_value}[],
}

export default ECommerceCarousel;
