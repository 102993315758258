import {useLuniiApi, RETRIEVE_CODE_ALBUMS_INFOS, lazyQueryInfos} from '../..';
import {LazyQueryHookOptions, LazyQueryResult} from '@apollo/client';

type useRetrieveCodeAlbumInfosVariables = {
    data: retrieveCodeAlbumParams;
}

type retrieveCodeAlbumParams = {
    countryCode: string;
    codeAlbum: string;
};

export function useRetrieveCodeAlbumInfos(
    options?: LazyQueryHookOptions,
): [((options?: LazyQueryHookOptions<any>) => Promise<LazyQueryResult<any, any>>), lazyQueryInfos<any, useRetrieveCodeAlbumInfosVariables>] {
    const {gql, useLazyQuery} = useLuniiApi();

    return useLazyQuery(RETRIEVE_CODE_ALBUMS_INFOS(gql), {
        fetchPolicy: 'network-only', // Used for first execution
        nextFetchPolicy: 'network-only', // Used for subsequent executions
        notifyOnNetworkStatusChange: true, // Used when method is called twice with same params to ensure onCompleted is called
        ...options,
    });
}

export default useRetrieveCodeAlbumInfos;
