import React, {useCallback, useEffect, useRef} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {useDebouncedCallback} from 'use-debounce';
import {
    useLuniiEventTracking,
    useLuniiPageTracking,
    useLuniiTranslation,
    Pagination,
    MosaicCardItem,
    useWishlist,
    wishlist_item, useLuniiPage,
} from '../../ui';
import CatalogItemsNotFound from './CatalogItemsNotFound';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        width: '100%',
    },
    choiceContainer: {
        marginBottom: 10,
    },
    formattedNames: {
        fontWeight: 'bold',
    },
    pagination: {
        margin: theme.spacing(6, 0),
    },
    container: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'space-between',
        },
    },
    mosaicItem: {
        [theme.breakpoints.up('lg')]: {
            minWidth: '23%',
            maxWidth: '23%',
            margin: '16px 7.5px',
            '&:nth-child(4n+1)': {
                marginLeft: 0,
            },
            '&:nth-child(4n)': {
                marginRight: 0,
            },
        },
        [theme.breakpoints.only('md')]: {
            minWidth: '32%',
            maxWidth: '32%',
            margin: '16px 6px',
            '&:nth-child(3n+1)': {
                marginLeft: 0,
            },
            '&:nth-child(3n)': {
                marginRight: 0,
            },
        },
        [theme.breakpoints.down('sm')]: {
            minWidth: '49%',
            maxWidth: '49%',
            margin: '16px 0px',
        },
    },
}));

export function CatalogMosaicCardItems({
    mode = 'fah',
    items = [],
    nbTotalItems = 0,
    currentPage = 1,
    numberOfPages = 1,
    searchQuery = '',
    filters = [],
    onPageChange = () => {},
    catalogMounted,
}: CatalogMosaicCartItemsProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const page = useLuniiPage();
    const dataLayerPageEvent = useLuniiPageTracking({envModel: page?.pageModel || ''});
    const dataLayerSearchPageEvent = useLuniiPageTracking({envModel: 'recherche'});
    const handleEventTracking = useLuniiEventTracking();
    const itemsRefs = useRef<string | null>(null);
    const [{addToWishlistExecute: addToWishlist, removeFromWishlistExecute: removeFromWishlist}] =
        useWishlist(); // todo warning should use new useUserWishlist, usePostUserWishlist and userDeleteUserWishlist hooks
    const debouncedPageTracking = useDebouncedCallback((items: any[], searchQuery: string) => {
        if (searchQuery !== '') {
            dataLayerSearchPageEvent(items, searchQuery);
        } else {
            dataLayerPageEvent(items);
        }
    }, 1000);

    const handlePageChange = useCallback(
        (position) => {
            onPageChange && onPageChange(position);
        },
        [onPageChange],
    );

    useEffect(() => {
        // Create string with displayed items id so that the debouncedPageTracking method is only called
        // when the items list
        const newItemsRefs = items?.reduce((acc, item) => {
            acc += item?.id;
            return acc;
        }, '');
        if (catalogMounted && itemsRefs.current !== newItemsRefs) {
            debouncedPageTracking(items, searchQuery);
            itemsRefs.current = newItemsRefs;
        }
    }, [catalogMounted, searchQuery, items, dataLayerPageEvent, dataLayerSearchPageEvent]);

    const activeNames = searchQuery
        ? [`"${searchQuery}"`]
        : (filters || []).map(({label}) => `"${label}"`);
    const activeNamesLength = activeNames.length;

    const handleWishlistClick = async (packInWishlist: boolean, product: wishlist_item) => {
        const packId = product.objectId;
        if (packInWishlist) {
            await removeFromWishlist({
                variables: {
                    data: {
                        packId,
                    },
                },
                optimisticResponse: {
                    __typename: 'Mutation',
                    removeFromWishlist: {
                        ...product,
                        _removed: true,
                        __typename: 'WishlistItem',
                    },
                },
            });
        } else {
            await addToWishlist({
                variables: {
                    data: {
                        packId,
                    },
                },
                optimisticResponse: {
                    __typename: 'Mutation',
                    addToWishlist: {
                        ...product,
                        __typename: 'WishlistItem',
                    },
                },
            });
        }
    };

    const handleWishlistTracking = (packInWishlist: boolean, product: wishlist_item) => {
        handleEventTracking(
            {
                categorie: 'ecommerce',
                action: packInWishlist ? 'retrait_liste_souhaits' : 'ajout_liste_souhaits',
                libelle: page?.pageModel,
                valeur: Math.round(product.priceExclTax / 100) * 100,
            },
            product,
        );
    };

    return (
        <div className={classes.root}>
            {activeNamesLength > 0 && (
                <div className={classes.choiceContainer}>
                    <div>
                        {`${nbTotalItems} ${t('catalog_results_for')} `}
                        <span className={classes.formattedNames}>{activeNames.join(', ')}</span>
                    </div>
                </div>
            )}
            <div className={classes.container}>
                {items.map((item: any, i: number) => (
                    <div
                        className={classes.mosaicItem}
                        key={item.id || i}
                    >
                        {' '}
                        {/* TODO: remove useless div !! */}
                        <MosaicCardItem
                            product={item}
                            onClickCb={() =>
                                handleEventTracking(
                                    {categorie: 'ecommerce', action: 'clic_produit'},
                                    {...item, position: i + 1},
                                )
                            }
                            onWishlistToggleClick={handleWishlistClick}
                            onWishlistToggleClickCb={handleWishlistTracking}
                        />
                    </div>
                ))}
            </div>
            {activeNamesLength > 0 && nbTotalItems === 0 && <CatalogItemsNotFound mode={mode}/> || false}
            <div className={classes.pagination}>
                <Pagination
                    size={numberOfPages}
                    onPageChange={handlePageChange}
                    currentPage={currentPage}
                />
            </div>
        </div>
    );
}

export interface CatalogMosaicCartItemsProps {
    mode?: 'fah' | 'flam';
    items?: any[];
    searchQuery?: string;
    filters?: any[];
    onPageChange?: Function;
    numberOfPages?: number;
    currentPage?: number;
    catalogMounted: boolean;
    nbTotalItems?: number;
}

export default CatalogMosaicCardItems;
