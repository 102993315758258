import React from 'react';
import {cssText} from '@ohoareau/css-utils';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {Modal} from '../../organisms';
import {useLuniiTranslation} from '../../hooks';
import {buttonifyFromProps} from '../../hocs';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    root: {
    },
    body: {
        ...cssText(theme, 'standard', 'body', undefined, undefined, '#716C5E'),
        [theme.breakpoints.down('sm')]: {
            ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#716C5E'),
        },
    },
}));

export function ActivateCouponUnableModal({className, open = false, onClose = () => {}, ...props}: ActivateCouponUnableModalProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const {Buttons} = buttonifyFromProps({
        buttonLabel: t('my_subscription_activate_unable_modal_button'),
        buttonTarget: onClose,
    }, [
        'primary',
    ]);
    const body = (
        <div className={classes.body}>
            {t('my_subscription_activate_unable_modal_body')}
        </div>
    );
    return (
        <Modal
            open={open}
            className={clsx(classes.root, className)}
            onCloseCallback={onClose}
            title={t('my_subscription_activate_unable_modal_title')}
            body={body}
            actions={Buttons && <Buttons/>}/>
    );
}

export interface ActivateCouponUnableModalProps {
    className?: string,
    open?: boolean,
    onClose?: any,
}

export default ActivateCouponUnableModal;
