import React, {forwardRef} from 'react';
import {cssBox} from '@ohoareau/css-utils';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        boxShadow: '0px -4px 40px rgba(66, 63, 55, 0.04), 0px 16px 40px rgba(66, 63, 55, 0.1)',
        borderRadius: theme.spacing(1),
        backgroundColor: '#FFFFFF !important'
    },
    lowerShadow: {
        boxShadow: '0px -2px 8px rgba(66, 63, 55, 0.02), 0px 4px 8px rgba(66, 63, 55, 0.1) !important',
    },
}));

export const ElevatedPanel = forwardRef<HTMLDivElement, ElevatedPanelProps>(
    ({className, children, lower = false, ...props}, forwardedRef) => {
        const classes = useStyles();
        return (
            <div
                ref={forwardedRef}
                className={clsx(classes.root, className, lower && classes.lowerShadow)}
                {...props}
            >
                {children || null}
            </div>
        );
    },
);

ElevatedPanel.displayName = 'ElevatedPanel';

export interface ElevatedPanelProps extends React.HTMLProps<HTMLDivElement> {
    className?: string;
    children?: any;
    lower?: boolean;
}

export default ElevatedPanel;
