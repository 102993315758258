import React, {useCallback, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core';
import {cssText} from '@ohoareau/css-utils';
import {MosaicCardItem, useLuniiCatalog, useLuniiTranslation, Button, catalog_context_value} from '../../ui';
import SvgRefresh from '../../ui/images/icons/Refresh';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    noResultContainer: {
        width: '100%',
        margin: '64px 0',
        textAlign: 'center',
    },
    noResultTitle: {
        ...cssText(theme, 'standard', 'title_1'),
        marginBottom: 5,
    },
    noResultRefresh: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: theme.spacing(6),
    },
    container: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'space-between',
        },
    },
    mosaicItem: {
        [theme.breakpoints.up('lg')]: {
            width: '23%',
            margin: '16px 7.5px',
            '&:nth-child(4n+1)': {
                marginLeft: 0,
            },
            '&:nth-child(4n)': {
                marginRight: 0,
            },
        },
        [theme.breakpoints.only('md')]: {
            width: '32%',
            margin: '16px 6px',
            '&:nth-child(3n+1)': {
                marginLeft: 0,
            },
            '&:nth-child(3n)': {
                marginRight: 0,
            },
        },
        [theme.breakpoints.down('sm')]: {
            width: '49%',
            margin: '16px 0px',
        },
    },
}));

export function CatalogItemsNotFound({mode = 'fah'}: CatalogItemsNotFoundProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const catalog = useLuniiCatalog() || {} as catalog_context_value;
    const [items, setItems] = useState<any[]>([]);
    const products = mode === 'flam' && catalog.flamProducts || catalog.fahProducts || [];

    useEffect(() => {
        refreshItems();
    }, []);

    const refreshItems = useCallback(() => {
        const nbProducts = products.length;
        if (nbProducts > 4) {
            const itemsRandomized = [];
            for (let i = 0; i < 4; i++) {
                const index = Math.floor(Math.random() * nbProducts);
                const product = products[index] || {};
                itemsRandomized.push(product);
            }
            setItems(itemsRandomized);
        } else {
            setItems(products);
        }
    }, [setItems, mode]);

    return (
        <div className={classes.root}>
            <div className={classes.noResultContainer}>
                <div className={classes.noResultTitle}>{t('catalog_no_result_title')}</div>
                <div>{t('catalog_no_result_content')}</div>
            </div>
            {products.length > 4 && <div className={classes.noResultRefresh}>
                <Button
                    color="plain"
                    endIcon={<SvgRefresh />}
                    size="large"
                    onClick={refreshItems}
                >
                    {t('catalog_no_result_refresh')}
                </Button>
            </div> || false}
            <div className={classes.container}>
                {items.map((item: any, i: number) => (
                    <div
                        className={classes.mosaicItem}
                        key={item.id || i}
                    >
                        {' '}
                        {/* TODO: remove useless div !! */}
                        <MosaicCardItem product={item} />
                    </div>
                ))}
            </div>
        </div>
    );
}

export interface CatalogItemsNotFoundProps {
    mode?: 'fah' | 'flam';
}

export default CatalogItemsNotFound;
