import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {Trans} from 'react-i18next';
import {cssText} from '@ohoareau/css-utils';
import {buttonifyFromProps, DynamicIcon, Modal, useLuniiTranslation} from '../../ui';

const useStyles = makeStyles(theme => ({
    root: {
    },
    body: {
        ...cssText(theme, 'standard', 'body', undefined, undefined, '#5F6769'),
        padding: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2, 4),
        },
    },
    title: {
        display: 'block',
        ...cssText(theme, 'standard', 'title_2', undefined, undefined, '#2C3637'),
        marginBottom: theme.spacing(1),
    },
}));

export function ProductUnavailableModal({open = false, onCloseCallback = () => {}}: ProductUnavailableModalProps) {
    const classes = useStyles();
    const {i18n, t} = useLuniiTranslation();
    const {Buttons} = buttonifyFromProps({
        buttonLabel: t('my_library_error_no_product_confirm'),
        buttonTarget: onCloseCallback,
        buttonType: 'primary',
    });
    return (
        <Modal open={open} onCloseCallback={onCloseCallback} bodyClassName={classes.body} className={classes.root}
            body={
                <div>
                    <DynamicIcon type='cross-badge' height={42} width={42}/>
                    <Trans
                        i18nKey='my_library_error_no_product'
                        i18n={i18n}
                        components={{'1': <span className={classes.title}/>}}
                    />
                </div>
            }
            actions={Buttons && <Buttons/>}
        />
    );
}

export interface ProductUnavailableModalProps {
    open?: boolean;
    onCloseCallback?: () => void;
}

export default ProductUnavailableModal;
