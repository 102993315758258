import React from 'react';
import {OwnedStatusesEnum} from '../types';
import useLuniiTranslation from '../hooks/useLuniiTranslation';
import {buttonify} from '../hocs';
import ItemOwnedSticker from '../nucleons/ItemOwnedSticker';
import {useLuniiNavigation} from '../hooks/useLuniiNavigation';

export function ItemStatusButton({ownedStatus = OwnedStatusesEnum.ToBuy, product, buttonLabel, buttonTarget, buttonType}: ItemStatusButtonProps) {
    const {t} = useLuniiTranslation();
    const {goCheckout} = useLuniiNavigation();
    const target = ownedStatus === OwnedStatusesEnum.InCart ? goCheckout : (buttonTarget || product.buttonTarget);
    const label = buttonLabel ? buttonLabel : (ownedStatus === OwnedStatusesEnum.ToBuy ? t('cta_item_to_buy') : t('cta_item_in_cart'));
    const bType = buttonType ? `${buttonType}` + ',size=small' : (ownedStatus === OwnedStatusesEnum.ToBuy ? 'startIcon=basket-16,color=primary,size=small' : 'startIcon=shopping-bag,color=plain,size=small');
    const Button = buttonify(label, bType, target);
    return (ownedStatus && ownedStatus === OwnedStatusesEnum.Owned ? <ItemOwnedSticker/> : Button && <Button/>);
};

export interface ItemStatusButtonProps {
    ownedStatus?: OwnedStatusesEnum,
    product: any,
    buttonLabel?: string,
    buttonTarget?: string,
    buttonType?: string,
};

export default ItemStatusButton;
