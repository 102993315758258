export const userSubscription = `
    creationDate
    productReference
    status
    stripeCustomerId
    stripeSubscriptionId
    stripeProductReference
    renewalPlannedAt
    plannedEvents {
        tag
        date
    }
    wallet {
        balance
    }
    endTrialDate
    hadMonthlySubscriptionFreeMonth
    subscriptionCategory
    subscriptionPrice
    subscriptionCurrency
`;
const stripeProduct = `
    id
    object
    active
    livemode
    metadata {
        months
        type
    }
    name
    couponType
    url
    taxCode
    price
    currency
    description
    priceDetails {
        totalWithVat
        taxTotal
        total
    }
`;

const subscriptionProduct = `
    id
    name
    price
    oldPrice
    priceExclTax
    currency
    reference
    stripeProductReference
    purchasable
    type
    category
    currency
`;
export const SPEND_SUBSCRIPTION_CREDIT = (gql) => gql`
    mutation spendSubscriptionCredit($data: SpendSubscriptionCreditInput!) {
        spendSubscriptionCredit(data: $data) {
            success
        }
    }
`;
export const CANCEL_SUBSCRIPTION = (gql) => gql`
    mutation cancelSubscription($data: CancelSubscriptionInput!) {
        cancelSubscription(data: $data) {
            success
        }
    }
`;
export const REACTIVATE_SUBSCRIPTION = (gql) => gql`
    mutation reactivateSubscription {
        reactivateSubscription {
            success
        }
    }
`;
export const SEND_SUBSCRIPTION_INFOS = (gql) => gql`
    mutation retrieveSubscriptionPaymentIntent($data: RetrieveSubscriptionPaymentIntentInput!) {
        retrieveSubscriptionPaymentIntent(data: $data) {
            client_secret
        }
    }
`;
export const SEND_SUBSCRIPTION_INFOS_FREE_MONTH = (gql) => gql`
    mutation retrieveSubscriptionSetupIntentFreeMonth(
        $data: RetrieveSubscriptionSetupIntentInput!
    ) {
        retrieveSubscriptionSetupIntentFreeMonth(data: $data) {
            client_secret
        }
    }
`;
export const RETRIEVE_USER_SUBSCRIPTION = (gql) => gql`
    query retrieveUserSubscription {
        retrieveUserSubscription {
            ${userSubscription}
        }
    }
`;
export const RETRIEVE_SUBSCRIPTION_PRODUCT = (gql) => gql`
    query retrieveSubscriptionProduct($productId: String!, $countryCode: String) {
        retrieveSubscriptionProduct(productId: $productId, countryCode: $countryCode) {
            ${subscriptionProduct}
        }
    }
`;
export const RETRIEVE_SUBSCRIPTION_PRODUCTS = (gql) => gql`
    query retrieveSubscriptionProducts($countryCode: String) {
        retrieveSubscriptionProducts(countryCode: $countryCode) {
            items {
                ${subscriptionProduct}
            }
        }
    }
`;
export const RETRIEVE_SUBSCRIPTION_COUPON_PRODUCTS = (gql) => gql`
    query retrieveSubscriptionCouponProducts($countryCode: String) {
        retrieveSubscriptionCouponProducts(countryCode: $countryCode) {
            items {
                ${stripeProduct}
            }
        }
    }
`;
export const VERIFY_SUBSCRIPTION_COUPON = (gql) => gql`
    query verifySubscriptionCoupon($data: VerifySubscriptionCouponInput!) {
        verifySubscriptionCoupon(data: $data) {
            success
        }
    }
`;
export const ACTIVATE_SUBSCRIPTION_COUPON = (gql) => gql`
    mutation activateSubscriptionCoupon($data: ActivateSubscriptionCouponInput!) {
        activateSubscriptionCoupon(data: $data) {
            createdAt
            updatedAt
            expireAt
            consumedAt
            deletedAt
            receiverId
            subscriptionId
            productId
            senderId
            code
            purchaseId
            numberOfMonths
        }
    }
`;
export const BUY_SUBSCRIPTION_COUPON = (gql) => gql`
    mutation buySubscriptionCoupon($data: BuySubscriptionCouponInput!) {
        buySubscriptionCoupon(data: $data) {
            client_secret
        }
    }
`;
export const GENERATE_STRIPE_CUSTOMER_PORTAL_SESSION = gql => gql`
    mutation generateStripeCustomerPortalSession($data: GenerateStripeCustomerPortalSessionInput!) {
        generateStripeCustomerPortalSession(data: $data) {
            url
        }
    }
`;

export default {
    SPEND_SUBSCRIPTION_CREDIT,
    CANCEL_SUBSCRIPTION,
    REACTIVATE_SUBSCRIPTION,
    SEND_SUBSCRIPTION_INFOS,
    SEND_SUBSCRIPTION_INFOS_FREE_MONTH,
    RETRIEVE_USER_SUBSCRIPTION,
    RETRIEVE_SUBSCRIPTION_PRODUCT,
    RETRIEVE_SUBSCRIPTION_PRODUCTS,
    RETRIEVE_SUBSCRIPTION_COUPON_PRODUCTS,
    VERIFY_SUBSCRIPTION_COUPON,
    ACTIVATE_SUBSCRIPTION_COUPON,
    BUY_SUBSCRIPTION_COUPON,
    GENERATE_STRIPE_CUSTOMER_PORTAL_SESSION,
}
