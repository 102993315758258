import React, {useCallback} from 'react';
import Typography from '@material-ui/core/Typography';
import {cssFont, cssText} from '@ohoareau/css-utils';
import makeStyles from '@material-ui/core/styles/makeStyles';
import HiddenCss from '@material-ui/core/Hidden/HiddenCss';
import {
    convertPrice,
    useLuniiTranslation,
    DynamicIcon,
    DropDownQuantity,
    useShoppingCart,
    ProductTableNameEnum,
} from '../../ui';
import {GiftCardMetadata} from '.';
import {DeviceTag} from './v2';

const useStyles = makeStyles((theme) => ({
    root: {
    },
    content: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },
    left: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        width: 96,
        marginRight: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            marginRight: theme.spacing(2),
        },
    },
    image: {
        width: 96,
        lineHeight: 0,
        borderRadius: 2,
        backgroundColor: '#faf9f8',
        objectFit: 'cover',
    },
    actions: {
        marginTop: 10,
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            marginTop: 14,
        },
    },
    name: {
        ...cssText(theme, 'standard', 'title_2', undefined, undefined, '#2C3637'),
        display: '-webkit-box',
        overflow: 'hidden',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        [theme.breakpoints.down('sm')]: {
            ...cssFont(theme, 'standard', 'body_thick'),
        },
    },
    locale: {
        ...cssText(theme, 'standard', 'caption', undefined, undefined, '#7C878B'),
    },
    priceContainer: {
        display: 'flex',
        justifyContent: 'flexStart',
        alignItems: 'center',
        flexWrap: 'wrap',
        marginTop: 4,
    },
    price: {
        ...cssText(theme, 'standard', 'body_thick', undefined, undefined, '#7C878B'),
    },
    oldPrice: {
        ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#A6B1B5'),
        textDecoration: 'line-through',
        marginRight: 8,
    },
    tag: {
        marginTop: 8,
    },
    btnDeleteItem: {
        color: '#20272C',
        cursor: 'pointer',
    },
    right: {
        flex: 1,
        display: 'flex',
    },
    infos: {
        flex: 'auto',
    },
    aside: {
        width: 24,
        marginLeft: theme.spacing(2),
        paddingTop: theme.spacing(2),
    },
}));

export function ShoppingCartCheckoutItem({item = {}, onRemove}: ShoppingCartCheckoutItemProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const [{updateCartItemQuantity}] = useShoppingCart();
    const handleRemove = useCallback(() => {
        onRemove && onRemove(item.id);
    }, [onRemove, item]);
    const handleChangeQuantity = useCallback(
        (quantity: number) => {
            updateCartItemQuantity(item.id, quantity);
        },
        [updateCartItemQuantity],
    );
    const quantityIsAvailable =
        item.type !== ProductTableNameEnum.Packs && item.type !== ProductTableNameEnum.GiftCards;
    const actionsSecondary = (
        <div className={classes.actions}>
            <DropDownQuantity
                quantitiesAvailable={4}
                quantity={item.quantity}
                onChange={handleChangeQuantity}
            />
        </div>
    );
    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <div className={classes.left}>
                    <img
                        className={classes.image}
                        src={item.thumbnailUrl}
                        alt={item.name}
                    />
                    <HiddenCss mdUp>{quantityIsAvailable && actionsSecondary}</HiddenCss>
                </div>
                <div className={classes.right}>
                    <div className={classes.infos}>
                        <Typography
                            component="div"
                            className={classes.name}
                            title={item.name}
                        >
                            {item.type === ProductTableNameEnum.GiftCards
                                ? t('gift_cards_gift_in_cart_name')
                                : item.name}
                        </Typography>
                        {item.locale && item.type === ProductTableNameEnum.Packs && (
                            <div className={classes.locale}>
                                {t(`locale_${item.locale.toLowerCase()}_product_language`)}
                            </div>
                        )}
                        <div className={classes.priceContainer}>
                            {item.oldPrice && (
                                <div className={classes.oldPrice}>
                                    {convertPrice(item.oldPrice * item.quantity, item.currency)}
                                </div>
                            )}
                            <div className={classes.price}>
                                {item.price === 0
                                    ? t('product_price_free')
                                    : convertPrice(item.price * item.quantity, item.currency)}
                            </div>
                        </div>
                        {item?.deviceTarget?.length && (
                            <div>
                                <DeviceTag className={classes.tag} size='xsmall' deviceTarget={item.deviceTarget}/>
                            </div>
                        ) || false}
                        <HiddenCss smDown>
                            {item.type === ProductTableNameEnum.GiftCards && item.metadata && (
                                <GiftCardMetadata metadata={item.metadata} />
                            )}
                            {quantityIsAvailable && actionsSecondary}
                        </HiddenCss>
                    </div>
                    <div className={classes.aside}>
                        <DynamicIcon
                            type="trash"
                            className={classes.btnDeleteItem}
                            height={24}
                            width={24}
                            onClick={handleRemove}
                        />
                    </div>
                </div>
            </div>
            <HiddenCss mdUp>
                {item.type === ProductTableNameEnum.GiftCards && item.metadata && (
                    <GiftCardMetadata metadata={item.metadata} />
                )}
            </HiddenCss>
        </div>
    );
}

export interface ShoppingCartCheckoutItemProps {
    item?: any;
    onRemove?: Function;
}

export default ShoppingCartCheckoutItem;
