import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {useTheme} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import {cssBorder, cssText} from '@ohoareau/css-utils';
import {useLocation, WindowLocation} from '@reach/router';
import clsx from 'clsx';
import {
    useLuniiUserTokens,
    useLuniiTranslation,
    ShadowedPanel,
    LoginPanel,
    FormPanel,
    RegisterForm,
    ForgotPasswordForm,
    InfosPanelForm,
    Button,
    useShoppingCart,
    useLuniiSpa,
    useLuniiCheckoutTracking,
    useLuniiUser,
    useLuniiPasswordUpdateModal,
    Row,
} from '../../../ui';
import {getRedirectLanguage} from '../../../utils';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        position: 'relative',
    },
    rootWrapper: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-end',
        '& >*': {
            width: '100%',
        },
        padding: theme.spacing(2),
    },
    panel: {
        ...cssBorder(theme, 'push'),
        backgroundColor: 'white',
        padding: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
        },
    },
    container: {
        paddingBottom: theme.spacing(8),
    },
    title: {
        marginBottom: theme.spacing(3),
        ...cssText(theme, 'standard', 'title_1'),
    },
    box: {
        margin: 0,
    },
    login: {
        width: '100%',
        textAlign: 'right',
    },
}));

export interface LoginScreenProps {
    onLoginSuccessPath?: string;
}

export function Login({onLoginSuccessPath = '/'}: LoginScreenProps) {
    const dataLayerCheckoutRegistrationEvent = useLuniiCheckoutTracking({envModel: 'inscription'});
    const dataLayerCheckoutConnectionEvent = useLuniiCheckoutTracking({envModel: 'identification'});
    const location: WindowLocation = useLocation();
    const classes = useStyles();
    const theme = useTheme();
    const {open: openPasswordUpdateModal} = useLuniiPasswordUpdateModal();
    const [{cart}] = useShoppingCart();
    const {user} = useLuniiUser();
    const {t} = useLuniiTranslation();
    const cartCountry = useMemo(
        () =>
            (cart || {}).country ||
            getRedirectLanguage(typeof navigator !== 'undefined' ? navigator : {}),
        [],
    ); // do not reset if cart is reseted
    const xsDevice = useMediaQuery(theme.breakpoints.only('xs'));
    const tbDevice = useMediaQuery(theme.breakpoints.only('md'));
    const [mode, setMode] = useState('login');
    const {setCurrentUserTokens} = useLuniiUserTokens();
    const handleForgotPasswordClick = useCallback(() => setMode('forgot-password'), [setMode]);
    const handleRegisterClick = useCallback(() => {
        setMode('register');
        dataLayerCheckoutRegistrationEvent();
    }, [setMode, dataLayerCheckoutRegistrationEvent]);
    const handleCancelClick = useCallback(() => {
        setMode('login');
        dataLayerCheckoutConnectionEvent();
    }, [setMode, dataLayerCheckoutConnectionEvent]);
    const handleForgotPasswordSuccess = useCallback(() => setMode('forgot-password-success'), []);
    const handleRegisterSuccess = useCallback(() => setMode('register-success'), []);
    const {navigate} = useLuniiSpa();
    const handleAuthTokensChange = useCallback(
        async (tokens: any) => {
            await setCurrentUserTokens(tokens);
            let params: string | undefined;
            if (location?.search && undefined !== window) {
                params = location.search;
            }
            navigate(`/${params || ''}`);
        },
        [location.search, navigate, setCurrentUserTokens],
    );
    const handleMustUpdatePassword = useCallback(
        async (loginData: any) => {
            openPasswordUpdateModal(loginData);
        },
        [openPasswordUpdateModal],
    );
    useEffect(() => {
        const execute = async () => {
            if (user?.id) {
                await navigate(onLoginSuccessPath);
            }
        };
        execute();
    }, [navigate, onLoginSuccessPath, user?.id]);
    const registerButton = () => (
        <Box
            className={classes.box}
            mb={3}
            onClick={handleRegisterClick}
        >
            <Button
                color="secondary"
                size="small"
            >
                {t('buttons_register_label')}
            </Button>
        </Box>
    );
    const loginButton = () => (
        <Box
            className={clsx(classes.box, classes.login)}
            mb={3}
            onClick={handleCancelClick}
        >
            <Button
                color="secondary"
                size="small"
            >
                {t('forms_login_buttons_submit_label')}
            </Button>
        </Box>
    );
    return (
        <Row
            padding="default"
            direction="column"
            alignItems="center"
            justify="center"
            className={classes.root}
        >
            <Grid
                container
                item
                lg={8}
                md={8}
                sm={12}
                className={classes.rootWrapper}
                direction="column"
            >
                <h2 className={classes.title}>{t('checkout_login_title')}</h2>
                <ShadowedPanel className={classes.panel}>
                    {mode === 'login' && (
                        <LoginPanel
                            dense
                            disableClose
                            title={t('checkout_login_form_title')}
                            titleInHeader={!xsDevice}
                            header={registerButton}
                            logoPosition="default"
                            formProps={{
                                loggedInGuest: false,
                                registerLink: false,
                                inputFullWidth: xsDevice || tbDevice,
                                onAuthTokensChange: handleAuthTokensChange,
                                onForgotPasswordClick: handleForgotPasswordClick,
                                onMustUpdatePassword: handleMustUpdatePassword,
                            }}
                        />
                    )}
                    {mode === 'forgot-password' && (
                        <FormPanel
                            title={t('panels_forgotpassword_title')}
                            onFormUpdate={handleForgotPasswordSuccess}
                            description={t('panels_forgotpassword_description')}
                            infos="register_special_info"
                            disableClose
                            dense
                            form={ForgotPasswordForm}
                            formProps={{
                                onCancelClick: handleCancelClick,
                                inputFullWidth: xsDevice || tbDevice,
                            }}
                        />
                    )}
                    {mode === 'forgot-password-success' && (
                        <FormPanel
                            title={t('panels_forgotpassword_success_title')}
                            description={t('forms_forgotpassword_verify_inbox_text')}
                            disableClose
                            dense
                            form={InfosPanelForm}
                            formProps={{
                                onCancelClick: handleCancelClick,
                                buttonLabel: t('forms_forgotpassword_buttons_cancel_label'),
                                inputFullWidth: xsDevice || tbDevice,
                            }}
                        />
                    )}
                    {mode === 'register' && (
                        <FormPanel
                            title={t('panels_register_title')}
                            onFormUpdate={handleRegisterSuccess}
                            description={t('panels_register_description')}
                            infos="register_special_info"
                            header={loginButton}
                            disableClose
                            dense
                            form={RegisterForm}
                            formProps={{
                                forcedCountry: cartCountry,
                                loginLink: false,
                                onCancelClick: handleCancelClick,
                                inputFullWidth: xsDevice || tbDevice,
                            }}
                        />
                    )}
                    {mode === 'register-success' && (
                        <FormPanel
                            title={t('panels_register_success_title')}
                            description={t('forms_register_verify_inbox_text')}
                            disableClose
                            dense
                            form={InfosPanelForm}
                            formProps={{
                                onCancelClick: handleCancelClick,
                                loginLink: false,
                                buttonLabel: t('forms_login_buttons_submit_label'),
                                alert: t('forms_register_alert_inbox_text'),
                                inputFullWidth: xsDevice || tbDevice,
                            }}
                        />
                    )}
                </ShadowedPanel>
            </Grid>
        </Row>
    );
}

export default Login;
