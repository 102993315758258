import React from 'react';
import TextField, {TextFieldProps} from './TextField';

export function PromoCodeField(props: PromoCodeFieldProps) {
    return (
        <TextField name={'promoCode'} {...props} />
    );
}

export interface PromoCodeFieldProps extends TextFieldProps {}

export default PromoCodeField