import React, {ReactNode} from 'react';
import {cssText} from '@ohoareau/css-utils';
import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';
import {DynamicIcon} from '../';

const useStyles = makeStyles((theme) => ({
    root: {
        ...cssText(theme, 'standard', 'secondary_body'),
        color: (props: any) => props.mode === 'alert' ? '#7F1F08' : '#04384A',
        flex: '0 1 100%',
        alignSelf: 'stretch',
        padding: theme.spacing(2),
        backgroundColor: (props: any) => props.mode === 'alert' ? '#F5E5E4' : '#D0F0FC',
        borderRadius: theme.spacing(2),
        margin: theme.spacing(2, 0, 0, 0),
        display: 'flex',
        alignItems: 'flex-start',
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(2, 0, 1, 0),
        },
    },
    svg: {
        minWidth: 24,
        minHeight: 24,
        marginRight: theme.spacing(1),
        marginTop: -2,
    },
}));

export function WarningMessage({className = '', body = undefined, svg = undefined, svgClassName = '', mode = 'default'}: WarningMessageProps) {
    const classes = useStyles({mode});
    return (
        <div className={clsx(classes.root, className)}>
            {svg && (
                <DynamicIcon
                    type={svg}
                    className={clsx(classes.svg, svgClassName)}
                    color={'currentColor'}
                />
            )}
            {body}
        </div>
    );
}

export interface WarningMessageProps {
    className?: string;
    body?: ReactNode;
    svg?: string;
    svgClassName?: string;
    mode?: 'default' | 'alert',
}

export default WarningMessage;
