import React, {useCallback} from 'react';
import {cssText} from '@ohoareau/css-utils';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {useLuniiTranslation, Clickable} from '../../ui';

const useStyles = makeStyles((theme) => ({
    root: {
        ...cssText(theme, 'standard', 'button_2'),
        margin: '5px 0',
        [theme.breakpoints.down('sm')]: {
            margin: '10px 0',
        },
    },
    content: {
        minWidth: '100%',
        maxWidth: '100%',
        padding: '5px 0px 5px 10px',
        '&::selection': {
            backgroundColor: 'transparent',
        },
    },
    active: {
        borderRadius: 4,
        backgroundColor: '#FEECC3',
    },
}));

export function CatalogMenuItem({type, item, active = false, onItemClick}: CatalogMenuItemProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const handleClick = useCallback(() => {
        onItemClick(
            type === 'langs'
                ? {
                      ...item,
                      label: t(`locale_${item.label}_product_language`),
                      type,
                  }
                : {...item, type},
        );
    }, [onItemClick, item, type]);

    if (!item) return null;

    const label = type === 'langs' ? t(`locale_${item.label}_product_language`) : item.label;
    return (
        <div className={classes.root}>
            <Clickable
                className={clsx(classes.content, active && classes.active)}
                onClick={handleClick}
            >
                {label}
            </Clickable>
        </div>
    );
}

interface CatalogMenuItemProps {
    type?: string;
    item?: any;
    onItemClick?: any;
    active?: boolean;
}

export default CatalogMenuItem;
