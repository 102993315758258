import React, {useState} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import {useForm, FormProvider} from 'react-hook-form';
import {cssText} from '@ohoareau/css-utils';
import clsx from 'clsx';
import {gift_card_theme} from '../../types';
import {useLuniiTranslation, useShoppingCart, Row, useLuniiNavigation} from '../../ui';
import GiftCardAmountChoice from '../molecules/GiftCardAmountChoice';
import GiftCardThemeChoice from '../molecules/GiftCardThemeChoice';
import GiftCardPreview from '../molecules/GiftCardPreview';
import GiftCardReceiverChoice from '../molecules/GiftCardReceiverChoice';
import GiftCardMessage from '../molecules/GiftCardMessage';
import GiftCardSenderChoice from '../molecules/GiftCardSenderChoice';
import GiftCardDateChoice from '../molecules/GiftCardDateChoice';
import GiftCardSubmit from '../molecules/GiftCardSubmit';
import {formatGiftCardAsCartItem} from '../../utils';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#FFFFFF',
    },
    grid: {
        alignSelf: 'stretch',
        position: 'relative',
    },
    container: {
        margin: theme.spacing(3, 0),
        '&:first-child': {
            marginTop: 0,
        },
        '&:last-child': {
            margin: theme.spacing(0, 0, 0, 0),
        },
    },
    desktopSubmit: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    mobileSubmit: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
        },
    },
    stickyContainer: {
        width: '100%',
        height: '100%',
        position: 'relative',
    },
    preview: {
        position: '-webkit-sticky',
        // @ts-ignore
        position: 'sticky',
        top: '20vh',
        left: 0,
        [theme.breakpoints.down('sm')]: {
            position: 'static',
            marginBottom: theme.spacing(5),
        },
    },
    error: {
        width: '100%',
        backgroundColor: '#FEE9E4',
        boxShadox: '0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: theme.spacing(2),
        ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#C04020'),
        margin: theme.spacing(2, 0),
        padding: theme.spacing(2),
    },
}));

export function GiftCards({giftCardsThemes = [], now, currency, catalog}: GiftCardsProps) {
    const classes = useStyles();
    const [{addCartItem}] = useShoppingCart();
    const {t} = useLuniiTranslation();
    const {goCheckout} = useLuniiNavigation();
    const form = useForm({mode: 'onBlur', defaultValues: {giftCardDate: now}});
    const {handleSubmit, errors} = form;
    const [formError, setFormError] = useState<any>(null);
    const [dateError, setDateError] = useState<any>(null);
    const [emptyValueErrors, setEmptyValueErrors] = useState<any>(false);
    const addToCart = (data: any) => {
        const {
            giftCardTheme: theme,
            giftCardAmount: amount,
            giftCardReceiverName: receiverName,
            giftCardReceiverEmail: receiverEmail,
            giftCardMessage: message,
            giftCardSenderName: senderName,
            giftCardNoReceiver: noReceiver,
            giftCardDate: date,
        } = data;
        const emptyErrors = {
            theme: !theme,
            amount: !amount.proposed && !amount.custom,
            receiverName: !receiverName,
            receiverEmail: !noReceiver && !receiverEmail,
            senderName: !noReceiver && !senderName,
            date: !date,
        };
        const formIsValid =
            Object.values(emptyErrors || {}).filter((x) => x).length === 0 &&
            Object.values(errors || {}).filter((x) => x).length === 0 &&
            !dateError;
        if (formIsValid) {
            const {id, reference, quantity, product, libelle, pageModel} = formatGiftCardAsCartItem(
                {
                    giftCardName: t('gift_cards_gift_in_cart_name'),
                    themeReference: theme.reference,
                    amount: amount?.proposed || amount?.custom,
                    image: theme.image,
                    currency,
                    catalog,
                    senderName,
                    receiverEmail,
                    receiverName,
                    date,
                    message,
                },
            );
            addCartItem(id, reference, quantity, product, libelle, pageModel);
            goCheckout && goCheckout();
        }
        if (emptyErrors) setEmptyValueErrors(emptyErrors);
        if (!formIsValid) setFormError(true);
    };
    const locale = catalog.substring(0, 2);
    return (
        <FormProvider {...form}>
            <Row
                className={classes.root}
                alignItems="flex-start"
                justify="flex-start"
            >
                <Grid
                    className={classes.grid}
                    item
                    container
                    lg={6}
                    md={6}
                    sm={12}
                    direction="column"
                >
                    <GiftCardThemeChoice
                        className={classes.container}
                        giftCardsThemes={giftCardsThemes}
                        emptyValueErrors={emptyValueErrors}
                        errorClassName={classes.error}
                    />
                    <GiftCardAmountChoice
                        className={classes.container}
                        currency={currency}
                        emptyValueErrors={emptyValueErrors}
                        errorClassName={classes.error}
                    />
                    <GiftCardReceiverChoice
                        className={classes.container}
                        emptyValueErrors={emptyValueErrors}
                        errorClassName={classes.error}
                    />
                    <GiftCardMessage className={classes.container} />
                    <GiftCardSenderChoice
                        className={classes.container}
                        emptyValueErrors={emptyValueErrors}
                        errorClassName={classes.error}
                    />
                    <GiftCardDateChoice
                        className={classes.container}
                        now={now}
                        locale={locale}
                        onError={setDateError}
                        error={dateError}
                        emptyValueErrors={emptyValueErrors}
                        errorClassName={classes.error}
                    />
                    <GiftCardSubmit
                        now={now}
                        className={clsx(classes.container, classes.desktopSubmit)}
                        currency={currency}
                        onClick={handleSubmit(addToCart)}
                        hasError={formError}
                        errorClassName={classes.error}
                    />
                </Grid>
                <Grid
                    className={classes.grid}
                    item
                    container
                    lg={1}
                    md={1}
                />
                <Grid
                    className={classes.grid}
                    item
                    container
                    lg={5}
                    md={5}
                    sm={12}
                >
                    <div className={classes.stickyContainer}>
                        <GiftCardPreview
                            locale={locale}
                            className={classes.preview}
                            currency={currency}
                        />
                    </div>
                </Grid>
                <Grid
                    className={clsx(classes.grid, classes.mobileSubmit)}
                    item
                    container
                    sm={12}
                    direction="column"
                >
                    <GiftCardSubmit
                        now={now}
                        currency={currency}
                        onClick={handleSubmit(addToCart)}
                        hasError={formError}
                        errorClassName={classes.error}
                    />
                </Grid>
            </Row>
        </FormProvider>
    );
}

export interface GiftCardsProps {
    giftCardsThemes: gift_card_theme[];
    now: Date;
    currency: string;
    catalog: string;
}

export default GiftCards;
