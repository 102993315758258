import {MutationHookOptions} from '@apollo/client';
import {REACTIVATE_SUBSCRIPTION, useLuniiApi, mutationInfos} from '../..';

interface useSubscriptionReactivateOptions {
    options?: MutationHookOptions;
}

export default function useSubscriptionReactivate({options}: useSubscriptionReactivateOptions = {}): [execute: Function, result: mutationInfos] {
    const {gql, useMutation} = useLuniiApi();
    const [execute, result] = useMutation(REACTIVATE_SUBSCRIPTION(gql), {
        notifyOnNetworkStatusChange: true,
        ...options,
    });
    return [execute, result];
}
