import React from 'react';
import {makeStyles} from '@material-ui/core';
import {cssText} from '@ohoareau/css-utils';
import {useLuniiTranslation, useLuniiEventTracking} from '../hooks';
import clsx from 'clsx';
import {Button} from '../atoms';
import Clickable from '../Clickable';

const useStyles = makeStyles(theme => ({
    root: {
        borderRadius: theme.spacing(1),
        backgroundColor: '#FFF9EC',
        padding: theme.spacing(2),
    },
    title: {
        ...cssText(theme, 'standard', 'body_thick', undefined, undefined, '#4F3B0C'),
        marginBottom: theme.spacing(1),
    },
    text: {
        ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#896717'),
        marginBottom: theme.spacing(2),
    },
}));

export function SubscriptionAdvert({navigate, className, ...props}: SubscriptionAdvertProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const handleTrackingEvent = useLuniiEventTracking();
    const handleNavigateTracking = () => {
        handleTrackingEvent({
            categorie: 'interaction',
            action: 'abonnement',
            libelle: 'decouverte',
        });
    };
    return (
        <div className={clsx(classes.root, className)} {...props}>
            <div className={classes.title}>
                {t('subscription_advert_offer_title')}
            </div>
            <div className={classes.text}>
                {t('subscription_advert_offer_text')}
            </div>
            <Clickable onClickCb={handleNavigateTracking}>
                <Button onClick={navigate} color={'primary'} size={'small'}>
                    {t('subscription_advert_navigate')}
                </Button>
            </Clickable>
        </div>
    );
}

export interface SubscriptionAdvertProps {
    className?: string
    navigate: Function | string
}

export default SubscriptionAdvert;
