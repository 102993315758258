import React, {useCallback} from 'react';
import {cssBox, cssText} from '@ohoareau/css-utils';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {buttonifyFromProps} from '../hocs';
import {image_value} from '../types';
import {Img} from '../nucleons';
import clsx from 'clsx';
import TextParagraph from './TextParagraph';
import {LuniiProductProvider} from '../contexts';
import {convertPrice} from '../utils';
import {useLuniiTranslation} from '../hooks';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        ...cssBox(theme, undefined, 'clear'),
        ...cssText(theme, 'standard', 'body'),
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        boxShadow: 'inset 0 -4px 16px -12px rgba(66, 63, 55, 0.1)',
    },
    right: {
        flexDirection: 'row-reverse',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
        },
    },
    imageContainer: {
        flex: 6,
        width: 'auto',
        lineHeight: 0,
        [theme.breakpoints.down('sm')]: {
            flex: 0,
        },
    },
    image: {
        height: '100%',
    },
    separator: {
        flex: 0.5,
        [theme.breakpoints.down('md')]: {
            flex: 0.3,
        },
    },
    textContainer: {
        flex: 5.5,
        [theme.breakpoints.down('md')]: {
            flex: 6,
        },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        [theme.breakpoints.down('sm')]: {
            flex: 1,
            justifyContent: 'flex-start',
        },
    },
    leftContent: {
        paddingRight: 150,
        [theme.breakpoints.down('md')]: {
            paddingRight: theme.spacing(3),
        },
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0, 2),
        },
    },
    rightContent: {
        paddingLeft: 150,
        [theme.breakpoints.down('md')]: {
            paddingLeft: theme.spacing(3),
        },
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0, 2),
        },
    },
    image2: {
        height: 66,
        width: 'auto',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    mobileText: {
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            width: '100%',
            margin: 0,
            padding: theme.spacing(4, 1, 2, 1),
        },
    },
    buttons: {
        gap: theme.spacing(0, 3),
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            gap: theme.spacing(0, 2),
        },
    },
    button: {
        marginBottom: theme.spacing(2),
    },
    outOfStock: {
        ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, 'status_alert'),
    },
}));

export function SliderContent({
    className,
    product,
    title,
    text,
    image,
    image2,
    imageLoading = 'eager',
    imagePosition = 'left',
    eventTrackingCallback,
    ...props
}: SliderContentProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const _product = product || {};
    const _img: any = image || _product.image || {};
    const _title = title || _product.name;
    const _alt = (_img && _img.alt) || _product.name || _title;
    const {buttonTarget, buttonLabel, buttonType, button2Target, button2Label, button2Type}: any =
        props; // target as button 2
    const {Buttons} = buttonifyFromProps(
        {
            buttonTarget:
                buttonTarget || (product && product.stock ? product.buttonTarget : undefined),
            buttonLabel:
                buttonLabel ||
                (product && product.stock
                    ? product.price === 0
                        ? t('product_price_free')
                        : convertPrice(product.price, product.currency)
                    : undefined),
            button2Target:
                button2Target || (product && product.type === 'packs' ? product.target : undefined),
            button2Label:
                button2Label ||
                (product && product.type === 'packs' ? t('product_discover') : undefined),
        },
        [
            buttonType || `primary${product && product.stock && ',startIcon=cart'}`,
            button2Type || 'plain',
        ].map((type: string) => `${type},size=small_mobile_only`),
    );
    // tracking event handler
    const PreparedButtons = useCallback(
        (props) => {
            return eventTrackingCallback ? (
                <Buttons
                    className={classes.button}
                    {...props}
                    onClickCb={() => {
                        const libelle = buttonTarget
                            ? buttonTarget
                            : product
                            ? product.buttonTarget
                            : 'bouton slider';
                        eventTrackingCallback({libelle});
                    }}
                />
            ) : (
                <Buttons className={classes.button} />
            );
        },
        [Buttons, eventTrackingCallback],
    );
    return (
        <LuniiProductProvider value={product}>
            <div className={clsx(classes.root, imagePosition === 'right' && classes.right)}>
                <div className={classes.imageContainer}>
                    {_img && (
                        <Img
                            className={classes.image}
                            {..._img}
                            alt={_alt}
                            loading={imageLoading}
                            objectFit={'cover'}
                            objectPosition={imagePosition === 'left' ? 'right' : 'left'}
                        />
                    )}
                </div>
                <div className={classes.separator} />
                <div className={classes.textContainer}>
                    <div
                        className={clsx(
                            imagePosition === 'left' ? classes.leftContent : classes.rightContent,
                        )}
                    >
                        {image2 && <Img className={classes.image2} {...image2} />}
                        <TextParagraph
                            className={classes.mobileText}
                            title={_title}
                            text={text}
                            buttonsComponent={PreparedButtons}
                            buttonsClassName={classes.buttons}
                        />
                    </div>
                </div>
            </div>
        </LuniiProductProvider>
    );
}

export interface SliderContentProps {
    product?: any;
    className?: any;
    title?: any;
    text?: any;
    image?: image_value;
    image2?: image_value;
    imageLoading?: 'eager' | 'lazy';
    imagePosition?: 'left' | 'right' | undefined;
    eventTrackingCallback?: any;
}

export default SliderContent;
