import React, {useEffect} from 'react';
import {registerLocale} from 'react-datepicker';
import {useLuniiPage} from '../../../ui';
import {convertLangToCatalog} from '../../../utils';
import {matchLocaleWithCurrency, useLuniiGiftCardsThemes} from '../../../hooks';
import {GiftCards} from '../../organisms/GiftCards';
import {mapLocaleToDateFnsLang} from '../../../../configs/site';

export function GiftCardsCustomModule(props: any) {
    const giftCardsThemes = useLuniiGiftCardsThemes();
    const {locale: pageLocale} = useLuniiPage() || {};
    const lang = (pageLocale || '').substring(0, 2);
    useEffect(() => {
        const loadLib = () =>
            import(
                /* webpackChunkName: "date_fns_locale" */ `date-fns/locale/${mapLocaleToDateFnsLang(
                    pageLocale,
                )}/index.js`
            ).then((loadedLang) => {
                registerLocale(lang, loadedLang);
            });
        loadLib();
    }, [lang, pageLocale]);
    if ((giftCardsThemes || []).length === 0) return null;
    return (
        <div>
            <GiftCards
                giftCardsThemes={giftCardsThemes}
                now={new Date()}
                currency={matchLocaleWithCurrency(pageLocale || '')}
                catalog={convertLangToCatalog(pageLocale || '')}
                {...props}
            />
        </div>
    );
}

export default GiftCardsCustomModule;
