import React from 'react';
import clsx from 'clsx';
import {cssText} from '@ohoareau/css-utils';
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    root: {
        ...cssText(theme, 'standard', 'large_title'),
    },
}));

export function LargeTitleText({className = undefined, children}) {
    const classes = useStyles();
    return (
        <div className={clsx(classes.root, className)}>
            {children || ''}
        </div>
    );
}

export default LargeTitleText
