import React, {useRef} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {cssText} from '@ohoareau/css-utils';
import clsx from 'clsx';
import {generateRandomId} from '../utils/generateRandomId';
import {convertProductReferenceToSlug, HardwareSelectorItem, hardware_product, Clickable, DropDown,} from '..';

const useStyles = makeStyles((theme) => ({
    root: ({rootId}: {rootId: string}) => ({
        // id is used to have more specificity over the dropdown component
        [`&#${rootId}`]: {
            display: 'flex',
            height: 86,
            ...cssText(theme, 'standard', 'secondary_body'),
            color: '#716C5E',
            borderRadius: theme.spacing(2),
            [theme.breakpoints.down('sm')]: {
                ...cssText(theme, 'standard', 'caption'),
            },
        },
    }),
    arrow: {
        height: 24,
        width: 24,
        color: '#2C3637',
    },
    clickable: {
        width: '100%',
    },
    popup: {
        maxHeight: 280,
        overflow: 'scroll',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        '-ms-overflow-style': 'none',
        scrollbarWidth: 'none',
        marginTop: 10,
    },
}));

export function HardwareSelector({
    className = '',
    product,
    alternateProducts,
    onProductChange,
}: HardwareSelectorProps) {
    // allows generateRandomId function to be invocated only once;
    const rootId = useRef<string>(generateRandomId('hardwareSelector'));
    const classes = useStyles({rootId: rootId.current});
    const handleClick = (e: any, p: any) => {
        e.preventDefault();
        onProductChange(p);
    };
    const currentChoice = (
        <HardwareSelectorItem
            translationKey={convertProductReferenceToSlug(product?.reference)}
            fallbackTitle={product?.name}
            image={product?.image?.url}
            current
        />
    );
    const choices = alternateProducts.map((p) => (
        <Clickable
            key={`qty_${p?.id}`}
            onClick={(e: any) => handleClick(e, p)}
            className={classes.clickable}
        >
            <HardwareSelectorItem
                translationKey={convertProductReferenceToSlug(p?.reference)}
                fallbackTitle={p?.name}
                image={p?.image?.url}
            />
        </Clickable>
    ));
    return (
        <DropDown
            rootId={rootId.current}
            className={clsx(classes.root, className)}
            currentChoice={currentChoice}
            choices={choices}
            popperPlacement='bottom'
            rounded
            popperFullWidth
            choicesClassName={classes.popup}
            clickableClassName={classes.clickable}
            arrowClassName={classes.arrow}
        />
    );
}

export interface HardwareSelectorProps {
    className?: string;
    product: hardware_product;
    alternateProducts: hardware_product[];
    onProductChange: (arg0: hardware_product) => void;
}

export default HardwareSelector;
