import React, {useEffect, useRef, useState} from 'react';

/**
 * Used in swiper instances to handle references on dom items used in properties such as Navigation, Pagination, ....
 */
export const useSwiperRef = <T extends HTMLElement>(): [T | null, React.Ref<T>] => {
    const [wrapper, setWrapper] = useState<T | null>(null);
    const ref = useRef<T>(null);

    useEffect(() => {
        if (ref.current) {
            setWrapper(ref.current);
        }
    }, []);

    return [wrapper, ref];
};

export default useSwiperRef;
