import React from 'react';
import {cssBox, cssText} from '@ohoareau/css-utils';
import ContentItemMosaic2 from '../molecules/ContentItemMosaic2';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Row from '../Row';
import Grid from '@material-ui/core/Grid';
import ForceTheme from '../ForceTheme';
import {WithItems, module_title} from '../types';

const useStyles = makeStyles(theme => ({
    root: {
        ...cssBox(theme, 'standard', 'clear'),
        ...cssText(theme, 'standard', 'body'),
        alignItems: 'flex-start',
    },
    title: {
        ...cssText(theme, 'standard', 'large_title'),
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            ...cssText(theme, 'standard', 'title_1'),
            marginBottom: theme.spacing(2),
        },
    },
    container: {
        margin: theme.spacing(-1.5),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(-1),
        },
    },
    item: {
        alignSelf: 'stretch',
    },
    itemSpace: {
        margin: theme.spacing(1.5),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(1),
        },
    },
}));

export function ContentItemsMosaic({title, items = []}: ContentItemsMosaicProps) {
    const classes = useStyles();
    if (items.length === 0) return null;
    return (
        <Row padding={'default--tablet-as-mobile'} className={classes.root} justify={'flex-start'}>
            <Grid item container lg={12} md={12} xs={12}>
                <div className={classes.title}>{title}</div>
            </Grid>
            <Grid item container lg={12} md={12} xs={12} className={classes.container}>
                {items.map((item, index) => (
                    <Grid item container className={classes.item} lg={4} md={6} xs={12} key={index}>
                        <ForceTheme theme={'default'}>
                            <ContentItemMosaic2 {...item} className={classes.itemSpace} />
                        </ForceTheme>
                    </Grid>
                ))}
            </Grid>
        </Row>
    );
}

export interface ContentItemsMosaicProps extends WithItems {
    title?: module_title,
}

export default ContentItemsMosaic;
