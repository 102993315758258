import * as React from 'react';
function SvgPlay(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width={props.width || 24}
            height={props.height || 24}
            {...props}
        >
            <path
                fill="none"
                d="M0 0h24v24H0z"
            />
            <path
                d="M7.5 12a.5.5 0 11-1 0 .5.5 0 011 0z"
                fill={props.color || 'currentColor'}
                stroke={props.color || 'currentColor'}
            />
            <path
                d="M7 8V6.062c0-1.66 1.904-2.597 3.22-1.586l7.609 5.854a2 2 0 01-.081 3.23l-7.61 5.267C8.812 19.746 7 18.797 7 17.183V16"
                stroke={props.color || 'currentColor'}
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
export default SvgPlay;
