import React from 'react';
import TextField, {TextFieldProps} from './TextField';

export function AdditionalAddressField(props: AdditionalAddressFieldProps) {
    return (
        <TextField name={'address2'} {...props} />
    );
}

export interface AdditionalAddressFieldProps extends TextFieldProps {}

export default AdditionalAddressField
