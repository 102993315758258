import React from 'react';
import {Helmet} from 'react-helmet';
import {Grid} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {cssText} from '@ohoareau/css-utils';
import {
    useHasMounted,
    buttonifyFromProps,
    module_button_label,
    module_button_target,
    module_button_type,
    module_text,
    module_title,
    Row,
} from '../../../ui';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: (props: any) => props.backgroundColor || 'white',
    },
    paragraph: {
        textAlign: 'center',
        marginBottom: theme.spacing(2),
        '& > :not(:first-child)': {
            marginTop: theme.spacing(2),
        },
    },
    title: {
        ...cssText(theme, 'standard', 'large_title'),
        [theme.breakpoints.down('sm')]: {
            ...cssText(theme, 'standard', 'title_1'),
        },
    },
    text: {
        ...cssText(theme, 'standard', 'body'),
    },
    button: {
        padding: 0,
    },
}));

export function DefaultSimplesatModule({
    dataWidgetId,
    backgroundColor,
    title,
    text,
    buttonLabel,
    buttonTarget,
    buttonType,
    ...props
}: DefaultSimplesatModuleProps) {
    const hasMounted = useHasMounted();
    const classes = useStyles({backgroundColor});
    const {Button} = buttonifyFromProps({buttonLabel, buttonTarget, buttonType});
    return (
        <Row
            padding="none"
            rootClassName={classes.root}
        >
            <Grid
                lg={6}
                md={6}
                xs={12}
                className={classes.paragraph}
            >
                {title && <div className={classes.title}>{title}</div>}
                {text && <div className={classes.text}>{text}</div>}
                {buttonLabel && Button && <Button className={classes.button} />}
            </Grid>
            <Grid
                lg={12}
                md={12}
                xs={12}
            >
                <a
                    href="https://simplesat.io"
                    data-widget-id={dataWidgetId}
                />
                <Helmet>
                    {hasMounted && (
                        <script
                            id="simplesat-widget-script"
                            src="https://cdn.simplesat.io/widget/widget-loader.js"
                            integrity="NIL29AV891M3clqia36oGChPeCsNDPMs7JiwpPrncztyamxTxSGBt43fZGUB0SRX"
                            crossOrigin="anonymous"
                        />
                    )}
                </Helmet>
            </Grid>
        </Row>
    );
}

export interface DefaultSimplesatModuleProps {
    title?: module_title;
    text?: module_text;
    buttonLabel?: module_button_label;
    buttonTarget?: module_button_target;
    buttonType?: module_button_type;
    dataWidgetId?: string;
    backgroundColor?: string;
}

export default DefaultSimplesatModule;
