import React from 'react';
import {cssBox} from '@ohoareau/css-utils';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import {row_padding_values} from './types';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        ...cssBox(theme, undefined, 'clear'),
        justifyContent: 'center',
    },
    root_padding_none: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    root_padding_custom1: {
        paddingTop: 64,
        paddingBottom: 24,
        [theme.breakpoints.down('sm')]: {
            paddingTop: 32,
            paddingBottom: 12,
        },
    },
    root_padding_custom2: {
        paddingTop: 48,
        paddingBottom: 48,
        [theme.breakpoints.down('sm')]: {
            paddingTop: 32,
            paddingBottom: 32,
        },
    },
    root_padding_default: {
        paddingTop: 64,
        paddingBottom: 64,
        [theme.breakpoints.down('sm')]: {
            paddingTop: 32,
            paddingBottom: 32,
        },
    },
    root_padding_small: {
        paddingTop: 32,
        paddingBottom: 32,
        [theme.breakpoints.down('sm')]: {
            paddingTop: 0,
            paddingBottom: 0,
        },
    },
    root_padding_medium: {
        paddingTop: 32,
        paddingBottom: 32,
        [theme.breakpoints.down('sm')]: {
            paddingTop: 16,
            paddingBottom: 16,
        },
    },
    root_padding_default__tablet_as_mobile: {
        paddingTop: 64,
        paddingBottom: 64,
        [theme.breakpoints.down('md')]: {
            paddingTop: 32,
            paddingBottom: 32,
        },
    },
    root_padding_small__tablet_as_mobile: {
        paddingTop: 32,
        paddingBottom: 32,
        [theme.breakpoints.down('md')]: {
            paddingTop: 0,
            paddingBottom: 0,
        },
    },
    root_padding_medium__tablet_as_mobile: {
        paddingTop: 32,
        paddingBottom: 32,
        [theme.breakpoints.down('md')]: {
            paddingTop: 16,
            paddingBottom: 16,
        },
    },
    root_padding_default_top: {
        paddingTop: 64,
        [theme.breakpoints.down('sm')]: {
            paddingTop: 32,
        },
    },
    root_padding_small_top: {
        paddingTop: 32,
        [theme.breakpoints.down('sm')]: {
            paddingTop: 0,
        },
    },
    root_padding_medium_top: {
        paddingTop: 32,
        [theme.breakpoints.down('sm')]: {
            paddingTop: 16,
        },
    },
    root_padding_default_bottom: {
        paddingBottom: 64,
        [theme.breakpoints.down('sm')]: {
            paddingBottom: 32,
        },
    },
    root_padding_small_bottom: {
        paddingBottom: 32,
        [theme.breakpoints.down('sm')]: {
            paddingBottom: 0,
        },
    },
    root_padding_medium_bottom: {
        paddingBottom: 32,
        [theme.breakpoints.down('sm')]: {
            paddingBottom: 16,
        },
    },
    root_padding_xsmall_top: {
        paddingTop: 24,
        [theme.breakpoints.down('sm')]: {
            paddingTop: 12,
        },
    },
    container: {
        flex: 1,
    },
    content: {
        paddingLeft: 150,
        paddingRight: 150,
        [theme.breakpoints.down('md')]: {
            paddingLeft: 24,
            paddingRight: 24,
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 16,
            paddingRight: 16,
        },
    },
    content_padding_none: {
        paddingLeft: 0,
        paddingRight: 0,
        [theme.breakpoints.down('md')]: {
            paddingLeft: 24,
            paddingRight: 24,
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 16,
            paddingRight: 16,
        },
    },
    content_padding_large: {
        paddingLeft: 247,
        paddingRight: 247,
        [theme.breakpoints.down('md')]: {
            paddingLeft: 150,
            paddingRight: 150,
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 16,
            paddingRight: 16,
        },
    },
    content_left: {
        paddingRight: 0,
    },
    content_left_except_mobile: {
        paddingRight: 0,
        [theme.breakpoints.down('sm')]: {
            paddingRight: 16,
        },
    },
    content_left_except_mobile_none: {
        paddingRight: 0,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
        },
    },
    content_right: {
        paddingLeft: 0,
    },
    content_right_except_mobile: {
        paddingLeft: 0,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 16,
        },
    },
    content_none: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    content_tablet_as_mobile: {
        // @todo
    },
    grid: {
        margin: 0,
        '& > .MuiGrid-item': {
            padding: theme.spacing(3),
        },
        [theme.breakpoints.down('md')]: {
            '& > .MuiGrid-item': {
                padding: theme.spacing(1),
            },
        },
        [theme.breakpoints.down('sm')]: {
            '& > .MuiGrid-item': {
                padding: theme.spacing(2),
            },
        },
    },
}));

export function Row({
    margins = 'all',
    padding = 'default',
    justify = 'center',
    alignItems = 'center',
    rootClassName,
    gridClassName,
    className,
    contentPadding,
    children,
    direction = 'row',
}: RowProps) {
    const classes = useStyles();
    const contentClassName = clsx(
        contentPadding ? classes[`content_padding_${contentPadding}`] : classes.content,
        className,
        'left' === margins && classes.content_left,
        'left-except-mobile' === margins && classes.content_left_except_mobile,
        'left-except-mobile-none' === margins && classes.content_left_except_mobile_none,
        'right-except-mobile' === margins && classes.content_right_except_mobile,
        'right' === margins && classes.content_right,
        'none' === margins && classes.content_none,
    );
    return (
        <div
            className={clsx(
                classes.root,
                rootClassName,
                classes[`root_padding_${padding.replace(/-/g, '_')}`],
            )}
        >
            <div className={classes.container}>
                <div className={contentClassName}>
                    <Grid
                        direction={direction}
                        spacing={0}
                        className={clsx(classes.grid, gridClassName)}
                        container
                        alignItems={alignItems}
                        justifyContent={justify}
                    >
                        {children}
                    </Grid>
                </div>
            </div>
        </div>
    );
}

export interface RowProps {
    children?: any;
    className?: string;
    rootClassName?: string;
    gridClassName?: string;
    direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
    margins?:
        | 'all'
        | 'left'
        | 'right'
        | 'none'
        | 'left-except-mobile'
        | 'left-except-mobile-none'
        | 'right-except-mobile';
    padding?: row_padding_values;
    alignItems?: 'flex-start' | 'center' | 'flex-end' | 'stretch' | 'baseline' | undefined;
    justify?:
        | 'center'
        | 'space-around'
        | 'space-between'
        | 'space-evenly'
        | 'flex-end'
        | 'flex-start'
        | undefined;
    contentPadding?: 'none' | 'large' | undefined;
    paddingTabletAsMobile?: boolean;
}

export default Row;
