import {useLocation} from '@reach/router';
import {useLuniiNavigation, useLuniiUser} from '../../../ui';

export function RegisterScreen() {
    const {user, logout} = useLuniiUser();
    const {goHomeRegister} = useLuniiNavigation();
    const location = useLocation();
    if (user) logout();
    const searchParams = new URLSearchParams(location.search);
    const deviceReturnContext = searchParams.get('device-return');
    goHomeRegister && goHomeRegister(null, {deviceReturnContext});
    return null;
}

export default RegisterScreen;
