import {ComponentType} from 'react';

export type user = {
    token?: string;
    refreshToken?: string;
    id?: string;
    hashedUserId?: string;
    email?: string;
    hashedEmail?: string;
    subscription?: subscription;
    newsletter?: user_newsletter;
    hasFahs?: boolean;
    hasFlam?: boolean;
    locale?: string;
    billingInfos?: address[];
    deliveryInfos?: address[];
    [key: string]: any;
};

export type user_newsletter = {
    active?: boolean;
    asked?: boolean;
    modificationDate?: number;
};

export type user_tokens = {
    token: string;
    refreshToken: string;
    resetPasswordToken?: string;
};

export type message = {
    code: string;
    level: string;
    message: string;
    data: string;
};

export type cart = {
    paymentTypes: payment_type[];
    id?: string;
    currency: string;
    appliedDiscountAmount?: number;
    country: string;
    price: number;
    basePrice: number;
    priceWithoutReduction: number;
    oldPrice: number;
    priceDetails: price_details;
    subpriceDetails: price_details;
    items?: cart_item[];
    bundles?: any[];
    discount?: discount;
    productsDiscount?: product_discount[];
    purchaseId?: string;
    messages?: message[];
    giftCard?: gift_card | undefined;
    leftToPay?: left_to_pay;
    accountBalance?: account_balance;
    removedItems?: removed_items[];
};

export type removed_items = {
    code: CartItemRemovalReason;
    items: removed_item[];
}

export type removed_item = {
    id: string;
    name: string;
    type: CartItemType;
}

export type discount = {
    code: string;
    mandatory: boolean;
    amountLimit: number;
    amount: number;
    value: string;
}

export type product_discount = {
    name: string;
    code: string;
    mandatory: boolean;
    amountLimit: number;
    amount: number;
    value: string;
    reference: string;
}

export type account_balance = {
    balance: number;
    amount: number;
};

export type left_to_pay = {
    totalWithVat: number;
};

export type price_details = {
    totalWithVat: number;
    total: number;
    taxTotal: number;
};

export type cart_item = {
    id: string;
    reference: string;
    quantity: number;
    currency: string;
    price: number;
    priceExclTax: number;
    oldPrice?: number;
    name?: string;
    type?: string;
    subtype?: string;
    thumbnailUrl?: string;
    productType?: string;
    age?: string | undefined;
    theme?: string | undefined;
    albumType?: string | undefined;
    foreignLang?: string | undefined;
    metadata?: item_metadata;
    productFromWishlist?: boolean;
    locale?: string;
    deviceTarget?: device_target,
    customBook?: boolean,
};

export type item_metadata = {
    amount: number;
    sender: {
        name: string;
    };
    receiver: {
        email: string;
        name: string;
    };
    sending_date: number;
    message: string;
    theme_reference: string;
};

export type user_context_value = {
    user: user | undefined;
    setUser: (user) => any | undefined;
    logout: () => any | undefined;
    refreshUser: () => any | undefined;
    refreshUserPartial: (params: {subscription?: boolean; library?: boolean}) => any | undefined;
    setTokens: (user_tokens) => any | undefined;
    getTokens: () => user_tokens | undefined;
};

export type tracking_context_value = {
    dataLayer: Array<any> | undefined;
    env: {
        env_type: string | undefined;
        env_appareil: string | undefined;
        env_langue: string | undefined;
        env_pays: string | undefined;
    };
};

export type cart_context_value = {
    cart: cart | undefined;
    setCart: (cart) => any | undefined;
    resetCart: () => any | undefined;
};

/**
 * locale is shaped like so: aa_BB
 * where aa is the lang
 * and BB the country ISO2 code
 */
export type address = {
    address1: string;
    address2?: string;
    city: string;
    firstName: string;
    lastName: string;
    country: string;
    locale: string;
    zipCode: string;
    phone?: string;
    hashedPhone?: string;
};

export interface StripeBillingDetails {
    address?: {
        city?: string;
        country?: string;
        line1?: string;
        line2?: string;
        postal_code?: string;
        state?: string;
    };
    email?: string;
    name?: string;
    phone?: string;
}

export type purchase = {
    id: string;
    status: string;
    modificationDate: number;
    currency: string;
    priceDetails: price_details;
    products: Array<purchase_product>;
    shipping?: purchase_shipping;
    paymentMethod: string;
    purchaseNumber: number;
    discount?: any;
    productsDiscounts?: any;
    giftCard?: gift_card;
    invoice?: invoice;
    accountBalance?: account_balance;
};

export type purchase_product = {
    productId: string;
    name: string;
    quantity: number;
    price: number;
    priceExclTax: number;
    reference: string;
};

export type gift_card = {
    giftCardId: string;
    reference?: string;
    balance?: number;
    creationDate?: number;
    expirationDate?: number;
    status?: string;
    currency?: string;
    amount?: number;
};

export type code_album_infos = {
    packs: Array<pack_album_infos>;
    countryCode: string;
    type: CodeAlbumEnum;
    codeAlbumId: string;
};

export type pack_album_infos = {
    title: string;
    image: image_value;
    duration: string;
    ageMin: string;
    productId: string;
    currency: string;
    reference: string;
    deviceTarget?: device_target;
};

export enum CodeAlbumEnum {
    ONE = 'one',
    ONE_IN_MANY = 'oneInMany',
}

export type credit_card_item = {
    customerId: string;
    brand: string;
    expMonth: number;
    expYear: number;
    id: string;
    last4: string;
    name?: string;
    isDefault?: boolean;
    usedInSubscription?: boolean;
};

export type credit_card = {
    card: credit_card_item;
    method: string;
};

export type purchase_shipping = {
    reference: string;
    price: number;
};

export type navigation_context_value = {
    ExternalLink: ComponentType;
    InternalLink: ComponentType;
    cartLocation?: string;
    checkoutLocation?: string;
    goCart?: Function;
    goCatalog?: Function;
    goCheckout?: Function;
    goHome?: Function;
    goHomeRegister?: Function;
    goHomePassword?: Function;
    goHomeLogin?: Function;
    goUserHome?: Function;
    goLibrary?: Function;
    goPageByModel?: Function;
    goSubscription?: Function;
    goSubscriptionGift?: Function;
    goWishlist?: Function;
    goSupport?: Function;
    rootNavigate?: Function;
    homeLocation?: string;
    loginLocation?: string;
    userHomeLocation?: string;
    userLibrary?: string;
    wishlistLocation: string;
};

export type language = {
    id: string;
    label?: any;
    target?: any;
};

export type sections_context_value = {
    section: string | undefined;
    sections: {id: string; label?: any; target?: any; selected?: boolean}[];
    allSections: {id: string; label?: any; target?: any}[];
    detectedSections: {id: string; selected?: boolean}[];
};

export type page_context_value = {
    uid: string;
    lang: string;
    product?: product;
    language?: language;
    languages?: language[];
    locale?: string;
    alternateLangs?: string[];
    menu?: any[];
    menuImage?: any[];
    pageModel?: string;
    pagesByModel: any;
};

export type catalog_context_item_value = {
    id?: string;
    name?: string;
    [key: string]: any;
};

export type catalog_context_menu_value = {
    id?: string;
    label?: string;
    items?: catalog_context_menu_value[];
    priority?: number;
};

export type catalog_context_value = {
    country: string;
    currency: string;
    fahProducts?: catalog_context_item_value[];
    flamProducts?: catalog_context_item_value[];
    fahMenu?: catalog_context_menu_value[];
    flamMenu?: catalog_context_menu_value[];
};

export type spa_context_value = {
    navigate: Function;
    prefix: string;
    screens: {[key: string]: any};
};

export type menuitem = {
    id: string;
    label?: string;
    target?: string;
    priority: number;
    items?: menuitem[];
};

export type breadcrumbs_item = {
    id: string;
    label: string;
    target?: string;
};

export type theme = {[key: string]: any};
export type themes = {default: theme; [key: string]: theme};

export type basebutton = {
    label?: string;
    type?: 'primary' | 'secondary' | 'tertiary' | 'default';
    variant?: 'contained' | 'outlined' | 'text';
    target?: any;
};

export type rich_text_value = string | JSX.Element;
export type target_value = any;
export type text_value = any;
export type image_value = {
    url: string;
    mobile?: {url?: string};
    tablet?: {url?: string};
    alt?: string;
};
export type module_title = rich_text_value;
export type module_subtitle = rich_text_value;
export type module_overline = rich_text_value;
export type module_label = rich_text_value;
export type module_description = rich_text_value;
export type module_text = rich_text_value;
export type module_button_type = text_value;
export type module_button_label = rich_text_value;
export type module_button_target = target_value;
export type module_image = image_value;
export type module_target = target_value;
export type module_color_variant = 'clear' | 'primary' | 'secondary' | string;

export type module_with_button = {
    buttonType?: module_button_type;
    buttonLabel?: module_button_label;
    buttonTarget?: module_button_target;
};

export type module_with_button2 = {
    button2Type?: module_button_type;
    button2Label?: module_button_label;
    button2Target?: module_button_target;
};

export type module_with_button3 = {
    button3Type?: module_button_type;
    button3Label?: module_button_label;
    button3Target?: module_button_target;
};

export type module_with_buttons = module_with_button & module_with_button2 & module_with_button3;

export type module_with_images = {
    image?: module_image;
    image2?: module_image;
    image3?: module_image;
};

export type module_item = module_with_buttons &
    module_with_images & {
        title?: module_title;
        overline?: module_overline;
        label?: module_label;
        text?: module_text;
        target?: module_target;
        product?: product;
        excludedLocales?: string[];
        theme?: string;
    };

export type module_push = module_with_buttons &
    module_with_images & {
        product?: product;
        title?: module_title;
        overline?: module_overline;
        label?: module_label;
        text?: module_text;
        target?: module_target;
    };

export type module_heading = module_with_buttons & {
    title?: module_title;
    overline?: module_overline;
    description?: module_description;
    text?: module_text;
    target?: module_target;
    image?: module_image;
    image2?: module_image;
    product?: any;
};

export type module_video = {
    title?: module_title;
    image?: module_image;
    url?: string;
    placeholderImage?: module_image;
};

export type module_items = {
    items?: module_item[];
};

export type module_argument = module_with_button & {
    text?: module_text;
    image?: module_image;
};

export type person_credits = {
    name?: string;
    gender?: gender;
    image?: {url?: string};
};

export type gender = 'male' | 'female' | 'NA';

export type module_section = {
    label?: string;
    target?: any;
    items?: any[];
};

export type module_sections = {
    onSectionChange?: Function;
    section?: string;
    sections: module_section[];
};

export type module_image_position = 'top' | 'bottom' | 'left' | 'right' | 'default';
export type module_mobile_image_position = 'top' | 'bottom' | 'default';

export type row_padding_values =
    | 'none'
    | 'default'
    | 'default-top'
    | 'default-bottom'
    | 'default--tablet-as-mobile'
    | 'medium'
    | 'medium-top'
    | 'medium-bottom'
    | 'medium--tablet-as-mobile'
    | 'small'
    | 'small-top'
    | 'small-bottom'
    | 'small--tablet-as-mobile'
    | 'xsmall-top'
    | 'custom1'
    | 'custom2';

export type WithButton = module_with_buttons;
export type WithButton2 = module_with_button2;
export type WithButton3 = module_with_button3;
export type WithButtons = module_with_buttons;
export type WithItems = module_items;
export type WithItem = module_item;
export type WithPush = module_push;
export type WithHeading = module_heading;
export type WithVideo = module_video;
export type WithArgument = module_argument;
export type WithSections = module_sections;
export type WithCreditCardItem = credit_card_item;

export type base_product = {
    __typename?: string;
    id?: string;
    objectId?: string;
    reference?: string;
    name?: string;
    slug?: string;
    creationDate?: number;
    currency?: string;
    catalog?: string;
    type?: string;
    url?: string;
    stock: boolean;
    label?: string;
    alt?: string;
    target?: string;
};

export type language_available = {
    slug?: string;
    url?: string;
    locale?: string;
};

export type locale_available = {
    id?: string;
    name?: string;
    slug?: string;
};

export type product_type = {
    id?: string;
    name?: string;
    slug?: string;
    image?: {url?: string};
};
export type product_theme = {
    id?: string;
    name?: string;
    slug?: string;
};

export type product_age_recommendation = {
    id?: string;
    name?: string;
    slug?: string;
};

export type digital_album_product = base_product & {
    oldPrice?: number;
    price?: number;
    priceExclTax: number;
    image?: {url?: string};
    previews?: string[];
    subtitle?: string;
    locale?: string;
    description?: string;
    reduction?: string;
    ageMin?: string;
    duration?: string;
    canPurchaseWithSubscription: boolean;
    languagesAvailable?: language_available[];
    localesAvailable?: locale_available[];
    types?: product_type;
    authors?: person_credits[];
    tellers?: person_credits[];
    translators?: person_credits[];
    themes?: product_theme[];
    ageRecommendations?: product_age_recommendation[];
    recommendations?: any;
    deviceTarget?: device_target;
    deviceTargetImage?: string;
    uuid?: string;
    stories?: audiobook_story[];
    credits?: audiobook_credit[];
    notFound?: boolean;
    customBook?: boolean;
};

export type audiobook_credit = {
    categoryTitle: string;
    songs: audiobook_credit_song[];
}

export type audiobook_credit_song = {
    credits: string;
    songName: string;
}

export type audiobook_story = {
    title: string;
    duration: number;
    combinations: string[];
}

export type device_target = ('flam' | 'fah')[];

export type hardware_product = base_product & {
    oldPrice?: number;
    price?: number;
    priceExclTax: number;
    reduction?: string;
    subtype?: string;
    version?: string;
    locale?: string;
    image?: {url?: string};
    illustration?: {url?: string};
    alternateProducts?: hardware_product[];
    recommendations?: any;
    description?: string;
    shippingInfos?: string;
    notFound?: boolean;
    included?: e_commerce_included[];
};

export type e_commerce_included = {
    title?: string;
    description?: string;
    imageUrl?: string;
    action?: {
        type: string;
        url?: string;
    };
};

export type product_item = base_product & {
    locale?: string;
    price?: number;
    subtype?: string;
    version?: string;
    image?: {url?: string};
    oldPrice?: number;
    priceExclTax: number;
    reduction?: string;
    label?: string;
    title?: string;
    buttonTarget?: string;
    target?: string;
    types?: string[]; // todo add good schema
    alternateProducts?: any; // todo add alternateProducts and define schema
};

export type paperbook_product = base_product & {
    title?: any; // temp
};

export type basic_product = base_product & {
    title?: any; // temp
};

export type book_product = base_product & {
    title?: any; // temp
};

export type product = digital_album_product &
    hardware_product &
    paperbook_product &
    book_product &
    basic_product &
    base_product;

export enum OwnedStatusesEnum {
    Owned = 'OWNED',
    ToBuy = 'TO_BUY',
    InCart = 'IN_CART',
}

export enum GiftCardStatusEnum {
    Redeemed = 'REDEEMED',
    Activated = 'ACTIVATED',
}

export enum MinifiableFormStatusEnum {
    Minified,
    Expanded,
    Loading,
    Error,
}

export enum PurchaseStatusEnum {
    CREATED = 'CREATED',
    PENDING = 'PENDING',
    CANCELLED = 'CANCELLED',
    SUCCESS = 'SUCCESS',
    FAILURE = 'FAILURE',
    ACCEPTED = 'ACCEPTED',
    SHIPPED = 'SHIPPED',
    REFUNDED = 'REFUNDED',
    PAID = 'PAID',
}

export enum ProductTableNameEnum {
    Hardware = 'hardware',
    Packs = 'packs',
    GiftCards = 'gift_card',
}

export type invoice = {
    id: string;
    invoiceId: string;
    url: string;
    status: string;
    creationDate: number;
    modificationDate: number;
    purchaseNumber: number;
};

export enum AddressTypeEnum {
    Billing = 'billing',
    Delivery = 'delivery',
}

export enum NavBarRootModeEnum {
    Default = 'DEFAULT',
    Checkout = 'CHECKOUT',
    My = 'MY',
    Library = 'LIBRARY',
    Wishlist = 'WISHLIST',
}

export enum MosaicCardItemDisplayEnum {
    Row = 'ROW',
    Column = 'COLUMN',
}

export enum PaymentTypeEnum {
    STRIPE_UNREGISTERED = 'stripe',
    STRIPE_REGISTERED = 'stripe_registered',
    PAYPAL = 'paypal',
    GIFT_CARD = 'gift_card',
    CREDIT = 'credit',
}

export type payment_type = {
    method: PaymentTypeEnum;
    card?: credit_card_item;
    balance?: number;
};

export type subscription = {
    userId?: string;
    creationDate: number;
    productReference: string;
    status: SubscriptionStatusEnum;
    stripeCustomerId: string;
    stripeSubscriptionId: string;
    stripeProductReference: string;
    renewalPlannedAt: number;
    plannedEvents: subscriptionPlannedEvents[];
    wallet: wallet;
    endTrialDate?: number;
    subscriptionCategory?: string;
    subscriptionPrice?: number;
    subscriptionCurrency?: string;
};

export type wishlist = {
    items: wishlist_item[];
};

export type wishlist_item = {
    title?: String;
    target?: String;
    buttonTarget?: String;
    _removed?: Boolean;
} & digital_album_product;

export type subscriptionPlannedEvents = {
    tag: SubscriptionPlannedEventsEnum;
    date: number;
};

export enum SubscriptionPlannedEventsEnum {
    DELETE_SUBSCRIPTION = 'delete_subscription',
    INCREMENT_BALANCE = 'increment_balance',
    END_WITHDRAWAL_PERIOD = 'end_withdrawal_period',
    DELETE_WALLET = 'delete_wallet',
    REFUND_SUBSCRIPTION = 'refund_subscription',
    NOTIFY_SUBSCRIPTION_TERMINATED = 'notify_subscription_terminated',
    NOTIFY_SUBSCRIPTION_CANCELED = 'notify_subscription_canceled',
    NOTIFY_SUBSCRIPTION_WITHDRAWN = 'notify_subscription_withdrawn',
}

export type wallet = {
    balance: number;
};

export enum SubscriptionStatusEnum {
    Active = 'active', // classic
    Widthdrawal = 'withdrawal', // 14 days trial, user can retract
    Termination = 'termination', // cancelled
    Trialing = 'trialing', // free month
    Free = 'free', // gift,
    Pastdue = 'past_due',
    Unpaid = 'unpaid',
}

/**
 * @property email user email
 * @property userFullname user name (first name + last name) linked to the billing address
 * @property address billing address of the user
 * @property productReference reference of the subscription product
 */
export type subscriptionPayment = {
    email: string;
    userFullname: string;
    address: subscriptionAddress;
    stripeProductReference: string;
};

/**
 * @property city city
 * @property country country code in two letters
 * @property postalCode postal code or zip code
 * @property addressLine1 first line of the address, the important one
 */
export type subscriptionAddress = {
    city: string;
    country: string;
    postalCode: string;
    addressLine1: string;
};

export type SubscriptionProduct = {
    id: string; // as product comes from stripe this property no longers contain the firebase id but the stripe id
    name: string;
    currency: string;
    oldPrice?: number;
    price: number;
    priceExclTax: number;
    reference: string;
    stripeProductReference: string; // contains stripe productId. `stripe_product_reference` stored in `reference`
    type: string; // should be `SUBSCRIPTION`   metadata.sub
    purchasable?: boolean; // purchasable status defined by the stripe product metadata
    __typename?: string;
    subscriptionCategory?: string;
    subscriptionPrice?: number;
    category?: SubscriptionCategory | null;
};

export enum SubscriptionCategory {
    MONTHLY = 'monthly',
    ANNUAL = 'annual',
}

export enum SubscriptionTypeEnum {
    MONTHLY = 'monthly_subscription',
    YEARLY = 'annual_subscription',
    FREE = 'free_subscription',
}

export type Fah = {
    fahId: string;
    name: string;
    creationDate: string;
    packId: string;
    serialNumber: string;
    serialNumberTypedByUser: boolean;
    metadata: FahMetadata;
    reference: string;
    version: string;
    illustrationUrl: string;
};

export type Device = {
    id: string;
    name: string;
    createdAt: string;
    packId: string;
    serialNumber: string;
    serialNumberTypedByUser: boolean;
    metadata: FahMetadata;
    reference: string;
    illustrationUrl: string;
    version?: string;
};

export type FahMetadata = {
    firmwareVersion: string;
    productId: string;
    sdCardSize: string;
    vendorId: string;
};

export type balance_status = {
    balance: number;
    expirationDate: string;
    valid: boolean;
    giftCardId?: string;
};

export type activateCoupon = {
    code: string;
    senderId: string;
    productId: string;
    purchaseId: string;
    numberOfMonths: number;
    receiverId: string;
    subscriptionId: string;
    expireAt: number;
    createdAt: number;
    updatedAt: number;
    deletedAt: number;
    consumedAt: number;
};

export type user_audiobook = {
    id?: string;
    title: string
    image: {url: string};
    label: string;
    slug: string;
    assets: string;
    deviceTarget?: ('fah' | 'flam')[];
    target?: string; // complete page url, added when the useUserAudiobooks hook is called
    customBook?: boolean;
}

export type shipping_method = {
    description: string;
    id: string;
    price: number;
    reference: string;
    selectedByDefault: string;
    title: string;
}

export const enum CartItemType {
    PACK = 'pack',
    HARDWARE = 'hardware',
    PROMOTION = 'promotion',
    PROMOTION_CODE = 'promotion_code',
    UNKNOWN = 'unknown',
}

export const enum CartItemRemovalReason {

    exclusive_locale_mismatch = 'exclusive_locale_mismatch',
    pack_already_in_user_library = 'pack_already_in_user_library', // livre audio dans la bibliothèque utilisateur
    out_of_stock = 'out_of_stock', // produit en rupture de stock
    shipping_not_allowed = 'shipping_not_allowed', // livraison du produit non disponible dans le département
    invalid_product = 'invalid_product', // produit non valable, reference qui ne match aucun produit
    mixed_array = 'mixed_array', // panier mixte
    promotion_locale_mismatch = 'promotion_locale_mismatch', // promotion applicable dans un store précis (ex: promo belge en france)
    invalid_promotion_code = 'invalid_promotion_code', // code non valable renvoyé par voucherify
    invalid_promotion = 'invalid_promotion', // promotion non valide
}

export const AcceptedCartItemRemovalReason = ['exclusive_locale_mismatch', 'pack_already_in_user_library', 'out_of_stock', 'shipping_not_allowed', 'invalid_product'];
