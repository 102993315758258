import React from 'react';
import ArgumentBlock from '../molecules/ArgumentBlock';
import clsx from 'clsx';
import {extractButtonsProps} from "../hocs";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Img} from "../nucleons";
import Grid from "@material-ui/core/Grid";
import {module_image_position, module_mobile_image_position, WithPush} from "../types";
import {cssBox} from "@ohoareau/css-utils";

const useStyles = makeStyles(theme => ({
    root: {
        ...cssBox(theme, undefined, 'clear'),
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        paddingLeft: 247,
        paddingRight: 247,
        [theme.breakpoints.down('md')]: {
            paddingLeft: 150,
            paddingRight: 150,
        },
        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },
    padding: {
        paddingLeft: 0,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
        }
    },
    reversePadding: {
        paddingRight: 0,
        [theme.breakpoints.down('sm')]: {
            paddingRight: theme.spacing(2),
        }
    },
    container: {
        filter: 'drop-shadow(0px 6px 12px rgba(66, 63, 55, 0.1)) drop-shadow(0px -1px 12px rgba(66, 63, 55, 0.02))',
        maxWidth: 'calc(1440px - 247px)',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            maxHeight: 'inherit',
        },
    },
    right: {
        flexDirection: 'row-reverse',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            maxHeight: 'inherit',
        }
    },
    imageContent: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            alignItems: 'flex-end',
            justifyContent: 'center',
            marginBottom: -5,
        },
    },
    textContent: {
        flex: 1.2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
}));

export function PushArgument({text, image, image2, imagePosition = 'left', ...props}: PushArgumentProps) {
    const classes = useStyles();
    const {buttonsProps} = extractButtonsProps(props)
    return (
        <Grid item container lg={12} md={12} xs={12} className={clsx(classes.root, imagePosition === 'left' ? classes.padding : classes.reversePadding)}>
            <div className={clsx(classes.container, (imagePosition === 'right' && classes.right))}>
                <div className={classes.imageContent}>
                    {image2 && <Img {...image2} objectFit={'contain'} />}
                </div>
                <div className={classes.textContent}>
                    <ArgumentBlock text={text} {...buttonsProps} image={image} noShadow={true}/>
                </div>
            </div>
        </Grid>
    );
}

export interface PushArgumentProps extends WithPush {
    imagePosition?: module_image_position,
    mobileImagePosition?: module_mobile_image_position,
}

export default PushArgument
