import React, {useMemo} from 'react';
import clsx from 'clsx';
import {cssText, cssBorder, cssBox} from '@ohoareau/css-utils';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import {convertPrice, getLongDate} from '../utils';
import {DynamicIcon} from '../nucleons';
import {useLuniiTranslation} from '../hooks';
import {PaymentTypeEnum, ProductTableNameEnum, PurchaseStatusEnum} from '../types';
import Button from './Button';
import constants from '../utils/constants';

const useStyles = makeStyles(theme => (
    {
        root: {
            ...cssBox(theme, undefined),
            ...cssText(theme, 'standard', 'body'),
            ...cssBorder(theme, 'push'),
            boxShadow: 'none',
            borderRadius: 8,
            margin: theme.spacing(1, 0),
            transition: 'background-color .5s ease',
            '&::before': {
                backgroundColor: 'inherit',
            },
            '&.Mui-expanded': {
                margin: theme.spacing(1, 0),
                paddingBottom: theme.spacing(1),
                paddingTop: theme.spacing(1),
            },
            [theme.breakpoints.down('sm')]: {
                width: 'calc(100% + 16px)',
                transform: 'translate3d(-8px, 0, 0)',
                padding: theme.spacing(0, .5),
            },
        },
        rootExpanded: {
            backgroundColor: '#fff9ec !important',
        },
        head: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        cell: {
            padding: 0,
            paddingRight: theme.spacing(2),
            display: 'flex',
            alignItems: 'center',
            '&:nth-child(2n)': {
                justifyContent: 'flex-end',
            },
        },
        accordionSummary: {
            ...cssText(theme, 'standard', 'body'),
            minHeight: 32,
            borderRadius: 8,
            '&>*': {
                margin: 0,
                padding: 0,
            },
            '&.Mui-expanded': {
                minHeight: 32,
                '&>*': {
                    margin: 0,
                    padding: 0,
                },
            },
            '&:hover:not(.Mui-expanded)': {
                ...cssBox(theme, undefined, 'clear'),
            },
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(0, .5),
            },
        },
        accordionSummaryExpanded: {
            color: '#896717 !important',
        },
        accordionSummaryWrapper: {
            padding: 0,
        },
        accordionSummaryIcon: {
            margin: '0 0 4px 8px',
        },
        accordionDetails: {
            display: 'block',
            border: 'none',
            padding: theme.spacing(0, 2, 0.5, 2),
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(0, 1, 0.5, 1),
            },
        },
        status: {
            paddingRight: theme.spacing(3),
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            '& >span': {
                ...cssText(theme, 'standard', 'body_thick'),
            },
        },
        title: {
            ...cssText(theme, 'standard', 'secondary_body_thick'),
            color: '#503C0D',
        },
        downloadInvoice: {
            marginLeft: theme.spacing(-2),
        },
        infoInvoice: {
            '& >span': {
                display: 'flex',
                alignItems: 'center',
            },
        },
        infoInvoiceLabel: {
            display: 'block',
            ...cssText(theme, 'standard', 'secondary_body'),
            marginLeft: theme.spacing(1),
            color: '#896717',
        },
        accordionDetailsWrapper: {
            padding: theme.spacing(.5, 0),
            paddingRight: theme.spacing(3),
            ...cssText(theme, 'standard', 'caption'),
        },
        accordionDetailsWrapperExpanded: {
            color: '#ae821d !important',
        },
        amount: {
            ...cssText(theme, 'standard', 'body_thick'),
            textAlign: 'right',
            color: '#503C0D',
        },
        detailAmount: {
            textAlign: 'right',
            whiteSpace: 'nowrap',
        },
        SUCCESS: {
            color: '#0c3421 !important',
        },
        REFUNDED: {
            color: '#493300 !important',
        },
        FAILURE: {
            color: '#510506 !important',
        },
        CANCELLED: {
            color: '#510506 !important',
        },
    }
));

export function PurchaseItem({className, expanded, toggle, purchase, timezone, locale}: PurchaseItemProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const purchaseStatus = useMemo(() => {
        switch (purchase.status) {
            case 'SUCCESS':
            case 'SHIPPED':
            case 'ACCEPTED':
            case 'PAID':
                return PurchaseStatusEnum.SUCCESS;
            case 'CREATED':
                return PurchaseStatusEnum.CREATED;
            case 'PENDING':
                return PurchaseStatusEnum.PENDING;
            case 'FAILURE':
            case 'DELETED':
                return PurchaseStatusEnum.FAILURE;
            case 'CANCELLED':
                return PurchaseStatusEnum.CANCELLED;
            case 'REFUNDED':
                return PurchaseStatusEnum.REFUNDED;
        }
        return false;
    }, [purchase.status]);
    const splittedProducts = useMemo(() => {
        return (purchase.products || []).reduce((products, product) => {
            if (product.tableName === ProductTableNameEnum.Hardware) {
                products.hardware.push(product);
            } else if (product.tableName === ProductTableNameEnum.Packs) {
                products.packs.push(product);
            } else {
                products.others.push(product);
            }
            return products;
        }, {
            packs: [],
            hardware: [],
            others: [],
        });
    }, [purchase.products]);
    const hasPacks = splittedProducts.packs.length > 0;
    const hasHardware = splittedProducts.hardware.length > 0;
    const hasOthers = splittedProducts.others.length > 0;
    const isSubscriptionPurchase = purchase.paymentMethod === PaymentTypeEnum.CREDIT;
    const isExpanded = expanded === purchase.id;
    const date = getLongDate(purchase.modificationDate, locale, {timeZone: timezone});
    return (
        <Accordion
            className={clsx(classes.root, isExpanded && classes.rootExpanded, className)}
            expanded={expanded === purchase.id} onChange={toggle(purchase.id)}>
            <AccordionSummary
                className={clsx(classes.accordionSummary, isExpanded && classes.accordionSummaryExpanded)}
                expandIcon={<DynamicIcon type={'ArrowDown'} color={'#20272C'}/>}
                IconButtonProps={{disableRipple: true}}
                aria-controls={`${purchase.id}_pi_content`}
                id={`${purchase.id}_pi_summary`}>
                <Grid
                    container
                    className={classes.accordionSummaryWrapper}>
                    <Grid
                        className={classes.cell}
                        item
                        lg={6}
                        md={6}
                        xs={6}>
                        {date}
                    </Grid>
                    <Grid
                        className={clsx(classes.amount, classes.cell)}
                        item
                        lg={6}
                        md={6}
                        xs={6}>
                        {!isSubscriptionPurchase ?
                            convertPrice(purchase.price, purchase.currency) :
                            <>
                                {constants.SUBSCRIPTION_PACK_CREDIT_VALUE}
                                <DynamicIcon
                                    className={classes.accordionSummaryIcon}
                                    type={'token'}/>
                            </>
                        }
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
                <Grid className={clsx(classes.status, classes.cell)}>
                    {purchaseStatus && (
                        <>
                            {`${t('purchase_status')} : `}
                            <span className={clsx(classes[purchaseStatus])}>
                                {t(`purchase_status_${purchaseStatus.toLowerCase()}`)?.toUpperCase()}
                            </span>
                        </>
                    )}
                </Grid>

                {/*Packs product lines*/}
                {hasPacks && expanded === purchase.id &&
                    <>
                        <span className={classes.title}>{t('purchase_invoice_subtitle_packs')}</span>
                        {splittedProducts.packs.map(({productId, name, price, quantity}) => (
                            <Grid
                                key={`${purchase.id}_${productId}`}
                                container
                                className={clsx(classes.accordionDetailsWrapper, classes.accordionDetailsWrapperExpanded)}>
                                <Grid
                                    className={classes.cell}
                                    item
                                    lg={6}
                                    md={6}
                                    xs={6}>
                                    {name}
                                </Grid>
                                {!isSubscriptionPurchase &&
                                    <Grid
                                        className={clsx(classes.detailAmount, classes.cell)}
                                        item
                                        lg={6}
                                        md={6}
                                        xs={6}>
                                        {quantity > 1 && <span>{`${quantity} × `}</span>}
                                        {convertPrice(price, purchase.currency)}
                                    </Grid>
                                }
                            </Grid>
                        ))}
                    </>
                }

                {/*Subscription and giftCards product lines*/}
                {hasOthers && expanded === purchase.id &&
                    <>
                        <span className={classes.title}>{t('purchase_invoice_subtitle_hardware')}</span>
                        {splittedProducts.others.map(({productId, name, price, quantity, tableName}) => (
                            <Grid
                                key={`${purchase.id}_${productId}`}
                                container
                                className={clsx(classes.accordionDetailsWrapper, classes.accordionDetailsWrapperExpanded)}>
                                <Grid
                                    className={classes.cell}
                                    item
                                    lg={6}
                                    md={6}
                                    xs={6}>
                                    {tableName === ProductTableNameEnum.GiftCards ? t('gift_cards_gift_in_cart_name') : name}
                                </Grid>
                                <Grid
                                    className={clsx(classes.detailAmount, classes.cell)}
                                    item
                                    lg={6}
                                    md={6}
                                    xs={6}>
                                    {quantity > 1 && <span>{`${quantity} × `}</span>}
                                    {convertPrice(price, purchase.currency)}
                                </Grid>
                            </Grid>
                        ))}
                    </>
                }

                {/*Packs or subscription invoices*/}
                {expanded === purchase.id && (hasPacks || hasOthers) && purchase?.invoice?.url &&
                    <Grid
                        className={clsx(classes.status, classes.downloadInvoice)}
                        item
                        lg={12}
                        md={12}
                        xs={12}>
                        <Button
                            onClick={purchase.invoice?.url}
                            size={'small'}
                            color={'tertiary'}
                            startIcon={<DynamicIcon type={'file'}/>}>
                            {t('purchase_invoice_download_invoice')}
                        </Button>
                    </Grid>
                }

                {/* Hardwares product lines && invoices*/}
                {hasHardware && expanded === purchase.id &&
                    <>
                        <span className={classes.title}>{t('purchase_invoice_subtitle_hardware')}</span>
                        {splittedProducts.hardware.map(({productId, name, price, quantity}) => (
                            <Grid
                                key={`${purchase.id}_${productId}`}
                                container
                                className={clsx(classes.accordionDetailsWrapper, classes.accordionDetailsWrapperExpanded)}>
                                <Grid
                                    className={classes.cell}
                                    item
                                    lg={6}
                                    md={6}
                                    xs={6}>
                                    {name}
                                </Grid>
                                <Grid
                                    className={clsx(classes.detailAmount, classes.cell)}
                                    item
                                    lg={6}
                                    md={6}
                                    xs={6}>
                                    {quantity > 1 && <span>{`${quantity} × `}</span>}
                                    {convertPrice(price, purchase.currency)}
                                </Grid>
                            </Grid>
                        ))}
                        {(purchaseStatus === PurchaseStatusEnum.REFUNDED || purchaseStatus === PurchaseStatusEnum.SUCCESS) &&
                            <Grid
                                className={clsx(classes.status, classes.infoInvoice)}
                                item
                                lg={12}
                                md={12}
                                xs={12}>
                                <span>
                                    <DynamicIcon
                                        type={'infos'}
                                        color={'#896717'}/>
                                    <span className={classes.infoInvoiceLabel}>{t('purchase_invoice_paper')}</span>
                                </span>
                            </Grid>
                        }
                    </>
                }
            </AccordionDetails>
        </Accordion>
    );
}

export interface PurchaseItemProps {
    className?: string,
    purchase?: any,
    toggle: Function,
    expanded?: string | boolean,
    timezone?: string,
    locale: string,
}

export default PurchaseItem;
