import React, {useEffect, useMemo, useReducer} from 'react';
import {
    HistoryLocation,
    NavigateFn,
    NavigateOptions,
    Redirect,
    useLocation,
    useNavigate,
} from '@reach/router';
import {Grid, makeStyles} from '@material-ui/core';
import clsx from 'clsx';
import {
    ForceTheme,
    LuniiPaymentProvider,
    LuniiSpaProvider,
    page_context_value,
    Row,
    Spacer,
} from '../ui';
import {usePageContext} from '../hooks';
import {page} from '../types';
import {routes} from '../configs/routes/subscription-gift';
import * as screens from './screens/subscription-gift';
import {Page} from './Page';
import {PrivateRoute} from './PrivateRoute';
import {PublicRoute} from './PublicRoute';
import {availableForSubscription, langs, stripePk, stripePkUS} from '../../configs/site';
import {
    LuniiSubscriptionGiftCheckoutProvider,
    subscriptionGiftCheckoutReducer,
} from '../contexts/LuniiSubscriptionGiftCheckoutContext';
import {PageAppBarModule} from './modules/app_bar/PageAppBarModule';
import {SpaLayout} from './layouts/SpaLayout';
import {menuClean} from '../services/property-mappers';
import {FadeTransitionRouter} from './routers';
import {SubscriptionGiftTunnelHeader} from './screens/subscription-gift/components/SubscriptionGiftTunnelHeader';

const subscriptionGiftCheckoutInitialState = {
    selected: {
        product: undefined,
        submitMode: undefined,
        metadata: {
            senderName: undefined,
            receiverEmail: undefined,
            receiverName: undefined,
            message: undefined,
            sendingDate: undefined,
        },
    },
    success: false,
};

const useStyles = makeStyles((theme) => ({
    root: {},
    headingWrapper: {
        opacity: 0,
        transition: 'opacity .35s ease-out, max-height .5s ease-in-out',
        pointerEvents: 'none',
        paddingTop: 32,
        overflow: 'hidden',
        maxHeight: 0,
        '&.show': {
            pointerEvents: 'auto',
            opacity: 1,
            maxHeight: '100%',
            transition: 'opacity .35s ease-out, max-height .75s ease-in-out',
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: 64,
        },
    },
    heading: {
        width: '100%',
        position: 'relative',
    },
}));

export function SubscriptionGift({pageContext = undefined}: SubscriptionGiftProps) {
    const classes = useStyles();
    const prefix = '/subscription-gift';
    const memoizedPageContext = useMemo(
        () => ({
            page: {
                uid: 'subscriptionGift',
                theme: 'default',
                lang: '@cart',
                product: undefined,
                head: {
                    link: [
                        {
                            as: 'script',
                            // eslint-disable-next-line max-len
                            href: 'https://cdnjs.cloudflare.com/ajax/libs/lazysizes/5.3.2/lazysizes.min.js',
                            rel: 'preload',
                        },
                    ],
                    script: [
                        {
                            async: true,
                            // eslint-disable-next-line max-len
                            src: 'https://cdnjs.cloudflare.com/ajax/libs/lazysizes/5.3.2/lazysizes.min.js',
                            type: 'text/javascript',
                        },
                    ],
                    title: 'Lunii - Offrir un abonnement',
                },
                favicon: undefined,
                modules: [],
                alernateLangs: [],
                language: undefined,
                languages: langs.map((lang) => ({
                    id: lang,
                    label: `locale_${lang.replace('-', '_').toLowerCase()}_label`,
                    target: `/${lang}/`,
                })),
                pageModel: undefined,
                features: {
                    pageTracking: false,
                },
                menu: pageContext?.page?.menu || [],
                menuImage: pageContext?.page?.menuImage || {},
                pagesByModel: pageContext?.page?.pagesByModel || {},
                env: pageContext?.page?.env,
            },
        }),
        [pageContext],
    );

    const {
        locale,
        navigation,
        user: userContext,
    } = usePageContext(memoizedPageContext as unknown as page);

    const baseNavigate: NavigateFn = useNavigate();
    const location: HistoryLocation = useLocation();

    const navigate = async (path: string | number, options?: NavigateOptions<{}>) => {
        if (Number.isInteger(path)) {
            // if provided path is a number, allows you to travel in history
            await baseNavigate(`${path}`, options);
        } else {
            await baseNavigate(`${prefix}${path}`, options);
        }
    };

    const menu = menuClean((memoizedPageContext?.page as any).menu[locale || '']);
    const menuImage = ((memoizedPageContext?.page.menuImage as any) || {})[locale || ''];

    const stripe = useMemo(() => {
        if (locale) {
            const stripeUS = ['US', 'CA', 'MX'];
            const country = (locale.split('-')[1] || '').toUpperCase();
            const pk = stripeUS.indexOf(country) !== -1 ? stripePkUS : stripePk;
            return pk
                ? {
                      pk,
                  }
                : undefined;
        }
        return undefined;
    }, [locale]);

    const luniiSubscriptionGiftCheckoutProviderValue = useReducer(
        subscriptionGiftCheckoutReducer,
        subscriptionGiftCheckoutInitialState,
    );

    const returnClickHandler = async (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        if (
            location.pathname === '/subscription-gift' ||
            location.pathname === '/subscription-gift/'
        ) {
            await navigation.goPageByModel('subscription_gift');
        } else {
            await navigation.rootNavigate(-1);
        }
    };

    // prevent /confirmation and /login page from displaying the SPANavigation
    const showSPANav =
        userContext.user &&
        location.pathname.match(
            /\/subscription-gift(?!\/confirmation)(?!\/login)(\/(billing|-1))?\/?/,
        );
    const currentStep = location.pathname.includes('billing') ? 2 : 1;

    // Prevent spa acces to users from locales other than available countries
    if (!availableForSubscription(locale)) {
        navigation?.goHome();
    } else if (location.pathname.match(/\/subscription-gift\/billing(\/)?/) &&
        (!luniiSubscriptionGiftCheckoutProviderValue[0].selected.submitMode || !luniiSubscriptionGiftCheckoutProviderValue[0].selected.product)
    ) {
        navigate('/');
    }

    return (
        <LuniiSpaProvider
            value={{
                prefix,
                navigate,
                screens,
            }}
        >
            <LuniiPaymentProvider
                stripe={stripe}
                locale={locale}
            >
                <LuniiSubscriptionGiftCheckoutProvider
                    value={luniiSubscriptionGiftCheckoutProviderValue}
                >
                    <Page pageContext={memoizedPageContext}>
                        <ForceTheme theme="default">
                            <PageAppBarModule
                                menu={menu || []}
                                menuImage={menuImage}
                            />
                        </ForceTheme>
                        <SpaLayout>
                            <Spacer size={2} />
                            <Row
                                padding="default-top"
                                direction="column"
                                alignItems="center"
                                justify="center"
                                rootClassName={clsx(
                                    classes.heading,
                                    classes.headingWrapper,
                                    showSPANav && 'show',
                                )}
                            >
                                <Grid
                                    item
                                    md={6}
                                    sm={9}
                                    xs={12}
                                    classes={{root: classes.heading}}
                                >
                                    <SubscriptionGiftTunnelHeader
                                        returnClickHandler={returnClickHandler}
                                        stepIndex={currentStep}
                                        stepCount={2}
                                    />
                                </Grid>
                            </Row>
                            <FadeTransitionRouter prefix={prefix}>
                                {routes.map((route: any, i: number) => {
                                    const key = `${i}-${route.path}`;
                                    if (route.redirect) {
                                        return (
                                            <Redirect
                                                noThrow
                                                key={key}
                                                from={route.path}
                                                to={`${prefix}/${route.redirect}`}
                                            />
                                        );
                                    }
                                    if (route.secured) {
                                        return (
                                            <PrivateRoute
                                                key={key}
                                                {...route}
                                                prefix={prefix}
                                            />
                                        );
                                    }
                                    return (
                                        <PublicRoute
                                            key={key}
                                            {...route}
                                            prefix={prefix}
                                        />
                                    );
                                })}
                            </FadeTransitionRouter>
                        </SpaLayout>
                    </Page>
                </LuniiSubscriptionGiftCheckoutProvider>
            </LuniiPaymentProvider>
        </LuniiSpaProvider>
    );
}

export interface SubscriptionGiftProps {
    pageContext?: page_context_value & {
        page: page;
    };
}

export default SubscriptionGift;
