import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {Grid, makeStyles, Theme} from '@material-ui/core';
import {FormProvider, useForm} from 'react-hook-form';
import {cssText} from '@ohoareau/css-utils';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Trans} from 'react-i18next';
import {
    Button,
    Illustration,
    Row,
    useLuniiEventTracking, useLuniiPage,
    useLuniiSpa,
    useLuniiTranslation,
    useSubscriptionGift,
} from '../../../ui';
import {
    LuniiSubscriptionGiftCheckoutContext,
    SubscriptionGiftCheckoutActionType,
} from '../../../contexts/LuniiSubscriptionGiftCheckoutContext';
import {SubscriptionGiftDeliveryEmailForm} from './components/SubscriptionGiftDeliveryEmailForm';
import {PickerWithSubview} from './components/PickerWithSubview';
import {SubscriptionGiftRecap} from './components/SubscriptionGiftRecap';
import {SubscriptionSubmitOptionsEnum} from '../../../definitions/subscriptionGift';
import {usePageTracking} from '../../../hooks';

const useStyles = makeStyles<Theme, {formHasError: boolean}>((theme) => ({
    root: {
        width: '100%',
        position: 'relative',
    },
    title: {
        ...cssText(theme, 'standard', 'title_1', undefined, undefined, '#2C3637'),
        margin: theme.spacing(0, 0, 4),
        [theme.breakpoints.up('sm')]: {
            ...cssText(theme, 'standard', 'large_title', 'center', undefined, '#2C3637'),
            margin: theme.spacing(0, 0, 5),
        },
    },
    recap: {
        margin: theme.spacing(0, 0, 4),
        [theme.breakpoints.up('sm')]: {
            margin: theme.spacing(0, 0, 5),
        },
    },
    explain: {
        display: 'block',
    },
    explainHeading: {
        ...cssText(theme, 'standard', 'title_2'),
        marginBottom: 16,
    },
    explainContent: {
        ...cssText(theme, 'standard', 'body', undefined, undefined, '#2C3637'),
    },
    sectionHeading: {
        ...cssText(theme, 'standard', 'title_2'),
        margin: '32px 0 24px',
        [theme.breakpoints.up('sm')]: {
            margin: '32px 0',
        },
    },
    picker: {
        margin: ({formHasError}) => (formHasError ? '0 0 16px' : '0 0 40px'),
    },
    choice: {
        display: 'flex',
        flexDirection: 'row',
        padding: theme.spacing(2, 6, 2, 2),
        alignItems: 'center',
        justifyContent: 'space-between',
        boxSizing: 'content-box',
        '&>div': {
            height: 56,
            flexShrink: 0,
        },
        '& span': {
            ...cssText(theme, 'standard', 'body_thick', 'left', undefined, '#2C3637'),
            display: 'block',
            margin: theme.spacing(0, 0, 0, 2),
            width: '100%',
        },
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(2),
            flexDirection: 'column',
            alignItems: 'center',

            '& span': {
                margin: theme.spacing(2, 0, 0),
                display: 'block',
                textAlign: 'center',
            },
        },
    },
    bulletOrderedList: {
        ...cssText(theme, 'standard', 'body', undefined, undefined, '#5F6769'),
        listStyle: 'none',
        counterReset: 'item',
        padding: 0,
        margin: theme.spacing(3, 0, 0),
        [theme.breakpoints.up('md')]: {
            margin: theme.spacing(3, 0, 0),
        },
        '& li': {
            counterIncrement: 'item',
            marginBottom: 8,
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
        },
        '& li:before': {
            ...cssText(theme, 'standard', 'body_thick', undefined, undefined, '#2C3637'),
            marginRight: 10,
            content: 'counter(item)',
            background: '#D7DDE0',
            borderRadius: '100%',
            width: '24px',
            textAlign: 'center',
            display: 'block',
            flexShrink: '0',
        },
    },
    actions: {
        width: '100%',
        alignItems: 'center',
        marginTop: 16,
        '&>*': {
            width: '100%',
            '&:not(:first-child)': {
                margin: theme.spacing(1, 0, 0, 0),
            },
        },
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            justifyContent: 'flex-end',
            '&>*': {
                width: 'auto',
                '&:not(:first-child)': {
                    margin: `${theme.spacing(0, 0, 0, 2)} !important`,
                },
            },
        },
    },
    loading: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 100,
        marginBottom: 48,
    },
}));

export function SelectScreen() {
    usePageTracking(true, 'abonnement_offrir_selection');
    const handeEventTracking = useLuniiEventTracking();
    const {navigate} = useLuniiSpa();
    const {i18n, t, exists} = useLuniiTranslation();
    const [state, dispatch] = useContext(LuniiSubscriptionGiftCheckoutContext);
    const [canGoNextStep, setCanGoNextStep] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const {locale} = useLuniiPage() || {};
    const [firstLoad, setFirstLoad] = useState<boolean>(false);
    const [
        {retrieveCouponProductsExecute},
        {
            retrieveCouponProductsResult: {loading},
        },
    ] = useSubscriptionGift({
        retrieveSubscriptionCouponProductsOptions: {
            variables: {countryCode: locale},
            onCompleted: (value: any) => {
                setFirstLoad(true);
                const product = value.retrieveSubscriptionCouponProducts?.items[0];
                if (product && state.selected?.product?.id !== product.id) {
                    // prevent fetch loop since updating context in subscription gift
                    // re-rendrers all tree and triggers useEffect below.
                    dispatch({
                        type: SubscriptionGiftCheckoutActionType.UPDATE_SELECTED_PRODUCT,
                        payload: {product},
                    });
                }
            },
        },
    })!;
    // Retrieve coupons;
    useEffect(() => {
        const execute = async () => {
            await retrieveCouponProductsExecute();
        };
        execute();
    }, [retrieveCouponProductsExecute, locale]); // Empty dependency array for execution on first render only.
    const form = useForm({
        mode: 'onBlur',
        defaultValues: {
            subscriptiongift_reciever_name: state.selected.metadata?.receiverName,
            subscriptiongift_reciever_email: state.selected.metadata?.receiverEmail,
            subscriptiongift_message:
                state.selected.metadata?.message ||
                (exists('subscriptiongift_form_message_defaultvalue') &&
                    t('subscriptiongift_form_message_defaultvalue')),
            subscriptiongift_sender_name: state.selected.metadata?.senderName,
            subscriptiongift_sending_date:
                state.selected.metadata?.sendingDate &&
                new Date(state.selected.metadata.sendingDate),
        },
    });
    const {handleSubmit, formState, reset} = form;
    const classes = useStyles({formHasError: formState.isSubmitted && !formState.isValid});
    useEffect(() => {
        if (state.selected.submitMode && !canGoNextStep) {
            setCanGoNextStep(true);
        } else if (!state.selected.submitMode && canGoNextStep) {
            setCanGoNextStep(false);
        }
    }, [canGoNextStep, setCanGoNextStep, state.selected.submitMode, formState.isValid]);
    const emitEventTracking = useCallback(() => {
        const {product, submitMode} = state.selected;
        handeEventTracking(
            {
                categorie: 'ecommerce',
                action: 'ajout_panier',
                valeur: product?.price,
            },
            {
                reference: 'offrir_abonnement',
                type: 'subscription_gift',
                name: `${product?.name} ${submitMode}`,
                price: product?.price,
                priceExclTax: product?.price,
                quantity: 1,
            },
        );
    }, [handeEventTracking, state.selected]);
    const onFormValidateSuccess = useCallback(
        async (data: any) => {
            dispatch({
                type: SubscriptionGiftCheckoutActionType.UPDATE_METADATA,
                payload: {
                    metadata: {
                        receiverName: data.subscriptiongift_reciever_name,
                        receiverEmail: data.subscriptiongift_reciever_email,
                        message: data.subscriptiongift_message,
                        senderName: data.subscriptiongift_sender_name,
                        sendingDate: data.subscriptiongift_sending_date
                            ? data.subscriptiongift_sending_date.getTime()
                            : undefined,
                    },
                },
            });
            if (data?.subscriptiongift_message) {
                handeEventTracking({
                    categorie: 'interaction',
                    action: 'abonnement',
                    libelle: data.subscriptiongift_message.length,
                });
            }
            emitEventTracking();
            await navigate('/billing');
        },
        [dispatch, emitEventTracking, handeEventTracking, navigate],
    );
    const onFormValidateError = useCallback(() => {
        setSubmitting(false);
    }, []);
    const handleValidate = useCallback(
        async (e: any) => {
            if (submitting) return;
            setSubmitting(true);
            try {
                if (state.selected?.submitMode === SubscriptionSubmitOptionsEnum.BY_EMAIL) {
                    await handleSubmit(onFormValidateSuccess, onFormValidateError)(e);
                } else {
                    emitEventTracking();
                    await navigate('/billing/');
                }
            } catch {
                setSubmitting(false);
            }
        },
        [
            state.selected?.submitMode,
            submitting,
            emitEventTracking,
            handleSubmit,
            onFormValidateSuccess,
            onFormValidateError,
            navigate,
        ],
    );
    const onPickerChoice = useCallback(
        (value: SubscriptionSubmitOptionsEnum): void => {
            reset();
            dispatch({
                type: SubscriptionGiftCheckoutActionType.UPDATE_SUBMIT_MODE,
                payload: {
                    submitMode: value,
                },
            });
        },
        [dispatch, reset],
    );
    const pickerOptions = useMemo(
        () => [
            {
                id: SubscriptionSubmitOptionsEnum.BY_HAND,
                choice: {
                    children: (
                        <div className={classes.choice}>
                            <Illustration
                                name="gift_subscriptioncoupon"
                                mt={0}
                                height={56}
                            />
                            <span>{t(`subscriptiongift_select_choice_choice_by_hand`)}</span>
                        </div>
                    ),
                },
                view: {
                    children: (
                        <>
                            {/* Fragment allows to have translations with multiple root keys */}
                            <Trans
                                i18nKey="subscriptiongift_select_choice_view_by_hand"
                                i18n={i18n}
                                components={{
                                    ol: <ol className={classes.bulletOrderedList} />,
                                    li: <li />,
                                }}
                            />
                        </>
                    ),
                },
            },
            {
                id: SubscriptionSubmitOptionsEnum.BY_EMAIL,
                choice: {
                    children: (
                        <div className={classes.choice}>
                            <Illustration
                                name="email"
                                mt={0}
                                height={56}
                            />
                            <span>{t(`subscriptiongift_select_choice_choice_by_email`)}</span>
                        </div>
                    ),
                },
                view: {
                    children: (
                        <>
                            <Trans
                                i18nKey="subscriptiongift_select_choice_view_by_email"
                                i18n={i18n}
                                components={{
                                    ol: <ol className={classes.bulletOrderedList} />,
                                    li: <li />,
                                }}
                            />
                            <p className={classes.sectionHeading}>
                                {t('subscriptiongift_select_choice_view_form_title')}
                            </p>
                            <SubscriptionGiftDeliveryEmailForm />
                        </>
                    ),
                },
            },
        ],
        [classes.bulletOrderedList, classes.choice, classes.sectionHeading, i18n, t],
    );
    return (
        <Row
            padding="custom2"
            direction="column"
            alignItems="center"
            justify="center"
            rootClassName={classes.root}
        >
            <Grid
                item
                md={6}
                sm={8}
                xs={12}
            >
                <div className={classes.title}>
                    <Trans
                        i18n={i18n}
                        i18nKey="subscriptiongift_title_step_1"
                    >
                        ...<span>...</span>...
                    </Trans>
                </div>
                {loading && !firstLoad && <div className={classes.loading}>
                    <CircularProgress size={40} />
                </div> || false}
                {(!loading || firstLoad) && <SubscriptionGiftRecap
                    subscription={state.selected}
                    className={classes.recap}
                /> || false}
                <div className={classes.explain}>
                    <Trans
                        i18nKey="subscriptiongift_select_explain"
                        i18n={i18n}
                    >
                        ...
                        <p className={classes.explainHeading}>...</p>
                        <p className={classes.explainContent}>...</p>
                        ...
                    </Trans>
                </div>
                <FormProvider {...form}>
                    <PickerWithSubview
                        options={pickerOptions}
                        onChoice={onPickerChoice}
                        className={classes.picker}
                        defaultSelectedId={state.selected?.submitMode}
                    />
                    <div className={classes.actions}>
                        <Button
                            onClick={handleValidate}
                            disabled={!canGoNextStep}
                            color="primary"
                        >
                            {t('subscriptiongift_navigate_next')}
                        </Button>
                    </div>
                </FormProvider>
            </Grid>
        </Row>
    );
}

export default SelectScreen;
