import React from 'react';
import {cssBox, cssText} from '@ohoareau/css-utils';
import QuoteSwiper from '../molecules/QuoteSwiper';
import QuoteSlide from '../molecules/QuoteSlide';
import {buttonifyFromProps} from "../hocs/buttonify";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Row from "../Row";
import Grid from "@material-ui/core/Grid";
import {module_title, WithButton, WithItems} from "../types";

const useStyles = makeStyles(theme => ({
    root: {
        ...cssBox(theme, 'standard', 'clear'),
        ...cssText(theme, 'standard', 'body'),
    },
    container: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    title: {
        ...cssText(theme, 'standard', 'large_title'),
        marginBottom: theme.spacing(8),
        [theme.breakpoints.down('sm')]: {
            ...cssText(theme, 'standard', 'title_1'),
            marginBottom: theme.spacing(4),
        },
    },
    button: {
        marginBottom: theme.spacing(1),
    },
    quote: {
        width: '66%',
        [theme.breakpoints.down('md')]: {
            width: '75%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
}));

export function Quote({title, items = [], ...props}: QuoteProps) {
    const classes = useStyles();
    if (items.length === 0) return null;
    const {Buttons} = buttonifyFromProps(props, ['tertiary,endIcon=next']);
    return (
        <Row className={classes.root}>
            <Grid item container className={classes.container} lg={12} md={12} xs={12}>
                <div className={classes.title}>{title}</div>
                {Buttons && <Buttons className={classes.button} />}
                {items.length === 1 && (
                    <div className={classes.quote}>
                        <QuoteSlide {...items[0]} />
                    </div>
                )}
                {items.length > 1 && (
                    <QuoteSwiper items={items}/>
                )}
            </Grid>
        </Row>
    );
}

export interface QuoteProps extends WithItems, WithButton {
    title?: module_title,
}

export default Quote
