import React from 'react';
import TextField, {TextFieldProps} from './TextField';

export function RecipientSelectorField(props: RecipientSelectorFieldProps) {
    return (
        <TextField name={'recipient'} {...props} />
    );
}

export interface RecipientSelectorFieldProps extends TextFieldProps {}

export default RecipientSelectorField