import React, {useCallback, useState, MouseEvent} from 'react';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {Grid, GridProps, LinkProps, PaperProps, Paper} from '@material-ui/core';
import {cssText} from '@ohoareau/css-utils';
import {Clickable, TextParagraph, Row, RowProps, TextParagraphProps} from '../';

const useStyles = makeStyles((theme) => ({
    root: {},
    container: {},
    menu: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        width: 'min-content',
        marginTop: theme.spacing(4),
    },
    title: {
        ...cssText(theme, 'standard', 'large_title', undefined, undefined, '#2C3637'),
    },
    description: {
        ...cssText(theme, 'standard', 'body', undefined, undefined, '#5F6769'),
        marginTop: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#5F6769'),
        },
    },
}));

const stylesActiveAndHover = {
    textDecoration: 'none',
    color: '#423f37',
    transition: 'color .35s ease-out',
    '&:after': {
        backgroundColor: '#FBBD2A',
        transition: 'background-color .35s ease-out',
    },
};

const useStylesLink = makeStyles((theme) => ({
    root: {
        ...cssText(theme, 'standard', 'body_thick'),
        display: 'flex',
        whiteSpace: 'nowrap',
        position: 'relative',
        cursor: 'pointer',
        textTransform: 'none',
        color: '#A79F8E',
        padding: theme.spacing(0, 0.5),
        margin: theme.spacing(0, 1),
        '&:first-child': {
            marginLeft: 0,
        },
        '&:last-child': {
            marginRight: 0,
        },
        '&:after': {
            content: '""',
            display: 'block',
            backgroundColor: 'transparent',
            width: '100%',
            height: 4,
            position: 'absolute',
            left: 0,
            bottom: -12,
            borderRadius: 2,
        },
        [theme.breakpoints.up('sm')]: {
            minWidth: 'auto',
        },
        '&:hover': {
            ...stylesActiveAndHover,
        },
    },
    selected: {
        ...stylesActiveAndHover,
    },
}));

export function PageMenu({
    title = '',
    description = '',
    className = '',
    classNameLink = '',
    classNameContainer = '',
    classNameMenu = '',
    TitleProps = {},
    RowProps = {},
    GridProps = {},
    PaperProps = {},
    LinkProps = {},
    onClick,
    items = [],
    defaultItem = undefined,
}: PageMenuProps) {
    const [itemSelected, setItemSelected] = useState<PageMenuItemInterface | undefined>(
        defaultItem,
    );
    const classes = useStyles();
    const classesLink = useStylesLink();

    const handleClickItem = useCallback(
        (item: PageMenuItemInterface) => (e: MouseEvent<HTMLAnchorElement>) => {
            setItemSelected(item);
            onClick && onClick(item);
        },
        [setItemSelected, onClick],
    );

    if (items.length === 0) return null;

    return (
        <Row
            className={clsx(classes.root, className)}
            {...RowProps}
        >
            <Grid direction="column" lg={6} md={8} xs={12}>
                {title && <div className={classes.title}>{title}</div> || false}
                {description && <div className={classes.description}>{description}</div> || false}
            </Grid>
            <Grid lg={6} md={4}/>
            <Grid
                direction="column"
                item
                container
                lg={12}
                md={12}
                xs={12}
                className={clsx(classes.container, classNameContainer)}
                {...GridProps}
            >
                <Paper
                    elevation={0}
                    square
                    className={clsx(classes.menu, classNameMenu)}
                    {...PaperProps}
                >
                    {items.map((item: any) => (
                        <Clickable
                            key={item.id}
                            onClick={item.link ? item.link : '#'}
                            onClickCb={handleClickItem(item)}
                            className={clsx(
                                classesLink.root,
                                itemSelected && item.id === itemSelected.id
                                    ? classesLink.selected
                                    : '',
                                classNameLink,
                            )}
                            {...LinkProps}
                        >
                            {item.label}
                        </Clickable>
                    ))}
                </Paper>
            </Grid>
        </Row>
    );
}

export interface PageMenuItemInterface {
    id: string;
    label: string;
    link?: string;
}

export interface PageMenuProps {
    title?: string;
    description?: any;
    className?: string;
    classNameLink?: string;
    classNameMenu?: string;
    classNameContainer?: string;
    onClick?: (item: PageMenuItemInterface) => void;
    RowProps?: RowProps;
    GridProps?: GridProps;
    PaperProps?: PaperProps;
    TitleProps?: TextParagraphProps;
    LinkProps?: LinkProps;
    items?: PageMenuItemInterface[];
    defaultItem?: PageMenuItemInterface;
}

export default PageMenu;
