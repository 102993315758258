import React, {useCallback} from 'react';
import {Swiper, SwiperProps, SwiperSlide} from 'swiper/react/swiper-react.js';
import {Navigation, Pagination} from 'swiper';
import clsx from 'clsx';
import Row from '../Row';
import 'swiper/swiper.min.css';
import SvgArrowLeft from '../images/icons/ArrowLeft';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {SliderStepper} from '../atoms';
import {SliderContent} from '../molecules';
import ForceTheme from '../ForceTheme';
import {useLuniiEventTracking} from '../hooks';
import useSwiperRef from '../hooks/swiper/useSwiperRef';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        width: '100%',
        height: '100%',
    },
    swiperContainer: {
        width: '100%',
        height: '100%',
    },
    swiperSlide: {
        display: 'flex',
        boxSizing: 'border-box',
        paddingTop: '37.5%',
        height: 0,
        overflow: 'hidden',
        position: 'relative',
        [theme.breakpoints.down('md')]: {
            paddingTop: '53%',
        },
        [theme.breakpoints.down('sm')]: {
            paddingTop: 0,
            height: 'auto',
            width: '100%',
        },
    },
    swiperContent: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            position: 'static',
            height: '100%',
            width: '100%',
        },
    },
    navigation: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '30px 0',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        },
    },
    stepper: {
        width: 'fit-content !important',
    },
    navButton: {
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxSizing: 'border-box',
        color: '#423F37',
        '&:focus': {
            outline: 'none',
        },
    },
    navLeft: {
        marginRight: 20,
    },
    navRight: {
        marginLeft: 20,
    },
    revert: {
        transform: 'rotate(180deg)',
    },
    hide: {
        display: 'none',
    },
}));

export function Slider({items = [], paginationBgColor = undefined}: SliderProps) {
    const classes = useStyles();
    const handleEventTracking = useLuniiEventTracking();
    const wrappedEventTracking = useCallback(({libelle}) => {
        handleEventTracking({categorie: 'interaction', action: 'carousel_horizontal', libelle});
    }, [handleEventTracking]);
    const [nextEl, nextElRef] = useSwiperRef<HTMLDivElement>();
    const [prevEl, prevElRef] = useSwiperRef<HTMLDivElement>();
    const [pagination, paginationRef] = useSwiperRef<HTMLDivElement>();

    const paginationClasses = paginationBgColor
        ? makeStyles(() => ({
              customBgColor: {backgroundColor: paginationBgColor},
          }))()
        : null;

    // const [swiperUpdate, setSwiperUpdate] = useState(false);
    const swiperOptions: SwiperProps = {
        modules: [Navigation, Pagination],
        slidesPerView: 'auto',
        spaceBetween: 24,
        watchOverflow: true,
        autoplay: {
            delay: 7000,
            disableOnInteraction: true,
        },
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0,
        pagination: {
            clickable: true,
            el: pagination,
            bulletClass: 'bull',
            bulletActiveClass: 'bull-active',
        },
        navigation: {
            prevEl,
            nextEl,
        },
        allowSlideNext: items.length > 1,
        allowSlidePrev: items.length > 1,
        allowTouchMove: items.length > 1,
    };

    if (items.length === 0) return null;

    return (
        <div className={classes.root}>
            <Row
                padding="none"
                margins="none">
                {swiperOptions && (
                    <Swiper
                        className={classes.swiperContainer}
                        {...swiperOptions}
                    >
                        {items.map((item, index) => (
                            <SwiperSlide
                                className={classes.swiperSlide}
                                key={`slide-${index}`}
                            >
                                <div className={classes.swiperContent}>
                                    <ForceTheme theme={item.theme || 'default'}>
                                        <SliderContent
                                            {...item}
                                            imageLoading={index === 0 ? 'eager' : 'lazy'}
                                            eventTrackingCallback={wrappedEventTracking}
                                        />
                                    </ForceTheme>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                )}
            </Row>
            <Row
                margins="none"
                className={clsx(items.length === 1 && classes.hide)}
                rootClassName={(paginationClasses && paginationClasses.customBgColor) || ''}
                padding="none"
                alignItems="center">
                <div className={classes.navigation}>
                    <div
                        className={clsx(classes.navButton, classes.navLeft)}
                        ref={prevElRef}>
                        <SvgArrowLeft
                            width={28}
                            height={28}
                            color="currentColor"
                        />
                    </div>
                    <SliderStepper
                        className={classes.stepper}
                        reference={paginationRef}
                    />
                    <div
                        className={clsx(classes.navButton, classes.navRight)}
                        ref={nextElRef}>
                        <SvgArrowLeft
                            width={28}
                            height={28}
                            color="currentColor"
                            className={classes.revert}
                        />
                    </div>
                </div>
            </Row>
        </div>
    );
}

export interface SliderProps {
    items?: any[],
    paginationBgColor?: string
}

export default Slider;
