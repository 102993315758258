import React from 'react';
import {paperbook_product} from '../../ui';

export function PaperBookProduct({product}: PaperBookProductProps) {
    return (
        <>
            PAPER BOOK - {product.id} - {product.title}
        </>
    );
}

export interface PaperBookProductProps {
    product: paperbook_product;
}

export default PaperBookProduct;
