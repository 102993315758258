import * as React from 'react';
function SvgEdit(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width={props.width || 24}
            height={props.height || 24}
            {...props}
        >
            <g clipPath="url(#edit_svg__clip0)">
                <path
                    fill="none"
                    d="M0 0h24v24H0z"
                />
                <path
                    d="M4 17v3h3"
                    stroke={props.color || 'currentColor'}
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M6 14l9.586-9.586a2 2 0 012.828 0l1.172 1.172a2 2 0 010 2.828L10 18"
                    stroke={props.color || 'currentColor'}
                    strokeWidth={2}
                    strokeLinecap="round"
                />
                <path
                    d="M14 7l3 3"
                    stroke={props.color || 'currentColor'}
                    strokeWidth={2}
                />
            </g>
            <defs>
                <clipPath id="edit_svg__clip0">
                    <path
                        fill="none"
                        d="M0 0h24v24H0z"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}
export default SvgEdit;
