import React, {Component} from 'react';

export class ErrorBoundary extends Component<ErrorBoundaryProps> {
    constructor(props) {
        super(props);
        this.state = {hasError: false, error: undefined};
    }
    static getDerivedStateFromError(error) {
        return {hasError: true, error};
    }
    componentDidCatch(error, errorInfo) {
        // TODO: send data to sentry
        // console.log('🐱 error ', error);
        // console.log('🐱 errorInfo ', errorInfo);
    }
    formatErrorTitle() {
        const {error, page = undefined, module = undefined} = this.props as any;
        if (module) return `RENDER MODULE ERROR (${module?.type} - ${module?.mode})`;
        if (page) return `RENDER PAGE ERROR (${page?.uid})`;
        return `RENDER ERROR: ${error.message}`;
    }
    render() {
        const {
            hasError,
            error,
        } = this.state as any;
        const {
            page,
            children,
        } = this.props;
        if (!hasError) return children;
        return (
            <div style={{display: 'block', backgroundColor: '#FFFFFF', position: 'fixed', zIndex:100000, top: 0, left: 0, height: '100vh', width: '100vw', fontFamily: 'Frutiger, "Frutiger Linotype", Univers, Calibri, "Gill Sans", "Gill Sans MT", "Myriad Pro", Myriad, "DejaVu Sans Condensed", "Liberation Sans", "Nimbus Sans L", Tahoma, Geneva, "Helvetica Neue", Helvetica, Arial, sans-serif'}}>
                <div style={{padding: '100px', width: '100%', height: '100%', boxSizing: 'border-box'}}>
                    <h1>Lunii</h1>
                    <p>OUPS ! Vous êtes tombés sur une erreur et vous voyez ce message à cause de cette dernière. Merci de retourner sur <a
                        href="https://lunii.com" rel={'noopener noreferrer'}>l'accueil de Lunii.com</a> ou d'utiliser le Luniistore le temps que nos lutins règlent ce souci !</p>
                    <p>WOPS ! Our website encountered an issue and you are seeing this message because of it. Please go back to the <a
                        href="https://lunii.com" rel={'noopener noreferrer'}>Lunii.com homepage</a> or use the Luniistore while our leprechauns solve this issue !</p>
                    { page?.env && page.env !== 'prod' && <div>
                        <p>
                            Ce message ne s'affiche que dans les environnements de test. <br/>
                            Si vous êtes de la maison hésitez pas à envoyer l'erreur ci-dessous à vous savez qui:
                        </p>
                        <pre>{this.formatErrorTitle()}</pre>
                        <pre>{error?.message || 'unable to render'}
                            {error?.stack}
                        </pre>
                    </div> }
                </div>
            </div>
        );
    }
}

export interface ErrorBoundaryProps {
    children?: any,
    module?: any,
    page?: any,
}

export default ErrorBoundary;
