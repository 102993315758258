import * as React from 'react';
function SvgCardPaypal(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width={props.width || 24}
            height={props.height || 24}
            {...props}
        >
            <path
                d="M8.611 19.003h-3.17a.41.41 0 01-.184-.044.447.447 0 01-.151-.125.522.522 0 01-.1-.387L7.143 3.677C7.2 3.286 7.509 3 7.869 3h5.135c1.769 0 3.15.407 3.916 1.357.695.863.898 1.816.697 3.216a9.135 9.135 0 01-.053.327c-.677 3.788-2.994 5.098-5.952 5.098h-1.507c-.36 0-.666.287-.723.675l-.77 5.33zm9.737-10.815a2.383 2.383 0 00-.418-.406c-.01.057-.018.131-.028.19-.64 3.584-2.757 5.401-6.29 5.401h-1.507a.37.37 0 00-.253.103.433.433 0 00-.13.256l-.817 5.646h-.348l-.165 1.137a.457.457 0 00.088.338.391.391 0 00.131.109.36.36 0 00.162.038h2.672c.316 0 .585-.25.634-.591.042-.195.523-3.639.562-3.817a.717.717 0 01.217-.424.611.611 0 01.418-.168h.4c2.588 0 4.615-1.146 5.206-4.459.248-1.385.12-2.541-.534-3.353z"
                fill="#253B80"
            />
        </svg>
    );
}
export default SvgCardPaypal;
