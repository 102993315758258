import {useCallback} from 'react';
import {SPEND_SUBSCRIPTION_CREDIT, useLuniiApi} from '../..';

export function useSubscriptionSpendCreditSubmit(callback: Function|undefined = undefined) {
    const {gql, useMutation} = useLuniiApi();
    const [spendSubscriptionCredit, {loading, error, ...infos}] = useMutation(SPEND_SUBSCRIPTION_CREDIT(gql));
    const onSubmit = useCallback(async ({locale, currency, productReference}) => {
        try {
            const data = await spendSubscriptionCredit({
                variables: {
                    data: {
                        locale,
                        currency,
                        productReference,
                    },
                },
            });
            callback && callback({result: ((data || {}).data || {}).spendSubscriptionCredit}, error);
            return ((data || {}).data || {}).spendSubscriptionCredit;
        } catch (e) {
            console.error(e);
            callback && callback({error: e});
            return false;
        }
    }, [spendSubscriptionCredit, callback, error]);
    return [onSubmit, {loading: Boolean(loading), error: error || false, ...infos}] as [any, any];
}

export default useSubscriptionSpendCreditSubmit;
