import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {cssText} from '@ohoareau/css-utils';
import {audiobook_credit, audiobook_credit_song, digital_album_product, Modal, useLuniiTranslation} from '../../../ui';
import {Button} from '.';

const useStyles = makeStyles(theme => ({
    body: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gap: 16,
        margin: theme.spacing(2, 0, 3, 0)
    },
    title: {
        ...cssText(theme, 'standard', 'body_thick', undefined, undefined, '#423F37'),
    },
    text: {
        ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#2C3637'),
    },
}));

export function ProductCreditsModal({className = '', product, open = false, setOpen = () => {}}: ProductCreditsModalProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    return (
        <Modal
            className={className}
            title={t('product_credits_modal_title')}
            subtitle={product.name}
            body={(
                <div className={classes.body}>
                    {(product.credits || []).map((credit: audiobook_credit) => (
                        <div>
                            {credit.categoryTitle && <div className={classes.title}>{credit.categoryTitle}</div> || false}
                            <div>
                                {credit.songs.map((creditSong: audiobook_credit_song) => (
                                    <div className={classes.text}>
                                        {creditSong.credits && <div>{creditSong.credits}</div> || false}
                                        {creditSong.songName && <div>{creditSong.songName}</div> || false}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            )}
            actions={<Button url={() => setOpen(false)} text={t('button_label_close')}/>}
            open={open}
            onCloseCallback={() => setOpen(false)}
        />
    );
}

export interface ProductCreditsModalProps {
    className?: string;
    product: digital_album_product;
    open?: boolean;
    setOpen?: Function;
}

export default ProductCreditsModal;
