import React from 'react';
import Link from '@material-ui/core/Link';

export function DefaultLinkComponent({href, to, active, children, ...props}: DefaultLinkComponentProps) {
    // noinspection SuspiciousTypeOfGuard
    return (
        <Link href={to ? (('string' === typeof to) ? to : to['url']) : (href || '/')} {...props}>
            {children || ''}
        </Link>
    );
}

export interface DefaultLinkComponentProps {
    t?: Function,
    tReady?: boolean,
    i18n?: any,
    classes?: any,
    href?: any
    to?: string,
    children?: any,
    theme?: any,
    active?: any,
}

export default DefaultLinkComponent
