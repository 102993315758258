import {useCallback, useEffect} from 'react';
import useLuniiLazyQueryApi from './useLuniiLazyQueryApi';

const useLuniiPurchasesFindApi = (options: any = {}) => useLuniiLazyQueryApi('FIND_PURCHASES', options);

export function usePurchasesFind(callback: Function|undefined = undefined) {
    const [getPurchases, {data, loading, error, ...infos}] =
        useLuniiPurchasesFindApi();
    const onSubmit = useCallback(async ({withInvoices}: {withInvoices: boolean} = {withInvoices: false}) => {
        try {
            await getPurchases({
                variables: {
                    withInvoices,
                },
            });
        } catch (e) {
            console.error(e);
        }
    }, [getPurchases, callback]);
    useEffect(() => {
        if (data?.findPurchases && callback) {
            callback(data.findPurchases?.items || []);
        }
    }, [data?.findPurchases]);
    return [onSubmit, {loading: Boolean(loading), error: error || false, ...infos}] as [any, any];
}

export default usePurchasesFind;
