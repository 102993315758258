import React, {MouseEventHandler, ReactNode, useEffect, useRef, useState} from 'react';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {cssShadow, cssText} from '@ohoareau/css-utils';
import {Modal as MuiModal} from '@material-ui/core';
import {DynamicIcon} from '..';
import {Spinner} from '../atoms/Spinner';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'absolute',
        maxWidth: 622,
        width: '90%',
        border: 'none',
        outline: 'none',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '95vh',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            maxWidth: '100%',
            left: 0,
            bottom: 0,
            top: 'unset',
            transform: 'unset',
            maxHeight: '85vh',
        },
    },
    main: {
        position: 'relative',
        backgroundColor: 'white',
        borderRadius: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
        },
        '& >*': {
            padding: theme.spacing(3),
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(2),
            },
        },

        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    mainBodyOverflowScroll: {
        maxHeight: '95vh',
        boxSizing: 'border-box',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'stretch',
        [theme.breakpoints.down('sm')]: {
            maxHeight: '85vh',
        },
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        [theme.breakpoints.up('sm')]: {
            marginBottom: -8,
        },
    },
    headerInfos: {
        width: '100%',
    },
    modalTitle: {
        ...cssText(theme, 'standard', 'title_2', undefined),
    },
    modalSubtitle: {
        ...cssText(theme, 'standard', 'title_1', undefined, undefined, '#5F6769'),
        maxWidth: '80%',
    },
    body: {
        flexGrow: 1,
        overflow: 'auto',
        minHeight: 0, // for Firefox
        padding: theme.spacing(0, 3, 1, 3),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0, 2),
        },
    },
    bodyOverflowScroll: {
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        '-ms-overflow-style': 'none',
        scrollbarWidth: 'none',
    },
    actions: {
        display: 'flex',
        justifyContent: 'flex-end',
        '& >*': {
            width: 'auto',
        },
        '& >*:not(:first-child)': {
            margin: theme.spacing(0, 0, 0, 2),
        },
        '& >*:not(:last-child)': {
            margin: theme.spacing(0, 0, 0, 0),
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
            '& >*': {
                width: '100%',
            },
            '& >*:not(:first-child)': {
                margin: 0,
            },
            '& >*:not(:last-child)': {
                margin: theme.spacing(1, 0, 0, 0),
            },
        },
    },
    overflowShadow: {
        ...cssShadow(theme, 'level_04'),
        zIndex: 2,
    },
    headerShadow: {
        ...cssShadow(theme, 'level_02'),
    },
    errors: {
        margin: theme.spacing(2, 0, 0, 0),
    },
    icon: {
        cursor: 'pointer',
        marginTop: 3,
    },
    overlay: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 10,
        position: 'absolute',
        overflow: 'hidden',
        '&:before': {
            content: '""',
            position: 'absolute',
            height: '100%',
            width: '100%',
            backgroundColor: 'white',
            opacity: .1,
            zIndex: -1,
        },
        '@supports (backdrop-filter: blur(1px))': {
            backdropFilter: 'blur(1px)',
        },
    },
    spinner: {
        overflow: 'hidden',
        borderRadius: theme.spacing(2),
    },
}));

export function Modal({
  title,
  subtitle = undefined,
  body = undefined,
  topContent,
  actions,
  errors,
  open,
  className = '',
  bodyClassName = '',
  titleClassName = '',
  onCloseCallback,
  loading = false,
  muiModalProps = {},
  bodyScrollOnOverflow = true,
  overlay = false,
  ...props
}: ModalProps) {
    const classes = useStyles();
    const bodyRef = useRef<null | HTMLDivElement>(null);
    const [bodyOverflow, setBodyOverflow] = useState<boolean>(false);
    const [headerShadow, setHeaderShadow] = useState<boolean>(false);

    const onClose = (e) => {
        if (onCloseCallback && !overlay) {
            onCloseCallback(e);
        }
    };

    const handleScroll = (e: any) => {
        setHeaderShadow(e?.target?.scrollTop > 0);
    };

    /**
     * TODO: could add a resize event listener to watch and update the var accordingly. Only body in dependency
     * array as refs changes do not trigger rerenders
     */
    useEffect(() => {
        if (bodyRef.current) {
            setBodyOverflow(
                bodyRef.current?.clientWidth < bodyRef.current?.scrollWidth ||
                bodyRef.current?.clientHeight < bodyRef.current?.scrollHeight,
            );
            bodyRef.current.addEventListener('scroll', handleScroll);
        } else if (!bodyRef.current && bodyOverflow) {
            setBodyOverflow(false);
        }

        return () => {
            if (bodyRef.current) {
                bodyRef.current.removeEventListener('scroll', handleScroll);
            }
        };
    }, [body, bodyOverflow]);

    return (
        <MuiModal
            open={open}
            onClose={onClose}
            {...muiModalProps}
        >
            <div
                className={clsx(classes.root, className)}
                {...props}
            >
                {topContent}
                <div
                    className={clsx(
                        classes.main,
                        bodyScrollOnOverflow && classes.mainBodyOverflowScroll,
                    )}
                >
                    {loading && <Spinner className={classes.spinner}/>}
                    <div className={clsx(classes.header, headerShadow && classes.headerShadow)}>
                        <div className={classes.headerInfos}>
                            <div className={clsx(classes.modalTitle, titleClassName)}>{title || ''}</div>
                            {subtitle && <div className={clsx(classes.modalSubtitle)}>{subtitle}</div> || false}
                        </div>
                        {onCloseCallback && (
                            <DynamicIcon
                                onClick={onCloseCallback as any}
                                className={classes.icon}
                                color='#2C3637'
                                type='cross-24'
                                height={24}
                                width={24}
                            />
                        )}
                    </div>
                    <div
                        ref={bodyRef}
                        className={clsx(
                            'modal_body',
                            classes.body,
                            bodyScrollOnOverflow && classes.bodyOverflowScroll,
                            bodyClassName,
                        )}
                    >
                        {body}
                    </div>
                    {actions && (
                        <div
                            className={clsx(
                                classes.actions,
                                bodyOverflow && bodyScrollOnOverflow && classes.overflowShadow,
                            )}
                        >
                            {actions}
                        </div>
                    )}
                    {errors && <div className={classes.errors}>{errors}</div>}
                    {overlay && <div className={classes.overlay}/> || false}
                </div>
            </div>
        </MuiModal>
    );
}

export interface ModalProps {
    className?: string;
    bodyClassName?: string;
    titleClassName?: string;
    open: boolean;
    onCloseCallback?: MouseEventHandler<HTMLButtonElement> | undefined;
    loading?: boolean;
    title?: ReactNode;
    subtitle?: ReactNode;
    body?: ReactNode;
    topContent?: ReactNode;
    actions?: ReactNode;
    errors?: ReactNode;
    muiModalProps?: any;
    bodyScrollOnOverflow?: boolean;
    overlay?: boolean;
}

export default Modal;
