import {useCallback} from 'react';
import useLuniiMutationApi from './useLuniiMutationApi';

export function useAuthentifiedNewsletterSubscribeSubmit(callback: Function|undefined = undefined) {
    const [subscribeNewsletterAuthentified, {loading, error, ...infos}] = useLuniiMutationApi('SUBSCRIBE_NEWSLETTER_AUTHENTIFIED');
    const onSubmit = useCallback(async (status) => {
        try {
            const data = await subscribeNewsletterAuthentified({
                variables: {
                    data: {
                        status: status,
                    },
                },
            });
            const response = ((data || {}).data || {}).subscribeNewsletterAuthentified;
            callback && callback(response);
            return response;
        } catch (e) {
            console.error(e);
        }
    }, [subscribeNewsletterAuthentified, callback]);
    return [onSubmit, {loading: Boolean(loading), error: error || false, ...infos}] as [any, any];
}

export default useAuthentifiedNewsletterSubscribeSubmit;
