import React from 'react';
import Spacers from '../atoms/Spacers';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Row from '../Row';

const useStyles = makeStyles({
    root: {
        display: 'flex',
    },
});

export function Spacer({size = undefined}: SpacerProps) {
    const classes = useStyles();
    return (
        <Row className={classes.root} padding={'none'}>
            <Spacers size={size} />
        </Row>
    );
}

export interface SpacerProps {
    size?: number,
}

export default Spacer;
