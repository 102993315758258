import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Trans} from 'react-i18next';
import {cssText} from '@ohoareau/css-utils';
import {useHasMounted, useLuniiTranslation, useLuniiUser, buttonifyFromProps, Modal, Clickable, useLuniiNavigation} from '..';
import {Illustration, Link} from '../..';
import SvgGift from '../images/icons/Gift';

const useStyles = makeStyles(theme => ({
    root: {
        width: 558,
        [theme.breakpoints.down('md')]: {
            maxWidth: 448,
            width: '100%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100vw',
            maxWidth: '100vw',
        },
    },
    title: {
        width: '90%',
        ...cssText(theme, 'standard', 'title_2', undefined, undefined, '#2C3637'),
    },
    image: {
        maxWidth: 228,
        width: '100%',
        margin: '0 auto 16px auto',
    },
    body: {
        ...cssText(theme, 'standard', 'body'),
        color: '#716C5E',
        marginBottom: theme.spacing(1),
    },
    email: {
        ...cssText(theme, 'standard', 'body_thick'),
        color: '#423F37',
    },
    infos: {
        ...cssText(theme, 'standard', 'secondary_body'),
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: '#DBF1F9',
        borderRadius: theme.spacing(2),
        padding: theme.spacing(2),
        margin: theme.spacing(4, 0, 0, 0),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(3, 0),
        },
    },
    svg: {
        minWidth: 26,
        minHeight: 26,
    },
    infosText: {
        marginLeft: theme.spacing(1),
        color: '#497281',
    },
    link: {
        ...cssText(theme, 'standard', 'secondary_body_thick'),
        display: 'inline',
        color: '#2762E9',
    },
    highlight: {
        ...cssText(theme, 'standard', 'body_thick', undefined, undefined, '#5F6769'),
    }
}));

export function MergeAccountWarningModal({mode = undefined, isOpen, onClose, onConfirm, onLogout}: MergeAccountWarningModalProps) {
    const classes = useStyles();
    const {t, i18n} = useLuniiTranslation();
    const {user, logout} = useLuniiUser();
    const hasMounted = useHasMounted();
    const {goPageByModel} = useLuniiNavigation();
    const {Buttons: ModalActions} = buttonifyFromProps({
        buttonLabel: t('button_label_cancel'),
        buttonTarget: onClose,
        buttonType: 'plain',
        button2Label: t('merge_account_warning_modal_button_confirm'),
        button2Target: onConfirm,
        button2Type: 'primary',
    });
    const handleLogoutButton = () => {
        logout();
        onLogout();
    };
    const handleLinkButton = () => {
        goPageByModel && goPageByModel('carte_cadeau');
    };
    if (!hasMounted) return null;
    if (!mode) return null;
    let infos: {title?: any, text?: string, image?: string} = {
        title: undefined,
        text: undefined,
        image: undefined,
    };
    switch (mode) {
        case 'fah':
            infos = {
                title: t('merge_account_warning_modal_title'),
                text: 'merge_account_warning_modal_body_text_fah',
                image: 'cart_no_fah_on_account',
            }
            break;
        case 'flam':
            infos = {
                title: t('merge_account_warning_modal_flam_title'),
                text: 'merge_account_warning_modal_body_text_flam',
                image: 'cart_no_flam_on_account'
            }
            break;
        case 'fah_flam':
            infos = {
                title: t('merge_account_warning_modal_fah_flam_title'),
                text: 'merge_account_warning_modal_body_text_fah_flam',
                image: 'cart_no_fah_and_flam_on_account'
            }
            break;
        default:
            break;
    }
    const title = infos.title && <div className={classes.title}>{infos.title}</div> || false;
    const body = (
        <div>
            {infos.image && <Illustration loading='eager' className={classes.image} name={infos.image}/> || false}
            {infos.text && <div className={classes.body}>
                <Trans
                    i18n={i18n} i18nKey={infos.text}
                    components={{1: <span className={classes.highlight}/>}}/>
            </div> || false}
            <div className={classes.body}>{t('merge_account_warning_modal_body_text')}</div>
            <div className={classes.body}>
                {t('merge_account_warning_modal_body_subtext')}
                <br/>
                <span className={classes.email}>{user?.email}</span>
            </div>
            <Link text={t('merge_account_warning_modal_logout_button')} url={handleLogoutButton} size='medium'/>
            <div className={classes.infos}>
                <SvgGift className={classes.svg} color='#497281'/>
                <div className={classes.infosText}>
                    <Trans i18nKey='merge_account_warning_modal_infos_text' i18n={i18n}>
                        ...<Clickable className={classes.link} onClick={handleLinkButton}/>
                    </Trans>
                </div>
            </div>

        </div>
    );
    if (!mode) onConfirm();
    return (
        <div>
            <Modal
                className={classes.root}
                open={isOpen}
                onCloseCallback={onClose}
                title={title}
                body={body}
                actions={ModalActions && <ModalActions/>}/>
        </div>
    );
}

export interface MergeAccountWarningModalProps {
    mode?: 'fah' | 'flam' | 'fah_flam',
    isOpen: boolean,
    onClose: () => void,
    onConfirm: () => void,
    onLogout: () => void,
}

export default MergeAccountWarningModal;
