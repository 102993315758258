export const routes = [
    {path: '/login', screen: 'login'},
    {path: '/library', redirect: 'audiobooks'},
    {path: '/library/studio', redirect: 'audiobooks'},
    {path: '/wishlist', screen: 'wishlist', secured: true},
    {path: '/', screen: 'home', secured: true},
    {path: '/audiobooks', screen: 'audiobooks', secured: true},
    {path: '/register', screen: 'register'},
    {path: '/forgot-password', screen: 'forgotPassword'}
];

export default routes;
