export function convertPrice(price: number|undefined, currency: string|undefined, hideDecimals: boolean = false, noNegative = false) {
    if ( typeof price !== 'number' || !currency) return ''; // do not return empty string on prices equal to 0.00
    const convertedPrice = noNegative && price <= 0 ? 0 : price;
    const formattedPrice = hideDecimals ? `${convertedPrice / 100}` : (convertedPrice / 100).toFixed(2);
    switch (currency.toLowerCase()) {
        case 'eur':
        case 'euro':
            return `${formattedPrice.replace('.', ',')}\xa0€`;
        case 'usd':
            return `$${formattedPrice}`;
        case 'cad':
            return `${formattedPrice.replace('.', ',')}\xa0CAD`;
        default:
            return 'undefined';
    }
}

export default convertPrice;
