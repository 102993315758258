import {useCallback} from 'react';
import useLuniiShoppingCartAnonymousComputeApi from './useLuniiShoppingCartAnonymousComputeApi';

export function useShoppingCartAnonymousComputeSubmit(callback: Function|undefined = undefined) {
    const [computeAnonymousCart, {loading, error, ...infos}] = useLuniiShoppingCartAnonymousComputeApi();
    const onSubmit = useCallback(async values => {
        try {
            const data = await computeAnonymousCart({
                variables: {
                    data: {
                        ...values,
                    }
                }
            });
            callback && callback(((data || {}).data || {}).computeAnonymousCart);
        } catch (e) {
            console.error(e);
        }
    }, [computeAnonymousCart, callback]);
    return [onSubmit, {loading: Boolean(loading), error: error || false, ...infos}] as [any, any];
}

export default useShoppingCartAnonymousComputeSubmit