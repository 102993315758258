import React from 'react';
import {Push} from '../../ui';
import useLiveElement from '../../hooks/useLiveElement';

export function LiveElement({
    locale,
    uid,
    contentPadding,
    imagePosition = 'left',
}: {
    locale: string;
    uid: string;
    contentPadding?: 'none' | 'large' | undefined;
    imagePosition?: 'left' | 'right';
}) {
    const element = useLiveElement(locale, uid);

    switch (element?.type) {
        case 'pushlarge':
            return (
                <Push
                    contentPadding={contentPadding}
                    imagePosition={imagePosition}
                    {...element}
                />
            );
        default:
            return null;
    }
}

export default LiveElement;
