import React from 'react';
import {cssText} from '@ohoareau/css-utils';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {DynamicIcon, Img, buttonifyFromProps} from '..';

const useStyles = makeStyles(theme => ({
    root: {
        flex: 1,
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0, 0.5, 0, 0.5),
        },
    },
    image: {
        height: 24,
        width: 24,
        marginBottom: 8,
    },
    defaultTitle: {
        ...cssText(theme, 'standard', 'title_2'),
        marginBottom: theme.spacing(0.5),
        [theme.breakpoints.down('sm')]: {
            fontSize: 18,
        },
    },
    smallTitle: {
        ...cssText(theme, 'standard', 'body_thick'),
        [theme.breakpoints.down('sm')]: {
            ...cssText(theme, 'standard', 'secondary_body_thick'),
        },
    },
    defaultText: {
        [theme.breakpoints.down('sm')]: {
            ...cssText(theme, 'standard', 'secondary_body'),
        },
    },
    smallText: {
        ...cssText(theme, 'standard', 'body'),
        [theme.breakpoints.down('sm')]: {
            ...cssText(theme, 'standard', 'secondary_body'),
        },
    },
    button: {
        marginTop: 16,
    }
}));

export function BulletPoint({title = undefined, text = undefined, image = undefined, icon = undefined, size = 'default', className = '', ...props}: BulletPointProps) {
    const classes = useStyles();
    const {Buttons} = buttonifyFromProps(props, ['tertiary,endIcon=next', 'tertiary', 'tertiary']);
    return (
        <div className={clsx(classes.root, className)}>
            <div className={classes.image}>
                {image && <Img {...image} objectFit='contain' />}
                {!image && icon && <DynamicIcon type={`${icon}-24`} width={24} height={24}/>}
            </div>
            {title && <div className={clsx(classes[`${size}Title`])}>{title}</div> || false}
            {text && <div className={clsx(classes[`${size}Text`])}>{text}</div> || false}
            {Buttons && <Buttons className={classes.button} />}
        </div>
    );
}

export interface BulletPointProps {
    title?: any,
    text?: any,
    image?: any,
    className?: string,
    icon?: any,
    size?: 'default' | 'small',
}

export default BulletPoint
