import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import SvgList from '../images/icons/List';
import SvgLogo from '../images/Logo';
import {ShoppingCartNavBarItem} from '../atoms/ShoppingCartNavBarItem';
import {ExtendedNavMenu} from './ExtendedNavMenu';
import {NavBarRootModeEnum} from '../types';
import {SubscriptionNavBarItem} from './SubscriptionNavBarItem';
import {Img} from '../nucleons';
import {useLuniiPage} from '../hooks';

const useStyles = makeStyles({
    content: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    left: {
        flex: 1,
    },
    center: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
    },
    logo: {
        cursor: 'pointer',
    },
    menuImage: {
        height: 40,
        width: 'auto',
    },
    right: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    icons: {
        display: 'flex',
        alignItems: 'center',
    },
    buttons: {
        marginLeft: 16,
    },
    shadow: {
        height: 2,
        background: '#E4E1DA',
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 16,
    },
    iconSubscription: {
        marginRight: 8,
        '@media all': {
            '&:hover': {
                backgroundColor: 'unset',
            },
        },
    },
    iconFirst: {
        marginLeft: 'unset',
    },
    searchIcon: {
        cursor: 'pointer',
    },
    listIcon: {
        cursor: 'pointer',
    },
});

export function MobileNavBar({rootMode = NavBarRootModeEnum.Default, onUserClick, mode, menu, menuImage, section, onLogoClick, onExtendedClick, onModeClose, onLibraryClick, onWishlistClick, page}: MobileNavBarProps) {
    const classes = useStyles();
    const {locale} = useLuniiPage() || {};
    const filteredMenu = menu.filter((m: any) => {
        const excludedLocales = (m.excludedLocales || '').split(',');
        return excludedLocales.indexOf((locale || '').slice(-2)) === -1;
    });
    return (
        <>
            {('extended' !== mode) && (
                <div className={classes.content}>
                    <div className={classes.left}>
                        <div className={classes.icons}>
                            <div className={clsx(classes.icon, classes.iconFirst)}>
                                <SvgList className={classes.listIcon} onClick={onExtendedClick}/>
                            </div>
                        </div>
                    </div>
                    <div className={classes.center}>
                        {menuImage?.url
                            ? <Img className={clsx(classes.logo, classes.menuImage)} {...menuImage} onClick={onLogoClick} />
                            : <SvgLogo className={classes.logo} onClick={onLogoClick}/>}
                    </div>
                    <div className={classes.right}>
                        <div className={classes.icons}>
                            <div className={clsx(classes.icon, classes.iconSubscription)}>
                                <SubscriptionNavBarItem/>
                            </div>
                            <div className={clsx(classes.icon, classes.iconFirst)}>
                                <ShoppingCartNavBarItem active={rootMode === NavBarRootModeEnum.Checkout}/>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {('extended' === mode) && (
                <ExtendedNavMenu
                    onModeClose={onModeClose}
                    menu={filteredMenu}
                    section={section}
                    mode={mode}
                    rootMode={rootMode}
                    onUserClick={onUserClick}
                    onLibraryClick={onLibraryClick}
                    onWishlistClick={onWishlistClick}
                    currentPageUid={page?.uid}/>
            )}
        </>
    );
}

export interface MobileNavBarProps {
    onUserClick?: any,
    mode?: any,
    menu?: any,
    menuImage?: any,
    section?: any,
    onLogoClick?: any,
    onExtendedClick?: any,
    onLibraryClick?: any,
    onWishlistClick?: any,
    onModeClose?: any,
    page?: any,
    rootMode?: NavBarRootModeEnum
}

export default MobileNavBar;
