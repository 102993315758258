import {useCallback} from 'react';
import useLuniiPurchasePaymentCreateApi from './useLuniiPurchasePaymentCreateApi';

export function usePurchasePaymentCreateSubmit(callback: Function|undefined = undefined) {
    const [createPurchasePayment, {loading, error, ...infos}] = useLuniiPurchasePaymentCreateApi();
    const onSubmit = useCallback(async ({purchase, paymentMethod, locale, paymentType}) => {
        try {
            const data = await createPurchasePayment({
                variables: {
                    data: {
                        purchase,
                        paymentMethod,
                        locale,
                        paymentType,
                    },
                },
            });
            const payment = ((data || {}).data || {}).createPurchasePayment;
            callback && callback(payment);
            return payment;
        } catch (e) {
            console.error(e);
        }
    }, [createPurchasePayment, callback]);
    return [onSubmit, {loading: Boolean(loading), error: error || false, ...infos}] as [any, any];
}

export default usePurchasePaymentCreateSubmit;
