import React from 'react';
import {product} from "./types";
import {LuniiProductProvider} from './contexts';

export function ForceProduct({product, children}: ForceProductProps) {
    return (
        <LuniiProductProvider value={product}>
            {children || ''}
        </LuniiProductProvider>
    );
}

export interface ForceProductProps {
    product?: product,
    children?: any,
}

export default ForceProduct
