import React from 'react';
import {ECommerceHardware, useLuniiProduct} from '../../../ui';

export function HardwareECommerceModule(props: any) {
    const product = useLuniiProduct();
    if (!product || product.type !== 'hardware') return null;
    return (
        <ECommerceHardware
            product={product}
            {...props}
        />
    );
}

export default HardwareECommerceModule;
