import React from 'react';
import {Route, RouteProps} from './Route';

export function PublicRoute (props: PublicRouteProps) {
    return <Route {...props} />;
}

export interface PublicRouteProps extends RouteProps {
}

export default PublicRoute;
