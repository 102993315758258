import {useCallback} from 'react';
import useLuniiShoppingCartInvalidateApi from './useLuniiShoppingCartInvalidateApi';

export function useShoppingCartInvalidateSubmit(callback: Function|undefined = undefined) {
    const [invalidate, {loading, error, ...infos}] = useLuniiShoppingCartInvalidateApi();
    const onSubmit = useCallback(async () => {
        try {
            const data = await invalidate();
            callback && callback(((data || {}).data || {}).invalidateCart);
        } catch (e) {
            console.error(e);
        }
    }, [invalidate, callback]);
    return [onSubmit, {loading: Boolean(loading), error: error || false, ...infos}] as [any, any];
}

export default useShoppingCartInvalidateSubmit