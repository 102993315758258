import React from 'react';
import {cssText} from '@ohoareau/css-utils';
import MosaicCardItems from '../molecules/MosaicCardItems';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Row from "../Row";
import Grid from "@material-ui/core/Grid";
import {module_text, module_title, WithItems} from "../types";

const useStyles = makeStyles(theme => ({
    root: {
        ...cssText(theme, 'standard', 'body'),
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        ...cssText(theme, 'standard', 'large_title'),
        marginBottom: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            ...cssText(theme, 'standard', 'title_1'),
        }
    },
    text: {
        marginBottom: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(3),
        }
    },
}));

export function PicturesMosaic({title, text, items = []}: PicturesMosaicProps) {
    const classes = useStyles();
    return (
        <Row className={classes.root}>
            <Grid item container className={classes.container} lg={12} md={12} xs={12}>
                <div className={classes.title}>{title}</div>
                <div className={classes.text}>{text}</div>
                <MosaicCardItems items={items} />
            </Grid>
        </Row>
    );
}

export interface PicturesMosaicProps extends WithItems {
    title?: module_title,
    text?: module_text,
}

export default PicturesMosaic
