import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {cssText} from '@ohoareau/css-utils';
import clsx from 'clsx';
import {useLuniiTranslation, Img} from '..';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    selection: {
        '&:hover': {
            '& $title': {
                color: '#503C0D',
            },
            color: '#896717',
        },
    },
    image: {
        height: 65,
        width: 65,
        marginRight: theme.spacing(1),
    },
    infos: {
        width: '80%',
    },
    title: {
        ...cssText(theme, 'standard', 'body_thick'),
        color: '#423F37',
        marginBottom: theme.spacing(.5),
        [theme.breakpoints.down('sm')]: {
            ...cssText(theme, 'standard', 'secondary_body_thick'),
        },
    },
    currentTitle: {
        height: 22,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    currentSubtitle: {
        height: 42,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    '@supports (-webkit-line-clamp: 1)': {
        currentTitle: {
            textOverflow: 'unset',
            whiteSpace: 'unset',
            display: '-webkit-box',
            height: 'unset',
            '-webkit-line-clamp': 1,
            '-webkit-box-orient': 'vertical',
        },
        currentSubtitle: {
            textOverflow: 'unset',
            whiteSpace: 'unset',
            display: '-webkit-box',
            height: 'unset',
            '-webkit-line-clamp': 2,
            '-webkit-box-orient': 'vertical',
        },
    },
}));

export function HardwareSelectorItem({translationKey = '', fallbackTitle = '', image = undefined, current = false}: HardwareSelectorItemProps) {
    const classes = useStyles();
    const {t, exists} = useLuniiTranslation();
    const titleKey = `ecommerce_hardware_${translationKey}_name`;
    const title = exists(titleKey) && t(titleKey) || fallbackTitle;
    const subtitleKey = `ecommerce_hardware_${translationKey}_description`;
    const subtitle = exists(subtitleKey) && t(subtitleKey);
    return (
        <div className={clsx(classes.root, !current && classes.selection)}>
            <div className={classes.image}>
                {image && <Img
                    loading='eager'
                    url={image}
                    alt={title}/>}
            </div>
            <div className={classes.infos}>
                <div className={clsx(classes.title, current && classes.currentTitle)}>{title}</div>
                {subtitle && <div className={clsx(current && classes.currentSubtitle)}>{subtitle}</div>}
            </div>
        </div>
    );
}

export interface HardwareSelectorItemProps {
    translationKey?: string,
    fallbackTitle?: string,
    image?: string,
    current?: boolean,
}

export default HardwareSelectorItem;
