import useLuniiQueryApi from '../useLuniiQueryApi';
import {useEffect} from 'react';
import {balance_status} from '../../types';

export function useGiftCardBalanceFind(callback: (_: balance_status) => void) {
    const {loading, error, data, ...infos} = useLuniiQueryApi('FIND_GIFT_CARD_BALANCE', {notifyOnNetworkStatusChange: true});
    useEffect(() => {
        !loading && callback && callback(data?.findGiftCardBalance || {});
    }, [loading]);
    return {giftCardBalance: data?.findGiftCardBalance || {}, loading: Boolean(loading), error: error || false, ...infos} as any;
}

export default useGiftCardBalanceFind;
