import React, {ReactNode} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import clsx from "clsx";

const useStyles = makeStyles({
    root: {
        textDecoration: 'unset',
        width: 'max-content',
        '&:hover': {
            textDecoration: 'unset',
            cursor: 'pointer',
        }
    }
});

export function ActionButton({children, className, onClick, linkComponent, ...props}) {
    const classes = useStyles();
    return (
        <div className={clsx(classes.root, className)} onClick={onClick} {...props}>
            {children}
        </div>
    );
}

export interface ActionButtonProps {
    children?: ReactNode,
    className?: string,
    onClick?: Function,
}

export default ActionButton
