import React, {useCallback} from 'react';
import Link from '@material-ui/core/Link';
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
    // @todo utiliser cssText pour utiliser les couleurs de la palette
    past: {
        color: '#423F37'
    },
    current: {
        color: '#2762E9'
    },
    future: {
        color: '#908977'
    }
});

export function BreadcrumbsItem({label, target, color = 'past', onClick = undefined}: BreadcrumbsItemProps) {
    const classes = useStyles();
    const handleClick = useCallback((e) => {
        e.preventDefault();
        onClick && onClick(e);
    }, [onClick]);

    return (
        <Link className={classes[color]} href={target} onClick={handleClick}>{label}</Link>
    );        
}

export interface BreadcrumbsItemProps {
    label: string,
    target?: string,
    onClick?: Function,
    color?: string
}

export default BreadcrumbsItem