import React from 'react';
import {Content1} from '../../../ui';

export function ContentRightPushModule(props: any) {
    return (
        <Content1
            {...props}
            imagePosition="right"
        />
    );
}

export default ContentRightPushModule;
