import * as React from 'react';
function SvgGift(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width={props.width || 24}
            height={props.height || 24}
            {...props}
        >
            <path
                fill="none"
                d="M0 0h24v24H0z"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.32 4.531l.009.016c.01.02.02.04.033.06l.008.014a.75.75 0 001.262 0l.007-.015a.768.768 0 00.033-.06l.009-.015.292-.626c.187-.4.587-.655 1.028-.655a.88.88 0 01.8.512l.04.107.053.169a1.25 1.25 0 01-.282 1.227l-.098.093-2.513 2.155-2.514-2.155-.098-.093a1.25 1.25 0 01-.282-1.227l.053-.17.04-.106a.88.88 0 01.8-.512c.44 0 .841.255 1.028.655l.292.626zM10 1.75c.78 0 1.508.345 2 .92a2.635 2.635 0 012.001-.92 2.38 2.38 0 012.273 1.673l.052.17.051.185a2.75 2.75 0 01-.887 2.72L13.737 8H17a3 3 0 012 5.236V19a3 3 0 01-2.824 2.995L16 22H8a3 3 0 01-2.995-2.824L5 19v-5.764A3 3 0 017 8h3.264L8.511 6.497a2.75 2.75 0 01-.887-2.719l.05-.186.053-.169A2.38 2.38 0 0110 1.75zM7 14v5a1 1 0 00.883.993L8 20h3v-3a1 1 0 011.993-.117L13 17v3h3a1 1 0 00.993-.883L17 19v-5H7zm6-4h4a1 1 0 110 2h-4v-2zm-2 0H7a1 1 0 100 2h4v-2z"
                fill={props.color || 'currentColor'}
            />
        </svg>
    );
}
export default SvgGift;
