import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {cssBox, cssText} from '@ohoareau/css-utils';
import Grid from '@material-ui/core/Grid';
import {SwiperSlide} from 'swiper/react/swiper-react';
import {module_title, Row} from '../../../ui';
import {CarouselSwiper, ProductCard} from '../..';

const useStyles = makeStyles((theme) => ({
    root: {
        ...cssText(theme, 'standard', 'body'),
        ...cssBox(theme, undefined, (props: any) => (theme as any)[`background_color_${props.color || 'clear'}`]),
    },
    title: {
        ...cssText(theme, 'standard', 'large_title'),
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            ...cssText(theme, 'standard', 'title_1'),
            marginBottom: theme.spacing(4),
        },
    },
    slide: {
        width: 267,
        [theme.breakpoints.down('md')]: {
            width: 216,
        },
        [theme.breakpoints.down('sm')]: {
            width: 160,
        },
    },
}));

export function ProductListSection({title = undefined, items = []}: ProductListSectionProps) {
    const classes = useStyles();
    if (items.length === 0) return null;
    const content = items.map((item, index) => (
        <SwiperSlide key={`itm-${Math.random()}`} className={classes.slide}>
            <ProductCard {...item} imageLoading={index > 4 ? 'lazy' : 'eager'}/>
        </SwiperSlide>
    ))
    return (
        <div className={classes.root}>
            <Row padding='none'>
                <Grid item container lg={12} md={12} xs={12}>
                    <div className={classes.title}>{title}</div>
                </Grid>
            </Row>
            <CarouselSwiper content={content}/>
        </div>
    );
}

export interface ProductListSectionProps {
    items?: any[];
    title?: module_title;
}

export default ProductListSection;
