/**
 * transforms string to slug
 * removes accentuated characters and prevent duplicates dashes
 * replace underscores and whitespaces by dashes
 *
 * ex: ('toto à du persil_entre les --- dents') => 'toto-du-persil-entre-les-dents'
 *
 * @param str[string]
 * @return string
 */
export function slugify(str: string): string {
    return str
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-')
        .replace(/^-+|-+$/g, '');
}
