import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {cssText} from '@ohoareau/css-utils';
import useLuniiTranslation from '../hooks/useLuniiTranslation';

const useStyle = makeStyles(theme => ({
    root: {
        ...cssText(theme, 'standard', 'button_2'),
        display: 'flex',
        height: theme.spacing(4),
        width: 'fit-content',
        borderRadius: '8px',
        backgroundColor: '#D6F3F2',
        color: '#097F82',
        boxShadow: 'none',
        textAlign: 'center',
        fontSize: 12,
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        cursor: 'default',
    },
    sticker: {
        display: 'block',
    },
}));

export function ItemOwnedSticker() {
    const {t} = useLuniiTranslation();
    const classes = useStyle();
    return (
        <div className={classes.root}>
            <span className={classes.sticker}>{t('cta_item_owned')}</span>
        </div>
    );
};

export default ItemOwnedSticker;
