import React from 'react';
import TextField, {TextFieldProps} from './TextField';
import MenuItem from '@material-ui/core/MenuItem';
import {useLuniiTranslation} from '../../hooks';
import {useController} from 'react-hook-form';

export function SelectField ({control, options, required = false, ...props}: SelectFieldProps) {
    const {t} = useLuniiTranslation();
    if (!options) return null;
    const {
        field: {ref, ...inputProps}, // inputProps contains the name prop for the textField
    } = useController({
        name: props.name,
        control: control,
        rules: {
            required: typeof required === 'boolean' ?
                t('field_required_error') :
                required,
        },
        defaultValue: '',
    });
    return (
        <TextField select {...props} {...inputProps} inputRef={ref} >
            <MenuItem key={'default'} value={undefined} disabled>{t(`fields_${props.name}_placeholder`)}</MenuItem>
            {options.map(opt => (
                <MenuItem key={opt.value} value={opt.value}>{opt.label}</MenuItem>
            ))}
        </TextField>
    );
}

export interface SelectFieldProps extends TextFieldProps {
    control: any,
    required?: boolean | string,
    options: {
        value: string,
        label: string,
    }[],
}


export default SelectField;
