import React from 'react';
import {cssBox, cssShadow} from '@ohoareau/css-utils';
import {Autoplay} from 'swiper';
import {Swiper, SwiperProps, SwiperSlide} from 'swiper/react/swiper-react.js';
import 'swiper/swiper.min.css';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {Img} from '../nucleons';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '10px 0',
        ...cssBox(theme, 'standard', 'clear'),
    },
    item: {
        ...cssShadow(theme, 'level_01'),
        borderRadius: 8,
        overflow: 'hidden',
    },
}));

export function ItemSlideshow1({items = [], desktopItems = 'auto', tabletItems = 'auto', mobileItems = 'auto'}: ItemSlideshow1Props) {
    const classes = useStyles();
    const params: SwiperProps = {
        modules: [Autoplay],
        centeredSlides: true,
        spaceBetween: 8,
        slidesPerView: mobileItems,
        breakpoints: {
            960: {
                spaceBetween: 16,
                slidesPerView: tabletItems,
            },
            1280: {
                spaceBetween: 24,
                slidesPerView: desktopItems,
            },
        },
        autoplay: {
            delay: 4000,
            disableOnInteraction: false,
        },
        autoHeight: true,
        loop: true,
        allowTouchMove: false,
    };
    const filteredItems: any[] = items.filter((item) => item.image); // TODO change
    // Swiper must have at least one SwiperSlider child in order to work
    if (filteredItems.length === 0) return null;
    return (
        <Swiper
            {...params}
            className={classes.root}>
            {filteredItems.map(({image}, index) => {
                if (!image) return null;
                return (<SwiperSlide
                    key={index}
                    className={classes.item}>
                    <Img {...image} />
                </SwiperSlide>);
            }).filter(x => null !== x)}
        </Swiper>
    );
}

export interface ItemSlideshow1Props {
    desktopItems?: number | 'auto',
    tabletItems?: number | 'auto',
    mobileItems?: number | 'auto',
    items?: any[],
}

export default ItemSlideshow1;
