import {useCallback, useEffect} from 'react';
import {SEND_SUBSCRIPTION_INFOS, useLuniiApi} from '../..';

export function useSubscriptionRetrievePaymentIntent(callback: Function|undefined = undefined) {
    const {gql, useMutation} = useLuniiApi();
    const [retrieveSubscriptionPaymentIntent, {loading, error, data, ...infos}] = useMutation(SEND_SUBSCRIPTION_INFOS(gql));
    const onSubmit = useCallback(async ({email, userFullname, stripeProductReference, address, countryCode}) => {
        const submitData = await retrieveSubscriptionPaymentIntent({
            variables: {
                data: {
                    email,
                    userFullname,
                    stripeProductReference,
                    address,
                    countryCode,
                },
            },
        });
        return ((submitData || {}).data || {}).retrieveSubscriptionPaymentIntent;
    }, [retrieveSubscriptionPaymentIntent]);
    useEffect(() => {
        if (data?.retrieveSubscriptionPaymentIntent && callback) {
            callback(data?.retrieveSubscriptionPaymentIntent);
        }
    }, [callback, data?.retrieveSubscriptionPaymentIntent]);
    return [onSubmit, {loading: Boolean(loading), error: error || false, ...infos}] as [any, any];
}

export default useSubscriptionRetrievePaymentIntent;
