import React, {useCallback} from 'react';
import {cssText} from '@ohoareau/css-utils';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import CheckoutCartTotal from './CheckoutCartTotal';
import {Button, useLuniiTranslation} from '../../ui';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        ...cssText(theme, 'standard', 'title_1'),
        color: '#2B434B',
    },
    divider: {
        margin: theme.spacing(2, 0),
        color: '#C1E7F5',
    },
    conditions: {
        color: '#5D90A3',
    },
    checkbox: {
        color: '#5D90A3',
    },
    action: {
        marginTop: 24,
    },
    actionButton: {
        width: '100%',
    },
    message: {
        ...cssText(theme, 'standard', 'secondary_body'),
    },
}));

export function CheckoutCartInfos({className, cart, onNextStep}: CheckoutCartInfosProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const handleNextStepClick = useCallback(
        (e) => {
            e.preventDefault();
            onNextStep && onNextStep(e);
        },
        [onNextStep],
    );
    return (
        <div className={clsx(classes.root, className)}>
            <div>
                <CheckoutCartTotal cart={cart} totalPrice={cart.price}/>
            </div>
            <div className={classes.message}>{t('checkout_bundle_next_page_warning')}</div>
            <div className={classes.action}>
                <Button
                    className={classes.actionButton}
                    onClick={handleNextStepClick}
                    color="primary"
                    size="large"
                >
                    {t('button_label_next')}
                </Button>
            </div>
        </div>
    );
}

export interface CheckoutCartInfosProps {
    className?: string;
    cart?: any;
    onNextStep?: Function;
}

export default CheckoutCartInfos;
