import React, {createContext, useState, useCallback} from 'react';
import {
    useLuniiUser,
    product,
    SubscriptionSpendModal,
    useHasMounted,
    digital_album_product,
} from '../index';
import createDefaultLuniiSubscriptionSpendModalContextValue from '../utils/createDefaultLuniiSubscriptionSpendModalContextValue';

const LuniiSubscriptionSpendModalContext = createContext(
    createDefaultLuniiSubscriptionSpendModalContextValue(),
);

export function LuniiSubscriptionSpendModalProvider(props) {
    const [isOpen, setIsOpen] = useState(false);
    const {user} = useLuniiUser();
    const hasMounted = useHasMounted();
    const [product, setProduct] = useState<product | undefined>(undefined);
    const [vars, setVars] = useState<undefined | object>(undefined);
    const [pageModel, setPageModel] = useState<string | undefined>(undefined);
    const open = useCallback(
        (product: any, vars: any, pageModel: string) => {
            setIsOpen(true);
            setProduct(product);
            if (vars) setVars(vars);
            if (pageModel) setPageModel(pageModel);
        },
        [setIsOpen, setProduct, setVars, setPageModel],
    );

    const close = () => {
        setIsOpen(false);
    };

    return (
        <LuniiSubscriptionSpendModalContext.Provider value={{isOpen, open, close}} {...props}>
            {props.children}
            {hasMounted && user?.subscription && product && (
                <SubscriptionSpendModal
                    isOpen={isOpen}
                    close={close}
                    product={product as digital_album_product}
                    key={product?.reference}
                    pageModel={pageModel}
                    {...vars}
                />
            )}
        </LuniiSubscriptionSpendModalContext.Provider>
    );
}

export const LuniiSubscriptionSpendModalConsumer = LuniiSubscriptionSpendModalContext.Consumer;
LuniiSubscriptionSpendModalContext.displayName = 'LuniiSubscriptionSpendModalContext';
export default LuniiSubscriptionSpendModalContext;
