import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {cssText} from '@ohoareau/css-utils';
import {
    ActivateCouponModal,
    ActivateCouponUnableModal,
    Button,
    Clickable,
    ReactivateModal,
    RetractModal,
    SubscriptionStatusEnum,
    useHasMounted,
    useLuniiEventTracking,
    useLuniiTranslation,
    useLuniiUser,
    useSubscriptionStripeCustomerPortal,
    CancelModal,
    CancelFromPastDueModal,
} from '../index';
import {subscription as subscription_type} from '../types';
import {Grid} from '@material-ui/core';
import SvgEdit from '../images/icons/Edit';
import SvgExternalLink from '../images/icons/ExternalLink';
import SvgClose from '../images/icons/Close';
import SvgRefresh from '../images/icons/Refresh';
import SvgAdd from '../images/icons/Add';

enum MySubscriptionModalActionEnum {
    Retract = 'retract',
    Cancel = 'cancel',
    CancelFromPastDue = 'cancel_from_past_due',
    Reactivate = 'reactivate',
    ActivateCoupon = 'activate_coupon',
    ActivateCouponUnable = 'activate_coupon_unable',
}

const useStyles = makeStyles((theme) => ({
    title: {
        marginTop: theme.spacing(1),
        ...cssText(theme, 'standard', 'title_2'),
    },
    actions: {
        margin: theme.spacing(3, 0, 0),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        '& button': {
            padding: 0,
        },
        '& >*': {
            padding: 0,
            '&:not(:last-child)': {
                margin: theme.spacing(0, 0, 2),
            },
        },
    },
    actionButton: {
        paddingLeft: 0,
    },
    body: {
        ...cssText(theme, 'standard', 'body', undefined, undefined, '#5F6769'),
        [theme.breakpoints.down('sm')]: {
            ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#5F6769'),
        },
    },
}));

export function MySubscriptionModals({subscription, locale, onChange = () => {}, onActivateCouponConfirm = () => {}}: MySubscriptionModalsProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const {user} = useLuniiUser();
    const [generateStripeCustomerPortalSession] = useSubscriptionStripeCustomerPortal();
    const hasMounted = useHasMounted();
    const [activeModal, setActiveModal] = useState<string|undefined>(undefined);
    const handleTrackingEvent = useLuniiEventTracking();
    const canRetractSubscription = !subscription.endTrialDate && (subscription.creationDate
        && (Date.now() - subscription.creationDate < 1000 * 3600 * 24 * 14)
        && subscription.status === SubscriptionStatusEnum.Widthdrawal);
    const handleStripeCustomerPortalSession = async () => {
        const {url} = await generateStripeCustomerPortalSession(user?.subscription?.stripeCustomerId, locale);
        if (url) window.location.href = url;
    };
    const handleGoToSubscriptionInfo = async () => {
        handleTrackingEvent({
            categorie: 'interaction',
            action: 'abonnement',
            libelle: 'decouverte',
        });
    };
    const handleCloseModal = () => {
        setActiveModal(undefined);
    };
    const handleOnConfirm = () => {
        handleCloseModal();
        onChange();
    };
    const handleOnActivateCouponConfirm = () => {
        handleCloseModal();
        onActivateCouponConfirm();
    };
    const handleOnActivationCouponClick = () => {
        handleTrackingEvent({
            categorie: 'interaction',
            action: 'abonnement',
            libelle: 'activer_abonnement_cadeau',
        });
        const modal = [SubscriptionStatusEnum.Pastdue, SubscriptionStatusEnum.Unpaid]
                .indexOf(subscription.status) !== -1
            && MySubscriptionModalActionEnum.ActivateCouponUnable
            || MySubscriptionModalActionEnum.ActivateCoupon;
        setActiveModal(modal);
    };
    const handleOnCancelSubscriptionClick = () => {
        const modal = [SubscriptionStatusEnum.Pastdue, SubscriptionStatusEnum.Unpaid]
                .indexOf(subscription.status) !== -1
            && MySubscriptionModalActionEnum.CancelFromPastDue
            || MySubscriptionModalActionEnum.Cancel;
        setActiveModal(modal);
    }
    const UpdateCreditCardButton = (
        <Button
            onClick={handleStripeCustomerPortalSession}
            startIcon={<SvgEdit/>}
            color='tertiary'
            noPadding>
            {t('my_subscription_scp_link')}
        </Button>
    );
    const CheckSubscriptionOffersButton = (
        <Clickable onClickCb={handleGoToSubscriptionInfo}>
            <Button
                onClick='https://lunii/go_to_subscription_presentation'
                startIcon={<SvgExternalLink/>}
                color='tertiary'
                noPadding>
                {t('my_subscription_action_browse_offers')}
            </Button>
        </Clickable>
    );
    const CancelSubscriptionButton = (
        <Button
            onClick={handleOnCancelSubscriptionClick}
            startIcon={<SvgClose/>}
            color='tertiary_alert'
            noPadding>
            {t('my_subscription_action_cancel')}
        </Button>
    );
    const RetractSubscriptionButton = (
        <Button
            onClick={() => setActiveModal(MySubscriptionModalActionEnum.Retract)}
            startIcon={<SvgClose/>}
            color='tertiary_alert'
            noPadding>
            {t('my_subscription_action_retract')}
        </Button>
    );
    const ReactivateSubscriptionButton = (
        <Button
            onClick={() => setActiveModal(MySubscriptionModalActionEnum.Reactivate)}
            startIcon={<SvgRefresh/>}
            color='tertiary'
            noPadding>
            {t('my_subscription_action_reactivate')}
        </Button>
    );
    const ActivateCouponButton = (
        <Button
            onClick={handleOnActivationCouponClick}
            startIcon={<SvgAdd/>}
            color='tertiary'
            noPadding>
            {t('my_subscription_action_activate_coupon')}
        </Button>
    );
    if (!hasMounted) return null;
    return (
        <>
            <Grid
                item
                sm={12}
                container
                direction='column'
                alignItems='flex-start'
                classes={{root: classes.actions}}>
                {subscription.status === SubscriptionStatusEnum.Termination && (
                    <>
                        {Date.now() < subscription.renewalPlannedAt && ReactivateSubscriptionButton}
                        {CheckSubscriptionOffersButton}
                    </>
                ) || null}
                {[SubscriptionStatusEnum.Widthdrawal, SubscriptionStatusEnum.Active, SubscriptionStatusEnum.Trialing].indexOf(subscription.status) !== -1 && (
                    <>
                        {CheckSubscriptionOffersButton}
                        {UpdateCreditCardButton}
                        {ActivateCouponButton}
                        {canRetractSubscription ? RetractSubscriptionButton : CancelSubscriptionButton}
                    </>
                )}
                {subscription.status === SubscriptionStatusEnum.Free && (
                    <>
                        {CheckSubscriptionOffersButton}
                    </>
                ) || null}
                {(subscription.status === SubscriptionStatusEnum.Pastdue || subscription.status === SubscriptionStatusEnum.Unpaid) && (
                    <>
                        {UpdateCreditCardButton}
                        {ActivateCouponButton}
                        {CancelSubscriptionButton}
                    </>
                ) || null}
            </Grid>

            <CancelModal {...subscription} locale={locale} open={activeModal === MySubscriptionModalActionEnum.Cancel} onClose={handleCloseModal} onConfirm={handleOnConfirm}/>
            <CancelFromPastDueModal {...subscription} locale={locale} open={activeModal === MySubscriptionModalActionEnum.CancelFromPastDue} onClose={handleCloseModal} onConfirm={handleOnConfirm}/>
            <RetractModal {...subscription} locale={locale} open={activeModal === MySubscriptionModalActionEnum.Retract} onClose={handleCloseModal} onConfirm={handleOnConfirm}/>
            <ReactivateModal {...subscription} locale={locale} open={activeModal === MySubscriptionModalActionEnum.Reactivate} onClose={handleCloseModal} onConfirm={handleOnConfirm}/>
            <ActivateCouponModal open={activeModal === MySubscriptionModalActionEnum.ActivateCoupon} onClose={handleCloseModal} onConfirm={handleOnActivateCouponConfirm}/>
            <ActivateCouponUnableModal open={activeModal === MySubscriptionModalActionEnum.ActivateCouponUnable} onClose={handleCloseModal}/>

        </>
    );
}

export interface MySubscriptionModalsProps {
    subscription: subscription_type,
    locale: string,
    onChange?: Function,
    onActivateCouponConfirm?: Function
}

export default MySubscriptionModals;
