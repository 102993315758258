import {useCallback, useState} from 'react';
import {
    LazyQueryResult,
    QueryResult,
    MutationResult,
    MutationFunction,
    LazyQueryHookOptions,
} from '@apollo/client';

export type mutationInfos<TData = any> =
    | {
          called: boolean;
          loading: boolean;
          data: any;
          error: any;
          client: any;
          reset: any;
      }
    | MutationResult<TData>;

export type queryInfos<TData = any, TVariables = any> =
    | {
          called: boolean;
          loading: boolean;
          data: any;
          error: any;
          refetch: any;
          client: any;
      }
    | QueryResult<TData, TVariables>;

export type lazyQueryInfos<TData = any, TVariables = any> =
    | {
          called: boolean;
          loading: boolean;
          data: any;
          error: any;
          client?: any;
          refetch?: any
      }
    | LazyQueryResult<TData, TVariables>;

// noinspection JSUnusedLocalSymbols
const gql = (x) => undefined;

// noinspection JSUnusedLocalSymbols
const createUseQuery = (mocks = []) => (query: any, options: any = {}) => {
    const [infos, setInfos] = useState<queryInfos & {mockCounter: number}>({
        mockCounter: 0,
        called: true,
        loading: true,
        data: undefined,
        error: undefined,
        refetch: undefined,
        client: undefined,
    });
    if (!infos.loading) return infos;
    const q = (reinitLoading = false) => {
        if (reinitLoading) setInfos({...infos, loading: true});
        const currentCounter = infos['mockCounter'];
        let data: any = mocks[currentCounter] || {};
        'function' === typeof data && (data = (<Function>data)({query, options}));
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (data?.error) {
                    setInfos({
                        ...infos,
                        mockCounter: currentCounter + 1 >= mocks.length ? 0 : currentCounter + 1,
                        called: true,
                        loading: false,
                        data: undefined,
                        error: {
                            graphQLErrors: [data.error].map((d) => ({
                                message: d.message,
                                extensions: {exception: {luniiCode: d.luniiCode, code: d.code}},
                            })),
                        },
                        refetch: () => q(true),
                    });
                    reject(data);
                } else {
                    setInfos({
                        ...infos,
                        mockCounter: currentCounter + 1 >= mocks.length ? 0 : currentCounter + 1,
                        called: true,
                        loading: false,
                        data,
                        error: undefined,
                        refetch: () => q(true),
                    });
                    options.onCompleted && options.onCompleted(data);
                    resolve(data);
                }
            }, 1000);
        }).then((x) => x);
    };
    q();
    return infos;
};
// noinspection JSUnusedLocalSymbols
const createUseMutation = (mocks = []) => (
    query: any,
    options: any = {},
): [MutationFunction, mutationInfos] => {
    const initialState = {
        mockCounter: 0,
        called: false,
        loading: false,
        data: undefined,
        error: undefined,
        client: undefined,
        reset: () => {},
    };
    const reset = () => setInfos({...initialState});
    const [infos, setInfos] = useState<mutationInfos & {mockCounter: number}>({
        ...initialState,
        reset,
    });
    const mutate = useCallback(
        async (localOptions = {}) => {
            const currentCounter = infos['mockCounter'];
            let data: any = mocks[infos['mockCounter']] || {};
            'function' === typeof data &&
                (data = (<Function>data)({
                    query,
                    options: localOptions,
                    globalOptions: options,
                }));
            setInfos({
                ...infos,
                called: true,
                loading: true,
                data: undefined,
                error: undefined,
                reset,
            });
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    if (data?.error) {
                        setInfos({
                            ...infos,
                            mockCounter:
                                currentCounter + 1 >= mocks.length ? 0 : currentCounter + 1,
                            called: true,
                            loading: false,
                            data: undefined,
                            reset,
                            error: {
                                graphQLErrors: [data.error].map((d) => ({
                                    message: d.message,
                                    extensions: {exception: {luniiCode: d.luniiCode, code: d.code}},
                                })),
                            },
                        });
                        reject(data);
                    } else {
                        setInfos({
                            ...infos,
                            mockCounter:
                                currentCounter + 1 >= mocks.length ? 0 : currentCounter + 1,
                            called: true,
                            loading: false,
                            data,
                            reset,
                            error: undefined,
                        });
                        options.onCompleted && options.onCompleted(data);
                        resolve(data);
                    }
                }, 1000);
            }).then((x) => x);
        },
        [setInfos, infos, options],
    );
    return [mutate as MutationFunction, infos];
};
// noinspection JSUnusedLocalSymbols
const createUseLazyQuery = (mocks = []) => (
    query: any,
    options: any = {},
): [
    (options?: LazyQueryHookOptions<any>) => Promise<LazyQueryResult<any, any>>,
    lazyQueryInfos,
] => {
    const [infos, setInfos] = useState<lazyQueryInfos & {mockCounter: number}>({
        mockCounter: 0,
        called: false,
        loading: false,
        data: undefined,
        error: undefined,
        client: undefined,
    });
    const lazy = useCallback(
        async (localOptions = {}) => {
            const currentCounter = infos['mockCounter'];
            let data: any = mocks[infos['mockCounter']] || {};
            'function' === typeof data &&
                (data = (<Function>data)({
                    query,
                    options: localOptions,
                    globalOptions: options,
                }));
            setInfos({...infos, called: true, loading: true, data: undefined, error: undefined});
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    if (data.error) {
                        setInfos({
                            ...infos,
                            mockCounter:
                                currentCounter + 1 >= mocks.length ? 0 : currentCounter + 1,
                            called: true,
                            loading: false,
                            data: undefined,
                            error: {
                                graphQLErrors: [data.error].map((d) => ({
                                    message: d.message,
                                    extensions: {exception: {luniiCode: d.luniiCode, code: d.code}},
                                })),
                            },
                        });
                        reject(data);
                    } else {
                        setInfos({
                            ...infos,
                            mockCounter:
                                currentCounter + 1 >= mocks.length ? 0 : currentCounter + 1,
                            called: true,
                            loading: false,
                            data,
                            error: undefined,
                        });
                        options.onCompleted && options.onCompleted(data);
                        resolve(data);
                    }
                }, 1000);
            }).then((x) => x);
        },
        [setInfos, infos, options],
    );
    return [
        lazy as (options?: LazyQueryHookOptions<any>) => Promise<LazyQueryResult<any, any>>,
        infos,
    ];
};
export const createDefaultLuniiApiContextValue = ({
    useMutationMocks = [],
    useQueryMocks = [],
    useLazyQueryMocks = [],
} = {}) => ({
    gql,
    useQuery: createUseQuery(useQueryMocks),
    useLazyQuery: createUseLazyQuery(useLazyQueryMocks),
    useMutation: createUseMutation(useMutationMocks),
});

export default createDefaultLuniiApiContextValue;
