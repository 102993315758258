import React from 'react';
import Image, {ImageProps} from "./Image";

export function ImageMedium(props: ImageMediumProps) {
    return (
        <Image {...props} size={'medium'} />
    );
}

export interface ImageMediumProps extends Omit<ImageProps, 'size'> {
}

export default ImageMedium