import * as React from 'react';
function SvgPackage(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width={props.width || 24}
            height={props.height || 24}
            {...props}
        >
            <path
                fill="#fff"
                d="M0 0h24v24H0z"
            />
            <path
                d="M4.188 6.472l7-3.111a2 2 0 011.624 0l7 3.111A2 2 0 0121 8.3v7.4a2 2 0 01-1.188 1.828l-7 3.111a2 2 0 01-1.624 0l-7-3.111A2 2 0 013 15.7V8.3a2 2 0 011.188-1.828z"
                stroke={props.color || 'currentColor'}
                strokeWidth={2}
            />
            <path
                d="M3 7l8.164 3.628a2 2 0 001.664-.018L20.5 7M12 11v10.5"
                stroke={props.color || 'currentColor'}
                strokeWidth={2}
            />
            <path
                d="M16.5 9l-9-4"
                stroke={props.color || 'currentColor'}
                strokeWidth={1.5}
            />
        </svg>
    );
}
export default SvgPackage;
