import {errors, useLuniiTranslation} from '..';

export function useUserError(error: any, group = 'default', locale: string|undefined = undefined) {
    const {exists} = useLuniiTranslation();
    if (!error) return undefined;
    const infos = error?.extensions?.exception || {};
    const {luniiCode, status, microservice} = infos;
    if (luniiCode) {
        let transKey = (errors[group]?.lunii || {})[luniiCode];
        if (locale) transKey = `${transKey}_${locale.slice(-2)}`;
        if (transKey && exists(transKey)) return {type: 'error', message: transKey};
    } else if ((errors[group]?.http || {}).default) {
        let transKey = (errors[group]?.http || {}).default;
        if (locale) transKey = `${transKey}_${locale.slice(-2)}`;
        if (exists(transKey)) return {type: 'error', message: transKey};
    } else if (error.code) {
        return {type: 'error', message: error.code}
    }
    return {type: 'error', message: 'global_error_message', options: {error: `${microservice}/${luniiCode}`, status}}
}

export default useUserError;
