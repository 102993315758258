import React, {useCallback} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import clsx from "clsx";
import useLuniiEventTracking from "../hooks/useLuniiEventTracking";

const useStyles = makeStyles({
    root: {
        textDecoration: 'unset',
        width: 'max-content',
        '&:hover': {
            textDecoration: 'unset',
            cursor: 'pointer',
        }
    }
});

export function ExternalLink({children, className, linkComponent: LinkComponent, href, active, to, ...props}) {
    const classes = useStyles();
    const handleEventTracking = useLuniiEventTracking();
    const handleClick = useCallback(() => handleEventTracking({categorie: 'lien', action: 'sortant', libelle: to || href}), [handleEventTracking]);
    return (
        <LinkComponent className={clsx(classes.root, className)}
                       onClick={handleClick}
                       href={to || href}
                       target="_blank"
                       rel={`noopener noreferrer ${(to || href)?.match(/.+\.(pdf|jpg|png)$/i) ? 'nofollow' : ''}`}
                       {...props}>
            {children}
        </LinkComponent>
    );
}

export default ExternalLink
