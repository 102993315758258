import React, {useMemo} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import HiddenCss from '@material-ui/core/Hidden/HiddenCss';
import {cssText} from '@ohoareau/css-utils';
import {
    AddressForm,
    AddressTypeEnum,
    LargeTitleText,
    PaymentTypeChoice,
    ProductTableNameEnum,
    ShadowedPanel,
    useLuniiTranslation,
    useShoppingCart,
} from '../../ui';

const useStyles = makeStyles((theme) => ({
    title: {
        marginBottom: theme.spacing(3),
    },
    panel: {
        padding: theme.spacing(4),
        marginBottom: theme.spacing(4),
        backgroundColor: 'white',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
        },
    },
    loading: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 30,
        color: '#fbbd2a',
    },
    panelTitle: {
        ...cssText(theme, 'standard', 'title_1'),
        color: '#423F37',
        marginBottom: 24,
    },
    panelText: {
        ...cssText(theme, 'standard', 'body'),
        color: '#716C5E',
        marginTop: 0,
    },
    hide: {
        display: 'none',
    },
}));

export function PaymentRight({
    onCardComplete,
    onCardRegister,
    onAddressUpdate,
    onPaymentTypeChoice,
    onGiftCardPartialChange,
    onEditDeliveryAddress = () => {},
    onEditBillingAddress = () => {}
}: PaymentRightProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const [{cart}] = useShoppingCart();
    const hasHardware = useMemo(
        () =>
            ((cart || {}).items || []).some(
                (item: any) => item.type === ProductTableNameEnum.Hardware,
            ),
        [cart],
    );
    return (
        <>
            <HiddenCss mdUp>
                <LargeTitleText className={classes.title as any}>
                    {t('checkout_payment_title')}
                </LargeTitleText>
            </HiddenCss>
            {hasHardware && (
                <ShadowedPanel className={classes.panel}>
                    <AddressForm
                        key="delivery"
                        onSuccess={onAddressUpdate}
                        title={t('checkout_shippingAddress_form_title')}
                        type={AddressTypeEnum.Delivery}
                        locale={cart?.country}
                        onEdit={onEditDeliveryAddress}
                    />
                </ShadowedPanel>
            )}
            <ShadowedPanel className={classes.panel}>
                <AddressForm
                    key="billing"
                    onSuccess={onAddressUpdate}
                    title={t('checkout_billingAddress_form_title')}
                    type={AddressTypeEnum.Billing}
                    locale={cart?.country}
                    extraButton={hasHardware}
                    onEdit={onEditBillingAddress}
                />
            </ShadowedPanel>
            {cart?.price > 0 &&
                cart?.paymentTypes?.length > 0 &&
                (cart?.leftToPay?.totalWithVat !== 0 || cart?.accountBalance?.amount) && (
                    <ShadowedPanel className={classes.panel}>
                        <PaymentTypeChoice
                            cart={cart}
                            defaultOnFirstIfOnly
                            onGiftCardPartialChange={onGiftCardPartialChange}
                            onPaymentTypeChoice={onPaymentTypeChoice}
                            onCardComplete={onCardComplete}
                            onCardRegister={onCardRegister}
                        />
                    </ShadowedPanel>
                )}
        </>
    );
}

export interface PaymentRightProps {
    onCardComplete: (arg0: boolean) => void;
    onCardRegister?: (arg0: boolean) => void;
    onAddressUpdate: Function;
    onPaymentTypeChoice: Function;
    onGiftCardPartialChange: (arg0: boolean) => void;
    onEditDeliveryAddress?: Function;
    onEditBillingAddress?: Function;
}

export default PaymentRight;
