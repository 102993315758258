import React from 'react';
import clsx from 'clsx';
import {cssText} from "@ohoareau/css-utils";
import {Typography} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    root: {

    },
    head: {

    },
    title: {
        ...cssText(theme, 'standard', 'title_1')
    },
}));

export function CheckoutPaymentMethod({className, title}: CheckoutPaymentMethodProps) {
    const classes = useStyles();
    return (
        <div className={clsx(classes.root, className)}>
            {title && (
                <div className={classes.head}>
                    <Typography variant={'h3'} className={classes.title}>{title}</Typography>
                </div>
            )}
        </div>
    );
}

export interface CheckoutPaymentMethodProps {
    className?: string,
    title?: string,
}

export default CheckoutPaymentMethod
