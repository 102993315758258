import * as React from 'react';
function SvgAdd(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width={props.width || 24}
            height={props.height || 24}
            {...props}
        >
            <path
                fill="none"
                d="M0 0h24v24H0z"
            />
            <rect
                x={11.5}
                y={4.5}
                width={1}
                height={4}
                rx={0.5}
                fill="#D8D8D8"
                stroke={props.color || 'currentColor'}
            />
            <rect
                x={11.5}
                y={11.5}
                width={1}
                height={8}
                rx={0.5}
                fill="#D8D8D8"
                stroke={props.color || 'currentColor'}
            />
            <rect
                x={19.5}
                y={11.5}
                width={1}
                height={4}
                rx={0.5}
                transform="rotate(90 19.5 11.5)"
                fill="#D8D8D8"
                stroke={props.color || 'currentColor'}
            />
            <rect
                x={12.5}
                y={11.5}
                width={1}
                height={8}
                rx={0.5}
                transform="rotate(90 12.5 11.5)"
                fill="#D8D8D8"
                stroke={props.color || 'currentColor'}
            />
        </svg>
    );
}
export default SvgAdd;
