import React from 'react';
import Clickable from '../Clickable';
import linkify from '../hocs/linkify';
import MuiButton, {ButtonProps as MuiButtonProps} from '@material-ui/core/Button';
import {PropTypes} from '@material-ui/core';

function ButtonComp({classes, color, disabled, className, children, ...props}: ButtonProps) {
    return (
        <MuiButton
            disableRipple={true}
            classes={classes}
            color={'tertiary' === color || 'tertiary_alert' === color ? 'default' : color}
            disabled={disabled}
            className={className}
            disableElevation={true}
            style={{display: 'inline'}}
            {...props}>
            {children || ''}
        </MuiButton>
    );
}

const components = (size = 'large', color) => ({
    internal: linkify({size: size, componentProps: {type: 'internal'}, color}),
    external: linkify({size: size, componentProps: {type: 'external'}, color}),
    action: linkify({size: size, component: ButtonComp, componentProps: {type: 'button'}, color}),
    submit: linkify({size: size, component: ButtonComp, componentProps: {type: 'submit'}, color}),
});

export function Button({size = 'large', ...props}) {
    return (
        <Clickable {...props} components={components(size, props['color']) || components('large', props['color'])} />
    );
}

export interface ButtonProps extends Omit<MuiButtonProps, 'color'> {
    color: PropTypes.Color | 'tertiary' | 'tertiary_alert'
}

export default Button;
