import React, {useCallback} from 'react';
import clsx from 'clsx';
import {cssText} from '@ohoareau/css-utils';
import SvgBackward from '../images/icons/Backward';
import SvgArrowLeft from '../images/icons/ArrowLeft';
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    root: {
        textAlign: 'center',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        ...cssText(theme, 'standard', 'body'),
        height: 32,
    },
    item: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'inherit',
        width: 42,
        borderRadius: '16px',
        margin: theme.spacing(0, 2),
        ...cssText(theme, 'standard', 'button_1', 'center'),
        backgroundColor: '#FEECC3',
        '&::selection': {
            background: 'transparent',
        },
    },
    next: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'inherit',
        width: 56,
        backgroundColor: '#E6EEFF',
        transition: 'background-color .5s, color .5s',
        color: '#2762E9',
        cursor: 'pointer',
        borderRadius: 24,
    },
    nextDisabled: {
        cursor: 'default',
        backgroundColor: '#E3E1DB',
        color: '#B8B3A4',
    },
    rotate: {
        transform: 'rotate(180deg)',
    },
    back: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 26px',
        height: 'inherit',
        cursor: 'pointer',
        color: '#2762E9',
        transition: 'color .5s',
        '&:first-child': {
            marginLeft: 0
        },
        '&:last-child': {
            marginRight: 0,
        },
        [theme.breakpoints.down('sm')]: {
            margin: '0 13px',
        },
    },
    backDisabled: {
        cursor: 'default',
        color: '#B8B3A4',
    },
}));

export function Pagination({className, size, onPageChange, currentPage}: PaginationProps) {
    const classes = useStyles();
    const decrementPage = useCallback(() => {
        currentPage !== 1 && onPageChange(currentPage - 1);
    }, [onPageChange, currentPage]);
    const incrementPage = useCallback(() => {
        currentPage !== size && onPageChange(currentPage + 1);
    }, [onPageChange, currentPage, size]);
    const goToFirstPage = useCallback(() => {
        currentPage !== 1 && onPageChange(1);
    }, [onPageChange, currentPage, size]);
    const goToLastPage = useCallback(() => {
        currentPage !== size && onPageChange(pages.length);
    }, [onPageChange, currentPage, size]);
    if (!size || size === 1) return null;
    const pages = Array.from({length: size}, (_, i) => i + 1);
    return (
        <div className={clsx(classes.root, className)}>
            <div className={clsx(classes.back, currentPage === 1 && classes.backDisabled)} onClick={goToFirstPage}>
                <SvgBackward/>
            </div>
            <div className={clsx(classes.next, currentPage === 1 && classes.nextDisabled)} onClick={decrementPage}>
                <SvgArrowLeft width={22} height={22}/>
            </div>
            <div className={classes.item}>{currentPage}</div>
            <div className={clsx(classes.next, classes.rotate, currentPage === size && classes.nextDisabled)} onClick={incrementPage}>
                <SvgArrowLeft width={22} height={22}/>
            </div>
            <div className={clsx(classes.rotate, classes.back, currentPage === size && classes.backDisabled)} onClick={goToLastPage}>
                <SvgBackward/>
            </div>
        </div>
    );
}

export interface PaginationProps {
    className?: string,
    size?: number,
    onPageChange?: any,
    currentPage: number,
}

export default Pagination
