import * as React from 'react';
function SvgCreditCard(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width={props.width || 24}
            height={props.height || 24}
            {...props}
        >
            <path
                fill="none"
                d="M0 0h24v24H0z"
            />
            <rect
                x={5.5}
                y={12.5}
                width={8}
                height={1.5}
                rx={0.75}
                fill={props.color || 'currentColor'}
            />
            <rect
                x={5.5}
                y={15}
                width={4}
                height={1.5}
                rx={0.75}
                fill={props.color || 'currentColor'}
            />
            <path
                clipRule="evenodd"
                d="M5 6a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2H5z"
                stroke={props.color || 'currentColor'}
                strokeWidth={2}
            />
            <path
                d="M4 10h16"
                stroke={props.color || 'currentColor'}
                strokeWidth={2}
            />
        </svg>
    );
}
export default SvgCreditCard;
