import React from 'react';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {cssBorder, cssText} from '@ohoareau/css-utils';
import {Trans} from 'react-i18next';
import {
    useLuniiNavigation, useLuniiTranslation, DynamicIcon, Illustration, Button, convertTimestampToDDMMYYYY,
    getDaysBetweenDates, WarningMessage, ElevatedPanel, SubscriptionPlannedEventsEnum, SubscriptionStatusEnum} from '..';
import {subscription as subscription_type} from '../types';

const useStyles = makeStyles( theme => ({
    root: {
        width: 320,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(2),
        ...cssBorder(theme, 'standard'),
    },
    status: {
        textAlign: 'center',
    },
    nextPlannedEvent: {
        ...cssText(theme, 'standard', 'overline_title_2'),
        textTransform: 'uppercase',
        marginBottom: theme.spacing(.5),
    },
    balance: {
        ...cssText(theme, 'standard', 'title_2'),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& >*': {
            display: 'block',
        },
    },
    danger: {
        color: '#C9310C',
    },
    creditsDanger: {
        color: '#5A1605',
    },
    illustration: {
        width: 128,
        height: 90,
        marginTop: theme.spacing(2),
    },
    action: {
        display: 'flex',
        flex: 1,
        ...cssText(theme, 'standard', 'button_2'),
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(2),
        alignItems: 'center',
        alignSelf: 'stretch',
    },
    highlight: {
        fontWeight: 800,
    },
}));

export function SubscriptionWidget({className = '', subscription}: SubscriptionWidgetProps) {
    // todo mobile and tablet version with panel
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const {goUserHome} = useLuniiNavigation();
    const subscriptionTerminating = subscription.status === SubscriptionStatusEnum.Termination;
    const frozenSubscription = [SubscriptionStatusEnum.Pastdue, SubscriptionStatusEnum.Unpaid].indexOf(subscription.status) !== -1;
    const reference = subscription.productReference.split('_')[0];
    const nextPlannedEvent = subscriptionTerminating
        ? {...subscription.plannedEvents[subscription.plannedEvents.length - 1]}
        : reference === 'monthly' && subscription.status !== SubscriptionStatusEnum.Free // monthly subscription only
            ? {tag: SubscriptionPlannedEventsEnum.INCREMENT_BALANCE, date: subscription.renewalPlannedAt}
            : {...subscription.plannedEvents[0]};
    const formatDate = (date: any) => subscriptionTerminating
        ? convertTimestampToDDMMYYYY(date)
        : getDaysBetweenDates(new Date().getTime(), date);
    return (
        <ElevatedPanel className={clsx(classes.root, className)}>
            <div className={classes.status}>
                <div className={clsx(classes.nextPlannedEvent, (subscriptionTerminating || frozenSubscription) && classes.danger)}>
                    {frozenSubscription && t(`my_subscription_status_${subscription.status}`)
                        || t(`subscription_widget_next_planned_event_${nextPlannedEvent.tag}`, {date: formatDate(nextPlannedEvent.date)})}
                </div>
                <div className={clsx(classes.balance, (subscriptionTerminating || frozenSubscription) && classes.creditsDanger)}>
                    <DynamicIcon
                        type='token'
                        width={24}/>
                    <span>
                        {'\u00A0' + subscription.wallet.balance + ' ' + t('subscription_widget_wallet_credits', {count: subscription?.wallet?.balance})}
                    </span>
                </div>
            </div>
            <Illustration
                className={classes.illustration}
                mt={0}
                name='my_subscription_credits_remaining'/>
            {frozenSubscription && (
                <WarningMessage
                    body={<div>
                        <Trans
                            i18nKey={`subscription_widget_warning_${subscription.status}`}
                            components={{1: <span className={classes.highlight}/>}}
                        />
                    </div>}
                    svg='warning'
                    mode='alert'/>
            ) || null}
            <Button
                onClick={(e: any) => goUserHome && goUserHome(e, {category: 'subscription'})}
                color='plain'
                className={classes.action}>
                {t('subscription_widget_go_to_my_subscription')}
            </Button>

        </ElevatedPanel>
    );
}

export interface SubscriptionWidgetProps {
    className?: string,
    subscription: subscription_type,
}

export default SubscriptionWidget;
