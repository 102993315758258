import React, {useMemo, useCallback} from 'react';
import {Router, Redirect, useNavigate, useLocation} from '@reach/router';
import routes from '../configs/routes/my-account';
import * as screens from './screens/my-account';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import {LuniiPaymentProvider, LuniiSpaProvider} from '../ui';
import Page from './Page';
import {langs, stripePk, stripePkUS} from '../../configs/site';
import usePageContext from '../hooks/usePageContext';
import {page} from '../types';

export function MyAccount(props: MyAccountProps) {
    const prefix = '/my';
    const baseNavigate = useNavigate();
    const navigate: Function = useCallback(
        async (path, ...rest) => baseNavigate(`${prefix}${path}`, ...rest),
        [baseNavigate],
    );
    const spaProviderValue = useMemo(
        () => ({
            prefix,
            navigate,
            screens,
        }),
        [prefix, navigate],
    );
    const location = useLocation();

    const pageContext = {
        page: {
            uid: 'my-account',
            theme: 'default',
            lang: '@user',
            product: undefined,
            head: {
                link: [
                    {
                        rel: 'preload',
                        as: 'script',
                        href: 'https://cdnjs.cloudflare.com/ajax/libs/lazysizes/5.3.2/lazysizes.min.js',
                    },
                ],
                script: [
                    {
                        src: 'https://cdnjs.cloudflare.com/ajax/libs/lazysizes/5.3.2/lazysizes.min.js',
                        type: 'text/javascript',
                        async: true,
                    },
                ],
            },
            favicon: undefined,
            modules: [],
            alernateLangs: [],
            language: undefined,
            languages: langs.map((lang) => ({
                id: lang,
                label: `locale_${lang.replace('-', '_').toLowerCase()}_label`,
                target: `/${lang}/`,
            })),
            features: {
                pageTracking: false,
            },
            menu: props?.pageContext?.page?.menu || [],
            menuImage: props?.pageContext?.page?.menuImage || {},
            pagesByModel: props?.pageContext?.page?.pagesByModel || {},
            env: props?.pageContext?.page?.env,
        },
        location,
    };
    const {locale} = usePageContext(pageContext as unknown as page);
    // eslint-disable-next-line consistent-return
    const stripe = useMemo(() => {
        if (locale) {
            const stripeUS = ['US', 'CA', 'MX'];
            const country = (locale.split('-')[1] || '').toUpperCase();
            const pk = stripeUS.indexOf(country) !== -1 ? stripePkUS : stripePk;
            return pk
                ? {
                      pk,
                  }
                : undefined;
        }
    }, [locale]);
    return (
        <LuniiSpaProvider value={spaProviderValue}>
            <LuniiPaymentProvider
                stripe={stripe}
                locale={locale}
            >
                <Page pageContext={pageContext}>
                    <Router basepath={prefix}>
                        {routes.map((route: any, i: number) => {
                            const key = `${i}-${route.path}`;
                            if (route.redirect) {
                                return (
                                    <Redirect
                                        noThrow
                                        key={key}
                                        from={route.path}
                                        to={`${prefix}/${route.redirect}`}
                                    />
                                );
                            }
                            if (route.secured) {
                                return (
                                    <PrivateRoute
                                        key={key}
                                        {...route}
                                        prefix={prefix}
                                    />
                                );
                            }
                            return (
                                <PublicRoute
                                    key={key}
                                    {...route}
                                    prefix={prefix}
                                />
                            );
                        })}
                    </Router>
                </Page>
            </LuniiPaymentProvider>
        </LuniiSpaProvider>
    );
}

export interface MyAccountProps {
    pageContext?: any;
}

export default MyAccount;
