export const countryStates = {
    'us': [
        {
            label: 'Alabama',
            value: 'AL',
        },
        {
            label: 'Alaska',
            value: 'AK',
        },
        {
            label: 'American Samoa',
            value: 'AS',
        },
        {
            label: 'Arizona',
            value: 'AZ',
        },
        {
            label: 'Arkansas',
            value: 'AR',
        },
        {
            label: 'California',
            value: 'CA',
        },
        {
            label: 'Colorado',
            value: 'CO',
        },
        {
            label: 'Connecticut',
            value: 'CT',
        },
        {
            label: 'Delaware',
            value: 'DE',
        },
        {
            label: 'District Of Columbia',
            value: 'DC',
        },
        {
            label: 'Federated States Of Micronesia',
            value: 'FM',
        },
        {
            label: 'Florida',
            value: 'FL',
        },
        {
            label: 'Georgia',
            value: 'GA',
        },
        {
            label: 'Guam',
            value: 'GU',
        },
        {
            label: 'Hawaii',
            value: 'HI',
        },
        {
            label: 'Idaho',
            value: 'ID',
        },
        {
            label: 'Illinois',
            value: 'IL',
        },
        {
            label: 'Indiana',
            value: 'IN',
        },
        {
            label: 'Iowa',
            value: 'IA',
        },
        {
            label: 'Kansas',
            value: 'KS',
        },
        {
            label: 'Kentucky',
            value: 'KY',
        },
        {
            label: 'Louisiana',
            value: 'LA',
        },
        {
            label: 'Maine',
            value: 'ME',
        },
        {
            label: 'Marshall Islands',
            value: 'MH',
        },
        {
            label: 'Maryland',
            value: 'MD',
        },
        {
            label: 'Massachusetts',
            value: 'MA',
        },
        {
            label: 'Michigan',
            value: 'MI',
        },
        {
            label: 'Minnesota',
            value: 'MN',
        },
        {
            label: 'Mississippi',
            value: 'MS',
        },
        {
            label: 'Missouri',
            value: 'MO',
        },
        {
            label: 'Montana',
            value: 'MT',
        },
        {
            label: 'Nebraska',
            value: 'NE',
        },
        {
            label: 'Nevada',
            value: 'NV',
        },
        {
            label: 'New Hampshire',
            value: 'NH',
        },
        {
            label: 'New Jersey',
            value: 'NJ',
        },
        {
            label: 'New Mexico',
            value: 'NM',
        },
        {
            label: 'New York',
            value: 'NY',
        },
        {
            label: 'North Carolina',
            value: 'NC',
        },
        {
            label: 'North Dakota',
            value: 'ND',
        },
        {
            label: 'Northern Mariana Islands',
            value: 'MP',
        },
        {
            label: 'Ohio',
            value: 'OH',
        },
        {
            label: 'Oklahoma',
            value: 'OK',
        },
        {
            label: 'Oregon',
            value: 'OR',
        },
        {
            label: 'Palau',
            value: 'PW',
        },
        {
            label: 'Pennsylvania',
            value: 'PA',
        },
        {
            label: 'Puerto Rico',
            value: 'PR',
        },
        {
            label: 'Rhode Island',
            value: 'RI',
        },
        {
            label: 'South Carolina',
            value: 'SC',
        },
        {
            label: 'South Dakota',
            value: 'SD',
        },
        {
            label: 'Tennessee',
            value: 'TN',
        },
        {
            label: 'Texas',
            value: 'TX',
        },
        {
            label: 'Utah',
            value: 'UT',
        },
        {
            label: 'Vermont',
            value: 'VT',
        },
        {
            label: 'Virgin Islands',
            value: 'VI',
        },
        {
            label: 'Virginia',
            value: 'VA',
        },
        {
            label: 'Washington',
            value: 'WA',
        },
        {
            label: 'West Virginia',
            value: 'WV',
        },
        {
            label: 'Wisconsin',
            value: 'WI',
        },
        {
            label: 'Wyoming',
            value: 'WY',
        },
    ],
    'ca': [
        {
            label: 'Alberta',
            value: 'AB',
        },
        {
            label: 'British Columbia',
            value: 'BC',
        },
        {
            label: 'Manitoba',
            value: 'MB',
        },
        {
            label: 'New Brunswick',
            value: 'NB',
        },
        {
            label: 'Newfoundland and Labrador',
            value: 'NL',
        },
        {
            label: 'Northwest Territories',
            value: 'NT',
        },
        {
            label: 'Nova Scotia',
            value: 'NS',
        },
        {
            label: 'Nunavut',
            value: 'NU',
        },
        {
            label: 'Ontario',
            value: 'ON',
        },
        {
            label: 'Prince Edward Island',
            value: 'PE',
        },
        {
            label: 'Quebec',
            value: 'QC',
        },
        {
            label: 'Saskatchewan',
            value: 'SK',
        },
        {
            label: 'Yukon Territory',
            value: 'YT',
        },
    ],
};

export const countryLocalesWithStates = ['en-us', 'es-us', 'fr-us', 'fr-ca', 'en-ca'];

export abstract class CountryStatesHelper {

    static statesFromLocale(locale: string): {label: string, value: string}[] {
        if (!locale) return [];
        const countryCode = locale.toLowerCase().split('_')[1];
        return countryStates[countryCode] || [];
    }

    /**
     * Accepts locale in the backend format (xx_XX)
     * @param locale
     */
    static countryFromLocaleHasStates(locale: string): boolean {
        return !!locale && countryLocalesWithStates.includes(locale.replace('_', '-').toLowerCase());
    }

    static stateObjectFromValue(locale: string, stateCode: string|undefined): {label: string, value: string} | undefined {
        const countryCode = locale?.toLowerCase()?.split('_')[1];
        return countryCode
            ? countryStates[countryCode]&& countryStates[countryCode].find(el => el.value === stateCode)
            : undefined;
    }

    static stateFromValue(locale: string, stateCode): string | undefined {
        const stateObject = CountryStatesHelper.stateObjectFromValue(locale, stateCode);
        return stateObject?.label;
    }

}

export default CountryStatesHelper;
