import React from 'react';
import clsx from 'clsx';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {cssBgColor} from '@ohoareau/css-utils';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        '& .bull': {
            margin: '0 5px',
            width: 8,
            height: 8,
            display: 'block',
            borderRadius: '100%',
            backgroundColor: '#E3E1DB',
            cursor: 'pointer',
            transition: 'background-color .35s ease, transform .35s ease',
            '&.bull-active': {
                ...cssBgColor(theme, 'primary'),
                transform: 'scale(1.6)',
            },
            '&.bull:focus': {
                outline: 'none',
            },
        },
    },

}));

export function SliderStepper({className, reference}: SliderStepperProps) {
    const classes = useStyles();
    return (
        <div className={clsx(classes.root, className)} ref={reference}/>
    );
}

export interface SliderStepperProps {
    className?: any,
    reference?: any,
}

export default SliderStepper
