import {useCallback, useEffect} from 'react';
import useLuniiAddressesRetrieveApi from './useLuniiAddressesRetrieveApi';

export function useAddressesRetrieveSubmit(callback: Function|undefined = undefined) {
    const [retrieveAddresses, {loading, error, ...infos}] = useLuniiAddressesRetrieveApi();
    const onSubmit = useCallback(async values => {
        try {
            await retrieveAddresses({
                variables: {
                    data: {
                        address: values.address,
                        country: values.country,
                    },
                },
            });
        } catch (e) {
            console.error(e);
        }
    }, [retrieveAddresses]);
    useEffect(() => {
        if (callback && infos?.data?.retrieveAddresses) {
            callback(infos?.data.retrieveAddresses.addresses || []);
        }
    }, [infos?.data?.retrieveAddresses]);
    const data = (((infos || {}).data || {}).retrieveAddresses || {}).addresses;
    return [onSubmit, {loading: Boolean(loading), error: error || false, data}] as [any, any];
}

export default useAddressesRetrieveSubmit;
