import React, {useCallback, useState} from 'react';
import clsx from 'clsx';
import Button from '../atoms/Button';
import EmailField from '../atoms/fields/EmailField';
import PasswordField from '../atoms/fields/PasswordField';
import {FieldSet, Checkbox} from '../atoms';
import {useForm} from "react-hook-form";
import {BodyText} from "../atoms/";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useLuniiTranslation from "../hooks/useLuniiTranslation";
import useRegisterSubmit from "../hooks/useRegisterSubmit";
import ErrorPanel from "../atoms/ErrorPanel";
import {useLuniiNavigation, useLuniiPage, CountrySelector} from "../index";
import {cssText} from "@ohoareau/css-utils";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    loginLink: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 32,
        fontWeight: 700,
    },
    fieldset: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
    },
    input: {
        minWidth: "70%",
    },
    checkBox: {
        margin: theme.spacing(1, 0),
        marginLeft: -2,
        ...cssText(theme, 'standard', 'caption'),
        fontSize: 12,
    },
    loginLinkLink: {
        display: 'inline-block',
        padding: 0,
        marginLeft: 10,
    },
    countrySelection: {
        margin: theme.spacing(2, 0),
    },
    separator: {
        margin: theme.spacing(1, 0),
    },
    countrySelectionText: {
        ...cssText(theme, 'nunito', 'secondary_body', undefined, undefined, '#716C5E'),
        marginBottom: theme.spacing(2),
    },
    footNote: {
        ...cssText(theme, 'standard', 'body', undefined, undefined, '#908977'),
        fontWeight: 600,
        margin: theme.spacing(4, 0, 1, 0),
        fontSize: 12,
    }
}));

export function RegisterForm({className = undefined, onFormUpdate, loginLink = true, onCancelClick, inputFullWidth = true, buttonFullWidth = true, forcedCountry = false}) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const page = useLuniiPage();
    const {goPageByModel} = useLuniiNavigation();
    const { handleSubmit, register, errors } = useForm();
    const x = {errors, register};
    const [language, setLanguage] = useState<any>((page || {}).locale);
    const handleOnFormUpdate = useCallback(() => {
        onFormUpdate(language);
    }, [onFormUpdate, language]);
    const [onSubmit, {loading, error}] = useRegisterSubmit(handleOnFormUpdate);
    const handleCountryChange = useCallback((item) => {
        setLanguage(item);
    }, [setLanguage]);
    const handleTargetLink = useCallback((e) => {
        e.preventDefault();
        goPageByModel && goPageByModel('mentions_legales', true);
    }, [goPageByModel]);
    return (
        <div className={clsx(classes.root, className)}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <ErrorPanel error={error} group={'register'}/>
                <FieldSet className={classes.fieldset}>
                    <EmailField className={classes.input} required autoFocus fullWidth={inputFullWidth} {...x}  />
                    <PasswordField className={classes.input} fullWidth={inputFullWidth} required {...x} />
                    {!language || forcedCountry ? <div className={classes.separator}/> : <div className={classes.countrySelection}>
                        <BodyText className={classes.countrySelectionText}>{t('forms_register_country_selection_text')}</BodyText>
                        <CountrySelector items={(page || {}).languages || []} currentItem={language} onChange={handleCountryChange} />
                    </div>}
                    <Checkbox className={classes.checkBox}
                              id={'registerform_newsletter_subscribed'} name={'newsletter_subscribed'}
                              status={false}
                              content={t('forms_register_newsletter_label')}
                              {...x} />
                    <Checkbox className={classes.checkBox}
                              id={'registerform_cgu_checked'} name={'cgu_checked'}
                              required status={false}
                              linkLabel={t('form_register_cgu_link')}
                              linkTarget={handleTargetLink}
                              content={t('forms_register_cgu_label')}
                              {...x} />
                    <input type={'hidden'} name={'country'} value={forcedCountry ? forcedCountry.toString() : language} ref={register} />
                </FieldSet>
                <Button disabled={loading} type={'submit'} color={'primary'} fullWidth={buttonFullWidth}>{t('forms_register_buttons_submit_label')}</Button>
                {loginLink && <BodyText className={classes.loginLink}>{t('forms_register_buttons_alreadyregistered_text')} <Button color={'tertiary'} noPadding className={classes.loginLinkLink} onClick={onCancelClick}>{t('forms_register_buttons_alreadyregistered_label')}</Button></BodyText>}
                <div className={classes.footNote}>{t('forms_register_data_infos')}</div>
            </form>
        </div>
    );
}

export default RegisterForm
