import React from 'react';
import CloseIcon from '../images/icons/Close';
import SvgUser from '../images/icons/User';
import {VerticalMenu} from './VerticalMenu';
import LoginButton from '../atoms/LoginButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useLuniiUser from '../hooks/useLuniiUser';
import useHasMounted from '../hooks/useHasMounted';
import SvgLibrary from '../images/icons/Library';
import {NavBarRootModeEnum} from '../types';
import clsx from 'clsx';
import {ShoppingCartNavBarItem} from '../atoms/ShoppingCartNavBarItem';
import SubscriptionNavBarItem from './SubscriptionNavBarItem';
import SvgWish from '../images/icons/Wish';

const useStyles = makeStyles(theme => ({
    root: {
        flex: 1,
        marginBottom: theme.spacing(2),
        height: '100vh',
    },
    top: {
        flex: 1,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'top',
        margin: theme.spacing(2,0),
    },
    close: {
        cursor: 'pointer',
    },
    search: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
    },
    icons: {
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: theme.spacing(2),
        padding: theme.spacing(.5),
        transition: 'background-color .35s ease-out',
        borderRadius: '100%',
    },
    iconActive: {
        backgroundColor: '#FEF0CE',
    },
    userIcon: {
        cursor: 'pointer',
    },
    libraryIcon: {
        cursor: 'pointer',
    },
    wishIcon: {
        cursor: 'pointer',
    },
    buttons: {
        marginLeft: theme.spacing(3),
        display: 'flex',
    },
    iconExtended: {
        marginRight: theme.spacing(2),
    },
    iconLoggedExtended: {
        marginRight: theme.spacing(1),
    },
}));

export function ExtendedNavMenu({onModeClose, onUserClick, onLibraryClick, onWishlistClick, menu, section, rootMode = NavBarRootModeEnum.Default, mode, currentPageUid}: ExtendedNavMenuProps) {
    const {user} = useLuniiUser();
    const classes = useStyles();
    /**
     * if not using the following hook, css could be broken in the SSG/Gatsby context
     *
     * @see https://www.joshwcomeau.com/react/the-perils-of-rehydration/
     */

    const hasMounted = useHasMounted();
    return (
        <div className={classes.root}>
            <div className={classes.top}>
                <div className={classes.close}><CloseIcon onClick={onModeClose}/></div>
                {!!user && hasMounted && (
                    <div className={classes.icons}>
                        <div className={clsx(classes.icon)}>
                            <SubscriptionNavBarItem/>
                        </div>
                        <div className={clsx(classes.icon)}>
                            <ShoppingCartNavBarItem active={rootMode === NavBarRootModeEnum.Checkout}/>
                        </div>
                        <div className={clsx(classes.icon, rootMode === NavBarRootModeEnum.Library && classes.iconActive)}>
                            <SvgLibrary className={classes.libraryIcon} onClick={rootMode === NavBarRootModeEnum.Library ? undefined : onLibraryClick} />
                        </div>
                        <div className={clsx(classes.icon, rootMode === NavBarRootModeEnum.Wishlist && classes.iconActive)}>
                            <SvgWish className={classes.wishIcon} onClick={rootMode === NavBarRootModeEnum.Wishlist ? undefined : onWishlistClick} />
                        </div>
                        <div className={clsx(classes.icon, rootMode === NavBarRootModeEnum.My && classes.iconActive)}>
                            <SvgUser className={classes.userIcon} onClick={rootMode === NavBarRootModeEnum.My ? undefined : onUserClick} />
                        </div>
                    </div>
                )}
                {(!user || !hasMounted) && (
                    <div className={classes.buttons}>
                        <div className={clsx(classes.icon, classes.iconExtended)}>
                            <ShoppingCartNavBarItem active={rootMode === NavBarRootModeEnum.Checkout}/>
                        </div>
                        <LoginButton />
                    </div>
                )}
            </div>
            {/*<div className={classes.search}>
                <SearchBar placeholder={t('navbar_search_placeholder')} defaultFocus={'search' === mode} />
            </div>*/}
            <VerticalMenu items={menu} current={section} currentPageUid={currentPageUid}/>
        </div>
    );
}

export interface ExtendedNavMenuProps {
    onModeClose?: any,
    onUserClick?: any,
    onLibraryClick?: any,
    onWishlistClick?: any,
    menu?: any,
    section?: string,
    mode?: 'search' | string,
    rootMode?: NavBarRootModeEnum,
    currentPageUid?: string,
}

export default ExtendedNavMenu;
