import React from 'react';
import useOnClick from './hooks/useOnClick';
import SubmitButton from './nucleons/SubmitButton';
import InternalLink from './nucleons/InternalLink';
import ExternalLink from './nucleons/ExternalLink';
import ActionButton from './nucleons/ActionButton';
import useLuniiNavigation from './hooks/useLuniiNavigation';
import {useLuniiPage} from './hooks';
import {mapPrismicTarget} from '../utils';

const defaultComponents = {
    submit: SubmitButton,
    internal: InternalLink,
    external: ExternalLink,
    action: ActionButton,
};

export function Clickable({active = false, type: typeOfButton, className, components, children, onClick, onClickCb, noMapping = false, ...props}: ClickableProps) {
    const {locale} = useLuniiPage() || {};
    const target = noMapping ? onClick : mapPrismicTarget(onClick, locale);
    const {type, ...computedProps} = useOnClick(target, typeOfButton === 'submit');
    if (onClickCb) {
        if (computedProps.onClick) {
            const {onClick} = computedProps;
            computedProps.onClick = (e) => {
                onClick(e);
                onClickCb(e);
            };
        } else {
            computedProps.onClick = onClickCb;
        }
    }
    const {InternalLink: InLink, ExternalLink: ExLink} = useLuniiNavigation();
    components = {...defaultComponents, ...(components || {})};
    const Component = components[type] || components['action'];
    return (
        <Component
            linkComponent={('internal' === type) ? InLink : ExLink}
            className={className}
            active={active.toString()}
            {...props}
            {...computedProps}>
            {children || ''}
        </Component>
    );
}

export interface ClickableProps {
    classes?: any,
    t?: Function,
    tReady?: boolean,
    i18n?: any,
    type?: string,
    className?: string,
    children?: any,
    onClick?: any,
    onClickCb?: Function
    components?: any,
    active?: boolean,
    noMapping?: boolean,
}

export default Clickable;
