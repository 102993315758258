import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';
import {cssText} from '@ohoareau/css-utils';
import {useFormContext} from 'react-hook-form';
import {gift_card_theme} from '../../types';
import GiftCardTheme from './GiftCardTheme';
import SvgLetter from '../../ui/images/icons/Letter';
import {useLuniiTranslation} from '../../ui';

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        ...cssText(theme, 'standard', 'body'),
    },
    title: {
        ...cssText(theme, 'standard', 'title_2'),
        marginBottom: theme.spacing(1),
    },
    content: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        marginTop: theme.spacing(3),
        '&:after': {
            content: '""',
            width: '31%',
        },
        [theme.breakpoints.down('sm')]: {
            '&:after': {
                content: '""',
                width: '48%',
            },
        },
    },
    theme: {
        cursor: 'pointer',
        width: '31%',
        [theme.breakpoints.down('sm')]: {
            width: '48%',
        },
        marginBottom: theme.spacing(3),
    },
    svgContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            alignItems: 'flex-start',
        },
        ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#716C5E'),
    },
    svg: {
        marginRight: theme.spacing(1),
        marginLeft: -4,
    },
    infos: {
        flex: 1,
    },
}));

export function GiftCardThemeChoice({
    className = '',
    giftCardsThemes = [],
    emptyValueErrors,
    errorClassName = '',
}: GiftCardThemeChoiceProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const {register, setValue, watch} = useFormContext();
    register('giftCardTheme');
    const handleGiftCardThemeChange = (index: number) => {
        setValue('giftCardTheme', giftCardsThemes[index]);
    };
    const giftCardTheme = watch('giftCardTheme');
    return (
        <div className={clsx(classes.root, className)}>
            <div className={classes.title}>{t('gift_cards_visual_title')}</div>
            <div>{t('gift_cards_visual_infos')}</div>
            <div className={classes.content}>
                {giftCardsThemes.map(
                    ({title, image, reference}: gift_card_theme, index: number) => (
                        <GiftCardTheme
                            key={`gift_card_theme_${index}`}
                            className={classes.theme}
                            active={giftCardTheme?.reference === reference}
                            title={title}
                            image={image}
                            onClick={() => handleGiftCardThemeChange(index)}
                        />
                    ),
                )}
            </div>
            <div className={classes.svgContainer}>
                <SvgLetter
                    className={classes.svg}
                    width={50}
                    height={50}
                />
                <div className={classes.infos}>{t('gift_cards_visual_receiver_infos')}</div>
            </div>
            {emptyValueErrors?.theme && (
                <div className={errorClassName}>{t('gift_cards_error_giftcardtheme')}</div>
            )}
        </div>
    );
}

export interface GiftCardThemeChoiceProps {
    className?: string;
    giftCardsThemes: gift_card_theme[];
    emptyValueErrors?: any;
    errorClassName?: string;
}

export default GiftCardThemeChoice;
