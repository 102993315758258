import React from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import {cssFont, cssText} from '@ohoareau/css-utils';
import {Typography} from '@material-ui/core';
import {Trans} from 'react-i18next';
import {
    convertTimestampToDDMMYYYY,
    getLongDate,
    Illustration,
    MySubscriptionModals,
    MySubscriptionTiles,
    ShortWarningMessage, StatusTag,
    SubscriptionPlannedEventsEnum,
    SubscriptionStatusEnum,
    SubscriptionTypeEnum,
    useLuniiPage,
    useLuniiTranslation,
    useLuniiUser,
    WarningMessage,
} from '..';
import SvgCheck from '../images/icons/Check';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        position: 'relative',
        boxSizing: 'border-box',
    },
    tag: {
      display: 'inline-block',
    },
    status: {
        textTransform: 'uppercase',
    },
    heading: {
        marginTop: theme.spacing(0.5),
        ...cssText(theme, 'standard', 'title_1'),
        [theme.breakpoints.down('sm')]: {
            ...cssText(theme, 'standard', 'title_2'),
        },
    },
    shortWarningMessage: {
        margin: theme.spacing(2, 0, 0),
        [theme.breakpoints.up('md')]: {
            margin: theme.spacing(3, 0, 1, 0),
        },
        '& >*': {
            color: '#896717',
        },
    },
    activateSuccess: {
        ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#1A9B74'),
        display: 'flex',
        alignItems: 'flex-start',
        margin: theme.spacing(2, 0, 1, 0),
        '&>:first-child': {
            marginRight: theme.spacing(1),
            marginTop: -2,
        },
    },
    freeContainer: {
        display: 'flex',
        alignSelf: 'stretch',
        alignItems: 'center',
        backgroundColor: '#F2F9FE',
        borderRadius: theme.spacing(1),
        ...cssText(theme, 'standard', 'caption', undefined, undefined, '#1A6F8E'),
        padding: theme.spacing(2),
        marginTop: theme.spacing(3),
    },
    contentHighlight: {
        display: 'inline-block',
        ...cssText(theme, 'standard', 'secondary_body_thick', undefined, undefined, '1A6F8E'),
        marginBottom: theme.spacing(0.5),
    },
    illustration: {
        width: 105,
        marginRight: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    text: {
        marginTop: 4,
        ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#7C878B'),
        [theme.breakpoints.down('sm')]: {
            ...cssFont(theme, 'standard', 'caption'),
        },
    }
}));

export function MyActiveSubscription({
    className = '',
    onChange = () => {},
    showActivateSuccess = false,
    onActivateCouponConfirm = () => {},
    ...props
}: MyActiveSubscriptionProps) {
    const classes = useStyles();
    const {t, i18n, exists} = useLuniiTranslation();
    const {user} = useLuniiUser();
    const locale = useLuniiPage()?.locale || 'fr-fr';
    const subscription = user?.subscription;
    if (!subscription || !user) return null;
    const variant =
        [SubscriptionStatusEnum.Termination, SubscriptionStatusEnum.Pastdue, SubscriptionStatusEnum.Unpaid].indexOf(subscription.status) !== -1 ? 'alert' : 'positive';
    const nextEvent =
        subscription.plannedEvents.length > 0 &&
        subscription.productReference === SubscriptionTypeEnum.MONTHLY
            ? subscription.plannedEvents[0]
            : subscription.plannedEvents[subscription.plannedEvents.length - 1];
    const warningDate =
        subscription.status === SubscriptionStatusEnum.Trialing &&
        getLongDate(subscription.endTrialDate, locale);
    const frozenSubscriptionDeleteEvent = [SubscriptionStatusEnum.Pastdue, SubscriptionStatusEnum.Unpaid].indexOf(subscription.status) !== -1
        && subscription.plannedEvents?.find(({tag}) => tag === SubscriptionPlannedEventsEnum.DELETE_SUBSCRIPTION);
    return (
        <div className={clsx(classes.root, className)} {...props}>
            <StatusTag
                className={classes.tag}
                labelClassName={classes.status}
                variant={variant}
                label={t(`my_subscription_status_${subscription.status}`)}
            />
            <Typography variant='h3' className={classes.heading}>
                {t('my_account_subscription_title')}
            </Typography>
            <div className={classes.text}>{t('my_account_subscription_infos')}</div>
            {showActivateSuccess && (
                <div className={classes.activateSuccess}>
                    <SvgCheck color='currentColor' width={24} height={24} />
                    {t('my_subscription_activate_success_message')}
                </div>
            )}
            {exists(`my_subscription_warning_${subscription.status}`) && (
                <ShortWarningMessage
                    className={classes.shortWarningMessage}
                    level='active'
                    body={
                        <p>
                            {t(`my_subscription_warning_${subscription.status}`, {
                                ...(warningDate && {date: warningDate}),
                            })}
                        </p>
                    }
                />
            )}
            <MySubscriptionTiles
                subscription={subscription}
                locale={locale}
                nextEvent={nextEvent}
            />
            {[SubscriptionStatusEnum.Pastdue, SubscriptionStatusEnum.Unpaid].indexOf(subscription.status) !== -1 && frozenSubscriptionDeleteEvent && (
                <WarningMessage
                    body={(
                        <div>
                            <Trans
                                i18n={i18n}
                                i18nKey={`my_subscription_tiles_${subscription.status}_subscription_warning`}
                                components={{1: <span className={classes.contentHighlight} />}}
                                values={{date: convertTimestampToDDMMYYYY(frozenSubscriptionDeleteEvent.date)}}
                            />
                        </div>
                    )}
                    svg='warning'
                    mode='alert'/>
            )}
            {subscription.status === SubscriptionStatusEnum.Free &&
                subscription.productReference !== SubscriptionTypeEnum.FREE && (
                <div className={classes.freeContainer}>
                    <Illustration
                        className={classes.illustration}
                        mt={0}
                        name='my_subscription_tiles_free_subscription_warning'
                    />
                    <div>
                        <Trans
                            i18n={i18n}
                            i18nKey={`my_subscription_tiles_free_subscription_warning`}
                            components={{1: <span className={classes.contentHighlight} />}}
                            values={{reference: t(subscription.productReference)}}
                        />
                    </div>
                </div>
            )}
            <MySubscriptionModals
                subscription={subscription}
                locale={locale}
                onChange={onChange}
                onActivateCouponConfirm={onActivateCouponConfirm}
            />
        </div>
    );
}

export interface MyActiveSubscriptionProps {
    className?: string;
    onChange?: Function;
    showActivateSuccess?: boolean;
    onActivateCouponConfirm?: Function;
}

export default MyActiveSubscription;
