import React, {useCallback, useRef, useState} from 'react';
import {cssBox, cssShadow, cssText} from '@ohoareau/css-utils';
import clsx from 'clsx';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popper, {PopperPlacementType} from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {generateRandomId} from '../utils/generateRandomId';
import {Clickable, DynamicIcon} from '../';

const useStyles = makeStyles((theme) => ({
    root: {
        ...cssBox(theme, 'standard', 'clear', 'standard'),
        ...cssText(theme, 'standard', 'button_2'),
        ...cssShadow(theme, 'level_02'),
        display: 'inline-block',
        backgroundColor: 'white',
        borderRadius: 8,
        height: 32,
        '& [role="tooltip"]': {
            pointerEvents: 'none',
            display: 'none',
        },
        '& #transitions-popper-language-selector': {
            pointerEvents: 'auto',
            display: 'inherit',
        },
    },
    roundedRoot: {
        borderRadius: 16,
    },
    disabledRoot: {
        filter: 'brightness(.99) saturate(.5)',
    },
    bigRoot: {
        height: 'auto',
        minHeight: '32px',
    },
    content: {
        width: '100%',
        alignItems: 'center',
        display: 'flex',
        padding: '0 16px',
        minHeight: '32px',
        position: 'relative',
        justifyContent: 'flex-start',
        alignContent: 'center',
    },
    bigContent: {
        padding: '8px 16px',
    },
    current: {
        display: 'flex',
        alignItems: 'center',
    },
    disabledCurrent: {
        cursor: 'default !important',
    },
    choices: {
        width: '100%',
        ...cssShadow(theme, 'level_02'),
        borderRadius: 8,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        padding: 8,
    },
    choicesRounded: {
        borderRadius: 16,
    },
    choicesBig: {},
    choice: {
        ...cssBox(theme, 'buttons'),
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        padding: '8px 8px',
        borderRadius: 8,
        transition: 'background-color 0.3s ease-out',
        '& :first-child': {
            display: 'flex',
        },
        '&:hover': {
            backgroundColor: '#FFF9EC',
            borderRadius: 8,
        },
    },
    choiceRounded: {
        borderRadius: 16,
        '&:hover': {
            backgroundColor: '#FFF9EC',
            borderRadius: 16,
        },
    },
    arrowIconWrapper: {
        transition: 'transform .35s',
        transform: 'rotate(0deg)',
        height: 0,
        marginLeft: theme.spacing(1),
    },
    arrowIconWrapperExpanded: {
        transform: 'rotate(180deg)',
    },
    arrowIcon: {
        transform: 'translate3d(0, -50%, 0)',
    },
    popper: {
        width: (props: any) => (props.popperFullWidth ? '100%' : 'auto'),
    },
}));

export function DropDown({
    rootId = '',
    className = '',
    currentChoice,
    choices,
    defaultOpened = false,
    disabled = false,
    rounded = false,
    big = false,
    popperPlacement = 'bottom',
    popperFullWidth = false,
    choicesClassName = '',
    arrowClassName = '',
    clickableClassName = '',
}: DropDownProps) {
    const rootIdRef = useRef(rootId || generateRandomId());
    const classes = useStyles({popperFullWidth});
    const [anchorEl, setAnchorEl] = useState(null);
    const [opened, setOpened] = useState(defaultOpened);
    const handleToggleOpened = useCallback(
        (event) => {
            if (disabled) return;
            setOpened(!opened);
            setAnchorEl(anchorEl ? null : event.currentTarget);
        },
        [opened, setOpened, setAnchorEl, anchorEl, disabled],
    );
    const handleClickAway = useCallback(() => {
        setOpened(false);
        setAnchorEl(null);
    }, [setOpened, setAnchorEl]);
    const handleClickChoice = useCallback(() => {
        setOpened(false);
        setAnchorEl(null);
    }, [setOpened, setAnchorEl]);
    const open = Boolean(anchorEl);
    const id = open ? 'transitions-popper-language-selector' : undefined;
    const popperModifiers = {
        flip: {
            enabled: true,
        },
        preventOverflow: {
            enabled: true,
            boundariesElement: 'window',
        },
        offset: {
            enabled: true,
            offset: '0px, 15px',
        },
    };
    const styledChoices = choices.map((choice, i) => (
        <div
            key={`item-${i}`}
            className={clsx(classes.choice, rounded && classes.choiceRounded)}
            onClick={handleClickChoice}
        >
            {choice || ''}
        </div>
    ));
    return (
        <div
            id={rootIdRef.current}
            className={clsx(
                classes.root,
                rounded && classes.roundedRoot,
                big && classes.bigRoot,
                disabled && classes.disabledRoot,
                className,
            )}
        >
            <ClickAwayListener onClickAway={handleClickAway}>
                <div className={clsx(classes.content, big && classes.bigContent)}>
                    <Clickable
                        className={clickableClassName}
                        aria-describedby={id}
                        onClick={handleToggleOpened}
                    >
                        <div className={clsx(classes.current, disabled && classes.disabledCurrent)}>
                            {currentChoice}
                            <div
                                className={clsx(
                                    classes.arrowIconWrapper,
                                    open && classes.arrowIconWrapperExpanded,
                                )}
                            >
                                <DynamicIcon
                                    type='arrow-down-24'
                                    className={clsx(classes.arrowIcon, arrowClassName)}
                                />
                            </div>
                        </div>
                    </Clickable>
                    <Popper
                        id={id}
                        keepMounted
                        open={open}
                        anchorEl={anchorEl}
                        transition
                        style={{zIndex: 1200}}
                        placement={popperPlacement}
                        modifiers={popperModifiers}
                        disablePortal
                        className={classes.popper}
                    >
                        {({TransitionProps}) => (
                            <Fade {...TransitionProps} timeout={350}>
                                <div
                                    className={clsx(
                                        classes.choices,
                                        rounded && classes.choicesRounded,
                                        big && classes.choicesBig,
                                        choicesClassName,
                                    )}
                                >
                                    {choices && styledChoices}
                                </div>
                            </Fade>
                        )}
                    </Popper>
                </div>
            </ClickAwayListener>
        </div>
    );
}

export interface DropDownProps {
    rootId?: string;
    className?: string;
    defaultOpened?: boolean;
    disabled?: boolean;
    rounded?: boolean;
    big?: boolean;
    popperPlacement?: PopperPlacementType;
    currentChoice: any;
    choices: any[];
    popperFullWidth?: boolean;
    choicesClassName?: string;
    arrowClassName?: string;
    clickableClassName?: string;
}

export default DropDown;
