import React, {useContext, useEffect} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {cssBorder, cssText} from '@ohoareau/css-utils';
import {Grid} from '@material-ui/core';
import {Trans} from 'react-i18next';
import Loadable from '@loadable/component';
import {
    DynamicIcon,
    ShadowedPanel,
    useLuniiNavigation,
    useLuniiTranslation,
    useLuniiUser,
    Illustration,
    StatusTag,
    convertTimestampToDDMMYYYY,
    Button,
    useLuniiSpa,
    Row,
    SubscriptionTypeEnum,
    useLuniiEventTracking,
    SubscriptionCategory,
} from '../../../ui';
import useLuniiPageTracking from '../../../ui/hooks/useLuniiPageTracking';
import {LuniiSubscriptionCheckoutContext} from '../../../contexts/LuniiSubscriptionCheckoutContext';
import {FREE_MONTH_SUBSCRIPTION_ON} from '../../../../configs/site';

const SuccessConfetti = Loadable(() => import('../commons/components/SuccessConfetti'));

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        position: 'relative',
    },
    panel: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(4),
        backgroundColor: 'white',
        ...cssBorder(theme, 'push'),
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(2, 3),
        },
    },
    heading: {
        margin: theme.spacing(1, 0, 2),
        [theme.breakpoints.up('sm')]: {
            margin: theme.spacing(0, 0, 3),
        },
    },
    headingTitle: {
        ...cssText(theme, 'standard', 'large_title'),
        margin: theme.spacing(1, 0, 2),
        [theme.breakpoints.up('sm')]: {
            margin: theme.spacing(1, 0),
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '24px',
        },
    },
    headingSubtitle: {
        ...cssText(theme, 'standard', 'body'),
        margin: 0,
    },
    icon: {
        backgroundColor: '#1eb587',
        borderRadius: '50%',
        padding: 10,
        margin: theme.spacing(2, 0, 0, 0),
        lineHeight: 0,
    },
    status: {
        padding: theme.spacing(0.5, 1, 0.3),
    },
    statusLabel: {
        lineHeight: '175%',
    },
    bodyTitle: {
        ...cssText(theme, 'standard', 'title_2'),
        margin: theme.spacing(1, 0, 3),
    },
    paragraph: {
        ...cssText(theme, 'standard', 'secondary_body'),
        marginBottom: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    paragraphIcon: {
        marginBottom: theme.spacing(1),
        width: 'fit-content',
    },
    paragraphStrong: {
        ...cssText(theme, 'standard', 'body_thick'),
        marginBottom: theme.spacing(0.5),
    },
    paragraphLink: {
        ...cssText(theme, 'standard', 'body_thick', undefined, undefined, '#2762e9'),
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    actions: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginBottom: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            marginBottom: theme.spacing(4),
        },
    },
    illustration: {
        position: 'relative',
        height: 200,
        '& >*': {
            height: '100%',
        },
    },
}));

export function ConfirmationScreen() {
    const classes = useStyles();
    const {t, i18n} = useLuniiTranslation();
    const {user} = useLuniiUser();
    const {goCatalog, goUserHome} = useLuniiNavigation();
    const {navigate} = useLuniiSpa();
    const dataLayerConfirmationPageEvent = useLuniiPageTracking({
        envModel: 'abonnement_confirmation',
    });
    const handleEventTracking = useLuniiEventTracking();

    const {state: subscriptionCheckoutState} = useContext(LuniiSubscriptionCheckoutContext);

    useEffect(() => {
        if (subscriptionCheckoutState.success) {
            dataLayerConfirmationPageEvent();

            const subscriptionCategory = subscriptionCheckoutState.selected?.category;

            const productEventValue =
                subscriptionCategory === SubscriptionCategory.MONTHLY &&
                !user?.hadMonthlySubscriptionFreeMonth && FREE_MONTH_SUBSCRIPTION_ON
                    ? 0
                    : subscriptionCheckoutState.selected.price;
            handleEventTracking({
                categorie: 'conversion',
                action: 'abonnement',
                valeur: productEventValue,
                libelle:
                    subscriptionCategory === SubscriptionCategory.ANNUAL ? 'annuel' : 'mensuel',
            });
        } else {
            navigate('/');
        }
    }, []);
    const handleNavigateToCatalog = () => {
        if (goCatalog) {
            goCatalog();
        }
    };
    const handleNavigateToAccount = (e: any) => {
        e.preventDefault();
        if (goUserHome) {
            goUserHome();
        }
    };
    const subscriptionRenewalDate =
        user?.subscription?.productReference === SubscriptionTypeEnum.MONTHLY
            ? convertTimestampToDDMMYYYY(user.subscription.renewalPlannedAt)
            : (user?.subscription?.plannedEvents[0] &&
                  convertTimestampToDDMMYYYY(user.subscription.plannedEvents[0].date)) ||
              null;

    const trialSubscription = user?.subscription?.endTrialDate;
    return (
        <Row
            padding="default"
            direction="column"
            alignItems="center"
            justify="center"
            rootClassName={classes.root}
        >
            <Grid
                item
                lg={6}
                md={8}
                sm={12}
            >
                <div className={classes.heading}>
                    <DynamicIcon
                        className={classes.icon}
                        type="check"
                        height={42}
                        width={42}
                        color="#fff"
                    />
                    <h2 className={classes.headingTitle}>
                        {t(
                            trialSubscription
                                ? 'subscription_confirm_title_free_month'
                                : 'subscription_confirm_title',
                        )}
                    </h2>
                    {subscriptionRenewalDate && (
                        <p className={classes.headingSubtitle}>
                            {t(
                                trialSubscription
                                    ? 'subscription_confirm_subtitle_free_month'
                                    : 'subscription_confirm_subtitle',
                                {date: subscriptionRenewalDate},
                            )}
                        </p>
                    )}
                </div>
                <ShadowedPanel className={classes.panel}>
                    <h3 className={classes.bodyTitle}>{t('subscription_confirm_body_title')}</h3>
                    <div className={classes.paragraph}>
                        <DynamicIcon
                            className={classes.paragraphIcon}
                            type="token"
                        />
                        <p>
                            <Trans
                                i18n={i18n}
                                i18nKey="subscription_confirm_body_p1"
                            >
                                ...
                                <span className={classes.paragraphStrong}>...</span>
                                ...
                            </Trans>
                        </p>
                    </div>
                    <div className={classes.paragraph}>
                        <div className={classes.paragraphIcon}>
                            <StatusTag
                                className={classes.status}
                                labelClassName={classes.statusLabel}
                                variant="positive"
                                icon={<DynamicIcon type="token" />}
                                label="1"
                            />
                        </div>
                        <p>
                            <Trans
                                i18n={i18n}
                                i18nKey="subscription_confirm_body_p2"
                            >
                                ...
                                <span className={classes.paragraphStrong}>...</span>
                                ...
                            </Trans>
                        </p>
                    </div>
                    <div className={classes.paragraph}>
                        <DynamicIcon
                            className={classes.paragraphIcon}
                            type="user"
                        />
                        <p>
                            <Trans
                                i18n={i18n}
                                i18nKey="subscription_confirm_body_p3"
                                components={[
                                    <span className={classes.paragraphStrong} />,
                                    <a
                                        className={classes.paragraphLink}
                                        onClick={handleNavigateToAccount}
                                    />,
                                ]}
                            />
                        </p>
                    </div>
                </ShadowedPanel>
                <div className={classes.actions}>
                    <Button
                        color="primary"
                        endIcon={<DynamicIcon type="next" />}
                        onClick={handleNavigateToCatalog}
                    >
                        {t('subscription_confirm_gotolibrary')}
                    </Button>
                </div>
                <div className={classes.illustration}>
                    <Illustration
                        name="subscription_success"
                        height={200}
                        objectFit="contain"
                    />
                </div>
            </Grid>
            <SuccessConfetti />
        </Row>
    );
}

export default ConfirmationScreen;
