import React from 'react';
import Divider, {DividerProps} from '@material-ui/core/Divider';
import {cssBox} from '@ohoareau/css-utils';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    root: {
        ...cssBox(theme, undefined, 'default'),
        flex: '1 0 auto',
        margin: props => props['spacing'] > 0 ? `${props['spacing'] * 4}px 0` : undefined,
        backgroundColor: props => props['color'] ? props['color'] : 'inherit',
    },
}));

export function DividingLine(props: DividingLineProps) {
    const classes = useStyles(props);
    return (
        <Divider classes={classes} {...props} />
    );
}

export interface DividingLineProps extends DividerProps {
    spacing?: number,
    color?: string,
}

export default DividingLine;
