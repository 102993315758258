import React from 'react';
import clsx from 'clsx';
import {cssText} from '@ohoareau/css-utils';
import {makeStyles} from '@material-ui/core/styles';
import {cart as cart_type, convertPrice, discount as discount_type, shipping_method, useLuniiTranslation} from '../../ui';
import {DiscountContainer, PaymentDetails} from '..';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
    },
    line: {
        display: 'flex',
        justifyContent: 'space-between',
        ...cssText(theme, 'standard', 'secondary_body'),
        color: '#5D90A3',
        '&>:last-child': {
            marginLeft: 24,
        },
    },
    lineDiscount: {
        ...cssText(theme, 'standard', 'secondary_body_thick'),
        color: '#FBBD2A',
    },
    lineBig: {
        ...cssText(theme, 'standard', 'title_2'),
        color: '#063646',
    },
    defaultTotalPriceContainer: {
        margin: theme.spacing(1, 0),
    },
    totalPriceContainer: {
        marginTop: theme.spacing(2),
    },
    totalPriceContainerNoDiscount: {
        marginTop: theme.spacing(4),
    },
    totalPrice: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 8,
    },
    basePrice: {
        ...cssText(theme, 'standard', 'button_1', undefined, undefined, '#2DAAD7'),
        marginRight: theme.spacing(1),
        textDecoration: 'line-through',
        marginTop: 2.5,
    },
    discount: {
        marginTop: theme.spacing(3),
    },
    details: {
        marginTop: theme.spacing(.5),
    }
}));

export function CheckoutCartTotal({
    className = '',
    cart = undefined,
    mode = 'default',
    hasHardware = false,
    shippingMethod = null,
    totalPrice,
    onPromoCodeUpdate = () => {},
}: CheckoutCartTotalProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const discount: discount_type|undefined = (cart?.discount || cart?.productsDiscount) && {
        ...(cart?.discount && {
            code: cart.discount.code,
            mandatory: cart.discount.mandatory,
        }),
        ...(cart?.productsDiscount?.length && {
            code: cart?.productsDiscount[0].code,
            mandatory: cart?.productsDiscount[0].mandatory,
        })
    } as discount_type;
    const totalBasePrice = (shippingMethod?.price || 0) + (cart?.basePrice || 0); // price with shipping method but without reduction
    if (!cart || (cart.items || []).length === 0) return null;
    return (
        <div className={clsx(classes.root, className)}>
            {mode === 'payment' && (
                <>
                    {discount && (
                        <DiscountContainer
                            className={classes.discount}
                            code={discount.code}
                            mandatory={discount.mandatory}
                            onDelete={onPromoCodeUpdate}/>
                    ) || false}
                    {/* total price container */}
                    <div className={clsx(classes.line, classes.lineBig, classes.totalPriceContainer, !cart.discount && !cart.productsDiscount && classes.totalPriceContainerNoDiscount)}>
                        <div>{t('checkout_cart_total_total_price')}</div>
                        <div className={classes.totalPrice}>
                            {totalBasePrice !== totalPrice && (
                                <div className={classes.basePrice}>{convertPrice(totalBasePrice, cart.currency, false, true)}</div>
                            ) || false}
                            <div>{convertPrice(totalPrice, cart.currency, false, true)}</div>
                        </div>
                    </div>
                    <PaymentDetails className={classes.details} cart={cart} shippingMethod={shippingMethod} defaultExpanded={hasHardware}/>
                </>
            )}
            {mode === 'default' && (
                <div className={clsx(classes.line, classes.lineBig, classes.defaultTotalPriceContainer)}>
                    <span>{t('checkout_cart_total_total_price')}</span>
                    <span>{convertPrice(cart?.priceWithoutReduction, cart.currency, false, true)}</span>
                </div>
            ) || false}
        </div>
    );
}

export interface CheckoutCartTotalProps {
    className?: string;
    cart?: cart_type;
    mode?: 'default' | 'payment';
    hasHardware?: boolean;
    shippingMethod?: shipping_method|null;
    totalPrice: number;
    onPromoCodeUpdate?: Function;
}

export default CheckoutCartTotal;
