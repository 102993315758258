export const routes = [
    {
        path: '/login',
        screen: 'login',
    },
    {
        path: '/',
        screen: 'select',
        secured: true,
    },
    {
        path: '/billing',
        screen: 'billing',
        secured: true,
    },
    {
        path: '/confirmation',
        screen: 'confirmation',
        secured: true,
    },
    {
        path: '/*',
        screen: 'notFound',
    },
];

export default routes;
