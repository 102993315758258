import React from 'react';
import {cssText} from '@ohoareau/css-utils';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import clsx from 'clsx';
import TextParagraph from "../molecules/TextParagraph";
import {buttonifyFromProps} from '../hocs';
import {Img} from "../nucleons";
import Row from "../Row";
import {module_image_position, module_mobile_image_position, WithPush} from "../types";

const useStyles = makeStyles(theme => ({
    root: {
        ...cssText(theme, 'standard', 'body'),
        display: 'flex',
    },
    fullHeightImg: {
        [theme.breakpoints.down('sm')]: {
            paddingTop: 0,
            paddingBottom: 32,
        },
    },
    image: {
        width: '100%',
        height: 'inherit',
        [theme.breakpoints.down('sm')]: {
            marginBottom: 14,
        },
    },
    separator: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    paragraph: {
        [theme.breakpoints.down('md')]: {
            padding: '0 10px',
        },
    },
}));

export function Content1({className, gridClassName, title, text, image, imagePosition = 'left', fullHeightImg = false, ...props}: Content1Props) {
    const classes = useStyles();
    const {Buttons} = buttonifyFromProps(props, ['primary', 'tertiary']);
    const padding = fullHeightImg ? 'none' : 'default'; // disable padding if full height
    const position = imagePosition !== 'left' && imagePosition !== 'right' ? 'left' : imagePosition; // force position to left or right
    const margins = fullHeightImg ? position === 'left' ? 'right-except-mobile' : 'left-except-mobile' : 'all';
    return (
        <Row className={clsx(classes.root, fullHeightImg && classes.fullHeightImg, className)} gridClassName={gridClassName} direction={position === 'right' ? 'row-reverse' : 'row'} justify={'space-between'} margins={margins} padding={padding}>
            <Grid item container lg={6} md={fullHeightImg ? 7 : 6} xs={12}>
                <div className={classes.image}>
                    {image && <Img rounded {...image} />}
                </div>
            </Grid>
            <Grid className={clsx(fullHeightImg && classes.paragraph)} item container lg={5} md={5} xs={12}>
                <TextParagraph title={title} text={text} buttonsComponent={Buttons} />
            </Grid>
        </Row>
    );
}

export interface Content1Props extends WithPush {
    className?: any,
    gridClassName?: any,
    imagePosition?: module_image_position,
    mobileImagePosition?: module_mobile_image_position,
    fullHeightImg?: boolean
}

export default Content1
