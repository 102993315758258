import React, {ReactNode, useEffect} from 'react';
import {I18nextProvider} from 'react-i18next';
import {MuiThemeProvider} from '@material-ui/core';
import {ScrollingProvider} from 'react-scroll-section';
import PageHead from './PageHead';
import PageBody from './PageBody';
import usePageContext from '../hooks/usePageContext';
import {ApolloProvider} from '../ApolloClient';
import {
    LuniiStorageProvider,
    LuniiApiProvider,
    LuniiCartProvider,
    LuniiUserProvider,
    LuniiProductProvider,
    LuniiNavigationProvider,
    LuniiCartModalProvider,
    LuniiPasswordUpdateModalProvider,
    LuniiCatalogProvider,
    LuniiPageProvider,
    LuniiThemesProvider,
    LuniiSectionsProvider,
    LuniiTrackingProvider,
    LuniiLocationProvider,
    ErrorBoundary,
} from '../ui';
import {page, catalog, gift_card_theme} from '../types';
import {LuniiGiftCardsThemesProvider} from '../contexts';

function resetCartIfNeeded(page: page, {cart, resetCart}: any) {
    // Reset cart if cart country !== page lang, only when they are not undefined
    const cartCountry = cart && cart.country && cart.country.replace('_', '-').toLowerCase();
    const pageCountry = page && page.locale;
    cartCountry && pageCountry && cartCountry !== pageCountry && resetCart();
}

function updateUserIfNeeded(page: page, {user, updateUserLocale}: any) {
    // Update user id user country !== page lang, only when user and page country are not undefined
    const pageCountry = page && page.locale;
    const userCountry = user && user.locale && user.locale.replace('_', '-').toLowerCase();
    if (user && pageCountry && userCountry !== pageCountry) {
        const newLocale = pageCountry
            .split('-')
            .map((item, index) => (index === 1 ? item.toUpperCase() : item))
            .join('_');
        updateUserLocale(newLocale);
    }
}

export function Page({
    pageContext: {page, catalog, giftCardsThemes},
    children,
    location,
}: PageProps) {
    const {
        storage,
        client,
        i18n,
        baseTheme,
        pageTheme,
        api,
        cart,
        user,
        product,
        navigation,
        locale,
        themes,
        sections,
        tracking,
    } = usePageContext(page);

    const {refreshUserPartial, user: userIsConnected} = user;

    useEffect(() => {
        resetCartIfNeeded(page, cart);
        updateUserIfNeeded(page, user);
    }, [cart, user]);

    useEffect(() => {
        if (userIsConnected) refreshUserPartial({subscription: true});
    }, []);

    page = {...page};
    page.locale = locale; // usefull for spas
    page.languages = (page.languages || [])
        .map((l) => ({...l, label: i18n.t(l.label)}))
        .sort((a: any, b: any) => {
            if (b.id === 'fr-fr' && a.id !== 'fr-fr') return 1;
            if (b.id !== 'fr-fr' && a.id === 'fr-fr') return -1;
            return (a.label || '').localeCompare(b.label);
        });
    return (
        <ErrorBoundary page={page}>
            {' '}
            {/* try-catch mechanism React-way */}
            <LuniiStorageProvider value={storage}>
                {' '}
                {/* localStorage */}
                <LuniiLocationProvider value={location}>
                    <ApolloProvider client={client}>
                        {' '}
                        {/* graphql client */}
                        <I18nextProvider i18n={i18n}>
                            {' '}
                            {/* translations/i18n */}
                            <MuiThemeProvider theme={baseTheme}>
                                {' '}
                                {/* static css theme */}
                                <MuiThemeProvider theme={pageTheme}>
                                    {' '}
                                    {/* page-level css theme */}
                                    <LuniiThemesProvider value={themes}>
                                        {' '}
                                        {/* list of all available themes */}
                                        <LuniiApiProvider value={api}>
                                            {' '}
                                            {/* for api-related hooks */}
                                            <LuniiCartProvider value={cart}>
                                                {' '}
                                                {/* for cart-related hooks */}
                                                <LuniiUserProvider value={user}>
                                                    {' '}
                                                    {/* for user-related hooks */}
                                                    <LuniiNavigationProvider value={navigation}>
                                                        {' '}
                                                        {/* for routing hooks */}
                                                        <LuniiCatalogProvider
                                                            value={catalog as any}
                                                        >
                                                            {' '}
                                                            {/* for catalog hooks */}
                                                            <LuniiGiftCardsThemesProvider
                                                                value={giftCardsThemes as any}
                                                            >
                                                                {' '}
                                                                {/* for gift cards themes hooks */}
                                                                <LuniiProductProvider
                                                                    value={product}
                                                                >
                                                                    {' '}
                                                                    {/* for product-related hooks */}
                                                                    <LuniiTrackingProvider
                                                                        value={tracking}
                                                                    >
                                                                        {' '}
                                                                        {/* for datalayer hooks */}
                                                                        <LuniiCartModalProvider>
                                                                            {' '}
                                                                            {/* for cart modal */}
                                                                            <LuniiPasswordUpdateModalProvider>
                                                                                {' '}
                                                                                {/* for password update modal */}
                                                                                <LuniiSectionsProvider
                                                                                    value={
                                                                                        sections
                                                                                    }
                                                                                >
                                                                                    <LuniiPageProvider
                                                                                        value={
                                                                                            page
                                                                                        }
                                                                                    >
                                                                                        {' '}
                                                                                        {/* for page-related hooks */}
                                                                                        <ScrollingProvider
                                                                                            offset={
                                                                                                -128
                                                                                            }
                                                                                        >
                                                                                            <PageHead
                                                                                                page={
                                                                                                    page
                                                                                                }
                                                                                            />{' '}
                                                                                            {/* html head tag from page data */}
                                                                                            <PageBody
                                                                                                page={
                                                                                                    page
                                                                                                }
                                                                                            />{' '}
                                                                                            {/* html body tag from page data */}
                                                                                            {children ||
                                                                                                ''}
                                                                                        </ScrollingProvider>
                                                                                    </LuniiPageProvider>
                                                                                </LuniiSectionsProvider>
                                                                            </LuniiPasswordUpdateModalProvider>
                                                                        </LuniiCartModalProvider>
                                                                    </LuniiTrackingProvider>
                                                                </LuniiProductProvider>
                                                            </LuniiGiftCardsThemesProvider>
                                                        </LuniiCatalogProvider>
                                                    </LuniiNavigationProvider>
                                                </LuniiUserProvider>
                                            </LuniiCartProvider>
                                        </LuniiApiProvider>
                                    </LuniiThemesProvider>
                                </MuiThemeProvider>
                            </MuiThemeProvider>
                        </I18nextProvider>
                    </ApolloProvider>
                </LuniiLocationProvider>
            </LuniiStorageProvider>
        </ErrorBoundary>
    );
}

export interface PageProps {
    pageContext: {
        page: page;
        catalog?: catalog;
        giftCardsThemes?: gift_card_theme[];
    };
    children?: ReactNode;
    location?: Location;
}

export default Page;
