import React, {useState} from 'react';
import {cssText} from '@ohoareau/css-utils';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import {DynamicIcon, Img} from '../../../ui';
import {getThemeColor} from '../../../../configs';
import {tag_theme} from '../../../types';
import {Button, Link} from '.';

const useStyles = makeStyles(theme => ({
    root: {
        ...cssText(theme, 'standard', 'body'),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        borderRadius: 16,
        padding: 16,
    },
    rootImage: {
        [theme.breakpoints.up('md')]: {
            paddingLeft: 0,
        },
    },
    rootIcon: {
        paddingLeft: 8,
    },
    content: {
        width: '100%',
        flex: '1 1 0',
    },
    close: {
        cursor: 'pointer',
        marginLeft: 4,
        marginTop: -2,
    },
    title: {
        ...cssText(theme, 'standard', 'secondary_body_thick', undefined, undefined , 'inherit'),
        marginBottom: 4,
    },
    description: {
        ...cssText(theme, 'standard', 'secondary_body', undefined, undefined , 'inherit'),
    },
    image: {
        width: '100%',
        height: '100%',
        maxWidth: 120,
        marginRight: 16,
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
        lineHeight: 0,
    },
    icon: {
        marginRight: 8,
        marginTop: -2,
    },
    info: {
        backgroundColor: getThemeColor('brand-secondary', '100'),
        color:  getThemeColor('brand-secondary', '800'),
    },
    tips: {
        backgroundColor: getThemeColor('brand-primary', '100'),
        color:  getThemeColor('brand-primary', '800'),
    },
    success: {
        backgroundColor: getThemeColor('positive', '100'),
        color:  getThemeColor('positive', '800'),
    },
    error: {
        backgroundColor: getThemeColor('danger', '100'),
        color:  getThemeColor('danger', '800'),
    },
    neutral: {
        backgroundColor: getThemeColor('ui', 'white'),
        color:  getThemeColor('ui', '800'),
    },
    flam: {
        backgroundColor: getThemeColor('brand-flam', '100'),
        color:  getThemeColor('brand-flam', '800'),
    },
    infoBold: {
        color:  getThemeColor('brand-secondary', '900')
    },
    tipsBold: {
        color:  getThemeColor('brand-primary', '900')
    },
    successBold: {
        color:  getThemeColor('positive', '900')
    },
    errorBold: {
        color:  getThemeColor('danger', '900')
    },
    neutralBold: {
        color:  getThemeColor('ui', '900')
    },
    flamBold: {
        color:  getThemeColor('brand-flam', '900')
    },
    actions: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
        '&>*': {
            marginTop: theme.spacing(1),
            '&:first-child': {
                marginRight: theme.spacing(1),
            },
        },
    },
    button: {
        marginTop: theme.spacing(1),
        width: '100%',
    },
}));

export function Alert({
    className = '',
    title = undefined,
    description,
    theme = 'info',
    closable = false,
    icon = undefined,
    image = undefined,
    linkLabel = undefined,
    linkIcon = undefined,
    linkIconPosition = undefined,
    linkUrl = undefined,
    link2Label = undefined,
    link2Icon = undefined,
    link2IconPosition = undefined,
    link2Url = undefined,
    buttonLabel = undefined,
    buttonIcon = undefined,
    buttonIconPosition = undefined,
    buttonUrl = undefined,
}: AlertProps) {
    const [visible, setVisible] = useState<boolean>(true);
    const classes = useStyles();
    const linkTheme = ['info', 'neutral'].indexOf(theme) !== -1 && 'default' || theme;
    if (!visible) {
        return null;
    }
    return (
        <div className={clsx(classes.root, classes[theme], (icon?.length && !image) && classes.rootIcon, image && classes.rootImage, className)}>
            {(icon?.length && !image) && <DynamicIcon className={clsx(classes.icon, title && classes[`${theme}Bold`])} type={icon}/> || false}
            {image && <div className={classes.image}><Img objectFit='contain' url={image?.url}/></div> || false}
            <div className={classes.content}>
                {title?.length && <div className={clsx(classes.title, classes[`${theme}Bold`])}>{title}</div> || false}
                <div className={classes.description}>{description}</div>
                {((linkLabel && linkUrl) || (link2Label && link2Url) || (buttonLabel && buttonLabel)) && (
                    <div className={classes.actions}>
                        {linkLabel && linkUrl && <Link text={linkLabel} url={linkUrl} theme={linkTheme} size='medium' icon={linkIcon} iconPosition={linkIconPosition}/> || false}
                        {link2Label && link2Url && <Link text={link2Label} url={link2Url} theme={linkTheme} size='medium' icon={link2Icon} iconPosition={link2IconPosition}/> || false}
                        <div className={classes.button}>
                            {buttonLabel && buttonUrl && <Button text={buttonLabel} url={buttonUrl} theme='secondary' size='small' icon={buttonIcon} iconPosition={buttonIconPosition}/> || false}
                        </div>
                    </div>
                ) || false}
            </div>
            {closable && <DynamicIcon className={clsx(classes.close, title && classes[`${theme}Bold`])} onClick={() => setVisible(!visible)} type='cross-24'/> || false}
        </div>
    );
}

export interface AlertProps {
    className?: string;
    title?: any;
    description: any;
    theme?: tag_theme;
    closable?: boolean;
    icon?: any;
    image?: any;
    linkLabel?: string;
    linkIcon?: string;
    linkIconPosition?: 'left' | 'right';
    linkUrl?: any;
    link2Label?: string;
    link2Icon?: string;
    link2IconPosition?: 'left' | 'right';
    link2Url?: any;
    buttonLabel?: string;
    buttonIcon?: string;
    buttonIconPosition?: 'left' | 'right';
    buttonUrl?: any;
}

export default Alert;
