import React, {useCallback, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {cssText} from '@ohoareau/css-utils';
import {useForm} from 'react-hook-form';
import {Trans} from 'react-i18next';
import {FieldSet} from '../atoms/FieldSet';
import {DeviceNameField} from '../atoms/fields';
import {buttonifyFromProps, Clickable, Device, ErrorPanel, Img, Modal,
    useFahNameUpdateSubmit, useLuniiNavigation, useLuniiTranslation} from '..';

const useStyles = makeStyles((theme) => ({
    body: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '> *': {
            maxWidth: '100%',
        },
        [theme.breakpoints.down('sm')]: {
            paddingBottom: theme.spacing(2),
        },
    },
    title: {
        [theme.breakpoints.down('sm')]: {
            ...cssText(theme, 'standard', 'body_thick', undefined, undefined, '#2C3637'),
            width: '100%',
            textAlign: 'center',
            paddingLeft: 20,
            marginTop: 3,
        },
    },
    form: {
        width: '100%',
    },
    fieldset: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    meta: {
        display: 'block',
        width: '100%',
        margin: theme.spacing(0, 0, 2, 0),
    },
    label: {
        ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#7C878B'),
        margin: theme.spacing(0, 0, 0.5, 0),
    },
    value: {
        ...cssText(theme, 'standard', 'body_thick', undefined, undefined, '#2C3637'),
    },
    deviceImage: {
        width: '100%',
        maxWidth: 90,
        height: 'auto',
        margin: theme.spacing(2, 0),
    },
    deviceModelDesc: {
        ...cssText(theme, 'standard', 'caption', undefined, undefined, '#7C878B'),
        transform: 'translateY(-12px)',
    },
    link: {
        display: 'inline',
        ...cssText(theme, 'standard', 'button_3', undefined, undefined, 'link'),
        textDecoration: 'underline',
        textTransform: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
}));

export function MyDevicesModal({open, onClose, device}: MyDevicesModalProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const {goSupport} = useLuniiNavigation();
    const [
        onSubmitNameUpdate,
        {loading: nameLoading, error: nameError, reset: resetName, called: calledName},
    ] = useFahNameUpdateSubmit();
    const [loading, setLoading] = useState<boolean>(false);
    const [formInit, setFormInit] = useState<boolean>(false);
    const {handleSubmit, register, errors, formState, reset, watch, setValue} = useForm({
        mode: 'onChange',
        defaultValues: device ? { deviceName: device?.name } : undefined,
    });
    const onFormInit = useCallback(
        (node) => {
            if (node && !formInit) {
                setFormInit(true);
            } else if (!node && formInit) {
                setFormInit(false);
            }
        },
        [formInit, setFormInit],
    );
    // if form has been initialized, set default form values
    useEffect(() => {
        if (open && device && formInit) {
            setValue('deviceName', device.name);
            // reset previous error messages for gql queries
            if (calledName) resetName();
        }
    }, [open, device, formInit]);

    const onSubmit = async (data: any) => {
        if (!device) return;

        setLoading(true);

        const results: any[] = [];

        if (device.name !== data.deviceName) {
            const result = await onSubmitNameUpdate(device.id, data.deviceName);
            results.push(result ? result.success : undefined);
        }

        setLoading(false);

        if (results.every((result) => !!result)) {
            onClose(true);
        }
    };

    const x = {errors, register};

    const muiModalProps = {
        ref: onFormInit,
        // This props allows to detect the modal body render and thus the form fields initialization
        // Explaination https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
    };

    const handleClose = () => {
        reset();
        onClose(false);
    };

    const {Buttons} = buttonifyFromProps(
        {
            buttonLabel: t('my_fahs_fah_modal_cancel'), // old fah key but same translation so no update
            buttonTarget: handleClose,
            button2Label: t('my_fahs_fah_modal_confirm'), // old fah key but same translation so no update
            button2Target: handleSubmit(onSubmit),
        },
        [`plain,disabled=${loading}`, `primary,disabled=${loading || !formState.isDirty}`],
    );
    function Link() {
        return (
            <Clickable
                className={classes.link}
                onClick={(e: any) => goSupport && goSupport(e, 'articles/4412936294417')}>
                {t('fah_modal_support_link')}
            </Clickable>
        );
    }
    const body = (
        <div className={classes.body}>
            {device?.illustrationUrl && (
                <Img
                    className={classes.deviceImage}
                    url={device?.illustrationUrl}
                    alt={device?.name}
                />
            )}
            <form className={classes.form} onSubmit={(e) => e.preventDefault()}>
                {nameError && (
                    <ErrorPanel
                        error={nameError}
                        group='fah'
                    />
                )}
                <FieldSet className={classes.fieldset}>
                    <DeviceNameField
                        charCount={watch('deviceName')?.length || 0}
                        required
                        {...x}
                    />
                </FieldSet>
                <div className={classes.meta}>
                    <div className={classes.label}>{t('fields_fahserial_label')}</div>
                    <div className={classes.value}>{device?.serialNumber || '-------'}</div>
                </div>
                {device?.metadata?.firmwareVersion && (
                    <div className={classes.meta}>
                        <div className={classes.label}>{t('device_firmware')}</div>
                        <div className={classes.value}>
                            {device?.metadata.firmwareVersion.split('_')[1] || device?.metadata.firmwareVersion}
                        </div>
                    </div>
                )}
                {device?.version && (
                    <div className={classes.meta}>
                        <div className={classes.label}>{t('device_model')}</div>
                        <div className={classes.value}>{t(`device_model_version_${device.version}`)}</div>
                    </div>
                )}
                {device?.version && (
                    <div className={classes.deviceModelDesc}>
                        <Trans
                            i18nKey={`device_model_desc_${device?.version}`}
                            components={{devComponent: <Link/>}}
                        />
                    </div>
                )}
            </form>
        </div>
    );

    if (!device) return null;

    return (
        <Modal
            open={open}
            onCloseCallback={handleClose}
            title={t(`my_device_${device?.version}_modal_title`)}
            titleClassName={classes.title}
            loading={nameLoading || loading}
            muiModalProps={muiModalProps}
            body={body}
            actions={Buttons && <Buttons />}
        />
    );
}

export interface MyDevicesModalProps {
    open: boolean;
    onClose: Function;
    device: Device | undefined;
}

export default MyDevicesModal;
