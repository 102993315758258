import React, {useCallback, useState} from 'react';
import clsx from 'clsx';
import Row from '../Row';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import HiddenCss from '@material-ui/core/Hidden/HiddenCss';
import Toolbar from '@material-ui/core/Toolbar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {cssBox, cssText} from '@ohoareau/css-utils';
import DesktopNavBar from '../molecules/DesktopNavBar';
import TabletNavBar from '../molecules/TabletNavBar';
import MobileNavBar from '../molecules/MobileNavBar';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import {NavBarRootModeEnum} from '../types';

const useStyles = makeStyles(theme => ({
    root: {
        ...cssBox(theme, undefined, 'clear', 'level_02'),
        ...cssText(theme, 'standard', 'body_thick'),
        transform: 'none',
        transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
        padding: theme.spacing(0, 4),
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(0, 2),
        },
    },
    has_extra: {
        transition: 'transform 275ms cubic-bezier(0, 0, 0.2, 1) 0ms',
    },
    expanded: {
        borderBottomLeftRadius: 16,
        borderBottomRightRadius: 16,
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            borderBottomLeftRadius: 'unset',
            borderBottomRightRadius: 'unset',
        },
    },
    hide_trigger_enabled: {
        transform: 'translateY(-65px)',
        [theme.breakpoints.down('sm')]: {
            transform: 'translateY(-56px)',
        },
    },
    offset: {
        minHeight: 64,
        [theme.breakpoints.down('sm')]: {
            minHeight: 56,
        },
    },
    offsetExtra: {
        minHeight: 128,
        [theme.breakpoints.down('sm')]: {
            minHeight: 112,
        },
    },
}));

export function NavBar({defaultMode = undefined, offsetClassName, children, position = 'fixed', hideOnScroll = false, hideToolbarOnScroll = false, ...props}: NavBarProps) {
    const classes = useStyles();
    const [mode, setMode] = useState(defaultMode);
    const handleSearchClick = useCallback(() => setMode('search'), [setMode]);
    const handleExtendedClick = useCallback(() => setMode('extended'), [setMode]);
    const handleModeClose = useCallback(() => setMode(undefined), [setMode]);
    const trigger = useScrollTrigger();
    const expanded = 'extended' === mode || 'search' === mode;
    props = {
        ...props,
        mode, onExtendedClick: handleExtendedClick, onSearchClick: handleSearchClick, onModeClose: handleModeClose,
    } as any;

    const content = (
        <div>
            <HiddenCss mdDown>
                <Toolbar disableGutters={true}>
                    <Grid container item lg={12} md={12} xs={12}>
                        <Row padding={'none'} margins={'none'}>
                            <DesktopNavBar {...props} />
                        </Row>
                    </Grid>
                </Toolbar>
            </HiddenCss>
            <HiddenCss lgUp smDown>
                <Toolbar disableGutters={true}>
                    <Grid container item lg={12} md={12} xs={12}>
                        <Row padding={'none'} margins={'none'}>
                            <TabletNavBar {...props} />
                        </Row>
                    </Grid>
                </Toolbar>
            </HiddenCss>
            <HiddenCss mdUp>
                <Toolbar disableGutters={true}>
                    <Grid container item lg={12} md={12} xs={12}>
                        <Row padding={'none'} margins={'none'}>
                            <MobileNavBar {...props} />
                        </Row>
                    </Grid>
                </Toolbar>
            </HiddenCss>
        </div>
    );
    return (
        <>
            <AppBar elevation={0} className={clsx(classes.root, children && classes.has_extra, expanded && classes.expanded, (hideOnScroll || hideToolbarOnScroll) && trigger && classes.hide_trigger_enabled)} position={position}>
                {content}
                {children && (
                    <Toolbar disableGutters={true}>
                        <Row padding={'none'} margins={'none'}>{children}</Row>
                    </Toolbar>
                )}
            </AppBar>
            <div className={clsx(classes.offset, children && classes.offsetExtra, children && offsetClassName)} />
        </>
    );
}

export interface NavBarProps {
    defaultMode?: 'search' | 'extended' | undefined,
    position?: 'static' | 'relative' | 'sticky' | 'absolute' | 'fixed',
    offsetClassName?: string,
    children?: any,
    onLogoClick?: Function,
    onUserClick?: Function,
    onWishlistClick?: Function,
    onLibraryClick?: Function,
    menu?: any,
    section?: string,
    hideOnScroll?: boolean,
    hideToolbarOnScroll?: boolean,
    rootMode?: NavBarRootModeEnum,
}

export default NavBar;
