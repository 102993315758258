import React from 'react';
import { Redirect } from '@reach/router';
import { SpaLayout } from '../../layouts';

export interface NotFoundScreenProps {
    prefix?: string;
    route?: string;
}

export function NotFound({ prefix = '', route = '' }: NotFoundScreenProps) {
    return (
        <SpaLayout>
            <Redirect
                noThrow
                to={`${prefix}/${route}`}
            />
        </SpaLayout>
    );
}

export default NotFound;
