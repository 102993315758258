import React from 'react';
import {cssText} from '@ohoareau/css-utils';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: 9,
    },
    content: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            paddingLeft: 40, // todo keep it ?
        },
    },
    title: {
        ...cssText(theme, 'standard', 'button_1'),
        marginBottom: -2,
    },
    expandedTitle: {
        color: '#503C0D',
    },
    expanded: {
        marginTop: theme.spacing(1),
        ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#896717'),
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 9,
        },
    },
    show: {
        display: 'block',
    },
}));

export function PaymentRadioLabel({icon, title, content, expanded = false, expandedClassName = ''}: PaymentRadioLabelProps) {
    const classes = useStyles({expanded});
    return (
        <div className={classes.root}>
            <div className={classes.content}>
                {icon && icon}
                {title && <div className={clsx(classes.title, expanded && classes.expandedTitle)}>{title}</div>}
            </div>
            <div className={clsx(classes.expanded, expanded && classes.show, expandedClassName)}>
                {content && content}
            </div>
        </div>
    );
}

export interface PaymentRadioLabelProps {
    icon?: any,
    title?: any,
    content?: any,
    expanded?: boolean,
    expandedClassName?: string,
}

export default PaymentRadioLabel;
