/**
 * Generate random string.
 * @param prefix - prefix for the random string
 * @param length - length of the random string
 * @param characters - chars used by the function to generate the random string
 */
export function generateRandomId (
    prefix: string = '',
    length: number = 12,
    characters: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789',
) {
    const charactersLength = characters.length;
    return [...Array(length)].reduce(
        (accumulator) =>
            accumulator + characters.charAt(Math.floor(Math.random() * charactersLength)),
        `${prefix}`,
    );
};

export default generateRandomId;
