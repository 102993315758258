import React from 'react';
import clsx from 'clsx';
import {cssText} from '@ohoareau/css-utils';
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    root: {
        ...cssText(theme, 'standard', 'body', undefined, undefined, '#716C5E'),
        fontWeight: 200,
    },
}));

export function BodyText({className = undefined, children}: any) {
    const classes = useStyles();
    return (
        <div className={clsx(classes.root, className)}>
            {children || ''}
        </div>
    );
}

export default BodyText
