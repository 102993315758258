import React from 'react';
import {cssText} from '@ohoareau/css-utils';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Row from "../Row";
import Grid from "@material-ui/core/Grid";
import {module_title, WithItems} from "../types";

const useStyles = makeStyles(theme => ({
    root: {
        ...cssText(theme, 'standard', 'body'),
    },
}));

export function SlideshowImages(props: SlideshowImagesProps) {
    const classes = useStyles();
    return (
        <Row className={classes.root}>
            <Grid item container lg={12} md={8} xs={4}>
                <p>SLIDESHOW IMAGES</p>
            </Grid>
        </Row>
    );
}

export interface SlideshowImagesProps extends WithItems {
    title?: module_title,
}

export default SlideshowImages
