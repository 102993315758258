import {useCallback} from 'react';
import {Stripe, StripeCardElement, StripeElements} from '@stripe/stripe-js';
import {address} from '../ui';

export interface CreateSubscriptionPaymentCallback {
    (
        email: string,
        name: string,
        stripeProductReference: string,
        address: address,
        countryCode: string,
        onError: Function,
    ): Promise<any>;
}

export interface ValidateSubscriptionPaymentCallback {
    (
        paymentIntentInfos: any,
        name: string,
        address: address,
        onSuccess: Function,
        onError: Function,
    ): Promise<any>;
}

export function useSubscriptionPayment(
    createPayment: Function,
    stripe: Stripe | null,
    elements: StripeElements | null,
): Function[] {
    const useCreateSubscriptionPayment = useCallback<CreateSubscriptionPaymentCallback>(
        async (email: string, name: string, stripeProductReference: string, address: address, countryCode: string) =>
            createPayment({
                email,
                userFullname: name,
                stripeProductReference,
                address: {
                    city: address.city,
                    country: address.country,
                    postalCode: address.zipCode,
                    addressLine1: address.address1,
                },
                countryCode,
            }),
        [createPayment],
    );

    const useValidatePayment = useCallback<ValidateSubscriptionPaymentCallback>(
        async (
            paymentIntentInfos: any,
            name: string,
            address: address,
            onSuccess: Function,
            onError: Function,
        ) => {
            if (!elements || !stripe) return;

            if (!paymentIntentInfos?.client_secret) {
                await onSuccess();
                return;
            }

            const card = elements.getElement('card') as StripeCardElement;

            if (!card) return;

            try {
                const {paymentIntent, error} = await stripe.confirmCardPayment(
                    paymentIntentInfos?.client_secret,
                    {
                        payment_method: {
                            card,
                            billing_details: {
                                address: {
                                    city: address.city,
                                    country: address.locale.substring(3),
                                    line1: address.address1,
                                    postal_code: address.zipCode,
                                },
                                name,
                            },
                        },
                    },
                );

                if (paymentIntent) onSuccess();
                else onError(error);
            } catch (error) {
                onError(error);
            }
        },
        [stripe, elements],
    );

    return [useCreateSubscriptionPayment, useValidatePayment];
}

export default useSubscriptionPayment;
