import React, {useState} from 'react';
import {DynamicIcon} from '../nucleons';
import {
    ClickAwayListener,
    makeStyles,
    PopperProps,
    Tooltip as MUITooltip,
    TooltipProps as MUITooltipProps,
} from '@material-ui/core';
import {cssShadow, cssText} from '@ohoareau/css-utils';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    iconWrapper: {
        position: 'relative',
        display: 'block',
        cursor: 'help',
        padding: theme.spacing(2),
        borderRadius: '100%',
        width: 'fit-content',
        height: 'fit-content',
        backgroundColor: (props) => props['backgroundColor'] || '#FAF9F8',
    },
    icon: {
        display: 'block',
    },
    tooltip: {
        ...cssText(theme, 'standard', 'button_1', undefined, undefined, '#423F37'),
        backgroundColor: '#FAF9F8',
        margin: theme.spacing(2),
        padding: theme.spacing(2),
        borderRadius: theme.spacing(1),
        ...cssShadow(theme, 'level_01'),
        position: 'relative',
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(4),
        },
    },
}));

export function IconTooltip({className, iconType, content, backgroundColor, color = '#423F37', tooltipProps, PopperProps, ...props}: IconTooltipProps) {
    const classes = useStyles({backgroundColor});
    const [open, setOpen] = useState<boolean>(false);
    const handleTooltipClickAway = () => {
        open && setOpen(false);
    };
    const handleTooltipClick = (e) => {
        e.preventDefault();
        setOpen(open => !open);
    };

    return (
        <ClickAwayListener
            onClickAway={handleTooltipClickAway}>

            <MUITooltip
                classes={{tooltip: classes.tooltip}}
                title={content}
                PopperProps={{
                    disablePortal: true,
                    ...PopperProps,
                }}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                {...tooltipProps}>

                <div
                    className={clsx(classes.iconWrapper, className)}
                    onClick={handleTooltipClick}>

                    <DynamicIcon
                        className={classes.icon}
                        type={iconType}
                        color={color}
                        {...props}/>

                </div>

            </MUITooltip>

        </ClickAwayListener>
    );
}

export interface IconTooltipProps {
    className?: string,
    iconType: string,
    content: string,
    color?: string,
    backgroundColor?: string,
    tooltipProps?: MUITooltipProps,
    PopperProps?: PopperProps,
}

export default IconTooltip;
