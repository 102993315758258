import React from 'react';
import Image, {ImageProps} from "./Image";

export function ImageLarge(props: ImageLargeProps) {
    return (
        <Image {...props} size={'large'} />
    );
}

export interface ImageLargeProps extends Omit<ImageProps, 'size'> {
}

export default ImageLarge