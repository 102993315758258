import React, {useCallback, useState} from 'react';
import clsx from 'clsx';
import {CardElement} from '@stripe/react-stripe-js';
import {StripeCardElementOptions, StripeError} from '@stripe/stripe-js';
import {cssFgColor, cssText} from '@ohoareau/css-utils';
import {Trans} from 'react-i18next';
import useLuniiTranslation from '../hooks/useLuniiTranslation';
import Clickable from '../Clickable';
import DynamicIcon from '../nucleons/DynamicIcon';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {CardOwnerField, Checkbox} from '../atoms';
import {useFormContext} from 'react-hook-form';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(1),
    },
    form: {
        marginBottom: theme.spacing(3),
    },
    cardOwner: {
        marginBottom: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            marginBottom: theme.spacing(4),
        },
    },
    card: {
        padding: '18px',
        borderRadius: 8,
        color: '#423f37',
        border: '2px solid #D6D3CA !important',
        backgroundColor: '#FAF9F8',
        '&.invalid': {
            borderColor: '#F25129 !important',
        },
        '&.focus': {
            borderColor: '#FBBD2A !important',
        },
    },
    error: {
        ...cssText(theme, 'standard', 'caption'),
        color: '#F25129 !important',
        borderColor: '#F25129 !important',
    },
    reassurance: {
        display: 'flex',
    },
    reassuranceIcon: {
        display: 'block',
        marginRight: theme.spacing(2),
    },
    reassuranceContent: {
        width: '80%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#908977'),
        '& a, & a:hover': {
            ...cssFgColor(theme, 'standard', 'link'),
        },
    },
    title: {
        ...cssText(theme, 'standard', 'secondary_body_thick', undefined, undefined, '#908977'),
        marginBottom: 8,
    },
    checkBox: {
        marginBottom: theme.spacing(3),
    },
    checkBoxText: {
        ...cssText(theme, 'standard', 'button_2', undefined, undefined, 'link'),
        userSelect: 'none',
    },
}));

export function CreditCardForm({className, onCardComplete, save = false, onCardRegister}: CreditCardFormProps) {
    const classes = useStyles();
    const [cardComplete, setCardComplete] = useState<boolean>(false);
    const [error, setError] = useState<StripeError|undefined>();
    const {i18n, t} = useLuniiTranslation();
    const {register, errors} = useFormContext();
    const handleCardElementChange = useCallback((e: any) => {
        if (!error && e.error || error && !e.error) {
            setError(e.error);
        } else if (error && e.error) {
            error.code !== e.error.code && setError(e.error);
        }
        if (e.complete !== cardComplete) {
            setError(undefined);
            setCardComplete(e.complete);
            onCardComplete(e.complete);
        }
    }, [cardComplete, setCardComplete, error, setError]);
    const stripeCardOptions = {
        iconStyle: 'solid',
        style: {
            base: {
                iconColor: '#908977',
                color: '#423f37',
                fontSize: '16px',
                fontFamily: 'Nunito, sans-serif',
                fontWeight: 800,
                fontSmoothing: 'antialiased',
                ':-webkit-autofill': {
                    color: '#423F37',
                },
                '::placeholder': {
                    color: '#908977',
                },
                ':focus': {
                    color: '#423F37',
                },
                ':focus::placeholder': {
                    color: '#423f37',
                },
            },
            invalid: {
                iconColor: '#F25129',
                color: '#423f37',
            },
        },
        hidePostalCode: true,
        classes: {
            base: 'base',
            complete: 'complete',
            focus: 'focus',
            invalid: 'invalid',
        },
    } as unknown as StripeCardElementOptions;
    const x = {errors, register};
    const reassurance = (
        <Trans i18nKey={'credit_card_form_reassurance_body'} i18n={i18n}>
            ...<Clickable onClick={'https://stripe.com/docs/security/stripe'}/>
        </Trans>
    );
    const handleOnCardRegisterChange = (e: any) => {
        onCardRegister && onCardRegister(e?.target?.checked);
    };
    return (
        <div className={clsx(classes.root, className)}>
            <div className={classes.form}>
                <CardOwnerField required {...x} min={1} max={40} className={classes.cardOwner}/>
                <label> {/* label here is necessary to fix a focus bug */}
                    <CardElement className={classes.card} options={stripeCardOptions} onChange={handleCardElementChange}/>
                </label>
                {error && <span className={classes.error}>{error!.message}</span>}
            </div>
            {save && <Checkbox
                className={classes.checkBox}
                textClassName={classes.checkBoxText}
                id={'creditcardform_card_register'}
                name={'cardRegister'}
                onChange={handleOnCardRegisterChange}
                content={t('credit_card_form_register')}/>}
            <div className={classes.reassurance}>
                <DynamicIcon className={classes.reassuranceIcon} type={'stripe'} width={64}/>
                <div className={classes.reassuranceContent}>
                    <div className={clsx(classes.title, 'reassuranceTitle')}>{t('credit_card_form_reassurance_title')}</div>
                    <div className={'reassuranceBody'}>{reassurance}</div>
                </div>
            </div>
        </div>
    );
}

export interface CreditCardFormProps {
    className?: string,
    onCardComplete: (boolean) => void,
    save?: boolean,
    onCardRegister?: (boolean) => void,
}

export default CreditCardForm;
