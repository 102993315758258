import React from 'react';
import TextField, {TextFieldProps} from './TextField';

export function MessageField(props: MessageFieldProps) {
    return (
        <TextField name={'message'} {...props} />
    );
}

export interface MessageFieldProps extends TextFieldProps {}

export default MessageField