import React, {MouseEventHandler, useCallback, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {cssText} from '@ohoareau/css-utils';
import {Trans} from 'react-i18next';
import {Modal} from './Modal';
import useLuniiTranslation from '../hooks/useLuniiTranslation';
import {buttonifyFromProps} from '../hocs';
import {code_album_infos, CodeAlbumEnum, pack_album_infos} from '../types';
import {PackSelectList, ShortWarningMessage} from '../molecules';
import {ErrorPanel} from '../atoms';
import {DynamicIcon} from '../nucleons';
import {useLuniiNavigation, useLuniiUser} from '../hooks';
import {getTargetDevice} from '../../utils';

const useStyles = makeStyles((theme) => ({
    root: {},
    header: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(1),
        },
    },
    successIcon: {
        backgroundColor: '#1EB587',
        color: '#FFF',
        borderRadius: '50%',
        padding: 10,
        margin: theme.spacing(2, 0, 0, 0),
    },
    body: {
        ...cssText(theme, 'standard', 'body', undefined, undefined, '#716C5E'),
        '& strong': {
            ...cssText(theme, 'standard', 'body_thick', undefined, undefined, '#716C5E'),
        },
        '& >*:first-child': {
            margin: theme.spacing(0, 0, 2),
        },
    },
    packSelectList: {
        marginTop: 24,
    },
    error: {
        margin: theme.spacing(2, 0, 0, 0),
    },
}));

export function MyCodeAlbumModal({
    open,
    onClose,
    loading,
    codeAlbumInfos,
    confirmCodeActivation,
    codeAlbumActivated,
    errors,
}: MyCodeAlbumModalProps) {
    const classes = useStyles();
    const {t, i18n} = useLuniiTranslation();
    const {goLibrary} = useLuniiNavigation();
    const {user} = useLuniiUser();
    const [packSelected, setPackSelected] = useState<pack_album_infos | undefined>();

    const handleSubmit = useCallback(async () => {
        if (!codeAlbumInfos || !packSelected) return;
        const {productId, currency} = packSelected;
        const {countryCode, codeAlbumId} = codeAlbumInfos;
        await confirmCodeActivation(
            productId,
            currency,
            countryCode,
            codeAlbumId,
            packSelected.reference,
        );
    }, [packSelected]);
    const {Buttons: ModalActions} = buttonifyFromProps(
        {
            buttonLabel: t('button_label_cancel'),
            buttonTarget: onClose,
            buttonType: 'plain',
            button2Label: t('my_code_album_modal_validate'),
            button2Target: handleSubmit,
            button2Type: 'primary',
        },
        [`disabled=${loading}`, `disabled=${loading || !packSelected}`],
    );

    const {Buttons: SuccessActions} = buttonifyFromProps(
        {
            buttonLabel: t('my_code_album_modal_validated_back'),
            buttonTarget: onClose,
            buttonType: 'plain',
            button2Label: t('my_code_album_modal_go_to_library'),
            button2Target: (e: any) => goLibrary && goLibrary(e, packSelected?.deviceTarget),
            button2Type: 'primary',
        },
        [`disabled=${loading}`, `disabled=${loading}`],
    );

    const handleAlbumSelected = (index) => {
        if (codeAlbumInfos && typeof index === 'number') {
            setPackSelected(codeAlbumInfos.packs[index]);
        } else {
            setPackSelected(undefined);
        }
    };
    const target = getTargetDevice(packSelected?.deviceTarget);
    const body = (
        <div className={classes.body}>
            {codeAlbumActivated || !codeAlbumInfos ? (
                <div>{t(`product_subscription_modal_success_${target}`)}</div>
            ) : (
                <>
                    <div>
                        <Trans
                            i18n={i18n}
                            i18nKey={'my_code_album_modal_body_account'}
                            values={{email: user?.email}}
                            components={{1: <strong />}}
                        >
                            ...<span>...</span>...
                        </Trans>
                    </div>
                    <ShortWarningMessage
                        body={
                            <div>
                                {codeAlbumInfos?.type === CodeAlbumEnum.ONE
                                    ? t('my_code_album_modal_body_choice_one')
                                    : t('my_code_album_modal_body_choice_oneinmany')}
                            </div>
                        }
                        level="active"
                        iconType="infos"
                    />
                    <PackSelectList
                        className={classes.packSelectList}
                        items={codeAlbumInfos?.packs || []}
                        onAlbumSelected={handleAlbumSelected}
                    />
                </>
            )}
            {errors && <ErrorPanel error={errors} group={'codeAlbum'} />}
        </div>
    );

    const title = codeAlbumActivated ? (
        <>
            <DynamicIcon type={'check'} height={42} width={42} className={classes.successIcon} />{' '}
            <br />
            {t('my_code_album_modal_title_success')}
        </>
    ) : codeAlbumInfos?.type === CodeAlbumEnum.ONE ? (
        t('my_code_album_modal_title_one')
    ) : (
        t('my_code_album_modal_title_oneinmany')
    );

    return (
        <Modal
            className={classes.root}
            title={title}
            body={body}
            onCloseCallback={onClose}
            open={open}
            actions={
                codeAlbumActivated
                    ? SuccessActions && <SuccessActions />
                    : ModalActions && <ModalActions />
            }
            loading={loading}
        />
    );
}

export interface MyCodeAlbumModalProps {
    open: boolean;
    onClose: MouseEventHandler<HTMLButtonElement> | undefined;
    codeAlbumInfos?: code_album_infos;
    className?: string;
    loading?: boolean;
    confirmCodeActivation: Function;
    codeAlbumActivated: boolean | undefined;
    errors?: any;
}

export default MyCodeAlbumModal;
