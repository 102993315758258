import * as React from 'react';
function SvgToken(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width={props.width || 24}
            height={props.height || 24}
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.5 19c2.712 0 5.5-2.777 5.5-7s-2.788-7-5.5-7S4 7.777 4 12s2.788 7 5.5 7zm7.5-7c0-2.824-1.084-5.344-2.779-6.994 1.507.086 2.814 1.036 3.546 1.91C18.847 8.213 19.5 10.037 19.5 12c0 1.963-.653 3.787-1.733 5.084A5.56 5.56 0 0116 18.514a6.604 6.604 0 01-1.736.438C15.934 17.3 17 14.8 17 12zm-8 9v-.02C5.091 20.672 2 16.77 2 12c0-4.97 3.358-9 7.5-9H14c.682 0 1.354.111 2 .326 1.238.411 2.379 1.2 3.303 2.31C20.71 7.324 21.5 9.613 21.5 12s-.79 4.676-2.197 6.364c-.924 1.11-2.065 1.899-3.303 2.31A6.333 6.333 0 0114 21H9zm-2.25-9.005c0 .826.099 1.557.302 2.189v.001c.208.632.512 1.14.921 1.506.417.373.918.559 1.491.559.257 0 .512-.05.763-.15.253-.097.473-.234.653-.412.157-.139.26-.319.315-.527.05-.196.067-.394.046-.592a1.48 1.48 0 00-.143-.551.767.767 0 00-.383-.383c-.216-.093-.428-.015-.594.104l-.01.009c-.109.087-.214.15-.317.19a.916.916 0 01-.284.051.748.748 0 01-.405-.105.862.862 0 01-.297-.335c-.178-.336-.28-.846-.28-1.554 0-.702.102-1.204.28-1.532v-.002a.878.878 0 01.299-.343.734.734 0 01.403-.107c.202 0 .413.077.638.261.154.128.361.212.58.112l.006-.003.007-.003a.81.81 0 00.347-.403 1.716 1.716 0 00.079-1.163c-.06-.21-.17-.392-.33-.535a1.834 1.834 0 00-.664-.392 2.025 2.025 0 00-.709-.135c-.573 0-1.074.186-1.49.559-.41.366-.714.875-.922 1.506-.203.626-.302 1.354-.302 2.18z"
                fill={props.color || 'currentColor'}
            />
        </svg>
    );
}
export default SvgToken;
