import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {cssFgColor} from '@ohoareau/css-utils';
import clsx from 'clsx';
import {DynamicIcon} from '../../ui';

const useStyles = makeStyles((theme) => ({
    root: ({size}: Partial<BulletIconProps>) => ({
        ...cssFgColor(theme, 'standard', 'bullet'),
        height: size === 'big' ? 32 : 24,
        width: size === 'big' ? 32 : 24,
        borderRadius: size === 'big' ? 16 : 12,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }),
}));

export interface BulletIconProps extends React.HTMLAttributes<HTMLElement> {
    iconType: string;
    size?: 'big' | 'small';
}

export function BulletIcon({iconType, size = 'big', className = '', ...props}: BulletIconProps) {
    const classes = useStyles({size});
    const width = size === 'big' ? 24 : 20;
    const height = size === 'big' ? 24 : 20;
    return (
        <div
            className={clsx(classes.root, className)}
            {...props}
        >
            <DynamicIcon
                color="currentColor"
                type={iconType}
                width={width}
                height={height}
            />
        </div>
    );
}

export default BulletIcon;
