import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import React, {useMemo} from 'react';
import {cssText} from '@ohoareau/css-utils';
import {useFormContext} from 'react-hook-form';
import SvgColoredGift from '../../ui/images/icons/ColoredGift';
import {convertPrice, useLuniiTranslation, PriceField, FieldSet} from '../../ui';
import {convertCurrency} from '../../utils';

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
    },
    title: {
        ...cssText(theme, 'standard', 'title_2'),
        marginBottom: theme.spacing(1),
    },
    svgContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            alignItems: 'flex-start',
        },
        ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#716C5E'),
    },
    svg: {
        marginRight: theme.spacing(1),
    },
    infos: {
        flex: 1,
    },
    content: {
        display: 'flex',
        margin: theme.spacing(3, 0, 2, 0),
        gap: theme.spacing(0, 2),
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'space-between',
        },
    },
    amount: {
        border: '2px solid #E3E1DB',
        borderRadius: theme.spacing(1),
        width: '100%',
        maxWidth: 70,
        height: 40,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...cssText(theme, 'standard', 'button_2', undefined, undefined, '#908977'),
        cursor: 'pointer',
        [theme.breakpoints.down('xs')]: {
            flex: 1,
        },
    },
    active: {
        backgroundColor: '#FFF9EC',
        border: '2px solid #FBBD2A',
        color: '#896717',
    },
    hidden: {
        display: 'none',
    },
    suffixClassName: {
        display: (props: any) => props.displaySuffix || 'inline',
    },
}));

export function GiftCardAmountChoice({
    className = '',
    currency,
    emptyValueErrors,
    errorClassName = '',
}: GiftCardAmountProps) {
    const {register, errors, watch, setValue} = useFormContext();
    const x = {errors, register};
    register('giftCardAmount.proposed');
    const giftCardAmount = watch('giftCardAmount');
    const classes = useStyles({displaySuffix: !giftCardAmount?.custom && 'none'});
    const {t} = useLuniiTranslation();
    const proposalAmounts: any[] = useMemo(
        () =>
            Array.from({length: 4}, (_, i) => {
                const amount = Number(t(`gift_cards_visual_amount_proposal_${i + 1}`));
                return !isNaN(amount) && amount >= 1 && amount;
            }).filter((x) => !!x),
        [],
    );
    const handleAmountClick = (index: number) => {
        setValue('giftCardAmount', {proposed: proposalAmounts[index], custom: undefined});
    };
    const handleOnCustomBlur = (value: any) => {
        setValue('giftCardAmount', {proposed: undefined, custom: value});
    };
    const handleOnCustomFocus = () => {
        setValue('giftCardAmount.proposed', undefined);
    };
    return (
        <div className={clsx(classes.root, className)}>
            <div className={classes.title}>{t('gift_cards_amount_title')}</div>
            <div className={classes.svgContainer}>
                <SvgColoredGift
                    className={classes.svg}
                    width={54}
                    height={50}
                />
                <div className={classes.infos}>{t('gift_cards_visual_amount_infos')}</div>
            </div>
            <div className={classes.content}>
                {proposalAmounts.map((amount: any, index: number) => (
                    <div
                        key={`gift_card_amount_${index}`}
                        className={clsx(
                            classes.amount,
                            amount === giftCardAmount?.proposed && classes.active,
                        )}
                        onClick={() => handleAmountClick(index)}
                    >
                        {convertPrice(Math.round(amount * 100), currency, true)}
                    </div>
                ))}
            </div>
            <FieldSet>
                <PriceField
                    name="giftCardAmount"
                    subName="custom"
                    {...x}
                    fullWidth
                    suffixClassName={classes.suffixClassName}
                    min={1}
                    max={10000}
                    onFocus={handleOnCustomFocus}
                    onBlur={handleOnCustomBlur}
                    currency={convertCurrency(currency)}
                />
            </FieldSet>
            {emptyValueErrors?.amount && (
                <div className={errorClassName}>{t('gift_cards_error_giftcardamount')}</div>
            )}
        </div>
    );
}

export interface GiftCardAmountProps {
    className?: string;
    currency: string;
    emptyValueErrors?: any;
    errorClassName?: string;
}

export default GiftCardAmountChoice;
