import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';
import {cssText} from '@ohoareau/css-utils';
import {useFormContext} from 'react-hook-form';
import {TextField, useLuniiTranslation, EmailField, FieldSet, Checkbox} from '../../ui';

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
    },
    title: {
        ...cssText(theme, 'standard', 'title_2'),
        marginBottom: theme.spacing(1),
    },
    charactersInfos: {
        ...cssText(theme, 'standard', 'caption', undefined, undefined, '#908977'),
        width: '100%',
        textAlign: 'end',
    },
    checkBox: {
        marginTop: theme.spacing(1.5),
        ...cssText(theme, 'standard', 'button_2', undefined, undefined, '#716C5E'),
        fontWeight: 700,
    },
}));

export function GiftCardReceiverChoice({
    className = '',
    emptyValueErrors,
    errorClassName = '',
}: GiftCardReceiverChoiceProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const {register, errors, watch} = useFormContext();
    const x = {errors, register};
    const giftCardReceiverName = watch('giftCardReceiverName') || '';
    const giftCardNoReceiver = watch('giftCardNoReceiver');
    return (
        <div className={clsx(classes.root, className)}>
            <div className={classes.title}>{t('gift_cards_receiver_title')}</div>
            <FieldSet>
                <TextField
                    name="giftCardReceiverName"
                    {...x}
                    fullWidth
                    definition={{
                        pattern: {
                            value: /^.{0,20}$/i,
                            message: t('constraints_too_many_characters'),
                        },
                    }}
                />
                <div className={classes.charactersInfos}>
                    {giftCardReceiverName.length}/20 {t('characters')}
                </div>
                {emptyValueErrors?.receiverName && (
                    <div className={errorClassName}>
                        {t('gift_cards_error_giftcardreceivername')}
                    </div>
                )}
                {!giftCardNoReceiver && (
                    <EmailField
                        name="giftCardReceiverEmail"
                        {...x}
                        fullWidth
                    />
                )}
                {!giftCardNoReceiver && emptyValueErrors?.receiverEmail && (
                    <div className={errorClassName}>
                        {t('gift_cards_error_giftcardreceiveremail')}
                    </div>
                )}
                <Checkbox
                    alignItems="flex-start"
                    className={classes.checkBox}
                    id="giftCardNoReceiver"
                    name="giftCardNoReceiver"
                    {...x}
                    content={t('gift_cards_receiver_no_receiver')}
                />
            </FieldSet>
        </div>
    );
}

export interface GiftCardReceiverChoiceProps {
    className?: string;
    emptyValueErrors?: any;
    errorClassName?: string;
}

export default GiftCardReceiverChoice;
