import React from 'react';
import {cssText} from '@ohoareau/css-utils';
import DividingLine from '../atoms/DividingLine';
import Job from '../molecules/Job';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import ForceTheme from "../ForceTheme";
import Row from "../Row";
import {WithItems, module_title, module_text} from "../types";

const useStyles = makeStyles(theme => ({
    root: {
        ...cssText(theme, 'standard', 'body'),
    },
    title: {
        ...cssText(theme, 'standard', 'large_title'),
        marginBottom: theme.spacing(0.5),
        [theme.breakpoints.down('sm')]: {
            ...cssText(theme, 'standard', 'title_1'),
            marginBottom: theme.spacing(1)
        }
    },
    header: {
        margin: '32px 0',
    },
}));

export function Jobs({title, text, items}: JobsProps) {
    const classes = useStyles();
    return (
        <Row className={classes.root} justify={'flex-start'}>
            <Grid item container lg={12} md={12} xs={12}>
                <div className={classes.header}>
                    <div className={classes.title}>{title}</div>
                    <div>{text}</div>
                </div>
            </Grid>
            {items && items.map((item, i) => (
                <Grid item container key={i} lg={6} md={6} xs={12} direction={'column'}>
                    <ForceTheme theme={'default'}>
                        <Job  {...item} />
                    </ForceTheme>
                    <DividingLine spacing={4} />
                </Grid>
            ))}
        </Row>
    );
}

export interface JobsProps extends WithItems {
    title?: module_title,
    text?: module_text,
}

export default Jobs
