import React, {useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import {cssFont, cssText} from '@ohoareau/css-utils';
import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';
import {MosaicCardItem, MosaicCardItemDisplayEnum, NavBarRootModeEnum, Row,
    Spinner, useLuniiEventTracking, useLuniiPage, useLuniiTranslation, useLuniiUser} from '../../../ui';
import {usePageTracking, Illustration, useUserWishlist, PageAppBarModule,
    WishlistEmpty, useDeleteUserWishlist, getTargetDevice} from '../../..';
import * as propertyMappers from '../../../services/property-mappers';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingBottom: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            paddingBottom: theme.spacing(5),
        },
    },
    header: {
        marginTop: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(5),
        },
    },
    overline: {
        ...cssText(theme, 'standard', 'overline_large_title', undefined, undefined, '#5F6769'),
        marginTop: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            ...cssFont(theme, 'standard', 'overline_title_1'),
        },
    },
    title: {
        ...cssText(theme, 'standard', 'large_title', undefined, undefined, '#2C3637'),
    },
    images: {
        marginTop: theme.spacing(3),
    },
    image: {
        cursor: 'pointer',
        borderRadius: 8,
        opacity: .4,
        border: '1px solid transparent',
        transition: 'opacity .2s, border-color .2s',
    },
    fahImage: {
        opacity: 1,
        borderColor: '#47C7F5',
        cursor: 'default',
    },
    flamImage: {
        opacity: 1,
        borderColor: '#FBBD8E',
        cursor: 'default',
    },
    emptyWishlist: {
        margin: theme.spacing(8, 0),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(5, 0, 3, 0),
        },
    },
    grid: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
        gap: 24,
        [theme.breakpoints.down('md')]: {
            gridTemplateColumns: '1fr 1fr 1fr 1fr',
            gap: 16
        },
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: '1fr 1fr',
            gap: 8
        },
        '& > *': {
            width: '100%',
            minWidth: '100%',
            maxWidth: '100%',
        }
    },
    wishlistGrid: {
        margin: theme.spacing(8, 0, 4, 0),
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gap: '32px 24px',
        [theme.breakpoints.down('md')]: {
            gridTemplateColumns: '1fr 1fr',
            gap: '24px 16px',
        },
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(5, 0, 4, 0),
            gridTemplateColumns: '1fr',
            gap: '24px 0px',
        },
        '& > *': {
            width: '100%',
            minWidth: '100%',
            maxWidth: '100%',
        }
    }
}));

export function UserWishlistScreen() {
    usePageTracking(true, 'bibliotheque');
    const {user, ownsPackReference} = useLuniiUser();
    const handleEventTracking = useLuniiEventTracking();
    const {wishlist, loading, refetch} = useUserWishlist({locale: user?.locale});
    const [deleteFromUserWishlist, {loading: deleteLoading}] = useDeleteUserWishlist(() => {
        refetch();
    });
    const {...p} = useLuniiPage();
    propertyMappers.menu((p as any).menu[p?.locale || ''], p, 'menu');
    const menuImage = ((p.menuImage as any) || {})[p?.locale || ''];
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const menu = [
        {
            id: 'fah',
            image: 'multi_product_fah_picture',
            alt: t('catalog_fah'),
        },
        {
            id: 'flam',
            alt: t('catalog_flam'),
            image: 'multi_product_flam_picture',
        },
    ];
    const [activeMenu, setActiveMenu] = useState<string[]>([menu[0].id]);
    const filteredWishlist = wishlist
        .filter((audiobook: any) => {
            const deviceTarget = getTargetDevice(audiobook.deviceTarget, false);
            return deviceTarget === activeMenu[0] && audiobook.target && !ownsPackReference(audiobook.reference);
        });
    const handleWishlistTracking = (audiobook: any) => {
        handleEventTracking(
            {
                categorie: 'ecommerce',
                action: 'retrait_liste_souhaits',
                libelle: `liste_souhaits`,
                valeur: Math.round(audiobook.priceExclTax / 100) * 100,
            },
            audiobook,
        );
    };
    useEffect(() => {
        refetch();
    }, [refetch, activeMenu]);
    return (
        <div className={classes.root}>
            <PageAppBarModule
                menu={p?.menu || []}
                menuImage={menuImage}
                rootMode={NavBarRootModeEnum.Wishlist}
            />
            {/* Header */}
            <Row className={classes.header} padding='none' justify='flex-start'>
                <Grid lg={6} md={7} xs={12}>
                    <div className={classes.overline}>{t('wishlist_heading_pretitle', {count: filteredWishlist.length})}</div>
                    <div className={classes.title}>{t('wishlist_heading_title')}</div>
                </Grid>
            </Row>
            {/* Images menu */}
            <Row padding='none' justify='flex-start' className={classes.images}>
                <Grid lg={12} md={12} xs={12} className={classes.grid}>
                    {menu.map(({id, image, alt}) => (
                        <Illustration
                            className={clsx(classes.image, id === activeMenu[0] && (classes as any)[`${id}Image`])}
                            alt={alt}
                            onClick={() => id !== activeMenu[0] && setActiveMenu((prevState) => [id, prevState[1]])}
                            name={image}/>
                    ))}
                </Grid>
            </Row>
            {/* Empty wishlist */}
            {!loading && !deleteLoading && !filteredWishlist?.length && (
                <Row padding='none' margins='none'>
                    <Grid lg={4} md={6} xs={12}>
                        <WishlistEmpty className={classes.emptyWishlist} mode={activeMenu[0] as 'fah' | 'flam'}/>
                    </Grid>
                </Row>
            ) || false}
            {/* Library */}
            {!loading && !deleteLoading && filteredWishlist?.length && (
                <Row padding='none' justify='flex-start'>
                    <Grid lg={12} md={12} xs={12} className={classes.wishlistGrid}>
                        {filteredWishlist.map((audiobook: any, index: number) => {
                            const item = {
                                ...audiobook,
                                buttonTarget: `${audiobook.buttonTarget}?libelle=liste_souhaits`
                            };
                            return (
                                <MosaicCardItem
                                    key={Math.random()}
                                    imageLoading={index > 10 ? 'lazy' : 'eager'}
                                    product={item}
                                    display={MosaicCardItemDisplayEnum.Row}
                                    onWishlistToggleClick={() => deleteFromUserWishlist({audiobookId: audiobook.objectId, locale: p?.locale})}
                                    onWishlistToggleClickCb={() => handleWishlistTracking(audiobook)}
                                />
                            );
                        })}
                    </Grid>
                </Row>
            ) || false}
            {(loading || deleteLoading) && <Spinner/> || false /* loader */}
        </div>
    );
}

export default UserWishlistScreen;
