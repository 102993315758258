import React, {useState} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import {cssFgColor, cssFont, cssText} from '@ohoareau/css-utils';
import {Typography} from '@material-ui/core';
import {AlbumCarroussel, digital_album_product, ExtractCard, Img, Row, useLuniiEventTracking, useLuniiPage,
    useLuniiTranslation} from '../../../ui';
import {convertLangToCatalog, getTargetDevice, purifyHtml} from '../../../utils';
import {Link, ProductCasting, ProductTaxonomy, ProductSubscription, AudiobookProductButton, ProductStoriesModal, ProductCreditsModal} from '../..';
import {useUserWishlist} from '../../../hooks';

const getModeColor = (mode: string) => {
    switch (mode) {
        case 'flam':
            return '#FCD1B1';
        case 'fah': case 'fah_flam': default:
            return '#C8E7F9';
    }
}

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            overflow: 'hidden',
        },
    },
    grid: {
        alignSelf: 'stretch',
    },
    stickyContainer: {
        width: '100%',
        height: '100%',
        position: 'relative',
    },
    sticky: {
        position: '-webkit-sticky',
        // @ts-ignore
        position: 'sticky',
        top: '128px',
        left: 0,
    },
    leftContent: {
        zIndex: 2,
        [theme.breakpoints.down('sm')]: {
            position: 'relative',
        },
    },
    decorator: {
        position: 'absolute',
        zIndex: -1,
        backgroundColor: (props: any) => getModeColor(props.mode),
        top: '-11vw',
        left: '-34vw',
        width: '55vw',
        height: '44vw',
        borderRadius: '0 0 100% 0',
        [theme.breakpoints.down('md')]: {
            top: '-20vw',
            left: '-19vw',
            width: '58vw',
            height: '51vw',
        },
        [theme.breakpoints.down('sm')]: {
            zIndex: 1,
            top: '-18vw',
            width: '200vw',
            height: '100vw',
            right: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '0 0 100vw 100vw',
        },
    },
    imageContainer: {
        lineHeight: 0,
    },
    image: {
        boxShadow: '0px 4px 16px 0px rgba(66, 63, 55, 0.10)',
        borderRadius: '28px !important',
    },
    actions: {
        display: 'flex',
        marginTop: theme.spacing(2),
        '&>*': {
            flex: 1,
        },
    },
    desktopSubscription: {
        display: 'block',
        marginTop: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    subtitle: {
        ...cssText(theme, 'standard', 'overline_large_title', undefined, undefined, '#7C878B'),
        [theme.breakpoints.down('sm')]: {
            ...cssFont(theme, 'standard', 'overline_title_1'),
            marginTop: theme.spacing(4),
        },
    },
    title: {
        ...cssText(theme, 'standard', 'large_title', undefined, undefined, '#2C3637'),
        [theme.breakpoints.down('sm')]: {
            ...cssFont(theme, 'standard', 'title_1'),
        },
    },
    taxonomy: {
        marginTop: theme.spacing(3),
        maxWidth: 460,
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
        },
    },
    description: {
        margin: theme.spacing(5, 0),
        ...cssText(theme, 'standard', 'body', undefined, undefined, '#5F6769'),
        '& a': {
            ...cssFgColor(theme, 'standard', 'link'),
            fontWeight: 700,
        },
        '& span': {
            ...cssFont(theme, 'standard', 'body_thick'),
            fontFamily: 'inherit !important',
        },
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(4, 0),
        },
    },
    previews: {
        margin: theme.spacing(5, 0),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(4, 0),
        },
    },
    casting: {
        marginBottom: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(4),
        },
    },
    mobileSubscription: {
        display: 'none',
        marginTop: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        },
    }
}));

export function DigitalAlbumProduct({product}: DigitalAlbumProductProps) {
    const mode: 'fah_flam' | 'fah' | 'flam' | undefined = getTargetDevice(product.deviceTarget);
    const {name, image} = product;
    const classes = useStyles({mode});
    const {t} = useLuniiTranslation();
    const {...p} = useLuniiPage();
    const {wishlist} = useUserWishlist({locale: convertLangToCatalog(p?.locale || '')});
    const handleEventTracking = useLuniiEventTracking();
    const [openStoriesModal, setOpenStoriesModal] = useState<boolean>(false);
    const [openCreditsModal, setOpenCreditsModal] = useState<boolean>(false);
    if (!product) return null;
    // todo wishlist has to be optimized
    const handleTracking = (pro: any, status: any = undefined) => {
        handleEventTracking(
            {
                categorie: 'ecommerce',
                action: status ? (status.added && 'ajout_liste_souhaits') || (status.deleted && 'retrait_liste_souhaits'): wishlist.find((audiobook: any) => audiobook.reference === pro?.reference) ? 'retrait_liste_souhaits' : 'ajout_liste_souhaits',
                libelle: `nos_histoires_produit`,
                valeur: Math.round(product.priceExclTax / 100) * 100,
            },
            product,
        );
    }
    const recommendations = (product.recommendations || []).map((recommendation: any) => ({
        product: {
            ...recommendation.product,
            buttonTarget: `${recommendation.product.buttonTarget}?libelle=cross_selling_page_produit`,
        },
        onWishlistToggleClickCb: () => handleTracking(recommendation.product),
    })).filter((x: any) => !!x);
    return (
        <div className={classes.root}>
            <Row alignItems='flex-start'>
                <Grid lg={4} md={4} xs={12} className={classes.grid}>
                    <div className={classes.stickyContainer}>
                        <div className={classes.sticky}>
                            <div className={classes.leftContent}>
                                {image && <div className={classes.imageContainer}><Img loading='eager' className={classes.image} {...image} alt={name}/></div> || false}
                                <div className={classes.actions}>
                                    <AudiobookProductButton product={product} wishlist={wishlist} onWishlistChangeCallback={(status: any) => handleTracking(product, status)}/>
                                </div>
                                <ProductSubscription className={classes.desktopSubscription} mode={mode}/>
                            </div>
                            <div className={classes.decorator}/>
                        </div>
                    </div>
                </Grid>
                <Grid lg={1} md={1} className={classes.grid}/>
                <Grid lg={6} md={7} xs={12} className={classes.grid}>
                    <div className={classes.subtitle}>{product.subtitle}</div>
                    <Typography variant='h1' className={classes.title}>{name}</Typography>
                    <ProductTaxonomy className={classes.taxonomy} product={product}/>
                    {/* eslint-disable-next-line react/no-danger */}
                    <div className={classes.description} dangerouslySetInnerHTML={{__html: purifyHtml(product.description || '')}}/>
                    {product.stories && <Link
                        text={t('product_stories_modal_link', {stories: product.stories.length})}
                        size='large'
                        icon='next'
                        iconPosition='right'
                        url={setOpenStoriesModal}/> || false}
                    {product.previews && <ExtractCard className={classes.previews} urls={product.previews} /> || false}
                    <ProductCasting className={classes.casting} product={product}/>
                    {product.credits && <Link
                        text={t('product_credits_modal_link')}
                        size='large'
                        icon='next'
                        iconPosition='right'
                        url={setOpenCreditsModal}/> || false}
                    <ProductSubscription className={classes.mobileSubscription} mode={mode}/>
                </Grid>
                <Grid lg={1} className={classes.grid}/>
            </Row>
            {recommendations.length > 0 && (
                <AlbumCarroussel
                    backgroundColor='#F9FAFA'
                    title={t('product_recommendations_title')}
                    text={t('product_recommendations_subtitle')}
                    items={recommendations}
                />
            )}
            <ProductStoriesModal product={product} open={openStoriesModal} setOpen={setOpenStoriesModal}/>
            <ProductCreditsModal product={product} open={openCreditsModal} setOpen={setOpenCreditsModal}/>
        </div>
    );
}

export interface DigitalAlbumProductProps {
    product: digital_album_product;
}

export default DigitalAlbumProduct;
