import React, {useCallback, useState} from 'react';
import SvgSearch from "../images/icons/Search";
import {InputBase} from "@material-ui/core";
import {cssText} from '@ohoareau/css-utils';
import SvgClose from "../images/icons/Close";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        padding: '12px 24px',
        width: '100%',
        height: 48,
        background: '#FFFFFF',
        border: '2px solid #E3E1DB',
        boxSizing: 'border-box',
        borderRadius: 32,
        alignItems: 'center',
        [theme.breakpoints.up('lg')]: {
            width: '50%',
        },
    },
    icon: {
        color: '#A79F8E',
        display: 'flex',
        alignItems: 'center',
        paddingRight: 8,
    },
    field: {
        flex: 1,
        ...cssText(theme, 'standard', 'body'),
    },
    inputField: {
        '&::placeholder': {
            opacity: 0.7,
        }
    },
    close: {
        cursor: 'pointer',
    }
}));

export function SearchBar({placeholder, defaultFocus = false, onClear}: SearchBarProps) {
    const classes = useStyles();
    const [query, setQuery] = useState('');
    const handleChange = useCallback(e => setQuery(e.target.value), [setQuery]);
    const handleClear = useCallback(() => {
        setQuery('');
        onClear && onClear();
    }, [setQuery]);
    const handleKeyUp = useCallback(e => (e.keyCode === 27) && handleClear(), [handleClear]);
    return (
        <div className={classes.root}>
            <div className={classes.icon}>
                <SvgSearch color={'currentColor'}/>
            </div>
            <InputBase
                placeholder={placeholder}
                className={classes.field}
                value={query}
                onChange={handleChange}
                inputProps={{className: classes.inputField}}
                autoFocus={!!defaultFocus}
                onKeyUp={handleKeyUp}
            />
            {!!query && (
                <SvgClose color={'currentColor'} onClick={handleClear} className={classes.close} />
            )}
        </div>
    );
}

export interface SearchBarProps {
    defaultFocus?: boolean,
    onClear?: Function,
    placeholder?: string,
}

export default SearchBar
