export default {
    "brand-primary-300": {
        "name": "Lunii Orange (Brand Primary / 300)",
        "font_color_extra_large_title": "#503c0d",
        "font_color_large_title": "#503c0d",
        "font_color_large_title_bold": "#fbbd2a",
        "font_color_title_1": "#503c0d",
        "font_color_title_2": "#503c0d",
        "font_color_overline_large_title": "#896717",
        "font_color_button_1": "#423f37",
        "font_color_button_1_primary": "#503c0d",
        "font_color_button_1_primary_hover": "#503c0d",
        "font_color_button_1_primary_disabled": "#ca9722",
        "font_color_button_1_plain": "#2762e9",
        "font_color_button_1_plain_hover": "#2762e9",
        "font_color_button_1_plain_disabled": "#b8b3a4",
        "font_color_button_1_destructive": "#c04020",
        "font_color_button_1_destructive_hover": "#c04020",
        "font_color_button_1_destructive_disabled": "#b8b3a4",
        "font_color_button_1_secondary": "#423f37",
        "font_color_button_1_secondary_hover": "#423f37",
        "font_color_button_1_secondary_disabled": "#b8b3a4",
        "font_color_button_1_tertiary": "#2762e9",
        "font_color_button_1_tertiary_hover": "#749dfc",
        "font_color_button_1_tertiary_disabled": "#b8b3a4",
        "font_color_button_1_tertiary_clicked": "#173a8a",
        "font_color_button_1_tertiary_alert": "#f25129",
        "font_color_button_1_tertiary_alert_hover": "#fc7958",
        "font_color_button_1_tertiary_alert_disabled": "#b8b3a4",
        "font_color_button_1_tertiary_alert_clicked": "#722613",
        "font_color_button_1_destructive_clicked": "#722613",
        "font_color_button_1_default": "#423f37",
        "font_color_button_1_default_hover": "#423f37",
        "font_color_button_1_default_disabled": "#777676",
        "font_color_button_2": "#423f37",
        "font_color_button_2_primary": "#503c0d",
        "font_color_button_2_primary_hover": "#503c0d",
        "font_color_button_2_primary_disabled": "#ca9722",
        "font_color_button_2_plain": "#2762e9",
        "font_color_button_2_plain_hover": "#2762e9",
        "font_color_button_2_plain_disabled": "#b8b3a4",
        "font_color_button_2_destructive": "#c04020",
        "font_color_button_2_destructive_hover": "#c04020",
        "font_color_button_2_destructive_disabled": "#b8b3a4",
        "font_color_button_2_secondary": "#423f37",
        "font_color_button_2_secondary_hover": "#423f37",
        "font_color_button_2_secondary_disabled": "#b8b3a4",
        "font_color_button_2_tertiary": "#2762e9",
        "font_color_button_2_tertiary_hover": "#749dfc",
        "font_color_button_2_tertiary_disabled": "#a79f8e",
        "font_color_button_2_tertiary_clicked": "#173a8a",
        "font_color_button_2_tertiary_alert": null,
        "font_color_button_2_tertiary_alert_hover": null,
        "font_color_button_2_tertiary_alert_disabled": null,
        "font_color_button_2_tertiary_alert_clicked": null,
        "font_color_button_2_destructive_clicked": "#722613",
        "font_color_button_2_default": "#423f37",
        "font_color_button_2_default_hover": "#423f37",
        "font_color_button_2_default_disabled": "#777676",
        "font_color_body_thick": "#503c0d",
        "font_color_body": "#4f3b0c",
        "font_color_secondary_body_thick": "#503c0d",
        "font_color_secondary_body": "#896717",
        "font_color_overline_title_1": "#896717",
        "font_color_button_3": "#423f37",
        "font_color_overline_title_2": "#896717",
        "font_color_caption": "#4f3b0c",
        "font_color_footnote": "#ae821d",
        "font_color_icon": "#423f37",
        "font_color_icon_inactive": "#c8c3b8",
        "font_color_status_default": "#493300",
        "font_color_status_message": "#493300",
        "font_color_status_alert": "#510506",
        "font_color_status_positive": "#0c3421",
        "font_color_link": "#2762e9",
        "font_color_bullet": "#2c3637",
        "background_color_clear": "#fccd5f",
        "background_color_primary": "#fbbd2a",
        "background_color_primary_hover": "#fccd5f",
        "background_color_primary_disabled": "#fddd94",
        "background_color_primary_clicked": "#fbbd2a",
        "background_color_plain": "#e6eeff",
        "background_color_plain_hover": "#d4e1fe",
        "background_color_plain_disabled": "#e3e1db",
        "background_color_plain_clicked": "#c1d3fe",
        "background_color_destructive": "#fee9e4",
        "background_color_destructive_hover": "#fed9cf",
        "background_color_destructive_disabled": "#e3e1db",
        "background_color_destructive_clicked": "#fec6b8",
        "background_color_secondary": "#ffffff",
        "background_color_secondary_hover": "#ffffff",
        "background_color_secondary_disabled": "#e1e1e1",
        "background_color_secondary_clicked": "#ffffff",
        "background_color_tertiary": null,
        "background_color_tertiary_hover": null,
        "background_color_tertiary_disabled": null,
        "background_color_tertiary_clicked": null,
        "background_color_tertiary_alert": null,
        "background_color_tertiary_alert_hover": null,
        "background_color_tertiary_alert_disabled": null,
        "background_color_tertiary_alert_clicked": null,
        "background_color_default": "#d6d3ca",
        "background_color_default_hover": null,
        "background_color_default_disabled": null,
        "background_color_default_clicked": null,
        "background_color_footer": "#dbf1f9",
        "background_color_danger": "#fdb19e",
        "background_color_positive": "#25dda6",
        "background_color_interactive": "#abc4fd",
        "background_color_status_default": "#fef0ce",
        "background_color_status_message": "#fef0ce",
        "background_color_status_alert": "#fccdce",
        "background_color_status_positive": "#d5f5e6",
        "background_color_active": "#fef0ce",
        "background_color_active_hover": "#fef0ce",
        "background_color_active_disabled": "#e1e1e1",
        "background_color_active_clicked": null,
        "background_color_inactive": null,
        "background_color_inactive_hover": "#fef0ce",
        "background_color_inactive_disabled": "#e1e1e1",
        "background_color_inactive_clicked": null,
        "background_color_bullet": "#c8e7f9",
        "border_buttons": null,
        "border_buttons_primary": null,
        "border_buttons_plain": null,
        "border_buttons_destructive": null,
        "border_buttons_secondary": "2px solid #E3E1DB",
        "border_buttons_tertiary": null,
        "border_buttons_tertiary_alert": null,
        "border_buttons_default": null,
        "border_radius_buttons": 48,
        "border_radius_blocks": "32px",
        "border_radius_blocks_mobile": "24px 16px",
        "border_radius_push": "16px",
        "border_tags": null,
        "border_radius_tags": 20,
        "border_standard": null,
        "border_radius_standard": 8,
        "shadow_standard": "0px 2px 4px rgba(42, 41, 40, 0.15)",
        "shadow_buttons": "0px 2px 0px #363533",
        "shadow_level_00": null,
        "shadow_level_01": "0px 4px 8px rgba(66, 63, 55, 0.1), 0px -2px 8px rgba(66, 63, 55, 0.02)",
        "shadow_level_02": "0px 6px 12px rgba(66, 63, 55, 0.1), 0px -2px 12px rgba(66, 63, 55, 0.02)",
        "shadow_level_03": "0px 8px 24px rgba(66, 63, 55, 0.1), 0px -2px 24px rgba(66, 63, 55, 0.02)",
        "shadow_level_04": "0px 16px 32px rgba(66, 63, 55, 0.1), 0px -2px 4px rgba(66, 63, 55, 0.04)",
        "shadow_level_05": "0px 16px 40px rgba(66, 63, 55, 0.1), 0px -4px 40px rgba(66, 63, 55, 0.04)",
        "shadow_level_06": "0px 24px 48px rgba(66, 63, 55, 0.1), 0px -4px 28px rgba(66, 63, 55, 0.04)",
        "typos_common_font_style": "normal",
        "typos_common_line_height": "150%",
        "typos_common_font_weight": 400,
        "typos_standard_common_font_family": "'Nunito', sans-serif",
        "typos_standard_common_letter_spacing": 0.25,
        "typos_standard_extra_large_title_font_size": 64,
        "typos_standard_extra_large_title_mobile_font_size": 48,
        "typos_standard_extra_large_title_font_weight": 800,
        "typos_standard_large_title_font_size": 32,
        "typos_standard_extra_large_title_mobile_font_weight": 800,
        "typos_standard_large_title_font_weight": 800,
        "typos_standard_title_1_font_size": 24,
        "typos_standard_title_1_font_weight": 800,
        "typos_standard_title_2_font_size": 20,
        "typos_standard_title_2_font_weight": 800,
        "typos_standard_overline_large_title_font_size": 16,
        "typos_standard_overline_large_title_font_weight": 600,
        "typos_standard_overline_large_title_text_transform": "uppercase",
        "typos_standard_button_1_font_size": 16,
        "typos_standard_button_1_font_weight": 700,
        "typos_standard_button_2_font_size": 14,
        "typos_standard_button_2_font_weight": 700,
        "typos_standard_body_thick_font_size": 16,
        "typos_standard_body_thick_font_weight": 800,
        "typos_standard_body_font_size": 16,
        "typos_standard_body_font_weight": 600,
        "typos_standard_secondary_body_thick_font_size": 14,
        "typos_standard_secondary_body_thick_font_weight": 800,
        "typos_standard_secondary_body_font_size": 14,
        "typos_standard_secondary_body_font_weight": 600,
        "typos_standard_overline_title_1_font_size": 14,
        "typos_standard_overline_title_1_font_weight": 600,
        "typos_standard_overline_title_1_text_transform": "uppercase",
        "typos_standard_button_3_font_size": 12,
        "typos_standard_button_3_font_weight": 700,
        "typos_standard_button_3_text_transform": "uppercase",
        "typos_standard_overline_title_2_font_size": 12,
        "typos_standard_overline_title_2_font_weight": 600,
        "typos_standard_overline_title_2_text_transform": "uppercase",
        "typos_standard_caption_font_size": 12,
        "typos_standard_caption_font_weight": 600,
        "typos_standard_footnote_font_size": 11,
        "typos_standard_footnote_font_weight": 600,
        "typos_content_common_font_family": "'Roboto Slab', serif",
        "typos_content_common_letter_spacing": 0.35,
        "typos_content_extra_large_title_font_size": 64,
        "typos_content_extra_large_title_font_weight": 800,
        "typos_content_extra_large_title_letter_spacing": 0.8,
        "typos_content_large_title_font_size": 32,
        "typos_content_large_title_font_weight": 700,
        "typos_content_large_title_letter_spacing": 0.8,
        "typos_content_title_1_font_size": 24,
        "typos_content_title_1_font_weight": 700,
        "typos_content_title_1_letter_spacing": 0.6,
        "typos_content_title_2_font_size": 20,
        "typos_content_title_2_font_weight": 700,
        "typos_content_title_2_letter_spacing": 0.5,
        "typos_content_body_thick_font_size": 16,
        "typos_content_body_thick_font_weight": 700,
        "typos_content_body_font_size": 16,
        "typos_content_body_font_weight": null
    },
    "brand-primary-50": {
        "name": "Lunii Orange (Brand Primary / 50)",
        "font_color_extra_large_title": "#503c0d",
        "font_color_large_title": "#503c0d",
        "font_color_large_title_bold": "#fbbd2a",
        "font_color_title_1": "#503c0d",
        "font_color_title_2": "#503c0d",
        "font_color_overline_large_title": "#896717",
        "font_color_button_1": "#423f37",
        "font_color_button_1_primary": "#503c0d",
        "font_color_button_1_primary_hover": "#503c0d",
        "font_color_button_1_primary_disabled": "#ca9722",
        "font_color_button_1_plain": "#2762e9",
        "font_color_button_1_plain_hover": "#2762e9",
        "font_color_button_1_plain_disabled": "#b8b3a4",
        "font_color_button_1_destructive": "#c04020",
        "font_color_button_1_destructive_hover": "#c04020",
        "font_color_button_1_destructive_disabled": "#b8b3a4",
        "font_color_button_1_secondary": "#423f37",
        "font_color_button_1_secondary_hover": "#423f37",
        "font_color_button_1_secondary_disabled": "#b8b3a4",
        "font_color_button_1_tertiary": "#2762e9",
        "font_color_button_1_tertiary_hover": "#749dfc",
        "font_color_button_1_tertiary_disabled": "#b8b3a4",
        "font_color_button_1_tertiary_clicked": "#173a8a",
        "font_color_button_1_tertiary_alert": "#f25129",
        "font_color_button_1_tertiary_alert_hover": "#fc7958",
        "font_color_button_1_tertiary_alert_disabled": "#b8b3a4",
        "font_color_button_1_tertiary_alert_clicked": "#722613",
        "font_color_button_1_destructive_clicked": "#722613",
        "font_color_button_1_default": "#423f37",
        "font_color_button_1_default_hover": "#423f37",
        "font_color_button_1_default_disabled": "#777676",
        "font_color_button_2": "#423f37",
        "font_color_button_2_primary": "#503c0d",
        "font_color_button_2_primary_hover": "#503c0d",
        "font_color_button_2_primary_disabled": "#ca9722",
        "font_color_button_2_plain": "#2762e9",
        "font_color_button_2_plain_hover": "#2762e9",
        "font_color_button_2_plain_disabled": "#b8b3a4",
        "font_color_button_2_destructive": "#c04020",
        "font_color_button_2_destructive_hover": "#c04020",
        "font_color_button_2_destructive_disabled": "#b8b3a4",
        "font_color_button_2_secondary": "#423f37",
        "font_color_button_2_secondary_hover": "#423f37",
        "font_color_button_2_secondary_disabled": "#b8b3a4",
        "font_color_button_2_tertiary": "#2762e9",
        "font_color_button_2_tertiary_hover": "#749dfc",
        "font_color_button_2_tertiary_disabled": "#a79f8e",
        "font_color_button_2_tertiary_clicked": "#173a8a",
        "font_color_button_2_tertiary_alert": null,
        "font_color_button_2_tertiary_alert_hover": null,
        "font_color_button_2_tertiary_alert_disabled": null,
        "font_color_button_2_tertiary_alert_clicked": null,
        "font_color_button_2_destructive_clicked": "#722613",
        "font_color_button_2_default": "#423f37",
        "font_color_button_2_default_hover": "#423f37",
        "font_color_button_2_default_disabled": "#777676",
        "font_color_body_thick": "#503c0d",
        "font_color_body": "#896717",
        "font_color_secondary_body_thick": "#503c0d",
        "font_color_secondary_body": "#896717",
        "font_color_overline_title_1": "#896717",
        "font_color_button_3": "#423f37",
        "font_color_overline_title_2": "#896717",
        "font_color_caption": "#ae821d",
        "font_color_footnote": "#ae821d",
        "font_color_icon": "#423f37",
        "font_color_icon_inactive": "#c8c3b8",
        "font_color_status_default": "#493300",
        "font_color_status_message": "#493300",
        "font_color_status_alert": "#510506",
        "font_color_status_positive": "#0c3421",
        "font_color_link": "#2762e9",
        "font_color_bullet": "#4f3b0c",
        "background_color_clear": "#fff9ec",
        "background_color_primary": "#fbbd2a",
        "background_color_primary_hover": "#fccd5f",
        "background_color_primary_disabled": "#fddd94",
        "background_color_primary_clicked": "#fbbd2a",
        "background_color_plain": "#e6eeff",
        "background_color_plain_hover": "#d4e1fe",
        "background_color_plain_disabled": "#e3e1db",
        "background_color_plain_clicked": "#c1d3fe",
        "background_color_destructive": "#fee9e4",
        "background_color_destructive_hover": "#fed9cf",
        "background_color_destructive_disabled": "#e3e1db",
        "background_color_destructive_clicked": "#fec6b8",
        "background_color_secondary": "#ffffff",
        "background_color_secondary_hover": "#ffffff",
        "background_color_secondary_disabled": "#e1e1e1",
        "background_color_secondary_clicked": "#ffffff",
        "background_color_tertiary": null,
        "background_color_tertiary_hover": null,
        "background_color_tertiary_disabled": null,
        "background_color_tertiary_clicked": null,
        "background_color_tertiary_alert": null,
        "background_color_tertiary_alert_hover": null,
        "background_color_tertiary_alert_disabled": null,
        "background_color_tertiary_alert_clicked": null,
        "background_color_default": "#d6d3ca",
        "background_color_default_hover": null,
        "background_color_default_disabled": null,
        "background_color_default_clicked": null,
        "background_color_footer": "#dbf1f9",
        "background_color_danger": "#fdb19e",
        "background_color_positive": "#25dda6",
        "background_color_interactive": "#abc4fd",
        "background_color_status_default": "#fef0ce",
        "background_color_status_message": "#fef0ce",
        "background_color_status_alert": "#fccdce",
        "background_color_status_positive": "#d5f5e6",
        "background_color_active": "#fef0ce",
        "background_color_active_hover": "#fef0ce",
        "background_color_active_disabled": "#e1e1e1",
        "background_color_active_clicked": null,
        "background_color_inactive": null,
        "background_color_inactive_hover": "#fef0ce",
        "background_color_inactive_disabled": "#e1e1e1",
        "background_color_inactive_clicked": null,
        "background_color_bullet": "#fde7c8",
        "border_buttons": null,
        "border_buttons_primary": null,
        "border_buttons_plain": null,
        "border_buttons_destructive": null,
        "border_buttons_secondary": "2px solid #E3E1DB",
        "border_buttons_tertiary": null,
        "border_buttons_tertiary_alert": null,
        "border_buttons_default": null,
        "border_radius_buttons": 48,
        "border_radius_blocks": "32px",
        "border_radius_blocks_mobile": "24px 16px",
        "border_radius_push": "16px",
        "border_tags": null,
        "border_radius_tags": 20,
        "border_standard": null,
        "border_radius_standard": 8,
        "shadow_standard": "0px 2px 4px rgba(42, 41, 40, 0.15)",
        "shadow_buttons": "0px 2px 0px #363533",
        "shadow_level_00": null,
        "shadow_level_01": "0px 4px 8px rgba(66, 63, 55, 0.1), 0px -2px 8px rgba(66, 63, 55, 0.02)",
        "shadow_level_02": "0px 6px 12px rgba(66, 63, 55, 0.1), 0px -2px 12px rgba(66, 63, 55, 0.02)",
        "shadow_level_03": "0px 8px 24px rgba(66, 63, 55, 0.1), 0px -2px 24px rgba(66, 63, 55, 0.02)",
        "shadow_level_04": "0px 16px 32px rgba(66, 63, 55, 0.1), 0px -2px 4px rgba(66, 63, 55, 0.04)",
        "shadow_level_05": "0px 16px 40px rgba(66, 63, 55, 0.1), 0px -4px 40px rgba(66, 63, 55, 0.04)",
        "shadow_level_06": "0px 24px 48px rgba(66, 63, 55, 0.1), 0px -4px 28px rgba(66, 63, 55, 0.04)",
        "typos_common_font_style": "normal",
        "typos_common_line_height": "150%",
        "typos_common_font_weight": 400,
        "typos_standard_common_font_family": "'Nunito', sans-serif",
        "typos_standard_common_letter_spacing": 0.25,
        "typos_standard_extra_large_title_font_size": 64,
        "typos_standard_extra_large_title_mobile_font_size": 48,
        "typos_standard_extra_large_title_font_weight": 800,
        "typos_standard_large_title_font_size": 32,
        "typos_standard_extra_large_title_mobile_font_weight": 800,
        "typos_standard_large_title_font_weight": 800,
        "typos_standard_title_1_font_size": 24,
        "typos_standard_title_1_font_weight": 800,
        "typos_standard_title_2_font_size": 20,
        "typos_standard_title_2_font_weight": 800,
        "typos_standard_overline_large_title_font_size": 16,
        "typos_standard_overline_large_title_font_weight": 600,
        "typos_standard_overline_large_title_text_transform": "uppercase",
        "typos_standard_button_1_font_size": 16,
        "typos_standard_button_1_font_weight": 700,
        "typos_standard_button_2_font_size": 14,
        "typos_standard_button_2_font_weight": 700,
        "typos_standard_body_thick_font_size": 16,
        "typos_standard_body_thick_font_weight": 800,
        "typos_standard_body_font_size": 16,
        "typos_standard_body_font_weight": 600,
        "typos_standard_secondary_body_thick_font_size": 14,
        "typos_standard_secondary_body_thick_font_weight": 800,
        "typos_standard_secondary_body_font_size": 14,
        "typos_standard_secondary_body_font_weight": 600,
        "typos_standard_overline_title_1_font_size": 14,
        "typos_standard_overline_title_1_font_weight": 600,
        "typos_standard_overline_title_1_text_transform": "uppercase",
        "typos_standard_button_3_font_size": 12,
        "typos_standard_button_3_font_weight": 700,
        "typos_standard_button_3_text_transform": "uppercase",
        "typos_standard_overline_title_2_font_size": 12,
        "typos_standard_overline_title_2_font_weight": 600,
        "typos_standard_overline_title_2_text_transform": "uppercase",
        "typos_standard_caption_font_size": 12,
        "typos_standard_caption_font_weight": 600,
        "typos_standard_footnote_font_size": 11,
        "typos_standard_footnote_font_weight": 600,
        "typos_content_common_font_family": "'Roboto Slab', serif",
        "typos_content_common_letter_spacing": 0.35,
        "typos_content_extra_large_title_font_size": 64,
        "typos_content_extra_large_title_font_weight": 800,
        "typos_content_extra_large_title_letter_spacing": 0.8,
        "typos_content_large_title_font_size": 32,
        "typos_content_large_title_font_weight": 700,
        "typos_content_large_title_letter_spacing": 0.8,
        "typos_content_title_1_font_size": 24,
        "typos_content_title_1_font_weight": 700,
        "typos_content_title_1_letter_spacing": 0.6,
        "typos_content_title_2_font_size": 20,
        "typos_content_title_2_font_weight": 700,
        "typos_content_title_2_letter_spacing": 0.5,
        "typos_content_body_thick_font_size": 16,
        "typos_content_body_thick_font_weight": 700,
        "typos_content_body_font_size": 16,
        "typos_content_body_font_weight": null
    },
    "brand-secondary-50": {
        "name": "Lunii Blue (Brand Secondary / 50)",
        "font_color_extra_large_title": "#2b434b",
        "font_color_large_title": "#2b434b",
        "font_color_large_title_bold": "#fbbd2a",
        "font_color_title_1": "#2b434b",
        "font_color_title_2": "#2b434b",
        "font_color_overline_large_title": "#497281",
        "font_color_button_1": "#423f37",
        "font_color_button_1_primary": "#503c0d",
        "font_color_button_1_primary_hover": "#503c0d",
        "font_color_button_1_primary_disabled": "#ca9722",
        "font_color_button_1_plain": "#2762e9",
        "font_color_button_1_plain_hover": "#2762e9",
        "font_color_button_1_plain_disabled": "#b8b3a4",
        "font_color_button_1_destructive": "#c04020",
        "font_color_button_1_destructive_hover": "#c04020",
        "font_color_button_1_destructive_disabled": "#b8b3a4",
        "font_color_button_1_secondary": "#423f37",
        "font_color_button_1_secondary_hover": "#423f37",
        "font_color_button_1_secondary_disabled": "#b8b3a4",
        "font_color_button_1_tertiary": "#2762e9",
        "font_color_button_1_tertiary_hover": "#749dfc",
        "font_color_button_1_tertiary_disabled": "#b8b3a4",
        "font_color_button_1_tertiary_clicked": "#173a8a",
        "font_color_button_1_tertiary_alert": "#f25129",
        "font_color_button_1_tertiary_alert_hover": "#fc7958",
        "font_color_button_1_tertiary_alert_disabled": "#b8b3a4",
        "font_color_button_1_tertiary_alert_clicked": "#722613",
        "font_color_button_1_destructive_clicked": "#722613",
        "font_color_button_1_default": "#423f37",
        "font_color_button_1_default_hover": "#423f37",
        "font_color_button_1_default_disabled": "#777676",
        "font_color_button_2": "#423f37",
        "font_color_button_2_primary": "#503c0d",
        "font_color_button_2_primary_hover": "#503c0d",
        "font_color_button_2_primary_disabled": "#ca9722",
        "font_color_button_2_plain": "#2762e9",
        "font_color_button_2_plain_hover": "#2762e9",
        "font_color_button_2_plain_disabled": "#b8b3a4",
        "font_color_button_2_destructive": "#c04020",
        "font_color_button_2_destructive_hover": "#c04020",
        "font_color_button_2_destructive_disabled": "#b8b3a4",
        "font_color_button_2_secondary": "#423f37",
        "font_color_button_2_secondary_hover": "#423f37",
        "font_color_button_2_secondary_disabled": "#b8b3a4",
        "font_color_button_2_tertiary": "#2762e9",
        "font_color_button_2_tertiary_hover": "#749dfc",
        "font_color_button_2_tertiary_disabled": "#a79f8e",
        "font_color_button_2_tertiary_clicked": "#173a8a",
        "font_color_button_2_tertiary_alert": null,
        "font_color_button_2_tertiary_alert_hover": null,
        "font_color_button_2_tertiary_alert_disabled": null,
        "font_color_button_2_tertiary_alert_clicked": null,
        "font_color_button_2_destructive_clicked": "#722613",
        "font_color_button_2_default": "#423f37",
        "font_color_button_2_default_hover": "#423f37",
        "font_color_button_2_default_disabled": "#777676",
        "font_color_body_thick": "#497281",
        "font_color_body": "#497281",
        "font_color_secondary_body_thick": "#497281",
        "font_color_secondary_body": "#497281",
        "font_color_overline_title_1": "#497281",
        "font_color_button_3": "#423f37",
        "font_color_overline_title_2": "#497281",
        "font_color_caption": "#5d90a3",
        "font_color_footnote": "#5d90a3",
        "font_color_icon": "#423f37",
        "font_color_icon_inactive": "#c8c3b8",
        "font_color_status_default": "#493300",
        "font_color_status_message": "#493300",
        "font_color_status_alert": "#510506",
        "font_color_status_positive": "#0c3421",
        "font_color_link": "#2762e9",
        "font_color_bullet": "#063646",
        "background_color_clear": "#f4fbfd",
        "background_color_primary": "#fbbd2a",
        "background_color_primary_hover": "#fccd5f",
        "background_color_primary_disabled": "#fddd94",
        "background_color_primary_clicked": "#fbbd2a",
        "background_color_plain": "#e6eeff",
        "background_color_plain_hover": "#d4e1fe",
        "background_color_plain_disabled": "#e3e1db",
        "background_color_plain_clicked": "#c1d3fe",
        "background_color_destructive": "#fee9e4",
        "background_color_destructive_hover": "#fed9cf",
        "background_color_destructive_disabled": "#e3e1db",
        "background_color_destructive_clicked": "#fec6b8",
        "background_color_secondary": "#ffffff",
        "background_color_secondary_hover": "#ffffff",
        "background_color_secondary_disabled": "#e1e1e1",
        "background_color_secondary_clicked": "#ffffff",
        "background_color_tertiary": null,
        "background_color_tertiary_hover": null,
        "background_color_tertiary_disabled": null,
        "background_color_tertiary_clicked": null,
        "background_color_tertiary_alert": null,
        "background_color_tertiary_alert_hover": null,
        "background_color_tertiary_alert_disabled": null,
        "background_color_tertiary_alert_clicked": null,
        "background_color_default": "#d6d3ca",
        "background_color_default_hover": null,
        "background_color_default_disabled": null,
        "background_color_default_clicked": null,
        "background_color_footer": "#dbf1f9",
        "background_color_danger": "#fdb19e",
        "background_color_positive": "#25dda6",
        "background_color_interactive": "#abc4fd",
        "background_color_status_default": "#fef0ce",
        "background_color_status_message": "#fef0ce",
        "background_color_status_alert": "#fccdce",
        "background_color_status_positive": "#d5f5e6",
        "background_color_active": "#fef0ce",
        "background_color_active_hover": "#fef0ce",
        "background_color_active_disabled": "#e1e1e1",
        "background_color_active_clicked": null,
        "background_color_inactive": null,
        "background_color_inactive_hover": "#fef0ce",
        "background_color_inactive_disabled": "#e1e1e1",
        "background_color_inactive_clicked": null,
        "background_color_bullet": "#c8e7f9",
        "border_buttons": null,
        "border_buttons_primary": null,
        "border_buttons_plain": null,
        "border_buttons_destructive": null,
        "border_buttons_secondary": "2px solid #E3E1DB",
        "border_buttons_tertiary": null,
        "border_buttons_tertiary_alert": null,
        "border_buttons_default": null,
        "border_radius_buttons": 48,
        "border_radius_blocks": "32px",
        "border_radius_blocks_mobile": "24px 16px",
        "border_radius_push": "16px",
        "border_tags": null,
        "border_radius_tags": 20,
        "border_standard": null,
        "border_radius_standard": 8,
        "shadow_standard": "0px 2px 4px rgba(42, 41, 40, 0.15)",
        "shadow_buttons": "0px 2px 0px #363533",
        "shadow_level_00": null,
        "shadow_level_01": "0px 4px 8px rgba(66, 63, 55, 0.1), 0px -2px 8px rgba(66, 63, 55, 0.02)",
        "shadow_level_02": "0px 6px 12px rgba(66, 63, 55, 0.1), 0px -2px 12px rgba(66, 63, 55, 0.02)",
        "shadow_level_03": "0px 8px 24px rgba(66, 63, 55, 0.1), 0px -2px 24px rgba(66, 63, 55, 0.02)",
        "shadow_level_04": "0px 16px 32px rgba(66, 63, 55, 0.1), 0px -2px 4px rgba(66, 63, 55, 0.04)",
        "shadow_level_05": "0px 16px 40px rgba(66, 63, 55, 0.1), 0px -4px 40px rgba(66, 63, 55, 0.04)",
        "shadow_level_06": "0px 24px 48px rgba(66, 63, 55, 0.1), 0px -4px 28px rgba(66, 63, 55, 0.04)",
        "typos_common_font_style": "normal",
        "typos_common_line_height": "150%",
        "typos_common_font_weight": 400,
        "typos_standard_common_font_family": "'Nunito', sans-serif",
        "typos_standard_common_letter_spacing": 0.25,
        "typos_standard_extra_large_title_font_size": 64,
        "typos_standard_extra_large_title_mobile_font_size": 48,
        "typos_standard_extra_large_title_font_weight": 800,
        "typos_standard_large_title_font_size": 32,
        "typos_standard_extra_large_title_mobile_font_weight": 800,
        "typos_standard_large_title_font_weight": 800,
        "typos_standard_title_1_font_size": 24,
        "typos_standard_title_1_font_weight": 800,
        "typos_standard_title_2_font_size": 20,
        "typos_standard_title_2_font_weight": 800,
        "typos_standard_overline_large_title_font_size": 16,
        "typos_standard_overline_large_title_font_weight": 600,
        "typos_standard_overline_large_title_text_transform": "uppercase",
        "typos_standard_button_1_font_size": 16,
        "typos_standard_button_1_font_weight": 700,
        "typos_standard_button_2_font_size": 14,
        "typos_standard_button_2_font_weight": 700,
        "typos_standard_body_thick_font_size": 16,
        "typos_standard_body_thick_font_weight": 800,
        "typos_standard_body_font_size": 16,
        "typos_standard_body_font_weight": 600,
        "typos_standard_secondary_body_thick_font_size": 14,
        "typos_standard_secondary_body_thick_font_weight": 800,
        "typos_standard_secondary_body_font_size": 14,
        "typos_standard_secondary_body_font_weight": 600,
        "typos_standard_overline_title_1_font_size": 14,
        "typos_standard_overline_title_1_font_weight": 600,
        "typos_standard_overline_title_1_text_transform": "uppercase",
        "typos_standard_button_3_font_size": 12,
        "typos_standard_button_3_font_weight": 700,
        "typos_standard_button_3_text_transform": "uppercase",
        "typos_standard_overline_title_2_font_size": 12,
        "typos_standard_overline_title_2_font_weight": 600,
        "typos_standard_overline_title_2_text_transform": "uppercase",
        "typos_standard_caption_font_size": 12,
        "typos_standard_caption_font_weight": 600,
        "typos_standard_footnote_font_size": 11,
        "typos_standard_footnote_font_weight": 600,
        "typos_content_common_font_family": "'Roboto Slab', serif",
        "typos_content_common_letter_spacing": 0.35,
        "typos_content_extra_large_title_font_size": 64,
        "typos_content_extra_large_title_font_weight": 800,
        "typos_content_extra_large_title_letter_spacing": 0.8,
        "typos_content_large_title_font_size": 32,
        "typos_content_large_title_font_weight": 700,
        "typos_content_large_title_letter_spacing": 0.8,
        "typos_content_title_1_font_size": 24,
        "typos_content_title_1_font_weight": 700,
        "typos_content_title_1_letter_spacing": 0.6,
        "typos_content_title_2_font_size": 20,
        "typos_content_title_2_font_weight": 700,
        "typos_content_title_2_letter_spacing": 0.5,
        "typos_content_body_thick_font_size": 16,
        "typos_content_body_thick_font_weight": 700,
        "typos_content_body_font_size": 16,
        "typos_content_body_font_weight": null
    },
    "default": {
        "name": "White (UI / 000) - DEFAULT",
        "font_color_extra_large_title": "#ffffff",
        "font_color_large_title": "#423f37",
        "font_color_large_title_bold": "#fbbd2a",
        "font_color_title_1": "#423f37",
        "font_color_title_2": "#423f37",
        "font_color_overline_large_title": "#716c5e",
        "font_color_button_1": "#423f37",
        "font_color_button_1_primary": "#503c0d",
        "font_color_button_1_primary_hover": "#503c0d",
        "font_color_button_1_primary_disabled": "#ca9722",
        "font_color_button_1_plain": "#2762e9",
        "font_color_button_1_plain_hover": "#2762e9",
        "font_color_button_1_plain_disabled": "#b8b3a4",
        "font_color_button_1_destructive": "#c04020",
        "font_color_button_1_destructive_hover": "#c04020",
        "font_color_button_1_destructive_disabled": "#b8b3a4",
        "font_color_button_1_secondary": "#423f37",
        "font_color_button_1_secondary_hover": "#423f37",
        "font_color_button_1_secondary_disabled": "#b8b3a4",
        "font_color_button_1_tertiary": "#2762e9",
        "font_color_button_1_tertiary_hover": "#749dfc",
        "font_color_button_1_tertiary_disabled": "#b8b3a4",
        "font_color_button_1_tertiary_clicked": "#173a8a",
        "font_color_button_1_tertiary_alert": "#f25129",
        "font_color_button_1_tertiary_alert_hover": "#fc7958",
        "font_color_button_1_tertiary_alert_disabled": "#b8b3a4",
        "font_color_button_1_tertiary_alert_clicked": "#722613",
        "font_color_button_1_destructive_clicked": "#722613",
        "font_color_button_1_default": "#423f37",
        "font_color_button_1_default_hover": "#423f37",
        "font_color_button_1_default_disabled": "#777676",
        "font_color_button_2": "#423f37",
        "font_color_button_2_primary": "#503c0d",
        "font_color_button_2_primary_hover": "#503c0d",
        "font_color_button_2_primary_disabled": "#ca9722",
        "font_color_button_2_plain": "#2762e9",
        "font_color_button_2_plain_hover": "#2762e9",
        "font_color_button_2_plain_disabled": "#b8b3a4",
        "font_color_button_2_destructive": "#c04020",
        "font_color_button_2_destructive_hover": "#c04020",
        "font_color_button_2_destructive_disabled": "#b8b3a4",
        "font_color_button_2_secondary": "#423f37",
        "font_color_button_2_secondary_hover": "#423f37",
        "font_color_button_2_secondary_disabled": "#b8b3a4",
        "font_color_button_2_tertiary": "#2762e9",
        "font_color_button_2_tertiary_hover": "#749dfc",
        "font_color_button_2_tertiary_disabled": "#a79f8e",
        "font_color_button_2_tertiary_clicked": "#173a8a",
        "font_color_button_2_destructive_clicked": "#722613",
        "font_color_button_2_default": "#423f37",
        "font_color_button_2_default_hover": "#423f37",
        "font_color_button_2_default_disabled": "#777676",
        "font_color_body_thick": "#423f37",
        "font_color_body": "#716c5e",
        "font_color_secondary_body_thick": "#423f37",
        "font_color_secondary_body": "#423f37",
        "font_color_overline_title_1": "#716c5e",
        "font_color_button_3": "#423f37",
        "font_color_overline_title_2": "#716c5e",
        "font_color_caption": "#908977",
        "font_color_footnote": "#908977",
        "font_color_icon": "#423f37",
        "font_color_icon_inactive": "#c8c3b8",
        "font_color_status_default": "#493300",
        "font_color_status_message": "#493300",
        "font_color_status_alert": "#510506",
        "font_color_status_positive": "#0c3421",
        "font_color_link": "#2762e9",
        "font_color_bullet": "#2c3637",
        "background_color_clear": "#ffffff",
        "background_color_primary": "#fbbd2a",
        "background_color_primary_hover": "#fccd5f",
        "background_color_primary_disabled": "#fddd94",
        "background_color_primary_clicked": "#fbbd2a",
        "background_color_plain": "#e6eeff",
        "background_color_plain_hover": "#d4e1fe",
        "background_color_plain_disabled": "#e3e1db",
        "background_color_plain_clicked": "#c1d3fe",
        "background_color_destructive": "#fee9e4",
        "background_color_destructive_hover": "#fed9cf",
        "background_color_destructive_disabled": "#e3e1db",
        "background_color_destructive_clicked": "#fec6b8",
        "background_color_secondary": "#ffffff",
        "background_color_secondary_hover": "#ffffff",
        "background_color_secondary_disabled": "#e1e1e1",
        "background_color_secondary_clicked": "#ffffff",
        "background_color_default": "#d6d3ca",
        "background_color_footer": "#dbf1f9",
        "background_color_danger": "#fdb19e",
        "background_color_positive": "#25dda6",
        "background_color_interactive": "#abc4fd",
        "background_color_status_default": "#fef0ce",
        "background_color_status_message": "#fef0ce",
        "background_color_status_alert": "#fccdce",
        "background_color_status_positive": "#d5f5e6",
        "background_color_active": "#fef0ce",
        "background_color_active_hover": "#fef0ce",
        "background_color_active_disabled": "#e1e1e1",
        "background_color_inactive_hover": "#fef0ce",
        "background_color_inactive_disabled": "#e1e1e1",
        "background_color_bullet": "#c8e7f9",
        "border_buttons_secondary": "2px solid #E3E1DB",
        "border_radius_buttons": 48,
        "border_radius_blocks": "32px",
        "border_radius_blocks_mobile": "24px 16px",
        "border_radius_push": "16px",
        "border_radius_tags": 20,
        "border_radius_standard": 8,
        "shadow_standard": "0px 2px 4px rgba(42, 41, 40, 0.15)",
        "shadow_buttons": "0px 2px 0px #363533",
        "shadow_level_01": "0px 4px 8px rgba(66, 63, 55, 0.1), 0px -2px 8px rgba(66, 63, 55, 0.02)",
        "shadow_level_02": "0px 6px 12px rgba(66, 63, 55, 0.1), 0px -2px 12px rgba(66, 63, 55, 0.02)",
        "shadow_level_03": "0px 8px 24px rgba(66, 63, 55, 0.1), 0px -2px 24px rgba(66, 63, 55, 0.02)",
        "shadow_level_04": "0px 16px 32px rgba(66, 63, 55, 0.1), 0px -2px 4px rgba(66, 63, 55, 0.04)",
        "shadow_level_05": "0px 16px 40px rgba(66, 63, 55, 0.1), 0px -4px 40px rgba(66, 63, 55, 0.04)",
        "shadow_level_06": "0px 24px 48px rgba(66, 63, 55, 0.1), 0px -4px 28px rgba(66, 63, 55, 0.04)",
        "typos_common_font_style": "normal",
        "typos_common_line_height": "150%",
        "typos_common_font_weight": 400,
        "typos_standard_common_font_family": "'Nunito', sans-serif",
        "typos_standard_common_letter_spacing": 0.25,
        "typos_standard_extra_large_title_font_size": 64,
        "typos_standard_extra_large_title_mobile_font_size": 48,
        "typos_standard_extra_large_title_font_weight": 800,
        "typos_standard_large_title_font_size": 32,
        "typos_standard_extra_large_title_mobile_font_weight": 800,
        "typos_standard_large_title_font_weight": 800,
        "typos_standard_title_1_font_size": 24,
        "typos_standard_title_1_font_weight": 800,
        "typos_standard_title_2_font_size": 20,
        "typos_standard_title_2_font_weight": 800,
        "typos_standard_overline_large_title_font_size": 16,
        "typos_standard_overline_large_title_font_weight": 600,
        "typos_standard_overline_large_title_text_transform": "uppercase",
        "typos_standard_button_1_font_size": 16,
        "typos_standard_button_1_font_weight": 700,
        "typos_standard_button_2_font_size": 14,
        "typos_standard_button_2_font_weight": 700,
        "typos_standard_body_thick_font_size": 16,
        "typos_standard_body_thick_font_weight": 800,
        "typos_standard_body_font_size": 16,
        "typos_standard_body_font_weight": 600,
        "typos_standard_secondary_body_thick_font_size": 14,
        "typos_standard_secondary_body_thick_font_weight": 800,
        "typos_standard_secondary_body_font_size": 14,
        "typos_standard_secondary_body_font_weight": 600,
        "typos_standard_overline_title_1_font_size": 14,
        "typos_standard_overline_title_1_font_weight": 600,
        "typos_standard_overline_title_1_text_transform": "uppercase",
        "typos_standard_button_3_font_size": 12,
        "typos_standard_button_3_font_weight": 700,
        "typos_standard_button_3_text_transform": "uppercase",
        "typos_standard_overline_title_2_font_size": 12,
        "typos_standard_overline_title_2_font_weight": 600,
        "typos_standard_overline_title_2_text_transform": "uppercase",
        "typos_standard_caption_font_size": 12,
        "typos_standard_caption_font_weight": 600,
        "typos_standard_footnote_font_size": 11,
        "typos_standard_footnote_font_weight": 600,
        "typos_content_common_font_family": "'Roboto Slab', serif",
        "typos_content_common_letter_spacing": 0.35,
        "typos_content_extra_large_title_font_size": 64,
        "typos_content_extra_large_title_font_weight": 800,
        "typos_content_extra_large_title_letter_spacing": 0.8,
        "typos_content_large_title_font_size": 32,
        "typos_content_large_title_font_weight": 700,
        "typos_content_large_title_letter_spacing": 0.8,
        "typos_content_title_1_font_size": 24,
        "typos_content_title_1_font_weight": 700,
        "typos_content_title_1_letter_spacing": 0.6,
        "typos_content_title_2_font_size": 20,
        "typos_content_title_2_font_weight": 700,
        "typos_content_title_2_letter_spacing": 0.5,
        "typos_content_body_thick_font_size": 16,
        "typos_content_body_thick_font_weight": 700,
        "typos_content_body_font_size": 16
    },
    "grey-color-for-argument-module-font": {
        "name": "Grey color for argument module font",
        "font_color_extra_large_title": "#908977",
        "font_color_large_title": "#908977",
        "font_color_large_title_bold": "#908977",
        "font_color_title_1": "#908977",
        "font_color_title_2": "#908977",
        "font_color_overline_large_title": "#716c5e",
        "font_color_button_1": "#423f37",
        "font_color_button_1_primary": "#503c0d",
        "font_color_button_1_primary_hover": "#503c0d",
        "font_color_button_1_primary_disabled": "#ca9722",
        "font_color_button_1_plain": "#2762e9",
        "font_color_button_1_plain_hover": "#2762e9",
        "font_color_button_1_plain_disabled": "#b8b3a4",
        "font_color_button_1_destructive": "#c04020",
        "font_color_button_1_destructive_hover": "#c04020",
        "font_color_button_1_destructive_disabled": "#b8b3a4",
        "font_color_button_1_secondary": "#423f37",
        "font_color_button_1_secondary_hover": "#423f37",
        "font_color_button_1_secondary_disabled": "#b8b3a4",
        "font_color_button_1_tertiary": "#2762e9",
        "font_color_button_1_tertiary_hover": "#749dfc",
        "font_color_button_1_tertiary_disabled": "#b8b3a4",
        "font_color_button_1_tertiary_clicked": "#173a8a",
        "font_color_button_1_tertiary_alert": "#f25129",
        "font_color_button_1_tertiary_alert_hover": "#fc7958",
        "font_color_button_1_tertiary_alert_disabled": "#b8b3a4",
        "font_color_button_1_tertiary_alert_clicked": "#722613",
        "font_color_button_1_destructive_clicked": "#722613",
        "font_color_button_1_default": "#423f37",
        "font_color_button_1_default_hover": "#423f37",
        "font_color_button_1_default_disabled": "#777676",
        "font_color_button_2": "#423f37",
        "font_color_button_2_primary": "#503c0d",
        "font_color_button_2_primary_hover": "#503c0d",
        "font_color_button_2_primary_disabled": "#ca9722",
        "font_color_button_2_plain": "#2762e9",
        "font_color_button_2_plain_hover": "#2762e9",
        "font_color_button_2_plain_disabled": "#b8b3a4",
        "font_color_button_2_destructive": "#c04020",
        "font_color_button_2_destructive_hover": "#c04020",
        "font_color_button_2_destructive_disabled": "#b8b3a4",
        "font_color_button_2_secondary": "#423f37",
        "font_color_button_2_secondary_hover": "#423f37",
        "font_color_button_2_secondary_disabled": "#b8b3a4",
        "font_color_button_2_tertiary": "#2762e9",
        "font_color_button_2_tertiary_hover": "#749dfc",
        "font_color_button_2_tertiary_disabled": "#a79f8e",
        "font_color_button_2_tertiary_clicked": "#173a8a",
        "font_color_button_2_destructive_clicked": "#722613",
        "font_color_button_2_default": "#423f37",
        "font_color_button_2_default_hover": "#423f37",
        "font_color_button_2_default_disabled": "#777676",
        "font_color_body_thick": "#908977",
        "font_color_body": "#908977",
        "font_color_secondary_body_thick": "#908977",
        "font_color_secondary_body": "#908977",
        "font_color_overline_title_1": "#908977",
        "font_color_button_3": "#423f37",
        "font_color_overline_title_2": "#908977",
        "font_color_caption": "#908977",
        "font_color_footnote": "#908977",
        "font_color_icon": "#423f37",
        "font_color_icon_inactive": "#c8c3b8",
        "font_color_status_default": "#493300",
        "font_color_status_message": "#493300",
        "font_color_status_alert": "#510506",
        "font_color_status_positive": "#0c3421",
        "font_color_link": "#2762e9",
        "font_color_bullet": "#2c3637",
        "background_color_clear": "#ffffff",
        "background_color_primary": "#fbbd2a",
        "background_color_primary_hover": "#fccd5f",
        "background_color_primary_disabled": "#fddd94",
        "background_color_primary_clicked": "#fbbd2a",
        "background_color_plain": "#e6eeff",
        "background_color_plain_hover": "#d4e1fe",
        "background_color_plain_disabled": "#e3e1db",
        "background_color_plain_clicked": "#c1d3fe",
        "background_color_destructive": "#fee9e4",
        "background_color_destructive_hover": "#fed9cf",
        "background_color_destructive_disabled": "#e3e1db",
        "background_color_destructive_clicked": "#fec6b8",
        "background_color_secondary": "#ffffff",
        "background_color_secondary_hover": "#ffffff",
        "background_color_secondary_disabled": "#e1e1e1",
        "background_color_secondary_clicked": "#ffffff",
        "background_color_default": "#d6d3ca",
        "background_color_footer": "#dbf1f9",
        "background_color_danger": "#fdb19e",
        "background_color_positive": "#25dda6",
        "background_color_interactive": "#abc4fd",
        "background_color_status_default": "#fef0ce",
        "background_color_status_message": "#fef0ce",
        "background_color_status_alert": "#fccdce",
        "background_color_status_positive": "#d5f5e6",
        "background_color_active": "#fef0ce",
        "background_color_active_hover": "#fef0ce",
        "background_color_active_disabled": "#e1e1e1",
        "background_color_inactive_hover": "#fef0ce",
        "background_color_inactive_disabled": "#e1e1e1",
        "background_color_bullet": "#c8e7f9",
        "border_buttons_secondary": "2px solid #E3E1DB",
        "border_radius_buttons": 48,
        "border_radius_blocks": "32px",
        "border_radius_blocks_mobile": "24px 16px",
        "border_radius_push": "16px",
        "border_radius_tags": 20,
        "border_radius_standard": 8,
        "shadow_standard": "0px 2px 4px rgba(42, 41, 40, 0.15)",
        "shadow_buttons": "0px 2px 0px #363533",
        "shadow_level_01": "0px 4px 8px rgba(66, 63, 55, 0.1), 0px -2px 8px rgba(66, 63, 55, 0.02)",
        "shadow_level_02": "0px 6px 12px rgba(66, 63, 55, 0.1), 0px -2px 12px rgba(66, 63, 55, 0.02)",
        "shadow_level_03": "0px 8px 24px rgba(66, 63, 55, 0.1), 0px -2px 24px rgba(66, 63, 55, 0.02)",
        "shadow_level_04": "0px 16px 32px rgba(66, 63, 55, 0.1), 0px -2px 4px rgba(66, 63, 55, 0.04)",
        "shadow_level_05": "0px 16px 40px rgba(66, 63, 55, 0.1), 0px -4px 40px rgba(66, 63, 55, 0.04)",
        "shadow_level_06": "0px 24px 48px rgba(66, 63, 55, 0.1), 0px -4px 28px rgba(66, 63, 55, 0.04)",
        "typos_common_font_style": "normal",
        "typos_common_line_height": "150%",
        "typos_common_font_weight": 400,
        "typos_standard_common_font_family": "'Nunito', sans-serif",
        "typos_standard_common_letter_spacing": 0.25,
        "typos_standard_extra_large_title_font_size": 64,
        "typos_standard_extra_large_title_mobile_font_size": 48,
        "typos_standard_extra_large_title_font_weight": 800,
        "typos_standard_large_title_font_size": 32,
        "typos_standard_extra_large_title_mobile_font_weight": 800,
        "typos_standard_large_title_font_weight": 800,
        "typos_standard_title_1_font_size": 24,
        "typos_standard_title_1_font_weight": 800,
        "typos_standard_title_2_font_size": 20,
        "typos_standard_title_2_font_weight": 800,
        "typos_standard_overline_large_title_font_size": 16,
        "typos_standard_overline_large_title_font_weight": 600,
        "typos_standard_overline_large_title_text_transform": "uppercase",
        "typos_standard_button_1_font_size": 16,
        "typos_standard_button_1_font_weight": 700,
        "typos_standard_button_2_font_size": 14,
        "typos_standard_button_2_font_weight": 700,
        "typos_standard_body_thick_font_size": 16,
        "typos_standard_body_thick_font_weight": 800,
        "typos_standard_body_font_size": 16,
        "typos_standard_body_font_weight": 600,
        "typos_standard_secondary_body_thick_font_size": 14,
        "typos_standard_secondary_body_thick_font_weight": 800,
        "typos_standard_secondary_body_font_size": 14,
        "typos_standard_secondary_body_font_weight": 600,
        "typos_standard_overline_title_1_font_size": 14,
        "typos_standard_overline_title_1_font_weight": 600,
        "typos_standard_overline_title_1_text_transform": "uppercase",
        "typos_standard_button_3_font_size": 12,
        "typos_standard_button_3_font_weight": 700,
        "typos_standard_button_3_text_transform": "uppercase",
        "typos_standard_overline_title_2_font_size": 12,
        "typos_standard_overline_title_2_font_weight": 600,
        "typos_standard_overline_title_2_text_transform": "uppercase",
        "typos_standard_caption_font_size": 12,
        "typos_standard_caption_font_weight": 600,
        "typos_standard_footnote_font_size": 11,
        "typos_standard_footnote_font_weight": 600,
        "typos_content_common_font_family": "'Roboto Slab', serif",
        "typos_content_common_letter_spacing": 0.35,
        "typos_content_extra_large_title_font_size": 64,
        "typos_content_extra_large_title_font_weight": 800,
        "typos_content_extra_large_title_letter_spacing": 0.8,
        "typos_content_large_title_font_size": 32,
        "typos_content_large_title_font_weight": 700,
        "typos_content_large_title_letter_spacing": 0.8,
        "typos_content_title_1_font_size": 24,
        "typos_content_title_1_font_weight": 700,
        "typos_content_title_1_letter_spacing": 0.6,
        "typos_content_title_2_font_size": 20,
        "typos_content_title_2_font_weight": 700,
        "typos_content_title_2_letter_spacing": 0.5,
        "typos_content_body_thick_font_size": 16,
        "typos_content_body_thick_font_weight": 700,
        "typos_content_body_font_size": 16
    },
    "brand-tertiary-50": {
        "name": "Lunii Turquoise (Brand Tertiary / 50)",
        "font_color_extra_large_title": "#0c4745",
        "font_color_large_title": "#0c4745",
        "font_color_large_title_bold": "#199995",
        "font_color_title_1": "#0c4745",
        "font_color_title_2": "#0c4745",
        "font_color_overline_large_title": "#147975",
        "font_color_button_1": "#423f37",
        "font_color_button_1_primary": "#503c0d",
        "font_color_button_1_primary_hover": "#503c0d",
        "font_color_button_1_primary_disabled": "#ca9722",
        "font_color_button_1_plain": "#2762e9",
        "font_color_button_1_plain_hover": "#2762e9",
        "font_color_button_1_plain_disabled": "#b8b3a4",
        "font_color_button_1_destructive": "#c04020",
        "font_color_button_1_destructive_hover": "#c04020",
        "font_color_button_1_destructive_disabled": "#b8b3a4",
        "font_color_button_1_secondary": "#423f37",
        "font_color_button_1_secondary_hover": "#423f37",
        "font_color_button_1_secondary_disabled": "#b8b3a4",
        "font_color_button_1_tertiary": "#2762e9",
        "font_color_button_1_tertiary_hover": "#749dfc",
        "font_color_button_1_tertiary_disabled": "#b8b3a4",
        "font_color_button_1_tertiary_clicked": "#173a8a",
        "font_color_button_1_tertiary_alert": "#f25129",
        "font_color_button_1_tertiary_alert_hover": "#fc7958",
        "font_color_button_1_tertiary_alert_disabled": "#b8b3a4",
        "font_color_button_1_tertiary_alert_clicked": "#722613",
        "font_color_button_1_destructive_clicked": "#722613",
        "font_color_button_1_default": "#423f37",
        "font_color_button_1_default_hover": "#423f37",
        "font_color_button_1_default_disabled": "#777676",
        "font_color_button_2": "#423f37",
        "font_color_button_2_primary": "#503c0d",
        "font_color_button_2_primary_hover": "#503c0d",
        "font_color_button_2_primary_disabled": "#ca9722",
        "font_color_button_2_plain": "#2762e9",
        "font_color_button_2_plain_hover": "#2762e9",
        "font_color_button_2_plain_disabled": "#b8b3a4",
        "font_color_button_2_destructive": "#c04020",
        "font_color_button_2_destructive_hover": "#c04020",
        "font_color_button_2_destructive_disabled": "#b8b3a4",
        "font_color_button_2_secondary": "#423f37",
        "font_color_button_2_secondary_hover": "#423f37",
        "font_color_button_2_secondary_disabled": "#b8b3a4",
        "font_color_button_2_tertiary": "#2762e9",
        "font_color_button_2_tertiary_hover": "#749dfc",
        "font_color_button_2_tertiary_disabled": "#a79f8e",
        "font_color_button_2_tertiary_clicked": "#173a8a",
        "font_color_button_2_destructive_clicked": "#722613",
        "font_color_button_2_default": "#423f37",
        "font_color_button_2_default_hover": "#423f37",
        "font_color_button_2_default_disabled": "#777676",
        "font_color_body_thick": "#147975",
        "font_color_body": "#147975",
        "font_color_secondary_body_thick": "#147975",
        "font_color_secondary_body": "#147975",
        "font_color_overline_title_1": "#147975",
        "font_color_button_3": "#423f37",
        "font_color_overline_title_2": "#147975",
        "font_color_caption": "#199995",
        "font_color_footnote": "#199995",
        "font_color_icon": "#423f37",
        "font_color_icon_inactive": "#c8c3b8",
        "font_color_status_default": "#493300",
        "font_color_status_message": "#493300",
        "font_color_status_alert": "#510506",
        "font_color_status_positive": "#0c3421",
        "font_color_link": "#2762e9",
        "font_color_bullet": "#2c3637",
        "background_color_clear": "#f2fbfb",
        "background_color_primary": "#fbbd2a",
        "background_color_primary_hover": "#fccd5f",
        "background_color_primary_disabled": "#fddd94",
        "background_color_primary_clicked": "#fbbd2a",
        "background_color_plain": "#e6eeff",
        "background_color_plain_hover": "#d4e1fe",
        "background_color_plain_disabled": "#e3e1db",
        "background_color_plain_clicked": "#c1d3fe",
        "background_color_destructive": "#fee9e4",
        "background_color_destructive_hover": "#fed9cf",
        "background_color_destructive_disabled": "#e3e1db",
        "background_color_destructive_clicked": "#fec6b8",
        "background_color_secondary": "#ffffff",
        "background_color_secondary_hover": "#ffffff",
        "background_color_secondary_disabled": "#e1e1e1",
        "background_color_secondary_clicked": "#ffffff",
        "background_color_default": "#d6d3ca",
        "background_color_footer": "#dbf1f9",
        "background_color_danger": "#fdb19e",
        "background_color_positive": "#25dda6",
        "background_color_interactive": "#abc4fd",
        "background_color_status_default": "#fef0ce",
        "background_color_status_message": "#fef0ce",
        "background_color_status_alert": "#fccdce",
        "background_color_status_positive": "#d5f5e6",
        "background_color_active": "#fef0ce",
        "background_color_active_hover": "#fef0ce",
        "background_color_active_disabled": "#e1e1e1",
        "background_color_inactive_hover": "#fef0ce",
        "background_color_inactive_disabled": "#e1e1e1",
        "background_color_bullet": "#c8e7f9",
        "border_buttons_secondary": "2px solid #E3E1DB",
        "border_radius_buttons": 48,
        "border_radius_blocks": "32px",
        "border_radius_blocks_mobile": "24px 16px",
        "border_radius_push": "16px",
        "border_radius_tags": 20,
        "border_radius_standard": 8,
        "shadow_standard": "0px 2px 4px rgba(42, 41, 40, 0.15)",
        "shadow_buttons": "0px 2px 0px #363533",
        "shadow_level_01": "0px 4px 8px rgba(66, 63, 55, 0.1), 0px -2px 8px rgba(66, 63, 55, 0.02)",
        "shadow_level_02": "0px 6px 12px rgba(66, 63, 55, 0.1), 0px -2px 12px rgba(66, 63, 55, 0.02)",
        "shadow_level_03": "0px 8px 24px rgba(66, 63, 55, 0.1), 0px -2px 24px rgba(66, 63, 55, 0.02)",
        "shadow_level_04": "0px 16px 32px rgba(66, 63, 55, 0.1), 0px -2px 4px rgba(66, 63, 55, 0.04)",
        "shadow_level_05": "0px 16px 40px rgba(66, 63, 55, 0.1), 0px -4px 40px rgba(66, 63, 55, 0.04)",
        "shadow_level_06": "0px 24px 48px rgba(66, 63, 55, 0.1), 0px -4px 28px rgba(66, 63, 55, 0.04)",
        "typos_common_font_style": "normal",
        "typos_common_line_height": "150%",
        "typos_common_font_weight": 400,
        "typos_standard_common_font_family": "'Nunito', sans-serif",
        "typos_standard_common_letter_spacing": 0.25,
        "typos_standard_extra_large_title_font_size": 64,
        "typos_standard_extra_large_title_mobile_font_size": 48,
        "typos_standard_extra_large_title_font_weight": 800,
        "typos_standard_large_title_font_size": 32,
        "typos_standard_extra_large_title_mobile_font_weight": 800,
        "typos_standard_large_title_font_weight": 800,
        "typos_standard_title_1_font_size": 24,
        "typos_standard_title_1_font_weight": 800,
        "typos_standard_title_2_font_size": 20,
        "typos_standard_title_2_font_weight": 800,
        "typos_standard_overline_large_title_font_size": 16,
        "typos_standard_overline_large_title_font_weight": 600,
        "typos_standard_overline_large_title_text_transform": "uppercase",
        "typos_standard_button_1_font_size": 16,
        "typos_standard_button_1_font_weight": 700,
        "typos_standard_button_2_font_size": 14,
        "typos_standard_button_2_font_weight": 700,
        "typos_standard_body_thick_font_size": 16,
        "typos_standard_body_thick_font_weight": 800,
        "typos_standard_body_font_size": 16,
        "typos_standard_body_font_weight": 600,
        "typos_standard_secondary_body_thick_font_size": 14,
        "typos_standard_secondary_body_thick_font_weight": 800,
        "typos_standard_secondary_body_font_size": 14,
        "typos_standard_secondary_body_font_weight": 600,
        "typos_standard_overline_title_1_font_size": 14,
        "typos_standard_overline_title_1_font_weight": 600,
        "typos_standard_overline_title_1_text_transform": "uppercase",
        "typos_standard_button_3_font_size": 12,
        "typos_standard_button_3_font_weight": 700,
        "typos_standard_button_3_text_transform": "uppercase",
        "typos_standard_overline_title_2_font_size": 12,
        "typos_standard_overline_title_2_font_weight": 600,
        "typos_standard_overline_title_2_text_transform": "uppercase",
        "typos_standard_caption_font_size": 12,
        "typos_standard_caption_font_weight": 600,
        "typos_standard_footnote_font_size": 11,
        "typos_standard_footnote_font_weight": 600,
        "typos_content_common_font_family": "'Roboto Slab', serif",
        "typos_content_common_letter_spacing": 0.35,
        "typos_content_extra_large_title_font_size": 64,
        "typos_content_extra_large_title_font_weight": 800,
        "typos_content_extra_large_title_letter_spacing": 0.8,
        "typos_content_large_title_font_size": 32,
        "typos_content_large_title_font_weight": 700,
        "typos_content_large_title_letter_spacing": 0.8,
        "typos_content_title_1_font_size": 24,
        "typos_content_title_1_font_weight": 700,
        "typos_content_title_1_letter_spacing": 0.6,
        "typos_content_title_2_font_size": 20,
        "typos_content_title_2_font_weight": 700,
        "typos_content_title_2_letter_spacing": 0.5,
        "typos_content_body_thick_font_size": 16,
        "typos_content_body_thick_font_weight": 700,
        "typos_content_body_font_size": 16
    },
    "brand-tertiary-100": {
        "name": "Lunii Turquoise (Brand Tertiary / 100)",
        "font_color_extra_large_title": "#0c4745",
        "font_color_large_title": "#0c4745",
        "font_color_large_title_bold": "#199995",
        "font_color_title_1": "#0c4745",
        "font_color_title_2": "#0c4745",
        "font_color_overline_large_title": "#147975",
        "font_color_button_1": "#423f37",
        "font_color_button_1_primary": "#503c0d",
        "font_color_button_1_primary_hover": "#503c0d",
        "font_color_button_1_primary_disabled": "#ca9722",
        "font_color_button_1_plain": "#2762e9",
        "font_color_button_1_plain_hover": "#2762e9",
        "font_color_button_1_plain_disabled": "#b8b3a4",
        "font_color_button_1_destructive": "#c04020",
        "font_color_button_1_destructive_hover": "#c04020",
        "font_color_button_1_destructive_disabled": "#b8b3a4",
        "font_color_button_1_secondary": "#423f37",
        "font_color_button_1_secondary_hover": "#423f37",
        "font_color_button_1_secondary_disabled": "#b8b3a4",
        "font_color_button_1_tertiary": "#2762e9",
        "font_color_button_1_tertiary_hover": "#749dfc",
        "font_color_button_1_tertiary_disabled": "#b8b3a4",
        "font_color_button_1_tertiary_clicked": "#173a8a",
        "font_color_button_1_tertiary_alert": "#f25129",
        "font_color_button_1_tertiary_alert_hover": "#fc7958",
        "font_color_button_1_tertiary_alert_disabled": "#b8b3a4",
        "font_color_button_1_tertiary_alert_clicked": "#722613",
        "font_color_button_1_destructive_clicked": "#722613",
        "font_color_button_1_default": "#423f37",
        "font_color_button_1_default_hover": "#423f37",
        "font_color_button_1_default_disabled": "#777676",
        "font_color_button_2": "#423f37",
        "font_color_button_2_primary": "#503c0d",
        "font_color_button_2_primary_hover": "#503c0d",
        "font_color_button_2_primary_disabled": "#ca9722",
        "font_color_button_2_plain": "#2762e9",
        "font_color_button_2_plain_hover": "#2762e9",
        "font_color_button_2_plain_disabled": "#b8b3a4",
        "font_color_button_2_destructive": "#c04020",
        "font_color_button_2_destructive_hover": "#c04020",
        "font_color_button_2_destructive_disabled": "#b8b3a4",
        "font_color_button_2_secondary": "#423f37",
        "font_color_button_2_secondary_hover": "#423f37",
        "font_color_button_2_secondary_disabled": "#b8b3a4",
        "font_color_button_2_tertiary": "#2762e9",
        "font_color_button_2_tertiary_hover": "#749dfc",
        "font_color_button_2_tertiary_disabled": "#a79f8e",
        "font_color_button_2_tertiary_clicked": "#173a8a",
        "font_color_button_2_destructive_clicked": "#722613",
        "font_color_button_2_default": "#423f37",
        "font_color_button_2_default_hover": "#423f37",
        "font_color_button_2_default_disabled": "#777676",
        "font_color_body_thick": "#147975",
        "font_color_body": "#147975",
        "font_color_secondary_body_thick": "#147975",
        "font_color_secondary_body": "#147975",
        "font_color_overline_title_1": "#147975",
        "font_color_button_3": "#423f37",
        "font_color_overline_title_2": "#147975",
        "font_color_caption": "#199995",
        "font_color_footnote": "#199995",
        "font_color_icon": "#423f37",
        "font_color_icon_inactive": "#c8c3b8",
        "font_color_status_default": "#493300",
        "font_color_status_message": "#493300",
        "font_color_status_alert": "#510506",
        "font_color_status_positive": "#0c3421",
        "font_color_link": "#2762e9",
        "font_color_bullet": "#2c3637",
        "background_color_clear": "#d6f3f2",
        "background_color_primary": "#fbbd2a",
        "background_color_primary_hover": "#fccd5f",
        "background_color_primary_disabled": "#fddd94",
        "background_color_primary_clicked": "#fbbd2a",
        "background_color_plain": "#e6eeff",
        "background_color_plain_hover": "#d4e1fe",
        "background_color_plain_disabled": "#e3e1db",
        "background_color_plain_clicked": "#c1d3fe",
        "background_color_destructive": "#fee9e4",
        "background_color_destructive_hover": "#fed9cf",
        "background_color_destructive_disabled": "#e3e1db",
        "background_color_destructive_clicked": "#fec6b8",
        "background_color_secondary": "#ffffff",
        "background_color_secondary_hover": "#ffffff",
        "background_color_secondary_disabled": "#e1e1e1",
        "background_color_secondary_clicked": "#ffffff",
        "background_color_default": "#d6d3ca",
        "background_color_footer": "#dbf1f9",
        "background_color_danger": "#fdb19e",
        "background_color_positive": "#25dda6",
        "background_color_interactive": "#abc4fd",
        "background_color_status_default": "#fef0ce",
        "background_color_status_message": "#fef0ce",
        "background_color_status_alert": "#fccdce",
        "background_color_status_positive": "#d5f5e6",
        "background_color_active": "#fef0ce",
        "background_color_active_hover": "#fef0ce",
        "background_color_active_disabled": "#e1e1e1",
        "background_color_inactive_hover": "#fef0ce",
        "background_color_inactive_disabled": "#e1e1e1",
        "background_color_bullet": "#c8e7f9",
        "border_buttons_secondary": "2px solid #E3E1DB",
        "border_radius_buttons": 48,
        "border_radius_blocks": "32px",
        "border_radius_blocks_mobile": "24px 16px",
        "border_radius_push": "16px",
        "border_radius_tags": 20,
        "border_radius_standard": 8,
        "shadow_standard": "0px 2px 4px rgba(42, 41, 40, 0.15)",
        "shadow_buttons": "0px 2px 0px #363533",
        "shadow_level_01": "0px 4px 8px rgba(66, 63, 55, 0.1), 0px -2px 8px rgba(66, 63, 55, 0.02)",
        "shadow_level_02": "0px 6px 12px rgba(66, 63, 55, 0.1), 0px -2px 12px rgba(66, 63, 55, 0.02)",
        "shadow_level_03": "0px 8px 24px rgba(66, 63, 55, 0.1), 0px -2px 24px rgba(66, 63, 55, 0.02)",
        "shadow_level_04": "0px 16px 32px rgba(66, 63, 55, 0.1), 0px -2px 4px rgba(66, 63, 55, 0.04)",
        "shadow_level_05": "0px 16px 40px rgba(66, 63, 55, 0.1), 0px -4px 40px rgba(66, 63, 55, 0.04)",
        "shadow_level_06": "0px 24px 48px rgba(66, 63, 55, 0.1), 0px -4px 28px rgba(66, 63, 55, 0.04)",
        "typos_common_font_style": "normal",
        "typos_common_line_height": "150%",
        "typos_common_font_weight": 400,
        "typos_standard_common_font_family": "'Nunito', sans-serif",
        "typos_standard_common_letter_spacing": 0.25,
        "typos_standard_extra_large_title_font_size": 64,
        "typos_standard_extra_large_title_mobile_font_size": 48,
        "typos_standard_extra_large_title_font_weight": 800,
        "typos_standard_large_title_font_size": 32,
        "typos_standard_extra_large_title_mobile_font_weight": 800,
        "typos_standard_large_title_font_weight": 800,
        "typos_standard_title_1_font_size": 24,
        "typos_standard_title_1_font_weight": 800,
        "typos_standard_title_2_font_size": 20,
        "typos_standard_title_2_font_weight": 800,
        "typos_standard_overline_large_title_font_size": 16,
        "typos_standard_overline_large_title_font_weight": 600,
        "typos_standard_overline_large_title_text_transform": "uppercase",
        "typos_standard_button_1_font_size": 16,
        "typos_standard_button_1_font_weight": 700,
        "typos_standard_button_2_font_size": 14,
        "typos_standard_button_2_font_weight": 700,
        "typos_standard_body_thick_font_size": 16,
        "typos_standard_body_thick_font_weight": 800,
        "typos_standard_body_font_size": 16,
        "typos_standard_body_font_weight": 600,
        "typos_standard_secondary_body_thick_font_size": 14,
        "typos_standard_secondary_body_thick_font_weight": 800,
        "typos_standard_secondary_body_font_size": 14,
        "typos_standard_secondary_body_font_weight": 600,
        "typos_standard_overline_title_1_font_size": 14,
        "typos_standard_overline_title_1_font_weight": 600,
        "typos_standard_overline_title_1_text_transform": "uppercase",
        "typos_standard_button_3_font_size": 12,
        "typos_standard_button_3_font_weight": 700,
        "typos_standard_button_3_text_transform": "uppercase",
        "typos_standard_overline_title_2_font_size": 12,
        "typos_standard_overline_title_2_font_weight": 600,
        "typos_standard_overline_title_2_text_transform": "uppercase",
        "typos_standard_caption_font_size": 12,
        "typos_standard_caption_font_weight": 600,
        "typos_standard_footnote_font_size": 11,
        "typos_standard_footnote_font_weight": 600,
        "typos_content_common_font_family": "'Roboto Slab', serif",
        "typos_content_common_letter_spacing": 0.35,
        "typos_content_extra_large_title_font_size": 64,
        "typos_content_extra_large_title_font_weight": 800,
        "typos_content_extra_large_title_letter_spacing": 0.8,
        "typos_content_large_title_font_size": 32,
        "typos_content_large_title_font_weight": 700,
        "typos_content_large_title_letter_spacing": 0.8,
        "typos_content_title_1_font_size": 24,
        "typos_content_title_1_font_weight": 700,
        "typos_content_title_1_letter_spacing": 0.6,
        "typos_content_title_2_font_size": 20,
        "typos_content_title_2_font_weight": 700,
        "typos_content_title_2_letter_spacing": 0.5,
        "typos_content_body_thick_font_size": 16,
        "typos_content_body_thick_font_weight": 700,
        "typos_content_body_font_size": 16
    },
    "brand-tertiary-800": {
        "name": "Lunii Turquoise (Brand Tertiary - 800)",
        "font_color_extra_large_title": "#ffffff",
        "font_color_large_title": "#ffffff",
        "font_color_large_title_bold": "#fbbd2a",
        "font_color_title_1": "#ffffff",
        "font_color_title_2": "#f2fbfb",
        "font_color_overline_large_title": "#f2fbfb",
        "font_color_button_1": "#423f37",
        "font_color_button_1_primary": "#503c0d",
        "font_color_button_1_primary_hover": "#503c0d",
        "font_color_button_1_primary_disabled": "#ca9722",
        "font_color_button_1_plain": "#2762e9",
        "font_color_button_1_plain_hover": "#2762e9",
        "font_color_button_1_plain_disabled": "#b8b3a4",
        "font_color_button_1_destructive": "#c04020",
        "font_color_button_1_destructive_hover": "#c04020",
        "font_color_button_1_destructive_disabled": "#b8b3a4",
        "font_color_button_1_secondary": "#423f37",
        "font_color_button_1_secondary_hover": "#423f37",
        "font_color_button_1_secondary_disabled": "#b8b3a4",
        "font_color_button_1_tertiary": "#2762e9",
        "font_color_button_1_tertiary_hover": "#749dfc",
        "font_color_button_1_tertiary_disabled": "#b8b3a4",
        "font_color_button_1_tertiary_clicked": "#173a8a",
        "font_color_button_1_tertiary_alert": "#f25129",
        "font_color_button_1_tertiary_alert_hover": "#fc7958",
        "font_color_button_1_tertiary_alert_disabled": "#b8b3a4",
        "font_color_button_1_tertiary_alert_clicked": "#722613",
        "font_color_button_1_destructive_clicked": "#722613",
        "font_color_button_1_default": "#423f37",
        "font_color_button_1_default_hover": "#423f37",
        "font_color_button_1_default_disabled": "#777676",
        "font_color_button_2": "#423f37",
        "font_color_button_2_primary": "#503c0d",
        "font_color_button_2_primary_hover": "#503c0d",
        "font_color_button_2_primary_disabled": "#ca9722",
        "font_color_button_2_plain": "#2762e9",
        "font_color_button_2_plain_hover": "#2762e9",
        "font_color_button_2_plain_disabled": "#b8b3a4",
        "font_color_button_2_destructive": "#c04020",
        "font_color_button_2_destructive_hover": "#c04020",
        "font_color_button_2_destructive_disabled": "#b8b3a4",
        "font_color_button_2_secondary": "#423f37",
        "font_color_button_2_secondary_hover": "#423f37",
        "font_color_button_2_secondary_disabled": "#b8b3a4",
        "font_color_button_2_tertiary": "#2762e9",
        "font_color_button_2_tertiary_hover": "#749dfc",
        "font_color_button_2_tertiary_disabled": "#a79f8e",
        "font_color_button_2_tertiary_clicked": "#173a8a",
        "font_color_button_2_destructive_clicked": "#722613",
        "font_color_button_2_default": "#423f37",
        "font_color_button_2_default_hover": "#423f37",
        "font_color_button_2_default_disabled": "#777676",
        "font_color_body_thick": "#f2fbfb",
        "font_color_body": "#f2fbfb",
        "font_color_secondary_body_thick": "#f2fbfb",
        "font_color_secondary_body": "#f2fbfb",
        "font_color_overline_title_1": "#f2fbfb",
        "font_color_button_3": "#423f37",
        "font_color_overline_title_2": "#f2fbfb",
        "font_color_caption": "#f2fbfb",
        "font_color_footnote": "#f2fbfb",
        "font_color_icon": "#ffffff",
        "font_color_icon_inactive": "#ffffff",
        "font_color_status_default": "#493300",
        "font_color_status_message": "#493300",
        "font_color_status_alert": "#510506",
        "font_color_status_positive": "#0c3421",
        "font_color_link": "#2762e9",
        "font_color_bullet": "#2c3637",
        "background_color_clear": "#097f82",
        "background_color_primary": "#097f82",
        "background_color_primary_hover": "#fccd5f",
        "background_color_primary_disabled": "#fddd94",
        "background_color_primary_clicked": "#fbbd2a",
        "background_color_plain": "#e6eeff",
        "background_color_plain_hover": "#d4e1fe",
        "background_color_plain_disabled": "#e3e1db",
        "background_color_plain_clicked": "#c1d3fe",
        "background_color_destructive": "#fee9e4",
        "background_color_destructive_hover": "#fed9cf",
        "background_color_destructive_disabled": "#e3e1db",
        "background_color_destructive_clicked": "#fec6b8",
        "background_color_secondary": "#ffffff",
        "background_color_secondary_hover": "#ffffff",
        "background_color_secondary_disabled": "#e1e1e1",
        "background_color_secondary_clicked": "#ffffff",
        "background_color_default": "#d6d3ca",
        "background_color_footer": "#dbf1f9",
        "background_color_danger": "#fdb19e",
        "background_color_positive": "#25dda6",
        "background_color_interactive": "#abc4fd",
        "background_color_status_default": "#fef0ce",
        "background_color_status_message": "#fef0ce",
        "background_color_status_alert": "#fccdce",
        "background_color_status_positive": "#d5f5e6",
        "background_color_active": "#fef0ce",
        "background_color_active_hover": "#fef0ce",
        "background_color_active_disabled": "#e1e1e1",
        "background_color_inactive_hover": "#fef0ce",
        "background_color_inactive_disabled": "#e1e1e1",
        "background_color_bullet": "#c8e7f9",
        "border_buttons_secondary": "2px solid #E3E1DB",
        "border_radius_buttons": 48,
        "border_radius_blocks": "32px",
        "border_radius_blocks_mobile": "24px 16px",
        "border_radius_push": "16px",
        "border_radius_tags": 20,
        "border_radius_standard": 8,
        "shadow_standard": "0px 2px 4px rgba(42, 41, 40, 0.15)",
        "shadow_buttons": "0px 2px 0px #363533",
        "shadow_level_01": "0px 4px 8px rgba(66, 63, 55, 0.1), 0px -2px 8px rgba(66, 63, 55, 0.02)",
        "shadow_level_02": "0px 6px 12px rgba(66, 63, 55, 0.1), 0px -2px 12px rgba(66, 63, 55, 0.02)",
        "shadow_level_03": "0px 8px 24px rgba(66, 63, 55, 0.1), 0px -2px 24px rgba(66, 63, 55, 0.02)",
        "shadow_level_04": "0px 16px 32px rgba(66, 63, 55, 0.1), 0px -2px 4px rgba(66, 63, 55, 0.04)",
        "shadow_level_05": "0px 16px 40px rgba(66, 63, 55, 0.1), 0px -4px 40px rgba(66, 63, 55, 0.04)",
        "shadow_level_06": "0px 24px 48px rgba(66, 63, 55, 0.1), 0px -4px 28px rgba(66, 63, 55, 0.04)",
        "typos_common_font_style": "normal",
        "typos_common_line_height": "150%",
        "typos_common_font_weight": 400,
        "typos_standard_common_font_family": "'Nunito', sans-serif",
        "typos_standard_common_letter_spacing": 0.25,
        "typos_standard_extra_large_title_font_size": 64,
        "typos_standard_extra_large_title_mobile_font_size": 48,
        "typos_standard_extra_large_title_font_weight": 800,
        "typos_standard_large_title_font_size": 32,
        "typos_standard_extra_large_title_mobile_font_weight": 800,
        "typos_standard_large_title_font_weight": 800,
        "typos_standard_title_1_font_size": 24,
        "typos_standard_title_1_font_weight": 800,
        "typos_standard_title_2_font_size": 20,
        "typos_standard_title_2_font_weight": 800,
        "typos_standard_overline_large_title_font_size": 16,
        "typos_standard_overline_large_title_font_weight": 600,
        "typos_standard_overline_large_title_text_transform": "uppercase",
        "typos_standard_button_1_font_size": 16,
        "typos_standard_button_1_font_weight": 700,
        "typos_standard_button_2_font_size": 14,
        "typos_standard_button_2_font_weight": 700,
        "typos_standard_body_thick_font_size": 16,
        "typos_standard_body_thick_font_weight": 800,
        "typos_standard_body_font_size": 16,
        "typos_standard_body_font_weight": 600,
        "typos_standard_secondary_body_thick_font_size": 14,
        "typos_standard_secondary_body_thick_font_weight": 800,
        "typos_standard_secondary_body_font_size": 14,
        "typos_standard_secondary_body_font_weight": 600,
        "typos_standard_overline_title_1_font_size": 14,
        "typos_standard_overline_title_1_font_weight": 600,
        "typos_standard_overline_title_1_text_transform": "uppercase",
        "typos_standard_button_3_font_size": 12,
        "typos_standard_button_3_font_weight": 700,
        "typos_standard_button_3_text_transform": "uppercase",
        "typos_standard_overline_title_2_font_size": 12,
        "typos_standard_overline_title_2_font_weight": 600,
        "typos_standard_overline_title_2_text_transform": "uppercase",
        "typos_standard_caption_font_size": 12,
        "typos_standard_caption_font_weight": 600,
        "typos_standard_footnote_font_size": 11,
        "typos_standard_footnote_font_weight": 600,
        "typos_content_common_font_family": "'Roboto Slab', serif",
        "typos_content_common_letter_spacing": 0.35,
        "typos_content_extra_large_title_font_size": 64,
        "typos_content_extra_large_title_font_weight": 800,
        "typos_content_extra_large_title_letter_spacing": 0.8,
        "typos_content_large_title_font_size": 32,
        "typos_content_large_title_font_weight": 700,
        "typos_content_large_title_letter_spacing": 0.8,
        "typos_content_title_1_font_size": 24,
        "typos_content_title_1_font_weight": 700,
        "typos_content_title_1_letter_spacing": 0.6,
        "typos_content_title_2_font_size": 20,
        "typos_content_title_2_font_weight": 700,
        "typos_content_title_2_letter_spacing": 0.5,
        "typos_content_body_thick_font_size": 16,
        "typos_content_body_thick_font_weight": 700,
        "typos_content_body_font_size": 16
    },
    "danger-100": {
        "name": "Danger (Danger-100)",
        "font_color_extra_large_title": "#f01f1f",
        "font_color_large_title": "#e20e0e",
        "font_color_large_title_bold": "#e01c1c",
        "font_color_title_1": "#e91515",
        "font_color_title_2": "#d82020",
        "font_color_overline_large_title": "#e63131",
        "font_color_button_1": "#e61717",
        "font_color_button_1_primary": "#141313",
        "font_color_button_1_primary_hover": "#503c0d",
        "font_color_button_1_primary_disabled": "#ca9722",
        "font_color_button_1_plain": "#2762e9",
        "font_color_button_1_plain_hover": "#2762e9",
        "font_color_button_1_plain_disabled": "#b8b3a4",
        "font_color_button_1_destructive": "#c04020",
        "font_color_button_1_destructive_hover": "#c04020",
        "font_color_button_1_destructive_disabled": "#b8b3a4",
        "font_color_button_1_secondary": "#423f37",
        "font_color_button_1_secondary_hover": "#423f37",
        "font_color_button_1_secondary_disabled": "#b8b3a4",
        "font_color_button_1_tertiary": "#2762e9",
        "font_color_button_1_tertiary_hover": "#749dfc",
        "font_color_button_1_tertiary_disabled": "#b8b3a4",
        "font_color_button_1_tertiary_clicked": "#173a8a",
        "font_color_button_1_tertiary_alert": "#f25129",
        "font_color_button_1_tertiary_alert_hover": "#fc7958",
        "font_color_button_1_tertiary_alert_disabled": "#b8b3a4",
        "font_color_button_1_tertiary_alert_clicked": "#722613",
        "font_color_button_1_destructive_clicked": "#722613",
        "font_color_button_1_default": "#423f37",
        "font_color_button_1_default_hover": "#423f37",
        "font_color_button_1_default_disabled": "#777676",
        "font_color_button_2": "#423f37",
        "font_color_button_2_primary": "#503c0d",
        "font_color_button_2_primary_hover": "#503c0d",
        "font_color_button_2_primary_disabled": "#ca9722",
        "font_color_button_2_plain": "#2762e9",
        "font_color_button_2_plain_hover": "#2762e9",
        "font_color_button_2_plain_disabled": "#b8b3a4",
        "font_color_button_2_destructive": "#c04020",
        "font_color_button_2_destructive_hover": "#c04020",
        "font_color_button_2_destructive_disabled": "#b8b3a4",
        "font_color_button_2_secondary": "#423f37",
        "font_color_button_2_secondary_hover": "#423f37",
        "font_color_button_2_secondary_disabled": "#b8b3a4",
        "font_color_button_2_tertiary": "#2762e9",
        "font_color_button_2_tertiary_hover": "#749dfc",
        "font_color_button_2_tertiary_disabled": "#a79f8e",
        "font_color_button_2_tertiary_clicked": "#173a8a",
        "font_color_button_2_destructive_clicked": "#722613",
        "font_color_button_2_default": "#423f37",
        "font_color_button_2_default_hover": "#423f37",
        "font_color_button_2_default_disabled": "#777676",
        "font_color_body_thick": "#423f37",
        "font_color_body": "#716c5e",
        "font_color_secondary_body_thick": "#423f37",
        "font_color_secondary_body": "#423f37",
        "font_color_overline_title_1": "#716c5e",
        "font_color_button_3": "#423f37",
        "font_color_overline_title_2": "#716c5e",
        "font_color_caption": "#908977",
        "font_color_footnote": "#908977",
        "font_color_icon": "#423f37",
        "font_color_icon_inactive": "#c8c3b8",
        "font_color_status_default": "#493300",
        "font_color_status_message": "#493300",
        "font_color_status_alert": "#510506",
        "font_color_status_positive": "#0c3421",
        "font_color_link": "#2762e9",
        "font_color_bullet": "#2c3637",
        "background_color_clear": "#c25151",
        "background_color_primary": "#b95454",
        "background_color_primary_hover": "#b83737",
        "background_color_primary_disabled": "#a71f1f",
        "background_color_primary_clicked": "#fbbd2a",
        "background_color_plain": "#e6eeff",
        "background_color_plain_hover": "#d4e1fe",
        "background_color_plain_disabled": "#e3e1db",
        "background_color_plain_clicked": "#c1d3fe",
        "background_color_destructive": "#fee9e4",
        "background_color_destructive_hover": "#fed9cf",
        "background_color_destructive_disabled": "#e3e1db",
        "background_color_destructive_clicked": "#fec6b8",
        "background_color_secondary": "#ffffff",
        "background_color_secondary_hover": "#ffffff",
        "background_color_secondary_disabled": "#e1e1e1",
        "background_color_secondary_clicked": "#ffffff",
        "background_color_default": "#d6d3ca",
        "background_color_footer": "#dbf1f9",
        "background_color_danger": "#fdb19e",
        "background_color_positive": "#25dda6",
        "background_color_interactive": "#abc4fd",
        "background_color_status_default": "#fef0ce",
        "background_color_status_message": "#fef0ce",
        "background_color_status_alert": "#fccdce",
        "background_color_status_positive": "#d5f5e6",
        "background_color_active": "#fef0ce",
        "background_color_active_hover": "#fef0ce",
        "background_color_active_disabled": "#e1e1e1",
        "background_color_inactive_hover": "#fef0ce",
        "background_color_inactive_disabled": "#e1e1e1",
        "background_color_bullet": "#c8e7f9",
        "border_buttons_secondary": "2px solid #E3E1DB",
        "border_radius_buttons": 48,
        "border_radius_blocks": "32px",
        "border_radius_blocks_mobile": "24px 16px",
        "border_radius_push": "16px",
        "border_radius_tags": 20,
        "border_radius_standard": 8,
        "shadow_standard": "0px 2px 4px rgba(42, 41, 40, 0.15)",
        "shadow_buttons": "0px 2px 0px #363533",
        "shadow_level_01": "0px 4px 8px rgba(66, 63, 55, 0.1), 0px -2px 8px rgba(66, 63, 55, 0.02)",
        "shadow_level_02": "0px 6px 12px rgba(66, 63, 55, 0.1), 0px -2px 12px rgba(66, 63, 55, 0.02)",
        "shadow_level_03": "0px 8px 24px rgba(66, 63, 55, 0.1), 0px -2px 24px rgba(66, 63, 55, 0.02)",
        "shadow_level_04": "0px 16px 32px rgba(66, 63, 55, 0.1), 0px -2px 4px rgba(66, 63, 55, 0.04)",
        "shadow_level_05": "0px 16px 40px rgba(66, 63, 55, 0.1), 0px -4px 40px rgba(66, 63, 55, 0.04)",
        "shadow_level_06": "0px 24px 48px rgba(66, 63, 55, 0.1), 0px -4px 28px rgba(66, 63, 55, 0.04)",
        "typos_common_font_style": "normal",
        "typos_common_line_height": "150%",
        "typos_common_font_weight": 400,
        "typos_standard_common_font_family": "'Nunito', sans-serif",
        "typos_standard_common_letter_spacing": 0.25,
        "typos_standard_extra_large_title_font_size": 64,
        "typos_standard_extra_large_title_mobile_font_size": 48,
        "typos_standard_extra_large_title_font_weight": 800,
        "typos_standard_large_title_font_size": 32,
        "typos_standard_extra_large_title_mobile_font_weight": 800,
        "typos_standard_large_title_font_weight": 800,
        "typos_standard_title_1_font_size": 24,
        "typos_standard_title_1_font_weight": 800,
        "typos_standard_title_2_font_size": 20,
        "typos_standard_title_2_font_weight": 800,
        "typos_standard_overline_large_title_font_size": 16,
        "typos_standard_overline_large_title_font_weight": 600,
        "typos_standard_overline_large_title_text_transform": "uppercase",
        "typos_standard_button_1_font_size": 16,
        "typos_standard_button_1_font_weight": 700,
        "typos_standard_button_2_font_size": 14,
        "typos_standard_button_2_font_weight": 700,
        "typos_standard_body_thick_font_size": 16,
        "typos_standard_body_thick_font_weight": 800,
        "typos_standard_body_font_size": 16,
        "typos_standard_body_font_weight": 600,
        "typos_standard_secondary_body_thick_font_size": 14,
        "typos_standard_secondary_body_thick_font_weight": 800,
        "typos_standard_secondary_body_font_size": 14,
        "typos_standard_secondary_body_font_weight": 600,
        "typos_standard_overline_title_1_font_size": 14,
        "typos_standard_overline_title_1_font_weight": 600,
        "typos_standard_overline_title_1_text_transform": "uppercase",
        "typos_standard_button_3_font_size": 12,
        "typos_standard_button_3_font_weight": 700,
        "typos_standard_button_3_text_transform": "uppercase",
        "typos_standard_overline_title_2_font_size": 12,
        "typos_standard_overline_title_2_font_weight": 600,
        "typos_standard_overline_title_2_text_transform": "uppercase",
        "typos_standard_caption_font_size": 12,
        "typos_standard_caption_font_weight": 600,
        "typos_standard_footnote_font_size": 11,
        "typos_standard_footnote_font_weight": 600,
        "typos_content_common_font_family": "'Roboto Slab', serif",
        "typos_content_common_letter_spacing": 0.35,
        "typos_content_extra_large_title_font_size": 64,
        "typos_content_extra_large_title_font_weight": 800,
        "typos_content_extra_large_title_letter_spacing": 0.8,
        "typos_content_large_title_font_size": 32,
        "typos_content_large_title_font_weight": 700,
        "typos_content_large_title_letter_spacing": 0.8,
        "typos_content_title_1_font_size": 24,
        "typos_content_title_1_font_weight": 700,
        "typos_content_title_1_letter_spacing": 0.6,
        "typos_content_title_2_font_size": 20,
        "typos_content_title_2_font_weight": 700,
        "typos_content_title_2_letter_spacing": 0.5,
        "typos_content_body_thick_font_size": 16,
        "typos_content_body_thick_font_weight": 700,
        "typos_content_body_font_size": 16
    },
    "ui-900": {
        "name": "Dark (UI / 900)",
        "font_color_extra_large_title": "#ffffff",
        "font_color_large_title": "#ffffff",
        "font_color_large_title_bold": "#fbbd2a",
        "font_color_title_1": "#ffffff",
        "font_color_title_2": "#ffffff",
        "font_color_overline_large_title": "#faf9f8",
        "font_color_button_1": "#423f37",
        "font_color_button_1_primary": "#503c0d",
        "font_color_button_1_primary_hover": "#503c0d",
        "font_color_button_1_primary_disabled": "#ca9722",
        "font_color_button_1_plain": "#2762e9",
        "font_color_button_1_plain_hover": "#2762e9",
        "font_color_button_1_plain_disabled": "#b8b3a4",
        "font_color_button_1_destructive": "#c04020",
        "font_color_button_1_destructive_hover": "#c04020",
        "font_color_button_1_destructive_disabled": "#b8b3a4",
        "font_color_button_1_secondary": "#423f37",
        "font_color_button_1_secondary_hover": "#423f37",
        "font_color_button_1_secondary_disabled": "#b8b3a4",
        "font_color_button_1_tertiary": "#2762e9",
        "font_color_button_1_tertiary_hover": "#749dfc",
        "font_color_button_1_tertiary_disabled": "#b8b3a4",
        "font_color_button_1_tertiary_clicked": "#173a8a",
        "font_color_button_1_tertiary_alert": "#f25129",
        "font_color_button_1_tertiary_alert_hover": "#fc7958",
        "font_color_button_1_tertiary_alert_disabled": "#b8b3a4",
        "font_color_button_1_tertiary_alert_clicked": "#722613",
        "font_color_button_1_destructive_clicked": "#722613",
        "font_color_button_1_default": "#423f37",
        "font_color_button_1_default_hover": "#423f37",
        "font_color_button_1_default_disabled": "#777676",
        "font_color_button_2": "#423f37",
        "font_color_button_2_primary": "#503c0d",
        "font_color_button_2_primary_hover": "#503c0d",
        "font_color_button_2_primary_disabled": "#ca9722",
        "font_color_button_2_plain": "#2762e9",
        "font_color_button_2_plain_hover": "#2762e9",
        "font_color_button_2_plain_disabled": "#b8b3a4",
        "font_color_button_2_destructive": "#c04020",
        "font_color_button_2_destructive_hover": "#c04020",
        "font_color_button_2_destructive_disabled": "#b8b3a4",
        "font_color_button_2_secondary": "#423f37",
        "font_color_button_2_secondary_hover": "#423f37",
        "font_color_button_2_secondary_disabled": "#b8b3a4",
        "font_color_button_2_tertiary": "#2762e9",
        "font_color_button_2_tertiary_hover": "#749dfc",
        "font_color_button_2_tertiary_disabled": "#a79f8e",
        "font_color_button_2_tertiary_clicked": "#173a8a",
        "font_color_button_2_destructive_clicked": "#722613",
        "font_color_button_2_default": "#423f37",
        "font_color_button_2_default_hover": "#423f37",
        "font_color_button_2_default_disabled": "#777676",
        "font_color_body_thick": "#faf9f8",
        "font_color_body": "#faf9f8",
        "font_color_secondary_body_thick": "#faf9f8",
        "font_color_secondary_body": "#faf9f8",
        "font_color_overline_title_1": "#faf9f8",
        "font_color_button_3": "#423f37",
        "font_color_overline_title_2": "#faf9f8",
        "font_color_caption": "#efedea",
        "font_color_footnote": "#efedea",
        "font_color_icon": "#423f37",
        "font_color_icon_inactive": "#c8c3b8",
        "font_color_status_default": "#493300",
        "font_color_status_message": "#493300",
        "font_color_status_alert": "#510506",
        "font_color_status_positive": "#0c3421",
        "font_color_link": "#2762e9",
        "font_color_bullet": "#2c3637",
        "background_color_clear": "#423f37",
        "background_color_primary": "#fbbd2a",
        "background_color_primary_hover": "#fccd5f",
        "background_color_primary_disabled": "#fddd94",
        "background_color_primary_clicked": "#fbbd2a",
        "background_color_plain": "#e6eeff",
        "background_color_plain_hover": "#d4e1fe",
        "background_color_plain_disabled": "#e3e1db",
        "background_color_plain_clicked": "#c1d3fe",
        "background_color_destructive": "#fee9e4",
        "background_color_destructive_hover": "#fed9cf",
        "background_color_destructive_disabled": "#e3e1db",
        "background_color_destructive_clicked": "#fec6b8",
        "background_color_secondary": "#ffffff",
        "background_color_secondary_hover": "#ffffff",
        "background_color_secondary_disabled": "#e1e1e1",
        "background_color_secondary_clicked": "#ffffff",
        "background_color_default": "#d6d3ca",
        "background_color_footer": "#dbf1f9",
        "background_color_danger": "#fdb19e",
        "background_color_positive": "#25dda6",
        "background_color_interactive": "#abc4fd",
        "background_color_status_default": "#fef0ce",
        "background_color_status_message": "#fef0ce",
        "background_color_status_alert": "#fccdce",
        "background_color_status_positive": "#d5f5e6",
        "background_color_active": "#fef0ce",
        "background_color_active_hover": "#fef0ce",
        "background_color_active_disabled": "#e1e1e1",
        "background_color_inactive_hover": "#fef0ce",
        "background_color_inactive_disabled": "#e1e1e1",
        "background_color_bullet": "#c8e7f9",
        "border_buttons_secondary": "2px solid #E3E1DB",
        "border_radius_buttons": 48,
        "border_radius_blocks": "32px",
        "border_radius_blocks_mobile": "24px 16px",
        "border_radius_push": "16px",
        "border_radius_tags": 20,
        "border_radius_standard": 8,
        "shadow_standard": "0px 2px 4px rgba(42, 41, 40, 0.15)",
        "shadow_buttons": "0px 2px 0px #363533",
        "shadow_level_01": "0px 4px 8px rgba(66, 63, 55, 0.1), 0px -2px 8px rgba(66, 63, 55, 0.02)",
        "shadow_level_02": "0px 6px 12px rgba(66, 63, 55, 0.1), 0px -2px 12px rgba(66, 63, 55, 0.02)",
        "shadow_level_03": "0px 8px 24px rgba(66, 63, 55, 0.1), 0px -2px 24px rgba(66, 63, 55, 0.02)",
        "shadow_level_04": "0px 16px 32px rgba(66, 63, 55, 0.1), 0px -2px 4px rgba(66, 63, 55, 0.04)",
        "shadow_level_05": "0px 16px 40px rgba(66, 63, 55, 0.1), 0px -4px 40px rgba(66, 63, 55, 0.04)",
        "shadow_level_06": "0px 24px 48px rgba(66, 63, 55, 0.1), 0px -4px 28px rgba(66, 63, 55, 0.04)",
        "typos_common_font_style": "normal",
        "typos_common_line_height": "150%",
        "typos_common_font_weight": 400,
        "typos_standard_common_font_family": "'Nunito', sans-serif",
        "typos_standard_common_letter_spacing": 0.25,
        "typos_standard_extra_large_title_font_size": 64,
        "typos_standard_extra_large_title_mobile_font_size": 48,
        "typos_standard_extra_large_title_font_weight": 800,
        "typos_standard_large_title_font_size": 32,
        "typos_standard_extra_large_title_mobile_font_weight": 800,
        "typos_standard_large_title_font_weight": 800,
        "typos_standard_title_1_font_size": 24,
        "typos_standard_title_1_font_weight": 800,
        "typos_standard_title_2_font_size": 20,
        "typos_standard_title_2_font_weight": 800,
        "typos_standard_overline_large_title_font_size": 16,
        "typos_standard_overline_large_title_font_weight": 600,
        "typos_standard_overline_large_title_text_transform": "uppercase",
        "typos_standard_button_1_font_size": 16,
        "typos_standard_button_1_font_weight": 700,
        "typos_standard_button_2_font_size": 14,
        "typos_standard_button_2_font_weight": 700,
        "typos_standard_body_thick_font_size": 16,
        "typos_standard_body_thick_font_weight": 800,
        "typos_standard_body_font_size": 16,
        "typos_standard_body_font_weight": 600,
        "typos_standard_secondary_body_thick_font_size": 14,
        "typos_standard_secondary_body_thick_font_weight": 800,
        "typos_standard_secondary_body_font_size": 14,
        "typos_standard_secondary_body_font_weight": 600,
        "typos_standard_overline_title_1_font_size": 14,
        "typos_standard_overline_title_1_font_weight": 600,
        "typos_standard_overline_title_1_text_transform": "uppercase",
        "typos_standard_button_3_font_size": 12,
        "typos_standard_button_3_font_weight": 700,
        "typos_standard_button_3_text_transform": "uppercase",
        "typos_standard_overline_title_2_font_size": 12,
        "typos_standard_overline_title_2_font_weight": 600,
        "typos_standard_overline_title_2_text_transform": "uppercase",
        "typos_standard_caption_font_size": 12,
        "typos_standard_caption_font_weight": 600,
        "typos_standard_footnote_font_size": 11,
        "typos_standard_footnote_font_weight": 600,
        "typos_content_common_font_family": "'Roboto Slab', serif",
        "typos_content_common_letter_spacing": 0.35,
        "typos_content_extra_large_title_font_size": 64,
        "typos_content_extra_large_title_font_weight": 800,
        "typos_content_extra_large_title_letter_spacing": 0.8,
        "typos_content_large_title_font_size": 32,
        "typos_content_large_title_font_weight": 700,
        "typos_content_large_title_letter_spacing": 0.8,
        "typos_content_title_1_font_size": 24,
        "typos_content_title_1_font_weight": 700,
        "typos_content_title_1_letter_spacing": 0.6,
        "typos_content_title_2_font_size": 20,
        "typos_content_title_2_font_weight": 700,
        "typos_content_title_2_letter_spacing": 0.5,
        "typos_content_body_thick_font_size": 16,
        "typos_content_body_thick_font_weight": 700,
        "typos_content_body_font_size": 16
    },
    "interactive-50": {
        "name": "Lunii Blue (Interactive / 50)",
        "font_color_extra_large_title": "#173a8a",
        "font_color_large_title": "#173a8a",
        "font_color_large_title_bold": "#fbbd2a",
        "font_color_title_1": "#173a8a",
        "font_color_title_2": "#173a8a",
        "font_color_overline_large_title": "#2762e9",
        "font_color_button_1": "#423f37",
        "font_color_button_1_primary": "#503c0d",
        "font_color_button_1_primary_hover": "#503c0d",
        "font_color_button_1_primary_disabled": "#ca9722",
        "font_color_button_1_plain": "#2762e9",
        "font_color_button_1_plain_hover": "#2762e9",
        "font_color_button_1_plain_disabled": "#b8b3a4",
        "font_color_button_1_destructive": "#c04020",
        "font_color_button_1_destructive_hover": "#c04020",
        "font_color_button_1_destructive_disabled": "#b8b3a4",
        "font_color_button_1_secondary": "#423f37",
        "font_color_button_1_secondary_hover": "#423f37",
        "font_color_button_1_secondary_disabled": "#b8b3a4",
        "font_color_button_1_tertiary": "#2762e9",
        "font_color_button_1_tertiary_hover": "#749dfc",
        "font_color_button_1_tertiary_disabled": "#b8b3a4",
        "font_color_button_1_tertiary_clicked": "#173a8a",
        "font_color_button_1_tertiary_alert": "#f25129",
        "font_color_button_1_tertiary_alert_hover": "#fc7958",
        "font_color_button_1_tertiary_alert_disabled": "#b8b3a4",
        "font_color_button_1_tertiary_alert_clicked": "#722613",
        "font_color_button_1_destructive_clicked": "#722613",
        "font_color_button_1_default": "#423f37",
        "font_color_button_1_default_hover": "#423f37",
        "font_color_button_1_default_disabled": "#777676",
        "font_color_button_2": "#423f37",
        "font_color_button_2_primary": "#503c0d",
        "font_color_button_2_primary_hover": "#503c0d",
        "font_color_button_2_primary_disabled": "#ca9722",
        "font_color_button_2_plain": "#2762e9",
        "font_color_button_2_plain_hover": "#2762e9",
        "font_color_button_2_plain_disabled": "#b8b3a4",
        "font_color_button_2_destructive": "#c04020",
        "font_color_button_2_destructive_hover": "#c04020",
        "font_color_button_2_destructive_disabled": "#b8b3a4",
        "font_color_button_2_secondary": "#423f37",
        "font_color_button_2_secondary_hover": "#423f37",
        "font_color_button_2_secondary_disabled": "#b8b3a4",
        "font_color_button_2_tertiary": "#2762e9",
        "font_color_button_2_tertiary_hover": "#749dfc",
        "font_color_button_2_tertiary_disabled": "#a79f8e",
        "font_color_button_2_tertiary_clicked": "#173a8a",
        "font_color_button_2_destructive_clicked": "#722613",
        "font_color_button_2_default": "#423f37",
        "font_color_button_2_default_hover": "#423f37",
        "font_color_button_2_default_disabled": "#777676",
        "font_color_body_thick": "#2762e9",
        "font_color_body": "#2762e9",
        "font_color_secondary_body_thick": "#2762e9",
        "font_color_secondary_body": "#2762e9",
        "font_color_overline_title_1": "#2762e9",
        "font_color_button_3": "#423f37",
        "font_color_overline_title_2": "#2762e9",
        "font_color_caption": "#4f83fc",
        "font_color_footnote": "#4f83fc",
        "font_color_icon": "#423f37",
        "font_color_icon_inactive": "#c8c3b8",
        "font_color_status_default": "#493300",
        "font_color_status_message": "#493300",
        "font_color_status_alert": "#510506",
        "font_color_status_positive": "#0c3421",
        "font_color_link": "#2762e9",
        "font_color_bullet": "#2c3637",
        "background_color_clear": "#f7f9ff",
        "background_color_primary": "#fbbd2a",
        "background_color_primary_hover": "#fccd5f",
        "background_color_primary_disabled": "#fddd94",
        "background_color_primary_clicked": "#fbbd2a",
        "background_color_plain": "#e6eeff",
        "background_color_plain_hover": "#d4e1fe",
        "background_color_plain_disabled": "#e3e1db",
        "background_color_plain_clicked": "#c1d3fe",
        "background_color_destructive": "#fee9e4",
        "background_color_destructive_hover": "#fed9cf",
        "background_color_destructive_disabled": "#e3e1db",
        "background_color_destructive_clicked": "#fec6b8",
        "background_color_secondary": "#ffffff",
        "background_color_secondary_hover": "#ffffff",
        "background_color_secondary_disabled": "#e1e1e1",
        "background_color_secondary_clicked": "#ffffff",
        "background_color_default": "#d6d3ca",
        "background_color_footer": "#dbf1f9",
        "background_color_danger": "#fdb19e",
        "background_color_positive": "#25dda6",
        "background_color_interactive": "#abc4fd",
        "background_color_status_default": "#fef0ce",
        "background_color_status_message": "#fef0ce",
        "background_color_status_alert": "#fccdce",
        "background_color_status_positive": "#d5f5e6",
        "background_color_active": "#fef0ce",
        "background_color_active_hover": "#fef0ce",
        "background_color_active_disabled": "#e1e1e1",
        "background_color_inactive_hover": "#fef0ce",
        "background_color_inactive_disabled": "#e1e1e1",
        "background_color_bullet": "#c8e7f9",
        "border_buttons_secondary": "2px solid #E3E1DB",
        "border_radius_buttons": 48,
        "border_radius_blocks": "32px",
        "border_radius_blocks_mobile": "24px 16px",
        "border_radius_push": "16px",
        "border_radius_tags": 20,
        "border_radius_standard": 8,
        "shadow_standard": "0px 2px 4px rgba(42, 41, 40, 0.15)",
        "shadow_buttons": "0px 2px 0px #363533",
        "shadow_level_01": "0px 4px 8px rgba(66, 63, 55, 0.1), 0px -2px 8px rgba(66, 63, 55, 0.02)",
        "shadow_level_02": "0px 6px 12px rgba(66, 63, 55, 0.1), 0px -2px 12px rgba(66, 63, 55, 0.02)",
        "shadow_level_03": "0px 8px 24px rgba(66, 63, 55, 0.1), 0px -2px 24px rgba(66, 63, 55, 0.02)",
        "shadow_level_04": "0px 16px 32px rgba(66, 63, 55, 0.1), 0px -2px 4px rgba(66, 63, 55, 0.04)",
        "shadow_level_05": "0px 16px 40px rgba(66, 63, 55, 0.1), 0px -4px 40px rgba(66, 63, 55, 0.04)",
        "shadow_level_06": "0px 24px 48px rgba(66, 63, 55, 0.1), 0px -4px 28px rgba(66, 63, 55, 0.04)",
        "typos_common_font_style": "normal",
        "typos_common_line_height": "150%",
        "typos_common_font_weight": 400,
        "typos_standard_common_font_family": "'Nunito', sans-serif",
        "typos_standard_common_letter_spacing": 0.25,
        "typos_standard_extra_large_title_font_size": 64,
        "typos_standard_extra_large_title_mobile_font_size": 48,
        "typos_standard_extra_large_title_font_weight": 800,
        "typos_standard_large_title_font_size": 32,
        "typos_standard_extra_large_title_mobile_font_weight": 800,
        "typos_standard_large_title_font_weight": 800,
        "typos_standard_title_1_font_size": 24,
        "typos_standard_title_1_font_weight": 800,
        "typos_standard_title_2_font_size": 20,
        "typos_standard_title_2_font_weight": 800,
        "typos_standard_overline_large_title_font_size": 16,
        "typos_standard_overline_large_title_font_weight": 600,
        "typos_standard_overline_large_title_text_transform": "uppercase",
        "typos_standard_button_1_font_size": 16,
        "typos_standard_button_1_font_weight": 700,
        "typos_standard_button_2_font_size": 14,
        "typos_standard_button_2_font_weight": 700,
        "typos_standard_body_thick_font_size": 16,
        "typos_standard_body_thick_font_weight": 800,
        "typos_standard_body_font_size": 16,
        "typos_standard_body_font_weight": 600,
        "typos_standard_secondary_body_thick_font_size": 14,
        "typos_standard_secondary_body_thick_font_weight": 800,
        "typos_standard_secondary_body_font_size": 14,
        "typos_standard_secondary_body_font_weight": 600,
        "typos_standard_overline_title_1_font_size": 14,
        "typos_standard_overline_title_1_font_weight": 600,
        "typos_standard_overline_title_1_text_transform": "uppercase",
        "typos_standard_button_3_font_size": 12,
        "typos_standard_button_3_font_weight": 700,
        "typos_standard_button_3_text_transform": "uppercase",
        "typos_standard_overline_title_2_font_size": 12,
        "typos_standard_overline_title_2_font_weight": 600,
        "typos_standard_overline_title_2_text_transform": "uppercase",
        "typos_standard_caption_font_size": 12,
        "typos_standard_caption_font_weight": 600,
        "typos_standard_footnote_font_size": 11,
        "typos_standard_footnote_font_weight": 600,
        "typos_content_common_font_family": "'Roboto Slab', serif",
        "typos_content_common_letter_spacing": 0.35,
        "typos_content_extra_large_title_font_size": 64,
        "typos_content_extra_large_title_font_weight": 800,
        "typos_content_extra_large_title_letter_spacing": 0.8,
        "typos_content_large_title_font_size": 32,
        "typos_content_large_title_font_weight": 700,
        "typos_content_large_title_letter_spacing": 0.8,
        "typos_content_title_1_font_size": 24,
        "typos_content_title_1_font_weight": 700,
        "typos_content_title_1_letter_spacing": 0.6,
        "typos_content_title_2_font_size": 20,
        "typos_content_title_2_font_weight": 700,
        "typos_content_title_2_letter_spacing": 0.5,
        "typos_content_body_thick_font_size": 16,
        "typos_content_body_thick_font_weight": 700,
        "typos_content_body_font_size": 16
    },
    "brand-secondary-100": {
        "name": "Lunii Blue (Brand Secondary / 100)",
        "font_color_extra_large_title": "#2b434b",
        "font_color_large_title": "#2b434b",
        "font_color_large_title_bold": "#fbbd2a",
        "font_color_title_1": "#2b434b",
        "font_color_title_2": "#2b434b",
        "font_color_overline_large_title": "#497281",
        "font_color_button_1": "#423f37",
        "font_color_button_1_primary": "#503c0d",
        "font_color_button_1_primary_hover": "#503c0d",
        "font_color_button_1_primary_disabled": "#ca9722",
        "font_color_button_1_plain": "#2762e9",
        "font_color_button_1_plain_hover": "#2762e9",
        "font_color_button_1_plain_disabled": "#b8b3a4",
        "font_color_button_1_destructive": "#c04020",
        "font_color_button_1_destructive_hover": "#c04020",
        "font_color_button_1_destructive_disabled": "#b8b3a4",
        "font_color_button_1_secondary": "#423f37",
        "font_color_button_1_secondary_hover": "#423f37",
        "font_color_button_1_secondary_disabled": "#b8b3a4",
        "font_color_button_1_tertiary": "#2762e9",
        "font_color_button_1_tertiary_hover": "#749dfc",
        "font_color_button_1_tertiary_disabled": "#b8b3a4",
        "font_color_button_1_tertiary_clicked": "#173a8a",
        "font_color_button_1_tertiary_alert": "#f25129",
        "font_color_button_1_tertiary_alert_hover": "#fc7958",
        "font_color_button_1_tertiary_alert_disabled": "#b8b3a4",
        "font_color_button_1_tertiary_alert_clicked": "#722613",
        "font_color_button_1_destructive_clicked": "#722613",
        "font_color_button_1_default": "#423f37",
        "font_color_button_1_default_hover": "#423f37",
        "font_color_button_1_default_disabled": "#777676",
        "font_color_button_2": "#423f37",
        "font_color_button_2_primary": "#503c0d",
        "font_color_button_2_primary_hover": "#503c0d",
        "font_color_button_2_primary_disabled": "#ca9722",
        "font_color_button_2_plain": "#2762e9",
        "font_color_button_2_plain_hover": "#2762e9",
        "font_color_button_2_plain_disabled": "#b8b3a4",
        "font_color_button_2_destructive": "#c04020",
        "font_color_button_2_destructive_hover": "#c04020",
        "font_color_button_2_destructive_disabled": "#b8b3a4",
        "font_color_button_2_secondary": "#423f37",
        "font_color_button_2_secondary_hover": "#423f37",
        "font_color_button_2_secondary_disabled": "#b8b3a4",
        "font_color_button_2_tertiary": "#2762e9",
        "font_color_button_2_tertiary_hover": "#749dfc",
        "font_color_button_2_tertiary_disabled": "#a79f8e",
        "font_color_button_2_tertiary_clicked": "#173a8a",
        "font_color_button_2_destructive_clicked": "#722613",
        "font_color_button_2_default": "#423f37",
        "font_color_button_2_default_hover": "#423f37",
        "font_color_button_2_default_disabled": "#777676",
        "font_color_body_thick": "#497281",
        "font_color_body": "#497281",
        "font_color_secondary_body_thick": "#497281",
        "font_color_secondary_body": "#497281",
        "font_color_overline_title_1": "#497281",
        "font_color_button_3": "#423f37",
        "font_color_overline_title_2": "#497281",
        "font_color_caption": "#5d90a3",
        "font_color_footnote": "#5d90a3",
        "font_color_icon": "#423f37",
        "font_color_icon_inactive": "#c8c3b8",
        "font_color_status_default": "#493300",
        "font_color_status_message": "#493300",
        "font_color_status_alert": "#510506",
        "font_color_status_positive": "#0c3421",
        "font_color_link": "#2762e9",
        "font_color_bullet": "#2c3637",
        "background_color_clear": "#dbf1f9",
        "background_color_primary": "#fbbd2a",
        "background_color_primary_hover": "#fccd5f",
        "background_color_primary_disabled": "#fddd94",
        "background_color_primary_clicked": "#fbbd2a",
        "background_color_plain": "#e6eeff",
        "background_color_plain_hover": "#d4e1fe",
        "background_color_plain_disabled": "#e3e1db",
        "background_color_plain_clicked": "#c1d3fe",
        "background_color_destructive": "#fee9e4",
        "background_color_destructive_hover": "#fed9cf",
        "background_color_destructive_disabled": "#e3e1db",
        "background_color_destructive_clicked": "#fec6b8",
        "background_color_secondary": "#ffffff",
        "background_color_secondary_hover": "#ffffff",
        "background_color_secondary_disabled": "#e1e1e1",
        "background_color_secondary_clicked": "#ffffff",
        "background_color_default": "#d6d3ca",
        "background_color_footer": "#dbf1f9",
        "background_color_danger": "#fdb19e",
        "background_color_positive": "#25dda6",
        "background_color_interactive": "#abc4fd",
        "background_color_status_default": "#fef0ce",
        "background_color_status_message": "#fef0ce",
        "background_color_status_alert": "#fccdce",
        "background_color_status_positive": "#d5f5e6",
        "background_color_active": "#fef0ce",
        "background_color_active_hover": "#fef0ce",
        "background_color_active_disabled": "#e1e1e1",
        "background_color_inactive_hover": "#fef0ce",
        "background_color_inactive_disabled": "#e1e1e1",
        "background_color_bullet": "#c8e7f9",
        "border_buttons_secondary": "2px solid #E3E1DB",
        "border_radius_buttons": 48,
        "border_radius_blocks": "32px",
        "border_radius_blocks_mobile": "24px 16px",
        "border_radius_push": "16px",
        "border_radius_tags": 20,
        "border_radius_standard": 8,
        "shadow_standard": "0px 2px 4px rgba(42, 41, 40, 0.15)",
        "shadow_buttons": "0px 2px 0px #363533",
        "shadow_level_01": "0px 4px 8px rgba(66, 63, 55, 0.1), 0px -2px 8px rgba(66, 63, 55, 0.02)",
        "shadow_level_02": "0px 6px 12px rgba(66, 63, 55, 0.1), 0px -2px 12px rgba(66, 63, 55, 0.02)",
        "shadow_level_03": "0px 8px 24px rgba(66, 63, 55, 0.1), 0px -2px 24px rgba(66, 63, 55, 0.02)",
        "shadow_level_04": "0px 16px 32px rgba(66, 63, 55, 0.1), 0px -2px 4px rgba(66, 63, 55, 0.04)",
        "shadow_level_05": "0px 16px 40px rgba(66, 63, 55, 0.1), 0px -4px 40px rgba(66, 63, 55, 0.04)",
        "shadow_level_06": "0px 24px 48px rgba(66, 63, 55, 0.1), 0px -4px 28px rgba(66, 63, 55, 0.04)",
        "typos_common_font_style": "normal",
        "typos_common_line_height": "150%",
        "typos_common_font_weight": 400,
        "typos_standard_common_font_family": "'Nunito', sans-serif",
        "typos_standard_common_letter_spacing": 0.25,
        "typos_standard_extra_large_title_font_size": 64,
        "typos_standard_extra_large_title_mobile_font_size": 48,
        "typos_standard_extra_large_title_font_weight": 800,
        "typos_standard_large_title_font_size": 32,
        "typos_standard_extra_large_title_mobile_font_weight": 800,
        "typos_standard_large_title_font_weight": 800,
        "typos_standard_title_1_font_size": 24,
        "typos_standard_title_1_font_weight": 800,
        "typos_standard_title_2_font_size": 20,
        "typos_standard_title_2_font_weight": 800,
        "typos_standard_overline_large_title_font_size": 16,
        "typos_standard_overline_large_title_font_weight": 600,
        "typos_standard_overline_large_title_text_transform": "uppercase",
        "typos_standard_button_1_font_size": 16,
        "typos_standard_button_1_font_weight": 700,
        "typos_standard_button_2_font_size": 14,
        "typos_standard_button_2_font_weight": 700,
        "typos_standard_body_thick_font_size": 16,
        "typos_standard_body_thick_font_weight": 800,
        "typos_standard_body_font_size": 16,
        "typos_standard_body_font_weight": 600,
        "typos_standard_secondary_body_thick_font_size": 14,
        "typos_standard_secondary_body_thick_font_weight": 800,
        "typos_standard_secondary_body_font_size": 14,
        "typos_standard_secondary_body_font_weight": 600,
        "typos_standard_overline_title_1_font_size": 14,
        "typos_standard_overline_title_1_font_weight": 600,
        "typos_standard_overline_title_1_text_transform": "uppercase",
        "typos_standard_button_3_font_size": 12,
        "typos_standard_button_3_font_weight": 700,
        "typos_standard_button_3_text_transform": "uppercase",
        "typos_standard_overline_title_2_font_size": 12,
        "typos_standard_overline_title_2_font_weight": 600,
        "typos_standard_overline_title_2_text_transform": "uppercase",
        "typos_standard_caption_font_size": 12,
        "typos_standard_caption_font_weight": 600,
        "typos_standard_footnote_font_size": 11,
        "typos_standard_footnote_font_weight": 600,
        "typos_content_common_font_family": "'Roboto Slab', serif",
        "typos_content_common_letter_spacing": 0.35,
        "typos_content_extra_large_title_font_size": 64,
        "typos_content_extra_large_title_font_weight": 800,
        "typos_content_extra_large_title_letter_spacing": 0.8,
        "typos_content_large_title_font_size": 32,
        "typos_content_large_title_font_weight": 700,
        "typos_content_large_title_letter_spacing": 0.8,
        "typos_content_title_1_font_size": 24,
        "typos_content_title_1_font_weight": 700,
        "typos_content_title_1_letter_spacing": 0.6,
        "typos_content_title_2_font_size": 20,
        "typos_content_title_2_font_weight": 700,
        "typos_content_title_2_letter_spacing": 0.5,
        "typos_content_body_thick_font_size": 16,
        "typos_content_body_thick_font_weight": 700,
        "typos_content_body_font_size": 16
    },
    "positive-50": {
        "name": "Lunii Green (Positive / 50)",
        "font_color_extra_large_title": "#0c4836",
        "font_color_large_title": "#0c4836",
        "font_color_large_title_bold": "#fbbd2a",
        "font_color_title_1": "#0c4836",
        "font_color_title_2": "#0c4836",
        "font_color_overline_large_title": "#157b5c",
        "font_color_button_1": "#423f37",
        "font_color_button_1_primary": "#503c0d",
        "font_color_button_1_primary_hover": "#503c0d",
        "font_color_button_1_primary_disabled": "#ca9722",
        "font_color_button_1_plain": "#2762e9",
        "font_color_button_1_plain_hover": "#2762e9",
        "font_color_button_1_plain_disabled": "#b8b3a4",
        "font_color_button_1_destructive": "#c04020",
        "font_color_button_1_destructive_hover": "#c04020",
        "font_color_button_1_destructive_disabled": "#b8b3a4",
        "font_color_button_1_secondary": "#423f37",
        "font_color_button_1_secondary_hover": "#423f37",
        "font_color_button_1_secondary_disabled": "#b8b3a4",
        "font_color_button_1_tertiary": "#2762e9",
        "font_color_button_1_tertiary_hover": "#749dfc",
        "font_color_button_1_tertiary_disabled": "#b8b3a4",
        "font_color_button_1_tertiary_clicked": "#173a8a",
        "font_color_button_1_tertiary_alert": "#f25129",
        "font_color_button_1_tertiary_alert_hover": "#fc7958",
        "font_color_button_1_tertiary_alert_disabled": "#b8b3a4",
        "font_color_button_1_tertiary_alert_clicked": "#722613",
        "font_color_button_1_destructive_clicked": "#722613",
        "font_color_button_1_default": "#423f37",
        "font_color_button_1_default_hover": "#423f37",
        "font_color_button_1_default_disabled": "#777676",
        "font_color_button_2": "#423f37",
        "font_color_button_2_primary": "#503c0d",
        "font_color_button_2_primary_hover": "#503c0d",
        "font_color_button_2_primary_disabled": "#ca9722",
        "font_color_button_2_plain": "#2762e9",
        "font_color_button_2_plain_hover": "#2762e9",
        "font_color_button_2_plain_disabled": "#b8b3a4",
        "font_color_button_2_destructive": "#c04020",
        "font_color_button_2_destructive_hover": "#c04020",
        "font_color_button_2_destructive_disabled": "#b8b3a4",
        "font_color_button_2_secondary": "#423f37",
        "font_color_button_2_secondary_hover": "#423f37",
        "font_color_button_2_secondary_disabled": "#b8b3a4",
        "font_color_button_2_tertiary": "#2762e9",
        "font_color_button_2_tertiary_hover": "#749dfc",
        "font_color_button_2_tertiary_disabled": "#a79f8e",
        "font_color_button_2_tertiary_clicked": "#173a8a",
        "font_color_button_2_destructive_clicked": "#722613",
        "font_color_button_2_default": "#423f37",
        "font_color_button_2_default_hover": "#423f37",
        "font_color_button_2_default_disabled": "#777676",
        "font_color_body_thick": "#157b5c",
        "font_color_body": "#157b5c",
        "font_color_secondary_body_thick": "#157b5c",
        "font_color_secondary_body": "#157b5c",
        "font_color_overline_title_1": "#157b5c",
        "font_color_button_3": "#423f37",
        "font_color_overline_title_2": "#157b5c",
        "font_color_caption": "#1a9b74",
        "font_color_footnote": "#1a9b74",
        "font_color_icon": "#423f37",
        "font_color_icon_inactive": "#c8c3b8",
        "font_color_status_default": "#493300",
        "font_color_status_message": "#493300",
        "font_color_status_alert": "#510506",
        "font_color_status_positive": "#0c3421",
        "font_color_link": "#2762e9",
        "font_color_bullet": "#2c3637",
        "background_color_clear": "#e7fff8",
        "background_color_primary": "#fbbd2a",
        "background_color_primary_hover": "#fccd5f",
        "background_color_primary_disabled": "#fddd94",
        "background_color_primary_clicked": "#fbbd2a",
        "background_color_plain": "#e6eeff",
        "background_color_plain_hover": "#d4e1fe",
        "background_color_plain_disabled": "#e3e1db",
        "background_color_plain_clicked": "#c1d3fe",
        "background_color_destructive": "#fee9e4",
        "background_color_destructive_hover": "#fed9cf",
        "background_color_destructive_disabled": "#e3e1db",
        "background_color_destructive_clicked": "#fec6b8",
        "background_color_secondary": "#ffffff",
        "background_color_secondary_hover": "#ffffff",
        "background_color_secondary_disabled": "#e1e1e1",
        "background_color_secondary_clicked": "#ffffff",
        "background_color_default": "#d6d3ca",
        "background_color_footer": "#dbf1f9",
        "background_color_danger": "#fdb19e",
        "background_color_positive": "#25dda6",
        "background_color_interactive": "#abc4fd",
        "background_color_status_default": "#fef0ce",
        "background_color_status_message": "#fef0ce",
        "background_color_status_alert": "#fccdce",
        "background_color_status_positive": "#d5f5e6",
        "background_color_active": "#fef0ce",
        "background_color_active_hover": "#fef0ce",
        "background_color_active_disabled": "#e1e1e1",
        "background_color_inactive_hover": "#fef0ce",
        "background_color_inactive_disabled": "#e1e1e1",
        "background_color_bullet": "#c8e7f9",
        "border_buttons_secondary": "2px solid #E3E1DB",
        "border_radius_buttons": 48,
        "border_radius_blocks": "32px",
        "border_radius_blocks_mobile": "24px 16px",
        "border_radius_push": "16px",
        "border_radius_tags": 20,
        "border_radius_standard": 8,
        "shadow_standard": "0px 2px 4px rgba(42, 41, 40, 0.15)",
        "shadow_buttons": "0px 2px 0px #363533",
        "shadow_level_01": "0px 4px 8px rgba(66, 63, 55, 0.1), 0px -2px 8px rgba(66, 63, 55, 0.02)",
        "shadow_level_02": "0px 6px 12px rgba(66, 63, 55, 0.1), 0px -2px 12px rgba(66, 63, 55, 0.02)",
        "shadow_level_03": "0px 8px 24px rgba(66, 63, 55, 0.1), 0px -2px 24px rgba(66, 63, 55, 0.02)",
        "shadow_level_04": "0px 16px 32px rgba(66, 63, 55, 0.1), 0px -2px 4px rgba(66, 63, 55, 0.04)",
        "shadow_level_05": "0px 16px 40px rgba(66, 63, 55, 0.1), 0px -4px 40px rgba(66, 63, 55, 0.04)",
        "shadow_level_06": "0px 24px 48px rgba(66, 63, 55, 0.1), 0px -4px 28px rgba(66, 63, 55, 0.04)",
        "typos_common_font_style": "normal",
        "typos_common_line_height": "150%",
        "typos_common_font_weight": 400,
        "typos_standard_common_font_family": "'Nunito', sans-serif",
        "typos_standard_common_letter_spacing": 0.25,
        "typos_standard_extra_large_title_font_size": 64,
        "typos_standard_extra_large_title_mobile_font_size": 48,
        "typos_standard_extra_large_title_font_weight": 800,
        "typos_standard_large_title_font_size": 32,
        "typos_standard_extra_large_title_mobile_font_weight": 800,
        "typos_standard_large_title_font_weight": 800,
        "typos_standard_title_1_font_size": 24,
        "typos_standard_title_1_font_weight": 800,
        "typos_standard_title_2_font_size": 20,
        "typos_standard_title_2_font_weight": 800,
        "typos_standard_overline_large_title_font_size": 16,
        "typos_standard_overline_large_title_font_weight": 600,
        "typos_standard_overline_large_title_text_transform": "uppercase",
        "typos_standard_button_1_font_size": 16,
        "typos_standard_button_1_font_weight": 700,
        "typos_standard_button_2_font_size": 14,
        "typos_standard_button_2_font_weight": 700,
        "typos_standard_body_thick_font_size": 16,
        "typos_standard_body_thick_font_weight": 800,
        "typos_standard_body_font_size": 16,
        "typos_standard_body_font_weight": 600,
        "typos_standard_secondary_body_thick_font_size": 14,
        "typos_standard_secondary_body_thick_font_weight": 800,
        "typos_standard_secondary_body_font_size": 14,
        "typos_standard_secondary_body_font_weight": 600,
        "typos_standard_overline_title_1_font_size": 14,
        "typos_standard_overline_title_1_font_weight": 600,
        "typos_standard_overline_title_1_text_transform": "uppercase",
        "typos_standard_button_3_font_size": 12,
        "typos_standard_button_3_font_weight": 700,
        "typos_standard_button_3_text_transform": "uppercase",
        "typos_standard_overline_title_2_font_size": 12,
        "typos_standard_overline_title_2_font_weight": 600,
        "typos_standard_overline_title_2_text_transform": "uppercase",
        "typos_standard_caption_font_size": 12,
        "typos_standard_caption_font_weight": 600,
        "typos_standard_footnote_font_size": 11,
        "typos_standard_footnote_font_weight": 600,
        "typos_content_common_font_family": "'Roboto Slab', serif",
        "typos_content_common_letter_spacing": 0.35,
        "typos_content_extra_large_title_font_size": 64,
        "typos_content_extra_large_title_font_weight": 800,
        "typos_content_extra_large_title_letter_spacing": 0.8,
        "typos_content_large_title_font_size": 32,
        "typos_content_large_title_font_weight": 700,
        "typos_content_large_title_letter_spacing": 0.8,
        "typos_content_title_1_font_size": 24,
        "typos_content_title_1_font_weight": 700,
        "typos_content_title_1_letter_spacing": 0.6,
        "typos_content_title_2_font_size": 20,
        "typos_content_title_2_font_weight": 700,
        "typos_content_title_2_letter_spacing": 0.5,
        "typos_content_body_thick_font_size": 16,
        "typos_content_body_thick_font_weight": 700,
        "typos_content_body_font_size": 16
    },
    "danger-50": {
        "name": "Lunii Red (Danger / 50)",
        "font_color_extra_large_title": "#722613",
        "font_color_large_title": "#722613",
        "font_color_large_title_bold": "#fbbd2a",
        "font_color_title_1": "#722613",
        "font_color_title_2": "#722613",
        "font_color_overline_large_title": "#c04020",
        "font_color_button_1": "#423f37",
        "font_color_button_1_primary": "#503c0d",
        "font_color_button_1_primary_hover": "#503c0d",
        "font_color_button_1_primary_disabled": "#ca9722",
        "font_color_button_1_plain": "#2762e9",
        "font_color_button_1_plain_hover": "#2762e9",
        "font_color_button_1_plain_disabled": "#b8b3a4",
        "font_color_button_1_destructive": "#c04020",
        "font_color_button_1_destructive_hover": "#c04020",
        "font_color_button_1_destructive_disabled": "#b8b3a4",
        "font_color_button_1_secondary": "#423f37",
        "font_color_button_1_secondary_hover": "#423f37",
        "font_color_button_1_secondary_disabled": "#b8b3a4",
        "font_color_button_1_tertiary": "#2762e9",
        "font_color_button_1_tertiary_hover": "#749dfc",
        "font_color_button_1_tertiary_disabled": "#b8b3a4",
        "font_color_button_1_tertiary_clicked": "#173a8a",
        "font_color_button_1_tertiary_alert": "#f25129",
        "font_color_button_1_tertiary_alert_hover": "#fc7958",
        "font_color_button_1_tertiary_alert_disabled": "#b8b3a4",
        "font_color_button_1_tertiary_alert_clicked": "#722613",
        "font_color_button_1_destructive_clicked": "#722613",
        "font_color_button_1_default": "#423f37",
        "font_color_button_1_default_hover": "#423f37",
        "font_color_button_1_default_disabled": "#777676",
        "font_color_button_2": "#423f37",
        "font_color_button_2_primary": "#503c0d",
        "font_color_button_2_primary_hover": "#503c0d",
        "font_color_button_2_primary_disabled": "#ca9722",
        "font_color_button_2_plain": "#2762e9",
        "font_color_button_2_plain_hover": "#2762e9",
        "font_color_button_2_plain_disabled": "#b8b3a4",
        "font_color_button_2_destructive": "#c04020",
        "font_color_button_2_destructive_hover": "#c04020",
        "font_color_button_2_destructive_disabled": "#b8b3a4",
        "font_color_button_2_secondary": "#423f37",
        "font_color_button_2_secondary_hover": "#423f37",
        "font_color_button_2_secondary_disabled": "#b8b3a4",
        "font_color_button_2_tertiary": "#2762e9",
        "font_color_button_2_tertiary_hover": "#749dfc",
        "font_color_button_2_tertiary_disabled": "#a79f8e",
        "font_color_button_2_tertiary_clicked": "#173a8a",
        "font_color_button_2_destructive_clicked": "#722613",
        "font_color_button_2_default": "#423f37",
        "font_color_button_2_default_hover": "#423f37",
        "font_color_button_2_default_disabled": "#777676",
        "font_color_body_thick": "#c04020",
        "font_color_body": "#c04020",
        "font_color_secondary_body_thick": "#c04020",
        "font_color_secondary_body": "#c04020",
        "font_color_overline_title_1": "#c04020",
        "font_color_button_3": "#423f37",
        "font_color_overline_title_2": "#c04020",
        "font_color_caption": "#f25129",
        "font_color_footnote": "#f25129",
        "font_color_icon": "#423f37",
        "font_color_icon_inactive": "#c8c3b8",
        "font_color_status_default": "#493300",
        "font_color_status_message": "#493300",
        "font_color_status_alert": "#510506",
        "font_color_status_positive": "#0c3421",
        "font_color_link": "#2762e9",
        "font_color_bullet": "#2c3637",
        "background_color_clear": "#fff8f6",
        "background_color_primary": "#fbbd2a",
        "background_color_primary_hover": "#fccd5f",
        "background_color_primary_disabled": "#fddd94",
        "background_color_primary_clicked": "#fbbd2a",
        "background_color_plain": "#e6eeff",
        "background_color_plain_hover": "#d4e1fe",
        "background_color_plain_disabled": "#e3e1db",
        "background_color_plain_clicked": "#c1d3fe",
        "background_color_destructive": "#fee9e4",
        "background_color_destructive_hover": "#fed9cf",
        "background_color_destructive_disabled": "#e3e1db",
        "background_color_destructive_clicked": "#fec6b8",
        "background_color_secondary": "#ffffff",
        "background_color_secondary_hover": "#ffffff",
        "background_color_secondary_disabled": "#e1e1e1",
        "background_color_secondary_clicked": "#ffffff",
        "background_color_default": "#d6d3ca",
        "background_color_footer": "#dbf1f9",
        "background_color_danger": "#fdb19e",
        "background_color_positive": "#25dda6",
        "background_color_interactive": "#abc4fd",
        "background_color_status_default": "#fef0ce",
        "background_color_status_message": "#fef0ce",
        "background_color_status_alert": "#fccdce",
        "background_color_status_positive": "#d5f5e6",
        "background_color_active": "#fef0ce",
        "background_color_active_hover": "#fef0ce",
        "background_color_active_disabled": "#e1e1e1",
        "background_color_inactive_hover": "#fef0ce",
        "background_color_inactive_disabled": "#e1e1e1",
        "background_color_bullet": "#c8e7f9",
        "border_buttons_secondary": "2px solid #E3E1DB",
        "border_radius_buttons": 48,
        "border_radius_blocks": "32px",
        "border_radius_blocks_mobile": "24px 16px",
        "border_radius_push": "16px",
        "border_radius_tags": 20,
        "border_radius_standard": 8,
        "shadow_standard": "0px 2px 4px rgba(42, 41, 40, 0.15)",
        "shadow_buttons": "0px 2px 0px #363533",
        "shadow_level_01": "0px 4px 8px rgba(66, 63, 55, 0.1), 0px -2px 8px rgba(66, 63, 55, 0.02)",
        "shadow_level_02": "0px 6px 12px rgba(66, 63, 55, 0.1), 0px -2px 12px rgba(66, 63, 55, 0.02)",
        "shadow_level_03": "0px 8px 24px rgba(66, 63, 55, 0.1), 0px -2px 24px rgba(66, 63, 55, 0.02)",
        "shadow_level_04": "0px 16px 32px rgba(66, 63, 55, 0.1), 0px -2px 4px rgba(66, 63, 55, 0.04)",
        "shadow_level_05": "0px 16px 40px rgba(66, 63, 55, 0.1), 0px -4px 40px rgba(66, 63, 55, 0.04)",
        "shadow_level_06": "0px 24px 48px rgba(66, 63, 55, 0.1), 0px -4px 28px rgba(66, 63, 55, 0.04)",
        "typos_common_font_style": "normal",
        "typos_common_line_height": "150%",
        "typos_common_font_weight": 400,
        "typos_standard_common_font_family": "'Nunito', sans-serif",
        "typos_standard_common_letter_spacing": 0.25,
        "typos_standard_extra_large_title_font_size": 64,
        "typos_standard_extra_large_title_mobile_font_size": 48,
        "typos_standard_extra_large_title_font_weight": 800,
        "typos_standard_large_title_font_size": 32,
        "typos_standard_extra_large_title_mobile_font_weight": 800,
        "typos_standard_large_title_font_weight": 800,
        "typos_standard_title_1_font_size": 24,
        "typos_standard_title_1_font_weight": 800,
        "typos_standard_title_2_font_size": 20,
        "typos_standard_title_2_font_weight": 800,
        "typos_standard_overline_large_title_font_size": 16,
        "typos_standard_overline_large_title_font_weight": 600,
        "typos_standard_overline_large_title_text_transform": "uppercase",
        "typos_standard_button_1_font_size": 16,
        "typos_standard_button_1_font_weight": 700,
        "typos_standard_button_2_font_size": 14,
        "typos_standard_button_2_font_weight": 700,
        "typos_standard_body_thick_font_size": 16,
        "typos_standard_body_thick_font_weight": 800,
        "typos_standard_body_font_size": 16,
        "typos_standard_body_font_weight": 600,
        "typos_standard_secondary_body_thick_font_size": 14,
        "typos_standard_secondary_body_thick_font_weight": 800,
        "typos_standard_secondary_body_font_size": 14,
        "typos_standard_secondary_body_font_weight": 600,
        "typos_standard_overline_title_1_font_size": 14,
        "typos_standard_overline_title_1_font_weight": 600,
        "typos_standard_overline_title_1_text_transform": "uppercase",
        "typos_standard_button_3_font_size": 12,
        "typos_standard_button_3_font_weight": 700,
        "typos_standard_button_3_text_transform": "uppercase",
        "typos_standard_overline_title_2_font_size": 12,
        "typos_standard_overline_title_2_font_weight": 600,
        "typos_standard_overline_title_2_text_transform": "uppercase",
        "typos_standard_caption_font_size": 12,
        "typos_standard_caption_font_weight": 600,
        "typos_standard_footnote_font_size": 11,
        "typos_standard_footnote_font_weight": 600,
        "typos_content_common_font_family": "'Roboto Slab', serif",
        "typos_content_common_letter_spacing": 0.35,
        "typos_content_extra_large_title_font_size": 64,
        "typos_content_extra_large_title_font_weight": 800,
        "typos_content_extra_large_title_letter_spacing": 0.8,
        "typos_content_large_title_font_size": 32,
        "typos_content_large_title_font_weight": 700,
        "typos_content_large_title_letter_spacing": 0.8,
        "typos_content_title_1_font_size": 24,
        "typos_content_title_1_font_weight": 700,
        "typos_content_title_1_letter_spacing": 0.6,
        "typos_content_title_2_font_size": 20,
        "typos_content_title_2_font_weight": 700,
        "typos_content_title_2_letter_spacing": 0.5,
        "typos_content_body_thick_font_size": 16,
        "typos_content_body_thick_font_weight": 700,
        "typos_content_body_font_size": 16
    },
    "ui-50": {
        "name": "Grey (UI / 50)",
        "font_color_extra_large_title": "#423f37",
        "font_color_large_title": "#423f37",
        "font_color_large_title_bold": "#908977",
        "font_color_title_1": "#423f37",
        "font_color_title_2": "#423f37",
        "font_color_overline_large_title": "#716c5e",
        "font_color_button_1": "#423f37",
        "font_color_button_1_primary": "#503c0d",
        "font_color_button_1_primary_hover": "#503c0d",
        "font_color_button_1_primary_disabled": "#ca9722",
        "font_color_button_1_plain": "#2762e9",
        "font_color_button_1_plain_hover": "#2762e9",
        "font_color_button_1_plain_disabled": "#b8b3a4",
        "font_color_button_1_destructive": "#c04020",
        "font_color_button_1_destructive_hover": "#c04020",
        "font_color_button_1_destructive_disabled": "#b8b3a4",
        "font_color_button_1_secondary": "#423f37",
        "font_color_button_1_secondary_hover": "#423f37",
        "font_color_button_1_secondary_disabled": "#b8b3a4",
        "font_color_button_1_tertiary": "#2762e9",
        "font_color_button_1_tertiary_hover": "#749dfc",
        "font_color_button_1_tertiary_disabled": "#b8b3a4",
        "font_color_button_1_tertiary_clicked": "#173a8a",
        "font_color_button_1_tertiary_alert": "#f25129",
        "font_color_button_1_tertiary_alert_hover": "#fc7958",
        "font_color_button_1_tertiary_alert_disabled": "#b8b3a4",
        "font_color_button_1_tertiary_alert_clicked": "#722613",
        "font_color_button_1_destructive_clicked": "#722613",
        "font_color_button_1_default": "#423f37",
        "font_color_button_1_default_hover": "#423f37",
        "font_color_button_1_default_disabled": "#777676",
        "font_color_button_2": "#423f37",
        "font_color_button_2_primary": "#503c0d",
        "font_color_button_2_primary_hover": "#503c0d",
        "font_color_button_2_primary_disabled": "#ca9722",
        "font_color_button_2_plain": "#2762e9",
        "font_color_button_2_plain_hover": "#2762e9",
        "font_color_button_2_plain_disabled": "#b8b3a4",
        "font_color_button_2_destructive": "#c04020",
        "font_color_button_2_destructive_hover": "#c04020",
        "font_color_button_2_destructive_disabled": "#b8b3a4",
        "font_color_button_2_secondary": "#423f37",
        "font_color_button_2_secondary_hover": "#423f37",
        "font_color_button_2_secondary_disabled": "#b8b3a4",
        "font_color_button_2_tertiary": "#2762e9",
        "font_color_button_2_tertiary_hover": "#749dfc",
        "font_color_button_2_tertiary_disabled": "#a79f8e",
        "font_color_button_2_tertiary_clicked": "#173a8a",
        "font_color_button_2_destructive_clicked": "#722613",
        "font_color_button_2_default": "#423f37",
        "font_color_button_2_default_hover": "#423f37",
        "font_color_button_2_default_disabled": "#777676",
        "font_color_body_thick": "#716c5e",
        "font_color_body": "#716c5e",
        "font_color_secondary_body_thick": "#716c5e",
        "font_color_secondary_body": "#716c5e",
        "font_color_overline_title_1": "#716c5e",
        "font_color_button_3": "#423f37",
        "font_color_overline_title_2": "#716c5e",
        "font_color_caption": "#908977",
        "font_color_footnote": "#908977",
        "font_color_icon": "#423f37",
        "font_color_icon_inactive": "#c8c3b8",
        "font_color_status_default": "#493300",
        "font_color_status_message": "#493300",
        "font_color_status_alert": "#510506",
        "font_color_status_positive": "#0c3421",
        "font_color_link": "#2762e9",
        "font_color_bullet": "#2c3637",
        "background_color_clear": "#faf9f8",
        "background_color_primary": "#fbbd2a",
        "background_color_primary_hover": "#fccd5f",
        "background_color_primary_disabled": "#fddd94",
        "background_color_primary_clicked": "#fbbd2a",
        "background_color_plain": "#e6eeff",
        "background_color_plain_hover": "#d4e1fe",
        "background_color_plain_disabled": "#e3e1db",
        "background_color_plain_clicked": "#c1d3fe",
        "background_color_destructive": "#fee9e4",
        "background_color_destructive_hover": "#fed9cf",
        "background_color_destructive_disabled": "#e3e1db",
        "background_color_destructive_clicked": "#fec6b8",
        "background_color_secondary": "#ffffff",
        "background_color_secondary_hover": "#ffffff",
        "background_color_secondary_disabled": "#e1e1e1",
        "background_color_secondary_clicked": "#ffffff",
        "background_color_default": "#d6d3ca",
        "background_color_footer": "#dbf1f9",
        "background_color_danger": "#fdb19e",
        "background_color_positive": "#25dda6",
        "background_color_interactive": "#abc4fd",
        "background_color_status_default": "#fef0ce",
        "background_color_status_message": "#fef0ce",
        "background_color_status_alert": "#fccdce",
        "background_color_status_positive": "#d5f5e6",
        "background_color_active": "#fef0ce",
        "background_color_active_hover": "#fef0ce",
        "background_color_active_disabled": "#e1e1e1",
        "background_color_inactive_hover": "#fef0ce",
        "background_color_inactive_disabled": "#e1e1e1",
        "background_color_bullet": "#c8e7f9",
        "border_buttons_secondary": "2px solid #E3E1DB",
        "border_radius_buttons": 48,
        "border_radius_blocks": "32px",
        "border_radius_blocks_mobile": "24px 16px",
        "border_radius_push": "16px",
        "border_radius_tags": 20,
        "border_radius_standard": 8,
        "shadow_standard": "0px 2px 4px rgba(42, 41, 40, 0.15)",
        "shadow_buttons": "0px 2px 0px #363533",
        "shadow_level_01": "0px 4px 8px rgba(66, 63, 55, 0.1), 0px -2px 8px rgba(66, 63, 55, 0.02)",
        "shadow_level_02": "0px 6px 12px rgba(66, 63, 55, 0.1), 0px -2px 12px rgba(66, 63, 55, 0.02)",
        "shadow_level_03": "0px 8px 24px rgba(66, 63, 55, 0.1), 0px -2px 24px rgba(66, 63, 55, 0.02)",
        "shadow_level_04": "0px 16px 32px rgba(66, 63, 55, 0.1), 0px -2px 4px rgba(66, 63, 55, 0.04)",
        "shadow_level_05": "0px 16px 40px rgba(66, 63, 55, 0.1), 0px -4px 40px rgba(66, 63, 55, 0.04)",
        "shadow_level_06": "0px 24px 48px rgba(66, 63, 55, 0.1), 0px -4px 28px rgba(66, 63, 55, 0.04)",
        "typos_common_font_style": "normal",
        "typos_common_line_height": "150%",
        "typos_common_font_weight": 400,
        "typos_standard_common_font_family": "'Nunito', sans-serif",
        "typos_standard_common_letter_spacing": 0.25,
        "typos_standard_extra_large_title_font_size": 64,
        "typos_standard_extra_large_title_mobile_font_size": 48,
        "typos_standard_extra_large_title_font_weight": 800,
        "typos_standard_large_title_font_size": 32,
        "typos_standard_extra_large_title_mobile_font_weight": 800,
        "typos_standard_large_title_font_weight": 800,
        "typos_standard_title_1_font_size": 24,
        "typos_standard_title_1_font_weight": 800,
        "typos_standard_title_2_font_size": 20,
        "typos_standard_title_2_font_weight": 800,
        "typos_standard_overline_large_title_font_size": 16,
        "typos_standard_overline_large_title_font_weight": 600,
        "typos_standard_overline_large_title_text_transform": "uppercase",
        "typos_standard_button_1_font_size": 16,
        "typos_standard_button_1_font_weight": 700,
        "typos_standard_button_2_font_size": 14,
        "typos_standard_button_2_font_weight": 700,
        "typos_standard_body_thick_font_size": 16,
        "typos_standard_body_thick_font_weight": 800,
        "typos_standard_body_font_size": 16,
        "typos_standard_body_font_weight": 600,
        "typos_standard_secondary_body_thick_font_size": 14,
        "typos_standard_secondary_body_thick_font_weight": 800,
        "typos_standard_secondary_body_font_size": 14,
        "typos_standard_secondary_body_font_weight": 600,
        "typos_standard_overline_title_1_font_size": 14,
        "typos_standard_overline_title_1_font_weight": 600,
        "typos_standard_overline_title_1_text_transform": "uppercase",
        "typos_standard_button_3_font_size": 12,
        "typos_standard_button_3_font_weight": 700,
        "typos_standard_button_3_text_transform": "uppercase",
        "typos_standard_overline_title_2_font_size": 12,
        "typos_standard_overline_title_2_font_weight": 600,
        "typos_standard_overline_title_2_text_transform": "uppercase",
        "typos_standard_caption_font_size": 12,
        "typos_standard_caption_font_weight": 600,
        "typos_standard_footnote_font_size": 11,
        "typos_standard_footnote_font_weight": 600,
        "typos_content_common_font_family": "'Roboto Slab', serif",
        "typos_content_common_letter_spacing": 0.35,
        "typos_content_extra_large_title_font_size": 64,
        "typos_content_extra_large_title_font_weight": 800,
        "typos_content_extra_large_title_letter_spacing": 0.8,
        "typos_content_large_title_font_size": 32,
        "typos_content_large_title_font_weight": 700,
        "typos_content_large_title_letter_spacing": 0.8,
        "typos_content_title_1_font_size": 24,
        "typos_content_title_1_font_weight": 700,
        "typos_content_title_1_letter_spacing": 0.6,
        "typos_content_title_2_font_size": 20,
        "typos_content_title_2_font_weight": 700,
        "typos_content_title_2_letter_spacing": 0.5,
        "typos_content_body_thick_font_size": 16,
        "typos_content_body_thick_font_weight": 700,
        "typos_content_body_font_size": 16
    }
}
