import React from 'react';
import clsx from 'clsx';
import {useForm} from 'react-hook-form';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {FieldSet, ErrorPanel, TextField, EmailField, Button, useNewsletterSubscribeSubmit, useLuniiTranslation} from '..';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    actions: {
        textAlign: 'right',
    },
    form:{
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    fieldset: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
    },
    button: {
        width: '100%',
    },
}));

export function NewsletterForm({className, onSuccess}: NewsletterFormProps) {
    const classes = useStyles();
    const {handleSubmit, register, errors} = useForm();
    const [onSubmit, {loading, error}] = useNewsletterSubscribeSubmit(onSuccess);
    const x = {errors, register};
    const {t} = useLuniiTranslation();
    return (
        <div className={clsx(className, classes.root)}>
            <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
                <ErrorPanel error={error}/>
                <FieldSet className={classes.fieldset}>
                    <TextField required style={{width: '100%'}} disabled={loading} min={0} max={100} name={'yourFirstname'} {...x}/>
                    <EmailField required {...x} disabled={loading} />
                </FieldSet>
                <Button type={'submit'} className={classes.button} color={'primary'}>
                    {t('newsletter_button_label_register')}
                </Button>
            </form>
        </div>
    );
}

export interface NewsletterFormProps {
    className?: string,
    onSuccess?: any,
}

export default NewsletterForm;
