import * as React from 'react';
function SvgArrowDown(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width={props.width || 24}
            height={props.height || 24}
            {...props}
        >
            <path
                fill="none"
                d="M0 0h24v24H0z"
            />
            <path
                d="M18 9l-6 6-6-6"
                stroke={props.color || 'currentColor'}
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <rect
                x={13}
                y={9}
                width={2}
                height={2}
                rx={1}
                transform="rotate(90 13 9)"
                fill={props.color || 'currentColor'}
            />
        </svg>
    );
}
export default SvgArrowDown;
