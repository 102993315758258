import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import clsx from 'clsx';
import {cssText} from '@ohoareau/css-utils';
import {makeStyles} from '@material-ui/core/styles';
import useLuniiTranslation from '../hooks/useLuniiTranslation';
import {buttonifyFromProps} from '../hocs';
import DefaultAddress from './DefaultAddress';
import EditAddress from './EditAddress';
import SearchAddress from './SearchAddress';
import {ErrorPanel, Spinner} from '../atoms';
import {FormProvider, useForm, FieldValues} from 'react-hook-form';
import {useAddressesRetrieveSubmit, useAddressUpdateSubmit, useLuniiUser} from '../hooks';
import {AddressTypeEnum} from '../types';

enum AddressFormStateEnum {
    EDIT = 'edit',
    SEARCH = 'search',
    DEFAULT = 'default',
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
    },
    spinner: {
        borderRadius: theme.spacing(1),
        height: `calc(100% + ${theme.spacing(4)}px)`,
        width: `calc(100% + ${theme.spacing(4)}px)`,
        margin: theme.spacing(-2, 0, 0, -2),
        [theme.breakpoints.down('sm')]: {
            height: `calc(100% + ${theme.spacing(4)}px)`,
            width: `calc(100% + ${theme.spacing(2)}px)`,
            margin: theme.spacing(-2, 0, 0, -1),
        },
    },
    head: {
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
        marginBottom: theme.spacing(3),
    },
    title: {
        ...cssText(theme, 'standard', 'title_1'),
        color: '#423F37',
    },
    editButton: {
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(2),
        },
    },
    extraButton: {
        marginBottom: theme.spacing(3),
    },
    error: {
        marginTop: 0,
        marginBottom: theme.spacing(3),
    },
}));

export interface AddressFormValues extends FieldValues {
    address1: string;
    address2?: string;
    firstName: string;
    lastName: string;
    city: string;
    zipCode: string;
    state?: string;
    selectCountry: string,
    phone?: string;
}

export function AddressForm({
    className,
    locale,
    title,
    onSuccess = undefined,
    onError = undefined,
    type,
    autoFocus = false,
    extraButton = false,
    onEdit = () => {},
}: AddressFormProps) {
    const classes = useStyles();
    const {user, refreshUserPartial} = useLuniiUser();
    const {t} = useLuniiTranslation();
    const findLocaleAddress = useCallback((addresses) => {
        const address = addresses && addresses.find((address: any) => address.locale === locale);
        if (!address) return null;
        return address;
    }, []);
    const baseAddress = useMemo(() => findLocaleAddress((user || {})[`${type}Infos`]), [user]);
    const deliveryAddress = useMemo(() => findLocaleAddress((user || {}).deliveryInfos), [user]);
    const [mode, setMode] = useState<AddressFormStateEnum>(
        baseAddress && (type === AddressTypeEnum.Billing || baseAddress.phone) ? AddressFormStateEnum.DEFAULT : AddressFormStateEnum.EDIT,
    );
    const [loading, setLoading] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const formProvider = useForm({
        mode: 'onSubmit',
        defaultValues: baseAddress || {country: locale},
    });
    const editFormState = useRef<any>(null);
    const [foundAddresses, setFoundAddresses] = useState(undefined);
    const [retrieveAddresses, {loading: foundAddressLoading}] = useAddressesRetrieveSubmit(
        (addresses) => {
            setFoundAddresses(addresses);
        },
    );
    const [
        updateAddress,
        {loading: updateAddressLoading, error: updateAddressError},
    ] = useAddressUpdateSubmit(async () => {
        await refreshUserPartial({user: true});
        setMode(AddressFormStateEnum.DEFAULT);
        setLoading(false);
        onSuccess && onSuccess();
    });
    const handleSubmit = useCallback(
        async (address) => {
            setLoading(true);
            await updateAddress({type, address});
        },
        [updateAddress, setLoading],
    );
    const handleSelectedAddress = useCallback(
        (address) => {
            const {getValues} = formProvider;
            editFormState.current = getValues();
            setSelectedAddress(address);
            retrieveAddresses({
                address: `${address.address1} ${address.zipCode} ${address.city} ${
                    address.state ? address.state : ''
                } ${address.country}`,
                country: address.locale,
            });
            setMode(AddressFormStateEnum.SEARCH);
        },
        [formProvider],
    );
    const handleSearchCancel = useCallback(() => {
        const {reset} = formProvider;
        if (editFormState.current) {
            // editFormState is a ref so it should not be declared in the dependency array
            reset({...editFormState.current});
        }
        setMode(AddressFormStateEnum.EDIT);
    }, [setMode, formProvider]);
    const {Button: EditButton} = buttonifyFromProps(
        {
            buttonLabel: t('form_address_edit'),
            buttonTarget: () => setMode(AddressFormStateEnum.EDIT),
        },
        ['plain,size=small,startIcon=edit'],
    );
    const {Button: ExtraButton} = buttonifyFromProps(
        (extraButton && {
            buttonLabel: t('form_address_search_address_extra_button'),
            buttonTarget: () => {
                handleSubmit(deliveryAddress);
            },
            buttonType: 'plain,size=small',
        }) || {buttonLabel: '', buttonTarget: '', buttonType: ''},
    );
    useEffect(() => {
        if (updateAddressError) {
            setMode(baseAddress ? AddressFormStateEnum.DEFAULT : AddressFormStateEnum.EDIT);
            onError && onError(updateAddressError);
            setLoading(false);
        }
    }, [updateAddressError]);
    useEffect(() => {
        const {reset} = formProvider;
        reset({...baseAddress});
    }, [baseAddress]);
    useEffect(() => {
        onEdit(mode !== AddressFormStateEnum.DEFAULT);
    }, [mode, onEdit]);
    if (!user || !locale) return null;
    const canCloneAddress =
        !!(mode !== AddressFormStateEnum.SEARCH &&
            type === AddressTypeEnum.Billing &&
            deliveryAddress &&
            JSON.stringify({
                ...deliveryAddress,
                phone: undefined,
            }) !== JSON.stringify(baseAddress) &&
            extraButton &&
            ExtraButton);
    const spinnerDisplayed = foundAddressLoading || updateAddressLoading || loading;
    return (
        <FormProvider {...formProvider}>
            <div className={clsx(classes.root, className)}>
                {spinnerDisplayed && <Spinner className={classes.spinner} />}
                <div className={classes.head}>
                    <div className={classes.title}>{title}</div>
                    {mode === AddressFormStateEnum.DEFAULT && EditButton && (
                        <div className={classes.editButton}>
                            <EditButton />
                        </div>
                    )}{' '}
                    {/* edit button*/}
                </div>
                {canCloneAddress && (
                    // use delivery as billing button
                    <div>
                        <ExtraButton className={classes.extraButton} />
                    </div>
                )}
                {updateAddressError && (
                    <ErrorPanel
                        className={classes.error}
                        error={updateAddressError}
                        group={'updateAddress'}
                    />
                )}
                <div>
                    {mode === AddressFormStateEnum.DEFAULT && (
                        <DefaultAddress
                            defaultAddress={baseAddress}
                            locale={locale}
                            addressType={type}
                        />
                    )}
                    {mode === AddressFormStateEnum.EDIT && (
                        <EditAddress
                            defaultAddress={baseAddress}
                            locale={locale}
                            autoFocus={autoFocus}
                            addressType={type}
                            onCancel={() => setMode(AddressFormStateEnum.DEFAULT)}
                            onSelectAddress={handleSelectedAddress}
                        />
                    )}
                    {mode === AddressFormStateEnum.SEARCH && (
                        <SearchAddress
                            selectedAddress={selectedAddress}
                            locale={locale}
                            foundAddresses={foundAddresses}
                            addressType={type}
                            onCancel={handleSearchCancel}
                            onSubmit={handleSubmit}
                            loading={spinnerDisplayed}
                        />
                    )}
                </div>
            </div>
        </FormProvider>
    );
}

export interface AddressFormProps {
    title: React.ReactNode;
    locale: string;
    className?: string;
    autoFocus?: boolean;
    extraButton?: any;
    type: AddressTypeEnum;
    onSuccess?: any;
    onError?: any;
    onEdit?: Function;
}

export default AddressForm;
