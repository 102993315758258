import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({

    '@keyframes elastic': {
        '0%': {
            transform: 'scale(.9)',
            transformOrigin: '50% 50%',
        },
        '53.53%': {
            transform: 'scale(1.2)',
            transformOrigin: '50% 50%',
        },
        '80.16%': {
            transform: 'scale(.95)',
            transformOrigin: '50% 50%',
        },
        '93.41%': {
            transform: 'scale(1.03)',
            transformOrigin: '50% 50%',
        },
        '100.00%': {
            transform: 'scale(1)',
            transformOrigin: '50% 50%',
        },
    },

    root: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        height: 32,
        minHeight: 32,
        width: 32,
        minWidth: 32,
        '& svg > *': {
            transition: 'all .20s ease-out',
        },
        '& #plus': {
            transition: 'transform .20s ease-out',
            transform: props => `rotate(${props['toggled'] ? '0' : '45'}deg)`,
            transformOrigin: '18px 18px',
        },
        '.elasticObject': {
            animationName: 'elastic',
            animationDuration: '0.5s',
            animationFillMode: 'forwards',
            animationTimingFunction: 'ease-in-out',
        },
    },

    svg: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        '& path': {
            transition: 'all .20s ease-out',
        },
    },

    toggled: {
        '& #border, #fill': {
            animation: '.5s linear 0s $elastic',
        },
    },

    '@media (hover: hover)': {
        svg: {
            transition: 'transform .15s ease-in-out',
            '&:hover': {
                '& #border, #fill': {
                    transform: 'scale(1.1)',
                    transformOrigin: '50% 50%',
                },
            },
        },
    },
}));

export function WishlistToggle({toggled = false, height, width, className, ...props}:
                                   WishlistToggleProps & React.SVGProps<SVGSVGElement>) {
    const classes = useStyles({toggled: toggled});

    return (
        <div className={clsx(classes.root, className)}>
            <svg
                className={clsx(classes.svg, toggled && classes.toggled)}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                width={width || 24}
                height={height || 24}
                {...props}>
                <path
                    id="fill"
                    d="M15.9314 4C13.9657 4 12.9828 5.00655 12 6.01311C11.0172 5.00655 10.0343 4 8.06864 4C6.10297 4 4.66485 5.34207 4.13729 6.01311C3.60973 6.68414 2.17162 8.52949 3.64588 12.0524C4.90376 15.0583 8.79686 17.9076 11.7195 19.9129C11.8887 20.029 12.1113 20.029 12.2805 19.9129C15.2031 17.9076 19.0962 15.0583 20.3541 12.0524C21.8284 8.52949 20.3903 6.68414 19.8627 6.01311C19.3351 5.34207 17.897 4 15.9314 4Z"
                    fill={`${toggled ? '#FC7958' : 'transparent'}`}/>
                <path
                    id="border"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.06861 5.01164C6.54685 5.01164 5.36602 6.07656 4.92145 6.64162C4.67713 6.95216 4.29954 7.44047 4.11187 8.20681C3.92753 8.95953 3.90471 10.0794 4.56745 11.6619C5.10881 12.9547 6.26185 14.2965 7.72285 15.603C9.07522 16.8125 10.6151 17.9258 12 18.8816C13.3848 17.9258 14.9247 16.8125 16.2771 15.603C17.738 14.2965 18.8911 12.9547 19.4325 11.6619C20.0952 10.0794 20.0724 8.95953 19.888 8.20681C19.7004 7.44048 19.3228 6.95216 19.0785 6.64164C18.6339 6.07655 17.453 5.01164 15.9313 5.01164C14.4121 5.01164 13.688 5.72484 12.7132 6.72251L12 7.45238L11.2868 6.72251C10.3119 5.72485 9.58776 5.01164 8.06861 5.01164ZM3.35311 5.39331C3.96366 4.61727 5.65905 3 8.06861 3C9.94097 3 11.108 3.7823 12 4.60665C12.8919 3.7823 14.0589 3 15.9313 3C18.3409 3 20.0362 4.61729 20.6468 5.39329C20.9302 5.75348 21.5354 6.52271 21.83 7.72568C22.128 8.94269 22.0871 10.505 21.2756 12.4428C20.5591 14.1538 19.1367 15.7375 17.606 17.1064C16.0595 18.4894 14.3109 19.7323 12.8437 20.7383C12.3347 21.0872 11.6652 21.0872 11.1562 20.7383C9.68899 19.7323 7.94043 18.4894 6.39393 17.1064C4.86324 15.7375 3.44081 14.1538 2.72429 12.4428C1.91278 10.505 1.87188 8.94269 2.16993 7.72568C2.46452 6.52275 3.06973 5.7535 3.35311 5.39331Z"
                    fill={`${toggled ? '#FC7958' : '#423F37'}`}/>
                <circle
                    cx="18"
                    cy="18"
                    fill="#ffffff"
                    fillOpacity="1"
                    r="6" />
                <path
                    id="plus"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.828 16.586a1 1 0 10-1.414-1.414L18 16.586l-1.414-1.414a1 1 0 00-1.414 1.414L16.586 18l-1.414 1.414a1 1 0 101.414 1.414L18 19.414l1.414 1.414a1 1 0 101.414-1.414L19.414 18l1.414-1.414z"
                    fill={props.color || '#423F37'}/>
            </svg>
        </div>
    );
}

export interface WishlistToggleProps {
    toggled: boolean;
    className?: string;
    width?: number;
    height?: number;
}

export default WishlistToggle;
