import React from 'react';
import {PushLarge} from '../../../ui';

// todo deprecated, change for push_large_left
export function PushLargePushModule(props: PushLargePushModuleProps) {
    return (
        <PushLarge
            {...props}
            imagePosition="left"
        />
    );
}

export interface PushLargePushModuleProps {}

export default PushLargePushModule;
