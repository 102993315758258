import React, {forwardRef} from 'react';
import {TextFieldProps} from './TextField';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {cssText} from '@ohoareau/css-utils';
import SvgCalendar from '../../images/icons/Calendar';
import {getDateFormat} from '../../utils';
import {useLuniiTranslation} from '../../hooks';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    textField: {
        ...cssText(theme, 'standard', 'caption', undefined, undefined, '#908977'),
        color: 'red',
        width: '100%',
        textAlign: 'end',
        '& button': {
            margin: '-14px 0px 0 0',
        },
        '& > p': {
            ...cssText(theme, 'standard', 'caption', undefined, undefined, '#908977'),
            fontWeight: 'normal',
            width: '100%',
            textAlign: 'end',
            marginTop: 4,
        },
        '& input': {
            ...cssText(theme, 'standard', 'body_thick', undefined, undefined, '#423f37'),
        },
    },
    input: {
        background: '#FFFFFF',
        border: '2px solid #D6D3CA',
        boxSizing: 'border-box',
        borderRadius: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(1, 2),
        cursor: 'pointer',
    },
    infos: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    placeholder: {
        ...cssText(theme, 'standard', 'caption', undefined, undefined, '#908977'),
        transition: 'transform .35s ease-out',
        '&.has-value': {
            transform: 'scale(0.75)',
            transformOrigin: '0 8px',
        },
    },
    value: {
        ...cssText(theme, 'standard', 'body_thick', undefined, undefined, '#423f37'),
        transition: 'transform .35s ease-out',
        '&.has-value': {
            transform: 'translate3d(0, -2px, 0)',
        },
    },
    calendar: {
        ...cssText(theme, 'standard', 'body_thick', undefined, undefined, '#423f37'),
        background: '#FFFFFF',
        boxShadow: '0px 8px 24px rgba(66, 63, 55, 0.1)',
        borderRadius: 8,
        border: 'none',
        width: '91.5vw',
        maxWidth: 550,
        marginTop: theme.spacing(.5),
        '& *': {
            outline: 'none',
        },
        '& .react-datepicker__month-container': {
            width: '100%',
        },
        '& .react-datepicker__header': {
            backgroundColor: '#FFFFFF',
            borderBottom: '1px solid #D6D3CA',
            margin: '0.4rem',
        },
        '& .react-datepicker__navigation': {
            color: '#423F37',
            width: 24,
            height: 24,
            marginTop: 12,
        },
        '& .react-datepicker__triangle': {
            display: 'none',
        },
        '& .react-datepicker__current-month': {
            fontSize: 16,
            color: '#423F37',
            margin: theme.spacing(1, 0),
        },
        '& .react-datepicker__day-names, & .react-datepicker__week': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        '& .react-datepicker__day-name': {
            padding: theme.spacing(.5, 0),
            color: '#423F37',
            width: 40,
        },
        '& .react-datepicker__day': {
            width: 40,
            padding: theme.spacing(.5, 0),
            backgroundColor: '#FFFFFF',
            color: '#423f37',
        },
        '& .react-datepicker__day:hover': {
            backgroundColor: '#FFF9EC',
        },
        '& .react-datepicker__day--selected': {
            backgroundColor: '#FFF9EC',
            border: '2px solid #FBBD2A',
            boxShadow: '0px 2px 8px rgba(66, 63, 55, 0.1)',
            borderRadius: 8,
            color: '#423F37',
        },
        '& .react-datepicker__day--disabled, & .react-datepicker__day--disabled:hover': {
            backgroundColor: '#FFFFFF',
            color: '#A79F8E',
            fontWeight: 600,
        },
    },
}));

export function DateField({className = '', locale, value, onValueChange, onError, ...props}: DateFieldProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const CustomInput = forwardRef((props, ref) => (
        <div className={clsx(classes.input, className)} ref={ref as any} onClick={props['onClick']}>
            <div className={classes.infos}>
                <div className={clsx(classes.placeholder, 'placeholder', !!props['value'] && 'has-value')}>{t('date_format')}</div>
                <div className={clsx(classes.value, 'value', !!props['value'] && 'has-value')}>{props['value']}</div>
            </div>
            <SvgCalendar width={24} height={24}/>
        </div>
    ));
    return (
        <DatePicker
            locale={locale}
            selected={value}
            onChange={onValueChange}
            dateFormat={getDateFormat(locale)}
            calendarClassName={classes.calendar}
            customInput={<CustomInput/>}
            {...props}
        />
    );
}

export interface DateFieldProps extends TextFieldProps {
    locale: string,
    value?: any,
    onValueChange?: any,
    onError?: any,
}

export default DateField;
