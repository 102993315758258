import React, {useCallback, useState} from 'react';
import clsx from 'clsx';
import {useForm} from 'react-hook-form';
import {cssShadow, cssText} from '@ohoareau/css-utils';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {Modal, ErrorPanel, FieldSet, PromoCodeField, useLuniiTranslation, useShoppingCart} from '..'
import {Button} from '../..';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    form: {
        width: '100%',
    },
    open: {
        backgroundColor: 'transparent',
    },
    actions: {
        textAlign: 'right',
    },
    fieldset: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        marginTop: 0,
    },
    input: {
        width: '100%',
    },
    error: {
        marginTop: theme.spacing(1),
        padding: theme.spacing(1, 2),
    },
    cancel: {
        marginLeft: 10,
    },
    openFormButton: {
        marginLeft: 4,
        ...cssShadow(theme, 'level_01'),
    },
    modal: {
        maxWidth: 540,
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
        },
    },
    modalTitle: {
        color: '#2C3637 !important',
    },
    modalBody: {
        ...cssText(theme, 'standard', 'body', undefined, undefined, '#5F6769'),
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(1),
        },
    },
}));

export function PromoCodeForm({className = '', onPromoCodeAdded = () => {}, hasDiscount = false, disabled = false}: PromoCodeFormProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const [showForm, setShowForm] = useState<boolean>(false);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const onActivate = useCallback(() => {
        setShowForm(false);
        onPromoCodeAdded();
    }, [onPromoCodeAdded]);
    const {handleSubmit, register, errors, watch, clearErrors} = useForm({mode: 'onChange'});
    const [{activateCartDiscountCode}, {activateCartDiscountCode: {loading, error: errorActivateCartDiscount, reset}}] = useShoppingCart(onActivate);
    const toggleShowForm = useCallback(() => {
        setShowForm(!showForm);
        clearErrors('promoCode');
        reset();
    }, [showForm, reset, clearErrors]);
    const x = {errors, register};
    const promoValue = watch('promoCode');
    const onSubmit = ({promoCode}: any) => {
        hasDiscount ? setOpenModal(true) : activateCartDiscountCode({promoCode})
    };
    const handleModalConfirm = () => {
        setOpenModal(false);
        activateCartDiscountCode({promoCode: promoValue})
    }
    return (
        <div className={clsx(classes.root, className)}>
            {showForm && !disabled
                ? (
                    <form onSubmit={handleSubmit(onSubmit)} className={clsx(classes.form, showForm && classes.open)}>
                        <FieldSet className={classes.fieldset}>
                            <PromoCodeField disabled={loading} className={clsx(classes.input)} min={1} max={100} {...x}/>
                        </FieldSet>
                        <ErrorPanel className={classes.error} error={errorActivateCartDiscount || errors} group='activateCartDiscountCode'/>
                        <Button
                            text={t('button_label_validate')}
                            theme='primary'
                            size='small'
                            disabled={(promoValue as string || '').trim().length === 0}
                            loading={loading}
                            type='submit'/>
                        <Button
                            className={classes.cancel}
                            text={t('button_label_cancel')}
                            theme='secondary'
                            size='small'
                            disabled={loading}
                            url={toggleShowForm}/>

                    </form>
                )
                : (
                    <Button
                        className={classes.openFormButton}
                        disabled={disabled}
                        text={t(hasDiscount && 'discount_edit' || 'promo_code_form_add_promo_code')}
                        theme='secondary'
                        size='medium'
                        icon='coupon'
                        url={toggleShowForm}/>
                )
            }
            <Modal
                open={openModal}
                className={classes.modal}
                onCloseCallback={() => setOpenModal(false)}
                titleClassName={classes.modalTitle}
                title={t('discount_cumul_title')}
                body={<div className={classes.modalBody}>{t('discount_cumul_text')}</div>}
                actions={(
                    <>
                        <Button
                            text={t('button_label_cancel')}
                            theme='secondary'
                            size='medium'
                            url={() => setOpenModal(false)}/>
                        <Button
                            text={t('discount_cumul_cta')}
                            theme='primary'
                            size='medium'
                            url={handleModalConfirm}/>
                    </>
                )}
            />
        </div>
    );
}

export interface PromoCodeFormProps {
    className?: string;
    hasDiscount?: boolean;
    onPromoCodeAdded?: any;
    disabled?: boolean;
}

export default PromoCodeForm;
