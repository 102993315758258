import React from 'react';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {cssText} from "@ohoareau/css-utils";
import {BulletPoint, buttonifyFromProps, convertPrice, convertProductReferenceToSlug, DynamicIcon, ECommerceCarousel,
    hardware_product, HardwareSelector, image_value, LuniiProductProvider, Row,
    useHasMounted, useLuniiNavigation, useLuniiTranslation} from '../../../ui';
import {colors} from '../../../../configs';
import {Alert, Tag} from '../..';
import {multi_product_universe, tag_theme} from '../../../types';
import {convertProductTypeForPageModel} from '../../../utils';

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: theme.spacing(6),
        position: 'relative',
        overflow: 'hidden',
        [theme.breakpoints.down('xs')]: {
            paddingTop: theme.spacing(5),
        },
    },
    grid: {
        zIndex: 15,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    decorator: {
        position: 'absolute',
        zIndex: 10,
        backgroundColor: (props: any) => props.universe === 'default' && 'transparent' || colors.multiProductUniverse[props.universe].main,
        top: '-11vw',
        left: '-9vw',
        width: '42vw',
        height: '44vw',
        borderRadius: '0 0 100% 0',
        [theme.breakpoints.down('md')]: {
            top: '-22vw',
            left: '-12vw',
            width: '50vw',
            height: '51vw',
        },
        [theme.breakpoints.down('sm')]: {
            top: '-18vw',
            width: '200vw',
            height: '100vw',
            right: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '0 0 100vw 100vw',
        },
    },
    content: {
        flex: 1,
    },
    tag: {
        marginBottom: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(1),
        },
    },
    right: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        marginLeft: 22,
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
        },
    },
    title: {
        color: '#423F37',
        marginBottom: theme.spacing(1),
    },
    price: {
        color: (props: any) => colors.multiProductUniverse[props.universe][props.universe === 'default' && 'second' || 'main'],
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    reduction: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexWrap: 'wrap',
        margin: theme.spacing(.5, 0, .5, 1.5),
    },
    reductionPercentage: {
        ...cssText(theme, 'standard', 'button_3', undefined, undefined, '#7F1F08'),
        backgroundColor: '#F5E5E4',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 26,
        padding: theme.spacing(0, 1.5, 0, 1),
        clipPath: 'polygon(0 0, 0 100%, 86% 100%, 101% 50%, 86% 0%)',
        borderRadius: 4,
    },
    reductionOldPrice: {
        ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#7C878B'),
        textDecoration: 'line-through',
        marginLeft: 8,
        margin: '4px 0 2px 0',
    },
    text: {
        color: '#716C5E',
        margin: theme.spacing(2, 0),
    },
    itemsTitleContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        margin: theme.spacing(1, 0, 2, 0),
        color: '#423F37',
    },
    itemsTitleSvg: {
        marginRight: theme.spacing(1),
    },
    itemsTitle: {
        ...cssText(theme, 'standard', 'title_2'),
        color: '#2C3637',
    },
    hardwareSelector: {
        marginBottom: theme.spacing(3),
        width: '100%',
    },
    button: {
        width: '100%',
        margin: theme.spacing(1, 0),
    },
    shippingContainer: {
        width: '100%',
        textAlign: 'center',
    },
    shippingInfos: {
        width: '100%',
        color: '#5F6769',
        marginBottom: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    svg: {
        marginRight: theme.spacing(.5),
    },
    outOfStock: {
        color: '#C9310C',
    },
    itemsContainer: {
        width: '100%',
        display: 'grid',
        gridAutoFlow: 'row',
        gridTemplateColumns: '1fr 1fr',
        gap: theme.spacing(3, 2),
        marginTop: theme.spacing(3),
    },
    alert: {
        margin: theme.spacing(4, 0),
    },
}));

export function HardwareProductOverviewSectionSpec({
    product,
    items = [],
    itemsTitle = undefined,
    title = undefined,
    text = undefined,
    universe = 'default',
    tagTheme = undefined,
    tagLabel = undefined,
    tagIcon = undefined,
    image1 = undefined,
    image1Small = undefined,
    image2 = undefined,
    image2Small = undefined,
    image3 = undefined,
    image3Small = undefined,
    image4 = undefined,
    image4Small = undefined,
    alertTitle = undefined,
    alertDescription = undefined,
    alertTheme = undefined,
    alertClosable = undefined,
    alertIcon = undefined,
    alertImage = undefined,
    alertLinkLabel = undefined,
    alertLinkIcon = undefined,
    alertLinkIconPosition = undefined,
    alertLinkUrl = undefined,
    alertLink2Label = undefined,
    alertLink2Icon = undefined,
    alertLink2IconPosition = undefined,
    alertLink2Url = undefined,
    alertButtonLabel = undefined,
    alertButtonIcon = undefined,
    alertButtonIconPosition = undefined,
    alertButtonUrl = undefined,
 }: HardwareProductOverviewSectionSpecProps) {
    const {price, currency, name, reduction, stock, oldPrice, shippingInfos, reference} = product || {};
    const classes = useStyles({universe});
    const {t, exists} = useLuniiTranslation();
    const {goPageByModel} = useLuniiNavigation();
    const hasMounted = useHasMounted();
    const {Button} = buttonifyFromProps({
        buttonLabel: `${t('product_add_to_cart')} ${price === 0 || price === undefined ? ` - ${t('product_price_free')}` : convertPrice(price, currency)}`,
        buttonTarget: `https://lunii/add_to_cart?forceAdd=true&forcedQuantity=${1}`,
        buttonType: 'primary,startIcon=basket-24',
    });
    const onProductChange = (p: hardware_product) => {
        const prefix = convertProductTypeForPageModel(p?.subtype);
        p?.slug && goPageByModel && goPageByModel(`${prefix}_${p.slug}`); // works only with slugged endpoints (see gatsby-node.ts)
    };
    const titleKey = reference && `ecommerce_hardware_${convertProductReferenceToSlug(reference)}_name`;
    const _title = (titleKey && exists(titleKey) && t(titleKey)) || title || name;
    const formattedImages = [
        { image: image1, image2: image1Small },
        { image: image2, image2: image2Small },
        { image: image3, image2: image3Small },
        { image: image4, image2: image4Small },
    ].filter(x => x?.image) as {image: image_value}[];
    const alternateProducts = (product?.alternateProducts || []).filter(({slug}) => !!slug);
    if (!hasMounted) return null;
    if (!product || product.type !== 'hardware' || product.notFound) return null;
    return (
        <LuniiProductProvider value={product}>
            <Row className={classes.root} padding='none'>
                <Grid item container lg={12} md={12} xs={12} justifyContent='space-between' className={classes.grid}>
                    <ECommerceCarousel
                        universe={universe}
                        className={classes.content}
                        items={formattedImages}/>
                    <div className={clsx(classes.right, classes.content)}>
                        {(tagLabel || tagIcon) && <Tag className={classes.tag} theme={tagTheme} icon={tagIcon} label={tagLabel} size='medium'/> || false}
                        <div className={clsx('large_title', classes.title)}>{_title}</div>
                        <div className={clsx('title_1', classes.price)}>
                            {stock ? (price === 0 ? t('product_price_free') : convertPrice(price, currency)): undefined}
                            {stock && reduction && <span className={classes.reduction}>
                                <span className={clsx('secondary_body_thick', classes.reductionPercentage)}>{reduction}</span>
                                <span className={clsx('secondary_body', classes.reductionOldPrice)}>{convertPrice(oldPrice, currency)}</span>
                            </span>}
                        </div>
                        <div className={clsx('body', classes.text)}>{text}</div>
                        {!!alternateProducts.length &&
                            <>
                                <div className={classes.itemsTitleContainer}>
                                    <DynamicIcon
                                        className={classes.itemsTitleSvg}
                                        type='hand-24'/>
                                    <div className={classes.itemsTitle}>{itemsTitle || t('ecommerce_hardware_included_title')}</div>
                                </div>
                                <HardwareSelector
                                    className={classes.hardwareSelector}
                                    product={product}
                                    alternateProducts={alternateProducts}
                                    onProductChange={onProductChange}/>
                            </>
                            || false}
                        {Button && <Button className={classes.button} disabled={!stock}/>}
                        <div className={classes.shippingContainer}>
                            <div className={clsx('secondary_body', clsx(classes.shippingInfos, !stock && classes.outOfStock))}>
                                <DynamicIcon
                                    type='box-16'
                                    className={classes.svg}
                                    color='currentColor'/>
                                {stock && shippingInfos || false}
                                {!stock && t('product_out_of_stock') || false}
                            </div>
                        </div>
                        {items.length > 0 && (
                            <div className={classes.itemsContainer}>
                                {items.map(item => (
                                    <BulletPoint size='small' {...item} key={`bullet-point-${Math.random()}`}/>
                                ))}
                            </div>
                        )}
                        <Alert
                            className={classes.alert}
                            title={alertTitle}
                            description={alertDescription}
                            theme={alertTheme}
                            closable={alertClosable}
                            icon={alertIcon}
                            image={alertImage}
                            linkLabel={alertLinkLabel}
                            linkUrl={alertLinkUrl}
                            linkIcon={alertLinkIcon}
                            linkIconPosition={alertLinkIconPosition}
                            link2Label={alertLink2Label}
                            link2Url={alertLink2Url}
                            link2Icon={alertLink2Icon}
                            link2IconPosition={alertLink2IconPosition}
                            buttonLabel={alertButtonLabel}
                            buttonUrl={alertButtonUrl}
                            buttonIcon={alertButtonIcon}
                            buttonIconPosition={alertButtonIconPosition}
                        />
                    </div>
                </Grid>
                <div className={classes.decorator}/>
            </Row>
        </LuniiProductProvider>
    );
}

export interface HardwareProductOverviewSectionSpecProps {
    product: hardware_product;
    itemsTitle?: any;
    title?: any;
    text?: any;
    items: {icon: string, title: string, text: string}[];
    universe : multi_product_universe;
    tagTheme?: tag_theme;
    tagLabel?: string;
    tagIcon?: string;
    image1?: image_value;
    image1Small?: image_value;
    image2?: image_value;
    image2Small?: image_value;
    image3?: image_value;
    image3Small?: image_value;
    image4?: image_value;
    image4Small?: image_value;
    alertTitle?: any;
    alertDescription?: any;
    alertTheme?: tag_theme;
    alertClosable?: boolean;
    alertIcon?: string;
    alertImage?: image_value;
    alertLinkLabel?: any;
    alertLinkIcon?: string;
    alertLinkIconPosition?: 'left' | 'right';
    alertLinkUrl?: any;
    alertLink2Label?: any;
    alertLink2Icon?: string;
    alertLink2IconPosition?: 'left' | 'right';
    alertLink2Url?: any;
    alertButtonLabel?: any;
    alertButtonIcon?: string;
    alertButtonIconPosition?: 'left' | 'right';
    alertButtonUrl?: any;
}

export default HardwareProductOverviewSectionSpec;
