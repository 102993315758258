import {useCallback} from 'react';
import useLuniiMutationApi from '../useLuniiMutationApi';

export function useGiftCardBalanceSubmit(callback: Function|undefined = undefined) {
    const [addGiftCardToBalance, {loading, error, ...infos}] = useLuniiMutationApi('ADD_GIFT_CARD_TO_BALANCE');
    const onSubmit = useCallback(async ({giftCard: giftCardId}) => {
        try {
            const response = await addGiftCardToBalance({
                variables: {
                    data: {
                        giftCardId,
                    },
                },
            });
            const data = response?.data?.addGiftCardToBalance || {};
            const balanceResponse = {
                ...data,
                giftCardId,
            };
            callback && callback(balanceResponse);
            return (balanceResponse);
        } catch (e) {
            console.error(e);
        }
    }, [addGiftCardToBalance, callback]);
    return [onSubmit, {loading: Boolean(loading), error: error || false, ...infos}] as [any, any];
}

export default useGiftCardBalanceSubmit;
