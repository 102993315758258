import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import translations from '../../../configs/translations';

const defaultLocale = 'fr-FR';
const defaultFallbackLocale = 'fr-FR';
const defaultLang = 'fr';

export function i18nFactory({
    fallbackLng = undefined,
    lng = undefined,
    resources = undefined,
}: {
    fallbackLng?: string;
    lng?: string;
    resources?: any;
}) {
    const i = i18n.createInstance();
    // noinspection JSIgnoredPromiseFromCall
    i.use(initReactI18next).init({
        resources: resources || translations,
        fallbackLng: (code: string) => {
            /**
             ** Fallback lang is only active for french languages stores (fr, be, ch...).
             ** For others stores (it, de...), if key is missing, nothing is returned.
             */
            const lang = code && code.substr(0, 2).toLowerCase();

            let fallBack;

            switch (lang) {
                case 'fr':
                    fallBack = 'fr-fr';
                    break;
                case 'en': case 'nl':
                    fallBack = 'en-us';
                    break;
                case 'de':
                    fallBack = 'de-de';
                    break;
                case 'it':
                    fallBack = 'it-it';
                    break;
                case 'es':
                    fallBack = 'es-es';
                    break;
                default:
                    fallBack = 'fr-fr';
                    break;
            }

            return fallBack;
        },
        lng: lng || defaultLocale,
        keySeparator: false,
        interpolation: {
            escapeValue: false,
        },
        react: {
            wait: true,
        },
    });
    return i;
}

export default i18nFactory;
