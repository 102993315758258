import React from 'react';
import TextField, {TextFieldProps} from './TextField';

export function TermsOfServiceOptInField(props: TermsOfServiceOptInFieldProps) {
    return (
        <TextField name={'termsOfServiceOptIn'} {...props} />
    );
}

export interface TermsOfServiceOptInFieldProps extends TextFieldProps {}

export default TermsOfServiceOptInField