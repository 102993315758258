import React from 'react';
import TextField, {TextFieldProps} from './TextField';

export function CountryField(props: CountryFieldProps) {
    return (
        <TextField name={'country'} {...props} />
    );
}

export interface CountryFieldProps extends TextFieldProps {}

export default CountryField