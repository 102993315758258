import React from 'react';
import {cssShadow, cssText} from '@ohoareau/css-utils';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Img from '../nucleons/Img';
import clsx from 'clsx';
import {buttonifyFromProps} from '../hocs';

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: '#ffffff',
        borderRadius: theme.spacing(2),
        overflow: 'hidden',
        height: '100%',
        width: 244,
        [theme.breakpoints.up('sm')]: {
            width: 332,
        },
        [theme.breakpoints.up('md')]: {
            width: 276,
        },

        transition: 'box-shadow .35s ease-out',
        ...cssShadow(theme, 'level_01'),
        '&:hover': {
            ...cssShadow(theme, 'level_02'),
        },
    },
    bottom: {
        flexDirection: 'column-reverse',
    },
    imageContainer: {
        flex: 1,
        width: '100%',
    },
    image: {
        lineHeight: 0,
        height: 'initial',
        '& img': {
            height: 128,
        },
    },
    container: {
        flex: 2,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        padding: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(2, 3, 3),
        },
    },
    imageBottom: {
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(3, 3, 2),
        },
    },
    title: {
        ...cssText(theme, 'standard', 'title_2'),
        position: 'relative',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        marginBottom: theme.spacing(0.5),
        maxHeight: '56px',
    },
    text: {
        ...cssText(theme, 'standard', 'secondary_body'),
        color: '#716C5E',
        position: 'relative',
        maxHeight: '84px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    textGradient: {
        height: '44%',
        background: 'linear-gradient(transparent 33%, rgba(255,255,255,1) )',
        width: '100%',
        position: 'absolute',
        bottom: 0,
        pointerEvents: 'none',
        content: '""',
    },
    '@supports (-webkit-line-clamp: 2)': { // only apply these properties on browser that support it !
        title: {
            whiteSpace: 'unset',
            display: '-webkit-box',
            '-webkit-line-clamp': 2,
            '-webkit-box-orient': 'vertical',
            overflow: 'hidden',
        },
        text: {
            whiteSpace: 'unset',
            display: '-webkit-box',
            '-webkit-line-clamp': 4,
            '-webkit-box-orient': 'vertical',
            overflow: 'hidden',
        },
        textGradient: {
            display: 'none',
        },
    },
    button: {
        padding: '5.5px 15px',
        margin: theme.spacing(2, 0, 0),
    },
}));

export function ContentItemMosaic1({
    title,
    text,
    image,
    imagePosition = 'top',
    className,
    ...props
}: ContentItemMosaic1Props) {
    const classes = useStyles();
    const {Buttons} = buttonifyFromProps(props, ['tertiary,startIcon=add']);
    return (
        <div className={clsx(classes.root, imagePosition === 'bottom' && classes.bottom, className)}>
            {image && <div className={classes.imageContainer}>
                <div className={clsx(classes.image)}>
                    <Img {...image} />
                </div>
            </div>}
            <div className={clsx(classes.container, imagePosition === 'bottom' && image && classes.imageBottom)}>
                <div>
                    <div className={classes.title}>{title}</div>
                    <div className={classes.text}>
                        {text}
                        <div className={classes.textGradient}/>
                    </div>
                </div>
                {Buttons && <Buttons className={classes.button}/>}
            </div>
        </div>
    );
}

export interface ContentItemMosaic1Props {
    title?: any,
    text?: any,
    image?: any,
    imagePosition?: 'top' | 'bottom' | undefined,
    className?: string
}

export default ContentItemMosaic1;
