import {useCallback, useEffect} from 'react';
import useLuniiLazyQueryApi from './useLuniiLazyQueryApi';

export function useDatasource(dataSource: string = '', callback: Function|undefined = undefined) {
    let dataSourceGql = '';

    switch (dataSource) {
        case 'user_recommendations':
            dataSourceGql = 'FIND_USER_RECOMMENDATIONS';
            break;
        default:
            break;
    }

    const [load, {loading, error, data, ...infos}] = useLuniiLazyQueryApi(dataSourceGql);

    const onRefresh = useCallback(async () => {
        try {
            if (dataSource) await load();
        } catch (e) {
            console.error(e);
        }
    }, [load, dataSource]);

    useEffect(() => {
        if (callback) {
            callback(data);
        }
    }, [data, callback]);

    return [onRefresh, {loading: Boolean(loading), error: error || false, ...infos}] as [any, any];
}

export default useDatasource;
