import React, {useState} from 'react';
import {cssText} from '@ohoareau/css-utils';
import {useForm} from 'react-hook-form';
import {Typography, makeStyles} from '@material-ui/core';
import {Trans} from 'react-i18next';
import clsx from 'clsx';
import {Button, Clickable, TextField, Modal, Illustration, useSubscriptionGift, ErrorPanel, WarningMessage} from '..';
import {useLuniiTranslation} from '../hooks';
import SvgQuestion from '../images/icons/Question';

const useStyles = makeStyles((theme) => ({
    root: {},
    heading: {
        ...cssText(theme, 'standard', 'title_1'),
        [theme.breakpoints.down('sm')]: {
            ...cssText(theme, 'standard', 'title_2'),
        },
    },
    text: {
        ...cssText(theme, 'standard', 'body', undefined, undefined, '#5F6769'),
        margin: theme.spacing(1, 0, 2, 0),
        [theme.breakpoints.down('sm')]: {
            ...cssText(theme, 'standard', 'caption', undefined, undefined, '#5F6769'),
        },
    },
    form: {
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
    },
    field: {
        flex: '1 0 10%',
        [theme.breakpoints.down('sm')]: {
            flex: 1,
        },
        '&>div': {
            display: 'flex',
            alignItems: 'flex-start',
        },
    },
    buttons: {
        flex: '1 1 1',
        display: 'flex',
        marginTop: 12,
        '& > :first-child': {
            margin: theme.spacing(0, 2),
            [theme.breakpoints.down('sm')]: {
                margin: 0,
            },
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'row-reverse',
            flexWrap: 'wrap',
            marginTop: theme.spacing(2),
            '& > :not(:first-child)': {
                marginRight: theme.spacing(2),
            },
            order: 4,
        },
    },
    linkContainer: {
        flex: '0 1 100%',
        ...cssText(theme, 'standard', 'button_2', undefined, undefined, '#716C5E'),
        marginTop: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            order: 2,
        },
    },
    link: {
        ...cssText(theme, 'standard', 'button_1', undefined, undefined, 'link'),
        width: 'fit-content',
        display: 'flex',
        alignItems: 'flex-start',
    },
    modal: {
        display: 'flex',
        flexDirection: 'column',
        ...cssText(theme, 'standard', 'body', undefined, undefined, '#716C5E'),
        [theme.breakpoints.down('sm')]: {
            ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#716C5E'),
        },
    },
    list: {
        marginTop: 0,
        paddingLeft: 0,
        [theme.breakpoints.down('sm')]: {
            listStyleType: 'disc',
            paddingLeft: 20,
        },
        '&>li': {
            display: 'flex',
            marginTop: theme.spacing(3),
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
                display: 'list-item',
                marginTop: theme.spacing(2),
            },
        },
    },
    modalBodyHighlight: {
        display: 'inline',
        fontWeight: 800,
    },
    illustration: {
        height: 47,
        width: 64,
        marginRight: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    warning: {
        [theme.breakpoints.down('sm')]: {
            order: 3,
        },
    },
    error: {
        display: 'block',
        width: '100%',
        marginBottom: 0,
    },
    svg: {
        minWidth: 24,
        minHeight: 24,
        margin: theme.spacing(0, 1, 0, 0),
    },
}));

export function MySubscriptionForm({
    className = '',
    showWarning = false,
    onCancelClick = () => {},
    onSuccess = () => {},
}: MySubscriptionFormProps) {
    const classes = useStyles();
    const {t, i18n} = useLuniiTranslation();
    const {handleSubmit, register, errors, getValues, clearErrors, reset} = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
    });
    const [
        {activateCouponExecute},
        {
            activateCouponResult: {loading, error, reset: resetCall},
        },
    ] = useSubscriptionGift({
        activateCouponOptions: {
            onCompleted: (data) => onSuccess(data?.activateSubscriptionCoupon),
        },
    });
    const x = {errors, register};
    const couponId = (getValues('couponId') || '').toUpperCase();
    const handleCouponExecute = () => activateCouponExecute({variables: {data: {couponId}}});
    const [openModal, setOpenModal] = useState<boolean>(false);
    const toggleModal = () => {
        setOpenModal(!openModal);
    };
    const handleCancelClick = () => {
        onCancelClick();
        errors && reset();
        error && resetCall();
    };
    const modalBody = (
        <div className={classes.modal}>
            {t('my_subscription_activate_form_modal_body_title')}
            <ul className={classes.list}>
                <li>
                    <Illustration
                        className={classes.illustration}
                        name='my_subscription_activate_form_modal_body_one'
                        mt={0}
                    />
                    <div>
                        <Trans
                            i18n={i18n}
                            i18nKey='my_subscription_activate_form_modal_body_one'
                            components={{1: <span className={classes.modalBodyHighlight} />}}
                        />
                    </div>
                </li>
                <li>
                    <Illustration
                        className={classes.illustration}
                        name='my_subscription_activate_form_modal_body_two'
                        mt={0}
                    />
                    <div>
                        <Trans
                            i18n={i18n}
                            i18nKey='my_subscription_activate_form_modal_body_two'
                            components={{1: <span className={classes.modalBodyHighlight} />}}
                        />
                    </div>
                </li>
            </ul>
        </div>
    );
    return (
        <div className={clsx(classes.root, className)}>
            <Typography variant='h3' className={classes.heading}>
                {t('my_subscription_activate_form_heading')}
            </Typography>
            <div className={classes.text}>{t('my_subscription_activate_form_text')}</div>
            <form className={classes.form} onSubmit={handleSubmit(handleCouponExecute)}>
                <TextField
                    className={classes.field}
                    name='couponId'
                    required
                    disabled={showWarning}
                    autoFocus
                    loader={loading}
                    fullWidth
                    onChange={() => clearErrors()}
                    definition={{
                        pattern: {
                            value: /^ABO_[A-Z0-9]{5,}$/i,
                            message: t('my_subscription_activate_form_error_format_not_valid'),
                        },
                    }}
                    InputProps={{style: {textTransform: 'uppercase'}}}
                    {...x}
                />
                <div className={classes.buttons}>
                    <Button
                        type='submit'
                        disabled={showWarning || loading || couponId.length === 0}
                        size='small_mobile_only'
                        color='primary'
                    >
                        {t('my_subscription_activate_form_activate')}
                    </Button>
                    <Button
                        disabled={loading}
                        color='plain'
                        size='small_mobile_only'
                        onClick={handleCancelClick}
                    >
                        {t('my_subscription_activate_form_cancel')}
                    </Button>
                </div>
                {showWarning && <WarningMessage className={classes.warning} body={t('my_subscription_activate_form_modal_warning')} svg='warning' mode='alert'/> || false}
                {error && <ErrorPanel className={classes.error} error={error} group='subscriptionCoupon'/> || false}
                <div className={classes.linkContainer}>
                    <Clickable className={classes.link} onClick={toggleModal}>
                        <SvgQuestion className={classes.svg} color='currentColor' />
                        {t('my_subscription_activate_form_modal_heading')}
                    </Clickable>
                </div>
            </form>
            <Modal
                open={openModal}
                onCloseCallback={toggleModal}
                title={t('my_subscription_activate_form_modal_heading')}
                body={modalBody}
                actions={
                    <Button color='primary' onClick={toggleModal}>
                        {t('my_subscription_activate_form_modal_button')}
                    </Button>
                }
            />
        </div>
    );
}

export interface MySubscriptionFormProps {
    className?: string,
    showWarning?: boolean,
    onCancelClick?: Function,
    onSuccess?: Function,
}

export default MySubscriptionForm;
