import React, {useCallback, useState} from 'react';
import {Accordion, AccordionSummary, AccordionDetails} from '@material-ui/core'
import {cssText} from '@ohoareau/css-utils';
import DynamicIcon from '../nucleons/DynamicIcon';
import {darken} from '@material-ui/core/styles';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useLuniiEventTracking, useLuniiPage} from '../hooks';

const useStyles = makeStyles(theme => ({
    root: {
        ...cssText(theme, 'standard', 'body')
    },
    accordion: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        '&:not(:first-child)': {
            borderColor: darken(theme['background_color_clear'], .1),
            borderTop: '1px solid'
        },
        '&:before': {
            display: 'none'
        },
        '&.Mui-expanded': {
            margin: 0
        }
    },
    summary: {
        margin: 0,
        padding: '16px 0',
        minHeight: 36,
        ...cssText(theme, 'standard', 'title_1'),
        [theme.breakpoints.down('sm')]: {
            ...cssText(theme, 'standard', 'title_2'),
        },
        '&>*': {
            margin: 0,
            padding: 0
        },
        '&.Mui-expanded': {
            margin: 0,
            padding: '16px 0',
            minHeight: 36,
            '&>*': {
                margin: 0,
                padding: 0
            }
        },
        '.MuiAccordionSummary-expandIcon': {
            margin: 0,
            padding: 0,
            '&.Mui-expanded': {
                margin: 0,
                padding: 0,
                minHeight: 36
            }
        }
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        position: 'relative',
        padding: 0,
    },
    specItem: {
        display: 'flex',
        position: 'relative',
        marginBottom: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap'
        },
    },
    specItemLabel: {
        ...cssText(theme, 'standard', 'body_thick'),
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(4),
        width: 'calc(100% / 3)',
        [theme.breakpoints.down('md')]: {
            width: 'calc(100% / 4)'
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
    },
    specItemContent: {
        ...cssText(theme, 'standard', 'body'),
        width: '100%',
        overflow: 'hidden',
        '& ul': {
            columnCount: 2,
            columnGap: theme.spacing(4),
            [theme.breakpoints.down('sm')]: {
                columnCount: 1,
            }
        },
        '& img': {
            width: 'calc(100% / 2)',
            [theme.breakpoints.down('md')]: {
                width: '83.33%'
            },
            [theme.breakpoints.down('sm')]: {
                width: '100%'
            }
        }
    },
}));

export function ProductSpecsAccordion({items = []}: ProductSpecsAccordionProps) {
    const classes = useStyles();
    const handleEventTracking = useLuniiEventTracking();
    const {locale} = useLuniiPage() || {};
    const [expanded, setExpanded] = useState<string | false>(false);
    const handleChange = useCallback((panel: string, title: any) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
        let formattedTitle: string;
        if (title.props) { // FIXME - remove if richtext is prevemented in title field
            while (title && title['props'] && title['props']['render']) {
                title = title['props']['render'][0];
            }
            title = title.text;
        }
        formattedTitle = title
        newExpanded && handleEventTracking({
            categorie: 'interaction',
            action: 'accordeon',
            libelle: formattedTitle || panel
        });
        setExpanded(newExpanded ? panel : false);
    }, [setExpanded]);
    if (!items.length) return null;
    const expandIcon = <DynamicIcon type={'ArrowDown'}/>
    const IconButtonProps = {
        disableRipple: true
    };
    return (
        <div className={classes.root}>
            {items.map((item, index) => {
                const excludedLocales = (item.excludedLocales || '').split(',');
                if (excludedLocales.indexOf((locale || '').slice(-2)) !== -1) return false;
                return (
                    <Accordion className={classes.accordion} expanded={expanded === `panel-${index}`}
                               onChange={handleChange(`panel-${index}`, item.title)} key={`panel-${index}`}>
                        <AccordionSummary className={classes.summary} aria-controls={`panel-${index}d-content`}
                                          id={`panel-${index}d-header`} expandIcon={expandIcon}
                                          IconButtonProps={IconButtonProps}>
                            <div>{item.title}</div>
                        </AccordionSummary>
                        <AccordionDetails className={classes.details}>
                            {(item.items || []).map((specItem, itemIndex) => {
                                const itemExcludedLocales = (specItem.excludedLocales || '').split(',');
                                if (itemExcludedLocales.indexOf((locale || '').slice(-2)) !== -1) return false;
                                return (
                                    <div className={classes.specItem} key={`item-${itemIndex}`}>
                                        <div className={classes.specItemLabel}>{specItem.label}</div>
                                        <div className={classes.specItemContent}>
                                            {specItem.content}
                                        </div>
                                    </div>
                                )
                            })}
                        </AccordionDetails>
                    </Accordion>
                )
            })}
        </div>
    );
}

export interface ProductSpecsAccordionProps {
    items?: {
        title: any,
        excludedLocales: string,
        items: {
            excludedLocales: string,
            label: any,
            content: any
        }[]
    }[]
}

export default ProductSpecsAccordion
