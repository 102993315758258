import * as React from 'react';
function SvgLibrary(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width={props.width || 24}
            height={props.height || 24}
            {...props}
        >
            <path
                fill="none"
                d="M0 0h24v24H0z"
            />
            <path
                d="M17 5l4 16M10 11v4"
                stroke={props.color || 'currentColor'}
                strokeWidth={2}
                strokeLinecap="round"
            />
            <path
                d="M3 19.5v-13a1.5 1.5 0 113 0v13a1.5 1.5 0 01-3 0z"
                stroke={props.color || 'currentColor'}
                strokeWidth={2}
            />
            <path
                d="M10 7V4.5A1.5 1.5 0 0111.5 3v0A1.5 1.5 0 0113 4.5v15a1.5 1.5 0 01-1.5 1.5v0a1.5 1.5 0 01-1.5-1.5V19"
                stroke={props.color || 'currentColor'}
                strokeWidth={2}
                strokeLinecap="round"
            />
        </svg>
    );
}
export default SvgLibrary;
