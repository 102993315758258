import React from 'react';
import clsx from 'clsx';
import {cssBgColor} from '@ohoareau/css-utils';
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    root: {
        ...cssBgColor(theme, 'clear'),
    }
}));

export function Box({className, children}: BoxProps) {
    const classes = useStyles();
    return (
        <div className={clsx(classes.root, className)}>
            {children || ''}
        </div>
    );
}

export interface BoxProps {
    className?: string,
    children?: any,
}

export default Box