import useIllustration from '../hooks/useIllustration';
import {Img, ImgProps} from './Img';
import {Box} from '@material-ui/core';
import React from 'react';

export function Illustration({name, set = 'default', mt = 4, ...props}: IllustrationProps) {
    const image = useIllustration(name, set);
    return image ? <Box mt={mt} lineHeight={0}><Img {...image} {...props}/></Box> : null;
}

export interface IllustrationProps extends ImgProps {
    name: string,
    set?: string,
    mt?: number,
}
export default Illustration;
