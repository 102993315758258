import React from 'react';
import {useIllustration, Img, ImgProps} from '../../../ui';

export function Illustration({className = '', imageClassName = '', name, set = 'default', ...props}: IllustrationProps) {
    const image = useIllustration(name, set);
    if (!image) return null;
    return <div className={className} style={{lineHeight: 0, overflow: 'hidden'}}>
        <Img {...image} {...props} className={imageClassName}/>
    </div>
}

export interface IllustrationProps extends ImgProps {
    className?: string;
    imageClassName?: string;
    name: string,
    set?: string,
}
export default Illustration;
