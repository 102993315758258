import * as React from "react";

function SvgWish(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || 24}
      height={props.height || 24}
      {...props}
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.044 5.638C19.002 4.552 17.629 4 16 4c-1.398 0-2.728.56-3.976 1.64C10.779 4.56 9.453 4 8.058 4c-1.625 0-2.994.553-4.042 1.648-1.617 1.65-2.26 3.842-1.933 6.476.342 2.745 3.474 5.904 9.374 9.716a1 1 0 001.116-.02l.803-.495c5.41-3.582 8.289-6.58 8.616-9.201.33-2.635-.317-4.828-1.948-6.486zm-7.07 1.844C13.998 6.476 15.001 6 16 6c1.099 0 1.947.341 2.61 1.032 1.188 1.209 1.655 2.79 1.398 4.844-.224 1.788-2.637 4.338-7.258 7.46l-.728.484c-5.075-3.343-7.72-6.063-7.954-7.944-.256-2.055.209-3.637 1.385-4.837C6.121 6.34 6.965 6 8.058 6c1.06 0 2.124.54 3.217 1.69a1 1 0 001.414.034l.056-.057.228-.185z"
        fill={props.color || "currentColor"}
      />
      <path fill="#F8FCFF" fillOpacity={0.01} d="M0 0h24v24H0z" />
    </svg>
  );
}

export default SvgWish;
