import React, {useCallback} from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {cssActivableClickBox, cssBgColor, cssFgColor, bgVariant, fgVariant, cssText, cssShadow} from '@ohoareau/css-utils';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {useScrollSection} from 'react-scroll-section';
import Clickable from '../Clickable';
import useLuniiEventTracking from '../hooks/useLuniiEventTracking';
import {clampBuilder, fontClampBuilder, slugify} from '../utils';
import ArrowDown from '../images/icons/ArrowDown';
import {useLuniiPage} from '../hooks';
import {mapPrismicTarget} from '../../utils';

const buildTarget = (target, id, onSectionChange) => {
    const cb = useCallback(() => {
        onSectionChange && onSectionChange(id);
    }, [id]);
    return (!target && id) ? cb : target;
};

const useStyles = makeStyles(theme => ({
    '@global': {
        html: {
            '--menu-item-font-size': fontClampBuilder({minSize: '14px', maxSize: '16px', minScreenWidth: '1280px', maxScreenWidth: '1400px', root: '16px'}),
            '--menu-item-margin': clampBuilder({minSize: 2, maxSize: 8, minScreenWidth: 1280, maxScreenWidth: 1400}),
            '--menu-item-padding': clampBuilder({minSize: 6, maxSize: 8, minScreenWidth: 1280, maxScreenWidth: 1400}),
        },
    },
    root: {
        ...cssText(theme, 'standard', 'button_1'),
        margin: `0 var(--menu-item-margin)`,
        height: 30,
        borderRadius: 16,
        backgroundColor: 'unset',
        display: 'flex',
        alignItems: 'center',
        transition: 'background-color 0.3s ease-out',
        ...cssActivableClickBox(theme, 'button_1', 'buttons'),
        '& a:-webkit-any-link': {
            color: 'unset',
        },
        flex: 1,
        '&:first-child': {
            marginLeft: 0,
        },
        '&:last-child': {
            marginRight: 0,
        },
    },
    parentItem: {
        padding: `0 var(--menu-item-padding)`,
        display: 'flex',
        alignItems: 'center',
        color: '#423F37',
        '&:active, &:hover, &:selected': {
            color: '#423F37',
        },
        '& span': {
            display: 'block',
            margin: 0,
            fontSize: 'var(--menu-item-font-size)',
        },
    },
    withChildren: {
        padding: `0 ${clampBuilder({minSize: 4, maxSize: 8, minScreenWidth: 1280, maxScreenWidth: 1400})} 0 calc(8px + ${clampBuilder({minSize: 4, maxSize: 8, minScreenWidth: 1280, maxScreenWidth: 1400})})`,
        '& span': {
            margin: `0 ${clampBuilder({minSize: 6, maxSize: 8, minScreenWidth: 1280, maxScreenWidth: 1400})} 0 0`,
        },
    },
    opened: {
        ...cssBgColor(theme, bgVariant(theme, 'hover', 'active', 'none', true)),
        ...cssFgColor(theme, fgVariant(theme, 'hover', 'active', 'default', true, 'button_1')),
    },
    root_default: {},
    root_level1: {},
    root_level2: {
        ...cssText(theme, 'standard', 'button_2'),
    },
    childItem: {
        ...cssText(theme, 'standard', 'button_1'),
        padding: 4,
        '&:active, &:hover, &:selected': {
            color: '#423F37',
        },
        color: '#423F37',
        fontSize: '--menu-item-font-size',
    },
    container: {
        boxSizing: 'unset', // fix for CssBaseline collision
        paddingTop: 7,
        paddingLeft: 8,
        height: 24,
        overflow: 'hidden',
    },
    iconWrapper: {
        width: 'fit-content',
        height: 'fit-content',
        '& svg': {
            display: 'block',
        },
    },
    paper: {
        marginTop: theme.spacing(0.5),
        borderRadius: '16px !important',
        ...cssShadow(theme, 'level_04'),
    },
    list: {
        padding: 0,
    },
    clickable: {
        width: '100%',
    },
    menuItem: {
        '&:hover': {
            ...cssBgColor(theme, bgVariant(theme, 'hover', 'active', 'none', true)),
        },
        '&.Mui-selected': {
            '&:hover': {
                ...cssBgColor(theme, bgVariant(theme, 'hover', 'active', 'none', true)),
            },
            ...cssBgColor(theme, bgVariant(theme, 'hover', 'active', 'none', true)),
        },
        fontSize: 'var(--menu-item-font-size)',
    },
    menuItemGutters: {
        padding: '6px 16px',
        [theme.breakpoints.up('md')]: {
            padding: `${clampBuilder({minSize: 2, maxSize: 6, minScreenWidth: 1280, maxScreenWidth: 1400})} ${clampBuilder({minSize: 6, maxSize: 16, minScreenWidth: 1280, maxScreenWidth: 1400})}`,
        },
    },
}));

export function HorizontalMenuItem({
    active,
    index,
    id,
    label,
    target,
    variant = 'default',
    items = [],
    isTabBarMenuItem = false,
    currentPageUid,
}: HorizontalMenuItemProps) {
    const {locale} = useLuniiPage() || {};
    const classes = useStyles({active});
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const itemSection = useScrollSection(id || '');
    const handleEventTracking = useLuniiEventTracking();

    const handleMenuItemClick = useCallback((item: any) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        handleEventTracking({
            categorie: 'navigation',
            action: isTabBarMenuItem ? 'onglet' : 'menu',
            libelle: item.target || item.label,
        });
    }, [handleEventTracking]);

    const menuId = (items.length && label &&`menu-${slugify(label)}`) || undefined;
    const menuProps = items.length && {
        'aria-controls': menuId,
        'aria-haspopup': 'true',
    };
    const t = mapPrismicTarget(target, locale);
    return (
        <div className={clsx(classes.root, Boolean(anchorEl) && classes.opened, variant && classes[`root_${variant}`])} >
            <Clickable
                className={clsx(classes.parentItem, items.length > 0 && classes.withChildren)}
                onClick={items.length > 0 ? handleClick : buildTarget(t, id, itemSection.onClick)}
                onClickCb={handleMenuItemClick({target: t, label})}
                {...menuProps}>
                <span>
                    {label || `Item #${index}`}
                </span>
                {(items.length > 0) && (
                    <div className={classes.iconWrapper}>
                        <ArrowDown
                            width={16}
                            height={16}/>
                    </div>
                )}
            </Clickable>
            {(items.length > 0) && (
                <Menu
                    id={menuId}
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    elevation={0}
                    getContentAnchorEl={null}
                    classes={{paper: classes.paper, list: classes.list}}
                    anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                    transformOrigin={{vertical: 'top', horizontal: 'left'}}>
                    {items.map((item, i) => {
                        const itemT = mapPrismicTarget(item.target, locale);
                        return (
                            <MenuItem
                                selected={!!item.targetUid && !!currentPageUid && (currentPageUid === item.targetUid)}
                                key={i}
                                classes={{
                                    root: classes.menuItem,
                                    gutters: classes.menuItemGutters,
                                }}>
                                <Clickable
                                    className={classes.clickable}
                                    onClick={itemT}
                                    onClickCb={handleMenuItemClick({...item, target: itemT})}>
                                    <div className={classes.childItem}>
                                        {item.label}
                                    </div>
                                </Clickable>
                            </MenuItem>
                        )
                    })}
                </Menu>
            )}
        </div>
    );
}

export interface HorizontalMenuItemProps {
    index: number,
    id?: string,
    label?: string,
    last?: boolean,
    first?: boolean,
    active?: boolean,
    target?: any,
    items?: any[],
    onSectionChange?: Function,
    variant?: 'default' | 'level1' | 'level2',
    isTabBarMenuItem?: boolean,
    currentPageUid?: string,
}

export default HorizontalMenuItem;
