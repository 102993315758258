import React, {useCallback, useState} from 'react';
import {Clickable, DropDown} from '../';
import DynamicIcon from '../nucleons/DynamicIcon';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {cssText} from '@ohoareau/css-utils';

const useStyles = makeStyles(theme => ({
    item: {
        display: 'flex',
        ...cssText(theme, 'standard', 'button_2'),
        justifyContent: 'center',
        alignItems: 'center',
    },
    choicesSizing: {
        maxHeight: 330,
        overflow: 'auto',
        [theme.breakpoints.down('sm')]: {
            maxHeight: 300,
            maxWidth: 300,
        },
    },
    icon: {
        marginRight: theme.spacing(.5),
    },
}));

export function CountrySelector({items = [], currentItem, defaultOpened = false, onChange}: CountrySelectorProps) {
    const classes = useStyles();
    const [currentChoice, setCurrentChoice] = useState(currentItem);
    const handleChange = useCallback((id) => {
        setCurrentChoice(id);
        onChange && onChange(id);
    }, [onChange]);
    if (!items.length || !currentItem) return null;
    let currentCountry = (items || []).find(item => item.id === currentChoice);
    if (!currentCountry) return null;
    const currentChoiceComp = (
        <>
            <DynamicIcon type={`flag-${currentCountry.id.substr(3)}`} id={currentCountry.id} width={26} height={26} className={classes.icon}/>
            <div>{currentCountry.label}</div>
        </>
    );

    const choices = items && items.map(({id, target, label}, index) => (
        <Clickable key={`langItem-${index}`} onClick={() => handleChange(id)} className={classes.item}>
            <DynamicIcon type={`flag-${id.substr(3)}`} id={id} width={26} height={26} className={classes.icon}/>
            <div>{label}</div>
        </Clickable>
    ));

    return (
        <DropDown currentChoice={currentChoiceComp} choices={choices} rounded={true} big={false} defaultOpened={defaultOpened} choicesClassName={classes.choicesSizing} />
    );
}

export interface CountrySelectorProps {
    defaultOpened?: boolean,
    currentItem?: string,
    items: any[],
    onChange?: Function
}

export default CountrySelector
