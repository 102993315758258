
const allUnits = {
    px: {
        value: 'px',
        label: 'px',
        step: 1,
    },
    rem: {
        value: 'rem',
        label: 'rem',
        step: 0.01,
    },
};

/**
 * Units of measurements.
 */
const UNITS = Object.values(allUnits);

/**
 * Parses a number and unit from a value.
 */
function parseUnit(value: string): [string, string, number] {
    const num = value.replace('px', '').replace('rem', '');

    const unitMatches = value.match(/[\d.\-+]*\s*(.*)/);
    const unit = unitMatches !== null ? unitMatches[1] : '';
    const match = UNITS.find((item) => item.value === unit);

    return [num, match?.value || 'px', match?.step || 1];
}

function convertToRem(value: string, root: number): number {
    const [num, unit] = parseUnit(value);

    if (unit === 'rem') {
        return parseFloat(num);
    }

    return parseFloat(num) / root;
}

function toFixed(value: number) {
    return parseFloat(value.toFixed(4));
}

/**
 * Create clamp expression using the min/max values for the output value using the window min/max width
 * @param options
 */
export function clampBuilder(options: {
    minSize: number;
    maxSize: number;
    minScreenWidth: number;
    maxScreenWidth: number;
}): string {
    if (Object.values(options).some((value) => value === undefined)) {
        return '';
    }
    const {
        minSize,
        maxSize,
        minScreenWidth,
        maxScreenWidth,
    } = options;

    const slope = (maxSize - minSize) / (maxScreenWidth - minScreenWidth);
    const yAxisIntersection = toFixed(-minScreenWidth * slope + minSize);

    const min = `${minSize}px`;
    const max = `${maxSize}px`;
    const preferred = `calc(${yAxisIntersection}px + ${toFixed(slope * 100)}vw)`;

    return `clamp(${min}, ${preferred}, ${max})`;
}

/**
 * Create clamp expression for font using the min/max values for the output value using the window min/max
 * @param options
 */
export function fontClampBuilder(options: {
    minSize: string;
    maxSize: string;
    minScreenWidth: string;
    maxScreenWidth: string;
    root: string;
}): string {
    if (Object.values(options).some((value) => !value)) {
        return '';
    }
    const root = parseInt(options.root, 10);

    const minSize = convertToRem(options.minSize, root);
    const maxSize = convertToRem(options.maxSize, root);
    const minScreenWidth = convertToRem(options.minScreenWidth, root);
    const maxScreenWidth = convertToRem(options.maxScreenWidth, root);

    if ([minSize, maxSize, minScreenWidth, maxScreenWidth].some((v) => isNaN(v))) {
        return '';
    }

    const slope = (maxSize - minSize) / (maxScreenWidth - minScreenWidth);
    const yAxisIntersection = toFixed(-minScreenWidth * slope + minSize);

    const min = `${minSize}rem`;
    const max = `${maxSize}rem`;
    const preferred = `calc(${yAxisIntersection}rem + ${toFixed(slope * 100)}vw)`;

    return `clamp(${min}, ${preferred}, ${max})`;
}

export default {
    clampBuilder,
    fontClampBuilder,
};
