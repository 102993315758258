import React, {useCallback, useEffect, useState} from 'react';
import {InputBaseProps, makeStyles, InputBase} from '@material-ui/core';
import {cssText} from '@ohoareau/css-utils';
import {useDebouncedCallback} from 'use-debounce';
import {getThemeColor} from '../../../configs';
import {DynamicIcon} from '../../ui/nucleons';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        padding: '12px 16px',
        width: '100%',
        height: 48,
        border: `2px solid ${getThemeColor('ui', '200')}`,
        boxSizing: 'border-box',
        borderRadius: 16,
        alignItems: 'center',
        backgroundColor: getThemeColor('ui', '50'),
        color: getThemeColor('ui', '700'),
        transition: 'background-color 0.35s ease-out, border 0.35s ease-out',
        [theme.breakpoints.up('lg')]: {
            width: '50%',
        },
        '&:focus-within': {
            border: `2px solid ${getThemeColor('brand-primary', '500')}`,
            backgroundColor: getThemeColor('ui', 'white'),
            color: getThemeColor('ui', '900'),
        },
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        paddingRight: 8,
        transition: 'color 0.35s ease-out',
        color: 'currentColor',
    },
    searchIcon: {
        color: 'currentColor',
    },
    field: ({searchValue}: any) => ({
        flex: 1,
        ...cssText(theme, 'standard', searchValue ? 'body_thick' : 'body'),
        transition: 'color 0.35s ease-out',
        color: 'currentColor',
    }),
    inputField: {
        '&::placeholder': {
            opacity: 1,
        },
    },
    close: {
        cursor: 'pointer',
        color: 'currentColor',
        transition: 'all 0.35s ease-out',
    },
}));

export function CatalogSearch({
    value = '',
    onDebounced = () => {},
    debouncedTime = 500,
    onClear,
    ...props
}: CatalogSearchProps) {
    const [searchValue, setSearchValue] = useState<string>('');
    const classes = useStyles({searchValue});

    useEffect(() => {
        setSearchValue(value);
    }, [value]);

    const debouncedSearch = useDebouncedCallback((value: string) => {
        onDebounced && onDebounced(value);
    }, debouncedTime);

    const handleChange = useCallback(
        (e) => {
            const {value} = e.target;
            setSearchValue(value);
            debouncedSearch(value);
        },
        [setSearchValue, debouncedSearch],
    );

    const handleClear = useCallback(() => {
        setSearchValue('');
        onClear && onClear('');
    }, [setSearchValue, onClear]);

    const handleKeyUp = useCallback(
        (e) => {
            e.keyCode === 27 && handleClear(); // clear on escape
            e.keyCode === 13 && debouncedSearch.flush(); // search on enter
        },
        [handleClear],
    );

    return (
        <div className={classes.root}>
            <div className={classes.icon}>
                <DynamicIcon
                    className={classes.searchIcon}
                    type='search-24'
                />
            </div>
            <InputBase
                className={classes.field}
                value={searchValue}
                onChange={handleChange}
                inputProps={{className: classes.inputField}}
                onKeyUp={handleKeyUp}
                spellCheck
                {...props}
            />
            {!!searchValue && (
                <DynamicIcon
                    onClick={handleClear}
                    className={classes.close}
                    type='cross-24'
                />
            )}
        </div>
    );
}

export interface CatalogSearchProps extends InputBaseProps {
    value?: string;
    onDebounced?: Function;
    debouncedTime?: number;
    onClear?: Function;
}

export default CatalogSearch;
