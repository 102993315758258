import React, {useCallback} from 'react';
import {List} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {cssText} from '@ohoareau/css-utils';
import {ShoppingCartCheckoutItem} from '.';
import {useShoppingCart, useLuniiTranslation, Illustration} from '../../ui';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        padding: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2, 0),
        },
    },
    text: {
        ...cssText(theme, 'standard', 'overline_title_1', undefined, undefined, '#7C878B'),
        marginBottom: theme.spacing(.5)
    },
    title: {
        ...cssText(theme, 'standard', 'title_1', undefined, undefined, '#2C3637'),
        marginBottom: theme.spacing(3),
    },
    image: {
        width: '100%',
        maxWidth: 183,
        margin: '0 auto',
    },
    list: {
        margin: theme.spacing(3, 0),
        '&>:not(:last-child)': {
            marginBottom: theme.spacing(5),
        }
    },
    nbItems: {
        ...cssText(theme, 'standard', 'overline_title_1', undefined, undefined, '#7C878B'),
        marginBottom: theme.spacing(1 / 2),
    },
    nbTitle: {
        ...cssText(theme, 'standard', 'title_1', undefined, undefined, '#2C3637'),
    },
}));

export function ShoppingCartCheckout({cart}: ShoppingCartCheckoutProps) {
    const classes = useStyles();
    const [{deleteCartItem}] = useShoppingCart();
    const {t} = useLuniiTranslation();
    const handleRemove = useCallback(
        (id) => {
            deleteCartItem(id);
        },
        [deleteCartItem],
    );
    const items = (cart || {}).items || [];
    const itemsQuantity = items.reduce((q: number, item: any) => item.quantity + q, 0);
    return (
        <div className={classes.root}>
            {itemsQuantity && (
                <div>
                    <div className={classes.nbItems}>
                        {itemsQuantity} {t('checkout_cart_header_nb_items')}
                    </div>
                    <div className={classes.nbTitle}>
                        {t('checkout_cart_header_title')}
                    </div>
                </div>
            ) || false}
            {items.length > 0 ? (
                <List className={classes.list}>
                    {items.map((item: any) => (
                        <ShoppingCartCheckoutItem
                            key={Math.random()}
                            item={item}
                            onRemove={handleRemove}
                        />
                    ))}
                </List>
            ) : (
                <>
                    <div className={classes.text}>{t('cart_empty_subtitle')}</div>
                    <div className={classes.title}>{t('cart_empty_text')}</div>
                    <div className={classes.image}><Illustration name='checkout_empty_cart_v2'/></div>
                </>
            )}
        </div>
    );
}

export interface ShoppingCartCheckoutProps {
    cart: any;
}

export default ShoppingCartCheckout;
