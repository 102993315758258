import React from 'react';
import { Breadcrumbs as MuiBreadcrumbs } from '@material-ui/core';
import DynamicIcon from '../nucleons/DynamicIcon';
import {cssText} from '@ohoareau/css-utils';
import BreadcrumbsItem from '../nucleons/BreadcrumbsItem';
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    root: {
        ...cssText(theme, 'standard', 'button_1'),
    },
    /**
     * L'icône fait 8px mais dans un SVG de 24px
     * Donc il y a un espace de 8px de chaque côtés
     * Pour arriver à une marge de 32px on doit donc en tenir compte
     * 32 - ((24 - 8) / 2) = 24px
     */
    separator: {
        marginLeft: 24,
        marginRight: 24
    },
}));

export function Breadcrumbs({items = [], onClick, current}: BreadcrumbsProps) {
    const classes = useStyles();
    let currentFound = false;
    return (
        <MuiBreadcrumbs separator={<DynamicIcon type={'arrowRight'} />} classes={classes} aria-label="breadcrumb">
            {items.map((item, i) => {
                if (current === item.id) currentFound = true;           
                const color = current === item.id ? 'current' : currentFound ? 'future' : 'past';
                return <BreadcrumbsItem key={i} color={color} label={item.label} target={item.target} onClick={onClick} />;
            })}
        </MuiBreadcrumbs>       
    );
}

export interface BreadcrumbsProps {
    items: any[],
    onClick?: Function,
    current?: string,
}

export default Breadcrumbs