import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {cssText} from '@ohoareau/css-utils';
import {digital_album_product, Img, Modal, audiobook_story, useLuniiTranslation} from '../../../ui';
import {Button} from '.';

const useStyles = makeStyles(theme => ({
    body: {
        display: 'flex',
        flexDirection: 'column',
    },
    item: {
        ...cssText(theme, 'standard', 'body_thick', undefined, undefined, '#423F37'),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(1, 0),
        '&:not(:last-child)': {
            borderBottom: '1px solid #EFEDEA'
        },
        [theme.breakpoints.down('sm')]: {
            alignItems: 'flex-start',
            justifyContent: 'center',
            flexDirection: 'column',
            '&>:first-child': {
                marginBottom: theme.spacing(1),
            },
        },
    },
    image: {
        margin: theme.spacing(0, 0, 0, 2),
        maxHeight: 40,
        maxWidth: 40,
        height: 40,
        width: 40,
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(0, 2, 0, 0),
        },
    },
}));

export function ProductStoriesModal({className = '', product, open = false, setOpen = () => {}}: ProductStoriesModalProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    return (
        <Modal
            className={className}
            title={product.name}
            body={(
                <div className={classes.body}>
                    {(product.stories || []).map((story: audiobook_story) => (
                        <div className={classes.item}>
                            <div>{story.title}</div>
                            <div>
                                {story.combinations.map((combination: string) =>
                                    <Img loading='eager' className={classes.image} url={combination}/>)}
                            </div>
                        </div>
                    ))}
                </div>
            )}
            actions={<Button url={() => setOpen(false)} text={t('button_label_close')}/>}
            open={open}
            onCloseCallback={() => setOpen(false)}
        />
    );
}

export interface ProductStoriesModalProps {
    className?: string;
    product: digital_album_product;
    open?: boolean;
    setOpen?: Function;
}

export default ProductStoriesModal;
