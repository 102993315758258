import React, {useCallback} from 'react';
import {
    Avatar,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Typography,
} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {cssText} from '@ohoareau/css-utils';
import {DynamicIcon} from '../nucleons';
import {useLuniiTranslation} from '../hooks';
import {convertPrice} from '../utils';
import {ProductTableNameEnum} from '../types';

const useStyles = makeStyles(theme => ({
    root: {
        flex: 1,
    },
    name: {
        ...cssText(theme, 'standard', 'secondary_body_thick', undefined, undefined, '#2C3637'),
    },
    priceContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        ...cssText(theme, 'standard', 'secondary_body_thick', undefined, undefined,'#7C878B'),
        fontSize: 14,
        marginTop: 2,
    },
    oldPrice: {
        ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#A6B1B5'),
        textDecoration: 'line-through',
        fontSize: 12,
        marginRight: 8,
    },
    locale: {
        ...cssText(theme, 'standard', 'caption', undefined, undefined, '#7C878B'),
    },
    price: {
        fontWeight: 800,
    },
    thumbnail: {
        width: 48,
        height: 'auto',
        backgroundColor: '#faf9f8',
        borderRadius: 2,
    },
    actions: {
        right: 0,
    },
    btnDeleteItem: {
        color: '#20272C',
    },
}));

export function ShoppingCartWidgetItem({item = {}, onDelete}: ShoppingCartWidgetItemProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const handleDelete = useCallback(() => onDelete && onDelete(item.id), [onDelete, item]);
    const name = item.type === ProductTableNameEnum.GiftCards ? t('gift_cards_gift_in_cart_name') : item.name;
    return (
        <ListItem disableGutters className={classes.root}>
            <ListItemAvatar>
                <Avatar className={classes.thumbnail} variant={'square'} src={item.thumbnailUrl} alt={name} />
            </ListItemAvatar>
            <ListItemText>
                <Typography component={'div'} className={classes.name} noWrap title={name}>{name}</Typography>
                {item.locale && item.type === ProductTableNameEnum.Packs && <div className={classes.locale}>{t(`locale_${item.locale.toLowerCase()}_product_language`)}</div>}
                <div className={classes.priceContainer}>
                    {item.oldPrice && <div className={classes.oldPrice}>{convertPrice(item.oldPrice * item.quantity, item.currency)}</div>}
                    <div className={classes.price}>{item.price === 0 ? t('product_price_free') : convertPrice(item.price * item.quantity , item.currency)}</div>
                </div>
            </ListItemText>
            <ListItemSecondaryAction className={classes.actions}>
                <IconButton className={classes.btnDeleteItem} onClick={handleDelete} disableRipple><DynamicIcon type='trash-16' /></IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    );
}

export interface ShoppingCartWidgetItemProps {
    item?: any,
    onDelete?: Function,
}

export default ShoppingCartWidgetItem;
