import React, {useCallback, useEffect, useRef, useState} from 'react';
import {cssText} from '@ohoareau/css-utils';
import clsx from 'clsx';
import {useMediaQuery, useTheme, Grid, makeStyles} from '@material-ui/core';
import {useLocation} from '@reach/router';
import {
    useLuniiPage,
    useLuniiTranslation,
    Row,
    useLuniiEventTracking,
    DynamicIcon,
    useHasMounted,
} from '../../ui';
import {CatalogSearch, CatalogMosaicCardItems, CatalogMenu} from '../molecules';
import {setQueryParametersToCurrentURL} from '../../utils';
import {catalog_menu} from '../../types';

const useStyles = makeStyles((theme) => ({
    root: {
        ...cssText(theme, 'standard', 'body'),
        display: 'flex',
    },
    top: {
        marginBottom: theme.spacing(4),
    },
    separator: {
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
    },
    catalogMenu: {
        marginTop: theme.spacing(2),
    },
    mobileContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: 16,
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    mobileContent: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        ...cssText(theme, 'standard', 'button_1', undefined, undefined, 'link'),
    },
    svg: {
        marginRight: 8,
    },
    hide: {
        display: 'none',
    },
}));

// noinspection JSUnusedLocalSymbols
export function Catalog({mode = 'fah', items = [], menu = [], searchEngine = {}}: CatalogProps) {
    /**
     * Utiliser pour afficher tous les produits (sans pagination) pour les moteurs de recherche
     * Attention : provoque un re render quand le composant est monté...
     */
    const hasMounted = useHasMounted();

    const classes = useStyles();
    const theme = useTheme();
    const location = useLocation();
    const {...p} = useLuniiPage();
    const q = (URLSearchParams && new URLSearchParams(location?.search)).get('q') || '';
    const {t} = useLuniiTranslation();
    const handleEventTracking = useLuniiEventTracking();
    const ref = useRef<any>(null); // For scroll management on change page

    const [searchQuery, setSearchQuery] = useState('');
    const [filters, setFilters] = useState<catalog_menu[]>([]);
    const [currentPage, setCurrentPage] = useState(1);

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('md')) && !isMobile;
    const [openMobileMenu, setOpenMobileMenu] = useState(false);
    useEffect(() => {
        if (currentPage === 1) return;
        setCurrentPage(1);
    }, [filters, searchQuery]);

    useEffect(() => {
        setSearchQuery(q);
    }, [q]);

    useEffect(() => {
        setFilters(filters);
    }, [filters]);

    // Set filter to the current user locale
    useEffect(() => {
        if (hasMounted) {
            const locales: string[] = [];
            switch (p?.locale) {
                case 'es-us':
                    locales.push('es-mx');
                    break;
                case 'en-ca':
                    locales.push('en-us', 'en-gb');
                    break;
                case 'fr-be':
                case 'fr-lu':
                case 'fr-mc':
                case 'fr-ch':
                case 'fr-ca':
                    locales.push('fr-fr');
                    break;
                default:
                    locales.push(p.locale || 'fr-fr');
            }
            const langFilters = menu?.find((filterMenu) => filterMenu?.id === 'langs');
            const currentLocaleFilters: catalog_menu[] =
                langFilters?.items?.filter(
                    (filterItem: any) => locales.indexOf(filterItem?.id.slice(-5)) !== -1,
                ) || [];
            const categoriesFilters =
                (URLSearchParams && new URLSearchParams(location?.search)).get('filters') || '';

            if (categoriesFilters !== '') {
                const categoriesFiltersAsArray = categoriesFilters.split(',');
                const initialFilters = menu
                    .flatMap((menuCategory) =>
                        categoriesFiltersAsArray.map((categoryId) =>
                            menuCategory.items.find((item: any) => categoryId === item.id),
                        ),
                    )
                    .filter((res) => res !== undefined);
                setFilters(initialFilters);
            } else {
                setFilters(
                    currentLocaleFilters.map((filter: catalog_menu) => ({
                        ...filter,
                        label: t(`locale_${filter.label}_product_language`),
                        type: 'langs',
                    })),
                );
            }
        }
    }, [hasMounted]);

    const handlePageChange = useCallback(
        (position: number) => {
            // TODO: update location state / query based on results
            setCurrentPage(position);
            ref.current.scrollIntoView({
                block: 'center',
                inline: 'nearest',
                behavior: 'smooth',
            });
        },
        [ref, setCurrentPage],
    );

    const handleSearch = useCallback(
        (query: string) => {
            setQueryParametersToCurrentURL({
                queryParameters: {q: query},
            });
            if (filters.length > 0) setFilters([]);
            const queryLen = query.length;
            if (queryLen === 0 || queryLen > 1) setSearchQuery(query);
        },
        [searchQuery, setSearchQuery, setFilters, filters],
    );

    const handleMenuItemClick = useCallback(
        (item: catalog_menu) => {
            handleEventTracking({
                categorie: 'interaction',
                action: 'selecteur',
                libelle: item.label,
            });

            setFilters((prevFilters: catalog_menu[]) => {
                const index = prevFilters.findIndex((filter) => filter.id === item.id);
                if (index !== -1) prevFilters.splice(index, 1);
                else prevFilters.push(item);
                setQueryParametersToCurrentURL({
                    queryParameters: {
                        filters: [...prevFilters].map((filter) => filter.id).join(','),
                    },
                });
                return [...prevFilters];
            });

            if (searchQuery.length > 0) setSearchQuery('');
        },
        [setFilters, setCurrentPage, setSearchQuery, searchQuery, handleEventTracking],
    );

    const handleReinitialize = useCallback(() => {
        setQueryParametersToCurrentURL({
            queryParameters: {q: searchQuery},
            override: true,
        });
        setFilters([]);
    }, [setFilters]);

    const openMenu = useCallback(() => {
        setOpenMobileMenu(true);
    }, [setOpenMobileMenu]);

    const closeMenu = useCallback(() => {
        setOpenMobileMenu(false);
    }, [setOpenMobileMenu]);

    const subtitle: string = filters.length > 0 ? `(${filters.length})` : '';
    const maxItemsByPages = isMobile || isTablet ? 12 : 24;

    let filteredItems = items;
    if (searchQuery && searchQuery.length > 0) {
        filteredItems = searchEngine.search(searchQuery);
    } else if (filters && filters.length > 0) {
        const filtersBySections: any = {};

        (filters || []).forEach(({type, id}) => {
            if (type) {
                if (!filtersBySections[type]) filtersBySections[type] = [];
                filtersBySections[type].push(id);
            }
        });
        filteredItems = (items || []).filter((item) =>
            Object.keys(filtersBySections).every((section) =>
                filtersBySections[section].some((f: string) => item.types.indexOf(f) !== -1),
            ),
        );
    }

    const filteredItemsLength = filteredItems.length;
    const slicedItems = !hasMounted
        ? filteredItems
        : filteredItems.slice((currentPage - 1) * maxItemsByPages, maxItemsByPages * currentPage);
    const numberOfPages = Math.ceil(filteredItemsLength / maxItemsByPages);
    const activeFiltersCount = filters.length;

    return (
        <Row
            className={classes.root}
            alignItems="flex-start"
            padding="none"
        >
            <Grid
                ref={ref}
                className={classes.top}
                item
                container
                direction="column"
                lg={12}
                md={12}
                xs={12}
            >
                <CatalogSearch
                    onDebounced={handleSearch}
                    onClear={handleSearch}
                    debouncedTime={500}
                    value={searchQuery}
                    placeholder={t('catalog_search_textfield_placeholder')}
                />
            </Grid>
            <Grid
                item
                container
                xs={12}
                className={clsx(classes.mobileContainer, openMobileMenu && classes.hide)}
            >
                <div
                    className={classes.mobileContent}
                    onClick={openMenu}
                >
                    <DynamicIcon
                        type="filter"
                        className={classes.svg}
                        width={20}
                        height={20}
                    />
                    <div>{`${t('catalog_menu_title')} ${subtitle}`}</div>
                </div>
            </Grid>
            <Grid
                item
                container
                lg={8}
                md={9}
                xs={12}
                className={clsx(classes.content, openMobileMenu && classes.hide)}
            >
                <CatalogMosaicCardItems
                    mode={mode}
                    items={slicedItems}
                    filters={filters}
                    searchQuery={searchQuery}
                    onPageChange={handlePageChange}
                    numberOfPages={numberOfPages}
                    currentPage={currentPage}
                    catalogMounted={hasMounted}
                    nbTotalItems={filteredItemsLength}
                />
            </Grid>
            <Grid
                item
                container
                lg={1}
                className={classes.separator}
            />
            <Grid
                item
                container
                lg={3}
                md={3}
                xs={12}
                className={clsx(classes.content, classes.catalogMenu)}
            >
                <CatalogMenu
                    nbItemsActive={activeFiltersCount}
                    menu={menu}
                    onReinitialize={handleReinitialize}
                    onMenuItemClick={handleMenuItemClick}
                    opened={openMobileMenu}
                    onFinishClick={closeMenu}
                    filters={filters}
                />
            </Grid>
        </Row>
    );
}

export interface CatalogProps {
    mode?: 'fah' | 'flam';
    items?: any[];
    menu?: any[];
    searchEngine?: any;
}

export default Catalog;
