import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import {AuthorCard, digital_album_product,} from '../../../ui';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: 24,
        [theme.breakpoints.down('md')]: {
            gap: '16px 24px'
        },
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: '1fr',
            gap: 24,
        },
        '& > *': {
            width: '100%',
            minWidth: '100%',
            maxWidth: '100%',
        }
    },
}));

export function ProductCasting({className = '', product}: ProductCastingProps) {
    const classes = useStyles();
    const casting = [
        ...(product.authors || []).map((author: any) => ({
            ...author,
            type: 'author',
        })),
        ...(product.tellers || []).map((teller: any) => ({
            ...teller,
            type: 'teller',
        })),
        ...(product.translators || []).map((translator: any) => ({
            ...translator,
            type: 'translator',
        })),
    ];
    if (casting.length === 0) return null;
    return (
        <div className={clsx(classes.root, className)}>
            {casting.map(({name, gender, image, type}) => (
                <div key={`${name.replace(/\s/g, '-')}-${Math.random()}`}>
                    <AuthorCard title={name} gender={gender} image={image} type={type}/>
                </div>
            ))}
        </div>
    );
}

export interface ProductCastingProps {
    className?: string;
    product: digital_album_product;
}

export default ProductCasting;
