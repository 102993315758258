import React from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import useLuniiTranslation from '../hooks/useLuniiTranslation';
import {AddressForm} from '../molecules';
import {AddressTypeEnum, useLuniiUser} from '../';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
    },
}));

export function MyDeliveryAddress({className}: MyDeliveryAddressProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const {user} = useLuniiUser();
    return (
        <div className={clsx(classes.root, className)}>
            {user?.locale && (
                <AddressForm
                    key="delivery"
                    extraButton
                    title={t('my_delivery_address_title')}
                    locale={user?.locale}
                    type={AddressTypeEnum.Delivery}
                />
            )}
        </div>
    );
}

export interface MyDeliveryAddressProps {
    className?: string;
}

export default MyDeliveryAddress;
