import React, {useCallback} from 'react';
import {Swiper, SwiperProps, SwiperSlide} from 'swiper/react/swiper-react.js';
import {Navigation, Pagination, Mousewheel, A11y} from 'swiper';
import 'swiper/swiper.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/modules/a11y/a11y.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import {cssShadow, cssText} from '@ohoareau/css-utils';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Row from '../Row';
import Push from '../molecules/Push';
import SvgArrowLeft from '../images/icons/ArrowLeft';
import SvgArrowRight from '../images/icons/ArrowRight';
import {extractButtonsProps} from '../hocs';
import {module_title, WithItems} from '../types';
import ForceTheme from '../ForceTheme';
import {useLuniiEventTracking} from '../hooks';
import {SliderStepper} from '../atoms';
import useSwiperRef from '../hooks/swiper/useSwiperRef';

const useStyles = makeStyles(theme => ({
    root: {
        ...cssText(theme, 'standard', 'body'),
        position: 'relative',
        width: '100%',
        height: '100%',
        overflowX: 'hidden',
        boxSizing: 'border-box',
    },
    title: {
        ...cssText(theme, 'standard', 'large_title'),
        [theme.breakpoints.down('sm')]: {
            ...cssText(theme, 'standard', 'title_1'),
        },
    },
    swiperContainer: {
        width: '100%',
        height: '100%',
        padding: '8px 0',
        overflow: 'visible',
    },
    swiperSlide: {
        display: 'block',
        position: 'relative',
        borderRadius: theme.spacing(2),
        maxWidth: 946,
        width: '100%',
        height: '100%',
        [theme.breakpoints.down('md')]: {
            maxWidth: 796,
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '90%',
        },

        ...cssShadow(theme, 'level_01'),

        transition: 'opacity .35s ease-out, box-shadow .35s ease-out',

        '&.swiper-slide': {
            opacity: 0.6,
        },
        '&.swiper-slide-active': {
            ...cssShadow(theme,'level_03'),
            opacity: 1,
        },
    },
    swiperSlideContent: {
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
    },
    navigation: {
        display: 'flex',
        justifyContent: 'space-between',
        maxWidth: 946,
        width: '100%',
        margin: '20px 0',
        [theme.breakpoints.only('md')]: {
            maxWidth: 796,
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            maxWidth: '100%',
        },

    },
    stepper: {
        width: 'fit-content !important',
    },
    navButton: {
        cursor: 'pointer',
        padding: '4px 16px',
        borderRadius: 24,
        width: 50,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxSizing: 'border-box',
        backgroundColor: 'white',
        ...cssShadow(theme,'level_03'),
        '&:focus': {
            outline: 'none',
        },
    },
}));

export function Slideshow({title, items = []}: SlideshowProps) {
    const classes = useStyles();
    const handleEventTracking = useLuniiEventTracking();
    const wrappedEventTracking = useCallback(({libelle}) => {
        handleEventTracking({categorie: 'interaction', action: 'carousel_horizontal', libelle});
    }, [handleEventTracking]);

    const [nextEl, nextElRef] = useSwiperRef<HTMLDivElement>();
    const [prevEl, prevElRef] = useSwiperRef<HTMLDivElement>();
    const [pagination, paginationRef] = useSwiperRef<HTMLDivElement>();

    const swiperOptions: SwiperProps = {
        modules: [Navigation, Pagination, Mousewheel, A11y],
        slidesPerView: 'auto',
        spaceBetween: 8,
        slidesOffsetBefore: 0,
        centeredSlides: true,
        breakpoints: {
            960: {
                centeredSlides: false,
                spaceBetween: 16,
                slidesOffsetBefore: 24,
            },
            1280: {
                centeredSlides: false,
                spaceBetween: 16,
                slidesOffsetBefore: 150,
            },
        },
        loop: true,
        speed: 350,
        loopAdditionalSlides: items.length,
        watchOverflow: true,
        autoplay: {
            delay: 4000,
            disableOnInteraction: true,
        },
        grabCursor: true,
        mousewheel: {
            forceToAxis: true,
        },
        pagination: {
            clickable: true,
            el: pagination,
            bulletClass: 'bull',
            bulletActiveClass: 'bull-active',
        },
        navigation: {
            prevEl,
            nextEl,
        },
    };

    if (items.length === 0) return null;

    return (
        <div className={classes.root}>
            <Row padding="default-top">
                <Grid
                    item
                    container
                    lg={12}
                    md={12}
                    xs={12}>
                    <div className={classes.title}>{title}</div>
                </Grid>
            </Row>
            <Row
                padding="xsmall-top"
                margins="none">
                { swiperOptions && <Swiper
                    className={classes.swiperContainer}
                    {...swiperOptions}>
                    {items.map(({product, title, text, image, ...itemProps}, index) => {
                        const {buttonsProps} = extractButtonsProps(itemProps);
                        return (
                            <SwiperSlide
                                className={classes.swiperSlide}
                                key={`slide-${index}`}>
                                <ForceTheme theme={itemProps.theme || 'default'}>
                                    <Push
                                        filters={false}
                                        title={title}
                                        product={product}
                                        text={text}
                                        {...buttonsProps}
                                        image={image}
                                        imagePadding
                                        imagePosition="right"
                                        imageLoading={index === 0 ? 'eager' : 'lazy'}
                                        className={classes.swiperSlideContent}
                                        mobileImagePosition="top"
                                        eventTrackingCallback={wrappedEventTracking}
                                    />
                                </ForceTheme>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
                }
            </Row>
            <Row padding="default-bottom">
                <Grid
                    item
                    container
                    lg={12}
                    md={12}
                    xs={12}>
                    <div className={classes.navigation}>
                        <div
                            className={classes.navButton}
                            ref={prevElRef}>
                            <SvgArrowLeft/>
                        </div>
                        <SliderStepper
                            className={classes.stepper}
                            reference={paginationRef}/>
                        <div
                            className={classes.navButton}
                            ref={nextElRef}>
                            <SvgArrowRight/>
                        </div>
                    </div>
                </Grid>
            </Row>
        </div>
    );
}

export interface SlideshowProps extends WithItems {
    title?: module_title,
}

export default Slideshow;
