import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import {cssText} from '@ohoareau/css-utils';
import {FooterSocialMenu, FooterMainMenu, FooterLanguageSelector, FooterNewsletter, Row, Img, FooterMainMenuMobile} from '..';

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: theme.spacing(2),
        ...cssText(theme, 'standard', 'button_2', undefined, undefined, 'body'),
    },
    content: {
        flex: 1,
    },
    desktop: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    mobile: {
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    image: {
        marginBottom: theme.spacing(2),
        lineHeight: 0,
        maxWidth: 200,
        width: '100%',
        height: 'initial',
        [theme.breakpoints.down('sm')]: {
            maxWidth: 150,
        },
    },
    newsletter: {
        marginBottom: theme.spacing(1),
    },
}));

export function Footer({image, menu, shareMenu, languages, currentLanguage}: FooterProps) {
    const classes = useStyles();
    return (
        <Row className={classes.root} alignItems='flex-start' justify='flex-start'>
            <Grid container item lg={5} md={5} xs={12} direction='column'>
                {image && <Img className={classes.image} objectFit='contain' {...image}/>}
                <FooterNewsletter className={classes.newsletter}/>
                <FooterSocialMenu menu={shareMenu}/>
            </Grid>
            <Grid lg={1} md={1} className={classes.desktop}/>
            <Grid container item lg={3} md={3} className={classes.desktop}>
                <FooterMainMenu menu={menu} col={1}/>
            </Grid>
            <Grid container item lg={3} md={3} xs={12}>
                <div className={classes.content}>
                    <FooterMainMenu menu={menu} col={2} className={classes.desktop}/>
                    <FooterMainMenuMobile menu={menu} className={classes.mobile}/>
                    <FooterLanguageSelector languages={languages} currentLanguage={currentLanguage}/>
                </div>
            </Grid>
        </Row>
    );
}

export interface FooterProps {
    image?: any,
    menu?: any,
    shareMenu?: any,
    languages?: any,
    currentLanguage?: any,
}

export default Footer;
