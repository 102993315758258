import React, {useCallback, useState} from 'react';
import {DropDown, Clickable, useLuniiTranslation} from '../';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    choice: {
        minWidth: 40,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

export function DropDownQuantity({className = '', quantity, quantitiesAvailable, onChange, disabled = false}: DropDownQuantityProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const [currentQuantity, setQuantity] = useState(quantity);
    const handleClick = useCallback((e, quantity) => {
        e.preventDefault();
        setQuantity(quantity);
        onChange(quantity);
    }, [onChange, setQuantity]);
    const quantities: number[] = [...Array(quantitiesAvailable).keys()].map(x => x + 1);
    const currentChoice = <span>{t('label_quantity_shortened')}: {currentQuantity}</span>;
    const choices = quantities.map((qty, i) => (
        <Clickable key={`qty_${qty}_${i}`} onClick={(e)=>handleClick(e, qty)}>
            <div className={classes.choice}>{qty}</div>
        </Clickable>
    ));
    return (
        <DropDown
            className={className}
            currentChoice={currentChoice}
            choices={choices}
            disabled={disabled}
            popperPlacement={'bottom'}/>
    );
}

export interface DropDownQuantityProps {
    className?: string,
    disabled?: boolean,
    onChange: Function,
    quantity: any,
    quantitiesAvailable: number,
}

export default DropDownQuantity;
