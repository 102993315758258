import React from 'react';
import clsx from 'clsx';
import {cssText, cssBg, cssBorder} from '@ohoareau/css-utils';
import {makeStyles} from '@material-ui/core/styles';
import {Button, Clickable, useLuniiTranslation, useLuniiUser, DynamicIcon} from '../../../../ui';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    menuItems: {
        display: 'flex',
        flexDirection: 'column',
    },
    menu: {
        margin: theme.spacing(0, 0, 2),
        '&:last-child': {
            margin: 0,
        },
    },
    active: {
        ...cssBg(theme, 'clear'),
        ...cssBorder(theme, 'push'),
        ...cssText(theme, 'standard', 'body_thick'),
        backgroundColor: '#feecc3',
    },
    label: {
        display: 'flex',
        ...cssText(theme, 'standard', 'body_thick'),
        height: 32,
        borderRadius: 16,
        padding: theme.spacing(0.5, 1),
        marginLeft: -theme.spacing(1),
        transition: 'color .35s ease-out, background-color .35s ease-out',
        '@media (hover: hover)': {
            '&:hover': {
                ...cssBg(theme, 'clear'),
                ...cssBorder(theme, 'push'),
                ...cssText(theme, 'standard', 'body_thick'),
                backgroundColor: '#feecc3',
            },
        },
    },
    icon: {
        heigh: 24,
        width: 24,
        marginRight: 6,
    },
    text: {
        ...cssText(theme, 'standard', 'caption'),
        marginTop: theme.spacing(0.5),
        paddingLeft: theme.spacing(4),
    },
    connectedAs: {
        ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#908977'),
        marginTop: theme.spacing(3),
    },
    userEmail: {
        ...cssText(theme, 'standard', 'secondary_body_thick'),
    },
    logoutBtn: {
        ...cssText(theme, 'standard', 'button_2', undefined, undefined, '#C04020'),
        backgroundColor: '#FEE9E4',
        marginTop: theme.spacing(2),
        '&:hover': {
            backgroundColor: '#FED9CF',
            color: '#C04020',
        },
    },
}));

export function MyMenu<T extends {id: string; title: string; subtitle?: string; icon: string}>({
    className = '',
    menu = [],
    selected = undefined,
    onMenuItemClick = () => {},
}: MyMenuProps<T>) {
    const classes = useStyles();
    const {user, logout} = useLuniiUser();
    const {t} = useLuniiTranslation();
    const handleLogout = () => {
        logout();
    };
    return (
        <div className={clsx(classes.root, className)}>
            <div className={classes.menuItems}>
                {menu.map(
                    (item) =>
                        item && (
                            <div
                                className={classes.menu}
                                key={`menu_item_${item.id}`}
                            >
                                <Clickable
                                    className={clsx(
                                        classes.label,
                                        item?.id === selected && classes.active,
                                    )}
                                    onClick={() => onMenuItemClick(item.id)}
                                >
                                    <div className={classes.icon}>
                                        <DynamicIcon type={item.icon} />
                                    </div>
                                    <span>{item.title}</span>
                                </Clickable>
                                <div className={classes.text}>{item.subtitle}</div>
                            </div>
                        ),
                )}
            </div>
            <div className={classes.connectedAs}>
                <div>{t('my_account_connected_as')}</div>
                <div className={classes.userEmail}>{user?.email}</div>
                <Button
                    className={classes.logoutBtn}
                    color="destructive"
                    size="small"
                    onClick={handleLogout}
                >
                    {t('my_account_logout')}
                </Button>
            </div>
        </div>
    );
}

export interface MyMenuProps<T> {
    className?: string;
    menu?: T[];
    selected?: string;
    onMenuItemClick?: Function;
}

export default MyMenu;
