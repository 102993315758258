import React from 'react';
import {AlertBanner} from '../../../ui';

export function ClosableAlertBannerModule(props: any) {
    return (
        <AlertBanner
            {...props}
            closable
        />
    );
}

export default ClosableAlertBannerModule;
