import React from 'react';
import {Newsletter} from '../../../ui';
import {useIllustration} from '../../../ui/hooks/useIllustration';

export function NewsletterCustomModule(props: any) {
    const image = useIllustration('newsletter_banner');
    return (
        <Newsletter
            {...props}
            image={image}
        />
    );
}

export default NewsletterCustomModule;
