import React, {useCallback, useState} from 'react';
import {navigate} from 'gatsby-link';
import Drawer from '@material-ui/core/Drawer';
import makeStyles from '@material-ui/core/styles/makeStyles';
import LoginForm from '../molecules/LoginForm';
import ForgotPasswordForm from '../molecules/ForgotPasswordForm';
import RegisterForm from '../molecules/RegisterForm';
import FormPanel from './FormPanel';
import LoginPanel from './LoginPanel';
import InfosPanelForm from '../molecules/InfosPanelForm';
import {useLuniiPage, useLuniiPageTracking, useLuniiPasswordUpdateModal, useLuniiTranslation, useLuniiUserTokens} from '../hooks';

const useStyles = makeStyles(theme => ({
    content: {
        flex: 5,
        backgroundColor: '#FFFFFF',
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
        paddingRight: 24,
        paddingLeft: 24,
        maxWidth: 384,
        width: '100%',
        [theme.breakpoints.down('md')]: {
            maxWidth: 472,
            width: '100%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100vw',
        },
    },
}));

export function LoginDrawer({opened = false, onClose, closeOnAuthTokensChange = true}: LoginDrawerProps) {
    const classes = useStyles();
    const {setCurrentUserTokens} = useLuniiUserTokens();
    const {t} = useLuniiTranslation();
    const page = useLuniiPage();
    const {open} = useLuniiPasswordUpdateModal();
    const handleConnectionPageTracking = useLuniiPageTracking({envModel: 'connexion'});
    const handleRegisterPageTracking = useLuniiPageTracking({envModel: 'inscription'});
    const [mode, setMode] = useState('login');
    const handleClose = useCallback(event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) return;
        onClose && onClose();
        setMode('login');
    }, [onClose]);
    const [redirectLanguage, setRedirectLanguage] = useState<any>(null);
    const handleForgotPasswordClick = useCallback(() => setMode('forgot-password'), []);
    const handleRegisterClick = useCallback(() => {
        handleRegisterPageTracking();
        return setMode('register');
    }, []);
    const handleForgotPasswordCancelClick = useCallback(() => setMode('login'), []);
    const handleRegisterCancelClick = useCallback(() => {
        handleConnectionPageTracking();
        setMode('login');
    }, []);
    const handleAuthTokensChange = useCallback(tokens => {
        setCurrentUserTokens(tokens);
        closeOnAuthTokensChange && onClose && onClose();
    }, [setCurrentUserTokens, onClose, closeOnAuthTokensChange]);
    const handleMustUpdatePassword = useCallback(loginData => {
        open(loginData);
        onClose && onClose();
    }, [open, onClose]);
    const handleForgotPasswordSuccess = useCallback((e) => {
        setMode(e === 'validation' ? 'validation-resend-success' : 'forgot-password-success');
    }, []);
    const handleRegisterSuccessClick = useCallback(() => {
        if (redirectLanguage === (page || {}).lang) setMode('login');
        const r = ((page || {}).languages || []).find((lang: any) => lang.id === redirectLanguage);
        r && r.target ? navigate(r.target) : navigate(redirectLanguage);
    }, [redirectLanguage]);
    const handleRegisterSuccess = useCallback((language) => {
        setRedirectLanguage(language);
        setMode('register-success');
    }, []);
    return (
        <Drawer anchor={'right'} open={opened} onClose={handleClose}>
            <div className={classes.content}>
                {('login' === mode) &&
                    <LoginPanel title={t('panels_login_title')} description={t('panels_login_description')}
                        onClose={handleClose} form={LoginForm}
                        formProps={{onAuthTokensChange: handleAuthTokensChange, onCancelClick: handleClose,
                            onForgotPasswordClick: handleForgotPasswordClick, onRegisterClick: handleRegisterClick,
                            onMustUpdatePassword: handleMustUpdatePassword}}/>}
                {('forgot-password' === mode) &&
                    <FormPanel onFormUpdate={handleForgotPasswordSuccess} title={t('panels_forgotpassword_title')}
                        description={t('panels_forgotpassword_description')} infos={'register_special_info'}
                        onClose={handleClose} formProps={{onCancelClick: handleForgotPasswordCancelClick}}
                        form={ForgotPasswordForm} logoPosition={'bottom'}/>}
                {('register' === mode) &&
                    <FormPanel onFormUpdate={handleRegisterSuccess} title={t('panels_register_title')}
                        description={t('panels_register_description')} infos={'register_special_info'}
                        onClose={handleClose} formProps={{onCancelClick: handleRegisterCancelClick}}
                        form={RegisterForm} logoPosition={'bottom'}/>}
                {('forgot-password-success' === mode) &&
                    <FormPanel title={t('panels_forgotpassword_success_title')} form={InfosPanelForm} logoPosition={'bottom'}
                        description={t('forms_forgotpassword_verify_inbox_text')} onClose={handleClose}
                        formProps={{onCancelClick: handleForgotPasswordCancelClick,
                            buttonLabel: t('forms_forgotpassword_buttons_cancel_label')}} />}
                {('validation-resend-success' === mode) &&
                    <FormPanel title={t('panels_forgotpassword_success_title')} form={InfosPanelForm} logoPosition={'bottom'}
                        description={t('forms_resend_validation_verify_inbox_text')} onClose={handleClose}
                        formProps={{onCancelClick: handleForgotPasswordCancelClick,
                            buttonLabel: t('forms_forgotpassword_buttons_cancel_label')}} />}
                {('register-success' === mode) &&
                    <FormPanel title={t('panels_register_success_title')} form={InfosPanelForm} logoPosition={'bottom'}
                        description={t('forms_register_verify_inbox_text')}
                        onClose={handleRegisterSuccessClick}
                        formProps={{onCancelClick: handleRegisterSuccessClick,
                            buttonLabel: t('forms_login_buttons_submit_label'),
                            alert: t('forms_register_alert_inbox_text')}} />}
            </div>
        </Drawer>
    );
}

export interface LoginDrawerProps {
    opened?: boolean,
    onClose?: Function,
    closeOnAuthTokensChange?: boolean,
}

export default LoginDrawer;
