import React, {useMemo} from 'react';
import Button from '../atoms/Button';
import ButtonDeferred from '../atoms/ButtonDeferred';
import describeButtonFromDefinition from '../utils/describeButtonFromDefinition';
import {useLuniiPage} from '../hooks';
import {mapPrismicTarget} from '../../utils';

export const buttonify = (label, type, target, defaults: string|any = {}) => {
    const {locale} = useLuniiPage() || {};
    const t = mapPrismicTarget(target, locale);
    const computedProps = useMemo(() => describeButtonFromDefinition({label, type, target: t}), [label, type, t]);
    const defaultsProps = useMemo(() => describeButtonFromDefinition('string' === typeof defaults ? {type: defaults} : defaults), [label, type, t]);
    if (!label) return null;
    if (!computedProps && !defaultsProps) return null;
    if ((defaultsProps || {})['hide'] || (computedProps || {})['hide']) return null;
    const forcedProps = {...(defaultsProps || {}), ...(computedProps || {})};
    if (!forcedProps['children'] && !forcedProps['target'] && !forcedProps['icon']
        && !forcedProps['startIcon'] && !forcedProps['endIcon']) return null;
    const Component = forcedProps['onClickDeferred'] ? ButtonDeferred : Button;
    return (props) => <Component{...forcedProps} {...props} {...(forcedProps['onClick'] ? {onClick: forcedProps['onClick']} : {})} />;
};

export const buttonifyFromProps = (
    {
        buttonLabel, buttonType, buttonTarget,
        button2Label, button2Type, button2Target,
        button3Label, button3Type, button3Target,
        ...rest
    }: any,
    [buttonDefaults = {}, button2Defaults = {}, button3Defaults = {}]: any[] = [],
) => {
    const Button1 = buttonify(buttonLabel, buttonType, buttonTarget, buttonDefaults);
    const Button2 = buttonify(button2Label, button2Type, button2Target, button2Defaults);
    const Button3 = buttonify(button3Label, button3Type, button3Target, button3Defaults);

    const Buttons = (props) => (
        <>
            {Button1 && <Button1 {...props} />}
            {Button2 && <Button2 {...props} />}
            {Button3 && <Button3 {...props} />}
        </>
    );

    return {rest, Buttons, Button: Button1, Button1, Button2, Button3, hasButtons: Button1 || Button2 || Button3};
};

export const extractButtonsProps = ({
    buttonLabel, buttonType, buttonTarget,
    button2Label, button2Type, button2Target,
    button3Label, button3Type, button3Target,
    ...props
}: any) => ({
    props,
    buttonsProps: {buttonLabel, buttonType, buttonTarget, button2Label, button2Type, button2Target, button3Label, button3Type, button3Target},
});


export default buttonify;
