import React from 'react';
import BulletPoint from '../molecules/BulletPoint';
import {cssText} from '@ohoareau/css-utils';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Row from "../Row";
import Grid from "@material-ui/core/Grid";
import {WithItems} from "../types";

const useStyles = makeStyles(theme => ({
    root: {
        ...cssText(theme, 'standard', 'body'),
    },
    container: {
        margin: theme.spacing(-3),
        [theme.breakpoints.down('md')]: {
            margin: theme.spacing(-2),
        },
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(-1),
        },
    },
    item: {
        alignSelf: 'stretch',
    },
    itemMargin: {
        margin: theme.spacing(3),
        [theme.breakpoints.down('md')]: {
            margin: theme.spacing(2),
        },
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(1),
        },
    }
}));

export function BulletPoints({items = []}: BulletPointsProps) {
    const classes = useStyles();
    return items.length ? (
        <Row className={classes.root} justify={'space-evenly'} padding={'custom2'}>
            <Grid container item lg={12} md={12} xs={12} className={classes.container}>
                {items && items.map((item, i) => (
                    <Grid item container className={classes.item} key={i} lg={3} md={3} xs={6}>
                        <BulletPoint {...item} className={classes.itemMargin} />
                    </Grid>
                ))}
            </Grid>
        </Row>
    ) : null;
}

export interface BulletPointsProps extends WithItems {
}

export default BulletPoints
