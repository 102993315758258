import {useCallback, useEffect} from 'react';
import useLuniiLibraryStudioFindApi from './useLuniiLibraryStudioFindApi';

export function useLibraryStudio(variables: any = {}, callback: Function|undefined = undefined) {
    const [getItems, {loading, error, data, ...infos}] = useLuniiLibraryStudioFindApi();

    const onRefresh = useCallback(async () => {
        try {
            await getItems();
        } catch (e) {
            console.error(e);
        }
    }, [getItems]);


    useEffect(() => {
        if (data?.findLibraryStudio?.items && callback) {
            callback(data?.findLibraryStudio?.items);
        }
    }, [data?.findLibraryStudio, callback]);

    return [onRefresh, {loading: Boolean(loading), error: error || false, data: data?.findLibraryStudio?.items, ...infos}] as [any, any];
}

export default useLibraryStudio;
