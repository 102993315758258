import React, {useCallback, useState} from 'react';
import Button from './Button';
import LoginDrawer from './LoginDrawer';
import useLuniiTranslation from '../hooks/useLuniiTranslation';
import {useLuniiEventTracking, useLuniiPageTracking} from '../hooks';

export function LoginButton() {
    const {t} = useLuniiTranslation();
    const handleEventTracking = useLuniiEventTracking();
    const [opened, setOpened] = useState(false);
    const handlePageTracking = useLuniiPageTracking({envModel: 'connexion'});
    const handleClick = useCallback(e => {
        e.stopPropagation();
        e.preventDefault();
        setOpened(true);
        handlePageTracking();
        // FIXME: update if Login button used outside of navbar menu
        handleEventTracking({
            categorie: 'navigation',
            action: 'menu',
            libelle: 'connexion',
        });
    }, [setOpened]);
    const handleClose = useCallback(() => {
        setOpened(false);
    }, [setOpened]);
    return (
        <>
            <Button size={'small'} color={'plain'} onClick={handleClick}>{t('navbar_login_label')}</Button>
            <LoginDrawer opened={opened} onClose={handleClose} closeOnAuthTokensChange={false} />
        </>
    );
}

export default LoginButton;
