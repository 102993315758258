import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
    root: {
        display: 'flex',
    },
});

export function DefaultLayout({children}: any) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            {children || ''}
        </div>
    );
}

export default DefaultLayout;
