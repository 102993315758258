import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import Button from '../atoms/Button';
import EmailField from '../atoms/fields/EmailField';
import PasswordField from '../atoms/fields/PasswordField';
import FieldSet from '../atoms/FieldSet';
import {useForm} from 'react-hook-form';
import {BodyText} from '../atoms/';
import useLoginSubmit from '../hooks/useLoginSubmit';
import ErrorPanel from '../atoms/ErrorPanel';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useLuniiTranslation from '../hooks/useLuniiTranslation';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    button: {
        marginTop: 16,
    },
    buttonDense: {
        marginTop: 16,
        padding: 0,
    },
    registerLink: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 32,
        fontWeight: 700,
    },
    fieldset: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    input: {
        minWidth: '70%',
    },
    registerLinkLink: {
        display: 'inline-block',
        padding: 0,
        marginLeft: 10,
    },
}));

export function LoginForm({
    className = undefined,
    onAuthTokensChange,
    onCancelClick,
    onForgotPasswordClick,
    onRegisterClick,
    onMustUpdatePassword,
    inputFullWidth = true,
    buttonFullWidth = true,
    loggedInGuest = true,
    registerLink = true,
}: LoginFormProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const {handleSubmit, register, errors} = useForm();
    const [onSubmit, {loading, error: loginSubmitError}] = useLoginSubmit(onAuthTokensChange);
    const [loginErrorIsMustUpdate, setLoginErrorIsMustUpdate] = useState<boolean>(false);

    /**
     * On login, if the returned errors contains one of type `must_reset_password`,
     * trigger the password change process
     */
    useEffect(() => {
        if (loginSubmitError?.graphQLErrors) {
            let errorIsMustUpdate = false;
            for (let i = 0, l = loginSubmitError.graphQLErrors.length; i < l; i++) {
                const error = loginSubmitError.graphQLErrors[i];
                if (error?.extensions?.exception?.code === 'must_reset_password') {
                    const {data: errorData} = error.extensions.exception;
                    errorIsMustUpdate = true;
                    onMustUpdatePassword(errorData);
                    break;
                }
            }
            setLoginErrorIsMustUpdate(errorIsMustUpdate);
        }
    }, [loginSubmitError, setLoginErrorIsMustUpdate, onMustUpdatePassword]);

    const x = {errors, register};

    return (
        <div className={clsx(classes.root, className)}>
            <form onSubmit={handleSubmit(onSubmit)}>
                {!loginErrorIsMustUpdate && <ErrorPanel error={loginSubmitError} group={'login'} />}
                <FieldSet className={classes.fieldset}>
                    <EmailField
                        disabled={loading}
                        className={classes.input}
                        required
                        autoFocus
                        fullWidth={inputFullWidth}
                        {...x}
                    />
                    <PasswordField
                        disabled={loading}
                        className={classes.input}
                        required
                        fullWidth={inputFullWidth}
                        {...x}
                    />
                    <Button
                        disabled={loading}
                        className={classes.buttonDense}
                        color={'tertiary'}
                        onClick={onForgotPasswordClick}
                        noPadding
                    >
                        {t('forms_login_buttons_forgotpassword_label')}
                    </Button>
                </FieldSet>
                <Button
                    disabled={loading}
                    type={'submit'}
                    className={classes.button}
                    color={'primary'}
                    fullWidth={buttonFullWidth}
                >
                    {t('forms_login_buttons_submit_label')}
                </Button>
                {loggedInGuest && (
                    <Button
                        color={'secondary'}
                        disabled={loading}
                        className={classes.button}
                        onClick={onCancelClick}
                        fullWidth={buttonFullWidth}
                    >
                        {t('forms_login_buttons_cancel_label')}
                    </Button>
                )}
                {registerLink && (
                    <BodyText className={classes.registerLink}>
                        {t('forms_login_buttons_register_text')}{' '}
                        <Button
                            color={'tertiary'}
                            className={classes.registerLinkLink}
                            onClick={onRegisterClick}
                            noPadding
                        >
                            {t('forms_login_buttons_register_label')}
                        </Button>
                    </BodyText>
                )}
            </form>
        </div>
    );
}

export interface LoginFormProps {
    className?: any;
    onAuthTokensChange: Function;
    onForgotPasswordClick: Function;
    onMustUpdatePassword: Function;
    onCancelClick?: Function;
    onRegisterClick?: Function;
    inputFullWidth?: boolean;
    buttonFullWidth?: boolean;
    loggedInGuest?: boolean;
    registerLink?: boolean;
}

export default LoginForm;
