import React, {useState} from 'react';
import {cssText} from '@ohoareau/css-utils';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import {useLuniiTranslation, NewsletterForm} from '..';

const useStyles = makeStyles(theme => ({
    root: {},
    title: {
        ...cssText(theme, 'standard', 'body_thick', undefined, undefined, 'title_1'),
        marginBottom: theme.spacing(.5),
    },
    success: {
        marginTop: theme.spacing(.5),
        color: '#1EB587 !important',
    },
}));

export function FooterNewsletter({className = ''}: FooterNewsletterProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const [success, setSuccess] = useState(null);
    return (
        <div className={clsx(classes.root, className)}>
            <div className={classes.title}>{t('footer_newsletter_title')}</div>
            <div>{t('footer_newsletter_subtitle')}</div>
            <NewsletterForm onSuccess={setSuccess}/>
            {success && <div className={classes.success}>{t('newsletter_subscribe_success')}</div>}
        </div>
    );
}

export interface FooterNewsletterProps {
    className?: string,
}

export default FooterNewsletter;
