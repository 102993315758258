import React, {useCallback, useEffect, useRef, useState} from 'react';
import {cssShadow, cssText} from '@ohoareau/css-utils';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ExtractCardAudioTimeline from './ExtractCardAudioTimeline';
import ExtractCardAudioControls from './ExtractCardAudioControls';
import useLuniiTranslation from '../hooks/useLuniiTranslation';
import {useLuniiProduct, useLuniiEventTracking} from '../hooks';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3, 4),
        ...cssShadow(theme, 'level_02'),
        borderRadius: 16,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    title: {
        ...cssText(theme, 'standard', 'title_1', undefined, undefined, '#2C3637'),
        marginBottom: theme.spacing(2),
    },
}));

export function ExtractCard({className = '', urls = []}: ExtractCardProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const handleEventTracking = useLuniiEventTracking();
    const product = useLuniiProduct();
    const audioPlayer = useRef<any>(null); // audio player ref
    const [audioIndex, setAudioIndex] = useState(0); // audio index
    const [duration, setDuration] = useState(0); // duration of audio
    const [currentTime, setCurrentTime] = useState(0); // current time of audio
    const [play, setPlay] = useState(false); // play status
    const handleLoadedData = useCallback(() => {
        // once data is loaded, set duration
        setDuration(audioPlayer.current.duration);
    }, [setDuration, audioPlayer]);
    const handleTimeUpdate = useCallback(() => {
        // on time update, set current time
        audioPlayer.current && setCurrentTime(audioPlayer.current.currentTime);
    }, [setCurrentTime, audioPlayer]);
    const handleArrowClick = useCallback(
        (position) => {
            // handle arrow click (forward and backward)
            if (urls.length === 1) {
                audioPlayer.current.currentTime = 0;
            } else if (audioPlayer.current.currentTime > 0 && position === -1) {
                // when currentTime !== 0 and step backward, do no to change extract
                audioPlayer.current.currentTime = 0;
            } else {
                // if not, step to position
                setAudioIndex((prevIndex) => {
                    let newIndex = prevIndex + position;
                    if (newIndex < 0) newIndex = urls.length - 1; // go to last if backward on first
                    if (newIndex > urls.length - 1) newIndex = 0; // go to firs if forward on last
                    return newIndex;
                });
            }
            setPlay(false); // set play to false
        },
        [audioPlayer, setAudioIndex, setPlay],
    );
    const handleEnd = useCallback(() => {
        // once extract has ended, simulate a click on next extract
        handleArrowClick(1);
    }, [handleArrowClick]);
    const handlePlay = useCallback(() => {
        // toggle play
        !play &&
            handleEventTracking({
                categorie: 'interaction',
                action: 'ecoute',
                libelle: `extrait - ${product.name}`,
            });
        setPlay((play) => !play);
    }, [play, setPlay, handleEventTracking]);
    useEffect(() => {
        // set audioPlayer status
        play ? audioPlayer.current.play() : audioPlayer.current.pause();
    }, [play, audioPlayer]);
    const handleTimelineClick = useCallback(
        (position) => {
            // on timeline click, set currentTime
            audioPlayer.current.currentTime = (audioPlayer.current.duration * position) / 100;
        },
        [audioPlayer],
    );
    return (
        <div className={clsx(classes.root, className)}>
            <div className={classes.title}>
                {t('extract_card_info')}
                {audioIndex + 1}
            </div>
            <ExtractCardAudioTimeline
                duration={duration}
                currentTime={currentTime}
                onTimelineClick={handleTimelineClick}
            />
            <ExtractCardAudioControls
                onPlayClick={handlePlay}
                onArrowClick={handleArrowClick}
                playing={play}
            />
            <audio
                hidden
                src={urls[audioIndex]}
                ref={audioPlayer}
                onLoadedData={handleLoadedData}
                onTimeUpdate={handleTimeUpdate}
                onEnded={handleEnd}
            />
        </div>
    );
}

export interface ExtractCardProps {
    className?: string;
    urls?: string[];
}

export default ExtractCard;
