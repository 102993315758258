import * as React from 'react';
function SvgInstagram(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width={props.width || 24}
            height={props.height || 24}
            {...props}
        >
            <path
                d="M0 24C0 10.745 10.745 0 24 0s24 10.745 24 24-10.745 24-24 24S0 37.255 0 24z"
                fill="#D63F90"
            />
            <mask
                id="instagram_svg__a"
                style={{
                    maskType: 'alpha',
                }}
                maskUnits="userSpaceOnUse"
                x={0}
                y={0}
                width={48}
                height={48}
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 24.003c0-13.255 10.745-24 24-24s24 10.745 24 24-10.745 24-24 24-24-10.745-24-24z"
                    fill="#fff"
                />
            </mask>
            <g mask="url(#instagram_svg__a)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M24.001 11.2c-3.476 0-3.912.015-5.278.077-1.362.063-2.293.278-3.106.595a6.267 6.267 0 00-2.268 1.476 6.283 6.283 0 00-1.477 2.267c-.318.814-.534 1.745-.595 3.107-.061 1.365-.077 1.802-.077 5.278 0 3.476.016 3.911.077 5.277.063 1.362.279 2.293.595 3.106a6.274 6.274 0 001.476 2.268 6.267 6.267 0 002.267 1.477c.814.317 1.745.532 3.107.595 1.366.062 1.802.077 5.278.077 3.476 0 3.911-.015 5.276-.077 1.363-.063 2.294-.278 3.109-.595a6.263 6.263 0 002.266-1.477 6.284 6.284 0 001.477-2.267c.315-.814.53-1.745.595-3.107.061-1.365.077-1.8.077-5.277 0-3.476-.016-3.913-.077-5.278-.064-1.363-.28-2.293-.595-3.107a6.281 6.281 0 00-1.477-2.267 6.256 6.256 0 00-2.267-1.476c-.816-.317-1.747-.532-3.11-.595-1.365-.062-1.8-.077-5.277-.077h.004zm-.427 2.313h.427c3.418 0 3.823.012 5.172.074 1.249.057 1.926.265 2.377.44a3.96 3.96 0 011.471.958c.448.448.725.875.958 1.472.175.45.384 1.128.44 2.376.062 1.35.075 1.755.075 5.17 0 3.417-.013 3.822-.074 5.171-.057 1.248-.266 1.926-.441 2.377a3.958 3.958 0 01-.958 1.47 3.96 3.96 0 01-1.47.957c-.452.176-1.13.384-2.377.441-1.35.062-1.755.075-5.173.075s-3.823-.013-5.172-.075c-1.248-.057-1.925-.266-2.377-.441a3.964 3.964 0 01-1.472-.957 3.967 3.967 0 01-.958-1.472c-.175-.45-.384-1.128-.44-2.376-.062-1.349-.074-1.754-.074-5.172 0-3.419.012-3.822.073-5.171.058-1.248.266-1.926.441-2.377.232-.597.51-1.024.958-1.472a3.973 3.973 0 011.472-.958c.451-.176 1.129-.384 2.377-.441 1.18-.053 1.638-.07 4.024-.072v.003h.721zm5.724 3.653a1.536 1.536 0 113.071 0 1.536 1.536 0 01-3.071 0zM24 17.43a6.574 6.574 0 100 13.147 6.574 6.574 0 000-13.147zm4.267 6.577a4.267 4.267 0 10-8.534 0 4.267 4.267 0 008.534 0z"
                    fill="#fff"
                />
            </g>
        </svg>
    );
}
export default SvgInstagram;
