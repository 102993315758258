import React from 'react';
import HorizontalMenuItem from './HorizontalMenuItem';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});

export function HorizontalMenu({current, variant = 'default', items = [], onSectionChange, isTabBar = false, currentPageUid}: HorizontalMenuProps) {
    const classes = useStyles();
    const n = items.length;

    return (
        <div className={classes.root}>
            {items.map((item, index) => (
                <HorizontalMenuItem
                    key={index}
                    variant={variant}
                    {...item}
                    index={index}
                    last={index === (n - 1)}
                    first={index === 0}
                    active={(
                        !!item.targetUid && !!currentPageUid && (currentPageUid === item.targetUid) ||
                        !!item.id && !!current && (current === item.id)
                    )}
                    onSectionChange={onSectionChange}
                    isTabBarMenuItem={isTabBar}
                    currentPageUid={currentPageUid}
                />
            ))}
        </div>
    );
}

export interface HorizontalMenuProps {
    items?: any[],
    current?: string,
    onSectionChange?: Function,
    variant?: 'default' | 'level1' | 'level2',
    isTabBar?: boolean,
    currentPageUid?: string,
}

export default HorizontalMenu;
