import React from 'react';
import clsx from 'clsx';
import {cssBox} from '@ohoareau/css-utils';
import Spacers from './Spacers';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    root: {
        ...cssBox(theme, 'standard', props => (!props.color || ('default' === props.color)) ? theme['background_color_clear'] : theme[`background_color_${props.color}`] || theme['background_color_clear']),
    },
    fullscreen: {
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
    },
}));

export function Container({children, fullscreen = false, topPadding = undefined, bottomPadding = undefined, className = ''}: ContainerProps) {
    const classes = useStyles();
    return (
        <div className={clsx(classes.root, fullscreen && classes.fullscreen, className && className)}>
            {!!topPadding && <Spacers size={topPadding} />}
            {children || ''}
            {!!bottomPadding && <Spacers size={bottomPadding} />}
        </div>
    );
}

export interface ContainerProps {
    className?: string,
    children?: any,
    color?: string,
    fullscreen?: boolean,
    topPadding?: number,
    bottomPadding?: number,
}

export default Container;
