import React, {createContext, useState, useCallback} from 'react';
import createDefaultLuniiPasswordUpdateModalContextValue from '../utils/createDefaultLuniiPasswordUpdateModalContextValue';
import {PasswordUpdateModal} from '../organisms';

const LuniiPasswordUpdateModalContext = createContext(createDefaultLuniiPasswordUpdateModalContextValue());

export function LuniiPasswordUpdateModalProvider(props) {
    const [isOpen, setIsOpen] = useState(false);
    const [resetPasswordLoginData, setResetPasswordLoginData] =
        useState<{email: string, password: string}|undefined>(undefined);

    /**
     * In the event of a user login in with a temporary password, the latter must be changed immediately. For a better
     * UX, we keep in memory the email and current password and email to only ask him for its new password.
     */
    const open = useCallback((resetPasswordLoginData) => {
        setIsOpen(true);
        resetPasswordLoginData && setResetPasswordLoginData(resetPasswordLoginData);
    }, [setIsOpen, setResetPasswordLoginData]);

    const close = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    return (
        <LuniiPasswordUpdateModalContext.Provider value={{isOpen, open, close}} {...props} >
            {props.children}
            {isOpen &&
                <PasswordUpdateModal isOpen={isOpen} close={close}
                    loginData={resetPasswordLoginData}/>}
        </LuniiPasswordUpdateModalContext.Provider>
    );
}

export const LuniiPasswordUpdateModalConsumer = LuniiPasswordUpdateModalContext.Consumer;
LuniiPasswordUpdateModalContext.displayName = 'LuniiPasswordUpdateModalContext';
export default LuniiPasswordUpdateModalContext;
