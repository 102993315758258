import {
    LazyQueryHookOptions,
    LazyQueryResult,
    MutationHookOptions,
    MutationFunction,
    MutationResult,
} from '@apollo/client';
import {
    useLuniiApi,
    RETRIEVE_SUBSCRIPTION_COUPON_PRODUCTS,
    VERIFY_SUBSCRIPTION_COUPON,
    ACTIVATE_SUBSCRIPTION_COUPON,
    BUY_SUBSCRIPTION_COUPON,
    lazyQueryInfos,
} from '../..';

interface useSubscriptionGiftOptions {
    retrieveSubscriptionCouponProductsOptions?: LazyQueryHookOptions;
    verifySubscriptionCouponOptions?: LazyQueryHookOptions;
    activateCouponOptions?: MutationHookOptions;
    buyCouponOptions?: MutationHookOptions;
}

export function useSubscriptionGift({
    retrieveSubscriptionCouponProductsOptions,
    verifySubscriptionCouponOptions,
    activateCouponOptions,
    buyCouponOptions,
}: useSubscriptionGiftOptions = {}): [
    {
        retrieveCouponProductsExecute: (
            options?: LazyQueryHookOptions<any>,
        ) => Promise<LazyQueryResult<any, any>>;
        verifyCouponExecute: (
            options?: LazyQueryHookOptions<any>,
        ) => Promise<LazyQueryResult<any, any>>;
        activateCouponExecute: MutationFunction;
        buyCouponExecute: MutationFunction;
    },
    {
        retrieveCouponProductsResult: lazyQueryInfos;
        verifyCouponResult: lazyQueryInfos;
        activateCouponResult: MutationResult;
        buyCouponResult: MutationResult;
    },
] {
    const {gql, useLazyQuery, useMutation} = useLuniiApi();
    const [retrieveCouponProductsExecute, retrieveCouponProductsResult] = useLazyQuery(
        RETRIEVE_SUBSCRIPTION_COUPON_PRODUCTS(gql),
        {
            notifyOnNetworkStatusChange: true,
            ...retrieveSubscriptionCouponProductsOptions,
        },
    );

    const [verifyCouponExecute, verifyCouponResult] = useLazyQuery(
        VERIFY_SUBSCRIPTION_COUPON(gql),
        {
            notifyOnNetworkStatusChange: true,
            ...verifySubscriptionCouponOptions,
        },
    );

    const [activateCouponExecute, activateCouponResult] = useMutation(
        ACTIVATE_SUBSCRIPTION_COUPON(gql),
        {
            notifyOnNetworkStatusChange: true,
            ...activateCouponOptions,
        },
    );

    const [buyCouponExecute, buyCouponResult] = useMutation(BUY_SUBSCRIPTION_COUPON(gql), {
        notifyOnNetworkStatusChange: true,
        ...buyCouponOptions,
    });

    return [
        {
            retrieveCouponProductsExecute,
            verifyCouponExecute,
            activateCouponExecute,
            buyCouponExecute,
        },
        {
            retrieveCouponProductsResult,
            verifyCouponResult,
            activateCouponResult,
            buyCouponResult,
        },
    ];
}
