import React, {useMemo, useCallback} from 'react';
import {Router, Redirect, useNavigate} from '@reach/router';
import routes from '../configs/routes/checkout';
import * as screens from './screens/checkout';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import {LuniiPaymentProvider, LuniiSpaProvider} from '../ui';
import Page from './Page';
import {langs, stripePk, stripePkUS} from '../../configs/site';
import usePageContext from '../hooks/usePageContext';
import {page} from '../types';

export function Checkout(props: CheckoutProps) {
    const prefix = '/checkout';
    const baseNavigate = useNavigate();
    const navigate: Function = useCallback(
        async (path, ...rest) => baseNavigate(`${prefix}${path}`, ...rest),
        [baseNavigate],
    );
    const spaProviderValue = useMemo(
        () => ({prefix, navigate, screens}),
        [prefix, navigate, screens],
    );
    const pageContext = useMemo(
        () => ({
            page: {
                uid: 'checkout',
                theme: 'default',
                lang: '@cart',
                product: undefined,
                head: {
                    link: [
                        {
                            rel: 'preload',
                            as: 'script',
                            href: 'https://cdnjs.cloudflare.com/ajax/libs/lazysizes/5.3.2/lazysizes.min.js',
                        },
                    ],
                    script: [
                        {
                            src: 'https://cdnjs.cloudflare.com/ajax/libs/lazysizes/5.3.2/lazysizes.min.js',
                            type: 'text/javascript',
                            async: true,
                        },
                    ],
                },
                favicon: undefined,
                modules: [],
                alernateLangs: [],
                language: undefined,
                languages: langs.map((lang) => ({
                    id: lang,
                    label: `locale_${lang.replace('-', '_').toLowerCase()}_label`,
                    target: `/${lang}/`,
                })),
                pageModel: undefined,
                features: {
                    pageTracking: false,
                },
                menu: props?.pageContext?.page?.menu || [],
                menuImage: props?.pageContext?.page?.menuImage || {},
                pagesByModel: props?.pageContext?.page?.pagesByModel || {},
                env: props?.pageContext?.page?.env,
            },
        }),
        [langs, props.pageContext],
    );
    const {locale} = usePageContext(pageContext as unknown as page);
    const stripe = useMemo(() => {
        if (locale) {
            const stripeUS = ['US', 'CA', 'MX'];
            const country = (locale.split('-')[1] || '').toUpperCase();
            const pk = stripeUS.indexOf(country) !== -1 ? stripePkUS : stripePk;
            return pk
                ? {
                      pk,
                  }
                : undefined;
        }
    }, [locale]);
    return (
        <LuniiSpaProvider value={spaProviderValue}>
            <LuniiPaymentProvider
                stripe={stripe}
                locale={locale}
            >
                <Page pageContext={pageContext}>
                    <Router basepath={prefix}>
                        {routes.map((route: any, i: number) => {
                            const key = `${i}-${route.path}`;
                            if (route.redirect)
                                return (
                                    <Redirect
                                        noThrow
                                        key={key}
                                        from={route.path}
                                        to={`${prefix}/${route.redirect}`}
                                    />
                                );
                            if (route.secured)
                                return (
                                    <PrivateRoute
                                        key={key}
                                        {...route}
                                        prefix={prefix}
                                    />
                                );
                            return (
                                <PublicRoute
                                    key={key}
                                    {...route}
                                    prefix={prefix}
                                />
                            );
                        })}
                    </Router>
                </Page>
            </LuniiPaymentProvider>
        </LuniiSpaProvider>
    );
}

export interface CheckoutProps {
    pageContext?: any;
}

export default Checkout;
