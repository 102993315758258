import {user, user_tokens, user_context_value} from '../types';

export const createDefaultLuniiUserContextValue = (defaultValue: user|undefined = undefined): user_context_value =>
    ({
        user: defaultValue,
        setUser: (user: user) => {},
        logout: () => {},
        refreshUser:  () => undefined,
        refreshUserPartial:  () => undefined,
        setTokens: (tokens: user_tokens) => {},
        getTokens: () => undefined,
    })
;

export default createDefaultLuniiUserContextValue;
