import React from 'react';
import {device_target, useLuniiTranslation} from '../../../ui';
import {Tag, TagProps} from '.';
import {getTargetDevice} from '../../../utils';

export function DeviceTag({className = '', size = 'medium', deviceTarget = undefined}: DeviceTagProps) {
    const target = getTargetDevice(deviceTarget);
    const {t} = useLuniiTranslation();
    if (!target) return null;
    const tagProps = {
        ...target === 'fah' && {
            theme: 'info',
            icon: 'storyteller',
            label: t('device_target_tag_fah'),
        },
        ...target === 'flam' && {
            theme: 'flam',
            icon: 'flam',
            label: t('device_target_tag_flam'),
        },
        ...target === 'fah_flam' && {
            theme: 'success',
            icon: 'devices',
            label: t('device_target_tag_fah_flam'),
        },
    }
    return (
        <Tag className={className} size={size} {...tagProps as TagProps}/>
    );
}

export interface DeviceTagProps {
    className?: any;
    size?: 'medium' | 'small' | 'xsmall';
    deviceTarget?: device_target;
}

export default DeviceTag;
