import {price_details} from '../ui';

export interface SubscriptionGiftMetadata {
    senderName: string | undefined;
    receiverEmail: string | undefined;
    receiverName: string | undefined;
    message?: string;
    sendingDate?: number;
}

export interface SubscriptionGiftProductMetadata {
    months: string;
    type: string;
}

export interface SubscriptionGiftProduct {
    id: string;
    object: string;
    active: boolean;
    livemode: boolean;
    metadata: SubscriptionGiftProductMetadata;
    name: string;
    couponType: string;
    url?: string;
    taxCode?: string;
    price: number;
    priceDetails: price_details;
    currency: string;
    description: string;
}

export enum SubscriptionSubmitOptionsEnum {
    BY_HAND = 'by_hand',
    BY_EMAIL = 'by_email',
}
