import React from 'react';
import {NotFound} from '../commons';
import {useLuniiSpa} from '../../../ui';

export function NotFoundScreen() {
    const {prefix} = useLuniiSpa();
    return <NotFound prefix={prefix} />;
}

export default NotFoundScreen;
