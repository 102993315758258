import React, {useCallback, useEffect, useState} from 'react';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {cssFgColor, cssShadow, cssText} from '@ohoareau/css-utils';
import {Modal, IconButton} from '@material-ui/core';
import {
    useSubscriptionSpendCreditSubmit,
    useLuniiUser,
    useLuniiTranslation,
    useLuniiEventTracking,
    useHasMounted,
} from '../hooks';
import constants from '../utils/constants';
import {Img, DynamicIcon} from '../nucleons';
import {ErrorPanel, Spinner, Button, Checkbox, DividingLine} from '../atoms';
import {digital_album_product} from '../types';

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    root: {
        ...cssText(theme, 'standard', 'body', 'left'),
        position: 'absolute',
        width: 622,
        backgroundColor: 'white',
        border: 'none',
        borderRadius: theme.spacing(2),
        outline: 'none',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '95vh',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            left: 0,
            bottom: 0,
            top: 'unset',
            transform: 'unset',
            maxHeight: '85vh',
        },
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        '-ms-overflow-style': 'none',
        scrollbarWidth: 'none',
        overflow: 'auto',
    },
    rootSuccessMessage: {
        width: 744,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        overflow: 'auto',
    },
    main: {
        padding: theme.spacing(4),
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
        },
    },
    header: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(1),
        },
    },
    modalTitle: {
        ...cssText(theme, 'standard', 'title_2'),
    },
    modalText: {
        ...cssText(theme, 'standard', 'secondary_body', 'center', undefined, '#716C5E'),
    },
    body: {
        width: '100%',
        position: 'relative',
    },
    centeredBody: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
    footer: {
        width: '100%',
    },
    packInfos: {
        display: 'flex',
        alignItems: 'stretch',
        ...cssText(theme, 'standard', 'body_thick', undefined),
        marginBottom: theme.spacing(2),
    },
    subscriptionStatus: {
        margin: theme.spacing(2, 0),
        ...cssText(theme, 'standard', 'secondary_body'),
    },
    icon: {
        margin: theme.spacing(0, .5),
    },
    actions: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(3),
        width: '100%',
        '& >*:not(:last-child)': {
            marginRight: theme.spacing(2),
        },
    },
    illustration: {
        ...cssShadow(theme, 'level_02'),
        width: 76,
        height: 76,
        [theme.breakpoints.up('sm')]: {
            width: 96,
            height: 96,
        },
    },
    description: {
        flex: '1',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        margin: theme.spacing(0, 0, 0, 1),
        [theme.breakpoints.up('md')]: {
            margin: theme.spacing(0, 0, 0, 2),
        },
    },
    thick: {
        ...cssText(theme, 'standard', 'secondary_body_thick'),
    },
    light: {
        ...cssFgColor(theme, 'standard', 'caption'),
    },
    checkbox: {
        margin: theme.spacing(0, 0, 1, 0),
    },
    checkboxText: {
        ...cssText(theme, 'standard', 'button_2', undefined, undefined, '#2762e9'),
        fontWeight: '800',
    },
    currentBalance: {
        display: 'block',
        ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#716C5E'),
        margin: theme.spacing(0, 0, 1, 0),
    },
    disclamer: {
        '& p' : {
            ...cssText(theme, 'standard', 'caption'),
            [theme.breakpoints.down('sm')]: {
                fontSize: 12,
                lineHeight: 1.15,
            },
        },
    },
    centeredLine: {
        display: 'flex',
        alignItems: 'center',
        '& >*': {
            display: 'block',
        },
    },
    fullWidth: {
        width: '100%',
    },
    successIcon: {
        backgroundColor: '#1EB587',
        color: '#FFF',
        borderRadius: '50%',
        padding: 10,
        margin: theme.spacing(2, 0, 0, 0),
    },
}));

// deprecated
export function SubscriptionSpendModal({product, isOpen = false, close, className, libelle, pageModel, ...props}: SubscriptionSpendModalProps) {
    const classes = useStyles();
    const {user, setUser, refreshUserPartial} = useLuniiUser();
    const hasMounted = useHasMounted();
    const [confirmCreditUsage, setConfirmCreditUsage] = useState<boolean>(false);
    const {t} = useLuniiTranslation();
    const [inProgress, setInProgress] = useState<boolean>(false);
    const [success, setSuccess] = useState(false);
    const onError = useCallback(() => {
        setSuccess(false);
        setInProgress(false);
    }, []);
    const [spendCredit, {loading, error, called, data}] = useSubscriptionSpendCreditSubmit(onError);
    const handleTrackingEvent = useLuniiEventTracking();

    useEffect(() => {
        // if the subscription credit is spent, manually update the user ownedPacksReferences manually as the library update
        // on the user server is slower than the spending process
        if (called && data?.spendSubscriptionCredit?.success && product?.reference) {
            setUser({...user, ownedPacksReferences: Array.from(new Set([
                ...user?.ownedPacksReferences,
                product.reference,
            ]))});
            handleTrackingEvent({
                categorie: 'interaction',
                action: 'abonnement',
                libelle: `utilisation - ${product?.reference} - ${product?.name} ${product['bundle'] ? ' - ' + product['bundle'] : ''}`,
            });
        }
    }, [user?.subscription?.wallet?.balance]);

    const subscriptionBalance = user?.subscription?.wallet?.balance || 0;
    const subscriptionBalanceAfterPurchase = (subscriptionBalance - constants.SUBSCRIPTION_PACK_CREDIT_VALUE);

    const showOverlay = loading || inProgress;

    const handleClose = () => {
        close();
    };

    const updateConfirmCreditUsage = e => {
        setConfirmCreditUsage(e.target.checked);
    };

    const handleCreditSpend = async () => {
        setInProgress(true);

        const result = await spendCredit({
            productReference: product.reference,
            locale: user?.locale,
            currency: product.currency || 'EUR',
        });

        if (result?.success) {
            await refreshUserPartial({library: true, subscription: true});
            setSuccess(true);
        }

        setInProgress(false);
    };

    if (!hasMounted) return null;

    return (
        <Modal
            className={classes.modal}
            open={isOpen}
            onClose={handleClose}
            closeAfterTransition={true}
            aria-labelledby={t('subscriptionspendmodal_aria_label')}
            aria-describedby={t('subscriptionspendmodal_aria_description')}>
            <div className={clsx(classes.root, className)} {...props}>
                <div className={classes.main}>
                    <div className={classes.header}>
                        <div className={classes.modalTitle}>
                            {!success && t('subscriptionspendmodal_title', {count: constants.SUBSCRIPTION_PACK_CREDIT_VALUE, credit: constants.SUBSCRIPTION_PACK_CREDIT_VALUE})}
                        </div>
                        <IconButton
                            aria-label={'close'}
                            onClick={handleClose}
                            edge={'end'}
                            size={'small'}>
                            <DynamicIcon
                                type={'close'}
                                height={24}
                                width={24}/>
                        </IconButton>
                    </div>
                    <div className={clsx(classes.body, success && classes.centeredBody)}>
                        {showOverlay && <Spinner/>}
                        {(!success || error)
                            ? (
                                <div className={clsx(classes.body, success && classes.centeredBody)}>
                                    <div className={classes.packInfos}>
                                        <Img
                                            className={classes.illustration}
                                            url={product?.image?.url}/>
                                        <div className={classes.description}>
                                            <span>
                                                {product?.name}
                                            </span>
                                            <span className={classes.light}>
                                                {t(`locale_${product?.locale?.toLowerCase()}_product_language`)}
                                            </span>
                                        </div>
                                    </div>
                                    <DividingLine color={'#EFEDEA'}/>
                                    <div className={classes.subscriptionStatus}>
                                        <div className={classes.currentBalance}>
                                            {t('subscriptionspendmodal_balance_current', {count: subscriptionBalance, credit: subscriptionBalance} )}
                                        </div>
                                        <div className={clsx(classes.thick, classes.centeredLine)}>
                                            {t('subscriptionspendmodal_balance_after_purchase', {count: subscriptionBalanceAfterPurchase, credit: subscriptionBalanceAfterPurchase})}
                                            <DynamicIcon
                                                className={classes.icon}
                                                type={'token'}
                                                height={20}
                                                width={20}/>
                                        </div>
                                    </div>
                                    <div className={classes.footer}>
                                        <Checkbox
                                            id={'subscription_confirm_usage'}
                                            name={'subscription_confirm_usage'}
                                            status={confirmCreditUsage}
                                            content={t('subscriptionspendmodal_confirm_usage')}
                                            linkTarget={updateConfirmCreditUsage}
                                            onChange={updateConfirmCreditUsage}
                                            className={classes.checkbox}
                                            textClassName={classes.checkboxText}
                                            alignItems={'flex-start'}
                                        />
                                        <div className={classes.disclamer} >
                                            <p>{t('subscriptionspendmodal_disclamer')}</p>
                                        </div>
                                        {error && <ErrorPanel
                                            error={error}
                                            key={'subscription'}/>}
                                        <div className={classes.actions}>
                                            <Button
                                                color={'plain'}
                                                onClick={close}>
                                                {t('subscriptionspendmodal_cancel')}
                                            </Button>
                                            <Button
                                                color={'primary'}
                                                onClick={handleCreditSpend}
                                                disabled={!confirmCreditUsage || loading}>
                                                {t('subscriptionspendmodal_confirm')}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            )
                            : (
                                <div className={clsx(classes.body, success && classes.centeredBody)}>
                                    <div className={classes.modalTitle}>
                                        {t('subscriptionspendmodal_success_title')}
                                    </div>
                                    <div className={classes.modalText}>
                                        {t('subscriptionspendmodal_success_text')}
                                    </div>
                                    <DynamicIcon
                                        type={'check'}
                                        height={42}
                                        width={42}
                                        className={classes.successIcon}/>
                                    <div className={classes.footer}>
                                        <div className={classes.actions}>
                                            <Button
                                                className={classes.fullWidth}
                                                color={'primary'}
                                                onClick={handleClose}>
                                                {t('subscriptionspendmodal_success_close')}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export interface SubscriptionSpendModalProps {
    product: digital_album_product,
    className?: string,
    isOpen?: boolean,
    close: Function,
    libelle?: string,
    pageModel?: string,
}

export default SubscriptionSpendModal;
