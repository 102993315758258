import React from 'react';
import {CssBaseline} from '@material-ui/core';
import '../ui/assets/css/lunii.css';
import Module from './Module';
import {page, module} from '../types';
import usePageTracking from '../hooks/usePageTracking';

const pageTrackingIgnoredPageModels = ['nos_histoires_luniistore', 'catalogue_flam'];

export function PageBody({page}: PageBodyProps) {
    const isTracking = pageTrackingIgnoredPageModels.includes(page.pageModel || '')
        ? false
        : page?.features?.pageTracking;
    const model = page.pageModel || page.uid;
    const extraProps: any = {};

    if (page.pageModel && pageTrackingIgnoredPageModels.indexOf(page.pageModel) !== -1 && page.paperProductsCatalog) {
        extraProps.productList = page.paperProductsCatalog;
    } else if (page.pageModel === 'a_la_une') {
        extraProps.productList = (page?.modules || [])
            .filter((m: any) => !!m?.data?.items)
            .map((module) =>
                (module?.data?.items || [])
                    .filter((i: any) => !!i.product)
                    .map((item: any) => item.product),
            )
            .reduce((p, c) => [...p, ...c], []);
    }

    usePageTracking(isTracking, model, extraProps);

    const {modules = []} = page;

    return (
        <>
            <CssBaseline />
            {modules.map((module: module, i: number) => (
                <Module
                    key={i}
                    module={module}
                    page={page}
                />
            ))}
        </>
    );
}

export interface PageBodyProps {
    page: page;
}

export default PageBody;
