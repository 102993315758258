import React, {useEffect, useState} from 'react';
import {cssText} from '@ohoareau/css-utils';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {useLuniiTranslation} from '../hooks';
import {RadioGroup} from '@material-ui/core';
import {Trans} from 'react-i18next';
import {cart, Checkbox, convertPrice, CreditCardForm, CreditCardMy, ForceTheme, payment_type, PaymentRadioLabel,
    PaymentTypeEnum, PaypalPaymentImages, RadioFormControl, StripePaymentImages} from '..';

const useStyles = makeStyles(theme => ({
    root: {
        background: 'transparent',
        position: 'relative',
    },
    title: {
        ...cssText(theme, 'standard', 'title_1'),
        color: '#423F37',
        width: '100%',
        marginBottom: theme.spacing(2),
        marginLeft: 10,
    },
    creditCard: {
        [theme.breakpoints.down('md')]: {
            paddingLeft: 40,
        },
        marginLeft: 9,
    },
    svgStripe: {
        color: props => props['expanded'] ? '#44320a' : 'inherit',
    },
    balance: {
        ...cssText(theme, 'standard', 'body_thick', undefined, undefined, 'inherit'),
    },
    partialBalance: {
        marginLeft: 9,
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            marginLeft: 10,
        },
    },
    balancePriceLeft: {
        margin: theme.spacing(.5, 0),
        marginLeft: 35,
        ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#908977'),
    },
    checkBoxText: {
        ...cssText(theme, 'standard', 'button_1'),
        userSelect: 'none',
        marginLeft: 2,
    },
    inputCheckbox: {
        backgroundColor: 'white',
        color: '#757575',
    },
    creditCardForm: {
        padding: theme.spacing(0, 1, 0, 0),
    },
    noGiftCardPaymentMethod: {
        backgroundColor: '#FAF9F8',
        borderRadius: theme.spacing(1),
        padding: theme.spacing(1, 2),
        margin: theme.spacing(2, 0),
        ...cssText(theme, 'standard', 'body_thick', undefined, undefined, '#908977'),
    },
    paypalExpanded: {
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(0, 2),
            marginLeft: 25,
        },
    },
}));

export function PaymentTypeChoice({cart: {price, currency, paymentTypes = [], accountBalance, leftToPay}, onPaymentTypeChoice, onCardComplete, onCardRegister, onGiftCardPartialChange, defaultOnFirstIfOnly = false}: PaymentTypeChoiceProps) {
    const classes = useStyles();
    const {t, i18n} = useLuniiTranslation();
    const [typeIndex, setTypeIndex] = useState<number>(defaultOnFirstIfOnly && paymentTypes.length === 1 ? 0 : -1);
    const [balanceChecked, setBalanceChecked] = useState<boolean>(false);
    const handleChange = (e: any) => {
        setParameters(parseInt(e?.currentTarget?.value));
    };
    const setParameters = (index) => {
        setTypeIndex(index);
        onPaymentTypeChoice(paymentTypes[index]);
    };
    useEffect(() => {
        const defaultCardIndex = paymentTypes.findIndex(type => type.card?.isDefault);
        if (defaultCardIndex !== -1) {
            setTypeIndex(defaultCardIndex);
            onPaymentTypeChoice(paymentTypes[defaultCardIndex]);
        } else if (defaultOnFirstIfOnly && paymentTypes.length === 1) {
            onPaymentTypeChoice(paymentTypes[0]);
        }
    }, []);
    useEffect(() => {
        if (!(accountBalance?.amount) && typeIndex === 0) setTypeIndex(-1); // reinit
    }, [accountBalance?.amount]);
    const giftCardPayment = paymentTypes.find(({method}: payment_type) => method === PaymentTypeEnum.GIFT_CARD);
    const handleGiftCardPartialClick = (e: any) => {
        setBalanceChecked(e?.target?.checked);
        onGiftCardPartialChange && onGiftCardPartialChange(e?.target?.checked);
    };
    return (
        <div className={classes.root}>
            <div className={classes.title}>{t('checkout_paymentMethod_form_title')}</div>
            {!!giftCardPayment?.balance && giftCardPayment.balance > 0 && giftCardPayment.balance < price &&
            <div className={classes.partialBalance}>
                <Checkbox
                    alignItems={'flex-start'}
                    reinitState={!(accountBalance?.amount)}
                    textClassName={classes.checkBoxText}
                    inputClassName={classes.inputCheckbox}
                    id={'checkout_paymentMethod_gift_card_exact_cart'}
                    name={'giftCardPartialUse'}
                    onChange={handleGiftCardPartialClick}
                    content={<Trans
                        i18n={i18n} i18nKey={`checkout_paymentMethod_gift_card_exact_cart`}
                        values={{price: convertPrice(price, currency), balance: convertPrice(giftCardPayment.balance, currency)}}
                        components={{1: <span className={classes.balance}/>}}/>}/>
                {balanceChecked && <div className={classes.balancePriceLeft}>{t('checkout_paymentMethod_gift_card_left_to_pay', {amount: convertPrice(leftToPay?.totalWithVat, currency)})}</div>}
            </div>
            }
            <RadioGroup aria-label='paymentType' name='paymentType' onChange={handleChange} value={`${typeIndex}`}>
                {paymentTypes.map((item: payment_type, index: number) => {
                    if (item.method === PaymentTypeEnum.STRIPE_UNREGISTERED) {
                        return (
                            <RadioFormControl
                                value={`${index}`}
                                key={`${index}${item.method}`}
                                active={index === typeIndex}
                                LabelComponent={
                                    <PaymentRadioLabel
                                        icon={<StripePaymentImages className={classes.svgStripe} color={'currentColor'}/>}
                                        content={<CreditCardForm className={classes.creditCardForm} save onCardComplete={onCardComplete} onCardRegister={onCardRegister}/>}
                                        title={<div>{t('checkout_paymentMethod_stripe')}</div>}
                                        expanded={index === typeIndex}/>}
                            />);
                    } else if (item.method === PaymentTypeEnum.PAYPAL) {
                        return (
                            <RadioFormControl
                                value={`${index}`}
                                key={`${index}${item.method}`}
                                active={index === typeIndex}
                                LabelComponent={
                                    <PaymentRadioLabel
                                        expandedClassName={classes.paypalExpanded}
                                        icon={<PaypalPaymentImages/>}
                                        content={<div>{t('checkout_paymentMethod_paypal_content')}</div>}
                                        title={<div>{t('checkout_paymentMethod_paypal')}</div>}
                                        expanded={index === typeIndex}/>}
                            />);
                    } else if (item.method === PaymentTypeEnum.STRIPE_REGISTERED && item.card) {
                        return (
                            <RadioFormControl
                                value={`${index}`}
                                key={`${index}${item.card?.id}`}
                                active={index === typeIndex}
                                LabelComponent={
                                    <ForceTheme
                                        theme={index === typeIndex ? 'brand-primary-50' : 'default'}>
                                        <CreditCardMy
                                            className={classes.creditCard}
                                            {...item.card}
                                            hideCardSvg
                                            number={index + 1}
                                            hideCardTips
                                            onFormActive={index === typeIndex}/>
                                    </ForceTheme>}
                            />);
                    } else if (item.method === PaymentTypeEnum.GIFT_CARD && !!item.balance && item.balance > 0 && price && price <= item.balance) {
                        return (
                            <RadioFormControl
                                value={`${index}`}
                                key={`${index}balance`}
                                active={index === typeIndex}
                                LabelComponent={
                                    <PaymentRadioLabel
                                        title={
                                            <Trans
                                                i18n={i18n} i18nKey={`checkout_paymentMethod_gift_card_${item.balance !== price ? 'full' : 'exact'}_cart`}
                                                values={{price: convertPrice(price, currency), balance: convertPrice(item.balance, currency)}}
                                                components={{1: <span className={classes.balance}/>}}/>}
                                        expanded={index === typeIndex}/>}
                            />);
                    } else return undefined;
                })}
            </RadioGroup>
            {!paymentTypes.some((item: payment_type) => item.method === PaymentTypeEnum.GIFT_CARD) && (!!accountBalance?.balance && accountBalance.balance > 0) &&
                <div className={classes.noGiftCardPaymentMethod}>{t('checkout_paymentMethod_gift_card_not_allowed', {balance: convertPrice(accountBalance.balance, currency)})}</div>
            }
        </div>
    );
};



export interface PaymentTypeChoiceProps {
    cart: cart,
    onPaymentTypeChoice: any,
    onCardComplete: (boolean) => void,
    onCardRegister?: (boolean) => void,
    onGiftCardPartialChange?: (boolean) => void,
    defaultOnFirstIfOnly?: boolean,
}

export default PaymentTypeChoice;
