import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import React, {useEffect} from 'react';
import {cssText} from '@ohoareau/css-utils';
import {useFormContext} from 'react-hook-form';
import {addDays} from 'date-fns';
import {useLuniiTranslation, FieldSet, DateField} from '../../ui';

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
    },
    title: {
        ...cssText(theme, 'standard', 'title_2'),
        marginBottom: theme.spacing(1),
    },
    infos: {
        ...cssText(theme, 'standard', 'body', undefined, undefined, '#716C5E'),
        marginBottom: theme.spacing(1),
    },
    charactersInfos: {
        ...cssText(theme, 'standard', 'caption', undefined, undefined, '#908977'),
        width: '100%',
        textAlign: 'end',
    },
}));

export function GiftCardDateChoice({
    className = '',
    now,
    locale,
    emptyValueErrors,
    errorClassName = '',
    onError,
    error,
}: GiftCardDateChoiceProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const {register, setValue, watch} = useFormContext();
    register('giftCardDate');
    const giftCardDate = watch('giftCardDate');
    const giftCardNoReceiver = watch('giftCardNoReceiver');
    useEffect(() => {
        if (giftCardNoReceiver) setValue('giftCardDate', now);
    }, [giftCardNoReceiver]);
    const handleOnChange = (date: any) => {
        setValue('giftCardDate', date);
    };
    if (giftCardNoReceiver) return null;
    return (
        <div className={clsx(classes.root, className)}>
            <div className={classes.title}>{t('gift_cards_date_title')}</div>
            <div className={classes.infos}>{t('gift_cards_date_infos')}</div>
            <FieldSet>
                <DateField
                    locale={locale}
                    value={giftCardDate}
                    onError={onError}
                    onValueChange={handleOnChange}
                    minDate={now}
                    maxDate={addDays(
                        now,
                        new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate(),
                    )}
                />
            </FieldSet>
            {error && (
                <div className={errorClassName}>
                    {t(`date_field_error_giftcards_${error.toLowerCase()}`)}
                </div>
            )}
            {emptyValueErrors?.date && (
                <div className={errorClassName}>{t('gift_cards_error_giftcarddate')}</div>
            )}
        </div>
    );
}

export interface GiftCardDateChoiceProps {
    className?: string;
    locale: string;
    now: Date;
    emptyValueErrors?: any;
    errorClassName?: string;
    error?: string;
    onError?: any;
}

export default GiftCardDateChoice;
