import jwtDecode from 'jwt-decode';
import LinkTimeout from 'apollo-link-timeout';
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    useQuery,
    useMutation,
    useLazyQuery,
    gql,
    createHttpLink,
} from '@apollo/client';
import {onError} from '@apollo/client/link/error';
import {setContext} from '@apollo/client/link/context';

export {
    gql,
    useQuery,
    useLazyQuery,
    useMutation,
    ApolloProvider,
    jwtDecode,
};

export interface CreateClientArgs {
    fetch?: any,
    fetchOptions?: any,
    getCurrentTokens: Function,
    setCurrentTokens: Function,
    refreshTokens: Function,
    onLogout: Function,
    onAuthError: Function,
    uri: string,
    timeout?: number
}

export default function createClient({
    fetch = undefined,
    fetchOptions = undefined,
    getCurrentTokens,
    setCurrentTokens,
    refreshTokens,
    onLogout,
    onAuthError,
    uri,
    timeout = 5000,
}: CreateClientArgs) {
    const authClient = new ApolloClient({
        link: onError(() => {})
            .concat(new LinkTimeout(timeout).concat(createHttpLink({uri, fetch, fetchOptions})),
            ),
        cache: new InMemoryCache(),
        defaultOptions: {
            query: {
                fetchPolicy: 'no-cache',
            },
        },
    });

    const authLink = setContext(async (_, {headers}) => {
        let {token, refreshToken} = await getCurrentTokens();
        if (!token) {
            onLogout && await onLogout();
            return;
        }
        const decodedToken: {exp: number, [key: string]: any} = jwtDecode(token);

        const now = Math.floor(Date.now() / 1000);

        if (((decodedToken || {}).exp - now) < 1) {
            try {
                if (!refreshToken) {
                    onAuthError && await onAuthError(new Error(`No refresh-token available`));
                    return;
                }
                const tokenData = await refreshTokens(refreshToken, authClient);
                if (!tokenData || !tokenData.refreshToken || !tokenData.token) {
                    throw new Error(`Unable to refresh the token (jwt expired ?)`);
                }
                await setCurrentTokens(tokenData);
                token = tokenData.token;
            }
            catch (e) {
                onLogout && await onLogout();
                throw e;
            }
        }
        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : '',
            },
        };
    });

    return new ApolloClient({
        link: onError(() => { }).concat(
            authLink.concat(new LinkTimeout(timeout).concat(createHttpLink({uri, fetch, fetchOptions}))),
        ),
        cache: new InMemoryCache({
            typePolicies: {
                WishlistItem: {
                    fields: {
                        // Specify how the client field `_removed` should behave when requested in a query
                        _removed(_removed: Boolean) {
                            return Boolean(_removed);
                        },
                    },
                },
            },
        }),
        defaultOptions: {
            watchQuery: {
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'network-only', // Used for first execution
                nextFetchPolicy: 'network-only', // Used for subsequent executions
            },
            query: {
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'network-only', // Used for first execution
            },
            mutate: {
                fetchPolicy: 'network-only', // Used for first execution
            },
        }
    });

}
