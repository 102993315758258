import React, {useCallback, useEffect, useMemo} from 'react';
import {cssBorder, cssFgColor, cssShadow, cssText} from '@ohoareau/css-utils';
import {nanoid} from 'nanoid';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import Hidden from '@material-ui/core/Hidden';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {SUBSCRIPTION_PACK_CREDIT_VALUE} from '../../ui/utils/constants';
import {
    AlbumCarroussel,
    AuthorCard,
    buttonifyFromProps,
    convertPrice,
    digital_album_product,
    ExtractCard,
    Img,
    LuniiSubscriptionSpendModalProvider,
    ProductDescription,
    Row,
    SubscriptionAdvert,
    useHasMounted,
    useLuniiEventTracking,
    useLuniiNavigation,
    useLuniiPage,
    useLuniiSubscriptionSpendModal,
    useLuniiTranslation,
    useLuniiUser,
    useShoppingCart,
    useWishlist,
    wishlist_item,
    WishlistToggle,
} from '../../ui';
import {availableForSubscription} from '../../../configs/site';
import {convertDigitalAlbumProductToWishlistItem, findIndexInObjectArray} from '../../utils';

const useStyles = makeStyles((theme) => ({
    root: {
        ...cssText(theme, 'standard', 'body'),
        display: 'flex',
    },
    topLeft: {
        [theme.breakpoints.down('md')]: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            marginBottom: theme.spacing(4),
        },
    },
    productImage: {
        marginBottom: 16,
        lineHeight: 0,
        ...cssShadow(theme, 'level_02'),
        [theme.breakpoints.down('md')]: {
            marginBottom: 0,
        },
    },
    image: {
        width: '100%',
    },
    productPrice: {
        [theme.breakpoints.down('sm')]: {
            flex: 1,
            display: 'block',
        },
        [theme.breakpoints.up('sm')]: {
            width: '100%',
            marginTop: theme.spacing(2),
        },
    },
    reduction: {
        ...cssText(theme, 'standard', 'secondary_body_thick', undefined, undefined, '#F25129'),
        marginBottom: 2,
    },
    pricing: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'baseline',
    },
    price: {
        ...cssText(theme, 'standard', 'title_2'),
        display: 'inline',
        marginRight: 10,
    },
    oldPrice: {
        ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#908977'),
        textDecoration: 'line-through',
        display: 'inline',
    },
    actions: {
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
    actionsWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    button: {
        marginTop: theme.spacing(2),
        width: '100%',
        '&:not:first-of-type': {
            marginLeft: theme.spacing(1),
        },
        '& .s-hide': {
            whiteSpace: 'nowrap',
        },
        [theme.breakpoints.down('md')]: {
            '&:not:first-of-type': {
                height: '32px',
            },
            '& .s-hide': {
                display: 'none',
            },
        },
        [theme.breakpoints.down('xs')]: {
            '& .s-hide': {
                display: 'initial',
            },
        },
    },
    mobileButton: {
        marginTop: 6,
        width: '100%',
    },
    wishlistButton: {
        margin: theme.spacing(2, 0, 0, 1),
    },
    subscriptionAdvertDesktop: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
            marginTop: theme.spacing(4),
        },
    },
    subscriptionAdvertMobile: {
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    notify: {
        ...cssBorder(theme, 'push'),
        ...cssText(theme, 'standard', 'caption', undefined, undefined, '#716C5E'),
        backgroundColor: '#EFEDEA',
        padding: theme.spacing(1, 2, 1, 2),
        margin: theme.spacing(2, 0, 2, 0),
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(2),
        },
    },
    disabled: {
        pointerEvents: 'none',
    },
    subtitle: {
        ...cssText(theme, 'standard', 'overline_large_title', undefined, undefined, '#908977'),
        marginBottom: 4,
        [theme.breakpoints.down('sm')]: {
            ...cssText(theme, 'standard', 'overline_title_1', undefined, undefined, '#908977'),
        },
    },
    name: {
        ...cssText(theme, 'content', 'large_title'),
        [theme.breakpoints.down('sm')]: {
            ...cssText(theme, 'content', 'title_1'),
        },
    },
    types: {
        width: '100%',
        backgroundColor: '#FAF9F8',
        borderRadius: 8,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: theme.spacing(1, 0, 4, 0),
        height: 'fit-content',
    },
    typesContent: {
        display: 'flex',
        flex: 1.75,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        padding: theme.spacing(2, 3),
        [theme.breakpoints.down('md')]: {
            padding: '16px 26px 18px 24px',
        },
        [theme.breakpoints.down('sm')]: {
            padding: '16px 8px 16px 18px',
        },
    },
    typeTitle: {
        ...cssText(theme, 'standard', 'overline_title_2', undefined, undefined, '#908977'),
        marginBottom: 6,
    },
    typeName: {
        ...cssText(theme, 'standard', 'title_2'),
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            ...cssText(theme, 'standard', 'body_thick'),
        },
    },
    typeImage: {
        width: 'calc(100% / 7)',
        height: '100%',
        [theme.breakpoints.down('md')]: {
            width: 'calc(100% / 6)',
            height: '100%',
        },
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% / 5)',
        },
        verticalAlign: 'baseline',
    },
    description: {
        ...cssText(theme, 'content', 'body'),
        '& a': {
            ...cssFgColor(theme, 'standard', 'link'),
        },
    },
    extractCard: {
        margin: '48px 0',
    },
    casting: {
        margin: theme.spacing(2, 0),
    },
}));

// OLD VERSION, DO NOT USE, DEPRECATED, use DigitalAlbumProductV2 instead
export function DigitalAlbumProduct({product}: DigitalAlbumProductProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const {...p} = useLuniiPage();
    const [{cart, packInCart}] = useShoppingCart();
    const {user, ownsPackReference, isLogged} = useLuniiUser();
    const hasMounted = useHasMounted();
    const {open: openSubscriptionModal} = useLuniiSubscriptionSpendModal();
    const {goPageByModel} = useLuniiNavigation();
    const handleEventTracking = useLuniiEventTracking();

    const [
        {
            retrieveWishlistExecute: retrieveWishlist,
            addToWishlistExecute: addToWishlist,
            removeFromWishlistExecute: removeFromWishlist,
        },
        {
            retrieveWishlistResult: {data: dataRetrieveWishlist},
        },
    ] = useWishlist(); // todo warning should use new useUserWishlist, usePostUserWishlist and userDeleteUserWishlist hooks

    useEffect(() => {
        if (hasMounted && isLogged) {
            retrieveWishlist();
        }
    }, [hasMounted, isLogged, retrieveWishlist]);

    const handleWishlistClick = useCallback(
        async (packInWishlist: boolean, product: wishlist_item) => {
            const packId = product.objectId;
            if (packInWishlist) {
                await removeFromWishlist({
                    variables: {
                        data: {
                            packId,
                        },
                    },
                    optimisticResponse: {
                        __typename: 'Mutation',
                        removeFromWishlist: {
                            ...product,
                            _removed: true,
                            __typename: 'WishlistItem',
                        },
                    },
                });
            } else {
                await addToWishlist({
                    variables: {
                        data: {
                            packId,
                        },
                    },
                    optimisticResponse: {
                        __typename: 'Mutation',
                        addToWishlist: {
                            ...product,
                            id: nanoid(),
                            __typename: 'WishlistItem',
                        },
                    },
                });
            }
        },
        [addToWishlist, removeFromWishlist],
    );
    const handleWishlistEventTracking = useCallback(
        (packInWishlist: boolean, product: wishlist_item | digital_album_product) => {
            handleEventTracking(
                {
                    categorie: 'ecommerce',
                    action: packInWishlist ? 'retrait_liste_souhaits' : 'ajout_liste_souhaits',
                    libelle: `nos_histoires_produit`,
                    valeur: Math.round(product.priceExclTax / 100) * 100,
                },
                product,
            );
        },
        [handleEventTracking],
    );

    const subscriptionAvailable = useMemo(() => availableForSubscription(p?.locale), [p?.locale]);
    const actualRecommendations = useMemo(
        () =>
            (product?.recommendations || []).map((reco: any) => ({
                product: {
                    ...reco.product,
                    buttonTarget: `${reco.product?.buttonTarget}?libelle=cross_selling_page_produit`,
                },
                onWishlistToggleClick: handleWishlistClick,
                onWishlistToggleClickCb: handleWishlistEventTracking,
            })),
        [product, handleWishlistClick, handleWishlistEventTracking],
    );
    const subscription = useMemo(() => user?.subscription, [user?.subscription]);
    const owned = useMemo(
        () => ownsPackReference(product?.reference),
        [product?.reference, user?.ownedPacksReferences],
    );
    const freePack = useMemo(() => !product.price, [product.price]);
    const inCart = useMemo(() => packInCart(product), [product, cart?.items]);

    const handleLuniiSubscriptionSpendModalOpen = useCallback(() => {
        openSubscriptionModal(product);
    }, [openSubscriptionModal, product]);

    const {Button, Button2} = buttonifyFromProps(
        {
            buttonLabel: owned ? (
                t('product_owned')
            ) : (
                <>
                    <span className="s-hide">{t('product_add_to_cart')}&nbsp;</span>
                    {product.price === 0
                        ? ` - ${t('product_price_free')}`
                        : convertPrice(product.price, product.currency)}
                </>
            ),
            buttonTarget: 'https://lunii/add_to_cart',
            buttonType: owned
                ? 'tertiary,startIcon=library'
                : `${
                      subscriptionAvailable &&
                      product.canPurchaseWithSubscription &&
                      subscription &&
                      !freePack
                          ? 'secondary'
                          : 'primary'
                  }`,
            button2Label: t('product_open_subscription_modal', {
                count: SUBSCRIPTION_PACK_CREDIT_VALUE,
            }),
            button2Target: handleLuniiSubscriptionSpendModalOpen,
            button2Type:
                subscriptionAvailable &&
                product.canPurchaseWithSubscription &&
                !subscription?.wallet.balance &&
                'disabled',
        },
        ['primary,startIcon=cart', 'primary,startIcon=token'],
    );

    const productWishlistIndex = findIndexInObjectArray(
        (item: wishlist_item) => item?.objectId === product?.objectId,
        dataRetrieveWishlist?.retrieveWishlist?.items,
    );
    const productInWishlist =
        isLogged &&
        productWishlistIndex > -1 &&
        !dataRetrieveWishlist?.retrieveWishlist?.items[productWishlistIndex]?._removed;
    const handleWishlistToggleClick = async (_e: any) => {
        const convertedProduct = convertDigitalAlbumProductToWishlistItem(product);
        await handleWishlistClick(productInWishlist, convertedProduct);
        handleWishlistEventTracking(productInWishlist, product);
    };
    const handleSubscriptionAdvertNavigate = () => {
        goPageByModel && goPageByModel('abonnement_presentation');
    };

    const productDescriptionItems: any[] = [];
    product.ageMin &&
        productDescriptionItems.push({title: t('product_age_title'), content: product.ageMin});
    product.duration &&
        productDescriptionItems.push({
            title: t('product_duration_title'),
            content: product.duration,
        });

    const locales =
        product.localesAvailable && product.localesAvailable.map((locale) => locale.name);

    const productDescriptionLocales: any = locales
        ? {title: t('product_language_title'), languages: product.languagesAvailable, locales}
        : undefined;

    const casting = [
        ...(product.authors || []).map((author: any) => ({
            ...author,
            type: 'author',
        })),
        ...(product.tellers || []).map((teller: any) => ({
            ...teller,
            type: 'teller',
        })),
        ...(product.translators || []).map((translator: any) => ({
            ...translator,
            type: 'translator',
        })),
    ];

    return (
        <LuniiSubscriptionSpendModalProvider>
            <Row
                className={classes.root}
                alignItems="flex-start"
            >
                <Grid
                    item
                    container
                    lg={4}
                    md={3}
                    sm={3}
                    xs={12}
                >
                    <div className={classes.topLeft}>
                        <div className={classes.productImage}>
                            {product.image && (
                                <Img
                                    className={classes.image}
                                    {...product.image}
                                    height={512}
                                    width={512}
                                    alt={product.name}
                                />
                            )}
                        </div>
                        {product.reduction && product.oldPrice && (
                            <div className={classes.productPrice}>
                                <div className={classes.reduction}>
                                    {t('product_reduction_content')} {product.reduction}
                                </div>
                                <div className={classes.pricing}>
                                    <div className={classes.price}>
                                        {convertPrice(product.price, product.currency)}
                                    </div>
                                    <div className={classes.oldPrice}>
                                        {convertPrice(product.oldPrice, product.currency)}
                                    </div>
                                </div>
                            </div>
                        )}

                        {hasMounted && (
                            <div className={classes.actions}>
                                {subscriptionAvailable &&
                                    product.canPurchaseWithSubscription &&
                                    !freePack &&
                                    !owned &&
                                    subscription &&
                                    Button2 && (
                                        <Button2
                                            disabled={
                                                subscription.wallet.balance <
                                                SUBSCRIPTION_PACK_CREDIT_VALUE
                                            }
                                            className={clsx(
                                                classes.button,
                                                owned && classes.disabled,
                                            )}
                                        />
                                    )}
                                {Button && (
                                    <div className={classes.actionsWrapper}>
                                        <Button
                                            disabled={inCart}
                                            className={clsx(
                                                classes.button,
                                                owned && classes.disabled,
                                            )}
                                        />
                                        {isLogged && ((owned && productInWishlist) || !owned) && (
                                            <WishlistToggle
                                                className={classes.wishlistButton}
                                                toggled={productInWishlist}
                                                onClick={handleWishlistToggleClick}
                                            />
                                        )}
                                    </div>
                                )}
                                {subscriptionAvailable &&
                                    !product.canPurchaseWithSubscription &&
                                    subscription && (
                                        <div className={classes.notify}>
                                            {t('subscription_notify_music')}
                                        </div>
                                    )}
                                {subscriptionAvailable &&
                                    !subscription &&
                                    product.canPurchaseWithSubscription && (
                                        <SubscriptionAdvert
                                            className={classes.subscriptionAdvertDesktop}
                                            navigate="https://lunii/go_to_subscription_presentation"
                                        />
                                    )}
                            </div>
                        )}
                    </div>
                </Grid>
                <Hidden xsDown>
                    <Grid
                        item
                        container
                        lg={1}
                        md={1}
                        sm={1}
                    />
                </Hidden>
                <Grid
                    item
                    container
                    lg={6}
                    md={7}
                    sm={7}
                    xs={12}
                    direction="column"
                >
                    <div className={classes.subtitle}>{product.subtitle}</div>
                    <div className={classes.name}>
                        <h1>{product.name}</h1>
                    </div>
                    <ProductDescription
                        items={productDescriptionItems}
                        locales={productDescriptionLocales}
                    />
                    {product.types && (
                        <div className={classes.types}>
                            <div className={classes.typesContent}>
                                <div className={classes.typeTitle}>{t('product_type_title')}</div>
                                <div className={classes.typeName}>{product.types.name}</div>
                            </div>
                            <div className={classes.typeImage}>
                                {product?.types?.image && <Img {...product.types.image} />}
                            </div>
                        </div>
                    )}
                    <div
                        className={classes.description}
                        dangerouslySetInnerHTML={{__html: product.description || ''}}
                    />
                    <div className={classes.extractCard}>
                        <ExtractCard urls={product.previews} />
                    </div>
                    {casting.length > 0 &&
                        casting.map(({name, gender, image, type}, index) => (
                            <div
                                className={classes.casting}
                                key={`${name.replace(/\s/g, '-')}-${index}`}
                            >
                                <AuthorCard
                                    title={name}
                                    gender={gender}
                                    image={image}
                                    type={type}
                                />
                            </div>
                        ))}
                    {hasMounted &&
                        subscriptionAvailable &&
                        !subscription &&
                        product.canPurchaseWithSubscription && (
                            <SubscriptionAdvert
                                className={classes.subscriptionAdvertMobile}
                                navigate={handleSubscriptionAdvertNavigate}
                            />
                        )}
                </Grid>
            </Row>

            {actualRecommendations.length > 0 && (
                <AlbumCarroussel
                    backgroundColor="#FAF9F8"
                    title={t('product_recommendations_title')}
                    text={t('product_recommendations_subtitle')}
                    items={actualRecommendations}
                />
            )}
        </LuniiSubscriptionSpendModalProvider>
    );
}

export interface DigitalAlbumProductProps {
    product: digital_album_product;
}

export default DigitalAlbumProduct;
