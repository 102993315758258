import React from 'react';
import {cssText} from '@ohoareau/css-utils';
import ProductSpecsAccordion from '../molecules/ProductSpecsAccordion';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Row from "../Row";
import Grid from "@material-ui/core/Grid";
import {module_label, module_title} from "../types";

const useStyles = makeStyles(theme => ({
    root: {
        ...cssText(theme, 'standard', 'body'),
        paddingTop: 64
    },
    rootMobile: {
        paddingTop: 32
    },
    title: {
        ...cssText(theme, 'standard', 'large_title'),
        [theme.breakpoints.down('sm')]: {
            ...cssText(theme, 'standard', 'title_1'),
        },
    }
}));

export function ProductSpecs({productSpecification, title}: ProductSpecsProps) {
    const classes = useStyles();
    return (
        <Row className={classes.root}>
            <Grid item container lg={12} md={12} xs={12}>
                <div className={classes.title}>{title}</div>
                <ProductSpecsAccordion items={(productSpecification || {}).items}/>
            </Grid>
        </Row>
    );
}

export interface ProductSpecsProps {
    title: module_title,
    productSpecification: {
        items: {
            title: module_title,
            excludedLocales: string,
            items: {
                excludedLocales: string,
                label: module_label,
                content: any
            }[]
        }[]
    }
}

export default ProductSpecs
