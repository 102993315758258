import React from 'react';
import TextField, {TextFieldProps} from './TextField';

export function SearchField(props: SearchFieldProps) {
    return (
        <TextField name={'search'} {...props} />
    );
}

export interface SearchFieldProps extends TextFieldProps {}

export default SearchField