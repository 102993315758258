import React from 'react';
import clsx from 'clsx';
import {UneditableTextField} from '../atoms';
import {makeStyles} from '@material-ui/core/styles';
import {CountryStatesHelper} from '../utils';
import {AddressTypeEnum} from '../types';
import {formatPhoneNumberIntl} from 'react-phone-number-input';

const useStyles = makeStyles(theme => ({
    fieldset: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
    },
    input: {
        marginBottom: theme.spacing(2),
        width: '100%',
    },
    half: {
        [theme.breakpoints.up('lg')]: {
            width: 'calc(50% - 16px)',
        },
    },
    third: {
        [theme.breakpoints.up('lg')]: {
            width: 'calc(33.33% - 16px)',
        },
    },
    twoThird: {
        [theme.breakpoints.up('lg')]: {
            width: 'calc(66.66% - 16px)',
        },
    },
    threeQuarters: {
        [theme.breakpoints.up('lg')]: {
            width: 'calc(75% - 16px)',
        },
    },
}));

export function DefaultAddress({className, defaultAddress, locale, addressType}: DefaultAddressProps) {
    const classes = useStyles();
    if (!defaultAddress) return null;
    const {
        firstName,
        lastName,
        address1,
        address2,
        zipCode,
        city,
        country,
        state,
        phone,
    } = defaultAddress;

    return (
        <div className={clsx(classes.fieldset, className)}>
            <UneditableTextField name={'firstName'} value={firstName} className={clsx(classes.input, classes.half)}/>
            <UneditableTextField name={'lastName'} value={lastName} className={clsx(classes.input, classes.half)}/>
            <UneditableTextField name={'address1'} value={address1} className={classes.input}/>
            {address2 && <UneditableTextField name={'address2'} value={address2} className={clsx(classes.input, classes.threeQuarters)}/>}
            <UneditableTextField name={'zipCode'} value={zipCode} className={clsx(classes.input, classes.third)}/>
            <UneditableTextField name={'city'} value={city} className={clsx(classes.input, classes.twoThird)}/>
            {CountryStatesHelper.countryFromLocaleHasStates(locale) && <UneditableTextField name={'state'} value={CountryStatesHelper.stateFromValue(locale, state)} className={clsx(classes.input, classes.twoThird)}/>}
            <UneditableTextField name={'country'} value={country} className={clsx(classes.input, classes.half)}/>
            {addressType === AddressTypeEnum.Delivery &&
                <UneditableTextField name={'phone'} value={phone && formatPhoneNumberIntl(phone)} className={clsx(classes.input)}/>}
        </div>
    );
}

export interface DefaultAddressProps {
    className?: any,
    defaultAddress?: any,
    locale: string,
    addressType?: AddressTypeEnum|undefined,
}

export default DefaultAddress;
