import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {cssFont} from '@ohoareau/css-utils';
import clsx from 'clsx';
import {link_theme} from '../../../types';
import {DynamicIcon} from '../../../ui';
import {getThemeColor} from '../../../../configs';
import {Click} from '../..';

const getColorFromTheme = (theme: link_theme | undefined, nuance: string = '800'): string => {
    switch (theme) {
        case 'default':
            return getThemeColor('interactive', nuance);
        case 'info':
            return getThemeColor('brand-secondary', nuance);
        case 'neutral':
            return getThemeColor('ui', nuance);
        case 'error':
            return getThemeColor('danger', nuance);
        case 'tips':
            return getThemeColor('brand-primary', nuance);
        case 'success':
            return getThemeColor('positive', nuance);
        case 'flam':
            return getThemeColor('brand-flam', nuance);
        default:
            return getThemeColor('interactive', nuance);
    }
};

const useStyles = makeStyles((theme) => ({
    root: ({disabled, theme: linkTheme, iconPosition, size}: any) => ({
        background: 'none',
        border: 'none',
        padding: 0,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: disabled ? 'not-allowed' : 'pointer',
        textDecoration: 'underline',
        transition: 'all 0.35s ease-out, all 0.35s ease-out, all 0.35s ease-out',
        textAlign: 'left',
        color: getColorFromTheme(linkTheme, linkTheme === 'error' ? '500' : '800'),
        '&:link': {
            color: getColorFromTheme(linkTheme, linkTheme === 'error' ? '500' : '800'),
        },
        '&:visited': {
            color: getColorFromTheme(linkTheme, linkTheme === 'error' ? '600' : '900'),
        },
        '&:hover': {
            color: getColorFromTheme(linkTheme, linkTheme === 'error' ? '400' : '700'),
        },
        '&:focus-visible': {
            borderRadius: theme.spacing(4),
            outlineOffset: 4,
            outline: `2px solid ${getColorFromTheme(linkTheme, linkTheme === 'error' ? '500' : '800')}`,
        },
        ...(size === 'large' && {
            ...cssFont(theme, 'standard', 'button_1'),
        }),
        ...(size === 'medium' && {
            ...cssFont(theme, 'standard', 'button_2'),
        }),
        ...(size === 'small' && {
            ...cssFont(theme, 'standard', 'button_3'),
            textTransform: 'none',
        }),
        ...(iconPosition === 'right' && {
            flexDirection: 'row-reverse',
        }),
        ...(disabled && {
            color: getColorFromTheme('neutral', '600'),
            '&:link, &:visited, &:hover, &:focus-visible': {
                color: getColorFromTheme('neutral', '600'),
                outline: 'none',
                outlineOffset: 0,
            },
        }),
    }),
    icon: ({iconPosition}: any) => ({
        ...(iconPosition === 'left' && {
            marginRight: theme.spacing(0.5),
        }),
        ...(iconPosition === 'right' && {
            marginLeft: theme.spacing(0.5),
        }),
    }),
}));

export function Link({className = '', text, theme = 'default', size = undefined, disabled = false, icon = undefined, iconPosition = 'left', url}: LinkProps) {
    const classes = useStyles({theme, disabled, iconPosition, size});
    const width = (size === 'large' && 24) || 16;
    return (
        <Click url={url} className={clsx(classes.root, className)} disabled={disabled}>
            {(icon?.length && <DynamicIcon className={classes.icon} type={`${icon}-${width}`} width={width} height={width} />) || false}
            {text}
        </Click>
    );
}

export interface LinkProps {
    className?: string;
    text: string;
    theme?: link_theme;
    size?: 'large' | 'medium' | 'small';
    disabled?: boolean;
    icon?: string;
    iconPosition?: 'left' | 'right';
    url: string | Function;
}

export default Link;
