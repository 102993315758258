import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';
import {cssText} from '@ohoareau/css-utils';
import {useFormContext} from 'react-hook-form';
import {convertPrice, Button, useLuniiTranslation} from '../../ui';
import SvgSend from '../../ui/images/icons/Send';
import SvgAddToCart from '../../ui/images/icons/AddToCart';

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    infos: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#716C5E'),
        marginBottom: theme.spacing(1),
    },
    svg: {
        marginRight: theme.spacing(1),
    },
}));

export function GiftCardSubmit({
    className = '',
    now,
    currency,
    onClick,
    hasError = false,
    errorClassName = '',
}: GiftCardSubmitProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const {watch} = useFormContext();
    const giftCardAmount = watch('giftCardAmount');
    const giftCardDate = watch('giftCardDate');
    const amount = giftCardAmount?.proposed || giftCardAmount?.custom;
    return (
        <div className={clsx(classes.root, className)}>
            {!!giftCardDate && giftCardDate.getDate() < now.getDate() + 1 && (
                <div className={classes.infos}>
                    <SvgSend
                        className={classes.svg}
                        width={19}
                        height={19}
                        color="inherit"
                    />
                    {t('gift_cards_submit_infos')}
                </div>
            )}
            <Button
                size="large"
                color="primary"
                startIcon={<SvgAddToCart />}
                onClick={onClick}
            >
                {t('product_add_to_cart')}{' '}
                {amount &&
                    !isNaN(amount) &&
                    Number(amount) > 0 &&
                    convertPrice(Math.round(amount * 100), currency)}
            </Button>
            {hasError && <div className={errorClassName}>{t('gift_cards_invalid_form')}</div>}
        </div>
    );
}

export interface GiftCardSubmitProps {
    className?: string;
    now: Date;
    currency: string;
    onClick?: any;
    hasError?: boolean;
    errorClassName?: string;
}

export default GiftCardSubmit;
