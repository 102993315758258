import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {HardwareSelector, AudioController, AudioPlayer, DynamicIcon, e_commerce_included, hardware_product,
    Img,} from '../';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    header: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
        color: '#423F37',
    },
    svg: {
        marginRight: theme.spacing(1),
    },
    title: {
        color: '#423F37',
    },
    items: {
        backgroundColor: '#F4FBFD',
        padding: theme.spacing(2),
        borderRadius: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2, 1),
        },
    },
    selector: {
        marginBottom: theme.spacing(3),
    },
    item: {
        display: 'flex',
        lineHeight: 0,
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        [theme.breakpoints.down('md')]: {
            alignItems: 'flex-start',
        },
    },
    itemImage: {
        marginRight: theme.spacing(1.5),
        width: 64,
        height: 64,
        filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
    },
    itemContent: {
        alignSelf: 'stretch',
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
    },
    itemInfos: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    itemTitle: {
        marginBottom: theme.spacing(.5),
        color: '#2B434B',
    },
    itemDescription: {
        color: '#497281',
    },
    itemAction: {
        marginLeft: theme.spacing(1.5),
        [theme.breakpoints.down('md')]: {
            flex: '0 1 100%',
            margin: theme.spacing(1, 0),
        },
    },
    audio: {
        '&>:not(:last-child)': {
            marginBottom: theme.spacing(1),
            [theme.breakpoints.down('md')]: {
                marginBottom: theme.spacing(2),
            },
        },
    },
}));

export function ECommerceHardwareIncludedItems({title, icon, product, onProductChange}: ECommerceHardwareIncludedItemsProps) {
    const classes = useStyles();
    const items = product?.included || [];
    const alternateProducts = (product?.alternateProducts || []).filter(({slug}) => !!slug);
    return (
        <div className={classes.root}>
            <div className={classes.header}>
                {icon && <DynamicIcon
                    className={classes.svg}
                    type={icon}
                    width={24}
                    height={24}/>}
                {title && <div className={clsx('title_2', classes.title)}>{title}</div>}
            </div>
            <div className={classes.items}>
                {alternateProducts.length > 0 &&
                    <HardwareSelector
                        className={clsx(items.length > 0 && classes.selector)}
                        product={product}
                        alternateProducts={alternateProducts}
                        onProductChange={onProductChange}/>
                }
                <div className={classes.audio}>
                    <AudioController>
                        {items.map(({title, description, imageUrl, action}, index) => (
                            <div
                                key={`incl-itm-${index}`}
                                className={classes.item}>
                                {imageUrl && <Img
                                    className={classes.itemImage}
                                    url={imageUrl}
                                    alt={title}
                                    loading={'eager'}/>}
                                <div className={classes.itemContent}>
                                    <div className={classes.itemInfos}>
                                        {title && <div className={clsx('body_thick', classes.itemTitle)}>{title}</div>}
                                        {description && <div className={clsx('secondary_body', classes.itemDescription)}>{description}</div>}
                                    </div>
                                    {action && <div className={classes.itemAction}>
                                        {action.type === 'audio' && action.url && <AudioPlayer url={action.url}/>}
                                    </div>}
                                </div>
                            </div>
                        ))}
                    </AudioController>
                </div>
            </div>
        </div>
    );
}

export interface ECommerceHardwareIncludedItemsProps {
    title?: any,
    icon?: string,
    product: hardware_product,
    items?: e_commerce_included[],
    onProductChange: any,
}

export default ECommerceHardwareIncludedItems;
