import React, {useState} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
    Row, convertPrice, useLuniiTranslation, ECommerceHardwareIncludedItems, ECommerceCarousel,
    buttonifyFromProps, LuniiProductProvider, hardware_product, image_value, DropDownQuantity, useLuniiNavigation,
    convertProductReferenceToSlug, useHasMounted,
} from '..';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import SvgPackage from '../images/icons/Package';
import SvgClose from '../images/icons/Close';
import {convertProductTypeForPageModel} from '../../utils';

const useStyles = makeStyles(theme => ({
    root: {
    },
    content: {
        width: '49%',
        alignSelf: 'stretch',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    right: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    title: {
        color: '#423F37',
        marginBottom: theme.spacing(1),
    },
    price: {
        color: '#423F37',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    reduction: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexWrap: 'wrap',
        margin: theme.spacing(.5, 0, .5, 1.5),
    },
    reductionPercentage: {
        position: 'relative',
        color: '#722613',
        padding: theme.spacing(.25, 1),
        backgroundColor: '#FDB19E',
        clipPath: 'polygon(0 0, 0 100%, 85% 100%, 102% 50%, 85% 0)',
        borderRadius: 5,
        margin: theme.spacing(.5, 0, .25, 0),
    },
    reductionOldPrice: {
        color: '#A79F8E',
        textDecoration: 'line-through',
        marginLeft: theme.spacing(1),
        margin: theme.spacing(.5, 0, .25, 0),
    },
    text: {
        color: '#716C5E',
        margin: theme.spacing(2, 0),
    },
    selectors: {
        margin: theme.spacing(3, 0),
    },
    selectorsNoAlternateProducts: {
        margin: theme.spacing(1, 0, 3, 0),
    },
    shippingContainer: {
        width: '100%',
        textAlign: 'center',
    },
    shippingInfos: {
        width: '100%',
        color: '#896717',
        marginBottom: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    svg: {
        marginRight: theme.spacing(.5),
    },
    button: {
        width: '100%',
    },
    outOfStock: {
        width: '100%',
        color: '#F25129',
        marginBottom: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

export function ECommerceHardware({product, items = [], itemsTitle, text}: ECommerceHardwareProps) {
    const {price, currency, name, stock, reduction, oldPrice, shippingInfos, reference} = product || {};
    const classes = useStyles();
    const {t, exists} = useLuniiTranslation();
    const {goPageByModel} = useLuniiNavigation();
    const hasMounted = useHasMounted();
    const [quantity, setQuantity] = useState<number>(1);
    const {Button} = buttonifyFromProps({
        buttonLabel: `${t('product_add_to_cart')} ${price === 0 || price === undefined ? ' - ' + t('product_price_free') : convertPrice(price * quantity, currency)}`,
        buttonTarget: `https://lunii/add_to_cart?forceAdd=true&forcedQuantity=${quantity}`,
        buttonType: 'primary,startIcon=add-to-cart',
    });
    const onProductChange = (p: hardware_product) => {
        const prefix = convertProductTypeForPageModel(p?.subtype);
        p?.slug && goPageByModel && goPageByModel(`${prefix}_${p.slug}`); // works only with slugged endpoints (see gatsby-node.ts)
    };
    const titleKey = reference && `ecommerce_hardware_${convertProductReferenceToSlug(reference)}_name`;
    const title = titleKey && exists(titleKey) && t(titleKey) || name;
    if (!product || product.type !== 'hardware' || product.notFound) return null;
    if (!hasMounted) return null;
    return (
        <LuniiProductProvider value={product}>
            <Row className={classes.root}>
                <Grid
                    item
                    container
                    lg={12}
                    md={12}
                    xs={12}
                    justifyContent={'space-between'}>
                    <ECommerceCarousel
                        className={classes.content}
                        items={items}/>
                    <div className={clsx(classes.right, classes.content)}>
                        <div className={clsx('large_title', classes.title)}>{title}</div>
                        <div className={clsx('title_1', classes.price)}>
                            {stock ? (price === 0 ? t('product_price_free') : convertPrice(price, currency)): undefined}
                            {stock && reduction && <span className={classes.reduction}>
                                <span className={clsx('secondary_body_thick', classes.reductionPercentage)}>{reduction}</span>
                                <span className={clsx('secondary_body', classes.reductionOldPrice)}>{convertPrice(oldPrice, currency)}</span>
                            </span>}
                        </div>
                        <div className={clsx('body', classes.text)}>{text}</div>
                        {product.alternateProducts && <ECommerceHardwareIncludedItems
                            title={itemsTitle || t('ecommerce_hardware_included_title')}
                            icon='union'
                            product={product}
                            onProductChange={onProductChange}/> || false}
                        <DropDownQuantity
                            className={clsx(classes.selectors, !product.alternateProducts && classes.selectorsNoAlternateProducts)}
                            quantitiesAvailable={4}
                            quantity={quantity}
                            onChange={setQuantity}/>
                        <div className={classes.shippingContainer}>
                            {stock ?
                                shippingInfos && (<div className={clsx('secondary_body', classes.shippingInfos)}>
                                    <SvgPackage
                                        className={classes.svg}
                                        width={16}
                                        height={16}
                                        color={'currentColor'}/>
                                    {shippingInfos}
                                </div>) :
                                (<div className={clsx('secondary_body', classes.outOfStock)}>
                                    <SvgClose
                                        className={classes.svg}
                                        width={16}
                                        height={16}
                                        color={'currentColor'}/>
                                    {t('product_out_of_stock')}
                                </div>)
                            }
                            {Button && <Button
                                className={classes.button}
                                disabled={!stock}/>}
                        </div>
                    </div>
                </Grid>
            </Row>
        </LuniiProductProvider>
    );
}

export interface ECommerceHardwareProps {
    product: hardware_product,
    itemsTitle?: any,
    text?: any,
    items: {image: image_value, image2: image_value}[],
}

export default ECommerceHardware;
