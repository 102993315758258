import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {cssFont, cssText} from '@ohoareau/css-utils';
import clsx from 'clsx';
import {Button, useLuniiNavigation, useLuniiTranslation,} from '../../ui';
import {Illustration} from '../index'

const useStyles = makeStyles((theme) => ({
    root: {
    },
    title: {
        ...cssText(theme, 'standard', 'title_1', undefined, undefined, '#2C3637'),
        fontSize: 24,
        textAlign: 'center',
        margin: theme.spacing(4, 0, 0, 0),
        [theme.breakpoints.down('sm')]: {
            ...cssFont(theme, 'standard', 'title_2'),
        },
    },
    description: {
        ...cssText(theme, 'standard', 'body', 'center'),
        marginTop: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            ...cssFont(theme, 'standard', 'secondary_body', 'center'),
        },
    },
    cta: {
        marginTop: theme.spacing(1),
    },
    image: {
        width: '100%',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 16,
            paddingRight: 16,
        },
    },
}));

export function WishlistEmpty({className = '', mode = undefined}: WishlistEmptyProps) {
    const {t, exists} = useLuniiTranslation();
    const {goPageByModel} = useLuniiNavigation();
    const classes = useStyles();
    let illustration: string = '';
    let title: string = '';
    let description: string = '';
    let cta: string | undefined;
    let model: string | undefined;
    switch (mode) {
        case 'fah':
            title = 'my_empty_fah_wishlist_title';
            description = 'my_empty_fah_wishlist_description';
            illustration = 'my_empty_fah_wishlist_illustration';
            cta = 'my_empty_fah_wishlist_action';
            model = 'nos_histoires_luniistore';
            break;
        case 'flam':
            title = 'my_empty_flam_wishlist_title';
            description = 'my_empty_flam_wishlist_description';
            illustration = 'my_empty_flam_wishlist_illustration';
            cta = 'my_empty_flam_wishlist_action';
            model = 'catalogue_flam';
            break;
        default:
            break;
    }
    if (!mode) return null;
    return (
        <div className={clsx(classes.root, className)}>
            <Illustration
                className={classes.image}
                name={illustration}
                tablet={null}
            />
            <div className={classes.content}>
                <h3 className={classes.title}>{t(title)}</h3>
                {exists(description) && <p className={classes.description}>{t(description)}</p> || false}
                {cta && goPageByModel ? (
                    <div className={classes.cta}>
                        <Button color='primary' onClick={() => goPageByModel(model)}>
                            {t(cta)}
                        </Button>
                    </div>
                ) : false}
            </div>
        </div>
    );
}

export interface WishlistEmptyProps {
    className?: string,
    mode?: 'fah' | 'flam',
}

export default WishlistEmpty;

