import {useCallback} from 'react';
import useLuniiShoppingCartValidateApi from './useLuniiShoppingCartValidateApi';

export function useShoppingCartValidateSubmit(callback: Function|undefined = undefined) {
    const [validate, {loading, error, ...infos}] = useLuniiShoppingCartValidateApi();
    const onSubmit = useCallback(async shippingMethod => {
        try {
            const data = await validate({
                variables: {
                    data: {
                        shippingMethod
                    },
                },
            });
            const response = ((data || {}).data || {}).validateCart;
            callback && callback(response);
            return response;
        } catch (e) {
            console.error(e);
        }
    }, [validate, callback]);
    return [onSubmit, {loading: Boolean(loading), error: error || false, ...infos}] as [any, any];
}

export default useShoppingCartValidateSubmit
