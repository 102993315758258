import React from 'react';
import TextField, {TextFieldProps} from './TextField';

export function AddressField(props: AddressFieldProps) {
    return (
        <TextField name={'address1'} {...props} />
    );
}

export interface AddressFieldProps extends TextFieldProps {}

export default AddressField
