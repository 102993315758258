import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';

export function useLuniiTranslation() {
    /**
     * for some not understood reasons, using directly useTranslation and the returned t() function
     * seems not to be working well to update the labels.
     * the following trick seems to be working (i.e. using i18n.t() instead of t().)
     */
    const {i18n, ...x} = useTranslation();
    const t = useCallback((...args) => {
        // @ts-ignore
        return i18n.t(...args);
    }, [i18n, i18n.language]);
    const exists = useCallback((...args) => {
        // @ts-ignore
        return i18n.exists(...args);
    }, [i18n, i18n.language]);
    return {...x, i18n, t, exists};
}

export default useLuniiTranslation;
