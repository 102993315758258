import {useLocation} from '@reach/router';
import {useLuniiNavigation, useLuniiUser} from '../../../ui';

export function ForgotPasswordScreen() {
    const {user, logout} = useLuniiUser();
    const {goHomePassword} = useLuniiNavigation();
    const location = useLocation();
    if (user) logout();
    const searchParams = new URLSearchParams(location.search);
    const deviceReturnContext = searchParams.get('device-return');
    goHomePassword && goHomePassword(null, {deviceReturnContext});
    return null;
}

export default ForgotPasswordScreen;
