import React from 'react';
import {cssText} from '@ohoareau/css-utils';
import SvgInstagram from '../images/icons/Instagram';
import {buttonifyFromProps} from "../hocs/buttonify";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Row from "../Row";
import Grid from "@material-ui/core/Grid";
import HiddenCss from "@material-ui/core/Hidden/HiddenCss";
import {Img} from "../nucleons";
import {WithItems, WithButton, module_title} from "../types";

const useStyles = makeStyles(theme => ({
    root: {
        ...cssText(theme, 'standard', 'body'),
    },
    title: {
        ...cssText(theme, 'standard', 'large_title'),
        marginLeft: 14,
    },
    container: {
        flex: 1,
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 12,
    },
    content: {
        display: 'flex',
    },
    item: {
        alignSelf: 'stretch',
    },
    mobileButton: {
        marginTop: 12,
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));

export function InstagramViewer({title, items = [], ...props}: InstagramViewerProps) {
    const classes = useStyles();
    if (items.length === 0) return null;
    const {Buttons} = buttonifyFromProps(props, ['tertiary']);
    return (
        <Row className={classes.root}>
            <Grid item container lg={12} md={12} xs={12}>
                <div className={classes.container}>
                    <div className={classes.content}>
                        <div>
                            <SvgInstagram width={48} height={48}/>
                        </div>
                        <div className={classes.title}>{title}</div>
                    </div>
                    <HiddenCss smDown>
                        {Buttons && <Buttons noPadding />}
                    </HiddenCss>
                </div>
            </Grid>
            {items.map(({image}, index) => {
                return (
                    <Grid item container className={classes.item} lg={3} md={3} xs={6} key={index}>
                        {image && <Img {...image} />}
                    </Grid>
                );
            })}
            <Grid xs={12} className={classes.mobileButton}>
                {Buttons && <Buttons noPadding/>}
            </Grid>
        </Row>
    );
}

export interface InstagramViewerProps extends WithItems, WithButton {
    title?: module_title,
}

export default InstagramViewer
