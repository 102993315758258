import * as React from 'react';
function SvgAddToCart(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width={props.width || 24}
            height={props.height || 24}
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19 15a1 1 0 10-2 0v2h-2a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2v-2z"
                fill={props.color || 'currentColor'}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.235 4.152A2 2 0 0114 6h-4a2 2 0 011.235-1.848zM8 6H6.862A3 3 0 003.87 8.786l-.715 10A3 3 0 006.148 22H12a1 1 0 100-2H6.148a1 1 0 01-.998-1.071l.715-10A1 1 0 016.862 8h10.276a1 1 0 01.997.929l.265 2.197a.993.993 0 001.973-.233l-.243-2.107A3 3 0 0017.138 6H16a4 4 0 00-8 0zm1 6a1 1 0 100-2 1 1 0 000 2zm7-1a1 1 0 11-2 0 1 1 0 012 0z"
                fill={props.color || 'currentColor'}
            />
        </svg>
    );
}
export default SvgAddToCart;
