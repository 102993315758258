import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import {FormControlLabel, Radio} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'flex-start',
        width: '100%',
        padding: theme.spacing(1, 0),
        marginTop: theme.spacing(.5),
        marginBottom: theme.spacing(.5),
        [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        '&:first-child': {
            marginTop: 0,
        },
        '&:last-child': {
            marginBottom: 0,
        },
        marginLeft: 0,
    },
    active: {
        backgroundColor: '#FFF9EC',
        borderRadius: 8,
    },
    label: {
        marginLeft: theme.spacing(1),
        width: '100%',
    },
    radio: {
        [theme.breakpoints.down('md')]: {
            position: 'absolute',
        },
    },
}));

export function RadioFormControl({className, value, active = false, LabelComponent, disabled = false}: RadioFormControlProps) {
    const classes = useStyles();
    return (
        <FormControlLabel
            classes={{label: classes.label}}
            className={clsx(classes.root, active && classes.active, className)}
            value={value}
            disabled={disabled}
            control={<Radio className={classes.radio} color={'primary'}/>}
            label={LabelComponent}/>
    );
}

export interface RadioFormControlProps {
    className?: any,
    value: string,
    active?: boolean
    LabelComponent: any,
    disabled?: boolean,
}

export default RadioFormControl;
