import {LazyQueryHookOptions, LazyQueryResult} from '@apollo/client';
import {RETRIEVE_USER_SUBSCRIPTION, useLuniiApi} from '../..';

interface useSubscriptionOptions {
    retrieveUserSubscriptionOptions?: LazyQueryHookOptions;
}

// TODO: implement other subscription hooks methods here. cancel, retract, reactivate, activate
export function useSubscription(
    {retrieveUserSubscriptionOptions}: useSubscriptionOptions = {
        retrieveUserSubscriptionOptions: {},
    },
): [
    {
        retrieveUserSubscriptionExecute: (
            options?: LazyQueryHookOptions,
        ) => Promise<LazyQueryResult<any, any>>;
    },
    {
        retrieveUserSubscriptionResult: LazyQueryResult<any, any>;
    },
] {
    const {gql, useLazyQuery} = useLuniiApi();
    const [retrieveUserSubscriptionExecute, retrieveUserSubscriptionResult] = useLazyQuery(
        RETRIEVE_USER_SUBSCRIPTION(gql),
        {
            notifyOnNetworkStatusChange: true,
            ...retrieveUserSubscriptionOptions,
        },
    );

    return [
        {
            retrieveUserSubscriptionExecute: retrieveUserSubscriptionExecute as (
                options?: LazyQueryHookOptions,
            ) => Promise<LazyQueryResult<any, any>>,
        },
        {
            retrieveUserSubscriptionResult: retrieveUserSubscriptionResult as LazyQueryResult<
                any,
                any
            >,
        },
    ];
}
