import {useCallback, useEffect} from 'react';
import {GENERATE_STRIPE_CUSTOMER_PORTAL_SESSION, useLuniiApi} from '../..';

const retrieveUrl = (locale: string = '') => locale.replace('_', '-').toLowerCase() === 'es-es' ? 'https://stelii.com' : 'https://lunii.com';

export function useSubscriptionStripeCustomerPortal(callback: Function|undefined = undefined) {
    const {gql, useMutation} = useLuniiApi();
    const [generateStripeCustomerPortalSession, {loading, error, data, ...infos}] = useMutation(GENERATE_STRIPE_CUSTOMER_PORTAL_SESSION(gql));
    const onSubmit = useCallback(async (stripeCustomerId, locale) => {
        const returnUrl = window?.location?.href || retrieveUrl(locale);
        try {
            const data = await generateStripeCustomerPortalSession({
                variables: {
                    data: {
                        stripeCustomerId,
                        returnUrl,
                        locale,
                    },
                },
            });
            return ((data || {}).data || {}).generateStripeCustomerPortalSession;
        } catch (e) {
            console.error(e);
            return false;
        }
    }, [generateStripeCustomerPortalSession, callback]);
    useEffect(() => {
        if (data?.retrieveSubscriptionPaymentIntent && callback) {
            callback(data?.generateStripeCustomerPortalSession);
        }
    }, [callback, data?.generateStripeCustomerPortalSession]);
    return [onSubmit, {loading: Boolean(loading), error: error || false, ...infos}] as [any, any];
}

export default useSubscriptionStripeCustomerPortal;
