import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {CircularProgress} from '@material-ui/core';
import {
    MyActiveSubscription,
    MySubscriptionForm,
    useLuniiUser,
    address,
    ActivateCouponSuccessModal,
    MyEmptySubscriptionInfos,
    activateCoupon,
    useLuniiEventTracking,
} from '..';

const useStyles = makeStyles((theme) => ({
    root: {},
    circular: {
        width: '100%',
        textAlign: 'center',
    },
}));

export function MySubscription({className, ...props}: MySubscriptionProps) {
    const classes = useStyles();
    const handleTrackingEvent = useLuniiEventTracking();
    const [loading, setLoading] = useState<boolean>(false);
    const [openForm, setOpenForm] = useState<boolean>(false);
    const {user, refreshUserPartial} = useLuniiUser();
    const [showActivateSuccess, setShowActivateSuccess] = useState<boolean>(false);
    const [data, setData] = useState<any>({});
    const [openModal, setOpenModal] = useState<boolean>(false);
    const subscription = user?.subscription;
    const handleRefreshSubscription = async (showSuccessMessage = false) => {
        setLoading(true);
        await refreshUserPartial({subscription: true, library: false});
        if (showSuccessMessage) setShowActivateSuccess(true);
        setLoading(false);
    };
    const handleSuccess = (data: activateCoupon) => {
        handleTrackingEvent({
            categorie: 'conversion',
            action: 'abonnement',
            valeur: 0,
            libelle: `abonnement_consomme ${
                data?.numberOfMonths % 12 === 0 ? 'annuel' : 'mensuel'
            }`,
        });
        setData({numberOfMonths: data?.numberOfMonths});
        toggleModal();
    };
    const handleCloseModal = async () => {
        setOpenModal(false);
        setOpenForm(false);
        await handleRefreshSubscription(true);
    };
    const toggleForm = () => setOpenForm(!openForm);
    const toggleModal = () => setOpenModal(!openModal);
    const userHasBillingInfos = !!(user?.billingInfos || []).find(
        (address: address) => address.locale === user?.locale,
    );
    if (!user) return null;
    return (
        <div className={classes.root}>
            {loading && (
                <div className={classes.circular}>
                    <CircularProgress color="primary" />
                </div>
            )}
            {subscription && !loading && !openForm && (
                <MyActiveSubscription
                    onChange={handleRefreshSubscription}
                    showActivateSuccess={showActivateSuccess}
                    onActivateCouponConfirm={toggleForm}
                />
            )}
            {!subscription && !loading && !openForm && (
                <MyEmptySubscriptionInfos onActivateClick={toggleForm} />
            )}
            {openForm && (
                <MySubscriptionForm
                    showWarning={!userHasBillingInfos}
                    onCancelClick={toggleForm}
                    onSuccess={handleSuccess}
                />
            )}
            <ActivateCouponSuccessModal
                open={openModal}
                onClose={handleCloseModal}
                data={data}
            />
        </div>
    );
}

export interface MySubscriptionProps {
    className?: string;
}

export default MySubscription;
