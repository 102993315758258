import React from 'react';
import {PushLarge} from '../../../ui';

export function PushLargeLeftPushModule(props: PushLargeLeftPushModuleProps) {
    return (
        <PushLarge
            {...props}
            imagePosition="left"
        />
    );
}

export interface PushLargeLeftPushModuleProps {}

export default PushLargeLeftPushModule;
