import React from 'react';
import TextField, {TextFieldProps} from './TextField';

export function CityField(props: CityFieldProps) {
    return (
        <TextField name={'city'} {...props} />
    );
}

export interface CityFieldProps extends TextFieldProps {}

export default CityField