import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {DynamicIcon} from '../nucleons';
import clsx from 'clsx';
import {cssBox, cssText} from '@ohoareau/css-utils';

const useStyles = makeStyles((theme) => ({
    root: props => ({
        position: 'relative',
        display: 'flex',
        padding: theme.spacing(2),
        ...cssBox(theme, 'standard', props['level']),
        borderRadius: 16,
    }),
    iconWrapper: {
        height: 'fit-content',
        margin: theme.spacing(0, 2, 0, 0),
        '& >*': {
            lineHeight: 0,
        },
    },
    icon: {
        display: 'block',
        height: 20,
        width: 20,
    },
    body: {
        height: 'fit-content',
        ...cssText(theme, 'standard', 'secondary_body'),
    },
}));

export interface ShortWarningMessageProps {
    iconType?: string | undefined,
    level?: 'clear' | 'plain' | 'primary' | 'danger' | 'destructive' | 'positive' | 'secondary' | 'active' | 'interactive',
    body: React.ReactNode,
    className?: string,
}

export function ShortWarningMessage({iconType, level = 'plain', body, className = ''}: ShortWarningMessageProps) {
    const classes = useStyles({level});
    return <div className={clsx(classes.root, className)}>
        {iconType && <div className={classes.iconWrapper}>
            <DynamicIcon
                className={classes.icon}
                type={iconType}/>
        </div>}
        <div className={classes.body}>
            {body}
        </div>
    </div>;
};

export default ShortWarningMessage;
