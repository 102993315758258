import React from 'react';
import BookProduct from '../../organisms/BookProduct';
import HardwareProduct from '../../organisms/HardwareProduct';
import PaperBookProduct from '../../organisms/PaperBookProduct';
import UnknownProduct from '../../organisms/UnknownProduct';
import {useLuniiProduct} from '../../../ui';
import DigitalAlbumProductV2 from "../../organisms/v2/DigitalAlbumProductV2";

const map = {
    book: BookProduct,
    digitalalbum: DigitalAlbumProductV2,
    hardware: HardwareProduct,
    paperbook: PaperBookProduct,
    default: UnknownProduct,
};

export function ProductCustomModule(props: ProductCustomModuleProps) {
    const product = useLuniiProduct();
    const type = product.__typename
        .replace(/Product$/, '')
        .replace(/Lunii_/, '')
        .toLowerCase();
    const Comp = (map as any)[type || ''] || map.default;
    return (
        <Comp
            {...props}
            product={product || {}}
        />
    );
}

export interface ProductCustomModuleProps {}

export default ProductCustomModule;
