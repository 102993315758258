import React from 'react';
import QuoteSlide from './QuoteSlide';
import {Swiper, SwiperProps, SwiperSlide} from 'swiper/react/swiper-react.js';
import 'swiper/swiper.min.css';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {SwiperModule, Swiper as SwiperInstance} from 'swiper/types';
import {cssShadow} from '@ohoareau/css-utils';

const useStyles = makeStyles(theme => ({
    root: {
        width: '33%',
        marginBottom: 10,
        padding: '5px 0 10px',
        [theme.breakpoints.down('md')]: {
            width: '50%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    slide: {
        display: 'flex',
        justifyContent: 'center',
        opacity: 0.01,
        transition: 'opacity .35s ease-in-out',
        '& $quote': {
            transform: 'scale(.5)',
            transition: 'transform .35s ease-in-out',
        },
        '&.swiper-slide-visible': {
            opacity: 0.5,
        },
        '&.swiper-slide-active': {
            opacity: 1,
            '& $quote': {
                transform: 'scale(1)',
            },
        },
        '&.swiper-slide-duplicate-active': {
            opacity: 1,
            '& $quote': {
                transform: 'scale(1)',
            },
        },
        '&.swiper-slide-duplicate-active $quote': {
            transform: 'scale(1)',
        },
    },
    quote: {
        height: '64px',
        width: 64,
        overflow: 'hidden',
        borderRadius: '50%',
        ...cssShadow(theme, 'level_01'),
    },
}));

export function QuoteSwiperImages({controller, onSwiper, navLeftController, navRightController, items = [], modules}: QuoteSwiperImagesProps) {
    const classes = useStyles();
    const params: SwiperProps = {
        loop: true,
        modules,
        slidesPerView: items.length > 5 ? 5 : 3,
        loopedSlides: 6,
        watchSlidesProgress: true,
        centeredSlides: true,
        initialSlide: 0,
        controller: {
            control: controller,
        },
        slideToClickedSlide: true,
        navigation: {
            prevEl: navLeftController && navLeftController.current as unknown as HTMLElement,
            nextEl: navRightController && navRightController.current as unknown as HTMLElement,
        },
        onSwiper: onSwiper,
    };
    return (
        <Swiper
            {...params}
            className={classes.root}>
            {items.map(({image}, index) => (
                <SwiperSlide
                    key={index}
                    className={classes.slide}>
                    <QuoteSlide
                        image={image}
                        className={classes.quote}/>
                </SwiperSlide>
            ))}
        </Swiper>
    );
}

export interface QuoteSwiperImagesProps {
    controller?: any,
    onSwiper?: ((swiper: SwiperInstance) => void) | undefined,
    navLeftController?: any,
    navRightController?: any,
    items?: any[],
    modules: SwiperModule[]
}

export default QuoteSwiperImages;
