import React, {useCallback} from 'react';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {cssText} from '@ohoareau/css-utils';
import {IconButton, Typography} from '@material-ui/core';
import {useLuniiNavigation} from '../hooks';
import LargeTitleText from './LargeTitleText';
import BodyText from './BodyText';
import WarningPanel from './WaningPanel';
import CloseIcon from '../images/icons/Close';
import Logo from '../images/Logo';
import LogoStelii from '../images/LogoStelii';
import {deviceReturnUrl} from '../../../configs/site';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    header: {
        padding: theme.spacing(3, 0),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    header_dense: {
        padding: 0,
    },
    titleSizeNormal: {
        ...cssText(theme, 'standard', 'title_1'),
        width: '100%',
    },
    close: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    form_dense: {
        paddingBottom: 0,
    },
    bodyText: {
        marginTop: theme.spacing(1),
    },
    footer: {
        padding: theme.spacing(1, 0),
    },
    footer_dense: {
        minHeight: 0,
    },
    logo: {
        padding: theme.spacing(2, 0),
        textAlign: 'center',
    },
    logoFooter: {
        width: 85,
        height: 35,
        transform: 'scale(1.4)',
        marginLeft: 8,
    },
    logoClick: {
        cursor: 'pointer',
    },
}));

// TODO: fix ['form', 'header', 'footer'] props using https://www.developerway.com/posts/react-component-as-prop-the-right-way
export function FormPanel({
    onFormUpdate = undefined,
    enableLogoClick = false,
    dense = false,
    disableClose = false,
    onClose = undefined,
    logoPosition = 'default',
    className = undefined,
    formProps = {},
    form: Form = undefined,
    title = undefined,
    titleSize = 'default',
    description = undefined,
    infos = undefined,
    titleInHeader = false,
    header: Header = undefined,
    footer: Footer = undefined,
    deviceReturnContext = false,
}: FormPanelProps) {
    const classes = useStyles({dense});
    const {goHome} = useLuniiNavigation();
    const onLogoClick = useCallback(() => {
        if (enableLogoClick) {
            if (deviceReturnContext) {
                window.location.href = deviceReturnUrl;
            } else {
                goHome && goHome();
            }
        }
    }, [goHome, enableLogoClick, deviceReturnContext]);
    const LogoEl =
        (undefined !== location && location.host?.includes('stelii.com')) ||
        location.pathname?.includes('es-es') ? (
                <LogoStelii
                    onClick={onLogoClick}
                    className={clsx(classes.logoFooter, enableLogoClick && classes.logoClick)}
                />
            ) : (
                <Logo
                    onClick={onLogoClick}
                    className={clsx(classes.logoFooter, enableLogoClick && classes.logoClick)}
                />
            );
    return (
        <div className={clsx(classes.root, className)}>
            <div>
                <div className={clsx(classes.header, dense && classes.header_dense)}>
                    {!!title && titleInHeader && titleSize === 'large' && (
                        <LargeTitleText>{title}</LargeTitleText>
                    )}
                    {!!title && titleInHeader && titleSize !== 'large' && (
                        <Typography className={classes.titleSizeNormal}>{title}</Typography>
                    )}
                    {logoPosition === 'top' && !titleInHeader && LogoEl}
                    {Header && <Header />}
                    {!disableClose && (
                        <div className={classes.close}>
                            <IconButton onClick={onClose} disableRipple={true}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    )}
                </div>
                <div className={clsx(dense && classes.form_dense)}>
                    {!!title && !titleInHeader && titleSize === 'large' && (
                        <LargeTitleText>{title}</LargeTitleText>
                    )}
                    {!!title && !titleInHeader && titleSize !== 'large' && (
                        <Typography className={classes.titleSizeNormal}>{title}</Typography>
                    )}
                    {!!description && (
                        <BodyText className={classes.bodyText}>{description}</BodyText>
                    )}
                    {!!infos && <WarningPanel transKey={infos} />}
                    {Form && <Form onFormUpdate={onFormUpdate} {...formProps} />}
                </div>
            </div>
            <div className={clsx(classes.footer, dense && classes.footer_dense)}>
                {Footer && <Footer />}
                {logoPosition === 'bottom' && <div className={classes.logo}>{LogoEl}</div>}
            </div>
        </div>
    );
}

export interface FormPanelProps {
    onFormUpdate?: any;
    enableLogoClick?: boolean;
    dense?: boolean;
    disableClose?: boolean;
    onClose?: any;
    logoPosition?: 'top' | 'bottom' | 'default';
    className?: any;
    formProps?: any;
    form?: any;
    title?: any;
    titleSize?: 'large' | 'default';
    description?: any;
    infos?: any;
    titleInHeader?: any;
    header?: any;
    footer?: any;
    deviceReturnContext?: boolean;
}

export default FormPanel;
