import React from 'react';

import {Link} from "gatsby";
import clsx from "clsx";

export function ModifiedGatsbyLink({classes = {}, className, ...props}: any) {
    return (
        <Link className={clsx(...Object.values(classes as {[key: string]: string}), className)} {...props} />
    );
}

export default ModifiedGatsbyLink