import React from 'react';
import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';
import {cssBorder, cssShadow, cssText} from '@ohoareau/css-utils';
import {DynamicIcon} from '../../../../ui';
import {purifyHtml} from "../../../../utils";

const useStyles = makeStyles((theme) => ({
    root: {
        '--main': '#fbbd2a',
        position: 'relative',
        cursor: 'pointer',
        [theme.breakpoints.up('sm')]: {
            marginBottom: 0,
        },
        ...cssShadow(theme, 'level_01'),
        ...cssBorder(theme, 'push'),
        padding: theme.spacing(4),
        backgroundColor: 'white',
        transition: 'background .35s ease-out',
        height: '100%',
    },
    '@media (hover: hover)': {
        card: {
            willChange: 'box-shadow',
            transition: 'background .35s ease-out, box-shadow .30s ease-out',
            '&:hover': {
                ...cssShadow(theme, 'level_03'),
            },
        },
    },
    active: {
        ...cssShadow(theme, 'level_03'),
        backgroundColor: '#FFF9EC',
        '& $title, $pretitle, $subtitle': {
            color: '#503C0D',
        },
        '& $body, $bottomNote': {
            color: '#896717',
        },
    },
    border: {
        pointerEvents: 'none',
        ...cssBorder(theme, 'push'),
        border: '2px solid',
        borderColor: 'transparent',
        boxSizing: 'border-box',
        transition: 'border-color .35s ease',
        willChange: 'border-color',
        position: 'absolute',
        height: '100%',
        width: '100%',
        top: 0,
        left: 0,
        zIndex: 0,
    },
    borderActive: {
        ...cssBorder(theme, 'push'),
        border: '2px solid !important',
        borderColor: '#fbbd2a !important',
    },

    checkmark: {
        pointerEvents: 'none',
        position: 'absolute',
        top: 32,
        right: 32,
        zIndex: 0,
        height: 32,
        width: 32,
        backgroundColor: '#fbbd2a',
        borderRadius: '100%',
        transition: 'transform .35s ease, opacity .35s ease',
        transform: 'scale(.77)',
        opacity: 0,
    },
    checkmarkActive: {
        opacity: 1,
        transform: 'scale(1)',
    },

    checkmarkIcon: {
        pointerEvents: 'none',
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: 16,
        height: 16,
        opacity: 0.5,
        transform: 'translate3d(-46%, -46%, 0) scale(.77)',
        transition: 'opacity .35s ease',
    },
    checkmarkIconActive: {
        opacity: 1,
        transform: 'translate3d(-46%, -46%, 0) scale(1)',
    },
    headings: {
        margin: theme.spacing(0, 0, 2, 0),
    },
    pretitle: {
        ...cssText(theme, 'standard', 'body_thick', undefined, undefined, '#423F37'),
        transition: 'color .35s ease',
        marginBottom: 4,
    },
    title: {
        ...cssText(theme, 'standard', 'title_1', undefined, undefined, '#423F37'),
        transition: 'color .35s ease',
    },
    subtitle: {
        ...cssText(theme, 'standard', 'body_thick', undefined, undefined, '#423F37'),
        transition: 'color .35s ease',
        marginTop: 4,
    },
    body: {
        ...cssText(theme, 'standard', 'secondary_body'),
        transition: 'color .35s ease',
        lineHeight: '1.9em',
    },
    bottomNote: {
        ...cssText(theme, 'standard', 'caption'),
        transition: 'color .35s ease',
        marginTop: 6,
    },
}));

export function PickerCard({
    id,
    title,
    pretitle = undefined,
    subtitle = undefined,
    body = undefined,
    bottomNote = undefined,
    handleClick,
    active = false,
    className = '',
}: PickerCardProps) {
    const classes = useStyles();
    return (
        <div
            className={clsx(classes.root, active && classes.active, className)}
            onMouseMove={(e) => e.stopPropagation()}
            onClick={(e) => handleClick(id, e)}
        >
            {/* Start: Selection animation */}
            <div className={clsx(classes.border, active && classes.borderActive)} />
            <div className={clsx(classes.checkmark, active && classes.checkmarkActive)}>
                <DynamicIcon
                    className={clsx(
                        classes.checkmarkIcon,
                        active && classes.checkmarkIconActive,
                    )}
                    type="check"
                    height={32}
                    width={32}
                />
            </div>
            {/* End: Selection animation */}
            <div>
                <div className={classes.headings}>
                    {pretitle && <div className={classes.pretitle}>{pretitle}</div>}
                    {title && <div className={classes.title}>{title}</div>}
                    {subtitle && <div className={classes.subtitle}>{subtitle}</div>}
                </div>
                {body && <div className={classes.body} dangerouslySetInnerHTML={{__html: purifyHtml(body || '')}}/>}
                {bottomNote && <div className={classes.bottomNote}>{bottomNote}</div>}
            </div>
        </div>
    );
}

export interface PickerCardProps {
    id: string;
    title: string;
    pretitle?: string;
    subtitle?: string;
    bottomNote?: string;
    body?: any;
    handleClick: (id: string, e: React.MouseEvent<Element>) => void;
    active?: boolean;
    className?: string;
}

export default PickerCard;
