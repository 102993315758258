import React, {useMemo, useState} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Row from '../Row';
import Grid from '@material-ui/core/Grid';
import Img from '../nucleons/Img';
import {cssBox, cssText} from '@ohoareau/css-utils';
import {LuniiProductProvider} from '../contexts';
import {buttonifyFromProps} from '../hocs';
import {convertPrice} from '../utils/convertPrice';
import {useHasMounted, useLuniiUser, useLuniiTranslation, useShoppingCart, useLuniiPage} from '../hooks';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: 'white',
        zIndex: 999, // above everything
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        padding: theme.spacing(2),
        ...cssBox(theme, undefined, 'clear', 'level_02'),
    },
    image: {
        borderRadius: 4,
        height: 73,
        width: 73,
        lineHeight: 0,
        backgroundColor: '#faf9f8',
        [theme.breakpoints.only('md')]: {
            height: 100,
            width: 100,
        },
        [theme.breakpoints.down('sm')]: {
            height: 64,
            width: 64,
            marginRight: theme.spacing(2),
        },
    },
    content: {
        flex: 1,
        width: '100%',
        margin: theme.spacing(0, 3),
        [theme.breakpoints.down('sm')]: {
            margin: 0,
        },
    },
    title: {
        ...cssText(theme, 'standard', 'title_2'),
        width: '80%',
        [theme.breakpoints.only('md')]: {
            width: '90%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '95%',
        },
    },
    text: {
        ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#716C5E'),
        textOverflow: 'ellipsis',
        width: '80%',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '95%',
        },
    },
    buttons: {
        display: 'flex',
        flexDirection: 'row-reverse',
        alignItems: 'center',
        '& > *:first-child': {
            marginLeft: theme.spacing(1),
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginTop: theme.spacing(2),
        },
    },
}));

export function PushSticky({product, title, text, buttonLabel, buttonTarget, buttonType, image, ...props}: PushStickyProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const {ownsPackReference} = useLuniiUser();
    const [{packInCart}] = useShoppingCart();
    const page = useLuniiPage();
    const hasMounted = useHasMounted();
    const hideButton = useMemo(() => ({
        button2Label: t('push_sticky_close'),
        button2Target: () => setShow(false),
        button2Type: 'tertiary,endIcon=close,size=small_mobile_only',
    }), []);
    const [show, setShow] = useState<boolean>(true);
    const {Buttons} = buttonLabel
        ? buttonifyFromProps({buttonTarget, buttonLabel, ...hideButton}, [buttonType ? `${buttonType},size=small_mobile_only` : 'primary,size=small_mobile_only'])
        : product
            ? buttonifyFromProps({
                buttonTarget: product.stock && `https://lunii/add_to_cart${page?.pageModel ? `?libelle=sticky_${page?.pageModel}` : ''}`,
                buttonLabel: product.stock ? (product.price === 0 ? t('product_price_free') : convertPrice(product.price, product.currency)) : undefined,
                ...hideButton,
            }, ['primary,startIcon=cart,size=small_mobile_only'])
            : buttonifyFromProps({...hideButton});
    const _product: any = product || {};
    const _img = image || _product.image || {};
    const _title = title || _product.name || _product.title;
    const _alt = (_img && _img.alt) || _product.name || _title;
    return (
        <>
            {((product && hasMounted && !ownsPackReference(product?.reference) && !packInCart(product)) || !product) && show && <LuniiProductProvider value={product}>
                <Row className={classes.root} padding={'none'}>
                    <Grid item container lg={12} md={12} xs={12} justifyContent={'flex-start'}>
                        <div>
                            <Img className={classes.image} {..._img} alt={_alt} loading={'eager'}/>
                        </div>
                        <div className={classes.content}>
                            <div className={classes.title}>{_title}</div>
                            <div className={classes.text}>{text}</div>
                        </div>
                        <div className={classes.buttons}>
                            {Buttons && <Buttons/>}
                        </div>
                    </Grid>
                </Row>
            </LuniiProductProvider>}
        </>
    );
}

export interface PushStickyProps {
    product?: any,
    buttonLabel?: any,
    buttonTarget?: any,
    buttonType?: any,
    title?: any,
    text?: any,
    image?: any,
    className?: string,
    eventTrackingCallback?: any,
}

export default PushSticky;
