import React from 'react';
import TextField, {TextFieldProps} from './TextField';
import useLuniiTranslation from "../../hooks/useLuniiTranslation";
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
    input: {
        '::placeholder': { /* Recent browsers */
            textTransform: 'none',
        },
        textTransform: 'lowercase',
    }

});

export function EmailField(props: EmailFieldProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    return (
        <TextField inputClassName={classes.input} name={'email'} definition={{
            pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: t('constraints_email')
            }
        }} {...props} />
    );
}

export interface EmailFieldProps extends TextFieldProps {}

export default EmailField
