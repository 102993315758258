import React from 'react';
import {cssBorder} from '@ohoareau/css-utils';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Row from "../Row";
import {WithItems, module_image} from '../types';
import Img from "../nucleons/Img";
import Grid from "@material-ui/core/Grid";
import BulletPoint from "../molecules/BulletPoint";

const useStyles = makeStyles(theme => ({
    root: {
    },
    itemsContainer: {
        margin: theme.spacing(0, 10),
        ...cssBorder(theme, 'push'),
        [theme.breakpoints.down('md')]: {
            margin: theme.spacing(0, 3),
        },
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(0, 2),
        },
    },
    item: {
        alignSelf: 'stretch',
        [theme.breakpoints.down('sm')]: {
            marginBottom: 20,
        },
    },
}));

export function ItemsSpecs({image, items = []}: ItemsSpecsProps) {
    const classes = useStyles();
    return (
        <Grid className={classes.root} container item direction={'column'} lg={12} md={12} xs={12}>
            {image && <Img {...image} />}
            <Row rootClassName={classes.itemsContainer} padding={'medium'} justify={'space-evenly'}>
                {items && items.map((item, i) => (
                    <Grid item container className={classes.item} key={i} lg={3} md={items.length % 2 === 0 ? 6 : 3} sm={3} xs={12}>
                        <BulletPoint {...item} />
                    </Grid>
                ))}
            </Row>
        </Grid>
    );
}

export interface ItemsSpecsProps extends WithItems {
    image?: module_image
}

export default ItemsSpecs
