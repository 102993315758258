import React from 'react';
import {cssText} from '@ohoareau/css-utils';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {Modal} from '../../organisms';
import {useLuniiEventTracking, useLuniiTranslation} from '../../hooks';
import {buttonifyFromProps} from '../../hocs';
import clsx from 'clsx';
import {Trans} from 'react-i18next';
import WarningMessage from '../WarningMessage';

const useStyles = makeStyles(theme => ({
    root: {
    },
    body: {
        ...cssText(theme, 'standard', 'body', undefined, undefined, '#716C5E'),
        [theme.breakpoints.down('sm')]: {
            ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#716C5E'),
        },
    },
    list: {
        margin: 0,
        paddingLeft: 0,
        listStyleType: 'none',
        ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#908977'),
        [theme.breakpoints.down('sm')]: {
            ...cssText(theme, 'standard', 'caption', undefined, undefined, '#908977'),
        },
        '& strong': {
            ...cssText(theme, 'standard', 'body_thick', undefined, undefined, '#716C5E'),
            [theme.breakpoints.down('sm')]: {
                ...cssText(theme, 'standard', 'secondary_body_thick', undefined, undefined, '#716C5E'),

            },
        },
        '&>li': {
            margin: theme.spacing(2, 0),
            display: 'flex',
        },
    },
    tag: {
        ...cssText(theme, 'standard', 'body_thick', undefined, undefined, '#063646'),
        backgroundColor: '#C8E7F9',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 24,
        width: 24,
        flex: 'none',
        marginRight: theme.spacing(1.5),
    },
}));

export function ActivateCouponModal({className, open = false, onClose = () => {}, onConfirm = () => {}, loading = false, ...props}: ActivateCouponModalProps) {
    const classes = useStyles();
    const {t,i18n} = useLuniiTranslation();
    const handleTrackingEvent = useLuniiEventTracking();
    const handleOnActivationCouponClick = () => {
        handleTrackingEvent({
            categorie: 'interaction',
            action: 'abonnement',
            libelle: 'activer_abonnement_cadeau',
        });
        onConfirm();
    };
    const {Buttons} = buttonifyFromProps({
        buttonLabel: t('button_label_cancel'),
        buttonTarget: onClose,
        button2Label: t('my_subscription_modal_action_activate_coupon'),
        button2Target: handleOnActivationCouponClick,
    }, [
        'plain', 'primary',
    ]);
    const body = (
        <div className={classes.body}>
            {t('my_subscription_modal_activate_coupon_body_title')}
            <ul className={classes.list}>
                {[1, 2, 3].map((key: number) => (
                    <li key={key}>
                        <div className={classes.tag}>{key}</div>
                        <div>
                            <Trans
                                i18n={i18n}
                                i18nKey={`my_subscription_modal_activate_coupon_body_${key}`}
                                components={{1: <strong />}}
                            />
                        </div>
                    </li>
                ))}
            </ul>
            <WarningMessage body={t('my_subscription_modal_activate_coupon_body_warning')} svg={'infos'}/>
        </div>
    );
    return (
        <>
            <Modal
                open={open}
                className={clsx(classes.root, className)}
                onCloseCallback={onClose}
                title={t('my_subscription_modal_activate_coupon_title')}
                body={body}
                loading={loading}
                actions={Buttons && <Buttons/>}/>
        </>
    );
}

export interface ActivateCouponModalProps {
    className?: string,
    open?: boolean,
    onClose?: any,
    onConfirm?: any,
    loading?: boolean
}

export default ActivateCouponModal;
