import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import {cssFont} from '@ohoareau/css-utils';
import {getThemeColor} from '../../../../configs';
import {button_theme} from '../../../types';
import {DynamicIcon} from '../../../ui';
import {Click} from '../..';

const useStyles = makeStyles((theme) => ({
    root: ({disabled, iconPosition, size}: any) => ({
        borderRadius: 24,
        border: 'none',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: disabled ? 'not-allowed' : 'pointer',
        outlineOffset: 0,
        textDecoration: 'none',
        transition: 'all 0.35s ease-out, all 0.35s ease-out, all 0.35s ease-out',
        '&:focus-visible': {
            outlineOffset: 4,
            outline: `2px solid ${getThemeColor('interactive', '800')}`,
        },
        '&:disabled': {
            outline: 'none',
            outlineOffset: 0,
        },
        ...size === 'medium' && {
            ...cssFont(theme, 'standard', 'button_1'),
        },
        ...size === 'small' && {
            ...cssFont(theme, 'standard', 'button_2'),
            textTransform: 'none',
        },
        ...iconPosition === 'right' && {
            flexDirection: 'row-reverse',
        },
        ...disabled && {
            '&:link, &:visited, &:hover, &:focus-visible': {
                outline: 'none',
                outlineOffset: 0,
            },
        },
    }),
    mediumRoot: ({text}: any) => ({
        height: 48,
        padding: text?.length ? '12px 24px 12px 24px' : '12px 20px 12px 20px',
    }),
    smallRoot: {
        height: 32,
        padding: '0px 16px 0px 16px',
    },
    primaryRoot: ({disabled}: any) => ({
        color: getThemeColor('brand-primary', '900'),
        backgroundColor: getThemeColor('brand-primary', '500'),
        '&:link, &:visited': {
            color: getThemeColor('brand-primary', '900'),
            backgroundColor: getThemeColor('brand-primary', '500'),
        },
        '&:hover': {
            backgroundColor: getThemeColor('brand-primary', '400'),
        },
        '&:active': {
            backgroundColor: getThemeColor('brand-primary', '600'),
        },
        '&:disabled': {
            color: getThemeColor('brand-primary', '600'),
            backgroundColor: getThemeColor('brand-primary', '200'),
        },
        ...disabled && {
            color: getThemeColor('brand-primary', '600'),
            backgroundColor: getThemeColor('brand-primary', '200'),
            '&:link, &:visited, &:focus-visible, &:active': {
                color: getThemeColor('brand-primary', '600'),
                backgroundColor: getThemeColor('brand-primary', '200'),
                outline: 'none',
                outlineOffset: 0,
            },
        },
    }),
    secondaryRoot: ({disabled}: any) => ({
        color: getThemeColor('interactive', '800'),
        backgroundColor: 'white',
        boxShadow: '0px 4px 16px 0px rgba(66, 63, 55, 0.10)',
        '&:link, &:visited': {
            color: getThemeColor('interactive', '800'),
            backgroundColor: 'white',
            boxShadow: '0px 4px 16px 0px rgba(66, 63, 55, 0.10)',
        },
        '&:hover': {
            color: getThemeColor('interactive', '700'),
        },
        '&:active': {
            color: getThemeColor('interactive', '900'),
        },
        '&:disabled': {
            color: getThemeColor('ui', '600'),
            backgroundColor: getThemeColor('ui', '300'),
        },
        ...disabled && {
            color: getThemeColor('ui', '600'),
            backgroundColor: getThemeColor('ui', '300'),
            '&:link, &:visited, &:focus-visible, &:active': {
                color: getThemeColor('ui', '600'),
                backgroundColor: getThemeColor('ui', '300'),
            },
        },
    }),
    tertiaryRoot: ({disabled}: any) => ({
        color: getThemeColor('interactive', '800'),
        backgroundColor: getThemeColor('interactive', '100'),
        '&:link, &:visited': {
            color: getThemeColor('interactive', '800'),
            backgroundColor: getThemeColor('interactive', '100'),
        },
        '&:hover': {
            backgroundColor: getThemeColor('interactive', '200'),
        },
        '&:active': {
            color: getThemeColor('interactive', '900'),
            backgroundColor: getThemeColor('interactive', '300'),
        },
        '&:disabled': {
            color: getThemeColor('ui', '600'),
            backgroundColor: getThemeColor('ui', '300'),
        },
        ...disabled && {
            color: getThemeColor('ui', '600'),
            backgroundColor: getThemeColor('ui', '300'),
            '&:link, &:visited, &:focus-visible, &:active': {
                color: getThemeColor('ui', '600'),
                backgroundColor: getThemeColor('ui', '300'),
            },
        },
    }),
    destructiveRoot: ({disabled}: any) => ({
        color: getThemeColor('danger', '500'),
        backgroundColor: 'white',
        boxShadow: ' 0px 4px 16px 0px rgba(66, 63, 55, 0.10)',
        '&:link, &:visited': {
            color: getThemeColor('danger', '500'),
            backgroundColor: 'white',
            boxShadow: ' 0px 4px 16px 0px rgba(66, 63, 55, 0.10)',
        },
        '&:hover': {
            color: getThemeColor('danger', '400'),
        },
        '&:active': {
            color: getThemeColor('danger', '600'),
        },
        '&:disabled': {
            color: getThemeColor('ui', '600'),
            backgroundColor: getThemeColor('ui', '300'),
        },
        ...disabled && {
            color: getThemeColor('ui', '600'),
            backgroundColor: getThemeColor('ui', '300'),
            '&:link, &:visited, &:focus-visible, &:active': {
                color: getThemeColor('ui', '600'),
                backgroundColor: getThemeColor('ui', '300'),
            },
        },
    }),
    icon: ({iconPosition, size, text}: any) => ({
        ...(text && iconPosition === 'left') && {
            marginRight: theme.spacing(size === 'medium' ? 1 : 0.5)
        },
        ...(text && iconPosition === 'right') && {
            marginLeft: theme.spacing(size === 'medium' ? 1 : 0.5)
        },
    }),
    '@keyframes rotate': {
        from: {
            transform: 'rotate(0deg)',
        },
        to: {
            transform: 'rotate(359deg)',
        },
    },
    loading: {
        animation: '$rotate .8s infinite linear',
        marginLeft: (props: any) => theme.spacing(props.size === 'medium' ? 1 : 0.5),
    }
}));

export function Button({className = '', text = undefined, theme = 'primary', size = 'medium', disabled = false, icon = undefined, iconPosition = 'left', url = undefined, loading = false, type = undefined}: ButtonProps) {
    const classes = useStyles({disabled, iconPosition, size, text});
    const width = size === 'medium' && 24 || 16;
    return (
        <Click url={url} className={clsx(className, classes.root, classes[`${theme}Root`], classes[`${size}Root`])} disabled={disabled || loading} type={type}>
            {icon?.length && <DynamicIcon className={classes.icon} type={`${icon}-${width}`} width={width} height={width}/> || false}
            {text}
            {loading && <DynamicIcon className={classes.loading} type={`loader-${width}`} width={width} height={width}/> || false}
        </Click>
    )
}

export interface ButtonProps {
    className?: string;
    text?: string;
    theme?: button_theme;
    size?: 'medium' | 'small';
    disabled?: boolean;
    icon?: string;
    iconPosition?: 'left' | 'right';
    url?: string | Function;
    loading?: boolean;
    type?: string;
}

export default Button;
