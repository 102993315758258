import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MosaicCardItem from './MosaicCardItem';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'space-between',
        },
    },
    containerMargin: {
        margin: theme.spacing(-1.5),
        [theme.breakpoints.only('md')]: {
            margin: theme.spacing(-1),
        },
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(-1),
        },
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(-0.5),
        },
    },
    containerMarginLarge: {
        margin: theme.spacing(-1.5),
    },
    item: {
        [theme.breakpoints.up('lg')]: {
            width: '15%',
            margin: theme.spacing(1.5),
        },
        [theme.breakpoints.only('md')]: {
            width: '23%',
            margin: theme.spacing(1.5, 1),
        },
        [theme.breakpoints.down('sm')]: {
            width: '45%',
            margin: theme.spacing(1.5, 0.5),
        },
    },
    itemLarge: {
        [theme.breakpoints.up('lg')]: {
            width: '23%',
            margin: theme.spacing(1.5),
        },
    },
}));

export function MosaicCardItems({items = [], mode = 'small', classNames}: MosaicCartItemsProps) {
    const classes = useStyles();
    return (
        <div>
            <div className={clsx(classes.container, classes.containerMargin, mode == 'large' && classes.containerMarginLarge)}>
                {items.map((item: any, i: number) => (
                    <div className={clsx(classes.item, mode == 'large' && classes.itemLarge, classNames && classNames.item)} key={item.id || i}>
                        <MosaicCardItem {...item} />
                    </div>
                ))}
            </div>
        </div>
    );
}

export interface MosaicCartItemsProps {
    items?: any[],
    mode?: string,
    classNames?: any,
}

export default MosaicCardItems;
