import * as React from 'react';
function SvgSend(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width={props.width || 24}
            height={props.height || 24}
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.717 5.615c-1.658.573-2.144 2.684-.904 3.924l1.269 1.269c.76.76 1.938.915 2.87.376l1.695-.982-.982 1.696a2.375 2.375 0 00.376 2.87l1.27 1.268c1.24 1.24 3.35.754 3.923-.904l3.43-9.926c.646-1.872-1.149-3.667-3.021-3.02L3.717 5.615zm.216 2.804a.792.792 0 01.301-1.308l9.926-3.429a.792.792 0 011.007 1.007l-3.43 9.926a.792.792 0 01-1.307.301L9.16 13.648a.792.792 0 01-.125-.957l3.957-6.834-6.835 3.956a.792.792 0 01-.956-.125L3.932 8.42zm1.237 6.25a.594.594 0 10-.84-.839l-1.979 1.98a.594.594 0 10.84.839l1.979-1.98zm-1.583-3.214a.594.594 0 010 .84l-.792.791a.594.594 0 11-.84-.84l.792-.79a.594.594 0 01.84 0zm3.34 5.566a.594.594 0 100-1.188.594.594 0 000 1.188z"
                fill="#716C5E"
            />
        </svg>
    );
}
export default SvgSend;
