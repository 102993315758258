import React from 'react';
import {PushLarge} from '../../../ui';

export function PushLargeRightPushModule(props: PushLargeRightPushModuleProps) {
    return (
        <PushLarge
            {...props}
            imagePosition="right"
        />
    );
}

export interface PushLargeRightPushModuleProps {}

export default PushLargeRightPushModule;
