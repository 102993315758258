import * as React from 'react';
function SvgUser(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width={props.width || 24}
            height={props.height || 24}
            {...props}
        >
            <path
                d="M17.09 19.423a9 9 0 112.973-3.424M8 10v1M16 10v1"
                stroke={props.color || 'currentColor'}
                strokeWidth={2}
                strokeLinecap="round"
            />
            <path
                d="M12.19 16.333l.96-.536a1 1 0 00-.062-1.777l-.294-.139a1 1 0 01-.567-.796L12 11"
                stroke={props.color || 'currentColor'}
                strokeWidth={1.5}
                strokeLinecap="round"
            />
        </svg>
    );
}
export default SvgUser;
