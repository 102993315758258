import React from 'react';
import {cssText} from '@ohoareau/css-utils';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {Modal} from '../../organisms';
import {useLuniiEventTracking, useLuniiTranslation, useLuniiUser} from '../../hooks';
import {buttonifyFromProps} from '../../hocs';
import clsx from 'clsx';
import {Trans} from 'react-i18next';
import {ErrorPanel} from '../../atoms';
import useSubscriptionCancel from '../../hooks/subscription/useSubscriptionCancel';

const useStyles = makeStyles((theme) => ({
    root: {},
    body: {
        ...cssText(theme, 'standard', 'body', undefined, undefined, '#716C5E'),
        [theme.breakpoints.down('sm')]: {
            ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#716C5E'),
        },
    },
    highlightBody: {
        ...cssText(theme, 'standard', 'body_thick', undefined, undefined, '#716C5E'),
        [theme.breakpoints.down('sm')]: {
            ...cssText(theme, 'standard', 'secondary_body_thick', undefined, undefined, '#716C5E'),
        },
    },
}));

export function CancelFromPastDueModal({
    className = '',
    open = false,
    onClose = () => {},
    onConfirm = () => {},
    renewalPlannedAt,
    productReference,
    locale,
    ...props
}: CancelFromPastDueModalProps) {
    const classes = useStyles();
    const {t, i18n} = useLuniiTranslation();
    const handleTrackingEvent = useLuniiEventTracking();
    const {user} = useLuniiUser();
    const [execute, {loading, error}] = useSubscriptionCancel({
        options: {
            onCompleted: () => {
                handleTrackingEvent({
                    categorie: 'abonnement',
                    action: 'annulation',
                    libelle: productReference === 'monthly_subscription' ? 'mensuel' : 'annuel',
                });
                onConfirm();
            },
        },
    });
    const handleAction = () => execute({variables: {data: {email: user?.email}}});
    const {Buttons} = buttonifyFromProps(
        {
            buttonLabel: t(`my_subscription_cancel_modal_negative`),
            buttonTarget: onClose,
            button2Label: t(`my_subscription_cancel_modal_positive`),
            button2Target: handleAction,
        },
        [`plain,disabled=${loading}`, `destructive,disabled=${loading}`],
    );
    const body = (
        <div className={classes.body}>
            <p>
                <Trans
                    i18n={i18n}
                    i18nKey='my_subscription_cancel_from_past_due_modal_body'
                    components={{'1': <div className={classes.highlightBody}/>}}
                />
            </p>
            {error && <ErrorPanel error={error} key='subscription' />}
        </div>
    );
    return (
        <Modal
            open={open}
            className={clsx(classes.root, className)}
            onCloseCallback={onClose}
            title={t('my_subscription_cancel_modal_title')}
            body={body}
            loading={loading}
            actions={Buttons && <Buttons />}
        />
    );
}

export interface CancelFromPastDueModalProps {
    className?: string;
    open?: boolean;
    onClose?: any;
    onConfirm?: any;
    renewalPlannedAt: any;
    productReference: string;
    locale: string;
}

export default CancelFromPastDueModal;
