import {useCallback} from 'react';
import {Stripe, StripeCardElement, StripeElements} from '@stripe/stripe-js';
import {useCreditCardAddSubmit, cart, credit_card_item, useLuniiSpa} from '../ui';

export interface IStripeBillingAddress {
    city: string;
    countryCode: string;
    address1: string;
    address2: string;
    zipCode: string;
}

export interface StripeCreateRegisteredPaymentCallback {
    (
        purchaseId: string,
        cart: cart,
        onError: Function,
        billingInfos: IStripeBillingAddress,
        cardInfos: credit_card_item,
    ): Promise<any>;
}
export interface StripeCreateUnregisteredPaymentCallback {
    (
        purchaseId: string,
        cart: cart,
        onError: Function,
        billingInfos: IStripeBillingAddress,
        cardOwner: string,
        cardRegister: boolean,
    ): Promise<any>;
}
export interface StripeValidatePaymentCallback {
    (purchaseId: string, cart: cart, payment: any, onError: Function): Promise<any>;
}
export function useStripePayment(
    createPayment: Function,
    stripe: Stripe | null,
    elements: StripeElements | null,
): Function[] {
    const [addCreditCard] = useCreditCardAddSubmit();

    const {navigate} = useLuniiSpa();

    const onSuccess = useCallback(
        async (confirmationHistoryState: {cart: cart; purchaseId: string}) => {
            await navigate('/confirmation', {state: confirmationHistoryState});
        },
        [navigate],
    );

    const useCreateRegisteredPayment = useCallback<StripeCreateRegisteredPaymentCallback>(
        async (
            purchaseId: string,
            cart: cart,
            onError: Function,
            billingInfos: IStripeBillingAddress,
            cardInfos: credit_card_item,
        ) =>
            // past this line the remote cart has been deleted and any action concerning the cart must be done using the local one
            createPayment({
                purchase: purchaseId,
                paymentMethod: {
                    id: cardInfos.id,
                    customerId: cardInfos.customerId,
                },
                locale: (cart || {}).country,
                paymentType: 'stripe',
            }),

        [createPayment],
    );

    const registerCard = async (cardOwner: string, card: any) => {
        if (!stripe) return null;
        const {error, token} = await stripe.createToken(card);
        if (error || !token) return null;
        return addCreditCard({
            stripeCardToken: token,
            name: cardOwner,
        });
    };

    const useCreateUnregisteredPayment = useCallback<StripeCreateUnregisteredPaymentCallback>(
        async (
            purchaseId: string,
            cart: cart,
            onError: Function,
            billingInfos: IStripeBillingAddress,
            cardOwner: string,
            cardRegister: boolean,
        ) => {
            if (!elements || !stripe) return;
            const card = elements.getElement('card') as StripeCardElement;

            if (!card) return;

            if (cardRegister) {
                try {
                    registerCard(cardOwner, card);
                } catch (e) {
                    // todo return possible error in confirmation state to display alert message in case of registerCard failure
                }
            }

            const {error, paymentMethod} = await stripe.createPaymentMethod({
                type: 'card', // type depends on payment type method
                card,
                billing_details: {
                    address: {
                        city: billingInfos.city,
                        country: billingInfos.countryCode,
                        line1: billingInfos.address1,
                        line2: billingInfos.address2,
                        postal_code: billingInfos.zipCode,
                    },
                    name: cardOwner,
                },
            });

            if (error || !paymentMethod) {
                onError(error);
                return;
            }

            // past this line the remote cart has been deleted and any action concerning the cart must be done using the local one
            return createPayment({
                purchase: purchaseId,
                paymentMethod: {
                    id: paymentMethod.id,
                },
                locale: (cart || {}).country,
                paymentType: 'stripe',
            });
        },
        [stripe, elements, createPayment],
    );

    const useValidatePayment = useCallback<StripeValidatePaymentCallback>(
        async (purchaseId: string, cart: cart, payment, onError: Function) => {
            if (
                stripe &&
                payment &&
                (payment.status === 'requires_source_action' ||
                    payment.status === 'requires_action')
            ) {
                const {error, paymentIntent} = await stripe.confirmCardPayment(
                    payment.clientSecret,
                ); // TODO: here, card type defines the type of `confirm` to use

                if (paymentIntent && paymentIntent.status === 'succeeded') {
                    const confirmationHistoryState = {
                        cart,
                        purchaseId,
                    };

                    await onSuccess(confirmationHistoryState);
                } else {
                    await onError(error);
                }
            } else if (payment) {
                const confirmationHistoryState = {
                    cart,
                    purchaseId,
                };

                await onSuccess(confirmationHistoryState);
            } else {
                // UNKNOWN STRIPE ERROR HANDLER
                // have to wait for stripe to notify lunii servers of purchase failure and destroy pending purchase so
                // that the user can start the purchase process aagin
                setTimeout(() => {
                    onError();
                }, 3500);
            }
        },
        [stripe, onSuccess],
    );

    return [useCreateUnregisteredPayment, useCreateRegisteredPayment, useValidatePayment];
}

export default useStripePayment;
