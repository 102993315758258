import {useLuniiQueryApi, useLuniiUser} from '../../../ui/hooks';

export function useUserWishlist({locale = undefined}: {locale?: string}) {
    const {user} = useLuniiUser();
    const {loading, error, data, ...infos} = useLuniiQueryApi('GET_USER_WISHLIST', {
        variables: {data: {locale}},
        skip: !user,
    });
    return {
        wishlist: data?.getUserWishlist?.items || [],
        loading,
        error: error || false,
        ...infos,
    };
}

export default useUserWishlist;
