import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {Container, ForceTheme} from '../../ui';
import {DefaultLayout} from './DefaultLayout';

const useStyles = makeStyles({
    container: {
        '& >*': {
            width: '100%',
            position: 'relative',
        },
    },
});

export function SpaLayout({children, theme = 'ui-50'}: any) {
    const classes = useStyles();
    return (
        <DefaultLayout>
            <ForceTheme theme={theme}>
                <Container
                    fullscreen
                    topPadding={8}
                    className={classes.container}
                >
                    {children || ''}
                </Container>
            </ForceTheme>
        </DefaultLayout>
    );
}

export default SpaLayout;
