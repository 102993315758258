import React from 'react';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {Illustration} from '../../ui';

const useStyles = makeStyles({
    root: {
        flex: 1,
    },
});

export function ImagePanel({className, image}: any) {
    const classes = useStyles();
    return (
        <div className={clsx(classes.root, className)}>
            {image && <Illustration name={image} />}
        </div>
    );
}

export default ImagePanel;
