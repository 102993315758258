import React from 'react';
import {cssText} from '@ohoareau/css-utils';
import {buttonifyFromProps} from '../hocs/buttonify';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {Img} from '../nucleons';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    },
    overline: {
        ...cssText(theme, 'standard', 'overline_title_1'),
        [theme.breakpoints.down('sm')]: {
            ...cssText(theme, 'standard', 'overline_title_2'),
        },
    },
    title: {
        ...cssText(theme, 'standard', 'title_1'),
        margin: '5px 0',
        [theme.breakpoints.down('sm')]: {
            ...cssText(theme, 'standard', 'title_2'),
        },
    },
    image: {
        width: 64,
        height: 64,
        marginBottom: 20,
    },
    defaultImage: {
        backgroundColor: '#EFEDEA',
    },
}));

export function QuoteSlide({title, overline, image, noImage = false, className, ...props}: QuoteSlideProps) {
    const classes = useStyles();
    const {Buttons} = buttonifyFromProps(props, ['tertiary,endIcon=next']);
    return (
        <div className={clsx(classes.root, className)}>
            {!noImage && (
                <div className={clsx(classes.image, !image && classes.defaultImage)}>
                    {image && <Img {...image} />}
                </div>
            )}
            <div className={classes.overline}>{overline}</div>
            <div className={classes.title}>{title}</div>
            {Buttons && <Buttons />}
        </div>
    );
}

export interface QuoteSlideProps {
    noImage?: boolean,
    title?: any,
    overline?: any,
    image?: any,
    className?: string,
}

export default QuoteSlide;
