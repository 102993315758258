import {useLuniiCartModal} from '../hooks/useLuniiCartModal';
import {useLuniiNavigation} from '../hooks/useLuniiNavigation';
import {useScrollSections} from 'react-scroll-section';

export function convertFunction(name, vars: any = {}, ctx: any = {}) {
    const {open} = useLuniiCartModal();
    const scrollers = useScrollSections();
    const {goPageByModel, goSubscription, goSubscriptionGift, goUserHome, goCatalog} = useLuniiNavigation();

    let result = {
        type: 'action',
        onClick: () => {
            console.log(`ignored action: ${name}`);
        },
    };
    switch (name) {
        case 'add_to_cart':
            if (ctx?.product) {
                result = {
                    type: 'action',
                    onClick: async () => {
                        open(ctx?.product, vars, ctx?.page?.pageModel);
                    },
                };
            }
            break;
        case 'open_cookies_modal':
            result = {
                type: 'action',
                onClick: () => {
                    // @ts-ignore
                    window?.cookiehub?.openSettings();
                },
            };
            break;
        case 'go_to_subscription':
            result = {
                type: 'action',
                onClick: () => {
                    goSubscription && goSubscription(undefined, {selected: vars?.selected, next: vars?.next});
                },
            };
            break;
        case 'go_to_subscription_presentation':
            result = {
                type: 'action',
                onClick: () => {
                    goPageByModel && goPageByModel('abonnement_presentation');
                },
            };
            break;
        case 'go_to_subscription_gift':
            result = {
                type: 'action',
                onClick: () => {
                    goSubscriptionGift && goSubscriptionGift(undefined, {selected: vars?.selected});
                },
            };
            break;
        case 'go_to_my_account':
            result = {
                type: 'action',
                onClick: () => {
                    goUserHome && goUserHome();
                },
            };
            break;
        case 'go_to_product_page':
            result = {
                type: 'action',
                onClick: () => {
                    goCatalog && goCatalog(undefined, vars?.page_model, vars?.slug);
                },
            };
            break;
    }

     // 'scroll_to' scroll to the linked module section id
     // ex: 'scroll_to_product_list' will scroll to the 'product_list' section id module
    if (name?.indexOf('scroll_to_') === 0) {
        const [, target] = name.split('scroll_to_');
        result = {
            type: 'action',
            onClick: () => {
                scrollers?.find(({id}) => id === target)?.onClick();
            }
        };
    }

    return result;
}

export default convertFunction;
