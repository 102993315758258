import React from 'react';
import VerticalMenuItem from './VerticalMenuItem';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme=>({
    root: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        top: theme.spacing(2),
    },
}));

export function VerticalMenu({current, items = [], currentPageUid}: VerticalMenuProps) {
    const classes = useStyles();
    const n = items.length;
    return (
        <div className={classes.root}>
            {items.map((item, index) => (
                <VerticalMenuItem
                    key={index}
                    {...item}
                    index={index}
                    last={index === (n - 1)}
                    active={(
                        !!item.targetUid && !!currentPageUid && (currentPageUid === item.targetUid) ||
                        !!item.id && !!current && (current === item.id)
                    )}
                    currentPageUid={currentPageUid}
                />
            ))}
        </div>
    );
}

export interface VerticalMenuProps {
    items?: any[],
    current?: string,
    currentPageUid?: string,
}

export default VerticalMenu;
