import React from 'react';
import {module_image} from "../types";
import Fade from '@material-ui/core/Fade';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Img from "../nucleons/Img";

const useStyles = makeStyles({
    root: {
        position: 'relative',
        backgroundColor: '#F2FBFB',
    },
    absolute: {
        position: 'absolute',
        top: 0,
    },
});

export function DemoShowCase({image, image2}: DemoShowCaseProps) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            {image && <Img {...image} />}
            <Fade in={true} timeout={1000}>
                <div className={classes.absolute}>
                    {image2 && <Img {...image2} />}
                </div>
            </Fade>
        </div>
    );
}

export interface DemoShowCaseProps {
    image?: module_image,
    image2?: module_image,
}

export default DemoShowCase
