import React from 'react';
import {cssText} from '@ohoareau/css-utils';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {Modal} from '../../organisms';
import {useLuniiNavigation, useLuniiTranslation} from '../../hooks';
import {buttonifyFromProps} from '../../hocs';
import clsx from 'clsx';
import {Trans} from 'react-i18next';
import {Illustration} from '../../nucleons';

const useStyles = makeStyles(theme => ({
    root: {
    },
    body: {
        ...cssText(theme, 'standard', 'body', undefined, undefined, '#5F6769'),
        [theme.breakpoints.down('sm')]: {
            ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#5F6769'),
        },
    },
    highlight: {
        fontWeight: 800,
    },
    illustration: {
        width: '60%',
        maxWidth: 300,
        margin: theme.spacing(3, 'auto'),
        [theme.breakpoints.down('sm')]: {
            width: '80%',
        },
    },
}));

export function ActivateCouponSuccessModal({className, open = false, onClose = () => {}, loading = false, data, ...props}: ActivatCouponSuccessModalProps) {
    const classes = useStyles();
    const {t,i18n} = useLuniiTranslation();
    const {goCatalog} = useLuniiNavigation();
    const body = (
        <div className={classes.body}>
            <Trans
                i18n={i18n}
                i18nKey={`my_subscription_activate_success_modal_body`}
                values={{amount: 1, duration: data?.numberOfMonths}}
                components={{1: <span className={classes.highlight} />}}
            />
            <div className={classes.illustration}>
                <Illustration name={'my_subscription_activate_success_modal'} mt={0} />
            </div>
        </div>
    );
    const {Buttons} = buttonifyFromProps(
        {
            buttonLabel: t('my_subscription_activate_success_modal_button_subscription'),
            buttonTarget: onClose,
            button2Label: t('my_subscription_activate_success_modal_button_catalogue'),
            button2Target: () => goCatalog && goCatalog(),
        },
        ['plain', 'primary'],
    );
    return (
        <>
            <Modal
                open={open}
                className={clsx(classes.root, className)}
                onCloseCallback={onClose}
                title={t('my_subscription_activate_success_modal_heading')}
                body={body}
                loading={loading}
                actions={Buttons && <Buttons/>}
            />
        </>
    );
}

export interface ActivatCouponSuccessModalProps {
    className?: string,
    open?: boolean,
    onClose?: any,
    loading?: boolean
    data?: any
}

export default ActivateCouponSuccessModal;
