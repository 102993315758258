import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {Trans} from 'react-i18next';
import {cssFont, cssText} from '@ohoareau/css-utils';
import {buttonifyFromProps, Modal, useLuniiTranslation} from '../../ui';

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: 542,
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
        },
    },
    title: {
        display: 'block',
        ...cssText(theme, 'standard', 'title_1', undefined, undefined, '#2C3637'),
        [theme.breakpoints.down('sm')]: {
            ...cssFont(theme, 'standard', 'title_2'),
        },
    },
    body: {
        ...cssText(theme, 'standard', 'body', undefined, undefined, '#5F6769'),
        padding: theme.spacing(0, 4),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0, 2),
            marginBottom: theme.spacing(4),
        },
    },
}));

export function ProductCustomBookModal({open = false, onCloseCallback = () => {}, target}: ProductCustomBookModalProps) {
    const classes = useStyles();
    const {i18n, t} = useLuniiTranslation();
    const {Buttons} = buttonifyFromProps({
        buttonLabel: t('my_library_name_replace_redirect'),
        buttonTarget: target,
        buttonType: 'tertiary',
        button2Label: t('my_library_error_no_product_confirm'),
        button2Target: onCloseCallback,
        button2Type: 'primary',
    });
    return (
        <Modal
            open={open}
            onCloseCallback={onCloseCallback}
            bodyClassName={classes.body}
            className={classes.root}
            title={<div className={classes.title}>{t('my_library_name_replace_title')}</div>}
            body={<div><Trans i18nKey='my_library_name_replace_body' i18n={i18n}/></div>}
            actions={Buttons && <Buttons/>}
        />
    );
}

export interface ProductCustomBookModalProps {
    open?: boolean;
    onCloseCallback?: () => void;
    target: string|undefined;
}

export default ProductCustomBookModal;
