import React, {useCallback} from 'react';
import {cssText} from '@ohoareau/css-utils';
import Clickable from '../Clickable';
import Collapse from '@material-ui/core/Collapse';
import clsx from 'clsx';
import SvgArrowDown from '../images/icons/ArrowDown';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {useLuniiEventTracking, useLuniiPage} from '../hooks';
import {mapPrismicTarget} from '../../utils';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: 'unset',
        borderBottom: '1px solid #E3E1DB',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        alignSelf: 'flex-start',
        padding: theme.spacing(2, 0),
        width: '100%',
        '&:last-child': {
            borderBottom: 'none',
        },
    },
    parentItem: {
        ...cssText(theme, 'standard', 'title_2'),
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        padding: theme.spacing(1/2,2),
        '& a:-webkit-any-link': {
            color: 'unset',
        },
        '&:active, &:hover, &:selected': {
            color: '#423F37',
        },
        color: '#423F37',
    },
    arrowIcon: {
        marginLeft: 8,
        transition: 'transform .2s',
    },
    expandedArrowIcon: {
        transform: 'rotate(-180deg)',
    },
    collapse: {
        margin: theme.spacing(1,0,0,0),
        width: '100%',
    },
    childItem: {
        padding: theme.spacing(1/2,2),
        margin: theme.spacing(1/2,0),
        ...cssText(theme, 'standard', 'body'),
        color: '#716C5E',
    },
    clickable: {
        '& a:-webkit-any-link': {
            color: 'unset',

        },
        '&:active, &:hover, &:selected': {
            color: '#896717',
        },
        color: '#716C5E',
    },
    activeItem: {
        margin: theme.spacing(-0.5,0,-0.5,0),
        borderRadius: theme.spacing(5.5),
        backgroundColor: '#FEECC3',
        color: '#503C0D',
        '& a': {
            color: '#503C0D',
        },
    },
    activeSubmenu: {
        ...cssText(theme, 'standard', 'body'),
        backgroundColor: '#FEECC3',
        fontWeight: 700,
        borderRadius: theme.spacing(1),
        '& a': {
            color: '#896717',
        },
    },
}));

export function VerticalMenuItem({index, label, target, items = [], active, currentPageUid}: VerticalMenuItemProps) {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);
    const handleClick = useCallback(() => {
        setExpanded(!expanded);
    }, [expanded, setExpanded]);
    const itemsLength = items.length;
    const handleEventTracking = useLuniiEventTracking();
    const {locale} = useLuniiPage() || {};
    const t = mapPrismicTarget(target, locale);
    const handleMenuItemClick = useCallback((item: any) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        handleEventTracking({categorie: 'navigation', action: 'menu', libelle: item.target || item.label});
    }, [handleEventTracking]);
    const submenuItemActive = items.filter(({targetUid}) => !!targetUid && !!currentPageUid && (currentPageUid === targetUid));
    return (
        <div className={classes.root}>
            <Clickable
                className={clsx(classes.parentItem, (active || (!!items && submenuItemActive.length > 0)) && classes.activeItem)}
                onClick={itemsLength > 0 ? handleClick : t}
                onClickCb={handleMenuItemClick({label, target: t})}
            >
                <div>{label || `Item #${index}`}</div>
                {itemsLength > 0 && <SvgArrowDown className={clsx(classes.arrowIcon, expanded && classes.expandedArrowIcon)} width={24} height={24}/>}
            </Clickable>
            {itemsLength > 0 &&
                <Collapse className={classes.collapse} in={expanded} timeout='auto' unmountOnExit>
                    {items.map(( {label, target, targetUid}, index) => {
                        const itemT = mapPrismicTarget(target, locale);
                        return (
                            <div className={clsx(classes.childItem, (!!targetUid && !!currentPageUid && (currentPageUid === targetUid)) && classes.activeSubmenu)} key={index}>
                                <Clickable className={classes.clickable} onClick={itemT} onClickCb={handleMenuItemClick({label, target: itemT})}>
                                    {label}
                                </Clickable>
                            </div>
                        )
                    })}
                </Collapse>
            }
        </div>
    );
}

export interface VerticalMenuItemProps {
    index: number,
    label?: string,
    last?: boolean,
    first?: boolean,
    active?: boolean,
    target?: any,
    items?: any[],
    currentPageUid?: string,
}

export default VerticalMenuItem;
