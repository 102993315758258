import {useCallback} from 'react';
import useLuniiShoppingCartResetApi from './useLuniiShoppingCartResetApi';

export function useShoppingCartResetSubmit(callback: Function|undefined = undefined) {
    const [reset, {loading, error, ...infos}] = useLuniiShoppingCartResetApi();
    const onSubmit = useCallback(async values => {
        try {
            const data = await reset({
                variables: {
                    data: {
                        ...values,
                    },
                },
            });
            callback && callback(((data || {}).data || {}).resetCart);
        } catch (e) {
            console.error(e);
        }
    }, [reset, callback]);
    return [onSubmit, {loading: Boolean(loading), error: error || false, ...infos}] as [any, any];
}

export default useShoppingCartResetSubmit