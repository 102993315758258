import {useCallback} from 'react';
import useLuniiMutationApi from './useLuniiMutationApi';

export function useCreditCardAddSubmit(callback: Function|undefined = undefined) {
    const [addCreditCard, {loading, error, ...infos}] = useLuniiMutationApi('ADD_CREDITCARD');
    const onSubmit = useCallback(async ({stripeCardToken, name}) => {
        try {
            const data = await addCreditCard({
                variables: {
                    data: {
                        stripeCardToken: JSON.stringify(stripeCardToken),
                        name,
                    },
                },
            });
            const response = ((data || {}).data || {}).addCreditCard;
            callback && callback(response);
            return response;
        } catch (e) {
            console.error(e);
        }
    }, [addCreditCard, callback]);
    return [onSubmit, {loading: Boolean(loading), error: error || false, ...infos}] as [any, any];
}

export default useCreditCardAddSubmit;
