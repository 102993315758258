import React from 'react';
import NavBar, {NavBarProps} from './NavBar';
import Grid from '@material-ui/core/Grid';
import {Img} from '../nucleons/';
import HiddenCss from '@material-ui/core/Hidden/HiddenCss';
import HorizontalMenu from '../molecules/HorizontalMenu';
import Button from '../atoms/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {convertPrice} from '../utils/';
import useLuniiTranslation from '../hooks/useLuniiTranslation';
import {cssText} from '@ohoareau/css-utils';
import SvgShoppingBag from '../images/icons/ShoppingBag';
import {product} from '../types';

const useStyles = makeStyles(theme => ({
    center: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
    },
    image: {
        height: 58,
        display: 'flex',
    },
    priceContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    price: {
        ...cssText(theme, 'standard', 'body_thick'),
    },
    oldPrice: {
        ...cssText(theme, 'standard', 'body', undefined, undefined, '#A79F8E'),
        textDecoration: 'line-through',
        marginRight: 10,
    },
    textLine2: {
        ...cssText(theme, 'standard', 'secondary_body', 'right', undefined, 'body'),
    },
    texts: {
        marginRight: 24,
    },
    right: {
        flex: 1,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    menu: {
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    svgShoppingBag: {
        marginRight: theme.spacing(1),
    },
    outOfStock: {
        ...cssText(theme, 'standard', 'caption', 'right', undefined, 'status_alert'),
    },
}));

export function ProductNavBar({image, product, sections, section, onSectionChange, ...props}: ProductNavBarProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    return (
        <NavBar hideToolbarOnScroll {...props}>
            <Grid item container lg={3} md={6} xs={6}>
                <div className={classes.image}>
                    {image && <Img {...image} objectFit={'scale-down'} objectPosition={'left center'} />}
                </div>
            </Grid>
            <Grid item container className={classes.menu} lg={5}>
                <div className={classes.center}>
                    {sections && <HorizontalMenu variant={'level2'} current={section} items={sections} onSectionChange={onSectionChange} isTabBar={true}/>}
                </div>
            </Grid>
            <Grid item container lg={4} md={6} xs={6}>
                {product && (
                    <div className={classes.right}>
                        {product.stock &&
                        <>
                            <HiddenCss smDown>
                                <div className={classes.texts}>
                                    <div className={classes.priceContainer}>
                                        {product.oldPrice && <div className={classes.oldPrice}>{convertPrice(product.oldPrice, product.currency)}</div>}
                                        <div className={classes.price}>{convertPrice(product.price, product.currency) || ''}</div>
                                    </div>
                                    <div className={classes.textLine2}>{product.shippingInfos || t('productbar_textline2')}</div>
                                </div>
                            </HiddenCss>
                            <div>
                                <HiddenCss smDown>
                                    <Button size={'small'} color={'primary'} onClick={'https://lunii/add_to_cart'}><SvgShoppingBag className={classes.svgShoppingBag} />{t('productbar_buy_button_label')}</Button>
                                </HiddenCss>
                                <HiddenCss mdUp>
                                    <Button size={'small'} color={'primary'} onClick={'https://lunii/add_to_cart'}><SvgShoppingBag className={classes.svgShoppingBag} />{convertPrice(product.price, product.currency) || ''}</Button>
                                </HiddenCss>
                            </div>
                        </>}
                        {!product.stock && !product.notFound && <div className={classes.outOfStock}>{t('product_out_of_stock')}</div>}
                    </div>
                )}
            </Grid>
        </NavBar>
    );
}

export interface ProductNavBarProps extends NavBarProps {
    className?: string,
    image?: any,
    product?: product,
    section?: string,
    sections?: any[],
    onSectionChange?: any,
}

export default ProductNavBar;
