import React from 'react';
import {hardware_product} from '../../ui';

export function HardwareProduct({product}: HardwareProductProps) {
    return (
        <>
            HARDWARE - {product.id} - {product.name}
        </>
    );
}

export interface HardwareProductProps {
    product: hardware_product;
}

export default HardwareProduct;
