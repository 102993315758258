import React from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import useLuniiTranslation from '../hooks/useLuniiTranslation';
import {AddressForm} from '../molecules';
import {AddressTypeEnum, useLuniiUser} from '../';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
    },
}));

export function MyBillingAddress({className}: MyBillingAddressProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const {user} = useLuniiUser();
    return (
        <div className={clsx(classes.root, className)}>
            {user?.locale && <AddressForm
                key='billing'
                extraButton
                title={t('my_invoice_address_title')}
                locale={user?.locale}
                type={AddressTypeEnum.Billing}/>}
        </div>
    );
}

export interface MyBillingAddressProps {
    className?: string,
}

export default MyBillingAddress;
