export function formatTimeToMinutes(secs: number): string {
    const hr: number  = Math.floor(secs / 3600);
    const min: number = Math.floor((secs - (hr * 3600)) / 60);
    const sec: number = Math.floor(secs - (hr * 3600) -  (min * 60));
    const finalMin = min < 10 ? '0' + min : min;
    const finalSec = sec < 10 ? '0' + sec : sec;

    return finalMin + ':' + finalSec;
}

export default formatTimeToMinutes