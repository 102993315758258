import React from 'react';
import {Grid, makeStyles} from '@material-ui/core';
import {
    BodyText,
    ForceTheme,
    LargeTitleText,
    NavBarRootModeEnum,
    Row,
    useLuniiPage,
} from '../../ui';
import {DefaultLayout, PageAppBarModule} from '..';
import * as propertyMappers from '../../services/property-mappers';

const useStyles = makeStyles((theme) => ({
    content: {
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(8),
        },
    },
    center: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-end',
        '& >*': {
            width: '100%',
        },
        [theme.breakpoints.up('md')]: {
            marginTop: theme.spacing(8),
        },
    },
    left: {
        position: 'relative',
        '& >*': {
            width: '100%',
        },
        [theme.breakpoints.up('md')]: {
            position: 'sticky',
            top: 128,
        },
        [theme.breakpoints.down('md')]: {
            marginBottom: theme.spacing(4),
        },
    },
    leftTitle: {
        marginBottom: theme.spacing(3),
    },
}));

export function MyAccountLayout({
    children,
    title,
    text,
    theme = 'ui-50',
    secondaryTheme = 'default',
    leftComponent = undefined,
}: any) {
    const classes = useStyles();
    const {...p} = useLuniiPage();
    propertyMappers.menu((p as any).menu[p?.locale || ''], p, 'menu');
    const menuImage = ((p.menuImage as any) || {})[p?.locale || ''];
    return (
        <DefaultLayout>
            <ForceTheme theme={secondaryTheme}>
                <PageAppBarModule
                    menu={p?.menu || []}
                    menuImage={menuImage}
                    rootMode={NavBarRootModeEnum.My}
                />
            </ForceTheme>
            <ForceTheme theme={theme}>
                <Row
                    alignItems="flex-start"
                    justify="space-around"
                    className={classes.content}
                >
                    <Grid
                        item
                        container
                        lg={3}
                        md={4}
                        sm={12}
                        className={classes.left}
                        direction="column"
                    >
                        {!!title && (
                            <LargeTitleText className={classes.leftTitle as any}>
                                {title}
                            </LargeTitleText>
                        )}
                        {!!text && <BodyText>{text}</BodyText>}
                        {!!leftComponent && (
                            <ForceTheme theme={secondaryTheme}>{leftComponent}</ForceTheme>
                        )}
                    </Grid>
                    <Grid
                        item
                        container
                        lg={7}
                        md={7}
                        sm={12}
                        className={classes.center}
                        direction="column"
                    >
                        {children || ''}
                    </Grid>
                </Row>
            </ForceTheme>
        </DefaultLayout>
    );
}

export default MyAccountLayout;
