import {useLuniiQueryApi, useLuniiUser} from '../../../ui/hooks';

export function useUserDevices() {
    const {user} = useLuniiUser();
    const {loading, error, data, ...infos} = useLuniiQueryApi('GET_USER_DEVICES', {
        skip: !user,
    });
    return {
        devices: data?.getUserDevices?.items || [],
        loading,
        error: error || false,
        ...infos,
    };
}

export default useUserDevices;
