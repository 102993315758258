import React, {useState} from 'react';
import {cssText} from '@ohoareau/css-utils';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {Accordion, AccordionSummary, AccordionDetails} from '@material-ui/core';
import {darken} from '@material-ui/core/styles';
import clsx from 'clsx';
import {DynamicIcon, Clickable, useLuniiPage} from '..';

const useStyles = makeStyles(theme => ({
    menuItem: {
        marginBottom: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
    },
    menuTitle: {
        ...cssText(theme, 'standard', 'body_thick', undefined, undefined, 'title_1'),
        margin: theme.spacing(.5, 0),
    },
    childItem: {
        ...cssText(theme, 'standard', 'button_2', undefined, undefined, 'body'),
        cursor: 'pointer',
        margin: theme.spacing(.5, 0),
        textDecoration: 'none',
    },
    accordion: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        marginBottom: 0,
        '&:not(:first-child)': {
            borderColor: darken(theme['background_color_clear'], .1),
            borderTop: '1px solid',
        },
        '&:before': {
            display: 'none',
        },
        '&.Mui-expanded': {
            margin: 0,
        },
        '&:last-of-type': {
            marginBottom: theme.spacing(2),
        },
    },
    accordionSumary: {
        margin: 0,
        padding: '16px 0',
        minHeight: 36,
        ...cssText(theme, 'standard', 'title_1'),
        '&>*': {
            margin: 0,
            padding: 0,
        },
        '&.Mui-expanded': {
            margin: 0,
            padding: '16px 0',
            minHeight: 36,
            '&>*': {
                margin: 0,
                padding: 0,
            },
        },
        '.MuiAccordionSummary-expandIcon': {
            margin: 0,
            padding: 0,
            '&.Mui-expanded': {
                margin: 0,
                padding: 0,
                minHeight: 36,
            },
        },
    },
    accordionDetails: {
        display: 'flex',
        flexDirection: 'column',
        paddingRight: theme.spacing(2),
    },
}));

export function FooterMainMenuMobile({className = '', menu = []}: FooterMainMenuMobileProps) {
    const classes = useStyles();
    const {locale} = useLuniiPage() || {};
    const [expanded, setExpanded] = useState<string | false>(false);
    if (!menu.length) return null;
    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };
    const iconButtonProps = {disableRipple: true};
    return (
        <div className={clsx(className)}>
            {menu.map((menuItem: any, parentIndex: number) => {
                const excludedLocales = (menuItem.excludedLocales || '').split(',');
                if (excludedLocales.indexOf((locale || '').slice(-2)) !== -1) return false;
                return (
                    <Accordion
                        className={clsx(classes.menuItem)}
                        classes={{root: classes.accordion}}
                        expanded={expanded === `panel_footer_${parentIndex}`}
                        onChange={handleChange(`panel_footer_${parentIndex}`)}
                        key={`panel_footer_${parentIndex}`}
                    >
                        <AccordionSummary
                            className={classes.accordionSumary}
                            aria-controls={`panel_footer_${parentIndex}_content`}
                            id={`panel_footer_${parentIndex}_content`}
                            expandIcon={<DynamicIcon type={'ArrowDown'}/>}
                            IconButtonProps={iconButtonProps}
                        >
                            <div className={classes.menuTitle}>{menuItem.label}</div>
                        </AccordionSummary>
                        <AccordionDetails
                            className={classes.accordionDetails}
                        >
                            {(menuItem?.items || []).map((childItem: any) => {
                                const childExcludedLocales = (childItem.excludedLocales || '').split(',');
                                if (childExcludedLocales.indexOf((locale || '').slice(-2)) !== -1) return false;
                                return (
                                    <Clickable key={Math.random()} onClick={childItem.target}>
                                        <div className={classes.childItem}>
                                            {childItem.label}
                                        </div>
                                    </Clickable>
                                )
                            })}
                        </AccordionDetails>
                    </Accordion>
                )
            })}
        </div>
    );
}

export interface FooterMainMenuMobileProps {
    className?: string,
    menu?: any,
}

export default FooterMainMenuMobile;
