import React from 'react';
import {Login} from '../commons';
import {usePageTracking} from '../../../hooks';

export function LoginScreen() {
    usePageTracking(true, 'abonnement_connexion');
    return <Login onLoginSuccessPath="/" />;
}

export default LoginScreen;
