import React, {useCallback} from 'react';
import {Grid, GridProps, makeStyles} from '@material-ui/core';
import clsx from 'clsx';
import CatalogSearch from '../../molecules/CatalogSearch';
import {useLuniiNavigation, useLuniiTranslation, Row, RowProps} from '../../../ui';

const useStyles = makeStyles((theme) => ({
    root: {},
    container: {marginBottom: theme.spacing(8)},
}));

export function DefaultCatalogSearchModule({
    className = '',
    classNameContainer = '',
    RowProps = {},
    GridProps = {},
}: DefaultCatalogSearchModuleProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const {goCatalog} = useLuniiNavigation();

    const handleSearch = useCallback(
        (searchQuery) => {
            // warning, go to fah catalog by default, use second param to dynamically chose between fah or flam
            goCatalog && goCatalog(searchQuery);
        },
        [goCatalog],
    );

    return (
        <Row
            className={clsx(classes.root, className)}
            padding="none"
        >
            <Grid
                item
                container
                lg={12}
                md={12}
                xs={12}
                className={clsx(classes.container, classNameContainer)}
                {...GridProps}
            >
                <CatalogSearch
                    onDebounced={handleSearch}
                    onClear={handleSearch}
                    debouncedTime={500}
                    value=""
                    placeholder={t('catalog_search_textfield_placeholder')}
                />
            </Grid>
        </Row>
    );
}

export interface DefaultCatalogSearchModuleProps {
    className?: string;
    classNameContainer?: string;
    RowProps?: RowProps;
    GridProps?: GridProps;
}

export default DefaultCatalogSearchModule;
