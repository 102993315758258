import React from 'react';
import clsx from 'clsx';
import {cssText} from '@ohoareau/css-utils';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ContentItemMosaic2 from "./ContentItemMosaic2";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    head: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(4)
    },
    title: {
        ...cssText(theme, 'standard', 'title_1')
    },
    item: {
        alignSelf: 'stretch',
        marginBottom: theme.spacing(2)
    }
}));

export function MyHistoriesFactory({title, items, className}: MyHistoriesFactoryProps) {
    const classes = useStyles();

    return (
        <div className={clsx(classes.root, className)}>
            {title &&
                <div className={classes.head}>
                    <Typography variant={'h3'} className={classes.title}>{title}</Typography>
                </div>
            }
            {items && items.map((item, index) => (
                <Grid item container className={classes.item} lg={12} md={12} xs={12} key={index}>
                    <ContentItemMosaic2 {...item} />
                </Grid>
            ))}
        </div>
    );
}

export interface MyHistoriesFactoryProps {
    title?: string,
    items?: any[],
    className?: string,
}

export default MyHistoriesFactory
