import {useCallback} from 'react';
import {useLuniiMutationApi} from '../../../ui/hooks';
import {convertLangToCatalog} from '../../../utils';

export function usePostUserWishlist(callback: Function|undefined = undefined) {
    const [call, {loading, error, ...infos}] = useLuniiMutationApi('ADD_TO_USER_WISHLIST');
    const onSubmit = useCallback(async ({audiobookId = undefined, locale = undefined}) => {
        try {
            const data = await call({variables: {data: {audiobookId, locale: convertLangToCatalog(locale || '')}}});
            callback && callback(((data || {}).data || {}).addToUserWishlist);
        } catch (e) {
            console.error(`useUserAddToWishlist error: ${e}`);
        }
    }, [call, callback]);
    return [onSubmit, {loading: Boolean(loading), error: error || false, ...infos}] as [any, any];
}

export default usePostUserWishlist;
