import React from 'react';
import {DefaultLayout} from './DefaultLayout';
import {Container, ForceTheme, NavBarRootModeEnum, useLuniiPage} from '../../ui';
import {PageAppBarModule} from '../modules';
import * as propertyMappers from '../../services/property-mappers';

export function CheckoutLayout({
    children,
    theme = 'brand-secondary-50',
    secondaryTheme = 'default',
}: any) {
    const {...p} = useLuniiPage();
    propertyMappers.menu((p as any).menu[p?.locale || ''], p, 'menu');
    const menuImage = ((p.menuImage as any) || {})[p?.locale || ''];
    return (
        <DefaultLayout>
            <ForceTheme theme={secondaryTheme}>
                <PageAppBarModule
                    menu={p?.menu || []}
                    menuImage={menuImage}
                    rootMode={NavBarRootModeEnum.Checkout}
                />
            </ForceTheme>
            <ForceTheme theme={theme}>
                <Container
                    fullscreen
                    topPadding={16}
                >
                    {children || ''}
                </Container>
            </ForceTheme>
        </DefaultLayout>
    );
}

export default CheckoutLayout;
