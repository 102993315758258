import React from 'react';
import {RouteComponentProps} from '@reach/router';
import {useLuniiSpa} from '../ui';

export function Route({screen, className = ''}: RouteProps) {
    const {screens = {}} = useLuniiSpa();
    const Component = screens[screen || 'notFound'] || screens.notFound;
    return <Component className={className} />;
}

export interface RouteProps extends RouteComponentProps {
    screen: string;
    className?: string;
}

export default Route;
