import React, {useMemo, useState} from 'react';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {cssShadow} from '@ohoareau/css-utils';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useTheme} from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import {DynamicIcon} from '../../../../ui';

const useStyle = makeStyles((theme) => ({
    root: {},
    choices: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
        },
    },
    choiceWrapper: {
        position: 'relative',
        height: 'auto',
        width: '100%',
        '&:not(:first-child)': {
            margin: theme.spacing(2, 0, 0, 0),
        },
        [theme.breakpoints.up('sm')]: {
            '&:not(:first-child)': {
                margin: theme.spacing(0, 0, 0, 3),
            },
        },
    },
    choice: {
        display: 'block',
        position: 'relative',
        height: '100%',
        width: '100%',
        borderRadius: 16,
        ...cssShadow(theme, 'level_01'),
        background: '#fff',
        border: '2px solid #fff',
        transition: 'box-shadow .35s ease-out, background .35s ease-out, border .35s ease-out',
        [theme.breakpoints.up('sm')]: {
            '&:hover': {
                ...cssShadow(theme, 'level_02'),
            },
        },
        '&.active': {
            ...cssShadow(theme, 'level_02'),
            background: '#FFF9EC',
            border: '2px solid #FBBD2A',
        },
        '&>*': {
            cursor: 'pointer',
        },
    },
    checkmark: {
        pointerEvents: 'none',
        position: 'absolute',
        top: 'calc(50% - 12px)',
        right: 16,
        zIndex: 0,
        height: 24,
        width: 24,
        backgroundColor: '#fbbd2a',
        borderRadius: '100%',
        transition: 'transform .35s ease, opacity .35s ease',
        transform: 'scale(.77)',
        opacity: 0,

        [theme.breakpoints.up('sm')]: {
            top: 16,
            height: 32,
            width: 32,
        },
    },
    checkmarkActive: {
        opacity: 1,
        transform: 'scale(1)',
    },
    checkmarkIcon: {
        pointerEvents: 'none',
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: 16,
        height: 16,
        opacity: 0.5,
        transform: 'translate3d(-46%, -46%, 0) scale(.77)',
        transition: 'opacity .35s ease',

        [theme.breakpoints.up('sm')]: {
            height: 20,
            width: 20,
        },
    },
    checkmarkIconActive: {
        opacity: 1,
        transform: 'translate3d(-46%, -46%, 0) scale(1)',
    },
}));

export type PickerWithSubviewOption = {
    id: string;
    choice: {
        children: React.ReactNode;
    };
    view: {
        children: React.ReactNode;
    };
};

export interface PickerWithSubviewProps {
    options: PickerWithSubviewOption[];
    onChoice?: Function | null;
    className?: string;
    defaultSelectedId?: string;
}

export function PickerWithSubview({
    options = [],
    onChoice = null,
    className = '',
    defaultSelectedId = undefined,
}: PickerWithSubviewProps) {
    const classes = useStyle();
    const [selectedChoiceIndex, setSelectedChoiceIndex] = useState<number>(() =>
        defaultSelectedId !== undefined
            ? options.findIndex((el) => el.id === defaultSelectedId)
            : -1,
    );
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
    const {choices, views} = useMemo<{choices: any; views: any}>(
        () =>
            options.reduce<{choices: any[]; views: any[]}>(
                (accumulator, currentChoice) => {
                    const {id, choice, view} = currentChoice;
                    accumulator.choices.push({
                        ...choice,
                        id,
                    });
                    accumulator.views.push({
                        ...view,
                        id,
                    });
                    return accumulator;
                },
                {
                    choices: [],
                    views: [],
                },
            ),
        [options],
    );
    const handleChoiceClick = (idx: number) => {
        if (idx === selectedChoiceIndex) return;
        setSelectedChoiceIndex(idx);
        if (onChoice) {
            const methodId = options[idx].id;
            onChoice(methodId);
        }
    };
    return (
        <div className={clsx(classes.root, className)}>
            <div className={classes.choices}>
                {choices?.map((el: any, index: number) => {
                    const active = index === selectedChoiceIndex;
                    return (
                        <div
                            key={el.id}
                            className={classes.choiceWrapper}
                        >
                            <button
                                type="button"
                                onClick={() => handleChoiceClick(index)}
                                className={clsx(classes.choice, active && 'active')}
                            >
                                {el.children}
                                <div
                                    className={clsx(
                                        classes.checkmark,
                                        active && classes.checkmarkActive,
                                    )}
                                >
                                    <DynamicIcon
                                        className={clsx(
                                            classes.checkmarkIcon,
                                            active && classes.checkmarkIconActive,
                                        )}
                                        type="check"
                                        height={32}
                                        width={32}
                                    />
                                </div>
                            </button>
                            {isMobile && (
                                <Collapse in={active}>
                                    <div>{views[index].children}</div>
                                </Collapse>
                            )}
                        </div>
                    );
                })}
            </div>
            {!isMobile && (
                <div className="view">
                    <div className="options">
                        {selectedChoiceIndex > -1 && (
                            <div>{views[selectedChoiceIndex].children}</div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

export default PickerWithSubview;
