import React from 'react';

export function StandardTagsModule(props: StandardTagsModuleProps) {
    return (
        <>TODO TAGS (STANDARD)</>
    )
}

export interface StandardTagsModuleProps {
}

export default StandardTagsModule