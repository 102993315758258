import React from 'react';
import {Redirect, useLocation} from '@reach/router';
import {useLuniiUser} from '../ui';
import {Route, RouteProps} from './Route';

export function PrivateRoute({prefix, className = '', ...props}: PrivateRouteProps) {
    const {user} = useLuniiUser();
    const location = useLocation();
    const transferedLocationParams = `${location.search || ''}${location.hash || ''}`;
    return user ? (
        <Route
            className={className}
            {...props}
        />
    ) : (
        <Redirect
            noThrow
            to={`${prefix}/login${
                transferedLocationParams
                    ? `?transferedLocationParams=${encodeURIComponent(transferedLocationParams)}`
                    : ''
            }`}
        />
    );
}

export interface PrivateRouteProps extends RouteProps {
    prefix: string;
    className?: string;
}

export default PrivateRoute;
