import React, {useRef, useState} from 'react';
import 'swiper/swiper.min.css';
import {Controller, Navigation} from 'swiper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import QuoteSwiperImages from './QuoteSwiperImages';
import QuoteSwiperTexts from './QuoteSwiperTexts';
import SvgArrowLeft from '../images/icons/ArrowLeft';
import SvgArrowRight from '../images/icons/ArrowRight';
import {cssShadow} from '@ohoareau/css-utils';

const useStyles = makeStyles(theme => ({
    swiperWrapper: {
        width: '100%',
        flex: '1 0 auto',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
    },
    navigation: {
        position: 'absolute',
        top: '50%',
        left: 0,
        transform: 'translate3d(0, -50%, 0)',
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        pointerEvents: 'none',
        [theme.breakpoints.down('sm')]: {
            position: 'relative',
            transform: 'none',
        },
    },
    navButton: {
        pointerEvents: 'auto',
        cursor: 'pointer',
        padding: '4px 16px',
        borderRadius: 24,
        width: 50,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxSizing: 'border-box',
        backgroundColor: 'white',
        ...cssShadow(theme, 'level_02'),
    },
}));

export function QuoteSwiper({items = []}: QuoteSwiperProps) {
    const classes = useStyles();
    const [carouselText, setCarouselText] = useState(undefined);
    const [carouselImage, setCarouselImage] = useState(undefined);
    const prevEl = useRef(null);
    const nextEl = useRef(null);
    return (
        <>
            <div className={classes.swiperWrapper}>
                <QuoteSwiperImages
                    modules={[Controller, Navigation]}
                    controller={carouselText}
                    navLeftController={prevEl}
                    navRightController={nextEl}
                    items={items}
                    onSwiper={(swiper: any) => {setCarouselImage(swiper);}}/>
                <QuoteSwiperTexts
                    modules={[Controller]}
                    controller={carouselImage}
                    items={items}
                    onSwiper={(swiper: any) => {setCarouselText(swiper);}}/>
            </div>
            <div className={classes.navigation}>
                <div
                    className={classes.navButton}
                    ref={prevEl}>
                    <SvgArrowLeft/>
                </div>
                <div
                    className={classes.navButton}
                    ref={nextEl}>
                    <SvgArrowRight/>
                </div>
            </div>
        </>
    );
}

export interface QuoteSwiperProps {
    items?: any[],
}

export default QuoteSwiper;
