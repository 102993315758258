export function convertTimestampToDDMMYYYY(timestamp: number, timeOperations?: {day?: number, month?: number, year?: number}) {
    const date = new Date(timestamp);
    if (timeOperations) {
        if (timeOperations.day) {
            let day = date.getUTCDate();
            date.setUTCDate(day + timeOperations.day);
        }
        if (timeOperations.month) {
            let month = date.getUTCMonth();
            date.setUTCMonth(month + timeOperations.month);
        }
        if (timeOperations.year) {
            let year = date.getUTCFullYear();
            date.setUTCFullYear(year + timeOperations.year);
        }
    }
    const toTwoDigits = (num) => {
        const string = num.toString();
        return string.length > 1 ? string : '0' + string;
    };
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${toTwoDigits(day)}/${toTwoDigits(month)}/${year}`;
}

export function getCurrentDateElements() { // add other elements if necessary
    const currentDate = new Date();
    return {
        currentMonth: currentDate.getMonth() + 1,
        currentYear: currentDate.getFullYear(),
    };
}

const defaultLongDateOptions = {timeZone: 'UTC', year: 'numeric', month: 'long', day: '2-digit'};

/**
 * Format timestamp to pretty date in the user language: "23 mars 2021" fr-FR, "March 23rd, 2021" en-US. Works with
 * native options: https://developer.mozilla.org/fr/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
 *
 * @param timestamp
 * @param locale
 * @param options
 * @param timeOperations
 */
export function getLongDate(timestamp, locale: string|undefined = undefined, options?: Intl.DateTimeFormatOptions, timeOperations?: {day?: number, month?: number, year?: number}): string {
    const date = new Date(timestamp);
    if (timeOperations) {
        if (timeOperations.day) {
            let day = date.getUTCDate();
            date.setUTCDate(day + timeOperations.day);
        }
        if (timeOperations.month) {
            let month = date.getUTCMonth();
            date.setUTCMonth(month + timeOperations.month);
        }
        if (timeOperations.year) {
            let year = date.getUTCFullYear();
            date.setUTCFullYear(year + timeOperations.year);
        }
    }
    return new Intl.DateTimeFormat(locale, {
        ...defaultLongDateOptions as Intl.DateTimeFormatOptions,
        ...options,
    }).format(date);
}

export function getDaysBetweenDates(date1: number, date2: number) {
    return Math.floor((date2 - date1) / (1000 * 3600 * 24));
}

export default {
    convertTimestampToDDMMYYYY,
    getCurrentDateElements,
    getLongDate,
    getDaysBetweenDates,
};
