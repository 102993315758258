import * as React from 'react';
function SvgClose(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width={props.width || 24}
            height={props.height || 24}
            {...props}
        >
            <path
                fill="none"
                d="M0 0h24v24H0z"
            />
            <path
                d="M6 18L18 6M6 6l3 3M12 12l6 6"
                stroke={props.color || 'currentColor'}
                strokeWidth={2}
                strokeLinecap="round"
            />
        </svg>
    );
}
export default SvgClose;
