import React from 'react';
import {Link} from 'gatsby';
import {useOnClick} from '../../../ui';

export function Click({className = '', url, children, disabled = false, type = undefined}: ClickProps) {
    const target = useOnClick(url);
    const Component: any = target.href || target.to ? Link : 'button';
    return (
        <Component className={className} {...!disabled && target} disabled={disabled} type={type}>
            {children}
        </Component>
    );
}

export interface ClickProps {
    className?: string;
    url: any;
    children: any;
    disabled?: boolean;
    type?: string;
}

export default Click;
