import React from 'react';
import {PageMenu} from '../../../ui';

export function DefaultPageMenuModule({items, title, defaultItem, ...props}: any) {
    return (
        <PageMenu
            title={title}
            items={items}
            defaultItem={defaultItem}
            {...props}
        />
    );
}

export default DefaultPageMenuModule;
