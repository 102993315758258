import React, {createContext, ReactNode, useMemo} from 'react';
import createDefaultLuniiPaymentContextValue from '../utils/createDefaultLuniiPaymentContextValue';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js/pure'; // will delay loading the Stripe.js script until loadStripe is first called:

const LuniiPaymentContext = createContext(createDefaultLuniiPaymentContextValue());
const InternalLuniiPaymentProvider = LuniiPaymentContext.Provider;
export const LuniiPaymentConsumer = LuniiPaymentContext.Consumer;
LuniiPaymentContext.displayName = 'LuniiPaymentContext';
export default LuniiPaymentContext;

/**
 * Make sure to call `loadStripe` outside of a component’s render or to cache it to avoid
 * recreating the `Stripe` object on every render.
 *
 * @see https://stripe.com/docs/stripe-js/react
 */
const stripePromises: any = {};


export function LuniiPaymentProvider({stripe = undefined, locale = 'fr-fr', children}: LuniiPaymentProviderProps) {
    const stripePromise = stripe && (stripePromises[stripe.pk] || (stripePromises[stripe.pk] = loadStripe(stripe.pk)));
    const value = useMemo(() => {
        return {
            stripe,
            // add more payment provider here
        };
    }, [stripe]);
    const options = useMemo(() => ({
        fonts: [{
            cssSrc: 'https://fonts.googleapis.com/css?family=Nunito:wght@0,400;0,800;1,400;1,800&display=swap',
        }],
        locale: (locale || 'fr-fr').substr(0, 2) || 'fr',
    }), []);
    return (
        <InternalLuniiPaymentProvider value={value}>
            {stripePromise && (
                <Elements stripe={stripePromise} options={options}>
                    {children}
                </Elements>
            )}
            {!stripePromise && children}
        </InternalLuniiPaymentProvider>
    );
}

export interface LuniiPaymentProviderProps {
    stripe?: {
        pk: string,
    },
    locale: any,
    children?: ReactNode,
}
