import React from 'react';
import NavBar, {NavBarProps} from './NavBar';
import Grid from '@material-ui/core/Grid';
import {Img} from '../nucleons';
import HorizontalMenu from '../molecules/HorizontalMenu';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {module_image, module_target, product, WithSections} from '../types';
import SvgGooglestore from '../images/icons/Googleplay';
import SvgAppstore from '../images/icons/Appstore';
import parser from 'ua-parser-js';
import useTheme from '@material-ui/core/styles/useTheme';
import {useMediaQuery} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    center: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
    },
    image: {
        height: 58,
        display: 'flex',
    },
    menu: {
        [theme.breakpoints.down('md')]: {
            display: 'none',
        } ,
    },
    buttons: {
        justifyContent: 'flex-end', 
        '& a': {marginRight: theme.spacing(0.5)},
        '& a:first-child': {
            marginRight: theme.spacing(3), 
            [theme.breakpoints.down('sm')]: {marginRight: 'inherit'},
        },
    },
}));

export function ApplicationNavBar({image, product, sections, section, onSectionChange, buttonIosTarget, buttonAndroidTarget, ...props}: ApplicationNavBarProps) {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const ua = parser();

    return (
        <NavBar hideToolbarOnScroll {...props}>
            <Grid item container lg={3} md={6} xs={6}>
                <div className={classes.image}>
                    {image && <Img {...image} objectFit={'scale-down'} objectPosition={'left center'} />}
                </div>
            </Grid>
            <Grid item container className={classes.menu} lg={6}>
                <div className={classes.center}>
                    {sections && <HorizontalMenu variant={'level2'} current={section} items={sections} onSectionChange={onSectionChange} isTabBar={true}/>}
                </div>
            </Grid>
            <Grid item container lg={3} md={6} xs={6} className={classes.buttons}>
                {isMobile ?
                    <>
                        {ua?.os?.name === 'iOS' && !!buttonIosTarget && <a href={buttonIosTarget}><SvgAppstore/></a>}
                        {ua?.os?.name === 'Android' && !!buttonAndroidTarget && <a href={buttonAndroidTarget}><SvgGooglestore/></a>}
                    </>
                    :
                    <>
                        {!!buttonIosTarget && <a href={buttonIosTarget}><SvgAppstore/></a>}
                        {!!buttonAndroidTarget &&
                        <a href={buttonAndroidTarget}><SvgGooglestore/></a>}
                    </>
                }
            </Grid>
        </NavBar>
    );
}

export interface ApplicationNavBarProps extends NavBarProps, WithSections {
    className?: string,
    image?: module_image,
    product?: product,
    buttonIosTarget?: module_target,
    buttonAndroidTarget?: module_target,
}

export default ApplicationNavBar;
