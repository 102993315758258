import React from "react";
import {cssBox, cssText} from "@ohoareau/css-utils";
import buttonify from "../hocs/buttonify";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Row from "../Row";
import Grid from "@material-ui/core/Grid";
import {module_label, module_overline, module_text, module_title, module_subtitle} from "../types";

const useStyles = makeStyles(theme => ({
    root: {
        flex: 1,
        ...cssBox(theme, undefined, 'clear'),

    },
    title: {
        ...cssText(theme, 'standard', 'title_1', undefined),
        marginBottom: (props: ParagraphProps) => props.text && !props.label ? 16 : undefined,
    },
    subtitle: {
        ...cssText(theme, 'standard', 'title_1', undefined),
    },
    text: {
        ...cssText(theme, 'standard', 'text', undefined),
    },
    label: {
        ...cssText(theme, 'standard', 'title_2', undefined),
        marginBottom: (props: ParagraphProps) => props.text || props.buttons || props.buttonsComponent ? 16 : undefined,
    },
    overline: {
        ...cssText(theme, 'standard', `overline_title_1`, undefined),
    },
    buttons: {
        textAlign: undefined,
        marginTop: (props: ParagraphProps) => (props.buttonsComponent || (props.buttons && props.buttons.length)) ? 40 : 'unset',
    },
    button: {
        marginRight: (props: ParagraphProps) => (props.buttons && props.buttons.length > 1) ? 24 : 'unset',
    },
    footerText: {
        ...cssText(theme, 'standard', 'body', undefined),
        marginTop: 24,
    },
    texts: {
    },
}));

export function Paragraph({overline, title, label, subtitle, text, footerText, buttons = [], buttonsComponent: Buttons}: ParagraphProps) {
    const classes = useStyles({text, label, buttonsComponent: Buttons, buttons});
    return (
        <Row>
            <Grid item container>
                <div className={classes.root}>
                    <div className={classes.texts}>
                        {overline && <div className={classes.overline}>{overline}</div>}
                        {title && <div className={classes.title}>{title}</div>}
                        {label && <div className={classes.label}>{label}</div>}
                        {subtitle && <div className={classes.subtitle}>{subtitle}</div>}
                        {text && <div className={classes.text}>{text}</div>}
                    </div>
                    <div className={classes.buttons}>
                        {buttons.map((b, i) => {
                            const Button = buttonify(b.label, b.type, b.target, {type: 'color=primary'});
                            return Button
                                ? <Button className={classes.button} key={i} variant={b.variant || 'contained'} />
                                : undefined
                        }).filter(x => !!x)}
                        {Buttons && <Buttons />}
                    </div>
                    {footerText && (
                        <div className={classes.footerText}>
                            {footerText}
                        </div>
                    )}
                </div>
            </Grid>
        </Row>
    );
}

export interface ParagraphProps {
    overline?: module_overline,
    title?: module_title,
    subtitle?: module_subtitle,
    label?: module_label,
    text?: module_text,
    footerText?: module_text,
    buttons?: {
        label?: string,
        type?: 'primary' | 'secondary' | 'tertiary' | 'default',
        variant?: 'contained' | 'outlined' | 'text',
        target?: any,
    }[],
    buttonsComponent?: any,
}

export default Paragraph
