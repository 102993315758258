import React, {useMemo} from 'react';
import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';
import {cssBorder, cssText} from '@ohoareau/css-utils';
import {
    convertPrice,
    DynamicIcon,
    Illustration,
    ShadowedPanel,
    useLuniiTranslation,
} from '../../../../ui';
import {SingleFlapAccordion} from './SingleFlapAccordion';
import {SubscriptionGiftMetadataListDisplay} from './SubscriptionGiftMetadataListDisplay';
import {
    SubscriptionGiftMetadata,
    SubscriptionSubmitOptionsEnum,
} from '../../../../definitions/subscriptionGift';
import {Tag} from '../../../molecules/v2';

interface SubscriptionRecapCardProps {
    subscription?: any;
    submitMode?: SubscriptionSubmitOptionsEnum;
    metadata?: SubscriptionGiftMetadata;
    className?: string;
}

const useStyles = makeStyles<any, {hasExtra: boolean}>((theme) => ({
    root: {
        padding: theme.spacing(3, 4),
        ...cssBorder(theme, 'push'),
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'stretch',
            padding: theme.spacing(3, 2),
        },
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: 'white',
        alignItems: ({hasExtra}) => (hasExtra ? 'flex-start' : 'center'),
        justifyContent: 'space-between',
    },
    content: {
        fontSize: 10,
        [theme.breakpoints.up('sm')]: {
            marginRight: 24,
        },
        '& >:last-child': {
            marginBottom: 0,
        },
    },
    heading: {
        ...cssText(theme, 'standard', 'body_thick', undefined, undefined, '#423F37'),
        marginBottom: 8,
    },
    price: {
        ...cssText(theme, 'standard', 'title_2'),
        marginBottom: 8,
    },
    detail: {
        ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#2C3637'),
        display: 'flex',
        alignItems: 'center',
        marginBottom: 16,
        '&>*': {
            marginRight: 8,
        },
    },
    tag: {
        marginBottom: 16,
    },
    subscriptionGiftMetadata: {
        width: '100%',
        marginBottom: 0,
    },
    illustration: {
        margin: 0,
        height: '80px',
        maxHeight: '85px',
        width: 'auto',
        display: 'block',
        flexShrink: 0,
        '&>*': {
            width: '100%',
            height: '100%',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}));

export function SubscriptionGiftRecap({
  subscription = {},
  submitMode = undefined,
  metadata = undefined,
  className = '',
}: SubscriptionRecapCardProps) {
    const {t} = useLuniiTranslation();
    const hasMetadata = useMemo<boolean>(
        () => Object.values(metadata || {}).some((x) => !!x),
        [metadata],
    );
    const classes = useStyles({hasExtra: hasMetadata || !!submitMode});
    return (
        <ShadowedPanel className={clsx(classes.root, className)}>
            <div className={classes.container}>
                <div className={classes.content}>
                    <div className={classes.heading}>
                        {t('subscription_gift_offer_months_number', {months: subscription?.product?.metadata?.months})}
                    </div>
                    <div className={classes.price}>
                        {convertPrice(subscription?.product?.price, subscription?.product?.currency)}
                    </div>
                    <div className={classes.detail}>
                        <DynamicIcon
                            type='token'
                            height={18}
                            width={18}
                            color='#2C3637'
                        />{' '}
                        {subscription?.product?.description}
                    </div>
                    {submitMode && (
                        <Tag className={classes.tag} size='small' label={t(`subscripitongift_recap_${submitMode}`)}/>
                    ) || false}
                    {hasMetadata && metadata && submitMode === SubscriptionSubmitOptionsEnum.BY_EMAIL && (
                        <SingleFlapAccordion className={classes.subscriptionGiftMetadata}>
                            <SubscriptionGiftMetadataListDisplay metadata={metadata} />
                        </SingleFlapAccordion>
                    ) || false}
                </div>
                <div className={classes.illustration}>
                    <Illustration
                        name='subscription_gift'
                        mt={0}
                    />
                </div>
            </div>
        </ShadowedPanel>
    );
}

export default SubscriptionGiftRecap;
