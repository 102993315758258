import {useCallback, useEffect} from 'react';
import useLuniiLazyQueryApi from './useLuniiLazyQueryApi';

export function useLuniiRecommendations(callback: Function|undefined = undefined) {
    const useLuniiRecommendationsFindApi = (options: any = {}) => useLuniiLazyQueryApi('FIND_PRODUCT_RECOMMENDATIONS', options);
    const [findProductRecommendations, {data, loading, error, ...infos}] = useLuniiRecommendationsFindApi();
    const onSubmit = useCallback(async (productId, locale) => {
        try {
            await findProductRecommendations({
                variables: {
                    data: {
                        productId,
                        locale,
                    },
                },
            });
        } catch (e) {
            console.error(e);
        }
    }, [findProductRecommendations, callback]);
    useEffect(() => {
        if (data?.findProductRecommendations?.items && callback) {
            callback(data?.findProductRecommendations?.items);
        }
    }, [data]);
    return [onSubmit, {loading: Boolean(loading), error: error || false, ...infos}] as [any, any];
}

export default useLuniiRecommendations;
