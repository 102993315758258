import {tracking_context_value} from "../types";

export const createDefaultLuniiUserContextValue = (defaultValue: Array<any>|undefined = []): tracking_context_value =>
    ({
        dataLayer: defaultValue,
        env: {
            env_type: undefined,
            env_appareil: undefined,
            env_langue: undefined,
            env_pays: undefined
        }
    })
;

export default createDefaultLuniiUserContextValue
