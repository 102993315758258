import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {getThemeColor} from '../../../../configs';

const themeColors: any = {
    none: 'transparent',
    blue: getThemeColor('brand-secondary', '50'),
    'blue-old': '#f5fbfd',
    green: getThemeColor('positive', '50'),
    'green-old': '#f4fbfb',
    orange: getThemeColor('brand-flam', '50'),
    yellow: getThemeColor('brand-primary', '50'),
    'yellow-old': '#fef9ed',
}

const useStyles = makeStyles((theme) => ({
    root: ({color, heightDesktop, heightTablet, heightMobile} : any) => ({
        width: '100%',
        backgroundColor: themeColors[color],
        height: heightDesktop,
        [theme.breakpoints.down('md')]: {
            height: heightTablet,
        },
        [theme.breakpoints.down('sm')]: {
            height: heightMobile,
        },
    }),
}));

export function CustomizableSpacer({heightDesktop = 0, heightTablet = 0, heightMobile = 0, color = 'none'}: CustomizableSpacerProps) {
    const classes = useStyles({color, heightDesktop, heightTablet, heightMobile});
    return (
        <div className={classes.root}/>
    );
}

export interface CustomizableSpacerProps {
    heightDesktop?: number;
    heightTablet?: number;
    heightMobile?: number;
    color?: 'none' | 'blue' | 'blue-old' | 'green' | 'green-old' | 'orange' | 'yellow' | 'yellow-old';
}

export default CustomizableSpacer;