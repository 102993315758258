import makeStyles from '@material-ui/core/styles/makeStyles';
import {cssText} from '@ohoareau/css-utils';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import {Button, DynamicIcon} from '../../ui';

const useStyles = makeStyles((theme) => ({
    box: {
        position: 'relative',
    },
    icon: {
        position: 'absolute',
        left: 0,
    },
    title2: {
        ...cssText(theme, 'standard', 'title_2'),
        color: '#423F37',
    },
    text: {
        ...cssText(theme, 'standard', 'body', undefined, undefined, '#716C5E'),
    },
    action: {
        marginTop: theme.spacing(2),
    },
    link: {
        padding: 0,
    },
    download: {
        padding: theme.spacing(1, 2),
    },
}));

export function ConfirmationBox({title, text, icon, buttonLabel, buttonType, buttonTarget}: any) {
    const classes = useStyles();
    return (
        <Box
            pb={5}
            pl={5}
            className={classes.box}
        >
            <Typography
                className={classes.title2}
                component="h3"
            >
                <DynamicIcon
                    type={icon}
                    className={classes.icon}
                />
                {title}
            </Typography>
            <Typography className={classes.text}>{text}</Typography>
            {buttonLabel && buttonTarget && buttonType && (
                <Typography className={classes.action}>
                    <Button
                        color={buttonType}
                        onClick={buttonTarget}
                        className={classes.download}
                        size="small"
                    >
                        {buttonLabel}
                    </Button>
                </Typography>
            )}
        </Box>
    );
}

export default ConfirmationBox;
