import React from 'react';
import {Footer, useLuniiPage} from '../../../ui';

export function PageFooterModule(props: any) {
    const {language, languages} = (useLuniiPage() as any) || {};
    return (
        <Footer
            {...props}
            currentLanguage={language ? language.id : undefined}
            languages={languages || []}
        />
    );
}

export default PageFooterModule;
