import {useCallback} from 'react';
import useLuniiPurchasePaymentFreeCreateApi from './useLuniiPurchasePaymentFreeCreateApi';

export function usePurchasePaymentFreeCreateSubmit(callback: Function|undefined = undefined) {
    const [createPurchasePaymentFree, {loading, error, ...infos}] = useLuniiPurchasePaymentFreeCreateApi();
    const onSubmit = useCallback(async ({purchase}) => {
        try {
            const data = await createPurchasePaymentFree({
                variables: {
                    data: {
                        purchase,
                    }
                },
            });
            const payment = ((data || {}).data || {}).createPurchasePaymentFree;
            callback && callback(payment);
            return payment;
        } catch (e) {
            console.error(e);
        }
    }, [createPurchasePaymentFree, callback]);
    return [onSubmit, {loading: Boolean(loading), error: error || false, ...infos}] as [any, any];
}

export default usePurchasePaymentFreeCreateSubmit;
