import React from 'react';
import clsx from "clsx";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
    root: {
        marginTop: 16,
        marginBottom: 16,
    },
});

export function FieldSet({className = undefined, children}: any) {
    const classes = useStyles();
    return (
        <div className={clsx(className, classes.root)}>
            {children || ''}
        </div>
    );
}

export default FieldSet