import {useCallback, useMemo, useState} from 'react';
import {navigate} from 'gatsby';
import MuiLink from '@material-ui/core/Link';
import fetch from 'isomorphic-fetch';
import {window} from 'browser-monads';
import parser from 'ua-parser-js';
import {formatLng, getDefaultLocale, getRedirectLanguage, mergeCartItems, richText2react, sha256} from '../utils';
import createClient, {gql, jwtDecode, useLazyQuery, useMutation, useQuery} from '../ApolloClient';
import {decoded_token, page, storage, user} from '../types';
import themes from '../../configs/themes';
import {
    cart,
    i18nFactory,
    product,
    queries,
    sections_context_value,
    tracking_context_value,
    user_context_value,
} from '../ui';
import muiTheme from '../../configs/mui-theme';
import {gtm, storageKeys} from '../../configs/site';
import ModifiedGatsbyLink from '../components/ModifiedGatsbyLink';
import translations from '../../configs/translations';

export function matchWithGtmIso(isoCode: string): string {
    switch (isoCode) {
        case 'mc':
            return 'mo';
        case 'gb':
            return 'uk';
        default:
            return isoCode;
    }
}

export function matchWithGtmDevice(device: string): string {
    switch (device) {
        case 'tablet':
            return 'tablette';
        case 'mobile':
            return 'mobile';
        default:
            return 'ordinateur';
    }
}

export function matchLocaleWithCurrency(locale: string = ''): string {
    const countryCode = locale.split('-')[1];
    switch (countryCode) {
        case 'us':
            return 'USD';
        case 'ca':
            return 'CAD';
        default:
            return 'EUR';
    }
}

export function usePageContext(page: page) {
    const {theme = 'default', lang, product, sections: rawSections = [], pagesByModel} = page;
    const storage = useMemo<storage | undefined>(() => {
        const s = typeof localStorage === 'undefined' ? undefined : localStorage;
        if (!s) return undefined;
        return {
            setItem: (key: string, value: any) => {
                if (!value) return s.removeItem(key);
                return s.setItem(key, JSON.stringify(value));
            },
            getItem: (key: string) => {
                const v = s.getItem(key);
                return v && v !== 'undefined' ? JSON.parse(v) : undefined;
            },
            hasItem: (key: string) => s.hasItem(key),
            removeItem: (key: string) => s.removeItem(key),
        };
    }, []);
    const fetchUserFromLocalStorage = useCallback(
        () => storage?.getItem(storageKeys.user),
        [storage],
    );
    const fetchCartFromLocalStorage = useCallback(
        () => storage?.getItem(storageKeys.cart),
        [storage],
    );
    const fetchLocaleFromLocalStorage = useCallback(
        () => storage?.getItem(storageKeys.locale),
        [storage],
    );
    const [user, setUser] = useState<user | undefined>(fetchUserFromLocalStorage);
    const [cart, setCart] = useState<cart | undefined>(fetchCartFromLocalStorage);

    const locale = useMemo(() => {
        let value;
        switch (lang) {
            case '@cart':
                const c = (fetchCartFromLocalStorage() || {}).country;
                value = c && c.replace('_', '-').toLowerCase();
                break;
            case '@user':
                const l = (fetchUserFromLocalStorage() || {}).locale;
                value = l && l.replace('_', '-').toLowerCase();
                break;
            default:
                value = lang;
                break;
        }
        if (!value)
            value =
                fetchLocaleFromLocalStorage() ||
                getRedirectLanguage(typeof navigator !== 'undefined' ? navigator : {});
        storage?.setItem(storageKeys.locale, value);
        return value;
    }, [fetchCartFromLocalStorage, fetchLocaleFromLocalStorage, fetchUserFromLocalStorage, lang]);

    const enrichedSetCart = useCallback(
        (cart: cart) => {
            const localStorageCart = fetchCartFromLocalStorage();
            let mergedCart: cart;
            if (localStorageCart) {
                const {
                    items: oldItems,
                    price,
                    leftToPay,
                    discount,
                    productsDiscount,
                    giftCard,
                    ...oldCart
                }: cart = fetchCartFromLocalStorage();
                // extract leftToPay, discount && productsDiscount from the old cart object so they wont remain in cart if
                // newCart does not have these props
                const {items: newItems, ...newCart} = cart;
                const mergedItems = mergeCartItems(newItems, oldItems);
                mergedCart = {...oldCart, ...newCart, items: mergedItems};
            } else {
                mergedCart = cart;
            }
            storage?.setItem(storageKeys.cart, mergedCart);
            setCart(cart);
        },
        [storage, setCart, fetchCartFromLocalStorage],
    );

    const enrichedSetUser = useCallback(
        (user: any) => {
            storage?.setItem(storageKeys.user, user);
            setUser(user);
        },
        [storage, setUser],
    );

    const resetCart = useCallback(() => {
        storage?.removeItem(storageKeys.cart);
        setCart(undefined);
    }, [storage, setCart]);

    const getCurrentTokens = useCallback(() => {
        const u = fetchUserFromLocalStorage();
        return u
            ? {
                  token: u.token,
                  refreshToken: u.refreshToken,
              }
            : {};
    }, [fetchUserFromLocalStorage]) as any;

    const lunii: any = {client: undefined};

    const refreshUser = useCallback(async () => {
        const r = await lunii.client!.query({query: queries.GET_CURRENT_USER(gql)});
        if (!r || !r.data || !r.data.getCurrentUser)
            throw new Error('Unable to retrieve current user');
        const [hashedEmail, hashedUserId] = await Promise.all([
            sha256(r.data.getCurrentUser.email),
            sha256(r.data.getCurrentUser.id),
        ]);
        await enrichedSetUser({
            ...fetchUserFromLocalStorage(),
            ...r.data.getCurrentUser,
            hashedEmail,
            hashedUserId,
        });
    }, [lunii, enrichedSetUser, fetchUserFromLocalStorage, sha256]) as any;

    const refreshUserPartial = useCallback(
        async (
            {
                subscription,
                library,
                user,
            }: {subscription?: boolean; library?: boolean; user?: boolean} = {
                subscription: false,
                library: false,
                user: false,
            },
        ) => {
            const [userSubscription, userLibrary, userUser] = await Promise.all([
                subscription &&
                    lunii.client!.query({query: queries.RETRIEVE_USER_SUBSCRIPTION(gql)}),
                library && lunii.client!.query({query: queries.GET_USER_AUDIOBOOKS(gql)}),
                user && lunii.client!.query({query: queries.RETRIEVE_USER(gql)}),
            ]);
            await enrichedSetUser({
                ...fetchUserFromLocalStorage(),
                ...(subscription && {
                    subscription: userSubscription?.data?.retrieveUserSubscription,
                }),
                ...(library && {
                    ownedPackReferences: Object.values(userLibrary.data.getUserAudiobooks).map(
                        (pack: any) => pack.reference,
                    ),
                }),
                ...(user && {
                    ...userUser?.data?.retrieveUser,
                }),
            });
        },
        [lunii, enrichedSetUser, fetchUserFromLocalStorage],
    );

    const updateUserLocale = useCallback(
        async (locale: string) => {
            const r = await lunii.client!.mutate({
                mutation: queries.UPDATE_USER_LOCALE(gql),
                variables: {data: {locale}},
            });
            if (r) await refreshUserPartial({user: true});
        },
        [lunii, refreshUserPartial],
    );

    const setCurrentTokens = useCallback(
        async (tokens: any) => {
            // todo change token (remove addresses, locale...)
            const u = (await jwtDecode(tokens.token)) as decoded_token;
            await enrichedSetUser({...u, token: tokens.token, refreshToken: tokens.refreshToken});
            await refreshUser();
        },
        [enrichedSetUser, refreshUser],
    ) as any;

    const onLogout = useCallback(async () => {
        await enrichedSetUser(undefined);
    }, [enrichedSetUser]) as any;

    const luniiApiProviderValue: any = useMemo<{
        gql: Function;
        useMutation: Function;
        useQuery: Function;
        useLazyQuery: Function;
    }>(
        () => ({
            gql,
            useMutation,
            useQuery,
            useLazyQuery,
        }),
        [],
    );

    const luniiCartProviderValue: any = useMemo<{
        cart: cart | undefined;
        setCart: Function;
        resetCart: Function;
    }>(
        () => ({
            cart: fetchCartFromLocalStorage(),
            setCart: enrichedSetCart,
            resetCart,
        }),
        [cart, enrichedSetCart, resetCart],
    );

    const luniiUserProviderValue: any = useMemo<user_context_value>(
        () => ({
            user: fetchUserFromLocalStorage(),
            setUser: enrichedSetUser,
            getTokens: getCurrentTokens,
            setTokens: setCurrentTokens,
            logout: onLogout,
            refreshUser,
            refreshUserPartial,
            updateUserLocale,
        }),
        [
            fetchUserFromLocalStorage,
            user,
            enrichedSetUser,
            setCurrentTokens,
            getCurrentTokens,
            refreshUserPartial,
            onLogout,
            refreshUser,
            updateUserLocale,
        ],
    );

    const luniiProductProviderValue: product | undefined = product;
    const luniiTrackingProviderValue: any = useMemo<tracking_context_value>(() => {
        // @ts-ignore
        const ua = parser();
        const [language, country] = (locale || '').split('-');
        const device = matchWithGtmDevice(ua ? ua?.device?.type || 'desktop' : 'desktop');
        const currency = matchLocaleWithCurrency(locale);
        return {
            dataLayer: window?.dataLayer,
            env: {
                env_type: gtm.env,
                env_langue: language,
                env_pays: matchWithGtmIso(country),
                env_appareil: device,
                env_devise: currency,
                env_page: window?.document?.URL,
                env_titre: page?.head?.title || window?.document?.title || '',
            },
        };
    }, [window, locale, parser]);

    const luniiNavigationProviderValue: any = useMemo(
        () => ({
            InternalLink: ModifiedGatsbyLink,
            ExternalLink: MuiLink,
            homeLocation: `/${locale}`,
            goHome: (e: any) => {
                e && e.stopPropagation();
                navigate(`/${locale}/`);
            },
            goCheckout: (e: any) => {
                e && e.stopPropagation();
                navigate('/checkout');
            },
            goHomeRegister: (e:any, options?: {deviceReturnContext: boolean}) => {
                e && e.stopPropagation();
                navigate(`/my/login?mode=register${options?.deviceReturnContext ? '&device-return-context=true': ''}`);
            },
            goHomePassword: (e:any, options?: {deviceReturnContext: boolean}) => {
                e && e.stopPropagation();
                navigate(`/my/login?mode=forgot-password${options?.deviceReturnContext ? '&device-return-context=true': ''}`);
            },
            goHomeLogin: (e: any) => {
                e && e.stopPropagation();
                navigate('/my/login');
            },
            goUserHome: (e: any, vars?: any) => {
                e && e.stopPropagation();
                navigate(`/my${vars?.category ? `#${vars.category}` : ''}`);
            },
            goSubscription: (e: any, vars?: any) => {
                e && e.stopPropagation();
                navigate(`/subscription/${vars?.selected ? `?selected=${vars.selected}${vars?.next ? `&next=${vars.next}` : ''}` : ''}`);
            },
            goSubscriptionGift: (e: any, vars?: any) => {
                e && e.stopPropagation();
                navigate(
                    `/subscription-gift/${vars?.selected ? `?selected=${vars.selected}` : ''}`,
                );
            },
            goWishlist: (e: any) => {
                e && e.stopPropagation();
                navigate(`/my/wishlist`);
            },
            goPageByModel: (model: any, external: boolean = false) => {
                const defaultLocale = getDefaultLocale(locale);
                const url = `/${locale}/${pagesByModel?.[model]?.[defaultLocale] || ''}/`;
                if (external && typeof window !== 'undefined')
                    window.open(`${window.location.origin}${url}`, '_blank', 'noopener');
                else navigate(url);
            },
            goLibrary: (e: any, target: 'fah' | 'flam' | undefined = undefined) => {
                e && e.stopPropagation();
                navigate(`/my/library${target ? `?target=${target}` : ''}`);
            },
            goCatalog: (q: any, model: 'nos_histoires_luniistore' | 'catalogue_flam' = 'nos_histoires_luniistore', productSlug = undefined) => {
                const defaultLocale = getDefaultLocale(locale);
                const url = `/${locale}/${((pagesByModel || {})[model] || {})[defaultLocale] || ''}${productSlug ? `/${productSlug}` : ''}`;
                navigate(q && typeof q === 'string' ? `${url}?q=${q}` : url);
            },
            goSupport: (e: any, target: string = '') => {
                e && e.stopPropagation();
                let l: string;
                switch (locale) {
                    case 'en-us':
                    case 'es-us':
                    case 'en-ca':
                        l = 'en-us';
                        break;
                    case 'en-gb':
                    case 'nl-nl':
                        l = 'en-gb';
                        break;
                    case 'es-es':
                        l = 'es-es';
                        break;
                    case 'it-it':
                        l = 'it';
                        break;
                    case 'fr-ca':
                        l = 'fr-ca';
                        break;
                    default:
                        l = 'fr';
                        break;
                }
                window.open(`https://support.lunii.com/hc/${l}/${target}`, '_blank', 'noopener');
            },
            rootNavigate: (path: string | number, options) => navigate(`${path}`, options),
        }),
        [pagesByModel, navigate, locale, ModifiedGatsbyLink, MuiLink],
    ); // @todo catalog

    const refreshTokens = useCallback(async (refreshToken: string, client: {mutate: Function}) => {
        const r = await client.mutate({
            mutation: queries.REFRESH_LOGIN(gql),
            variables: {data: {refreshToken}},
        });
        if (!r || !r.data || !r.data.refreshAuthToken)
            throw new Error('Unable to refresh auth token');
        return {
            token: r.data.refreshAuthToken.token,
            refreshToken: r.data.refreshAuthToken.refreshToken,
        };
    }, []);

    lunii.client = useMemo(
        () =>
            createClient({
                fetch,
                getCurrentTokens,
                setCurrentTokens,
                refreshTokens,
                onLogout,
                onAuthError: onLogout,
                uri: process.env.GATSBY_API_ENDPOINT as string,
                timeout: 20000,
            }),
        [getCurrentTokens, setCurrentTokens, refreshTokens, onLogout],
    );

    const themeFactory = useCallback(
        (old: any = {}) => ({
            ...old,
            ...((themes as any)[theme] || {}),
        }),
        [themes, theme],
    );

    // use default locale, ex: fr-fr for fr-be (prismic deoptimization)
    const i18n = useMemo(
        () => i18nFactory({lng: formatLng(getDefaultLocale(locale)), resources: translations}),
        [i18nFactory, locale, translations],
    );

    // @ts-ignore
    const sections = useMemo<sections_context_value>(
        () => ({
            section: undefined,
            sections: (rawSections || []).map((s: any) => ({
                ...s,
                label: richText2react(s.label),
            })),
        }),
        [rawSections],
    );

    return {
        client: lunii.client,
        baseTheme: muiTheme,
        pageTheme: themeFactory,
        i18n,
        storage,
        locale,
        themes,
        sections,
        user: luniiUserProviderValue,
        api: luniiApiProviderValue,
        cart: luniiCartProviderValue,
        product: luniiProductProviderValue,
        navigation: luniiNavigationProviderValue,
        tracking: luniiTrackingProviderValue,
    };
}

export default usePageContext;
