import React, {createContext, useState, useCallback} from 'react';
import createDefaultLuniiCartModalContextValue from '../utils/createDefaultLuniiCartModalContextValue';
import AddCartModal from '../organisms/AddToCartModal';
import {product} from '../index';

const LuniiCartModalContext = createContext(createDefaultLuniiCartModalContextValue());

export function LuniiCartModalProvider(props) {
    const [isOpen, setIsOpen] = useState(false);
    const [product, setProduct] = useState<product | undefined>(undefined);
    const [vars, setVars] = useState<undefined | object>(undefined);
    const [pageModel, setPageModel] = useState<string | undefined>(undefined);
    const open = useCallback(
        (product: any, vars: any, pageModel: string) => {
            setIsOpen(true);
            setProduct(product);
            if (vars) setVars(vars);
            if (pageModel) setPageModel(pageModel);
        },
        [setIsOpen, setProduct],
    );

    const close = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    return (
        <LuniiCartModalContext.Provider value={{isOpen, open, close}} {...props}>
            {props.children}
            {isOpen && (
                <AddCartModal
                    isOpen={isOpen}
                    close={close}
                    product={product}
                    key={product?.reference}
                    pageModel={pageModel}
                    {...vars}
                />
            )}
        </LuniiCartModalContext.Provider>
    );
}

export const LuniiCartModalConsumer = LuniiCartModalContext.Consumer;
LuniiCartModalContext.displayName = 'LuniiCartModalContext';
export default LuniiCartModalContext;
