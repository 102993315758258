import React, {useCallback, useState} from 'react';
import {makeStyles, AppBar, Tab, Box} from '@material-ui/core';
import {TabPanel, TabContext, TabList} from '@material-ui/lab';
import {cssText} from "@ohoareau/css-utils";

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
});

const useBarStyles = makeStyles({
    root: {
        backgroundColor: 'transparent',
        boxShadow: 'none'
    }
});

const useTabsStyles = makeStyles({
    indicator: {
        height: '4px',
        borderRadius: 2,
        transition: 'opacity .35s ease-out',
        backgroundColor: '#FBBD2A',
    }
});

const useTabStyles = makeStyles((theme) => ({
    root: {
        textTransform: 'none',
        color: '#A79F8E',
        padding: 0,
        margin: theme.spacing(0, 1.5),
        '&:first-child': {
            marginLeft: 0,
        },
        '&:last-child': {
            marginRight: 0,
        },
        ...cssText(theme, 'standard', 'body_thick'),
        [theme.breakpoints.up('sm')]: {
            minWidth: 'auto',
        },
    },
    selected: {
        backgroundColor: 'transparent',
        color: '#423F37',
        transition: 'color .35s ease-out',
    },
}));

export function TabView({defaultTab = '0', dense = false, tabs = [], onChange,  ...props}: TabViewProps) {
    const classes = useStyles();
    const tabClasses = useTabStyles();
    const tabsClasses = useTabsStyles();
    const barClasses = useBarStyles();
    const [tabActive, setTabActive] = useState<string>(defaultTab);
    const handleTabChange = useCallback((e: any, tab: any) => {
        setTabActive(tab);
        onChange && onChange(tab);
    }, [setTabActive, onChange]);
    return (
        <div className={classes.root}>
            <TabContext value={tabActive}>
                <AppBar classes={barClasses} position='static'>
                    <TabList variant={'scrollable'} classes={tabsClasses} onChange={handleTabChange}>
                        {tabs.map(({label}: any, index: number) => <Tab classes={tabClasses} disableRipple={true} key={index} label={label} value={`${index}`}/>)}
                    </TabList>
                </AppBar>
                {tabs.map(({content}: any, index: number) => (
                    <TabPanel value={`${index}`} key={index}>
                        <Box p={dense ? 1 : 3}>{content}</Box>
                    </TabPanel>
                ))}
            </TabContext>
        </div>
    );
};

export interface TabViewProps {
    dense?: boolean,
    defaultTab?: string,
    tabs?: any[],
    onChange?: Function
}

export default TabView;
