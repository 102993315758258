import React from 'react';
import {cssText} from '@ohoareau/css-utils';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Img, useLuniiTranslation, gender as gender_type} from '..';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    image: {
        width: 48,
        height: 48,
        borderRadius: '50%',
        marginRight: 16,
    },
    title: {
        ...cssText(theme, 'standard', 'body_thick', undefined, undefined, '#2C3637'),
        marginBottom: 4,
    },
    type: {
        ...cssText(theme, 'standard', 'caption', undefined, undefined, '#7C878B'),
    },
}));

export function AuthorCard({title = undefined, gender = undefined, image = undefined, type}: AuthorCardProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const genderTranslation = gender === 'NA' && 'male' || gender || 'male';
    return (
        <div className={classes.root}>
            {image && <div className={classes.image}>
                <Img {...image} />
            </div> || false}
            <div>
                {title && <div className={classes.title}>{title}</div> || false}
                <div className={classes.type}>{t(`product_${type}_${genderTranslation}`)}</div>
            </div>
        </div>
    );
}

export interface AuthorCardProps {
    image?: {
        url?: string,
    }
    title?: any,
    type: 'author' | 'teller' | 'translator',
    gender?: gender_type,
}

export default AuthorCard;
