import React, {useCallback, useState} from 'react';
import {cssText} from '@ohoareau/css-utils';
import Button from '../atoms/Button';
import SvgPlay from '../images/icons/Play';
import ReactPlayer from 'react-player';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Row from '../Row';
import Grid from '@material-ui/core/Grid';
import Img from '../nucleons/Img';
import {module_image, module_title, WithVideo} from '../types';
import useLuniiStorage from '../hooks/useLuniiStorage';
import sha1 from '../utils/sha1';
import useLuniiEventTracking from '../hooks/useLuniiEventTracking';

const useStyles = makeStyles(theme => ({
    root: {
        ...cssText(theme, 'standard', 'body'),
        paddingTop: 16,
        paddingBottom: 16,
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    top: {
        flex: 1,
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
    },
    title: {
        ...cssText(theme, 'standard', 'large_title'),
        marginBottom: 24,
        flex: 2,
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            ...cssText(theme, 'standard', 'title_2'),
            marginBottom: 16,
            flex: 3,
            textAlign: 'left',
        },
    },
    image: {
        flex: 1,
        '& img': {
            display: 'flex',
        },
    },
    imageLeft: {
        flex: 1,
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    bottom: {
        flex: 1,
        padding: 16,
        backgroundColor: 'white',
        borderRadius: 16,
        position: 'relative',
        boxShadow: '0px -2px 8px rgba(66, 63, 55, 0.02), 0px 4px 8px rgba(66, 63, 55, 0.1)',
        [theme.breakpoints.down('sm')]: {
            padding: 8,
            borderRadius: 4,
        },
    },
    inside: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        margin: 16,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            margin: 8,
        },
    },
    button: {
        borderRadius: '50%',
        padding: 24,
        [theme.breakpoints.down('sm')]: {
            padding: 16,
        },
    },
    playIcon: {
        width: 54,
        height: 54,
        [theme.breakpoints.down('sm')]: {
            width: 30,
            height: 30,
        },
    },
    video: {
        height: '100%',
        width: '100%',
    },
}));

export function VideoPlayer({title, image, url, placeholderImage}: VideoPlayerProps) {
    if (!url) return null;

    const classes = useStyles();
    const storage = useLuniiStorage();
    const handleEventTracking = useLuniiEventTracking();
    const [showVideo, setShowVideo] = useState(false);

    const playVideo = useCallback(async () => {
        setShowVideo(true);
        const shaUrl = await sha1(url);
        if(shaUrl) {
            const localstorageItem = 'lunii_played_videos';
            let alreadyPlayedVideos = storage?.getItem(localstorageItem); // todo do not use storage on libs-js
            if(!alreadyPlayedVideos) alreadyPlayedVideos = {};
            if(!alreadyPlayedVideos[shaUrl]) {
                handleEventTracking({categorie: 'interaction', action: 'video', libelle: 'lecture - '+url}); // TODO retrieve title ?
                alreadyPlayedVideos[shaUrl] = true;
                storage?.setItem(localstorageItem, alreadyPlayedVideos);
            }
        }
    }, [url, sha1, setShowVideo, storage, handleEventTracking]);

    const hideVideo = useCallback(async () => {
        setShowVideo(false);
    }, [setShowVideo]);

    return (
        <Row className={classes.root} contentPadding={'large'}>
            <Grid item container lg={12} md={12} xs={12} justifyContent={'center'}>
                <div className={classes.container}>
                    <div className={classes.top}>
                        <div className={classes.imageLeft}/>
                        <div className={classes.title}>{title}</div>
                        <div className={classes.image}>
                            <Img {...image} />
                        </div>
                    </div>
                    <div className={classes.bottom}>
                        <Img {...placeholderImage} />
                        <div className={classes.inside}>
                            {!showVideo && (
                                <Button className={classes.button} onClick={playVideo} color={'primary'}>
                                    <SvgPlay className={classes.playIcon} />
                                </Button>
                            )}
                            {showVideo &&
                            <div className={classes.video}>
                                <ReactPlayer url={url} width={'100%'} height={'100%'} playing={true} controls onEnded={hideVideo}/>
                            </div>}
                        </div>
                    </div>
                </div>
            </Grid>
        </Row>
    );
}

export interface VideoPlayerProps extends WithVideo {
    title?: module_title,
    image?: module_image,
    url?: string,
    placeholderImage?: module_image
}

export default VideoPlayer;
