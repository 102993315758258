import {useCallback} from 'react';
import useLuniiNewsletterSubscribeApi from './useLuniiNewsletterSubscribeApi';
import useLuniiPage from './useLuniiPage';
import useLuniiEventTracking from './useLuniiEventTracking';

export function useNewsletterSubscribeSubmit(callback: Function|undefined = undefined) {
    const [subscribe, {loading, error, ...infos}] = useLuniiNewsletterSubscribeApi();
    const page = useLuniiPage();
    const handleEventTracking = useLuniiEventTracking();
    const onSubmit = useCallback(async values => {
        try {
            const data = await subscribe({
                variables: {
                    data: {
                        firstName: values.yourFirstname,
                        email: values.email,
                        country: (page || {}).locale,
                    },
                },
            });
            handleEventTracking({categorie: 'interaction', action: 'inscription', libelle: 'newsletter'});
            callback && callback(((data || {}).data || {}).subscribeNewsletter);
        } catch (e) {
            console.error(e);
        }
    }, [subscribe, callback, handleEventTracking]);
    return [onSubmit, {loading: Boolean(loading), error: error || false, ...infos}] as [any, any];
}

export default useNewsletterSubscribeSubmit;
