import React, {useRef, useEffect, useState, useCallback, useMemo} from 'react';
import {WindowLocation, useLocation} from '@reach/router';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
    ElevatedPanel,
    MyBillingAddress,
    MyCodeAlbum,
    MyCreditCards,
    MyDeliveryAddress,
    MyDevices,
    MyGiftCardBalance,
    MyPurchaseHistory,
    MySubscription,
    MyUserInformations,
    useHasMounted,
    useLuniiPage,
    useLuniiTranslation,
} from '../../../ui';
import {MyMenu} from './components/MyMenu';
import {MyAccountLayout} from '../../layouts/MyAccountLayout';
import {usePageTracking} from '../../../hooks/usePageTracking';
import {availableForSubscription, excludeNorthAmerica} from '../../../../configs/site';

const useStyles = makeStyles((theme) => ({
    root: {},
    panel: {
        padding: theme.spacing(4, 2),
        marginBottom: theme.spacing(4),
        backgroundColor: 'white',
        borderRadius: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(4),
        },
    },
}));

// height of Navbar + Panel padding
const HEADER_OFFSET = 96;

/**
 * In this component we are not using refs directly on the components as it is
 * not possible to use the ref attribute on functionnal components as they have
 * no `instance`. See: https://reactjs.org/docs/refs-and-the-dom.html#accessing-refs
 */
export function HomeScreen() {
    usePageTracking(true, 'mon_compte');
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const mounted = useHasMounted();
    const location: WindowLocation = useLocation();
    const {...p} = useLuniiPage();

    const sections = useMemo(
        () => ({
            myInfosSection: {
                title: t('my_account_user_information_title'),
                subtitle: t('my_account_user_information_subtitle'),
                icon: 'user',
                id: 'user-infos',
            },
            myCheckoutSection: {
                title: t('my_account_billing_and_invoices_title'),
                subtitle: t('my_account_billing_and_invoices_subtitle'),
                icon: 'credit-card',
                id: 'billing-and-invoices',
            },
            myPurchaseHistorySection: {
                title: t('my_account_purchase_history_title'),
                subtitle: t('my_account_purchase_history_subtitle'),
                icon: 'file',
                id: 'purchase-history',
            },
            mySubscriptionSection: {
                title: t('my_account_subscription_title'),
                subtitle: t('my_account_subscription_subtitle'),
                icon: 'token',
                id: 'subscription',
            },
            myCodeAlbumSection: {
                title: t('my_code_album_title'),
                subtitle: t('my_code_album_subtitle'),
                icon: 'lock-closed',
                id: 'code-livre',
            },
            myGiftCardBalanceSection: {
                title: t('my_account_giftcard_balance_title'),
                subtitle: t('my_account_giftcard_balance_subtitle'),
                icon: 'gift',
                id: 'giftcards',
            },
            myDevicesSection: {
                title: t('my_account_devices_title'),
                subtitle: t('my_account_devices_subtitle'),
                icon: 'devices-24',
                id: 'devices',
            },
        }),
        [t],
    );
    const [selected, setSelected] = useState<string | undefined>(sections.myInfosSection.id);

    const [defaultCodeAlbumValue, setDefaultCodeAlbumValue] = useState<string | undefined>();
    const panelRefs = useRef<null | Map<string, any>>(null);
    const getPanelRefs = function () {
        if (!panelRefs.current) {
            panelRefs.current = new Map();
        }
        return panelRefs.current;
    };
    const refCallback = (id: string) => (node: HTMLElement | null) => {
        const refsMap = getPanelRefs();
        if (node) {
            refsMap.set(id, node);
        } else {
            refsMap.delete(id);
        }
    };

    const scrollTo = useCallback(
        (id: string) => {
            const refsMap = getPanelRefs();
            if (id && refsMap) {
                const element = refsMap.get(id);
                const elementPosition = element?.getBoundingClientRect().top;
                const currentScroll = document.documentElement.scrollTop;
                setSelected(id);
                location.hash = `#${id}`;
                // sync internal location hash to prevent use effect invocation
                window.scrollTo({
                    top: elementPosition + currentScroll - HEADER_OFFSET,
                    behavior: 'smooth',
                });
            }
        },
        [location],
    );

    const menu: IMenuItem[] = useMemo(
        () =>
            [
                sections.myInfosSection,
                availableForSubscription(p.locale) && sections.mySubscriptionSection,
                sections.myCheckoutSection,
                sections.myPurchaseHistorySection,
                sections.myCodeAlbumSection,
                ...[excludeNorthAmerica(p?.locale) && sections.myGiftCardBalanceSection],
                sections.myDevicesSection,
            ].filter((x) => !!x) as IMenuItem[],
        [p.locale, sections],
    );

    // Used to retrieve codeLivre in location;
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const codeLivreQueryParam = searchParams.get('code');
        if (codeLivreQueryParam) {
            setDefaultCodeAlbumValue(codeLivreQueryParam);
        }
    }, []);

    useEffect(() => {
        if (!mounted || !location?.hash) {
            return;
        }
        const sectionId = location?.hash?.slice(1);
        // Location.hash starts with #. As the ids don't, we're slicing them here.
        const refsMap = getPanelRefs();
        if (refsMap?.has(sectionId)) {
            const timeout = setTimeout(() => {
                scrollTo(sectionId);
            }, 500);
            // eslint-disable-next-line consistent-return
            return () => {
                clearTimeout(timeout);
            };
        }
    }, [location?.hash, menu, mounted, scrollTo]);

    return (
        <MyAccountLayout
            title={t('my_account_title')}
            leftComponent={
                <MyMenu
                    menu={menu}
                    selected={selected}
                    onMenuItemClick={scrollTo}
                />
            }
        >
            <ElevatedPanel
                className={classes.panel}
                id={sections.myInfosSection.id}
                ref={refCallback(sections.myInfosSection.id)}
                lower
            >
                <MyUserInformations title={sections.myInfosSection.title} />
            </ElevatedPanel>

            {(availableForSubscription(p.locale) && (
                <ElevatedPanel
                    id={sections.mySubscriptionSection.id}
                    ref={refCallback(sections.mySubscriptionSection.id)}
                    className={classes.panel}
                    lower
                >
                    <MySubscription />
                </ElevatedPanel>
            )) ||
                null}

            <ElevatedPanel
                id={sections.myCheckoutSection.id}
                ref={refCallback(sections.myCheckoutSection.id)}
                lower
                className={classes.panel}
            >
                <MyCreditCards title={t('my_account_creditcard_title')} />
            </ElevatedPanel>

            <ElevatedPanel
                className={classes.panel}
                lower
            >
                <MyDeliveryAddress />
            </ElevatedPanel>

            <ElevatedPanel
                className={classes.panel}
                lower
            >
                <MyBillingAddress />
            </ElevatedPanel>

            <ElevatedPanel
                id={sections.myPurchaseHistorySection.id}
                ref={refCallback(sections.myPurchaseHistorySection.id)}
                className={classes.panel}
                lower
            >
                <MyPurchaseHistory title={sections.myPurchaseHistorySection.title} />
            </ElevatedPanel>

            <ElevatedPanel
                id={sections.myCodeAlbumSection.id}
                ref={refCallback(sections.myCodeAlbumSection.id)}
                className={classes.panel}
                lower
            >
                <MyCodeAlbum
                    title={sections.myCodeAlbumSection.title}
                    validateOnMount
                    defaultCodeAlbumValue={defaultCodeAlbumValue}
                />
            </ElevatedPanel>

            {(excludeNorthAmerica(p?.locale) && (
                <ElevatedPanel
                    id={sections.myGiftCardBalanceSection.id}
                    ref={refCallback(sections.myGiftCardBalanceSection.id)}
                    className={classes.panel}
                    lower
                >
                    <MyGiftCardBalance />
                </ElevatedPanel>
            )) ||
                null}

            <ElevatedPanel
                id={sections.myDevicesSection.id}
                ref={refCallback(sections.myDevicesSection.id)}
                className={classes.panel}
                lower
            >
                <MyDevices title={sections.myDevicesSection.title} />
            </ElevatedPanel>
        </MyAccountLayout>
    );
}

HomeScreen.displayName = 'HomeScreen';

export default HomeScreen;

export interface IMenuItem {
    title: string;
    subtitle: string;
    icon: string;
    id: string;
}
