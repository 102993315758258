import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {cssText} from "@ohoareau/css-utils";
import {
    BulletPoint,
    convertPrice, digital_album_product,
    ECommerceCarousel,
    image_value,
    LuniiProductProvider,
    Row,
    useHasMounted,
    useLuniiNavigation,
    useLuniiPage,
    useLuniiTranslation,
    useLuniiUser,
    useShoppingCart
} from '../../../ui';
import {colors} from '../../../../configs';
import {Alert, ProductSubscriptionModal, Button, DeviceTag} from '../..';
import {multi_product_universe, tag_theme} from '../../..';
import {availableForSubscription} from '../../../../configs/site';

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: theme.spacing(6),
        position: 'relative',
        overflow: 'hidden',
        [theme.breakpoints.down('xs')]: {
            paddingTop: theme.spacing(5),
        },
        paddingBottom: 15,
    },
    grid: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        zIndex: 15,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    content: {
        flex: 1,
    },
    tag: {
        marginBottom: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(1),
        },
    },
    right: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        marginLeft: 22,
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
        },
    },
    title: {
        color: '#423F37',
        marginBottom: theme.spacing(1),
    },
    price: {
        color: (props: any) => colors.multiProductUniverse[props.universe][props.universe === 'default' && 'second' || 'main'],
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    reduction: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexWrap: 'wrap',
        margin: theme.spacing(.5, 0, .5, 1.5),
    },
    reductionPercentage: {
        ...cssText(theme, 'standard', 'button_3', undefined, undefined, '#7F1F08'),
        backgroundColor: '#F5E5E4',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 26,
        padding: theme.spacing(0, 1.5, 0, 1),
        clipPath: 'polygon(0 0, 0 100%, 86% 100%, 101% 50%, 86% 0%)',
        borderRadius: 4,
    },
    reductionOldPrice: {
        ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#7C878B'),
        textDecoration: 'line-through',
        marginLeft: 8,
        margin: '4px 0 2px 0',
    },
    text: {
        color: '#716C5E',
        margin: theme.spacing(2, 0),
    },

    actions: {
        width: '100%',
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(3),
        '&>*': {
            flex: 1,
        }
    },
    subscription: {
        width: '100%',
        marginTop: theme.spacing(3),
        '&>:first-child': {
            width: '100%',
        }
    },
    subscriptionActions: {
        width: '100%',
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1),
        '&>*': {
            flex: 1,
        }
    },
    itemsContainer: {
        width: '100%',
        display: 'grid',
        gridAutoFlow: 'row',
        gridTemplateColumns: '1fr 1fr',
        gap: theme.spacing(3, 2),
        marginTop: theme.spacing(3),
    },
    alert: {
        margin: theme.spacing(4, 0),
    },
}));

export function AudiobookProductOverviewSectionSpec({
   product,
   items = [],
   title = undefined,
   text = undefined,
   universe = 'default',
   image1 = undefined,
   image1Small = undefined,
   image2 = undefined,
   image2Small = undefined,
   image3 = undefined,
   image3Small = undefined,
   image4 = undefined,
   image4Small = undefined,
   alertTitle = undefined,
   alertDescription = undefined,
   alertTheme = undefined,
   alertClosable = undefined,
   alertIcon = undefined,
   alertImage = undefined,
   alertLinkLabel = undefined,
   alertLinkIcon = undefined,
   alertLinkIconPosition = undefined,
   alertLinkUrl = undefined,
   alertLink2Label = undefined,
   alertLink2Icon = undefined,
   alertLink2IconPosition = undefined,
   alertLink2Url = undefined,
   alertButtonLabel = undefined,
   alertButtonIcon = undefined,
   alertButtonIconPosition = undefined,
   alertButtonUrl = undefined,
}: AudiobookProductOverviewSectionSpecProps) {
    const {price, currency, name, reduction, oldPrice} = product || {};
    const classes = useStyles({universe});
    const {t} = useLuniiTranslation();
    const hasMounted = useHasMounted();
    const _title = title || name;
    const formattedImages = [
        { image: image1, image2: image1Small },
        { image: image2, image2: image2Small },
        { image: image3, image2: image3Small },
        { image: image4, image2: image4Small },
    ].filter(x => x?.image) as {image: image_value}[];
    const {...p} = useLuniiPage();
    const {goCheckout} = useLuniiNavigation();
    const {user, ownsPackReference} = useLuniiUser();
    const [openSubscriptionModal, setOpenSubscriptionModal] = useState<boolean>(false);
    const [{packInCart}] = useShoppingCart();
    const subscriptionBalance = user?.subscription?.wallet?.balance || 0;
    const addToCartProps: any = {
        text: `${t('product_add_to_cart')} ${price === 0 || price === undefined ? ` - ${t('product_price_free')}` : convertPrice(price, currency)}`,
        url: `https://lunii/add_to_cart?forceAdd=true&forcedQuantity=${1}`,
        icon: 'basket',
    };
    const inCartProps: any = {
        text: t(`cartmodal_go_cart_button_label`),
        url: goCheckout as any,
        theme: 'tertiary',
        icon: 'next',
        iconPosition: 'right',
    };
    const useCreditsProps: any = {
        text: t(`product_open_subscription_modal`, {
            credit: 1,
        }),
        url: () => setOpenSubscriptionModal(true),
        icon: 'token',
    };
    const isInCart = packInCart(product as any);
    const isInUserLibrary = ownsPackReference(product.reference);
    const showSubscription = user?.subscription && availableForSubscription(p?.locale) && product.price; // if free, do not show
    if (!hasMounted) return null;
    if (!product || product.type !== 'packs' || product.notFound) return null;
    return (
        <LuniiProductProvider value={product}>
            <Row className={classes.root} padding='none'>
                <Grid lg={12} md={12} xs={12} className={classes.grid}>
                    <ECommerceCarousel
                        universe={universe}
                        className={classes.content}
                        items={formattedImages}/>
                    <div className={clsx(classes.right, classes.content)}>
                        <DeviceTag className={classes.tag} size='medium' deviceTarget={product?.deviceTarget}/>
                        <div className={clsx('large_title', classes.title)}>{_title}</div>
                        <div className={clsx('title_1', classes.price)}>
                            {price === 0 ? t('product_price_free') : convertPrice(price, currency)}
                            {reduction && <span className={classes.reduction}>
                                <span className={clsx('secondary_body_thick', classes.reductionPercentage)}>{reduction}</span>
                                <span className={clsx('secondary_body', classes.reductionOldPrice)}>{convertPrice(oldPrice, currency)}</span>
                            </span>}
                        </div>
                        <div className={clsx('body', classes.text)}>{text}</div>
                        {/* basic case : not in user library, not in cart => add to cart button */}
                        {!isInUserLibrary && !isInCart && !showSubscription && (
                            <div className={classes.actions}>
                                <Button {...addToCartProps} disabled={isInCart}/>
                            </div>
                        ) || false}
                        {/* specific case : in cart => redirection to cart screen */}
                        {!isInUserLibrary && isInCart && !showSubscription && (
                            <div className={classes.actions}>
                                <Button {...inCartProps}/>
                            </div>
                        ) || false}
                        { /* specific case : in user library => owned message */}
                        {isInUserLibrary && <Alert
                            title={t('product_owned')}
                            description={t('custom_book_product_owned_description')}
                            theme='success'
                        /> || false}
                        { /* specific case : user has subscription => use credits button */ }
                        {!isInUserLibrary && showSubscription && (
                            <div className={classes.subscription}>
                                <Button {...useCreditsProps} disabled={subscriptionBalance <= 0}/>
                                <div className={classes.subscriptionActions}>
                                    {!isInCart && <Button {...addToCartProps} theme='secondary' disabled={isInCart}/> || false}
                                    {isInCart && <Button {...inCartProps}/> || false}
                                </div>
                            </div>
                        ) || false}
                        <ProductSubscriptionModal product={product} open={openSubscriptionModal} onClose={() => setOpenSubscriptionModal(false)}/>
                        {items.length > 0 && (
                            <div className={classes.itemsContainer}>
                                {items.map(item => (
                                    <BulletPoint size='small' {...item} key={`bullet-point-${Math.random()}`}/>
                                ))}
                            </div>
                        )}
                        {alertTitle || alertDescription && <Alert
                            className={classes.alert}
                            title={alertTitle}
                            description={alertDescription}
                            theme={alertTheme}
                            closable={alertClosable}
                            icon={alertIcon}
                            image={alertImage}
                            linkLabel={alertLinkLabel}
                            linkUrl={alertLinkUrl}
                            linkIcon={alertLinkIcon}
                            linkIconPosition={alertLinkIconPosition}
                            link2Label={alertLink2Label}
                            link2Url={alertLink2Url}
                            link2Icon={alertLink2Icon}
                            link2IconPosition={alertLink2IconPosition}
                            buttonLabel={alertButtonLabel}
                            buttonUrl={alertButtonUrl}
                            buttonIcon={alertButtonIcon}
                            buttonIconPosition={alertButtonIconPosition}
                        /> || false}
                    </div>
                </Grid>
            </Row>
        </LuniiProductProvider>
    );
}

export interface AudiobookProductOverviewSectionSpecProps {
    product: digital_album_product;
    itemsTitle?: any;
    title?: any;
    text?: any;
    items: {icon: string, title: string, text: string}[];
    universe : multi_product_universe;
    image1?: image_value;
    image1Small?: image_value;
    image2?: image_value;
    image2Small?: image_value;
    image3?: image_value;
    image3Small?: image_value;
    image4?: image_value;
    image4Small?: image_value;
    alertTitle?: any;
    alertDescription?: any;
    alertTheme?: tag_theme;
    alertClosable?: boolean;
    alertIcon?: string;
    alertImage?: image_value;
    alertLinkLabel?: any;
    alertLinkIcon?: string;
    alertLinkIconPosition?: 'left' | 'right';
    alertLinkUrl?: any;
    alertLink2Label?: any;
    alertLink2Icon?: string;
    alertLink2IconPosition?: 'left' | 'right';
    alertLink2Url?: any;
    alertButtonLabel?: any;
    alertButtonIcon?: string;
    alertButtonIconPosition?: 'left' | 'right';
    alertButtonUrl?: any;
}

export default AudiobookProductOverviewSectionSpec;
