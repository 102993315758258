import React, {useRef} from 'react';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {useForm} from 'react-hook-form';
import {Button, ErrorPanel, FieldSet, PasswordField} from '../atoms';
import useLuniiTranslation from '../hooks/useLuniiTranslation';
import usePasswordUpdateSubmit from '../hooks/usePasswordUpdateSubmit';
import {useLuniiUser} from '../hooks';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    fieldset: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        marginTop: 0,
    },
    button1: {
        width: '100%',
        marginTop: theme.spacing(2),
    },
    input: {
        minWidth: "70%",
    },
}));

export function PasswordUpdateForm({className = undefined, onPasswordUpdate, loginData}: PasswordUpdateFormProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const {user} = useLuniiUser();
    const {handleSubmit, register, errors, watch} = useForm();
    const [updatePassword, {loading, error}] = usePasswordUpdateSubmit(onPasswordUpdate);
    const newPassword = useRef<any>({});
    const handleUpdatePassword = async (data) => {
        if (loginData && loginData.email && loginData.password) {
            return await updatePassword({
                loggedIn: false,
                email: loginData.email,
                password: loginData.password,
                ...data
            });
        } else {
            return user && await updatePassword({email: user.email, ...data});
        }
    }
    const x = {errors, register};
    newPassword.current = watch('newPassword', '');
    return (
        <div className={clsx(classes.root, className)}>
            <form onSubmit={handleSubmit(handleUpdatePassword)} autoComplete={"off"}>
                <ErrorPanel error={error} group={'updatePassword'}/>
                <FieldSet className={classes.fieldset}>
                    {!loginData?.email && !loginData?.password &&
                        <PasswordField name={'password'} disabled={loading} className={classes.input} required autoFocus
                            {...x} fullWidth={true}/>}
                    <PasswordField name={'newPassword'} disabled={loading} className={classes.input} required {...x}
                        fullWidth={true} autoComplete={'new-password'}/>
                    <PasswordField name={'newPasswordConfirmation'} disabled={loading} className={classes.input} required
                        errors={errors} fullWidth={true} autoComplete={'new-password'}
                        inputRef={register({
                            validate: value => !!value && value === newPassword.current || t('forms_updatepassword_confirmation_not_match_error')
                        })}/>
                </FieldSet>
                <Button type={"submit"} disabled={loading} className={classes.button1} color={'primary'}>{t('forms_updatepassword_buttons_submit_label')}</Button>
            </form>
        </div>
    );
}

export interface PasswordUpdateFormProps {
    className?: string,
    onPasswordUpdate: Function,
    loginData?: {
        email: string,
        password: string,
    }
}

export default PasswordUpdateForm
