import * as React from 'react';
function SvgCalendar(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width={props.width || 24}
            height={props.height || 24}
            {...props}
        >
            <path
                fill="none"
                d="M0 0h24v24H0z"
            />
            <path
                clipRule="evenodd"
                d="M18 4a2 2 0 012 2v12a2 2 0 01-2 2H6a2 2 0 01-2-2V6a2 2 0 012-2h12z"
                stroke={props.color || 'currentColor'}
                strokeWidth={2}
                strokeLinecap="round"
            />
            <rect
                x={7}
                y={2}
                width={2}
                height={4}
                rx={1}
                fill={props.color || 'currentColor'}
            />
            <rect
                x={15}
                y={2}
                width={2}
                height={4}
                rx={1}
                fill={props.color || 'currentColor'}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 13a1 1 0 100-2 1 1 0 000 2zM12 17a1 1 0 100-2 1 1 0 000 2zM8 13a1 1 0 100-2 1 1 0 000 2zM8 17a1 1 0 100-2 1 1 0 000 2zM16 13a1 1 0 100-2 1 1 0 000 2zM16 17a1 1 0 100-2 1 1 0 000 2z"
                fill={props.color || 'currentColor'}
            />
            <path
                d="M4 8.245l16 .013"
                stroke={props.color || 'currentColor'}
                strokeWidth={1.5}
            />
        </svg>
    );
}
export default SvgCalendar;
