import {useContext, useMemo} from "react";
import {useScrollSections} from 'react-scroll-section';
import LuniiSectionsContext from "../contexts/LuniiSectionsContext";
import {sections_context_value} from "../types";

export const useLuniiPageSections = (): sections_context_value => {
    const detectedSections = useScrollSections() || [];
    const {sections = detectedSections || []} = useContext(LuniiSectionsContext);
    const section = useMemo(() => {
        return ((detectedSections).find(x => x.selected) || {}).id;
    }, [detectedSections]);
    const availableSections = useMemo(
        () => (sections as any[]).map(s => {
            const xx = detectedSections.find(x => x.id === s.id);
            if (!xx) return false;
            return {...xx, ...s, selected: xx.selected};
        }),
        [sections, detectedSections]
    ) as any;
    return {sections: availableSections, section, allSections: sections, detectedSections};
}

export default useLuniiPageSections
