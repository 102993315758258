import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {cssText} from '@ohoareau/css-utils';
import clsx from 'clsx';
import {availableForSubscription} from '../../../../configs/site';
import {useHasMounted, useLuniiNavigation, useLuniiPage, useLuniiTranslation, useLuniiUser} from '../../../ui';
import {Button, Illustration} from '.';

const useStyles = makeStyles(theme => ({
    root: {
        boxShadow: '0px 4px 16px 0px rgba(66, 63, 55, 0.10)',
        borderRadius: 16,
    },
    content: {
        padding: theme.spacing(3),
    },
    title: {
        ...cssText(theme, 'standard', 'title_2', undefined, undefined, '#2C3637'),
        marginBottom: theme.spacing(2),
    },
}));

export function ProductSubscription({className = '', mode = 'fah'}: ProductSubscriptionProps) {
    const classes = useStyles();
    const {goPageByModel} = useLuniiNavigation();
    const {user} = useLuniiUser();
    const {...p} = useLuniiPage();
    const {t} = useLuniiTranslation();
    const hasMounted = useHasMounted();
    if (!hasMounted || user?.subscription || !availableForSubscription(p?.locale)) return null;
    return (
        <div className={clsx(classes.root, className)}>
            <div className={classes.content}>
                <div className={classes.title}>{t('product_subscription_title')}</div>
                <Button url={() => goPageByModel && goPageByModel('abonnement_presentation')} text={t('product_description_button_label')} size='small' theme='secondary'/>
            </div>
            <Illustration name={`audiobooks_subscription_${mode}`} alt={mode}/>
        </div>
    );
}

export interface ProductSubscriptionProps {
    className?: string;
    mode?: 'fah_flam' | 'fah' | 'flam';
}

export default ProductSubscription;
