import {useCallback} from 'react';
import useLuniiShoppingCartLazyGetApi from './useLuniiShoppingCartLazyGetApi';

export function useShoppingCartRefresh() {
    // cannot use callbacks with lazy queries
    const [getCart, {loading, error, ...infos}] = useLuniiShoppingCartLazyGetApi();
    const onRefresh = useCallback(async () => {
        try {
            await getCart();
        } catch (e) {
            console.error(e);
        }
    }, [getCart]);
    const data = ((infos || {}).data || {}).getCart;
    return [onRefresh, {loading: Boolean(loading), error: error || false, data}] as [any, any];
}

export default useShoppingCartRefresh
