import React, {useContext, useEffect} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {cssBorder, cssText} from '@ohoareau/css-utils';
import {Grid} from '@material-ui/core';
import {Trans} from 'react-i18next';
import {HistoryLocation, useLocation} from '@reach/router';
import Loadable from '@loadable/component';
import {
    Button,
    DynamicIcon,
    Illustration,
    Row,
    ShadowedPanel,
    useLuniiEventTracking,
    useLuniiNavigation,
    useLuniiPageTracking,
    useLuniiSpa,
    useLuniiTranslation,
} from '../../../ui';
import {LuniiSubscriptionGiftCheckoutContext} from '../../../contexts/LuniiSubscriptionGiftCheckoutContext';

const SuccessConfetti = Loadable(() => import('../commons/components/SuccessConfetti'));

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        position: 'relative',
    },
    heading: {
        margin: theme.spacing(1, 0, 2),
        [theme.breakpoints.up('sm')]: {
            margin: theme.spacing(0, 0, 3),
        },
    },
    headingTitle: {
        ...cssText(theme, 'standard', 'title_1', undefined, undefined, '#423F37'),
        margin: theme.spacing(1, 0, 2),
        [theme.breakpoints.up('sm')]: {
            margin: theme.spacing(1, 0),
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '24px',
        },
        '& span': {
            display: 'block',
            ...cssText(theme, 'standard', 'body'),
            margin: theme.spacing(1.5, 0, 0),
        },
    },
    panel: {
        padding: theme.spacing(3),
        marginBottom: theme.spacing(4),
        backgroundColor: 'white',
        ...cssBorder(theme, 'push'),
    },
    panelHeading: {
        ...cssText(theme, 'standard', 'title_2', undefined, undefined, '#423F37'),
    },
    panelList: {
        ...cssText(theme, 'standard', 'body', undefined, undefined, '#423F37'),
        listStyle: 'none',
        counterReset: 'item',
        padding: 0,
        margin: 0,
        '& li': {
            counterIncrement: 'item',
            margin: theme.spacing(7, 0, 2),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            position: 'relative',
            ...cssText(theme, 'standard', 'body_thick', undefined, undefined, '#423F37'),
            '& span': {
                ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#716C5E'),
                marginTop: '4px',
            },
            '&:last-of-type': {
                marginBottom: 0,
            },
        },
        '& li:before': {
            ...cssText(theme, 'standard', 'body_thick', undefined, undefined, '#063646'),
            position: 'absolute',
            top: -32,
            left: 0,
            content: 'counter(item)',
            background: '#C8E7F9',
            borderRadius: '100%',
            width: '24px',
            textAlign: 'center',
            display: 'block',
            flexShrink: '0',
        },
    },
    icon: {
        backgroundColor: '#1eb587',
        borderRadius: '50%',
        padding: 10,
        margin: 0,
        lineHeight: 0,
    },
    paragraph: {
        ...cssText(theme, 'standard', 'secondary_body'),
        marginBottom: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    actions: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginBottom: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            marginBottom: theme.spacing(4),
        },
    },
    illustration: {
        position: 'relative',
        height: 200,
        marginBottom: 56,
        '& >*': {
            height: '100%',
        },
    },
}));

export function ConfirmationScreen() {
    const handleEventTracking = useLuniiEventTracking();
    const classes = useStyles();
    const [state] = useContext(LuniiSubscriptionGiftCheckoutContext);
    const {t, i18n} = useLuniiTranslation();
    const location: HistoryLocation = useLocation();
    const {goCatalog} = useLuniiNavigation();
    const {navigate} = useLuniiSpa();
    const dataLayerConfirmationPageEvent = useLuniiPageTracking({
        envModel: 'abonnement_offir_confirmation',
    });
    useEffect(() => {
        if (state.success && location?.state?.subscriptionGiftCheckoutSuccess) {
            dataLayerConfirmationPageEvent();
            handleEventTracking({
                categorie: 'conversion',
                action: 'abonnement',
                libelle: `abonnement_cadeau + ${state.selected.submitMode}`,
                valeur: state.selected.product?.price,
            });
        } else {
            navigate('/');
        }
    }, []);
    const handleNavigateToCatalog = () => {
        goCatalog!();
    };
    return (
        <Row
            padding="none"
            direction="column"
            alignItems="center"
            justify="center"
            rootClassName={classes.root}
        >
            <Grid
                item
                md={6}
                sm={8}
                xs={12}
            >
                <div className={classes.heading}>
                    <DynamicIcon
                        className={classes.icon}
                        type="check"
                        height={42}
                        width={42}
                        color="#fff"
                    />
                    <h2 className={classes.headingTitle}>
                        <Trans
                            i18n={i18n}
                            i18nKey="subscriptiongift_title_step_3"
                        >
                            ...<span>...</span>...
                        </Trans>
                    </h2>
                </div>
                <ShadowedPanel className={classes.panel}>
                    <Trans
                        i18n={i18n}
                        i18nKey={`subscriptiongift_confirmation_content_${state?.selected?.submitMode}`}
                        components={{
                            title: <div className={classes.panelHeading} />,
                            list: <ol className={classes.panelList} />,
                            el: <li />,
                            subel: <span />,
                        }}
                    />
                </ShadowedPanel>
                <div className={classes.actions}>
                    <Button
                        color="primary"
                        endIcon={<DynamicIcon type="next" />}
                        onClick={handleNavigateToCatalog}
                    >
                        {t('subscription_confirm_gotolibrary')}
                    </Button>
                </div>
                <div className={classes.illustration}>
                    <Illustration
                        name="subscription_success"
                        height={200}
                        objectFit="contain"
                    />
                </div>
            </Grid>
            <SuccessConfetti />
        </Row>
    );
}

export default ConfirmationScreen;
