import React from 'react';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
    root: {
        borderRadius: '50%',
        width: 8,
        height: 8,
        backgroundColor: (props: DotProps) => props.color,
        transition: '.35s background-color ease-out, .35s transform ease-out',
    },
    '@media (hover: hover)': {
        root: {
            '&:hover': {
                transform: 'scale(1.3)',
            },
        },
    },
    active: {
        transform: 'scale(1.3)',
        transformOrigin: 'center',
    },
    horizontal: {
        marginLeft: (props: DotProps) => props.offset ? 8 : undefined,
    },
    vertical: {
        marginTop: (props: DotProps) => props.offset ? 8 : undefined,
    },
});

export function Dot({size = 'default', vertical = false, color, offset = false}: DotProps) {
    const classes = useStyles({color, offset});
    return (
        <div
            className={clsx(
                classes.root,
                vertical && classes.vertical,
                !vertical && classes.horizontal,
                size === 'large' && classes.active,
            )}
        />
    );
}

export interface DotProps {
    vertical?: boolean,
    size?: 'normal' | 'default' | 'large',
    color: string,
    offset?: boolean,
}

export default Dot;
