import {useCallback} from 'react';
import useLuniiMutationApi from '../useLuniiMutationApi';

export function useLegacyGiftCardMerge(callback: Function|undefined = undefined) {
    const [mergeLegacyGiftCard, {loading, error, ...infos}] = useLuniiMutationApi('MERGE_LEGACY_GIFT_CARD');
    const onSubmit = useCallback(async () => {
        try {
            const response = await mergeLegacyGiftCard();
            const data = response?.data?.mergeLegacyGiftCard || {};
            callback && callback(data);
            return (data);
        } catch (e) {
            console.error(e);
        }
    }, [mergeLegacyGiftCard, callback]);
    return [onSubmit, {loading: Boolean(loading), error: error || false, ...infos}] as [any, any];
}

export default useLegacyGiftCardMerge;
