import React, {useState} from 'react';
import {cssBox, cssShadow, cssText} from '@ohoareau/css-utils';
import {A11y, FreeMode, Mousewheel, Navigation} from 'swiper';
import 'swiper/swiper.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/modules/a11y/a11y.min.css';
import 'swiper/modules/free-mode/free-mode.min.css';
import {Swiper, SwiperProps, SwiperSlide} from 'swiper/react/swiper-react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {IconButton, useMediaQuery} from '@material-ui/core';
import {Swiper as SwiperInstance} from 'swiper/types';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import {Row} from '../Row';
import {module_title} from '../types';
import ItemCarrousselSlide from '../molecules/ItemCarrousselSlide';
import ArrowLeft from '../images/icons/ArrowLeft';
import useSwiperRef from '../hooks/swiper/useSwiperRef';

const useStyles = makeStyles((theme) => ({
    root: {
        ...cssText(theme, 'standard', 'body'),
        ...cssBox(theme, undefined, (props) => theme[`background_color_${props.color || 'clear'}`]),
        position: 'relative',
        paddingBottom: 56,
        [theme.breakpoints.down('sm')]: {
            paddingBottom: 26,
        },
    },
    title: {
        paddingTop: 64,
        ...cssText(theme, 'standard', 'large_title'),
        [theme.breakpoints.down('sm')]: {
            ...cssText(theme, 'standard', 'title_1'),
            paddingTop: 32,
        },
    },
    swiperWrapper: {
        position: 'relative',
        width: '100%',
        paddingTop: 24,
        [theme.breakpoints.down('sm')]: {
            paddingTop: 12,
        },
    },
    slideWrapper: {
        width: 267,
        [theme.breakpoints.down('md')]: {
            width: 216,
        },
        [theme.breakpoints.down('sm')]: {
            width: 160,
        },
    },
    control: {
        display: 'none',
        position: 'absolute',
        zIndex: 100,
        ...cssShadow(theme, 'level_02'),
        backgroundColor: '#ffffff',
        border: '1px solid #efedea',
        transform: 'translate3d(0, calc(-50% + 12px), 0)',
        color: '#2762e9',
        transition:
            'color .35s ease-out, box-shadow .35s ease-out, background-color .35s ease-out, opacity .35s ease-out',
        top: 190 / 2, // item carroussel slide  height / 2
        [theme.breakpoints.up('sm')]: {
            top: 257 / 2, // item carroussel slide  height / 2
        },
        [theme.breakpoints.up('md')]: {
            top: 320 / 2, // item carroussel slide  height / 2
        },
        '&:hover': {
            backgroundColor: '#ffffff',
        },
        '&.hidden': {
            opacity: 0,
            display: 'none !important',
            pointerEvents: 'none',
        },
    },
    controlDisabled: {
        'pointer-events': 'unset !important',
        opacity: '0 !important',
    },

    '@media (hover: hover)': {
        root: {
            '&:hover $control': {
                opacity: 1,
                ...cssShadow(theme, 'level_01'),
            },
        },
        control: {
            opacity: 0,
            display: 'block',
        },
    },
    prev: {
        left: 10,
        [theme.breakpoints.up('md')]: {
            left: 20,
        },
    },
    next: {
        right: 10,
        [theme.breakpoints.up('md')]: {
            right: 20,
        },
    },
}));

export function ItemCarroussel({title, items = []}: ItemCarrousselProps) {
    const classes = useStyles();

    const hasHover = useMediaQuery('(hover: hover)');
    const [scrollLock, setScrollLock] = useState<boolean>(true);
    const [canGoPrev, setCanGoPrev] = useState<boolean>(false);
    const [canGoNext, setCanGoNext] = useState<boolean>(false);

    const [nextEl, nextElRef] = useSwiperRef<HTMLButtonElement>();
    const [prevEl, prevElRef] = useSwiperRef<HTMLButtonElement>();

    const handleNav = (swiper: SwiperInstance) => {
        if (!hasHover || scrollLock) return;

        if (swiper.isEnd) {
            if (canGoNext) setCanGoNext(false);
        } else if (swiper.isBeginning) {
            if (canGoPrev) setCanGoPrev(false);
            if (!canGoNext) setCanGoNext(true);
        } else {
            if (!canGoPrev) setCanGoPrev(true);
            if (!canGoNext) setCanGoNext(true);
        }
    };

    const swiperProps: SwiperProps = {
        modules: [A11y, Navigation, FreeMode, Mousewheel],
        slidesPerView: 'auto',
        slidesOffsetAfter: 16,
        slidesOffsetBefore: 16,
        spaceBetween: 8,
        breakpoints: {
            960: {
                slidesOffsetAfter: 24,
                slidesOffsetBefore: 24,
                spaceBetween: 16,
            },
            1280: {
                slidesOffsetAfter: 150,
                slidesOffsetBefore: 150,
                spaceBetween: 24,
            },
        },
        freeMode: true,
        mousewheel: {
            invert: false,
            forceToAxis: true,
            releaseOnEdges: true,
        },
        navigation: {
            prevEl,
            nextEl,
        },
        grabCursor: true,
        resizeObserver: true,
        watchSlidesProgress: true,
        roundLengths: true,
        onResize: handleNav,
        onProgress: handleNav,
        onUpdate: handleNav,
        onBreakpoint: (swiper) => {
            swiper.setProgress(0);
        },
        onLock: (swiper) => {
            // Slider has more space than provided slides, should hide controls
            if (!scrollLock) setScrollLock(true);
            if (setTimeout)
                setTimeout(() => {
                    swiper.setProgress(0);
                }, 0);
        },
        onUnlock: () => {
            // Slider has less space than provided slides, should display controls
            if (scrollLock) setScrollLock(false);
        },
    };

    if (items.length === 0) return null;

    return (
        <div className={classes.root}>
            <Row padding="none">
                <Grid item container lg={12} md={12} xs={12}>
                    <div className={classes.title}>{title}</div>
                </Grid>
            </Row>
            <Swiper {...swiperProps} className={classes.swiperWrapper}>
                {items.map((item, index) => (
                    <SwiperSlide key={`itm-${index}`} className={classes.slideWrapper}>
                        <ItemCarrousselSlide
                            {...item}
                            imageLoading={index > 4 ? 'lazy' : 'eager'}
                        />
                    </SwiperSlide>
                ))}

                <IconButton
                    classes={{root: classes.control, disabled: classes.controlDisabled}}
                    className={clsx(classes.prev, scrollLock && 'hidden')}
                    slot="container-start"
                    disabled={!canGoPrev}
                    ref={prevElRef}
                >
                    <ArrowLeft />
                </IconButton>
                <IconButton
                    classes={{root: classes.control, disabled: classes.controlDisabled}}
                    className={clsx(classes.control, classes.next, scrollLock && 'hidden')}
                    slot="container-end"
                    disabled={!canGoNext}
                    ref={nextElRef}
                >
                    <ArrowLeft style={{transform: 'rotate(180deg)'}} />
                </IconButton>
            </Swiper>
        </div>
    );
}

export interface ItemCarrousselProps {
    items?: any[];
    title?: module_title;
}

export default ItemCarroussel;
