import React, {useCallback} from 'react';
import {nanoid} from 'nanoid';
import {AlbumCarroussel, useWishlist, wishlist_item} from '../../../ui';
import useLuniiItemsTracking from '../../../hooks/useItemsTracking';

export function CarrousselCardSmallItemsModule({items, itemsAreTracked, ...props}: any) {
    const itemsTracked = useLuniiItemsTracking(itemsAreTracked, items);

    const [{addToWishlistExecute: addToWishlist, removeFromWishlistExecute: removeFromWishlist}] =
        useWishlist(); // todo warning should use new useUserWishlist, usePostUserWishlist and userDeleteUserWishlist hooks

    const handleWishlistClick = useCallback(
        async (packInWishlist: boolean, product: wishlist_item) => {
            const packId = product.objectId;
            if (packInWishlist) {
                await removeFromWishlist({
                    variables: {
                        data: {
                            packId,
                        },
                    },
                    optimisticResponse: {
                        __typename: 'Mutation',
                        removeFromWishlist: {
                            ...product,
                            _removed: true,
                            __typename: 'WishlistItem',
                        },
                    },
                });
            } else {
                await addToWishlist({
                    variables: {
                        data: {
                            packId,
                        },
                    },
                    optimisticResponse: {
                        __typename: 'Mutation',
                        addToWishlist: {
                            ...product,
                            id: nanoid(),
                            __typename: 'WishlistItem',
                        },
                    },
                });
            }
        },
        [addToWishlist, removeFromWishlist],
    );

    const enrichedItems = itemsTracked.map((item) => ({
        ...item,
        onWishlistToggleClick: handleWishlistClick,
    }));

    return (
        <AlbumCarroussel
            {...props}
            items={enrichedItems}
        />
    );
}

export default CarrousselCardSmallItemsModule;
