import React, {useCallback} from 'react';
import {cssBorder, cssBox, cssShadow, cssText} from '@ohoareau/css-utils';
import clsx from 'clsx';
import TextParagraph from '../molecules/TextParagraph';
import {buttonifyFromProps} from '../hocs';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {useMediaQuery, useTheme} from '@material-ui/core';
import Img from '../nucleons/Img';
import {convertPrice} from '../utils';
import {useLuniiTranslation} from '../hooks';
import {LuniiProductProvider} from '../contexts';

const useStyles = makeStyles(theme => ({
    root: {
        ...cssBox(theme, 'standard', 'clear'),
        ...cssText(theme, 'standard', 'body'),
        display: 'flex',
        width: '100%',
        ...cssBorder(theme, 'push'),
        overflow: 'hidden',
    },
    withFilters: {
        ...cssShadow(theme, 'level_03'),
    },
    top: {
        flexDirection: 'column',
    },
    bottom: {
        flexDirection: 'column-reverse',
    },
    left: {
        flexDirection: 'row',
    },
    right: {
        flexDirection: 'row-reverse',
    },
    imageContent: {
        flex: 7,
        display: 'flex',
        alignItems: 'center',
    },
    textContent: {
        flex: 5,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        padding: 32,
        [theme.breakpoints.down('sm')]: {
            padding: 24,
        },
    },
    separator: {
        flex: .5,
    },
    imageContentWithPadding: {
        margin: 32,
        borderRadius: 8,
        overflow: 'hidden',
    },
    imageContentWithPaddingMobile: {
        margin: 16,
    },
    image: {
        width: '100%',
        height: '100%',
        lineHeight: 0,
    },
    button: {
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(2),
        },
        marginRight: '8px !important',
    },
    outOfStock: {
        ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, 'status_alert'),
    },
}));

export function Push({product, title, text, label, overline, image, imagePosition = 'left', imageLoading = 'eager', mobileImagePosition = 'bottom', imagePadding = false, filters = true, buttonLabel, buttonTarget, buttonType, button2Type, className, eventTrackingCallback, ...props}: PushProps) {
    const classes = useStyles();
    const theme = useTheme();
    const {t} = useLuniiTranslation();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const {Buttons} = buttonLabel
        ? buttonifyFromProps({buttonTarget, buttonLabel, button2Type, ...props}, [`${buttonType ? buttonType : 'primary'},size=small_mobile_only`, `tertiary,${button2Type === 'tertiary' && 'endIcon=next-24' || ''},size=small_mobile_only`])
        : product
            ? buttonifyFromProps({
                buttonTarget: product.stock && product.buttonTarget,
                buttonLabel: product.stock ? (product.price === 0 ? t('product_price_free') : convertPrice(product.price, product.currency)) : undefined,
                button2Type,
                ...props,
            }, [`primary,startIcon=cart,size=small_mobile_only`, `tertiary,${button2Type === 'tertiary' && 'endIcon=next-24' || ''},size=small_mobile_only`])
            : buttonifyFromProps({});
    // tracking event handler
    const PreparedButtons = useCallback(props => {
        return eventTrackingCallback
            ? <Buttons className={classes.button} {...props} onClickCb={(e: Event) => {
                const libelle = buttonTarget ? buttonTarget : product ? product.buttonTarget : 'bouton slide';
                eventTrackingCallback({libelle});
            }}/>
            : <Buttons className={classes.button}/>;
    }, [Buttons, eventTrackingCallback]);
    if (product && product.notFound) return null; // hide module if product is defined but not found (prismic deoptimization)
    const _product = product || {};
    const _img = image || _product.image || {};
    const _text = text || _product.description;
    const _title = title || _product.name || _product.title;
    const _label = label || _product.label;
    const _alt = (_img && _img.alt) || _product.name || _title;
    const showOutOfStock = (!buttonLabel || !buttonTarget) && (product && !product.stock);
    return (
        <LuniiProductProvider value={product}>
            <div className={clsx(classes.root, className, isMobile ? classes[mobileImagePosition] : classes[imagePosition], filters && classes.withFilters)}>
                <div className={clsx(classes.imageContent, imagePadding && classes.imageContentWithPadding, imagePadding && isMobile && classes.imageContentWithPaddingMobile)}>
                    <div className={classes.image}>
                        <Img {..._img} alt={_alt} loading={imageLoading}/>
                    </div>
                </div>
                <div className={classes.separator}/>
                <div className={classes.textContent}>
                    <TextParagraph title={_title} text={_text} label={_label} overline={overline} buttonsComponent={PreparedButtons}/>
                    {showOutOfStock && <div className={classes.outOfStock}>{t('product_out_of_stock')}</div>}
                </div>
            </div>
        </LuniiProductProvider>
    );
}

export interface PushProps {
    product?: any,
    filters?: boolean,
    buttonLabel?: any,
    buttonTarget?: any,
    buttonType?: any,
    button2Type?: any;
    title?: any,
    text?: any,
    label?: any,
    overline?: any,
    image?: any,
    imagePosition?: any,
    imageLoading?: 'lazy' | 'eager',
    mobileImagePosition?: any,
    imagePadding?: any,
    className?: string,
    eventTrackingCallback?: any,
}

export default Push
