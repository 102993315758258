import React, {useState, useCallback} from 'react';
import Modal from '@material-ui/core/Modal';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {useLuniiUserTokens, useLuniiTranslation} from '../hooks/';
import {cssText} from '@ohoareau/css-utils';
import {PasswordUpdateForm} from '../molecules';
import {IconButton} from '@material-ui/core';
import CloseIcon from '../images/icons/Close';
import Button from '../atoms/Button';

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    root: {
        position: 'absolute',
        width: 622,
        backgroundColor: 'white',
        border: 'none',
        borderRadius: theme.spacing(2),
        outline: 'none',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',

        [theme.breakpoints.down('md')]: {
            width: 744,
        },

        [theme.breakpoints.down('sm')]: {
            width: '100%',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            left: 0,
            bottom: 0,
            top: 'unset',
            transform: 'unset',
        },

    },
    main: {
        padding: theme.spacing(4),
    },
    header: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    modalTitle: {
        ...cssText(theme, 'standard', 'title_2', undefined),
    },
    modalIntro: {
        marginBottom: theme.spacing(2),
        ...cssText(theme, 'standard', 'body'),
    },
    successMessage: {
        ...cssText(theme, 'standard', 'body'),
        marginBottom: theme.spacing(3),
    },
}));

export function PasswordUpdateModal ({isOpen = false, close, className, disableClose = false, loginData, ...props}: PasswordUpdateModalProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const {setCurrentUserTokens} = useLuniiUserTokens();
    const [success, setSuccess] = useState<boolean>(false);

    const handlePasswordUpdate = useCallback((tokens) => {
        setCurrentUserTokens(tokens);
        setSuccess(true);
    }, []);

    const handleClose = useCallback(() => {
        close(false);
    }, [close]);

    const modalTitle = success ? t('passwordupdatemodal_title_success') : t('passwordupdatemodal_title');

    return (
        <Modal
            className={classes.modal}
            open={isOpen}
            onClose={handleClose}
            disablePortal={true}
            disableBackdropClick={disableClose}
            disableEscapeKeyDown={disableClose}
            aria-labelledby={modalTitle}
            {...props}>
            <div className={classes.root}>
                <div className={classes.main}>
                    <div className={classes.header}>
                        <div className={classes.modalTitle}>
                            {modalTitle}
                        </div>
                        {!disableClose && <IconButton aria-label={'close'} onClick={handleClose} disableRipple={true}>
                            <CloseIcon height={24} width={24}/>
                        </IconButton>}
                    </div>
                    <div>
                        {
                            loginData?.password && !success && <div className={classes.modalIntro}>
                                {t('passwordupdatemodal_text_resetonlogin')}
                            </div>
                        }
                        {!success && <PasswordUpdateForm onPasswordUpdate={handlePasswordUpdate} loginData={loginData}/>}
                        {success && <>
                            <div className={classes.successMessage}>
                                <p>{t('passwordupdatemodal_success_message')}</p>
                            </div>
                            <Button onClick={handleClose} color={'primary'}>
                                {t('passwordupdatemodal_close')}
                            </Button>
                        </>}
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export interface PasswordUpdateModalProps {
    className?: string,
    isOpen?: boolean,
    close: Function,
    disableClose?: boolean,
    loginData?: {
        email: string,
        password: string,
    }
}

export default PasswordUpdateModal;
