export const colors: any = {
    multiProductUniverse: {
        blue: {
            main: '#2DAAD7',
        },
        orange: {
            main: '#EC6907',
        },
        default: {
            main: '#FBBD2F',
            second: '#2C3637',
        },
    },
    theme: {
        ['brand-primary']: {
            50: '#FFF9F2',
            100: '#FEF3E5',
            200: '#FDE7C8',
            300: '#FDDAA5',
            400: '#FCCC7A',
            500: '#FBBD2F',
            600: '#E1A92A',
            700: '#C29224',
            800: '#856212',
            900: '#4F3B0C',
        },
        ['brand-secondary']: {
            50: '#F2F9FE',
            100: '#E5F3FC',
            200: '#C8E7F9',
            300: '#A6DAF6',
            400: '#7ACDF3',
            500: '#47C7F5',
            600: '#2DAAD7',
            700: '#2793BA',
            800: '#1A6F8E',
            900: '#063646',
        },
        ['brand-flam']: {
            50: '#FEF4EB',
            100: '#FEE8D8',
            200: '#FCD1B1',
            300: '#FBBD8E',
            400: '#FA8A36',
            500: '#EC6907',
            600: '#DB6207',
            700: '#A34805',
            800: '#873C04',
            900: '#5D2902',
        },
        interactive: {
            50: '#F7F9FF',
            100: '#E6EEFF',
            200: '#D4E1FE',
            300: '#C1D3FE',
            400: '#ABC4FD',
            500: '#92B2FD',
            600: '#749DFC',
            700: '#4F83FC',
            800: '#2762E9',
            900: '#173A8A',
        },
        positive: {
            50: '#F2F9F8',
            100: '#D8F1F0',
            200: '#B3E8E4',
            300: '#85D8D5',
            400: '#1AB5B0',
            500: '#009F9B',
            600: '#018B88',
            700: '#007D7A',
            800: '#006764',
            900: '#074847',
        },
        danger: {
            50: '#FAF2F2',
            100: '#F5E5E4',
            200: '#EBC8C6',
            300: '#E0A6A2',
            400: '#D57A73',
            500: '#C9310C',
            600: '#B42C0B',
            700: '#9C2609',
            800: '#7F1F08',
            900: '#5A1605',
        },
        ui: {
            white: '#FFFFFF',
            50: '#F9FAFA',
            100: '#F2F4F5',
            200: '#E5E9EB',
            300: '#D7DDE0',
            400: '#C8D1D4',
            500: '#B8C4C8',
            600: '#A6B1B5',
            700: '#7C878B',
            800: '#5F6769',
            900: '#2C3637',
        },
    },
};

export const getColor = (type: string, color: string, variant: string) =>  ((colors[type] || {})[color] || {})[variant];
export const getThemeColor = (color: string, variant: string) =>  (colors.theme[color] || {})[variant];
export const getMultiProductUniverseColor = (color: string, variant: string) =>  (colors.multiProductUniverse[color] || {})[variant];

export default colors;
