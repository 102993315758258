import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flex: 1,
    },
});

export function ShoppingCartWidgetHeader() {
    const classes = useStyles();
    return (
        <div className={classes.root} />
    );
}

export default ShoppingCartWidgetHeader