import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';
import {cssText} from '@ohoareau/css-utils';
import {useFormContext} from 'react-hook-form';
import {TextField, useLuniiTranslation, FieldSet} from '../../ui';

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
    },
    title: {
        ...cssText(theme, 'standard', 'title_2'),
        marginBottom: theme.spacing(1),
    },
    subtitle: {
        ...cssText(theme, 'standard', 'body', undefined, undefined, '#716C5E'),
    },
    warning: {
        ...cssText(theme, 'standard', 'body', undefined, undefined, '#716C5E'),
        marginBottom: theme.spacing(1),
    },
    charactersInfos: {
        ...cssText(theme, 'standard', 'caption', undefined, undefined, '#908977'),
        width: '100%',
        textAlign: 'end',
    },
}));

export function GiftCardMessage({className = ''}: GiftCardMessageProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const {register, errors, watch} = useFormContext();
    const x = {errors, register};
    const giftCardMessage = watch('giftCardMessage') || '';
    const giftCardNoReceiver = watch('giftCardNoReceiver');
    const handleOnChange = (e: any) => {
        e.currentTarget.value = (e?.currentTarget?.value || '').replace(/[\n]{2,}/g, '\n\n');
    };
    if (giftCardNoReceiver) return null;
    return (
        <div className={clsx(classes.root, className)}>
            <div className={classes.title}>
                {t('gift_cards_message_title')}{' '}
                <span className={classes.subtitle}>({t('gift_cards_message_subtitle')})</span>
            </div>
            <FieldSet>
                <div className={classes.warning}>{t('gift_cards_message_warning')}</div>
                <TextField
                    multiline
                    rows={4}
                    name="giftCardMessage"
                    {...x}
                    onChange={handleOnChange}
                    fullWidth
                    definition={{
                        pattern: {
                            value: /^(.|\n){0,200}$/i,
                            message: t('constraints_too_many_characters'),
                        },
                    }}
                />
                <div className={classes.charactersInfos}>
                    {giftCardMessage.length}/200 {t('characters')}
                </div>
            </FieldSet>
        </div>
    );
}

export interface GiftCardMessageProps {
    className?: string;
}

export default GiftCardMessage;
