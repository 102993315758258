import {useCallback} from 'react';
import useLuniiShoppingCartSaveApi from './useLuniiShoppingCartSaveApi';

export function useShoppingCartSaveSubmit(callback: Function|undefined = undefined) {
    const [save, {loading, error, ...infos}] = useLuniiShoppingCartSaveApi();
    const onSubmit = useCallback(async ({currency, country, items, accountBalance}) => {
        try {
            const response = await save({
                variables: {
                    data: {
                        currency,
                        country,
                        items: [...items.map(({reference, name, quantity, metadata}) => ({
                            reference,
                            name,
                            quantity,
                            ...Object.keys(metadata || {}).length > 0 && {
                                metadata: {
                                    amount: metadata.amount,
                                    sender: {
                                        name: metadata.sender?.name,
                                    },
                                    receiver: {
                                        name: metadata.receiver?.name,
                                        email: metadata.receiver?.email,
                                    },
                                    sending_date: metadata.sending_date,
                                    message: metadata.message,
                                    theme_reference: metadata.theme_reference,
                                },
                            },
                        }))],
                        accountBalance: {
                            use: accountBalance?.use,
                        },
                    },
                },
            });
            const result = ((response || {}).data || {}).saveCart;
            callback && callback(result);
            return result;
        } catch (e) {
            console.error(e);
        }
    }, [save, callback]);
    return [onSubmit, {loading: Boolean(loading), error: error || false, ...infos}] as [any, any];
}

export default useShoppingCartSaveSubmit
