import React from 'react';
import {cssText} from '@ohoareau/css-utils';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Row} from "../Row";
import Grid from "@material-ui/core/Grid";
import Img from "../nucleons/Img";
import {module_image, module_label} from "../types";

const useStyles = makeStyles(theme => ({
    root: {
        ...cssText(theme, 'standard', 'body'),
    },
    label: {
        ...cssText(theme, 'standard', 'caption', 'center'),
        width: '100%',
        marginTop: 16,
        [theme.breakpoints.only('md')]: {
            marginTop: 24,
        },
    },
    image: {
        display: 'flex',
        justifyContent: 'center',
    }
}));

export function Image({image, label, size = 'medium'}: ImageProps) {
    const classes = useStyles();
    const isLarge = 'large' === size;
    return (
        <Row className={classes.root} padding={isLarge ? 'none' : 'default'}>
            <Grid className={classes.image} item container lg={isLarge ? 12 : 10} md={12} xs={12}>
                {image && <Img {...image} rounded={!isLarge} />}
            </Grid>
            {!isLarge && label && (
                <Grid item container lg={12} md={12} xs={12}>
                    <div className={classes.label}>{label}</div>
                </Grid>
            )}
        </Row>
    );
}

export interface ImageProps {
    size?: 'medium' | 'large',
    image?: module_image,
    label?: module_label,
}

export default Image
