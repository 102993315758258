import React from 'react';
import {cssBox, cssText} from '@ohoareau/css-utils';
import DemoStepper from '../molecules/DemoStepper';
import DemoShowCase from '../molecules/DemoShowCase';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Row from '../Row';
import Grid from '@material-ui/core/Grid';
import {WithItem} from "../types";

const useStyles = makeStyles(theme => ({
    root: {
        ...cssBox(theme, undefined, 'clear'),
        ...cssText(theme, 'standard', 'body'),
    },
}));

export function Demo({title, overline, image, image2, step, steps, onChange}: DemoProps) {
    const classes = useStyles();
    return (
        <Row className={classes.root} margins={'left-except-mobile-none'}>
            <Grid item container lg={5} md={6} xs={12}>
                <DemoStepper title={title} overline={overline} step={step} steps={steps} onChange={onChange} />
            </Grid>
            <Grid item container lg={7} md={12} xs={12}>
                <DemoShowCase image={image} image2={image2} />
            </Grid>
        </Row>
    );
}

export interface DemoProps extends WithItem {
    step: string,
    steps: string[],
    onChange?: Function,
}

export default Demo
