import React from 'react';
import {TransitionGroup, CSSTransition} from 'react-transition-group';
import {Location, Router} from '@reach/router';
import {makeStyles, Theme} from '@material-ui/core';

const useClasses = makeStyles<Theme, {timeout: {enter: number; exit: number}}>({
    fadeClasses: ({timeout}) => ({
        width: '100%',
        position: 'relative',
        '& .fade-exit': {
            opacity: 1,
        },
        '& .fade-exit-active': {
            transition: `opacity ${timeout.exit * 0.8}ms ease`,
            opacity: 0,
        },
        '& .fade-enter, & .fade-appear': {
            opacity: 0,
        },
        '& .fade-enter-active, & .fade-appear-active': {
            opacity: 1,
            transition: `opacity ${timeout.enter}ms ease`,
        },
    }),
});

export interface FadeTransitionRouterProps {
    prefix: string;
    children: React.ReactNode;
}

export function FadeTransitionRouter({prefix, children}: FadeTransitionRouterProps) {
    const timeout = {
        enter: 500,
        exit: 500,
    };
    const classes = useClasses({timeout});
    return (
        <Location>
            {({location}) => (
                <div className={classes.fadeClasses}>
                    <TransitionGroup className="fade">
                        <CSSTransition
                            key={location.pathname}
                            timeout={timeout}
                            classNames="fade"
                            appear
                            enter
                            mountOnEnter
                            onEnter={() => {
                                window.scrollTo({top: 0});
                            }}
                            exit
                        >
                            <Router
                                location={location}
                                basepath={prefix}
                            >
                                {children}
                            </Router>
                        </CSSTransition>
                    </TransitionGroup>
                </div>
            )}
        </Location>
    );
}

export default FadeTransitionRouter;
