import React, {useState} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {cssShadow} from '@ohoareau/css-utils';
import {IconButton, useMediaQuery} from '@material-ui/core';
import clsx from 'clsx';
import {A11y, FreeMode, Mousewheel, Navigation} from 'swiper';
import {Swiper as SwiperInstance} from 'swiper/types';
import {Swiper, SwiperProps} from 'swiper/react/swiper-react';
import {useSwiperRef} from '../../../ui';
import ArrowLeft24 from "../../../ui/images/icons/ArrowLeft24";

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        width: '100%',
    },
    control: {
        display: 'none',
        position: 'absolute',
        zIndex: 100,
        ...cssShadow(theme, 'level_02'),
        backgroundColor: '#ffffff',
        border: '1px solid #efedea',
        transform: 'translate3d(0, calc(-50% + 12px), 0)',
        color: '#2762e9',
        transition: 'color .35s ease-out, box-shadow .35s ease-out, background-color .35s ease-out, opacity .35s ease-out',
        top: 150, // item carroussel image  height / 2
        [theme.breakpoints.down('md')]: {
            top: 120, // item carroussel image  height / 2
        },
        '&:hover': {
            backgroundColor: '#ffffff',
        },
        '&.hidden': {
            opacity: 0,
            display: 'none !important',
            pointerEvents: 'none',
        },
    },
    controlDisabled: {
        'pointer-events': 'unset !important',
        opacity: '0 !important',
    },
    '@media (hover: hover)': {
        root: {
            '&:hover $control': {
                opacity: 1,
                ...cssShadow(theme, 'level_01'),
            },
        },
        control: {
            opacity: 0,
            display: 'block',
        },
    },
    prev: {
        left: 10,
        [theme.breakpoints.up('md')]: {
            left: 20,
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    next: {
        right: 10,
        [theme.breakpoints.up('md')]: {
            right: 20,
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
}));

export function CarouselSwiper({content}: CarouselSwiperProps) {
    const classes = useStyles();
    const hasHover = useMediaQuery('(hover: hover)');

    const [scrollLock, setScrollLock] = useState<boolean>(true);
    const [canGoPrev, setCanGoPrev] = useState<boolean>(false);
    const [canGoNext, setCanGoNext] = useState<boolean>(false);

    const [nextEl, nextElRef] = useSwiperRef<HTMLButtonElement>();
    const [prevEl, prevElRef] = useSwiperRef<HTMLButtonElement>();

    const handleNav = (swiper: SwiperInstance) => {
        if (!hasHover || scrollLock) return;
        if (swiper.isEnd) {
            if (canGoNext) setCanGoNext(false);
        } else if (swiper.isBeginning) {
            if (canGoPrev) setCanGoPrev(false);
            if (!canGoNext) setCanGoNext(true);
        } else {
            if (!canGoPrev) setCanGoPrev(true);
            if (!canGoNext) setCanGoNext(true);
        }
    };

    const config: SwiperProps = {
        modules: [A11y, Navigation, FreeMode, Mousewheel],
        slidesPerView: 'auto',
        slidesOffsetAfter: 16,
        slidesOffsetBefore: 16,
        spaceBetween: 8,
        breakpoints: {
            960: {
                slidesOffsetAfter: 24,
                slidesOffsetBefore: 24,
                spaceBetween: 16,
            },
            1280: {
                slidesOffsetAfter: 150,
                slidesOffsetBefore: 150,
                spaceBetween: 24,
            },
        },
        freeMode: true,
        mousewheel: {
            invert: false,
            forceToAxis: true,
            releaseOnEdges: true,
        },
        navigation: {
            prevEl,
            nextEl,
        },
        grabCursor: true,
        resizeObserver: true,
        watchSlidesProgress: true,
        roundLengths: true,
        onResize: handleNav,
        onProgress: handleNav,
        onUpdate: handleNav,
        onBreakpoint: (swiper) => {
            swiper.setProgress(0);
        },
        onLock: (swiper) => {
            // Slider has more space than provided slides, should hide controls
            if (!scrollLock) setScrollLock(true);
            if (setTimeout)
                setTimeout(() => {
                    swiper.setProgress(0);
                }, 0);
        },
        onUnlock: () => {
            // Slider has less space than provided slides, should display controls
            if (scrollLock) setScrollLock(false);
        },
    };

    return (
        <Swiper {...config} className={classes.root}>
            {content}
            <IconButton
                classes={{root: classes.control, disabled: classes.controlDisabled}}
                className={clsx(classes.prev, scrollLock && 'hidden')}
                slot='container-start'
                disabled={!canGoPrev}
                ref={prevElRef}>
                <ArrowLeft24 width={24} height={24}/>
            </IconButton>
            <IconButton
                classes={{root: classes.control, disabled: classes.controlDisabled}}
                className={clsx(classes.next, scrollLock && 'hidden')}
                slot='container-end'
                disabled={!canGoNext}
                ref={nextElRef}>
                <ArrowLeft24 width={24} height={24} style={{transform: 'rotate(180deg)'}}/>
            </IconButton>
        </Swiper>
    );
}

export interface CarouselSwiperProps {
    content: any;
}

export default CarouselSwiper;
