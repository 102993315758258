import React, {useCallback, useState} from 'react';
import TextField, {TextFieldProps} from './TextField';
import useLuniiTranslation from "../../hooks/useLuniiTranslation";
import {InputAdornment} from '@material-ui/core';
import {DynamicIcon} from '../../nucleons';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useInputAdornmentStyles = makeStyles({
    root: {
        marginBottom: 15,
        marginRight: 10,
        cursor: 'pointer',
    },
});

export function PasswordField(props: PasswordFieldProps) {
    const {t} = useLuniiTranslation();
    const [showPassword, setShowPassword] = useState<boolean>(false)
    const handleClickShowPassword = useCallback(() => {
        setShowPassword(showPassword => !showPassword)
    }, [setShowPassword]);
    function handleMouseDownPassword(event) {
        event.preventDefault();
    }
    function handleBlur() {
        showPassword && setShowPassword(false);
    }
    const inputAdornmentProps = {
        classes: useInputAdornmentStyles()
    };
    return (
        <TextField name={'password'}
            type={showPassword ? 'text' : 'password'}
            definition={{
                 pattern: {
                     value: /^.{8,}$/i,
                     message: t('constraints_password')
                 }
            }}
            onBlur={handleBlur}
            InputProps={{
                endAdornment: (
                    <InputAdornment position={'end'} {...inputAdornmentProps}>
                        <DynamicIcon type={showPassword ? 'show' : 'hide'}
                                     onClick={handleClickShowPassword}
                                     onMouseDown={handleMouseDownPassword}
                                     color={"#423F37"}
                                     height={24} width={24}/>
                    </InputAdornment>
                )
            }}
           {...props} />
    );
}

export interface PasswordFieldProps extends TextFieldProps {}

export default PasswordField
