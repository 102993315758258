import React from 'react';
import {DefaultLayout} from "../../layouts/DefaultLayout";

export function NotFoundScreen() {
    return (
        <DefaultLayout>
            <div>NOT FOUND</div>
        </DefaultLayout>
    );
}

export default NotFoundScreen