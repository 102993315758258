import React from 'react';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {Dot} from './Dot';

const useStyles = makeStyles({
    clickable: {
        cursor: 'pointer',
    },
});

export function Step({index = 0, vertical = false, active = false, onClick = undefined, color}: StepProps) {
    const classes = useStyles();
    const handleClick = () => onClick && onClick(index);
    return (
        <div className={clsx(onClick && classes.clickable)} onClick={handleClick}>
            <Dot
                vertical={vertical}
                offset={index !== 0}
                size={active ? 'large' : 'default'}
                color={active ? color : '#E5E9EB'}/>
        </div>
    );
}

export interface StepProps {
    step?: string,
    vertical?: boolean;
    active?: boolean;
    index?: number;
    onClick?: Function;
    color: string;
}

export default Step;
