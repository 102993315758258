import React from 'react';
import Button from '../atoms/Button';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {cssText} from '@ohoareau/css-utils';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    button1: {
        width: '100%',
        marginTop: theme.spacing(4),
    },
    "@keyframes appear": {
        "0%": {
            opacity: 0,
        },
        '75%': {
            opacity: 0,
        },
        "100%": {
            opacity: 1,
        }
    },
    alert: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#FFF9EC',
        padding: theme.spacing(4, 2),
        borderRadius: 16,
        ...cssText(theme, 'standard', 'body', undefined, undefined, '#AE821D'),
        fontWeight: 'normal',
        marginTop: theme.spacing(4),
        opacity: 1,
        animation: "$appear 3s ease"
    },
}));

export function InfosPanelForm({className = undefined, onCancelClick, buttonLabel, alert}) {
    const classes = useStyles();
    return (
        <div className={clsx(classes.root, className)}>
            <Button className={classes.button1} color={'primary'} onClick={onCancelClick}>{buttonLabel}</Button>
            {alert && <div className={classes.alert}>{alert}</div>}
        </div>
    );
}

export default InfosPanelForm
