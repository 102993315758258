import React from 'react';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {ShoppingCartWidgetHeader, ShoppingCartWidgetContent, ElevatedPanel, useShoppingCart} from '..';

const useStyles = makeStyles(theme => ({
    root: {
        width: 320,
        padding: theme.spacing(2)
    },
}));

export function ShoppingCartWidget({maxItems = 2, className = ''}: ShoppingCartWidgetProps) {
    // todo mobile and tablet version with panel
    const classes = useStyles();
    const [{cart, deleteCartItem}] = useShoppingCart();
    return (
        <ElevatedPanel className={clsx(classes.root, className)}>
            <ShoppingCartWidgetHeader />
            <ShoppingCartWidgetContent items={cart?.items} onDeleteItem={deleteCartItem} maxItems={maxItems} />
        </ElevatedPanel>
    );
}

export interface ShoppingCartWidgetProps {
    className?: string,
    maxItems?: number,
}

export default ShoppingCartWidget;
