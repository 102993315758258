import React from 'react';
import {Login as BaseLoginScreen} from '../commons';
import {usePageTracking} from '../../../hooks';

export function LoginScreen() {
    usePageTracking(true, 'abonnement_offrir_connexion');
    return <BaseLoginScreen onLoginSuccessPath="/" />;
}

export default LoginScreen;
