import React from 'react';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {cssText} from '@ohoareau/css-utils';
import {getThemeColor} from '../../../../configs';
import {Img, Row, DynamicIcon} from '../../../ui';
import {Link} from '../..';

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
        },
    },
    grid: {
        marginBottom: theme.spacing(3),
        alignItems: 'flex-start',
    },
    blue: {
        backgroundColor: getThemeColor('brand-secondary', '50'),
        color: getThemeColor('brand-secondary', '800'),
    },
    orange: {
        backgroundColor: getThemeColor('brand-flam', '50'),
        color: getThemeColor('brand-flam', '800'),
    },
    link: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(2),
            alignSelf: 'flex-end',
        },
    },
    title: (props: any) => ({
        ...cssText(theme, 'standard', 'large_title'),
        color: getThemeColor(props.universe === 'blue' ? 'brand-secondary' : 'brand-flam', '900'),
        [theme.breakpoints.down('sm')]: {
            ...cssText(theme, 'standard', 'title_1'),
            color: getThemeColor(props.universe === 'blue' ? 'brand-secondary' : 'brand-flam', '900'),
        },
    }),
    subtitleContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        marginTop: theme.spacing(1),
    },
    subtitle: {
        ...cssText(theme, 'standard', 'body', undefined, undefined, 'inherit'),
    },
    icon: {
        marginRight: theme.spacing(1),
        marginBottom: 2,
        width: 24,
        minWidth: 24,
        height: 24,
    },
    image: {
        lineHeight: 0,
    },
    desktopLink: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    mobileLink: {
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));

export function HeadingCatalog({title = undefined, text = undefined, icon = undefined, universe = 'blue', image = undefined, buttonTarget = undefined, buttonLabel = undefined, buttonIcon = undefined, buttonIconPosition = undefined}: HeadingCatalogProps) {
    const classes = useStyles({universe});
    const buttonProps: any = {
        url: buttonTarget,
        text: buttonLabel,
        theme: universe === 'blue' ? 'info' : universe === 'orange' ? 'flam' : 'default',
        icon: buttonIcon,
        iconPosition: buttonIconPosition,
    };
    return (
        <div>
            <Row className={classes[universe]} padding='none' gridClassName={classes.root} alignItems='flex-start'>
                <Grid lg={6} md={6} xs={12} className={classes.grid}>
                    <div>
                        {(title && (
                            <div className={classes.title}>
                                {title}
                            </div>
                        )) ||
                            false}
                        <div className={classes.subtitleContainer}>
                            {(icon && <DynamicIcon className={classes.icon} type={`${icon}-24`} />) || false}
                            {(text && <div className={classes.subtitle}>{text}</div>) || false}
                        </div>
                    </div>
                </Grid>
                <Grid lg={6} md={6} xs={12} className={classes.link}>
                    {(buttonLabel && buttonTarget && (
                        <>
                            <Link className={classes.desktopLink} size='large' {...buttonProps} />
                            <Link className={classes.mobileLink} size='medium' {...buttonProps} />
                        </>
                    )) ||
                        false}
                </Grid>
            </Row>
            {(image && (
                <div className={classes.image}>
                    <Img {...image} />
                </div>
            )) ||
                false}
        </div>
    );
}

export interface HeadingCatalogProps {
    title?: string;
    text?: string;
    icon?: string;
    universe?: 'blue' | 'orange';
    image?: any;
    buttonTarget?: any;
    buttonLabel?: any;
    buttonIcon?: string;
    buttonIconPosition?: string;
}

export default HeadingCatalog;
