import React from 'react';
import {EffectFade, FreeMode, Navigation, Pagination} from 'swiper';
import {Swiper, SwiperProps, SwiperSlide} from 'swiper/react/swiper-react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import {IconButton} from '@material-ui/core';
import {cssBgColor, cssShadow} from '@ohoareau/css-utils';
import {audiobook_news} from '../../../types';
import {NewsSlide} from '../..';
import ArrowLeft24 from '../../../ui/images/icons/ArrowLeft24';
import {useSwiperRef} from '../../../ui';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        height: 'auto',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    rootMobile: {
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    slide: {
        [theme.breakpoints.down('sm')]: {
            flex: '0 0 312px',
            height: 'auto',
        },
    },
    control: {
        position: 'absolute',
        zIndex: 100,
        ...cssShadow(theme, 'level_02'),
        backgroundColor: '#ffffff',
        border: '1px solid #efedea',
        color: '#2762e9',
        top: '42%',
        '&:hover': {
            backgroundColor: '#ffffff',
        },
    },
    prev: {
        left: 'calc(50% - 580px)',
        [theme.breakpoints.down('md')]: {
            left: 'calc(50% - 465px)',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    next: {
        right: 'calc(50% - 580px)',
        [theme.breakpoints.down('md')]: {
            right: 'calc(50% - 465px)',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    step: {
        zIndex: 100,
        position: 'absolute',
        left: 0,
        right: 0,
        margin: '0 auto',
        marginBottom: 22,
        width: 'fit-content !important',
        display: 'flex',
        alignItems: 'center',
        '& .bull': {
            margin: '0 4px',
            width: 8,
            height: 8,
            display: 'block',
            borderRadius: '100%',
            backgroundColor: '#E3E1DB',
            cursor: 'pointer',
            transition: 'background-color .35s ease, transform .35s ease',
            '&.bull-active': {
                ...cssBgColor(theme, 'primary'),
                transform: 'scale(1.5)',
            },
            '&.bull:focus': {
                outline: 'none',
            },
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}));

export function NewsSlider({items = []}: NewsSliderProps) {
    const classes = useStyles();
    const [nextEl, nextElRef] = useSwiperRef<HTMLButtonElement>();
    const [prevEl, prevElRef] = useSwiperRef<HTMLButtonElement>();
    const [pagination, paginationRef] = useSwiperRef<HTMLDivElement>();
    if (items.length === 0) return null;
    const config: SwiperProps = {
        modules: [EffectFade, Navigation, Pagination],
        effect: 'fade',
        fadeEffect: {
          crossFade: true,
        },
        speed: 1,
        loop: true,
        navigation: {
            prevEl,
            nextEl,
        },
        mousewheel: {
            invert: false,
            forceToAxis: true,
            releaseOnEdges: true,
        },
        keyboard: true,
        pagination: {
            clickable: true,
            el: pagination,
            bulletClass: 'bull',
            bulletActiveClass: 'bull-active',
        },
        slidesPerView: 'auto',
        resizeObserver: true,
        roundLengths: true,
    };
    const mobileConfig: SwiperProps = {
        modules: [FreeMode, Navigation],
        spaceBetween: 16,
        freeMode: true,
        slidesOffsetBefore: 16,
        slidesOffsetAfter: 16,
        slidesPerView: 'auto',
    }
    const content = items
        .filter((item) => item.layout !== 'none')
        .slice(0, 6) // only show six last items
        .map((item) => (
            <SwiperSlide className={classes.slide}>
                <NewsSlide item={item} key={`itm-${Math.random()}`}/>
            </SwiperSlide>
    ));
    return (
        <div>
            <Swiper {...config} className={classes.root}>
                {content}
                <IconButton
                    classes={{root: classes.control, disabled: classes.control}}
                    className={clsx(classes.prev)}
                    ref={prevElRef}>
                    <ArrowLeft24 width={24} height={24}/>
                </IconButton>
                <IconButton
                    classes={{root: classes.control}}
                    className={clsx(classes.next)}
                    ref={nextElRef}>
                    <ArrowLeft24 width={24} height={24} style={{transform: 'rotate(180deg)'}}/>
                </IconButton>
                <div className={classes.step} ref={paginationRef}/>
            </Swiper>
            <Swiper {...mobileConfig} className={classes.rootMobile}>
                {content}
            </Swiper>
        </div>
    );
}

export interface NewsSliderProps {
    items?: audiobook_news[];
}

export default NewsSlider;
