import React from 'react';
import Grid from '@material-ui/core/Grid';
import {ExtractCard, Row} from '../../../ui';

export function DefaultExtractCardModule({product, ...props}: {product: any}) {
    if (!product?.previews) return null;
    return (
        <Row contentPadding='large' padding='none'>
            <Grid lg={12} md={12} xs={12}>
                <ExtractCard urls={product.previews} {...props}/>
            </Grid>
        </Row>
    );
}

export default DefaultExtractCardModule;
