import {useCallback, useContext} from 'react';
import LuniiUserContext from '../contexts/LuniiUserContext';
import {user, user_context_value} from '../types';

export const useLuniiUser = (): useLuniiUserInterface => {
    const {
        user,
        setUser,
        logout,
        refreshUser,
        refreshUserPartial,
    } = useContext<user_context_value>(LuniiUserContext);
    const ownsPackReference = useCallback((reference: string|undefined) => {
        return user?.ownedPacksReferences?.includes(reference);
    }, [user?.ownedPacksReferences]);
    const isLogged = !!user;
    return {
        user,
        setUser,
        logout,
        refreshUser,
        refreshUserPartial,
        ownsPackReference,
        isLogged,
    };
};

export interface useLuniiUserInterface {
    user: user|undefined,
    setUser: (user: user) => any|undefined,
    logout: () => any|undefined,
    refreshUser: () => any|undefined,
    refreshUserPartial: (params: {subscription?: boolean, library?: boolean, user?: boolean}) => any|undefined,
    ownsPackReference: (reference: string|undefined) => boolean,
    isLogged: boolean,
}

export default useLuniiUser;
