import * as React from 'react';
function SvgList(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width={props.width || 24}
            height={props.height || 24}
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6 6a1 1 0 100 2h12a1 1 0 100-2H6zm0 5a1 1 0 100 2h6a1 1 0 100-2H6zm3 6a1 1 0 011-1h7a1 1 0 110 2h-7a1 1 0 01-1-1zm-3 1a1 1 0 100-2 1 1 0 000 2z"
                fill={props.color || 'currentColor'}
            />
        </svg>
    );
}
export default SvgList;
