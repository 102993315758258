import {MutationHookOptions, LazyQueryHookOptions} from '@apollo/client';
import {
    useLuniiApi,
    ADD_TO_WISHLIST,
    REMOVE_FROM_WISHLIST,
    RETRIEVE_WISHLIST,
    RETRIEVE_WISHLIST_ITEM_FRAGMENT,
    wishlist
} from '../..';

interface useWishlistOptions {
    retrieveWishlistOptions?: LazyQueryHookOptions,
    addToWishlistOptions?: MutationHookOptions,
    removeFromWishlistOptions?: MutationHookOptions,
}

export function useWishlist({retrieveWishlistOptions, addToWishlistOptions, removeFromWishlistOptions}: useWishlistOptions = {}) {
    const {gql, useLazyQuery, useMutation} = useLuniiApi();
    const [
        retrieveWishlistExecute,
        retrieveWishlistResult,
    ] = useLazyQuery(RETRIEVE_WISHLIST(gql), {
        notifyOnNetworkStatusChange: true,
        ...retrieveWishlistOptions,
    });
    const [
        addToWishlistExecute,
        addToWishlistResult,
    ] = useMutation(
        ADD_TO_WISHLIST(gql),
        {
            refetchQueries: [RETRIEVE_WISHLIST(gql)],
            awaitRefetchQueries: true,
            notifyOnNetworkStatusChange: true,
            update(cache, {data: {addToWishlist}}) {
                cache.modify({
                    fields: {
                        retrieveWishlist(userWishlist: wishlist = {items: []}) {
                            const newWishlistItem = cache.writeFragment({
                                fragment: RETRIEVE_WISHLIST_ITEM_FRAGMENT(gql),
                                data: {
                                    ...addToWishlist,
                                    _removed: false,
                                },
                                overwrite: true,
                            });
                            return {
                                items: [newWishlistItem, ...userWishlist.items],
                            };
                        },
                    },
                });
            },
            onError: () => {
                // Store is in an inconstistent state, reset it
                addToWishlistResult.client.resetStore();
            },
            ...addToWishlistOptions,
        },
    );
    const [
        removeFromWishlistExecute,
        removeFromWishlistResult,
    ] = useMutation(
        REMOVE_FROM_WISHLIST(gql),
        {
            refetchQueries: [RETRIEVE_WISHLIST(gql)],
            awaitRefetchQueries: true,
            notifyOnNetworkStatusChange: true,
            update(cache, {data: {removeFromWishlist}}) {
                cache.modify({
                    fields: {
                        retrieveWishlist(userWishlist: wishlist = {items: []}) {
                            const updatedFragment = cache.updateFragment({
                                id: `WishlistItem:${removeFromWishlist.id}`,
                                fragment: RETRIEVE_WISHLIST_ITEM_FRAGMENT(gql),
                            }, (wishlistItem) => {
                                if (wishlistItem) {
                                    return {
                                        ...wishlistItem,
                                        _removed: true,
                                    };
                                }
                            });
                            return {
                                items: userWishlist.items.map((wishlistItem) => {
                                    if (wishlistItem.id === updatedFragment.id) {
                                        return updatedFragment;
                                    } else {
                                        return wishlistItem;
                                    }
                                }),
                            };

                        },
                    },
                });
            },
            onError: () => {
                // Store is in an inconstistent state, reset it
                removeFromWishlistResult.client.resetStore();
            },
            ...removeFromWishlistOptions,
        },
    );

    return [
        {
            retrieveWishlistExecute,
            addToWishlistExecute,
            removeFromWishlistExecute,
        },
        {
            retrieveWishlistResult,
            addToWishlistResult,
            removeFromWishlistResult,
        },
    ] as [
        {
            retrieveWishlistExecute: (variables?: {
                variables: {
                    data: {
                        consolidate: boolean,
                    },
                },
            }) => Promise<any>,
            addToWishlistExecute: (variables: {
                variables: {
                    data: {
                        packId: string,
                    },
                },
                optimisticResponse: any,
            }) => Promise<any>,
            removeFromWishlistExecute: (variables: {
                variables: {
                    data: {
                        packId: string,
                    },
                },
                optimisticResponse: any,
            }) => Promise<any>,
        },
        {
            retrieveWishlistResult: any,
            addToWishlistResult: any,
            removeFromWishlistResult: any,
        }
    ];
}

export default useWishlist;
