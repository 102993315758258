import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';
import {cssFgColor, cssText} from '@ohoareau/css-utils';
import {image} from '../../types';
import {Img} from '../../ui';
import SvgCheck from '../../ui/images/icons/Check';

const useStyles = makeStyles((theme) => ({
    root: {
        ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#908977'),
    },
    image: {
        borderRadius: theme.spacing(1.5),
        boxSizing: 'border-box',
        marginBottom: theme.spacing(1),
        position: 'relative',
        lineHeight: 0,
        overflow: 'hidden',
    },
    active: {
        border: '2px solid #FBBD2A',
    },
    overlay: {
        height: '100%',
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: 'white',
        opacity: 0.7,
    },
    title: {
        padding: theme.spacing(0, 1),
        textAlign: 'center',
    },
    activeTitle: {
        ...cssFgColor(theme, 'standard', '#423F37'),
        fontWeight: 700,
    },
    svg: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        borderRadius: '50%',
        top: 12,
        right: 12,
        width: 26,
        height: 26,
        backgroundColor: '#FBBD2A',
    },
}));

export function GiftCardTheme({
    className = '',
    active = false,
    title,
    image,
    onClick,
}: GiftCardThemeProps) {
    const classes = useStyles();
    return (
        <div
            className={clsx(classes.root, className)}
            onClick={onClick}
        >
            {image && (
                <div className={clsx(classes.image, active && classes.active)}>
                    <Img
                        {...image}
                        objectFit="contain"
                    />
                    {active && (
                        <>
                            <div className={classes.overlay} />
                            <div className={classes.svg}>
                                <SvgCheck
                                    width={16}
                                    height={16}
                                    color="#000000"
                                />
                            </div>
                        </>
                    )}
                </div>
            )}
            <div className={clsx(classes.title, active && classes.activeTitle)}>{title}</div>
        </div>
    );
}

export interface GiftCardThemeProps {
    onClick?: any;
    className?: string;
    active?: boolean;
    title: any;
    image: image;
}

export default GiftCardTheme;
