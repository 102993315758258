import React, {useCallback, useState} from 'react';
import clsx from 'clsx';
import {cssText} from '@ohoareau/css-utils';
import {Button, Checkbox, UneditableTextField} from '../atoms';
import {makeStyles} from '@material-ui/core/styles';
import useLuniiTranslation from '../hooks/useLuniiTranslation';
import {buttonifyFromProps} from '../hocs';
import {AddressTypeEnum, Clickable, CountryStatesHelper} from '../index';

const useStyles = makeStyles(theme => ({
    button: {
        marginRight: 10,
    },
    fieldset: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
    },
    input: {
        marginBottom: theme.spacing(2),
        width: '100%',
    },
    half: {
        flex: 1,
    },
    searchAddresses: {
        backgroundColor: '#FAF9F8',
        borderRadius: 8,
        padding: theme.spacing(2),
    },
    searchAddressTitle: {
        ...cssText(theme, 'standard', 'caption'),
        color: '#908977',
        marginBottom: theme.spacing(2),
    },
    selectAddress: {
        width: 'auto',
        ...cssText(theme, 'standard', 'body_thick', undefined, undefined, '#503C0D'),
        margin: theme.spacing(2, 0),
        padding: 8,
        borderRadius: 4,
        '&:first-child': {
            marginTop: 0,
        },
        '&:last-child': {
            marginBottom: 0,
        },
        cursor: 'pointer',
    },
    selected: {
        backgroundColor: '#FEECC3',
    },
    noResults: {
        ...cssText(theme, 'standard', 'body_thick', undefined, undefined, '#503C0D'),
        margin: 0,
    },
    notFound: {
        margin: theme.spacing(3, 0),
        width: 'auto',
        display: 'inline-block',
        padding: 0,
    },
    checkbox: {
        ...cssText(theme, 'standard', 'button_2', undefined, undefined, 'link'),
        margin: theme.spacing(1, 0, 3, 0),
    },
    third: {
        [theme.breakpoints.up('lg')]: {
            width: 'calc(33.33% - 16px)',
        },
    },
    twoThird: {
        [theme.breakpoints.up('lg')]: {
            width: 'calc(66.66% - 16px)',
        },
    },
    threeQuarters: {
        [theme.breakpoints.up('lg')]: {
            width: 'calc(75% - 16px)',
        },
    },
}));

export function SearchAddress({className, onCancel, onSubmit, selectedAddress, foundAddresses = [], locale, addressType, loading}: SearchAddressProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const [searchSelectedAddressIndex, setSearchSelectedAddressIndex] = useState<any>(0);
    const [forceAddress, setForceAddress] = useState<boolean>(false);
    const handleValidate = useCallback(() => {
        const address = forceAddress
            ? {...selectedAddress}
            : {...selectedAddress, ...foundAddresses[searchSelectedAddressIndex]};
        onSubmit(address);
    }, [searchSelectedAddressIndex, selectedAddress, forceAddress, foundAddresses]);
    const [forceAddressContainer, setForceAddressContainer] = useState<boolean>(false);
    const closeForceAddressContainer = useCallback(() => setForceAddressContainer(false), []);
    const {Button: ValidateButton, Button2: ReturnButton} = buttonifyFromProps({
        buttonLabel: t('form_address_update_address'),
        buttonTarget: handleValidate,
        button2Label: t('form_address_cancel_edit'),
        button2Target: forceAddressContainer ? closeForceAddressContainer : onCancel,
    }, ['primary,size=small_mobile_only', 'plain,size=small_mobile_only']);
    const handleSelectAddressClick = useCallback((index: any) => {
        setSearchSelectedAddressIndex(index);
    }, []);
    const handleForceAddressClick = useCallback(() => setForceAddress(!forceAddress), [forceAddress]);
    return (
        <div className={className}>
            <div className={classes.fieldset}>
                <UneditableTextField name={'firstName'} value={selectedAddress?.firstName} className={clsx(classes.input, classes.half)}/>
                <UneditableTextField name={'lastName'} value={selectedAddress?.lastName} className={clsx(classes.input, classes.half)}/>
            </div>
            <div>
                {forceAddressContainer
                    ? (
                        <div className={classes.fieldset}>
                            <UneditableTextField name={'address1'} value={selectedAddress?.address1} className={classes.input}/>
                            {selectedAddress?.address2 && <UneditableTextField name={'address2'} value={selectedAddress?.address2} className={clsx(classes.input, classes.threeQuarters)}/>}
                            <UneditableTextField name={'zipCode'} value={selectedAddress?.zipCode} className={clsx(classes.input, classes.third)}/>
                            <UneditableTextField name={'city'} value={selectedAddress?.city} className={clsx(classes.input, classes.twoThird)}/>
                            {CountryStatesHelper.countryFromLocaleHasStates(locale) &&
                                <UneditableTextField name={'state'} value={CountryStatesHelper.stateFromValue(locale, selectedAddress?.state)} className={clsx(classes.input, classes.twoThird)}/>
                            }
                            <UneditableTextField name={'country'} value={selectedAddress?.country} className={clsx(classes.input, classes.half)}/>
                        </div>
                    ) : (
                        <div>
                            <div className={classes.searchAddressTitle}>{t('form_address_search_address_select')}</div>
                            <div className={classes.searchAddresses}>
                                {foundAddresses.length > 0
                                    ? foundAddresses.map((address: any, index) => (
                                        <Clickable onClick={() => handleSelectAddressClick(index)} key={index}
                                            className={clsx(classes.selectAddress, searchSelectedAddressIndex === index && classes.selected)} >
                                            {`${address.address1}, ${address.zipCode}, ${address.city}, `
                                                + `${address.state ? CountryStatesHelper.stateFromValue(locale, address.state) + ', ' : ''} `
                                                + `${address.country}`}
                                        </Clickable>
                                    ))
                                    : !loading && <p className={classes.noResults}>{`${t('form_address_search_no_address_found')} "${t('form_address_search_address_not_found')}".`}</p>
                                }
                            </div>
                        </div>
                    )
                }
            </div>
            <div>
                {forceAddressContainer
                    ? (
                        <Checkbox className={classes.checkbox} id={'forceAddress'} status={forceAddress} alignItems={'flex-start'}
                            content={addressType === AddressTypeEnum.Delivery ? t('form_address_search_address_force') : t('form_address_search_address_force_billing')}
                            onChange={handleForceAddressClick} name={'forceAddress'}/>
                    ) : (
                        <Button color={'tertiary'} className={classes.notFound} size={'small_mobile_only'} noPadding
                            onClick={setForceAddressContainer}>{t('form_address_search_address_not_found')}</Button>
                    )
                }
            </div>
            <div>
                {ValidateButton && <ValidateButton disabled={!forceAddress && (foundAddresses.length < 1 || forceAddressContainer)} className={classes.button}/>} {/* validate button */}
                {ReturnButton && <ReturnButton/>} {/* return button */}
            </div>
        </div>
    );
}

export interface SearchAddressProps {
    className?: any,
    onCancel?: any,
    onSubmit?: any,
    locale: string,
    selectedAddress: any,
    foundAddresses: any,
    addressType: AddressTypeEnum,
    loading: boolean,
}

export default SearchAddress;
