import {useCallback} from 'react';
import useLuniiShoppingCartAvailableShippingMethodsFindApi from './useLuniiShoppingCartAvailableShippingMethodsFindApi';

export function useShoppingCartShippingMethodsFindSubmit() {
    const [findCartAvailableShippingMethods, {loading, error, ...infos}] = useLuniiShoppingCartAvailableShippingMethodsFindApi();
    const onSubmit = useCallback(async (locale) => {
        try {
            await findCartAvailableShippingMethods({
                variables: {
                    locale
                }
            });
        } catch (e) {
            console.error(e);
        }
    }, [findCartAvailableShippingMethods]);
    const data = (((infos || {}).data || {}).findCartAvailableShippingMethods || {}).items;
    return [onSubmit, {loading: Boolean(loading), error: error || false, data}] as [any, any];
}

export default useShoppingCartShippingMethodsFindSubmit
