import React from 'react';
import {book_product} from '../../ui';

export function BookProduct({product}: BookProductProps) {
    return (
        <>
            BOOK - {product.id} - {product.title}
        </>
    );
}

export interface BookProductProps {
    product: book_product;
}

export default BookProduct;
