import React, {forwardRef, useCallback, useMemo} from 'react';
import {cssText} from '@ohoareau/css-utils';
import clsx from 'clsx';
import {FormControl, FormHelperText, makeStyles, MenuItem, Select} from '@material-ui/core';
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form';
import {getCountryCallingCode, isPossiblePhoneNumber} from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import {Control} from 'react-hook-form';
import {CountryCode} from 'libphonenumber-js';
import TextField, {TextFieldProps} from './TextField';
import ArrowDown from '../../images/icons/ArrowDown';
import {DynamicIcon} from '../../nucleons';
import {useLuniiTranslation} from '../../hooks';

const useCountrySelectStyles = makeStyles((theme) => ({
    selectWrapper: {
        margin: theme.spacing(1, 0, 0.5),
        marginRight: theme.spacing(1),
        borderRadius: theme.spacing(1),
        boxSizing: 'border-box',
        '& fieldset': {
            border: 'none',
        },
    },
    error: {
        border: '2px solid #F25129 !important',
    },
    menuPaper: {
        borderRadius: theme.spacing(1),
    },
    menuList: {
        padding: theme.spacing(1),
        width: 'auto',
        boxSizing: 'border-box',
    },
    menuItem: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        borderRadius: theme.spacing(1),
        margin: theme.spacing(1, 0),
        padding: theme.spacing(0.8, 0.5, 0.6, 1),
        boxSizing: 'border-box',
        width: '100%',
        ...cssText(theme, 'standard', 'body_thick', undefined, undefined, '#423f37'),

        '& span': {
            width: '100%',
        },
    },
    menuItemSelected: {
        backgroundColor: '#FFF9EC !important',
    },
    menuItemIcon: {
        color: '#FBBD2A',
        marginRight: theme.spacing(0.5),
    },
}));

const useForwardCountrySelectStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#E6EEFF',
        color: '#2762E9 !important',
        padding: '13px 8px 12px 8px',
        ...cssText(theme, 'standard', 'body_thick', undefined, undefined, '#2762E9'),
        fontSize: '18px',
        transition: 'border .35s ease',
        '&:hover': {
            borderColor: '#2762E9',
        },
        '&::placeholder': {
            opacity: 1,
        },
    },
    select: {
        borderRadius: theme.spacing(1),
        '&:focus': {
            borderRadius: theme.spacing(1),
            backgroundColor: '#E6EEFF',
            border: '2px solid #2762E9',
        },
    },
    disabled: {
        cursor: 'not-allowed',
        color: '#908977 !important',
    },
    outlined: {
        borderRadius: theme.spacing(1),
        border: '2px solid #E6EEFF',
    },
    icon: {
        color: '#2762E9',
        marginTop: theme.spacing(0.25),
    },
}));

/**
 * The undefined is used to allow the 'international value' allowing users to enter a phone indicative not present in
 * the following list.
 */
const allowedShippingCountries = ['BE', 'CA', 'CH', 'DE', 'FR', 'IT', 'LU', 'MC', 'MX', 'UK', 'US'];

export const ForwardCountrySelectComponent = forwardRef((props: ForwardCountrySelectProps, ref) => {
    const {
        options,
        onChange: _onChange,
        iconComponent: IconComponent,
        value,
        className,
        ...rest
    } = props;
    const classes = useCountrySelectStyles();
    const {t, exists} = useLuniiTranslation();
    const filteredOptions = useMemo<{value: string; label: string}[]>(
        () => options.filter((opt) => allowedShippingCountries.includes(opt?.value || undefined)),
        [options, allowedShippingCountries],
    );
    const onChange = useCallback(
        (event) => {
            _onChange(event.target.value);
        },
        [_onChange],
    );

    function RenderValue(val) {
        return <span>{(val && val !== '' && `+${getCountryCallingCode(val)}`) || ' + '}</span>;
    }

    return (
        <Select
            key={'phone'}
            className={clsx(className, classes.selectWrapper)}
            classes={useForwardCountrySelectStyles()}
            value={value}
            onChange={onChange}
            {...rest}
            variant={'outlined'}
            inputRef={ref}
            IconComponent={ArrowDown}
            renderValue={RenderValue}
            MenuProps={{classes: {paper: classes.menuPaper, list: classes.menuList}}}
            displayEmpty={true}
        >
            {filteredOptions.map((opt) => (
                <MenuItem
                    className={classes.menuItem}
                    classes={{
                        selected: classes.menuItemSelected,
                    }}
                    key={opt.value || 'ZZ'}
                    value={opt.value}
                    disableRipple={true}
                >
                    <span>
                        {exists(`country_${opt.value?.toLowerCase()}_label`)
                            ? t(`country_${opt.value?.toLowerCase()}_label`)
                            : opt.label}
                        {opt.value && ` +${getCountryCallingCode(opt.value as CountryCode)}`}
                    </span>
                    {value === opt.value && (
                        <DynamicIcon type={'check'} className={classes.menuItemIcon} />
                    )}
                </MenuItem>
            ))}
            <MenuItem
                className={classes.menuItem}
                classes={{
                    selected: classes.menuItemSelected,
                }}
                key={'ZZ'}
                value={''}
                disableRipple={true}
            >
                <span>{t('fields_phone_international_label')}</span>
                {!value && <DynamicIcon type={'check'} className={classes.menuItemIcon} />}
            </MenuItem>
        </Select>
    );
});

export interface ForwardCountrySelectProps {
    options: any;
    value: string | undefined;
    className: string;
    onChange: Function;
    iconComponent: any;
}

const useForwardInputComponentStyles = makeStyles((theme) => ({
    root: {
        marginBottom: 0,
    },
}));

export const ForwardInputComponent = forwardRef((props, ref) => {
    const classes = useForwardInputComponentStyles();
    return (
        <TextField name={'phoneInput'} inputClassName={classes.root} inputRef={ref} {...props} />
    );
});

const usePhoneFieldStyles = makeStyles((theme) => ({
    root: {
        display: 'block',
        width: '100%',
        marginBottom: theme.spacing(2),
        '& .PhoneInput': {
            marginBottom: 0,
        },
    },
    input: {
        border: 'none',
        marginBottom: '0 !important',
    },
    formHelper: {
        marginTop: 4,
    },
}));

export function PhoneField({
    defaultCountry = 'FR',
    control,
    errors,
    helperText,
    message,
    ...props
}: PhoneFieldProps) {
    const classes = usePhoneFieldStyles();
    const {t} = useLuniiTranslation();
    const phoneInputRules = {
        required: props.required ? t('field_required_error') : undefined,
        validate: (val) =>
            !val || (!!val && isPossiblePhoneNumber(val)) || 'fields_phone_format_error', // if no value or value is a possible number
    };
    const name = 'phone';
    const i18nPrefix = `fields_${name}`;
    const error = !!(errors && name && errors[name]);
    helperText = error
        ? t([errors[name].message || 'error'])
        : t([helperText || '', `${i18nPrefix}_helper`, helperText || '']);
    return (
        <FormControl className={classes.root} error={error}>
            <PhoneInputWithCountry
                name={name}
                className={classes.input}
                control={control}
                error={error}
                rules={phoneInputRules}
                countrySelectComponent={ForwardCountrySelectComponent}
                inputComponent={ForwardInputComponent}
                withCountryCallingCode={true}
                defaultCountry={defaultCountry as CountryCode}
                {...props}
            />
            {error ? (
                <FormHelperText className={classes.formHelper}>{helperText}</FormHelperText>
            ) : null}
            {message ? message : null}
        </FormControl>
    );
}

export interface PhoneFieldProps extends TextFieldProps {
    control: Control;
    defaultCountry: string | undefined;
    message?: React.ReactNode;
}

export default PhoneField;
