import React, {useMemo} from 'react';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import HiddenCss from '@material-ui/core/Hidden/HiddenCss';
import {FullScreenLayout} from './FullScreenLayout';
import ImagePanel from '../molecules/ImagePanel';
import {FormPanel, Row} from '../../ui';

const useStyles = makeStyles({
    root: {
        flex: 1,
        display: 'flex',
        backgroundColor: '#FAF9F8',
    },
    leftCol: {
        flex: 1,
        backgroundColor: '#FFFFFF',
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
        paddingRight: 24,
        paddingLeft: 78,
    },
    rightCol: {
        flex: 2,
        display: 'flex',
        alignItems: 'center',
    },
    gutterCol: {
        flex: 2,
    },
    centralCol: {
        flex: 4,
    },
});

export function ActionFullScreenLayout({
    onClose,
    form,
    image,
    formProps,
    title,
    description,
    customFormPanel,
    deviceReturnContext,
    ...props
}: any) {
    const classes = useStyles();
    const imagePanel = useMemo(() => <ImagePanel image={image} />, [image]);
    const formPanel = customFormPanel || (
        <FormPanel
            {...props}
            onClose={onClose}
            form={form}
            title={title}
            description={description}
            formProps={formProps}
            deviceReturnContext={deviceReturnContext}
        />
    );
    return (
        <>
            <HiddenCss mdDown>
                <FullScreenLayout>
                    <div className={classes.root}>
                        <div className={classes.leftCol}>{formPanel}</div>
                        <div className={classes.rightCol}>{imagePanel}</div>
                    </div>
                </FullScreenLayout>
            </HiddenCss>
            <HiddenCss
                smDown
                lgUp
            >
                <FullScreenLayout>
                    <div className={classes.root}>
                        <div className={classes.gutterCol} />
                        <div className={classes.centralCol}>{formPanel}</div>
                        <div className={classes.gutterCol} />
                    </div>
                    <div className={classes.root}>
                        <div className={classes.gutterCol} />
                        <div className={classes.centralCol}>{imagePanel}</div>
                        <div className={classes.gutterCol} />
                    </div>
                </FullScreenLayout>
            </HiddenCss>
            <HiddenCss mdUp>
                <FullScreenLayout>
                    <Row padding="none">
                        <Grid
                            item
                            container
                            sm={12}
                        >
                            {formPanel}
                        </Grid>
                    </Row>
                </FullScreenLayout>
            </HiddenCss>
        </>
    );
}

export default ActionFullScreenLayout;
