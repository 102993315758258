import React from 'react';
import {cssShadow, cssText} from '@ohoareau/css-utils';
import Clickable from '../Clickable';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Img from '../nucleons/Img';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        ...cssShadow(theme, 'level_02'),
        backgroundColor: 'white',
        padding: theme.spacing(2),
        borderRadius: 16,
        '&:hover': {
            ...cssShadow(theme, 'level_03'),
            transition: 'box-shadow .5s',
        },
    },
    imageContainer: {
        flex: 1.2,
        marginRight: theme.spacing(2),
    },
    image: {
        lineHeight: 0,
        height: 'initial',
    },
    container: {
        flex: 2.8,
    },
    title: {
        ...cssText(theme, 'standard', 'title_2'),
    },
    text: {
        ...cssText(theme, 'standard', 'secondary_body'),
        color: '#716C5E',
    },
    text2: {
        ...cssText(theme, 'standard', 'body_thick'),
    },
}));

export function ContentItemMosaic2({title, text, text2, buttonTarget, image, className}: ContentItemMosaicProps) {
    const classes = useStyles();
    return (
        <Clickable onClick={buttonTarget} className={clsx(classes.root, className)}>
            <div className={classes.imageContainer}>
                <div className={classes.image}><Img {...image} /></div>
            </div>
            <div className={classes.container}>
                <div className={classes.title}>{title}</div>
                <div className={classes.text}>{text}</div>
                {text2 && <div className={classes.text2}>{text2}</div>}
            </div>
        </Clickable>
    );
}

export interface ContentItemMosaicProps {
    title?: any,
    text?: any,
    text2?: any,
    buttonTarget?: any,
    image?: any,
    className?: string
}

export default ContentItemMosaic2;
