import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {cssText} from '@ohoareau/css-utils';
import {convertTimestampToDDMMYYYY, useLuniiTranslation} from '../../../../ui';
import {SubscriptionGiftMetadata} from '../../../../definitions/subscriptionGift';

export interface SubscriptionGiftMetadataListDisplayProps {
    metadata: SubscriptionGiftMetadata;
}

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 0,
        padding: 0,
        '& li': {
            wordBreak: 'break-word',
            ...cssText(theme, 'standard', 'body', undefined, undefined, '#5F6769'),
            '& span': {
                ...cssText(theme, 'standard', 'body_thick'),
            },
        },
    },
}));

export function SubscriptionGiftMetadataListDisplay({
    metadata,
}: SubscriptionGiftMetadataListDisplayProps) {
    const classes = useStyles();
    const {senderName, receiverEmail, receiverName, message, sendingDate} = metadata;
    const {t} = useLuniiTranslation();
    return (
        <ul className={classes.root}>
            {receiverName && (
                <li>
                    <span>{t('subscriptiongift_metadatalist_receiverName')} : </span>
                    {receiverName}
                </li>
            )}
            {receiverEmail && (
                <li>
                    <span>{t('subscriptiongift_metadatalist_receiverEmail')} : </span>
                    {receiverEmail}
                </li>
            )}
            {message && (
                <li>
                    <span>{t('subscriptiongift_metadatalist_message')} : </span>
                    {message}
                </li>
            )}
            {senderName && (
                <li>
                    <span>{t('subscriptiongift_metadatalist_senderName')} : </span>
                    {senderName}
                </li>
            )}
            {sendingDate && (
                <li>
                    <span>{t('subscriptiongift_metadatalist_sendingDate')} : </span>
                    {convertTimestampToDDMMYYYY(sendingDate)}
                </li>
            )}
        </ul>
    );
}

export default SubscriptionGiftMetadataListDisplay;
