import {useCallback, useMemo} from 'react';
import useLuniiCart from './useLuniiCart';
import useShoppingCartAnonymousComputeSubmit from './useShoppingCartAnonymousComputeSubmit';
import useShoppingCartDiscountCodeActivateSubmit from './useShoppingCartDiscountCodeActivateSubmit';
import useShoppingCartDiscountCodeDeleteSubmit from './useShoppingCartDiscountCodeDeleteSubmit';
import useShoppingCartInvalidateSubmit from './useShoppingCartInvalidateSubmit';
import useShoppingCartValidateSubmit from './useShoppingCartValidateSubmit';
import useShoppingCartResetSubmit from './useShoppingCartResetSubmit';
import useShoppingCartSaveSubmit from './useShoppingCartSaveSubmit';
import useShoppingCartRefresh from './useShoppingCartRefresh';
import useLuniiEventTracking from './useLuniiEventTracking';
import {cart, cart_item, product, ProductTableNameEnum} from '../types';

function formatProductItemLevelExtras (types: any[], key: string): string {
    return types
        .filter(type => !!type)
        .filter(type => type.includes(key))
        .map(entry => entry.split('-').slice(1).join(' '))
        .join('-');
}

function handleProductExtraInfos(product) {
    const {type, subtype, catalog, locale, metadata} = product;

    let age: string | undefined;
    let theme: string | undefined;
    let albumType: string | undefined;
    let productType: string | undefined;
    let foreignLang = locale !== catalog ? locale : undefined;

    switch (type) {
        case ProductTableNameEnum.Packs:
            productType = 'audio';
            break;
        case ProductTableNameEnum.GiftCards:
            productType = 'carte_cadeau';
            break;
        case ProductTableNameEnum.Hardware:
            productType = subtype === 'paperbook' ? 'papier' : 'materiel';
            break;
        default:
            break;
    }

    if (product.__typename && (product.__typename === 'Lunii_DigitalAlbumProduct' || product.__typename === 'Lunii_HardwareProduct')) { // If the product is added from a product page
        const {types, themes, ageRecommendations} = product;
        theme = (themes || []).map((theme: any) => (theme?.name)).join('-').toLowerCase();
        age = (ageRecommendations || []).map((age: any) => (age?.name)).join('-').toLowerCase();
        albumType = types?.name?.toLowerCase();
    } else { // if the product is added using a listing
        const {types} = product;
        age = types ? formatProductItemLevelExtras(types,'age-') : undefined;
        theme = types ? formatProductItemLevelExtras(types, 'theme-') : undefined;
        albumType = types ? formatProductItemLevelExtras(types, 'type-') : undefined;
    }

    return {
        productType,
        age,
        theme,
        albumType,
        foreignLang,
        locale,
        metadata,
    };
}

export function useShoppingCart(callback: Function|undefined = undefined) {
    const [cart, setCart, resetCart] = useLuniiCart();
    const eventFnFactory = useCallback(eventName => async (...args) => {
        switch (eventName) {
            case 'saved':
                // @ts-ignore
                setCart && args && args.length && setCart(args[0]);
                break;
        }
        callback && (await callback(eventName, ...args));
        return args[0] || undefined;
    }, [setCart, callback]);
    const fns = useMemo(() => ({
        onAnonymousCartComputed: eventFnFactory('anonymous_cart_computed'),
        onDiscountCodeActivated: eventFnFactory('discount_code_activated'),
        onDiscountCodeDeleted: eventFnFactory('discount_code_deleted'),
        onInvalidated: eventFnFactory('invalidated'),
        onValidated: eventFnFactory('validated'),
        onReset: eventFnFactory('reset'),
        onSave: eventFnFactory('saved'),
        onRefreshed: eventFnFactory('refreshed'), // cannot be used because of lazy query
    }), [eventFnFactory]);

    const handleEventTracking = useLuniiEventTracking();

    // @todo try to optimize to avoid systemic rerender
    const refreshHookResult = useShoppingCartRefresh();
    const anonymousComputeHookResult = useShoppingCartAnonymousComputeSubmit(fns.onAnonymousCartComputed);
    const discountCodeActivateHookResult = useShoppingCartDiscountCodeActivateSubmit(fns.onDiscountCodeActivated);
    const discountCodeDeleteHookResult = useShoppingCartDiscountCodeDeleteSubmit(fns.onDiscountCodeDeleted);
    const invalidateHookResult = useShoppingCartInvalidateSubmit(fns.onInvalidated);
    const validateHookResult = useShoppingCartValidateSubmit(fns.onValidated);
    const resetHookResult = useShoppingCartResetSubmit(fns.onReset);
    const saveHookResult = useShoppingCartSaveSubmit(fns.onSave);
    const deleteItemHookResult = useMemo(() => {
        return [
            async (id: string, quantity: number = -1) => {
                if (!cart?.items?.length) return;
                let c: cart = cart as cart;
                let items = (c.items as cart_item[]);
                let index = items.findIndex(x => x.id === id);
                if (index >= 0) {
                    if (quantity < 0) {
                        items.splice(index, 1);
                    }
                    else {
                        items[index] = {...items[index], quantity: items[index].quantity - quantity};
                        if (items[index].quantity <= 0) {
                            items.splice(index, 1);
                        }
                    }
                }
                c.items = [...items];
                delete c.leftToPay;
                delete c.giftCard;
                if (c.items.length === 0) resetCart();
                else {
                    c.price = c.items.length > 0 ? c.items.reduce((p, {price, quantity}) => p + quantity * price, 0) : 0;
                    c.priceWithoutReduction = c.price; // right now they are equals
                    c = {...c};
                    //const newCart = await saveHookResult[0](c);
                    //refreshHookResult[0](c); // @todo use newCart?
                    setCart(c);
                }
            },
            {loading: false, error: undefined, data: {}},
        ];
    }, [cart, setCart]);
    const addItemHookResult = useMemo(() => {
        return [
            async (id: string, reference: string, quantity: number = 1, product: any = {}, libelle?: string, pageModel?: string) => {
                let c: cart = (cart || {}) as cart;
                c.country = product?.catalog || 'fr_FR'; // @todo do not hardcode
                c.currency = product?.currency || 'EUR'; // @todo do not hardcode
                c.items = c.items || [] as cart_item[];
                const index = c.items.findIndex(x => x.id === id);
                if (index >= 0) {
                    c.items[index] = {...c.items[index], quantity: c.items[index].quantity + quantity};
                } else {
                    c.items.push({
                        id,
                        quantity,
                        reference,
                        price: product?.price,
                        priceExclTax: product?.priceExclTax,
                        oldPrice: product?.oldPrice,
                        name: product?.name,
                        currency: product?.currency,
                        thumbnailUrl: product?.image?.url,
                        type: product?.type,
                        subtype: product?.subtype,
                        ...handleProductExtraInfos(product),
                        productFromWishlist: libelle === 'liste_souhaits',
                        deviceTarget: product?.deviceTarget,
                        customBook: product?.customBook,
                    });
                }
                c.items = [...c.items.map((item: any) => ({
                    ...item,
                    quantity: item.type === 'packs' ? 1 : item.quantity,
                }))];
                delete c.leftToPay;
                delete c.giftCard;
                c.price = c.items.length > 0 ? c.items.reduce((p, {price, quantity}) => p + quantity * price, 0) : 0;
                c.priceWithoutReduction = c.price; // right now they are equals
                c = {...c};
                //const newCart = await saveHookResult[0](c);
                //refreshHookResult[0](c); // @todo use newCart?
                setCart(c);
                let tracking: any = {categorie: 'ecommerce', action: 'ajout_panier'};
                if (libelle) tracking = {...tracking, libelle};
                handleEventTracking(tracking, {...product, quantity}, pageModel);
            },
            {loading: false, error: undefined, data: {}},
        ];
    }, [cart, setCart, handleEventTracking]);
    const updateItemQuantityHookResult = useMemo(() => {
        return [
            async (id: string, quantity: number) => {
                if (!cart?.items?.length) return;
                let c: cart = cart as cart;
                let items = (c.items as cart_item[]);
                let index = items.findIndex(x => x.id === id);
                if (!items[index] || items[index].type === 'packs') return;
                items[index] = {...items[index], quantity};
                c.items = [...items];
                c.price = c.items.length > 0 ? c.items.reduce((p, {price, quantity}) => p + quantity * price, 0) : 0;
                c.priceWithoutReduction = c.price; // right now they are equals
                delete c.leftToPay;
                delete c.giftCard;
                c = {...c};
                //const newCart = await saveHookResult[0](c);
                //refreshHookResult[0](c); // @todo use newCart?
                setCart(c);
            },
            {loading: false, error: undefined, data: {}},
        ];
    }, [cart, setCart]);
    const updateGiftCardHookResult = useMemo(() => {
        return [
            async (giftCardId: string|undefined) => {
                let c: cart = cart as cart;
                if (giftCardId) c.giftCard = {giftCardId};
                else c.giftCard = undefined;
                setCart(c);
            },
            {loading: false, error: undefined, data: {}},
        ];
    }, [cart, setCart]);

    const packInCart = useCallback((product: product|undefined) => {
        if (!product || !cart?.items) return false;
        return product?.type === ProductTableNameEnum.Packs
            && cart?.items?.some((p: any) => p.reference === product.reference);
    }, [cart?.items]);

    return useMemo(() => [
        {
            cart,
            packInCart,
            deleteCartItem: deleteItemHookResult[0] as (id: string, quantity?: number) => any|undefined,
            addCartItem: addItemHookResult[0] as (id: string, reference: string, quantity: number, product?: any, libelle?: string) => any|undefined,
            updateCartItemQuantity: updateItemQuantityHookResult[0] as (id: string, quantity?: number) => any|undefined,
            updateCartGiftCard: updateGiftCardHookResult[0] as (giftCardId: string|undefined) => any|undefined,
            computeAnonymousCart: anonymousComputeHookResult[0],
            activateCartDiscountCode: discountCodeActivateHookResult[0],
            deleteCartDiscountCode: discountCodeDeleteHookResult[0],
            invalidateCart: invalidateHookResult[0],
            validateCart: validateHookResult[0],
            resetCart: resetHookResult[0],
            saveCart: saveHookResult[0],
            refreshCart: refreshHookResult[0],
        },
        {
            deleteCartItem: deleteItemHookResult[1] as {loading: boolean, error: any|undefined, data: any|undefined},
            addCartItem: addItemHookResult[1] as {loading: boolean, error: any|undefined, data: any|undefined},
            updateCartItemQuantity: updateItemQuantityHookResult[1] as {loading: boolean, error: any|undefined, data: any|undefined},
            updateCartGiftCard: updateGiftCardHookResult[1] as {loading: boolean, error: any|undefined, data: any|undefined},
            computeAnonymousCart: anonymousComputeHookResult[1],
            activateCartDiscountCode: discountCodeActivateHookResult[1],
            deleteCartDiscountCode: discountCodeDeleteHookResult[1],
            invalidateCart: invalidateHookResult[1],
            validateCart: validateHookResult[1],
            resetCart: resetHookResult[1],
            saveCart: saveHookResult[1],
            refreshCart: refreshHookResult[1],
        },
    ], [
        cart, packInCart, anonymousComputeHookResult, discountCodeActivateHookResult, discountCodeDeleteHookResult,
        invalidateHookResult, validateHookResult, resetHookResult, saveHookResult, refreshHookResult,
        deleteItemHookResult, addItemHookResult, updateItemQuantityHookResult, updateGiftCardHookResult,
    ]) as [
        {
            cart: cart,
            packInCart: (product: product|undefined) => boolean,
            deleteCartItem: (id: string, quantity?: number) => any|undefined,
            addCartItem: (id: string, reference: string, quantity: number, product?: any, libelle?: string, pageModel?: string) => any|undefined,
            updateCartItemQuantity: (id: string, quantity?: number) => any|undefined,
            updateCartGiftCard: (id: string|undefined) => any|undefined,
            computeAnonymousCart: Function,
            activateCartDiscountCode: Function,
            deleteCartDiscountCode: Function,
            invalidateCart: Function,
            validateCart: Function,
            resetCart: Function,
            saveCart: Function,
            refreshCart: Function,
        },
        {
            cart: any,
            deleteCartItem: any,
            addCartItem: any,
            updateCartItemQuantity: any,
            updateCartGiftCard: any,
            computeAnonymousCart: any,
            activateCartDiscountCode: any,
            deleteCartDiscountCode: any,
            invalidateCart: any,
            validateCart: any,
            resetCart: any,
            saveCart: any,
            refreshCart: any,
        }
    ];
}

export default useShoppingCart;
