import React, {useCallback, useEffect, useState} from 'react';
import {cssText} from '@ohoareau/css-utils';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import MuiSlider from '@material-ui/core/Slider';
import clsx from 'clsx';
import {formatTimeToMinutes} from '../utils';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    times: {
        display: 'flex',
        justifyContent: 'space-between',
        ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#7C878B'),
    },
}));

const LuniiSlider = withStyles({
    root: {
        marginTop: 14,
        color: '#FBBD2A',
        height: 4,
        borderRadius: 2,
    },
    thumb: {
        height: 16,
        width: 16,
        backgroundColor: 'white',
        border: '4px solid #FBBD2A',
        boxSizing: 'border-box',
        borderRadius: '50%',
        marginTop: -6,
        marginLeft: -8,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 4,
        borderRadius: 2,
    },
    rail: {
        height: 4,
        borderRadius: 2,
        backgroundColor: '#E3E1DB',
        opacity: 1
    },
})(MuiSlider);

export function ExtractCardAudioTimeline({className = '', currentTime = 0, duration = 0, onTimelineClick = () => {}}: ExtractCardAudioTimelineProps) {
    const classes = useStyles();
    const [progress, setProgress] = useState(0); // progress bar width
    useEffect(() => setProgress(100 * currentTime / duration), // setProgress when currentTime changes
        [setProgress, currentTime, duration]);
    const handleChange = useCallback((event: any, newValue: number | number[]) => onTimelineClick(newValue),
        [onTimelineClick]);
    return (
        <div className={clsx(classes.root, className)}>
            <div className={classes.times}>
                <div>{formatTimeToMinutes(currentTime)}</div>
                <div>{formatTimeToMinutes(duration)}</div>
            </div>
            <LuniiSlider value={progress} onChange={handleChange} aria-labelledby='continuous-slider' />
        </div>
    );
}

export interface ExtractCardAudioTimelineProps {
    className?: string;
    currentTime?: number;
    duration?: number;
    onTimelineClick?: Function;
}

export default ExtractCardAudioTimeline
