export const getDateFormat = (locale: string) => {
    let format = `dd/MM/yyyy`; // default format
    switch ((locale || '').substring(0, 2)) {
        case 'en':
        case 'ca':
            format = 'MM/dd/yyyy';
            break;
        default:
            break;

    }
    return format;
};

export default getDateFormat;
