import React, {useMemo} from 'react';
import {cssShadow, cssText} from '@ohoareau/css-utils';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {Img, Clickable, convertPrice, useLuniiTranslation, buttonifyFromProps, LuniiProductProvider} from '../../../ui';
import {Tag} from '.';
import {tag_theme} from '../../../types';

const useStyles = makeStyles((theme) => ({
    content: {
        position: 'relative',
        width: '100%',
        paddingBottom: theme.spacing(2),
    },
    contentTop: {
        position: 'relative',
        width: '100%',
    },
    imageContainer: {
        backgroundColor: 'white',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: 320,
        width: 267,
        maxWidth: 267,
        boxSizing: 'border-box',
        padding: 16,
        borderRadius: 16,
        ...cssShadow(theme, 'level_02'),
        transition: 'box-shadow 0.2s ease',
        [theme.breakpoints.down('md')]: {
            height: 257,
            width: 216,
            maxWidth: 216,
        },
        [theme.breakpoints.down('sm')]: {
            height: 190,
            width: 160,
            maxWidth: 160,
        },
        '&:hover': {
            ...cssShadow(theme, 'level_03'),
        },
    },
    image: {
        width: '100%',
    },
    tag: {
        position: 'absolute',
        right: 16,
        bottom: 16,
        marginLeft: 16,
        [theme.breakpoints.down('sm')]: {
            right: 8,
            bottom: 8,
        },
    },
    infos: {
        marginTop: '16px',
    },
    title: {
        maxWidth: '100%',
        ...cssText(theme, 'standard', 'body_thick'),
        '&:hover': {
            textDecoration: 'underline',
        },
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': 2,
        overflow: 'hidden',
    },
    description: {
        ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#5F6769'),
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': 1,
        overflow: 'hidden',
        marginTop: 4,
    },
    button: {
        marginTop: 10,
    },
    reduction: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexWrap: 'wrap',
        marginTop: 4,
    },
    reductionPercentage: {
        ...cssText(theme, 'standard', 'button_3', undefined, undefined, '#7F1F08'),
        backgroundColor: '#F5E5E4',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 26,
        padding: theme.spacing(0, 1.5, 0, 1),
        clipPath: 'polygon(0 0, 0 100%, 86% 100%, 101% 50%, 86% 0%)',
        borderRadius: 4,
    },
    reductionOldPrice: {
        ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#5F6769'),
        textDecoration: 'line-through',
        marginLeft: 8,
        margin: '4px 0 2px 0',
    },
    outOfStock: {
        marginTop: theme.spacing(1),
    },
}));

export function ProductCard({
    product = undefined,
    title = undefined,
    description = undefined,
    image = undefined,
    imageLoading = undefined,
    buttonLabel = undefined,
    target = undefined,
    buttonTarget = undefined,
    buttonType = undefined,
    tagTheme = undefined,
    tagLabel = undefined,
    tagIcon = undefined,
}: ProductCardProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const buttonifyProps = useMemo(() => {
        if (buttonLabel && buttonTarget) {
            return {
                buttonTarget,
                buttonLabel,
                buttonType: buttonType ? `${buttonType},size=small` : 'plain,size=small,endIcon=next-16'
            }
        } if (product) {
            return {
                buttonTarget: product.stock && product.buttonTarget,
                buttonLabel: product.stock
                    ? product.price === 0
                        ? t('product_price_free')
                        : convertPrice(product.price, product.currency)
                    : undefined,
                buttonType: 'primary,size=small,startIcon=basket-16'
            }
        } return {}
    }, [buttonLabel, buttonTarget, buttonType, product, t]);
    const {Button} = buttonifyFromProps(buttonifyProps, [])
    if (product && product.notFound) return null; // hide module if product is defined but not found (prismic deoptimization)
    const _product = product || {};
    const _img = image || _product.image;
    const _title = title || _product.name;
    const _target = target || _product.target;
    const _alt = (_img && _img.alt) || _product.name;
    const showOutOfStock = (!buttonLabel || !buttonTarget) && product && !product.stock;
    return (
        <LuniiProductProvider value={product}>
            <div className={classes.content}>
                <div className={classes.contentTop}>
                    <Clickable className={classes.imageContainer} onClick={_target}>
                        {_img && (
                            <Img
                                className={classes.image}
                                objectFit='contain'
                                {..._img}
                                loading={imageLoading}
                                alt={_alt}
                            />
                        )}
                        {(tagLabel || tagIcon) && <Tag className={classes.tag} theme={tagTheme} icon={tagIcon} label={tagLabel} size='small'/> || false}
                    </Clickable>
                    <div className={classes.infos}>
                        <Clickable onClick={_target} className={classes.title}>{_title}</Clickable>
                        {description && <div className={classes.description}>{description}</div> || false}
                        {product && product.stock && product.reduction &&
                            (!buttonLabel || !buttonTarget) && (
                                <div className={classes.reduction}>
                                    <div className={classes.reductionPercentage}>
                                        {product.reduction}
                                    </div>
                                    <div className={classes.reductionOldPrice}>
                                        {convertPrice(product.oldPrice, product.currency)}
                                    </div>
                                </div>
                            ) || false}
                    </div>
                </div>
                {Button && <Button className={classes.button}/>}
                {showOutOfStock && <Tag className={classes.outOfStock} theme='tips' size='small' label={t('product_out_of_stock')}/>}
            </div>
        </LuniiProductProvider>
    );
}

export interface ProductCardProps {
    product?: any;
    title?: any;
    target?: any;
    buttonLabel?: string;
    buttonTarget?: string;
    buttonType?: string;
    description?: any;
    image?: any;
    imageLoading?: 'eager' | 'lazy';
    tagTheme?: tag_theme;
    tagLabel?: string;
    tagIcon?: string;
}

export default ProductCard;
