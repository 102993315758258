import React from 'react';
import {cssText, cssBorder} from '@ohoareau/css-utils';
import {buttonifyFromProps} from "../hocs";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Img from "../nucleons/Img";
import {WithArgument} from "../types";
import useLuniiThemes from "../hooks/useLuniiThemes";
import clsx from "clsx";

const useStyles = makeStyles(theme => {

    const themes = useLuniiThemes();

    return {
        root: {
            ...cssText(theme, 'standard', 'body', undefined, 'clear'),
            ...cssBorder(theme, 'blocks'),
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
        },
        shadow: {
            filter: 'drop-shadow(0px 6px 12px rgba(66, 63, 55, 0.1)) drop-shadow(0px -1px 12px rgba(66, 63, 55, 0.02))',
        },
        top: {
            flex: 1,
            padding: theme.spacing(4),
            [theme.breakpoints.down('lg')]: {
                padding: theme.spacing(3),
            },
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(3, 2),
            },
            backgroundColor: 'white',
            borderTopLeftRadius: theme.spacing(4),
            borderTopRightRadius: theme.spacing(4),
        },
        text: {
            ...cssText(themes['default'], 'standard', 'title_1'),
            '& b, & strong' : {
                ...cssText(themes['grey-color-for-argument-module-font'], 'standard', 'title_1'),
            },

            [theme.breakpoints.down('sm')]: {
                ...cssText(themes['default'], 'standard', 'title_2'),
                '& b, & strong' : {
                    ...cssText(themes['grey-color-for-argument-module-font'], 'standard', 'title_2'),
                },
            }
        },
        spacerContainer: {
            height: 310,
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.down('lg')]: {
                height: 230,
            },
            [theme.breakpoints.down('sm')]: {
                height: 150,
            }
        },
        button: {
            marginTop: theme.spacing(2),
        },
        image: {
            lineHeight: 0,
        },
    }
});

export function ArgumentBlock({text, image, noImage = false, noShadow = false,  ...props}: ArgumentBlockProps) {
    const classes = useStyles();
    const {Buttons} = buttonifyFromProps(props, ['plain']);
    return (
        <div className={clsx(classes.root, !noShadow && classes.shadow)}>
            <div className={classes.top}>
                <div className={classes.text}>{text}</div>
                {Buttons && <Buttons className={classes.button} />}
            </div>
            {!noImage && <div className={classes.image}><Img {...image} objectFit={'contain'} /></div>}
            {noImage && <div className={classes.spacerContainer}/>}
        </div>
    );
}

export interface ArgumentBlockProps extends WithArgument {
    text?: any,
    image?: any,
    noImage?: boolean,
    noShadow?: boolean,
}

export default ArgumentBlock
