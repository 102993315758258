import React from 'react';

export function CategoriesTabsModule(props: CategoriesTabsModuleProps) {
    return (
        <>TODO TABS (CATEGORIES)</>
    )
}

export interface CategoriesTabsModuleProps {
}

export default CategoriesTabsModule