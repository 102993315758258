import React, {MouseEventHandler, useCallback, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {cssText} from '@ohoareau/css-utils';
import clsx from 'clsx';
import {useForm} from 'react-hook-form';
import {useApolloClient} from '@apollo/client';
import {
    useHasMounted,
    useLuniiUser,
    useLuniiTranslation,
    useActivateCodeAlbum,
    useRetrieveCodeAlbumInfos,
    useLuniiApi,
    Button,
    ErrorPanel,
    Spinner,
    TextField,
    code_album_infos,
    RETRIEVE_CODE_ALBUMS_INFOS,
    MyCodeAlbumModal, useLuniiNavigation
} from '..';
import Link from "../../components/molecules/v2/Link";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        position: 'relative',
        boxSizing: 'border-box',
    },
    title: {
        marginTop: 0,
        ...cssText(theme, 'standard', 'title_2'),
        [theme.breakpoints.up('sm')]: {
            ...cssText(theme, 'standard', 'title_1'),
        },
    },
    text: {
        width: '100%',
        marginTop: theme.spacing(1),
        ...cssText(theme, 'standard', 'body'),
        [theme.breakpoints.up('sm')]: {
            ...cssText(theme, 'standard', 'body'),
        },
    },
    form: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        marginTop: theme.spacing(1),
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    input: {
        width: '100%',
        maxWidth: 250,
    },
    button: {
        width: '100%',
        marginLeft: theme.spacing(1.5),
        marginTop: 11,
        [theme.breakpoints.down('sm')]: {
            width: 'auto',
            margin: theme.spacing(2, 0, 1, 0),
        },
    },
    error: {
        margin: theme.spacing(2, 0, 0, 0),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(1, 0, 0, 0),
        },
    },
    libraryMobile: {
        marginTop: theme.spacing(2),
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        },
    },
    libraryDesktop: {
        marginTop: theme.spacing(2),
        display: 'block',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    }
}));

export function MyCodeAlbum({
    title,
    text,
    className,
    autoFocus = false,
    defaultCodeAlbumValue = undefined,
    validateOnMount = false,
    ...props
}: MyCodeAlbumProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const mounted = useHasMounted();
    const {user, setUser, refreshUserPartial} = useLuniiUser();
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const client = useApolloClient();
    const {gql} = useLuniiApi();
    const {goLibrary} = useLuniiNavigation();

    // validate code & retrieve infos
    const retrieveCodeAlbumInfosOnCompleted = useCallback((data) => {
        if (data?.retrieveCodeAlbumInfos) {
            setModalOpen(true);
            setCodeAlbumInfos(data.retrieveCodeAlbumInfos);
        }
    }, []);
    const [codeAlbumInfos, setCodeAlbumInfos] = useState<code_album_infos | undefined>(undefined);
    const [retrieveCodeAlbumInfos, {loading: codeAlbumInfosLoading, error: codeAlbumInfosError}] =
        useRetrieveCodeAlbumInfos({
            onCompleted: retrieveCodeAlbumInfosOnCompleted,
        });

    // activate code et send success or failure code
    const [codeAlbumActivated, setCodeAlbumActivated] = useState<boolean>(false);
    const [
        onActivateCodeAlbum,
        {loading: codeAlbumActivateLoading, error: codeAlbumActivateError},
    ] = useActivateCodeAlbum();

    // form hook setup
    const {handleSubmit, register, errors, watch, reset, setValue} = useForm({
        defaultValues: {
            codeAlbum: defaultCodeAlbumValue || '',
        },
    });

    const onSubmit = async (data) => {
        setCodeAlbumActivated(false);
        const variables = {
            data: {
                countryCode: user?.locale || 'fr_FR',
                codeAlbum: data.codeAlbum,
            },
        };
        // Check apollo cache for existing response entry
        const cachedValue = client?.readQuery({
            query: RETRIEVE_CODE_ALBUMS_INFOS(gql),
            variables: variables,
        });
        if (cachedValue?.retrieveCodeAlbumInfos) {
            // should the response exist, invoke success callback with cache value
            retrieveCodeAlbumInfosOnCompleted(cachedValue);
        } else {
            await retrieveCodeAlbumInfos({
                variables,
                notifyOnNetworkStatusChange: true,
            });
        }
    };
    const formHookOptions = {errors, register};
    const codeAlbum = watch('codeAlbum');

    useEffect(() => {
        if (validateOnMount && defaultCodeAlbumValue && mounted) {
            setValue('codeAlbum', defaultCodeAlbumValue);
            handleSubmit(onSubmit)();
        }
    }, [defaultCodeAlbumValue, validateOnMount, mounted]);

    // confirm code Activation
    const confirmCodeActivation = async (
        productId,
        currency,
        countryCode,
        codeAlbumId,
        packReference,
    ) => {
        const response = await onActivateCodeAlbum({productId, currency, countryCode, codeAlbumId});
        if (response?.success) {
            // if the code is activated, manually update the user ownedPacksReferences manually as the library update
            // on the userServer might be slower than the codeAlbum process
            setUser({
                ...user,
                ownedPacksReferences: Array.from(
                    new Set([...user?.ownedPacksReferences, packReference]),
                ),
            });
            await refreshUserPartial({library: true});
            setCodeAlbumActivated(true);
        }
        reset();
    };

    const handleClose: MouseEventHandler<HTMLButtonElement> = useCallback(
        (e) => {
            setModalOpen(false);
        },
        [setModalOpen],
    );

    return (
        <div className={clsx(classes.root, className)} {...props}>
            <MyCodeAlbumModal
                open={modalOpen}
                onClose={handleClose}
                codeAlbumInfos={codeAlbumInfos}
                loading={codeAlbumInfosLoading || codeAlbumActivateLoading}
                confirmCodeActivation={confirmCodeActivation}
                codeAlbumActivated={codeAlbumActivated}
                errors={codeAlbumActivateError}
            />

            <div className={classes.title}>{t('my_code_album_title')}</div>
            <div className={classes.text}>{t('my_code_album_body')}</div>
            <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
                <TextField
                    label={t('my_code_album_label')}
                    placeholder={t('my_code_album_placeholder')}
                    name={'codeAlbum'}
                    disabled={codeAlbumInfosLoading}
                    className={clsx(classes.input)}
                    autoFocus={autoFocus}
                    required
                    definition={{minLength: {value: 8, message: t('my_code_album_size_error')}}}
                    {...formHookOptions}
                />
                <div className={classes.button}>
                    <Button
                        type={'submit'}
                        size={'large'}
                        disabled={
                            codeAlbumInfosLoading || codeAlbum === '' || codeAlbum?.length < 8
                        }
                        color={'primary'}
                    >
                        {t('button_label_validate')}
                    </Button>
                </div>
            </form>
            {codeAlbumInfosError && !codeAlbumActivated && (
                <ErrorPanel error={codeAlbumInfosError} group='codeAlbum' className={classes.error} />
            )}
            <Link className={classes.libraryMobile} text={t('my_code_album_modal_go_to_library')} url={() => goLibrary && goLibrary()} size='medium'/>
            <Link className={classes.libraryDesktop} text={t('my_code_album_modal_go_to_library')} url={() => goLibrary && goLibrary()} size='large'/>

            {codeAlbumInfosLoading && <Spinner overlay size={32} />}
        </div>
    );
}

export interface MyCodeAlbumProps {
    title?: string;
    text?: string;
    className?: string;
    autoFocus?: boolean;
    defaultCodeAlbumValue?: string;
    validateOnMount?: boolean;
}

export default MyCodeAlbum;
