import React, {useMemo, useState} from 'react';
import {cssFgColor, cssText} from '@ohoareau/css-utils';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Row from '../Row';
import Grid from '@material-ui/core/Grid';
import SvgClose from '../images/icons/Close';
import clsx from 'clsx';
import {module_text} from '../types';
import Clickable from '../Clickable';
import {useLuniiEventTracking, useLuniiPage, useLuniiPageTracking} from '../hooks';

const useStyles = makeStyles((theme) => ({
    root: {
        ...cssText(theme, 'standard', 'body'),
        padding: theme.spacing(2, 3),
        [theme.breakpoints.down('sm')]: {
            ...cssText(theme, 'standard', 'secondary_body'),
            padding: theme.spacing(1, 2),
        },
    },
    container: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    text: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        '& strong': {
            textDecoration: 'underline',
        },
        [theme.breakpoints.down('sm')]: {
            paddingTop: 3,
        },
    },
    content: {
        ...cssFgColor(theme, 'standard', 'body'),
        width: 'auto',
        textAlign: 'left',
    },
    icon: {
        flex: 0.05,
        cursor: 'pointer',
        height: 14,
        width: 14,
    },
}));

export function AlertBanner({
    text,
    className,
    target,
    closable = false,
}: AlertBannerProps) {
    const classes = useStyles();
    const handleTrackingEvent = useLuniiEventTracking();
    const [show, setShow] = useState(true);
    const {pagesByModel} = useLuniiPage() || {};
    const model = useMemo(() => {
        const [_, store, link] = (target || '').split('/');
        return Object.keys(pagesByModel || {}).find(pageModel => ((pagesByModel || {})[pageModel] || {})[store] === link) || target;
    }, [pagesByModel, target]);
    const pageEvent = useLuniiPageTracking({envModel: `banniere_${model}`});
    const handleClose = () => {
        setShow(false);
    };
    const handleTargetClick = () => {
        if (target) {
            pageEvent();
            handleTrackingEvent({
                categorie: 'interaction',
                action: 'banniere',
                libelle: model,
            });
        }
    };
    if (!show) return null;
    return (
        <Row padding={'none'} className={clsx(classes.root, className)}>
            <Grid className={classes.container} container item lg={12} md={12} xs={12}>
                <div className={classes.text}>
                    {(target && (
                        <Clickable className={classes.content} onClick={target} onClickCb={handleTargetClick}>
                            {text}
                        </Clickable>
                    )) || <div className={classes.content}>{text}</div>}
                </div>
                {closable && <div className={classes.icon}><SvgClose onClick={handleClose} color={'currentColor'}/></div>}
            </Grid>
        </Row>
    );
}

export interface AlertBannerProps {
    className?: string;
    text?: module_text;
    target?: any;
    closable?: boolean;
}

export default AlertBanner;
