import React from 'react';
import {Helmet} from 'react-helmet';
import {page, head_base, head_link, head_meta, head_noscript, head_script, head_style} from '../types';
const defaultFavicon = require('../images/favicon.png');

export function PageHead({page: {head = {}, favicon = defaultFavicon, product}}: PageHeadProps) {
    return (
        <Helmet>
            <title>{head.title}</title>
            {product && (
                <script type="application/ld+json">
                    {`{
                        "@context": "https://schema.org/",
                        "@type": "Product",
                        "name": "${product.name?.replace(/"/g, '\'')}",
                        "image": "${product.image?.url}",
                        "offers": {
                            "@type": "Offer",
                            "availability": "${product.stock ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock'}",
                            "price": ${(product.price || 0)/100},
                            "priceCurrency": "${product.currency}"
                        }
                    }`}
                </script>
            )}
            {(head.meta || []).map((meta: head_meta, i: number) => {
                switch (meta.type) {
                    case 'charset':
                        return <meta
                            key={i}
                            charSet={meta.charset} />;
                    case 'http-equiv':
                        return <meta
                            key={i}
                            httpEquiv={meta.name}
                            content={meta.content} />;
                    case 'property':
                        return <meta
                            key={i}
                            property={meta.name}
                            content={meta.content} />;
                    default:
                        return <meta
                            key={i}
                            name={meta.name}
                            content={meta.content} />;
                }
            })}
            {!!favicon && <link
                rel="icon"
                href={favicon} />}
            {(head.link || []).map((link: head_link, i: number) => {
                switch (link.type) {
                    default: return (
                        <link key={i} rel={link.rel}
                            href={link.href}
                            {...(link.hrefLang ? {hrefLang: link.hrefLang} : {})}
                            {...(link.sizes ? {sizes: link.sizes} : {})}/>
                    );
                }
            })}
            {(head.script || []).map((script: head_script, i: number) => {
                return <script
                    key={i}
                    type={script.type || 'text/javascript'}
                    {...(script.src ? {src: script.src} : {})}>
                    {script.code || ''}
                </script>;
            })}
            {(head.noscript || []).map((noscript: head_noscript, i: number) => {
                return <noscript key={i}>
                    {noscript}
                </noscript>;
            })}
            {(head.style || []).map((style: head_style, i: number) => {
                return <style
                    key={i}
                    type={style.type || 'text/css'}>
                    {style.content}
                </style>;
            })}
            {(head.base || []).map((base: head_base, i: number) => {
                return <base
                    key={i}
                    target={base.target}
                    href={base.href} />;
            })}
            {!!head.body && <body {...(head.body.props || {})} />}
            {!!head.html && <html
                lang={'en'}
                {...(head.html.props || {})} />}
        </Helmet>
    );
}

export interface PageHeadProps {
    page: page,
}

export default PageHead;
