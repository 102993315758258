import React from 'react';
import Steppers from '../atoms/Steppers';
import {cssText} from '@ohoareau/css-utils';
import {makeStyles} from "@material-ui/core/styles";
import {module_overline, module_title} from "../types";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            margin: '64px 0',
            textAlign: 'center',
        },
        [theme.breakpoints.down('sm')]: {
            margin: '32px 16px',
            textAlign: 'center',
        },
    },
    content: {
        marginLeft: 24,
    },
    overline: {
        ...cssText(theme, 'standard', 'overline_large_title'),
        [theme.breakpoints.down('sm')]: {
            fontSize: 16, // todo change
        },
    },
    title: {
        ...cssText(theme, 'standard', 'large_title', undefined, undefined, 'body'),
        [theme.breakpoints.down('sm')]: {
            fontSize: 24, // todo change
        }
    },
}));

export function DemoStepper({title, overline, step, steps, onChange}: DemoStepperProps) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Steppers vertical step={step} steps={steps} onClick={onChange} />
            <div className={classes.content}>
                <div className={classes.overline}>{overline}</div>
                <div className={classes.title}>{title}</div>
            </div>
        </div>
    );
}

export interface DemoStepperProps {
    overline?: module_overline,
    title?: module_title,
    step: string,
    steps: string[],
    onChange?: Function,
}

export default DemoStepper
