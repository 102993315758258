import React from 'react';
import {MuiThemeProvider} from "@material-ui/core";
import useLuniiThemes from "./hooks/useLuniiThemes";

export function ForceTheme({theme = 'default', children}: ForceThemeProps) {
    const themes = useLuniiThemes();
    return (
        <MuiThemeProvider theme={old => ({...old, ...themes[theme]})}>
            {children || ''}
        </MuiThemeProvider>
    )
}

export interface ForceThemeProps {
    theme?: string,
    children?: any,
}

export default ForceTheme
