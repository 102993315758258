import React, {useCallback, useEffect, useMemo, useState} from 'react';
import clsx from 'clsx';
import {cssText} from '@ohoareau/css-utils';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import {PurchaseItem, ErrorPanel, Spinner} from '../atoms';
import {useLuniiPage, usePurchasesFind} from '../hooks';
import useLuniiTranslation from '../hooks/useLuniiTranslation';
import Pagination from './Pagination';
import {purchase} from '../types';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
        },
        title: {
            ...cssText(theme, 'standard', 'title_1'),
            margin: theme.spacing(0, 0, 4),
            [theme.breakpoints.down('sm')]: {
                ...cssText(theme, 'standard', 'title_2'),
                margin: theme.spacing(0, 0, 2),
            },
        },
        noPurchase: {
            ...cssText(theme, 'standard', 'body'),
            margin: theme.spacing(0, 0),
            [theme.breakpoints.down('sm')]: {
                ...cssText(theme, 'standard', 'secondary_body'),
                margin: theme.spacing(0),
            },
        },
        purchaseItem: {
            [theme.breakpoints.up('md')]: {
                marginLeft: '-16px !important', // align content with title
                width: 'calc(100% + 32px)', // enlarge component so the content is centered in parent
            },
        },
        purchasesPagination: {
            marginTop: theme.spacing(2),
        },
    };
});

const PURCHASE_PER_VIEW = 10;

export function MyPurchaseHistory({className, title}: MyPurchaseHistoryProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const pageContext = useLuniiPage();
    const [expanded, setExpanded] = useState<string | false>(false);
    const [page, setPage] = useState(1);
    const [purchases, setPurchases] = useState<purchase[]>([]);
    const [getPurchases, {loading, error}] = usePurchasesFind(setPurchases);

    useEffect(() => {
        getPurchases({withInvoices: true});
    }, []);

    const handlePageChange = useCallback((newPage) => {
        setPage(newPage);
    }, []);
    const toogle = useCallback(
        (itemId: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
            setExpanded(isExpanded ? itemId : false);
        },
        [],
    );

    const pageCount = useMemo(
        () =>
            purchases && purchases.length > PURCHASE_PER_VIEW
                ? Math.round(purchases.length / PURCHASE_PER_VIEW)
                : 1,
        [purchases],
    );
    const pagePurchases =
        purchases && purchases.slice((page - 1) * PURCHASE_PER_VIEW, page * PURCHASE_PER_VIEW);
    const timezone = Intl?.DateTimeFormat().resolvedOptions().timeZone;
    const locale = pageContext?.locale?.match(/[a-z]{2}-[a-z]{2}/gi) ? pageContext.locale : 'fr-fr';

    return (
        <div className={clsx(classes.root, className)}>
            {loading && <Spinner />}

            {title && (
                <Typography variant={'h3'} className={classes.title}>
                    {title}
                </Typography>
            )}

            <ErrorPanel error={error} />
            {pagePurchases && pagePurchases.length > 0 ? (
                pagePurchases.map((item) => (
                    <PurchaseItem
                        className={classes.purchaseItem}
                        key={`purchase_${item.id}`}
                        purchase={item}
                        timezone={timezone}
                        expanded={expanded}
                        toggle={toogle}
                        locale={locale}
                    />
                ))
            ) : (
                <Typography variant={'subtitle1'} className={classes.noPurchase}>
                    {t('my_purchase_history_no_purchase')}
                </Typography>
            )}

            {pageCount > 1 && (
                <Pagination
                    className={classes.purchasesPagination}
                    currentPage={page}
                    size={pageCount}
                    onPageChange={handlePageChange}
                />
            )}
        </div>
    );
}

export interface MyPurchaseHistoryProps {
    title?: string;
    className?: string;
}

export default MyPurchaseHistory;
