import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import ShoppingCartNavBarItem from '../atoms/ShoppingCartNavBarItem';
import LoginButton from '../atoms/LoginButton';
import ExtendedNavMenu from './ExtendedNavMenu';
import useLuniiUser from '../hooks/useLuniiUser';
import useHasMounted from '../hooks/useHasMounted';
import {NavBarRootModeEnum} from '../types';
import {SubscriptionNavBarItem} from './SubscriptionNavBarItem';
import {Img} from '../nucleons';
import SvgUser from '../images/icons/User';
import SvgLibrary from '../images/icons/Library';
import SvgList from '../images/icons/List';
import SvgLogo from '../images/Logo';
import SvgWish from '../images/icons/Wish';
import {useLuniiPage} from '../hooks';

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    left: {
        flex: 1,
    },
    center: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
    },
    logo: {
        cursor: 'pointer',
    },
    menuImage: {
        height: 40,
        width: 'auto',
    },
    right: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    icons: {
        display: 'flex',
        alignItems: 'center',
    },
    buttons: {
        marginLeft: 20,
    },
    menu: {},
    shadow: {
        height: 2,
        background: '#E4E1DA',
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: theme.spacing(2),
        transition: 'background-color .35s ease-out',
        padding: theme.spacing(0.5),
        cursor: 'pointer',
        borderRadius: '100%',
        '@media not all and (hover: none)': {
            '&:hover': {
                backgroundColor: '#FEF0CE',
            },
        },
    },
    subscriptionIcon: {
        '@media all': {
            '&:hover': {
                backgroundColor: 'unset',
            },
        },
    },
    shoppingCartIcon: {
        padding: 0,
        '@media all': {
            '&:hover': {
                backgroundColor: 'unset',
            },
        },
    },
    iconFirst: {
        marginLeft: 'unset',
    },
    iconActive: {
        cursor: 'default !important',
        backgroundColor: '#FEF0CE',
        borderRadius: '100%',
    },
}));

export function TabletNavBar({
    rootMode = NavBarRootModeEnum.Default,
    mode,
    menu,
    menuImage,
    section,
    onLogoClick,
    onUserClick,
    onExtendedClick,
    onSearchClick,
    onLibraryClick,
    onWishlistClick,
    onModeClose,
    page,
}: TabletNavBarProps) {
    const classes = useStyles();
    const {isLogged} = useLuniiUser();
    const hasMounted = useHasMounted();
    const {locale} = useLuniiPage() || {};
    const filteredMenu = menu.filter((m: any) => {
        const excludedLocales = (m.excludedLocales || '').split(',');
        return excludedLocales.indexOf((locale || '').slice(-2)) === -1;
    });
    return (
        <>
            {mode !== 'search' && mode !== 'extended' && (
                <div className={classes.content}>
                    <div className={classes.left}>
                        <div className={classes.icons}>
                            <div className={clsx(classes.icon, classes.iconFirst)}>
                                <SvgList onClick={onExtendedClick} />
                            </div>
                        </div>
                    </div>
                    <div className={classes.center}>
                        {menuImage?.url ? (
                            <Img
                                className={clsx(classes.logo, classes.menuImage)}
                                {...menuImage}
                                onClick={onLogoClick}
                            />
                        ) : (
                            <SvgLogo
                                className={classes.logo}
                                onClick={onLogoClick}
                            />
                        )}
                    </div>
                    <div className={classes.right}>
                        <div className={classes.icons}>
                            {/* <div className={clsx(classes.icon, classes.iconFirst)}><SvgSearch className={classes.searchIcon} onClick={onSearchClick} /></div> */}
                            <div className={clsx(classes.icon, classes.subscriptionIcon)}>
                                <SubscriptionNavBarItem />
                            </div>
                            <div className={clsx(classes.icon, classes.shoppingCartIcon)}>
                                <ShoppingCartNavBarItem
                                    active={rootMode === NavBarRootModeEnum.Checkout}
                                />
                            </div>
                            {isLogged && hasMounted && (
                                <>
                                    <div
                                        className={clsx(
                                            classes.icon,
                                            rootMode === NavBarRootModeEnum.Library &&
                                                classes.iconActive,
                                        )}
                                    >
                                        <SvgLibrary
                                            onClick={
                                                rootMode === NavBarRootModeEnum.Library
                                                    ? undefined
                                                    : onLibraryClick
                                            }
                                        />
                                    </div>
                                    <div
                                        className={clsx(
                                            classes.icon,
                                            rootMode === NavBarRootModeEnum.Wishlist &&
                                                classes.iconActive,
                                        )}
                                    >
                                        <SvgWish
                                            onClick={
                                                rootMode === NavBarRootModeEnum.Wishlist
                                                    ? undefined
                                                    : onWishlistClick
                                            }
                                        />
                                    </div>
                                    <div
                                        className={clsx(
                                            classes.icon,
                                            rootMode === NavBarRootModeEnum.My &&
                                                classes.iconActive,
                                        )}
                                    >
                                        <SvgUser
                                            onClick={
                                                rootMode === NavBarRootModeEnum.My
                                                    ? undefined
                                                    : onUserClick
                                            }
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                        {(!isLogged || !hasMounted) && (
                            <div className={classes.buttons}>
                                <LoginButton />
                            </div>
                        )}
                    </div>
                </div>
            )}
            {(mode === 'search' || mode === 'extended') && (
                <ExtendedNavMenu
                    onModeClose={onModeClose}
                    menu={filteredMenu}
                    section={section}
                    mode={mode}
                    onUserClick={onUserClick}
                    onLibraryClick={onLibraryClick}
                    onWishlistClick={onWishlistClick}
                    rootMode={rootMode}
                    currentPageUid={page?.uid}
                />
            )}
        </>
    );
}

export interface TabletNavBarProps {
    mode?: any;
    menu?: any;
    menuImage?: any;
    section?: any;
    onLogoClick?: any;
    onUserClick?: any;
    onWishlistClick?: any;
    onExtendedClick?: any;
    onSearchClick?: any;
    onLibraryClick?: any;
    onModeClose?: any;
    page?: any;
    rootMode?: NavBarRootModeEnum;
}

export default TabletNavBar;
