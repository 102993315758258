export default {
    "default": {
        "images": {
            "login_image": {
                "dimensions": {
                    "width": 1310,
                    "height": 1544
                },
                "alt": null,
                "copyright": null,
                "url": "https://images.prismic.io/lunii/4d829e97-c4cc-4a57-8572-c2d77d5d8e17_30+-+Connexion+-+Desktop+-+Visuel.png?auto=compress,format",
                "id": "YEtCbhAAACQAMb4b",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "transparent"
                },
                "tablet": {
                    "dimensions": {
                        "width": 960,
                        "height": 1131
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/4d829e97-c4cc-4a57-8572-c2d77d5d8e17_30+-+Connexion+-+Desktop+-+Visuel.png?auto=compress,format&rect=0,0,1310,1543&w=960&h=1131",
                    "id": "YEtCbhAAACQAMb4b",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 0.732824427480916,
                        "background": "transparent"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 328,
                        "height": 387
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/4d829e97-c4cc-4a57-8572-c2d77d5d8e17_30+-+Connexion+-+Desktop+-+Visuel.png?auto=compress,format&rect=0,0,1309,1544&w=328&h=387",
                    "id": "YEtCbhAAACQAMb4b",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 0.2506476683937824,
                        "background": "transparent"
                    }
                }
            },
            "login_logo": {
                "dimensions": {
                    "width": 206,
                    "height": 274
                },
                "alt": null,
                "copyright": null,
                "url": "https://images.prismic.io/lunii/93c0399d-5869-46ed-a4b9-2fd0b470ff2c_Capture+d%E2%80%99e%CC%81cran+2020-09-14+a%CC%80+10.43.44.png?auto=compress,format",
                "id": "X1-ArxMAACEAx4wa",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "transparent"
                },
                "tablet": {
                    "dimensions": {
                        "width": 960,
                        "height": 1277
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/93c0399d-5869-46ed-a4b9-2fd0b470ff2c_Capture+d%E2%80%99e%CC%81cran+2020-09-14+a%CC%80+10.43.44.png?auto=compress,format&rect=0,0,206,274&w=960&h=1277",
                    "id": "X1-ArxMAACEAx4wa",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 4.660583941605839,
                        "background": "transparent"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 328,
                        "height": 436
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/93c0399d-5869-46ed-a4b9-2fd0b470ff2c_Capture+d%E2%80%99e%CC%81cran+2020-09-14+a%CC%80+10.43.44.png?auto=compress,format&rect=0,0,206,274&w=328&h=436",
                    "id": "X1-ArxMAACEAx4wa",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 1.5922330097087378,
                        "background": "transparent"
                    }
                }
            },
            "forgot_password_image": {
                "dimensions": {
                    "width": 897,
                    "height": 633
                },
                "alt": null,
                "copyright": null,
                "url": "https://images.prismic.io/lunii/b71a3b08-16a7-461d-9f2b-d2ae4a529156_12+-+A%CC%80+propos+-+Tablette+-+Innovation%402x.png?auto=compress,format",
                "id": "X3jpoBAAACMAONT3",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "transparent"
                },
                "tablet": {
                    "dimensions": {
                        "width": 960,
                        "height": 677
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/b71a3b08-16a7-461d-9f2b-d2ae4a529156_12+-+A%CC%80+propos+-+Tablette+-+Innovation%402x.png?auto=compress,format&rect=0,0,897,633&w=960&h=677",
                    "id": "X3jpoBAAACMAONT3",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 1.0702341137123745,
                        "background": "transparent"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 328,
                        "height": 231
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/b71a3b08-16a7-461d-9f2b-d2ae4a529156_12+-+A%CC%80+propos+-+Tablette+-+Innovation%402x.png?auto=compress,format&rect=0,0,897,632&w=328&h=231",
                    "id": "X3jpoBAAACMAONT3",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 0.3656633221850613,
                        "background": "transparent"
                    }
                }
            },
            "newsletter_banner": {
                "dimensions": {
                    "width": 1141,
                    "height": 129
                },
                "alt": null,
                "copyright": null,
                "url": "https://images.prismic.io/lunii/d8766c87-328a-4067-abe8-6fb66950fca5_01+-+Homepage+-+Desktop+-+Newsletter+copie.png?auto=compress,format",
                "id": "YEs-4xAAACUAMa-b",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "transparent"
                },
                "tablet": {
                    "dimensions": {
                        "width": 960,
                        "height": 109
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/d8766c87-328a-4067-abe8-6fb66950fca5_01+-+Homepage+-+Desktop+-+Newsletter+copie.png?auto=compress,format&rect=2,0,1136,129&w=960&h=109",
                    "id": "YEs-4xAAACUAMa-b",
                    "edit": {
                        "x": -2,
                        "y": 0,
                        "zoom": 0.8449612403100775,
                        "background": "transparent"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 328,
                        "height": 64
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/bac17761-4744-4d30-bf58-245749016d45_01+-+Homepage+-+Mobile+-+Newsletter.png?auto=compress,format&rect=0,0,657,128&w=328&h=64",
                    "id": "YEs_gxAAACYAMbJe",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 0.4992389649923896,
                        "background": "transparent"
                    }
                }
            },
            "checkout_confirmation_image": {
                "dimensions": {
                    "width": 728,
                    "height": 728
                },
                "alt": null,
                "copyright": null,
                "url": "https://images.prismic.io/lunii/a039632b-d4a9-4927-9146-2838bc16dba6_24+-+Panier+-+Desktop+-+Fah.png?auto=compress,format",
                "id": "YEs_nRAAACcAMbLN",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "transparent"
                },
                "tablet": {
                    "dimensions": {
                        "width": 960,
                        "height": 960
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/a039632b-d4a9-4927-9146-2838bc16dba6_24+-+Panier+-+Desktop+-+Fah.png?auto=compress,format&rect=0,0,728,728&w=960&h=960",
                    "id": "YEs_nRAAACcAMbLN",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 1.3186813186813187,
                        "background": "transparent"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 328,
                        "height": 184
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/746089f7-0cb8-469c-b0d8-6bd70716f7e0_24+-+Panier+-+Mobile+-+Fah.png?auto=compress,format&rect=0,0,720,404&w=328&h=184",
                    "id": "YEs_rhAAACYAMbMe",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 0.45555555555555555,
                        "background": "transparent"
                    }
                }
            },
            "checkout_empty_cart": {
                "dimensions": {
                    "width": 1182,
                    "height": 512
                },
                "alt": null,
                "copyright": null,
                "url": "https://images.prismic.io/lunii/059fa78c-42d2-4bb8-82ff-ac562e5eaed2_24+-+Panier+-+Desktop+-Vide.png?auto=compress,format",
                "id": "YEs_uxAAACQAMbNX",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "transparent"
                },
                "tablet": {
                    "dimensions": {
                        "width": 960,
                        "height": 416
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/059fa78c-42d2-4bb8-82ff-ac562e5eaed2_24+-+Panier+-+Desktop+-Vide.png?auto=compress,format&rect=0,0,1182,512&w=960&h=416",
                    "id": "YEs_uxAAACQAMbNX",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 0.8125,
                        "background": "transparent"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 328,
                        "height": 248
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/250eb3c3-60c3-4af3-b1e4-5bca83190d92_24+-+Panier+-+Mobile+-+Vide.png?auto=compress,format&rect=0,0,592,448&w=328&h=248",
                    "id": "YEs_yRAAACYAMbOW",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 0.5540540540540541,
                        "background": "transparent"
                    }
                }
            },
            "library_empty": {
                "dimensions": {
                    "width": 364,
                    "height": 364
                },
                "alt": null,
                "copyright": null,
                "url": "https://images.prismic.io/lunii/b45b1006-301c-45a1-a31d-e954907311c3_Bibiliothe%CC%80que+-+Desktop.png?auto=compress,format",
                "id": "YKZnkRMAACAARMJf",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "transparent"
                },
                "tablet": {
                    "dimensions": {
                        "width": 960,
                        "height": 960
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/b45b1006-301c-45a1-a31d-e954907311c3_Bibiliothe%CC%80que+-+Desktop.png?auto=compress,format&rect=0,0,364,364&w=960&h=960",
                    "id": "YKZnkRMAACAARMJf",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 2.6373626373626373,
                        "background": "transparent"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 328,
                        "height": 328
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/b45b1006-301c-45a1-a31d-e954907311c3_Bibiliothe%CC%80que+-+Desktop.png?auto=compress,format&rect=0,0,364,364&w=328&h=328",
                    "id": "YKZnkRMAACAARMJf",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 0.9010989010989011,
                        "background": "transparent"
                    }
                }
            },
            "library_discover": {
                "dimensions": {
                    "width": 1310,
                    "height": 1118
                },
                "alt": null,
                "copyright": null,
                "url": "https://images.prismic.io/lunii/911c917d-ed07-4055-9963-a3d8eb741415_Bibliothe%CC%80que+-+De%CC%81couvrir+-+Desktop+-.png?auto=compress,format",
                "id": "YLXg1hEAACIAvYCb",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "transparent"
                },
                "tablet": {
                    "dimensions": {
                        "width": 960,
                        "height": 819
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/911c917d-ed07-4055-9963-a3d8eb741415_Bibliothe%CC%80que+-+De%CC%81couvrir+-+Desktop+-.png?auto=compress,format&rect=0,0,1310,1118&w=960&h=819",
                    "id": "YLXg1hEAACIAvYCb",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 0.732824427480916,
                        "background": "transparent"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 328,
                        "height": 280
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/911c917d-ed07-4055-9963-a3d8eb741415_Bibliothe%CC%80que+-+De%CC%81couvrir+-+Desktop+-.png?auto=compress,format&rect=0,0,1310,1118&w=328&h=280",
                    "id": "YLXg1hEAACIAvYCb",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 0.2504472271914132,
                        "background": "transparent"
                    }
                }
            },
            "library_studio": {
                "dimensions": {
                    "width": 1310,
                    "height": 1118
                },
                "alt": null,
                "copyright": null,
                "url": "https://images.prismic.io/lunii/999c60f2-e98e-416e-8506-c538d62b3dc6_Bibliothe%CC%80que+-+Studio+-+Desktop.png?auto=compress,format",
                "id": "YLXhExEAACAAvYHB",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "transparent"
                },
                "tablet": {
                    "dimensions": {
                        "width": 960,
                        "height": 819
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/999c60f2-e98e-416e-8506-c538d62b3dc6_Bibliothe%CC%80que+-+Studio+-+Desktop.png?auto=compress,format&rect=0,0,1310,1118&w=960&h=819",
                    "id": "YLXhExEAACAAvYHB",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 0.732824427480916,
                        "background": "transparent"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 328,
                        "height": 280
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/999c60f2-e98e-416e-8506-c538d62b3dc6_Bibliothe%CC%80que+-+Studio+-+Desktop.png?auto=compress,format&rect=0,0,1310,1118&w=328&h=280",
                    "id": "YLXhExEAACAAvYHB",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 0.2504472271914132,
                        "background": "transparent"
                    }
                }
            },
            "my_subscription_credits_remaining": {
                "dimensions": {
                    "width": 106,
                    "height": 83
                },
                "alt": null,
                "copyright": null,
                "url": "https://lunii.cdn.prismic.io/lunii/4c66a222-74de-4e8b-af98-aaa992cc17ac_my_subscription_credits_remaining.svg",
                "id": "YRpmjBAAACIASXkA",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "#fff"
                },
                "tablet": {
                    "dimensions": {
                        "width": 960,
                        "height": 752
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/4c66a222-74de-4e8b-af98-aaa992cc17ac_my_subscription_credits_remaining.svg",
                    "id": "YRpmjBAAACIASXkA",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 9.060240963855422,
                        "background": "#fff"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 328,
                        "height": 257
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/4c66a222-74de-4e8b-af98-aaa992cc17ac_my_subscription_credits_remaining.svg",
                    "id": "YRpmjBAAACIASXkA",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 3.0963855421686746,
                        "background": "#fff"
                    }
                }
            },
            "my_subscription_sub_canceled": {
                "dimensions": {
                    "width": 107,
                    "height": 81
                },
                "alt": null,
                "copyright": null,
                "url": "https://lunii.cdn.prismic.io/lunii/4d23e90a-03b3-4863-8afb-8a8fe3bb2097_my_subscription_sub_canceled.svg",
                "id": "YRpmjBAAACIASXj_",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "#fff"
                },
                "tablet": {
                    "dimensions": {
                        "width": 960,
                        "height": 727
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/4d23e90a-03b3-4863-8afb-8a8fe3bb2097_my_subscription_sub_canceled.svg",
                    "id": "YRpmjBAAACIASXj_",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 8.975308641975309,
                        "background": "#fff"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 328,
                        "height": 248
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/4d23e90a-03b3-4863-8afb-8a8fe3bb2097_my_subscription_sub_canceled.svg",
                    "id": "YRpmjBAAACIASXj_",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 3.0654205607476634,
                        "background": "#fff"
                    }
                }
            },
            "my_subscription_sub_type": {
                "dimensions": {
                    "width": 129,
                    "height": 88
                },
                "alt": null,
                "copyright": null,
                "url": "https://lunii.cdn.prismic.io/lunii/c1d4c181-43fd-49ae-bd6c-c4e31c6aa16b_my_subscription_sub_type.svg",
                "id": "YRpmjRAAACIASXkB",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "#fff"
                },
                "tablet": {
                    "dimensions": {
                        "width": 960,
                        "height": 655
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/c1d4c181-43fd-49ae-bd6c-c4e31c6aa16b_my_subscription_sub_type.svg",
                    "id": "YRpmjRAAACIASXkB",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 7.443181818181818,
                        "background": "#fff"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 328,
                        "height": 224
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/c1d4c181-43fd-49ae-bd6c-c4e31c6aa16b_my_subscription_sub_type.svg",
                    "id": "YRpmjRAAACIASXkB",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 2.5454545454545454,
                        "background": "#fff"
                    }
                }
            },
            "my_subscription_credits_valid_until": {
                "dimensions": {
                    "width": 98,
                    "height": 87
                },
                "alt": null,
                "copyright": null,
                "url": "https://lunii.cdn.prismic.io/lunii/7b6536ef-d503-478f-904e-27706c4aa34d_my_subscription_credits_valid_until.svg",
                "id": "YRpmjRAAACIASXkC",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "#fff"
                },
                "tablet": {
                    "dimensions": {
                        "width": 960,
                        "height": 852
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/7b6536ef-d503-478f-904e-27706c4aa34d_my_subscription_credits_valid_until.svg",
                    "id": "YRpmjRAAACIASXkC",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 9.795918367346939,
                        "background": "#fff"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 328,
                        "height": 291
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/7b6536ef-d503-478f-904e-27706c4aa34d_my_subscription_credits_valid_until.svg",
                    "id": "YRpmjRAAACIASXkC",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 3.3469387755102042,
                        "background": "#fff"
                    }
                }
            },
            "my_subscription_sub_trial": {
                "dimensions": {
                    "width": 104,
                    "height": 85
                },
                "alt": null,
                "copyright": null,
                "url": "https://lunii.cdn.prismic.io/lunii/90832776-65e2-45f6-8ccc-fe7dfedb16cd_my_subscription_sub_planned.svg",
                "id": "YRpmjBAAAEMmSXj-",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "#fff"
                },
                "tablet": {
                    "dimensions": {
                        "width": 960,
                        "height": 785
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/90832776-65e2-45f6-8ccc-fe7dfedb16cd_my_subscription_sub_planned.svg",
                    "id": "YRpmjBAAAEMmSXj-",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 9.235294117647058,
                        "background": "#fff"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 328,
                        "height": 268
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/90832776-65e2-45f6-8ccc-fe7dfedb16cd_my_subscription_sub_planned.svg",
                    "id": "YRpmjBAAAEMmSXj-",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 3.1538461538461537,
                        "background": "#fff"
                    }
                }
            },
            "subscription_advert": {
                "dimensions": {
                    "width": 318,
                    "height": 152
                },
                "alt": null,
                "copyright": null,
                "url": "https://images.prismic.io/lunii/d28e665b-4de4-4caf-8583-e342761c729b_Subscription_advert.png?auto=compress,format",
                "id": "YXGGEREAACEA_xU9",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "transparent"
                },
                "tablet": {
                    "dimensions": {
                        "width": 960,
                        "height": 459
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/d28e665b-4de4-4caf-8583-e342761c729b_Subscription_advert.png?auto=compress,format&rect=0,0,318,152&w=960&h=459",
                    "id": "YXGGEREAACEA_xU9",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 3.0197368421052633,
                        "background": "transparent"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 328,
                        "height": 157
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/d28e665b-4de4-4caf-8583-e342761c729b_Subscription_advert.png?auto=compress,format&rect=0,0,318,152&w=328&h=157",
                    "id": "YXGGEREAACEA_xU9",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 1.0328947368421053,
                        "background": "transparent"
                    }
                }
            },
            "subscription_success": {
                "dimensions": {
                    "width": 267,
                    "height": 191
                },
                "alt": null,
                "copyright": null,
                "url": "https://lunii.cdn.prismic.io/lunii/abef018e-f124-4ff1-8bf1-11770d40bad4_subscription_success.svg",
                "id": "YYp8eBAAACMAVn9n",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "#fff"
                },
                "tablet": {
                    "dimensions": {
                        "width": 960,
                        "height": 687
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/abef018e-f124-4ff1-8bf1-11770d40bad4_subscription_success.svg",
                    "id": "YYp8eBAAACMAVn9n",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 3.5968586387434556,
                        "background": "#fff"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 328,
                        "height": 235
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/abef018e-f124-4ff1-8bf1-11770d40bad4_subscription_success.svg",
                    "id": "YYp8eBAAACMAVn9n",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 1.2303664921465969,
                        "background": "#fff"
                    }
                }
            },
            "illustration_letter": {
                "dimensions": {
                    "width": 48,
                    "height": 50
                },
                "alt": null,
                "copyright": null,
                "url": "https://lunii.cdn.prismic.io/lunii/22d49762-713c-4a53-bdfc-b3d68fb580af_illustration-letter.svg",
                "id": "YZ5gbxEAACcAeyZ_",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "#fff"
                },
                "tablet": {
                    "dimensions": {
                        "width": 960,
                        "height": 1000
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/22d49762-713c-4a53-bdfc-b3d68fb580af_illustration-letter.svg",
                    "id": "YZ5gbxEAACcAeyZ_",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 20,
                        "background": "#fff"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 328,
                        "height": 342
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/22d49762-713c-4a53-bdfc-b3d68fb580af_illustration-letter.svg",
                    "id": "YZ5gbxEAACcAeyZ_",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 6.84,
                        "background": "#fff"
                    }
                }
            },
            "illustration_gift": {
                "dimensions": {
                    "width": 54,
                    "height": 42
                },
                "alt": null,
                "copyright": null,
                "url": "https://lunii.cdn.prismic.io/lunii/0b365df1-8017-4ef2-b56a-a479e65a0d8d_illustration_gift.svg",
                "id": "YZ5gohEAACUAeydy",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "#fff"
                },
                "tablet": {
                    "dimensions": {
                        "width": 960,
                        "height": 747
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/0b365df1-8017-4ef2-b56a-a479e65a0d8d_illustration_gift.svg",
                    "id": "YZ5gohEAACUAeydy",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 17.785714285714285,
                        "background": "#fff"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 328,
                        "height": 255
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/0b365df1-8017-4ef2-b56a-a479e65a0d8d_illustration_gift.svg",
                    "id": "YZ5gohEAACUAeydy",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 6.074074074074074,
                        "background": "#fff"
                    }
                }
            },
            "my_fahs_empty": {
                "dimensions": {
                    "width": 312,
                    "height": 271
                },
                "alt": null,
                "copyright": null,
                "url": "https://images.prismic.io/lunii/c459680d-c883-48fd-ae46-aff6f959a24a_Illustration+%282%29.png?auto=compress,format",
                "id": "ZNSYcxEAAGcw2Tqf",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "transparent"
                },
                "tablet": {
                    "dimensions": {
                        "width": 960,
                        "height": 834
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/c459680d-c883-48fd-ae46-aff6f959a24a_Illustration+%282%29.png?auto=compress,format&rect=0,0,312,271&w=960&h=834",
                    "id": "ZNSYcxEAAGcw2Tqf",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 3.077490774907749,
                        "background": "transparent"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 328,
                        "height": 285
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/c459680d-c883-48fd-ae46-aff6f959a24a_Illustration+%282%29.png?auto=compress,format&rect=0,0,312,271&w=328&h=285",
                    "id": "ZNSYcxEAAGcw2Tqf",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 1.051660516605166,
                        "background": "transparent"
                    }
                }
            },
            "wishlist_empty": {
                "dimensions": {
                    "width": 558,
                    "height": 313
                },
                "alt": null,
                "copyright": null,
                "url": "https://lunii.cdn.prismic.io/lunii/c4e2b04e-8bb9-4143-be28-6ef0018d7757_wishlist-empty.svg",
                "id": "YmKyqBAAACMAS4WW",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "#fff"
                },
                "tablet": {
                    "dimensions": {
                        "width": 960,
                        "height": 538
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/c4e2b04e-8bb9-4143-be28-6ef0018d7757_wishlist-empty.svg",
                    "id": "YmKyqBAAACMAS4WW",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 1.7204301075268817,
                        "background": "#fff"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 328,
                        "height": 184
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/c4e2b04e-8bb9-4143-be28-6ef0018d7757_wishlist-empty.svg",
                    "id": "YmKyqBAAACMAS4WW",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 0.5878594249201278,
                        "background": "#fff"
                    }
                }
            },
            "merge_account_warning_modal_top_image": {
                "dimensions": {
                    "width": 456,
                    "height": 200
                },
                "alt": null,
                "copyright": null,
                "url": "https://images.prismic.io/lunii/64da0e32-2e72-428f-b8ad-207c00fb80ae_Desktop+-+Merge+account+modal.png?auto=compress,format",
                "id": "YqcHvxEAACMA6pJK",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "transparent"
                },
                "tablet": {
                    "dimensions": {
                        "width": 960,
                        "height": 421
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/64da0e32-2e72-428f-b8ad-207c00fb80ae_Desktop+-+Merge+account+modal.png?auto=compress,format&rect=0,0,456,200&w=960&h=421",
                    "id": "YqcHvxEAACMA6pJK",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 2.1052631578947367,
                        "background": "transparent"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 328,
                        "height": 144
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/64da0e32-2e72-428f-b8ad-207c00fb80ae_Desktop+-+Merge+account+modal.png?auto=compress,format&rect=0,0,456,200&w=328&h=144",
                    "id": "YqcHvxEAACMA6pJK",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 0.72,
                        "background": "transparent"
                    }
                }
            },
            "calendar": {
                "dimensions": {
                    "width": 139,
                    "height": 96
                },
                "alt": null,
                "copyright": null,
                "url": "https://lunii.cdn.prismic.io/lunii/34951fd8-0c48-4ee5-8b00-ee1df7ef3804_calendar.svg",
                "id": "Yt--7BEAACYAGQqf",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "#fff"
                },
                "tablet": {
                    "dimensions": {
                        "width": 960,
                        "height": 663
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/34951fd8-0c48-4ee5-8b00-ee1df7ef3804_calendar.svg",
                    "id": "Yt--7BEAACYAGQqf",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 6.906474820143885,
                        "background": "#fff"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 328,
                        "height": 227
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/34951fd8-0c48-4ee5-8b00-ee1df7ef3804_calendar.svg",
                    "id": "Yt--7BEAACYAGQqf",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 2.3645833333333335,
                        "background": "#fff"
                    }
                }
            },
            "calendar_subscription": {
                "dimensions": {
                    "width": 289,
                    "height": 247
                },
                "alt": null,
                "copyright": null,
                "url": "https://lunii.cdn.prismic.io/lunii/6b75c23e-447f-4e44-bf62-a92b87ee6557_calendar_subscription.svg",
                "id": "Yt_inhEAACcAGa4C",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "#fff"
                },
                "tablet": {
                    "dimensions": {
                        "width": 960,
                        "height": 820
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/6b75c23e-447f-4e44-bf62-a92b87ee6557_calendar_subscription.svg",
                    "id": "Yt_inhEAACcAGa4C",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 3.3217993079584773,
                        "background": "#fff"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 328,
                        "height": 280
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/6b75c23e-447f-4e44-bf62-a92b87ee6557_calendar_subscription.svg",
                    "id": "Yt_inhEAACcAGa4C",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 1.1349480968858132,
                        "background": "#fff"
                    }
                }
            },
            "my_subscription_sub_renew_planned": {
                "dimensions": {
                    "width": 114,
                    "height": 88
                },
                "alt": null,
                "copyright": null,
                "url": "https://lunii.cdn.prismic.io/lunii/860447f5-6eb9-4998-9e8d-8eb6d55e9f7d_my_subscription_next_debit.svg",
                "id": "YuKfjxAAACAAiDVg",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "#fff"
                },
                "tablet": {
                    "dimensions": {
                        "width": 960,
                        "height": 741
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/860447f5-6eb9-4998-9e8d-8eb6d55e9f7d_my_subscription_next_debit.svg",
                    "id": "YuKfjxAAACAAiDVg",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 8.421052631578947,
                        "background": "#fff"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 328,
                        "height": 253
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/860447f5-6eb9-4998-9e8d-8eb6d55e9f7d_my_subscription_next_debit.svg",
                    "id": "YuKfjxAAACAAiDVg",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 2.8771929824561404,
                        "background": "#fff"
                    }
                }
            },
            "my_subscription_activate_form_modal_body_one": {
                "dimensions": {
                    "width": 64,
                    "height": 47
                },
                "alt": null,
                "copyright": null,
                "url": "https://lunii.cdn.prismic.io/lunii/c9bbb8a8-0259-4016-bde3-53524b8e1ab4_star-svg.svg",
                "id": "Y0gUCREAAB-e5G9Q",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "#fff"
                },
                "tablet": {
                    "dimensions": {
                        "width": 960,
                        "height": 705
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/c9bbb8a8-0259-4016-bde3-53524b8e1ab4_star-svg.svg",
                    "id": "Y0gUCREAAB-e5G9Q",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 15,
                        "background": "#fff"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 328,
                        "height": 241
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/c9bbb8a8-0259-4016-bde3-53524b8e1ab4_star-svg.svg",
                    "id": "Y0gUCREAAB-e5G9Q",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 5.127659574468085,
                        "background": "#fff"
                    }
                }
            },
            "my_subscription_activate_form_modal_body_two": {
                "dimensions": {
                    "width": 64,
                    "height": 47
                },
                "alt": null,
                "copyright": null,
                "url": "https://lunii.cdn.prismic.io/lunii/efa5af92-c5bd-40b3-8b9a-25b9826f2f07_letter-computer-svg.svg",
                "id": "Y0gUCBEAAImM5G9P",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "#fff"
                },
                "tablet": {
                    "dimensions": {
                        "width": 960,
                        "height": 705
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/efa5af92-c5bd-40b3-8b9a-25b9826f2f07_letter-computer-svg.svg",
                    "id": "Y0gUCBEAAImM5G9P",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 15,
                        "background": "#fff"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 328,
                        "height": 241
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/efa5af92-c5bd-40b3-8b9a-25b9826f2f07_letter-computer-svg.svg",
                    "id": "Y0gUCBEAAImM5G9P",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 5.127659574468085,
                        "background": "#fff"
                    }
                }
            },
            "my_subscription_activate_success_modal": {
                "dimensions": {
                    "width": 779,
                    "height": 465
                },
                "alt": null,
                "copyright": null,
                "url": "https://images.prismic.io/lunii/78971e27-3825-4880-9a27-886fc44f26a1_subscription_success.png?auto=compress,format",
                "id": "Y00eMBEAAImM-tiv",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "transparent"
                },
                "tablet": {
                    "dimensions": {
                        "width": 960,
                        "height": 573
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/78971e27-3825-4880-9a27-886fc44f26a1_subscription_success.png?auto=compress,format&rect=0,0,779,465&w=960&h=573",
                    "id": "Y00eMBEAAImM-tiv",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 1.2323491655969192,
                        "background": "transparent"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 328,
                        "height": 196
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/78971e27-3825-4880-9a27-886fc44f26a1_subscription_success.png?auto=compress,format&rect=0,0,778,465&w=328&h=196",
                    "id": "Y00eMBEAAImM-tiv",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 0.421505376344086,
                        "background": "transparent"
                    }
                }
            },
            "subscription_gift": {
                "dimensions": {
                    "width": 111,
                    "height": 86
                },
                "alt": null,
                "copyright": null,
                "url": "https://lunii.cdn.prismic.io/lunii/013033a3-90bb-4dba-880d-6ca01cd667be_gift_box.svg",
                "id": "Y1AJBBEAAImMCCWW",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "#fff"
                },
                "tablet": {
                    "dimensions": {
                        "width": 960,
                        "height": 744
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/013033a3-90bb-4dba-880d-6ca01cd667be_gift_box.svg",
                    "id": "Y1AJBBEAAImMCCWW",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 8.651162790697674,
                        "background": "#fff"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 328,
                        "height": 254
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/013033a3-90bb-4dba-880d-6ca01cd667be_gift_box.svg",
                    "id": "Y1AJBBEAAImMCCWW",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 2.954954954954955,
                        "background": "#fff"
                    }
                }
            },
            "email": {
                "dimensions": {
                    "width": 81,
                    "height": 59
                },
                "alt": null,
                "copyright": null,
                "url": "https://lunii.cdn.prismic.io/lunii/b7cb4efd-d8dc-4ce2-b664-0d5a97dae6a1_email.svg",
                "id": "Y1AJJBEAADtSCCX_",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "#fff"
                },
                "tablet": {
                    "dimensions": {
                        "width": 960,
                        "height": 699
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/b7cb4efd-d8dc-4ce2-b664-0d5a97dae6a1_email.svg",
                    "id": "Y1AJJBEAADtSCCX_",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 11.851851851851851,
                        "background": "#fff"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 328,
                        "height": 239
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/b7cb4efd-d8dc-4ce2-b664-0d5a97dae6a1_email.svg",
                    "id": "Y1AJJBEAADtSCCX_",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 4.0508474576271185,
                        "background": "#fff"
                    }
                }
            },
            "gift_subscriptioncoupon": {
                "dimensions": {
                    "width": 81,
                    "height": 59
                },
                "alt": null,
                "copyright": null,
                "url": "https://lunii.cdn.prismic.io/lunii/8a9b75b7-0f17-4cd3-9d4f-6c47d6ef6355_paper_subscriptioncoupon.svg",
                "id": "Y1AJNREAAImMCCYj",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "#fff"
                },
                "tablet": {
                    "dimensions": {
                        "width": 960,
                        "height": 699
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/8a9b75b7-0f17-4cd3-9d4f-6c47d6ef6355_paper_subscriptioncoupon.svg",
                    "id": "Y1AJNREAAImMCCYj",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 11.851851851851851,
                        "background": "#fff"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 328,
                        "height": 239
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/8a9b75b7-0f17-4cd3-9d4f-6c47d6ef6355_paper_subscriptioncoupon.svg",
                    "id": "Y1AJNREAAImMCCYj",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 4.0508474576271185,
                        "background": "#fff"
                    }
                }
            },
            "my_subscription_sub_termination": {
                "dimensions": {
                    "width": 107,
                    "height": 81
                },
                "alt": null,
                "copyright": null,
                "url": "https://lunii.cdn.prismic.io/lunii/ab30cb6f-8a69-4c6c-a72b-6c483a69f8dc_my_subscription_sub_termination.svg",
                "id": "Y1afsxEAAKLNJXI4",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "#fff"
                },
                "tablet": {
                    "dimensions": {
                        "width": 960,
                        "height": 727
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/ab30cb6f-8a69-4c6c-a72b-6c483a69f8dc_my_subscription_sub_termination.svg",
                    "id": "Y1afsxEAAKLNJXI4",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 8.975308641975309,
                        "background": "#fff"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 328,
                        "height": 248
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/ab30cb6f-8a69-4c6c-a72b-6c483a69f8dc_my_subscription_sub_termination.svg",
                    "id": "Y1afsxEAAKLNJXI4",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 3.0654205607476634,
                        "background": "#fff"
                    }
                }
            },
            "my_subscription_sub_trialing": {
                "dimensions": {
                    "width": 104,
                    "height": 85
                },
                "alt": null,
                "copyright": null,
                "url": "https://lunii.cdn.prismic.io/lunii/593f4146-4745-4d47-9899-61265988d8b3_my_subscription_sub_trialing.svg",
                "id": "Y1agKxEAAKLNJXRY",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "#fff"
                },
                "tablet": {
                    "dimensions": {
                        "width": 960,
                        "height": 785
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/593f4146-4745-4d47-9899-61265988d8b3_my_subscription_sub_trialing.svg",
                    "id": "Y1agKxEAAKLNJXRY",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 9.235294117647058,
                        "background": "#fff"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 328,
                        "height": 268
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/593f4146-4745-4d47-9899-61265988d8b3_my_subscription_sub_trialing.svg",
                    "id": "Y1agKxEAAKLNJXRY",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 3.1538461538461537,
                        "background": "#fff"
                    }
                }
            },
            "my_subscription_sub_active": {
                "dimensions": {
                    "width": 114,
                    "height": 88
                },
                "alt": null,
                "copyright": null,
                "url": "https://lunii.cdn.prismic.io/lunii/0e1e24ac-4eb3-49c1-b2cd-47108d91e73d_my_subscription_sub_active.svg",
                "id": "Y1agNBEAAKLNJXSO",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "#fff"
                },
                "tablet": {
                    "dimensions": {
                        "width": 960,
                        "height": 741
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/0e1e24ac-4eb3-49c1-b2cd-47108d91e73d_my_subscription_sub_active.svg",
                    "id": "Y1agNBEAAKLNJXSO",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 8.421052631578947,
                        "background": "#fff"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 328,
                        "height": 253
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/0e1e24ac-4eb3-49c1-b2cd-47108d91e73d_my_subscription_sub_active.svg",
                    "id": "Y1agNBEAAKLNJXSO",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 2.8771929824561404,
                        "background": "#fff"
                    }
                }
            },
            "my_subscription_sub_withdrawal": {
                "dimensions": {
                    "width": 114,
                    "height": 88
                },
                "alt": null,
                "copyright": null,
                "url": "https://lunii.cdn.prismic.io/lunii/0e1e24ac-4eb3-49c1-b2cd-47108d91e73d_my_subscription_sub_active.svg",
                "id": "Y1agNBEAAKLNJXSO",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "#fff"
                },
                "tablet": {
                    "dimensions": {
                        "width": 960,
                        "height": 741
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/0e1e24ac-4eb3-49c1-b2cd-47108d91e73d_my_subscription_sub_active.svg",
                    "id": "Y1agNBEAAKLNJXSO",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 8.421052631578947,
                        "background": "#fff"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 328,
                        "height": 253
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/0e1e24ac-4eb3-49c1-b2cd-47108d91e73d_my_subscription_sub_active.svg",
                    "id": "Y1agNBEAAKLNJXSO",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 2.8771929824561404,
                        "background": "#fff"
                    }
                }
            },
            "my_subscription_sub_type_withdrawal": {
                "dimensions": {
                    "width": 129,
                    "height": 88
                },
                "alt": null,
                "copyright": null,
                "url": "https://lunii.cdn.prismic.io/lunii/07b7e903-a8d1-4886-bf28-f4825572925b_my_subscription_sub_type.svg",
                "id": "Y1ajdhEAAA1CJYQv",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "#fff"
                },
                "tablet": {
                    "dimensions": {
                        "width": 960,
                        "height": 655
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/07b7e903-a8d1-4886-bf28-f4825572925b_my_subscription_sub_type.svg",
                    "id": "Y1ajdhEAAA1CJYQv",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 7.443181818181818,
                        "background": "#fff"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 328,
                        "height": 224
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/07b7e903-a8d1-4886-bf28-f4825572925b_my_subscription_sub_type.svg",
                    "id": "Y1ajdhEAAA1CJYQv",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 2.5454545454545454,
                        "background": "#fff"
                    }
                }
            },
            "my_subscription_sub_type_active": {
                "dimensions": {
                    "width": 129,
                    "height": 88
                },
                "alt": null,
                "copyright": null,
                "url": "https://lunii.cdn.prismic.io/lunii/07b7e903-a8d1-4886-bf28-f4825572925b_my_subscription_sub_type.svg",
                "id": "Y1ajdhEAAA1CJYQv",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "#fff"
                },
                "tablet": {
                    "dimensions": {
                        "width": 960,
                        "height": 655
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/07b7e903-a8d1-4886-bf28-f4825572925b_my_subscription_sub_type.svg",
                    "id": "Y1ajdhEAAA1CJYQv",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 7.443181818181818,
                        "background": "#fff"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 328,
                        "height": 224
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/07b7e903-a8d1-4886-bf28-f4825572925b_my_subscription_sub_type.svg",
                    "id": "Y1ajdhEAAA1CJYQv",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 2.5454545454545454,
                        "background": "#fff"
                    }
                }
            },
            "my_subscription_sub_type_free": {
                "dimensions": {
                    "width": 107,
                    "height": 81
                },
                "alt": null,
                "copyright": null,
                "url": "https://lunii.cdn.prismic.io/lunii/ab30cb6f-8a69-4c6c-a72b-6c483a69f8dc_my_subscription_sub_termination.svg",
                "id": "Y1afsxEAAKLNJXI4",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "#fff"
                },
                "tablet": {
                    "dimensions": {
                        "width": 960,
                        "height": 727
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/ab30cb6f-8a69-4c6c-a72b-6c483a69f8dc_my_subscription_sub_termination.svg",
                    "id": "Y1afsxEAAKLNJXI4",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 8.975308641975309,
                        "background": "#fff"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 328,
                        "height": 248
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/ab30cb6f-8a69-4c6c-a72b-6c483a69f8dc_my_subscription_sub_termination.svg",
                    "id": "Y1afsxEAAKLNJXI4",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 3.0654205607476634,
                        "background": "#fff"
                    }
                }
            },
            "my_subscription_sub_type_termination": {
                "dimensions": {
                    "width": 98,
                    "height": 87
                },
                "alt": null,
                "copyright": null,
                "url": "https://lunii.cdn.prismic.io/lunii/f85e9c8c-33a0-4c06-b196-e370e7e348af_my_subscription_credits_valid_until.svg",
                "id": "Y1ajghEAAEolJYRi",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "#fff"
                },
                "tablet": {
                    "dimensions": {
                        "width": 960,
                        "height": 852
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/f85e9c8c-33a0-4c06-b196-e370e7e348af_my_subscription_credits_valid_until.svg",
                    "id": "Y1ajghEAAEolJYRi",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 9.795918367346939,
                        "background": "#fff"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 328,
                        "height": 291
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/f85e9c8c-33a0-4c06-b196-e370e7e348af_my_subscription_credits_valid_until.svg",
                    "id": "Y1ajghEAAEolJYRi",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 3.3469387755102042,
                        "background": "#fff"
                    }
                }
            },
            "my_subscription_sub_type_trialing": {
                "dimensions": {
                    "width": 129,
                    "height": 88
                },
                "alt": null,
                "copyright": null,
                "url": "https://lunii.cdn.prismic.io/lunii/07b7e903-a8d1-4886-bf28-f4825572925b_my_subscription_sub_type.svg",
                "id": "Y1ajdhEAAA1CJYQv",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "#fff"
                },
                "tablet": {
                    "dimensions": {
                        "width": 960,
                        "height": 655
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/07b7e903-a8d1-4886-bf28-f4825572925b_my_subscription_sub_type.svg",
                    "id": "Y1ajdhEAAA1CJYQv",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 7.443181818181818,
                        "background": "#fff"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 328,
                        "height": 224
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/07b7e903-a8d1-4886-bf28-f4825572925b_my_subscription_sub_type.svg",
                    "id": "Y1ajdhEAAA1CJYQv",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 2.5454545454545454,
                        "background": "#fff"
                    }
                }
            },
            "my_subscription_sub_free": {
                "dimensions": {
                    "width": 112,
                    "height": 73
                },
                "alt": null,
                "copyright": null,
                "url": "https://lunii.cdn.prismic.io/lunii/7849c4f2-5048-4879-bc04-f84bf753e9e6_my_subscription_sub_free.svg",
                "id": "Y1am2hEAAA1CJZTv",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "#fff"
                },
                "tablet": {
                    "dimensions": {
                        "width": 960,
                        "height": 626
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/7849c4f2-5048-4879-bc04-f84bf753e9e6_my_subscription_sub_free.svg",
                    "id": "Y1am2hEAAA1CJZTv",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 8.575342465753424,
                        "background": "#fff"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 328,
                        "height": 214
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/7849c4f2-5048-4879-bc04-f84bf753e9e6_my_subscription_sub_free.svg",
                    "id": "Y1am2hEAAA1CJZTv",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 2.9315068493150687,
                        "background": "#fff"
                    }
                }
            },
            "my_subscription_tiles_free_subscription_warning": {
                "dimensions": {
                    "width": 105,
                    "height": 60
                },
                "alt": null,
                "copyright": null,
                "url": "https://lunii.cdn.prismic.io/lunii/d5f2b00c-135c-4791-af30-1b8bf33857ff_my_subscription_tiles_free_subscription_warning.svg",
                "id": "Y1u93hEAAA1CPM89",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "#fff"
                },
                "tablet": {
                    "dimensions": {
                        "width": 960,
                        "height": 549
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/d5f2b00c-135c-4791-af30-1b8bf33857ff_my_subscription_tiles_free_subscription_warning.svg",
                    "id": "Y1u93hEAAA1CPM89",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 9.15,
                        "background": "#fff"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 328,
                        "height": 187
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/d5f2b00c-135c-4791-af30-1b8bf33857ff_my_subscription_tiles_free_subscription_warning.svg",
                    "id": "Y1u93hEAAA1CPM89",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 3.123809523809524,
                        "background": "#fff"
                    }
                }
            },
            "my_subscription_sub_past_due": {
                "dimensions": {
                    "width": 114,
                    "height": 88
                },
                "alt": null,
                "copyright": null,
                "url": "https://lunii.cdn.prismic.io/lunii/860447f5-6eb9-4998-9e8d-8eb6d55e9f7d_my_subscription_next_debit.svg",
                "id": "YuKfjxAAACAAiDVg",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "#fff"
                },
                "tablet": {
                    "dimensions": {
                        "width": 960,
                        "height": 741
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/860447f5-6eb9-4998-9e8d-8eb6d55e9f7d_my_subscription_next_debit.svg",
                    "id": "YuKfjxAAACAAiDVg",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 8.421052631578947,
                        "background": "#fff"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 328,
                        "height": 253
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/860447f5-6eb9-4998-9e8d-8eb6d55e9f7d_my_subscription_next_debit.svg",
                    "id": "YuKfjxAAACAAiDVg",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 2.8771929824561404,
                        "background": "#fff"
                    }
                }
            },
            "my_subscription_sub_type_past_due": {
                "dimensions": {
                    "width": 112,
                    "height": 73
                },
                "alt": null,
                "copyright": null,
                "url": "https://lunii.cdn.prismic.io/lunii/7849c4f2-5048-4879-bc04-f84bf753e9e6_my_subscription_sub_free.svg",
                "id": "Y1am2hEAAA1CJZTv",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "#fff"
                },
                "tablet": {
                    "dimensions": {
                        "width": 960,
                        "height": 626
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/7849c4f2-5048-4879-bc04-f84bf753e9e6_my_subscription_sub_free.svg",
                    "id": "Y1am2hEAAA1CJZTv",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 8.575342465753424,
                        "background": "#fff"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 328,
                        "height": 214
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/7849c4f2-5048-4879-bc04-f84bf753e9e6_my_subscription_sub_free.svg",
                    "id": "Y1am2hEAAA1CJZTv",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 2.9315068493150687,
                        "background": "#fff"
                    }
                }
            },
            "my_subscription_sub_unpaid": {
                "dimensions": {
                    "width": 114,
                    "height": 88
                },
                "alt": null,
                "copyright": null,
                "url": "https://lunii.cdn.prismic.io/lunii/0e1e24ac-4eb3-49c1-b2cd-47108d91e73d_my_subscription_sub_active.svg",
                "id": "Y1agNBEAAKLNJXSO",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "#fff"
                },
                "tablet": {
                    "dimensions": {
                        "width": 960,
                        "height": 741
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/0e1e24ac-4eb3-49c1-b2cd-47108d91e73d_my_subscription_sub_active.svg",
                    "id": "Y1agNBEAAKLNJXSO",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 8.421052631578947,
                        "background": "#fff"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 328,
                        "height": 253
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/0e1e24ac-4eb3-49c1-b2cd-47108d91e73d_my_subscription_sub_active.svg",
                    "id": "Y1agNBEAAKLNJXSO",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 2.8771929824561404,
                        "background": "#fff"
                    }
                }
            },
            "my_subscription_sub_type_unpaid": {
                "dimensions": {
                    "width": 129,
                    "height": 88
                },
                "alt": null,
                "copyright": null,
                "url": "https://lunii.cdn.prismic.io/lunii/07b7e903-a8d1-4886-bf28-f4825572925b_my_subscription_sub_type.svg",
                "id": "Y1ajdhEAAA1CJYQv",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "#fff"
                },
                "tablet": {
                    "dimensions": {
                        "width": 960,
                        "height": 655
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/07b7e903-a8d1-4886-bf28-f4825572925b_my_subscription_sub_type.svg",
                    "id": "Y1ajdhEAAA1CJYQv",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 7.443181818181818,
                        "background": "#fff"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 328,
                        "height": 224
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://lunii.cdn.prismic.io/lunii/07b7e903-a8d1-4886-bf28-f4825572925b_my_subscription_sub_type.svg",
                    "id": "Y1ajdhEAAA1CJYQv",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 2.5454545454545454,
                        "background": "#fff"
                    }
                }
            },
            "library_empty_studio": {
                "dimensions": {
                    "width": 1000,
                    "height": 420
                },
                "alt": null,
                "copyright": null,
                "url": "https://images.prismic.io/lunii/8f3547b1-ccd0-4dbd-aec5-87dc4275a885_Illustration+%281%29.png?auto=compress,format",
                "id": "ZNH4xRAAAKcPM1bG",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "transparent"
                },
                "tablet": {
                    "dimensions": {
                        "width": 960,
                        "height": 403
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/8f3547b1-ccd0-4dbd-aec5-87dc4275a885_Illustration+%281%29.png?auto=compress,format&rect=0,0,1000,420&w=960&h=403",
                    "id": "ZNH4xRAAAKcPM1bG",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 0.96,
                        "background": "transparent"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 328,
                        "height": 138
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/8f3547b1-ccd0-4dbd-aec5-87dc4275a885_Illustration+%281%29.png?auto=compress,format&rect=0,0,998,420&w=328&h=138",
                    "id": "ZNH4xRAAAKcPM1bG",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 0.32857142857142857,
                        "background": "transparent"
                    }
                }
            },
            "library_empty_v2": {
                "dimensions": {
                    "width": 882,
                    "height": 394
                },
                "alt": null,
                "copyright": null,
                "url": "https://images.prismic.io/lunii/de85d9a8-5775-42ff-833d-85a0c60b4f36_library_empty.png?auto=compress,format",
                "id": "ZPyGQhEAACgAyYX0",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "transparent"
                },
                "tablet": {
                    "dimensions": {
                        "width": 960,
                        "height": 429
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/de85d9a8-5775-42ff-833d-85a0c60b4f36_library_empty.png?auto=compress,format&rect=0,0,882,394&w=960&h=429",
                    "id": "ZPyGQhEAACgAyYX0",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 1.0888324873096447,
                        "background": "transparent"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 328,
                        "height": 146
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/b43b39a8-edb1-4580-b9e4-a8ee9d43959d_Illustration+%285%29.png?auto=compress,format&rect=0,0,359,160&w=328&h=146",
                    "id": "ZPyFdREAACgAyYJo",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 0.9125,
                        "background": "transparent"
                    }
                }
            },
            "my_devices_empty": {
                "dimensions": {
                    "width": 312,
                    "height": 270
                },
                "alt": null,
                "copyright": null,
                "url": "https://images.prismic.io/lunii/5909649a-8cfa-44c6-ab8e-564b9339f1ae_my_devices.png?auto=compress,format",
                "id": "ZOTAtBEAACUAHA4y",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "transparent"
                },
                "tablet": {
                    "dimensions": {
                        "width": 960,
                        "height": 831
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/5909649a-8cfa-44c6-ab8e-564b9339f1ae_my_devices.png?auto=compress,format&rect=0,0,312,270&w=960&h=831",
                    "id": "ZOTAtBEAACUAHA4y",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 3.077777777777778,
                        "background": "transparent"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 328,
                        "height": 284
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/5909649a-8cfa-44c6-ab8e-564b9339f1ae_my_devices.png?auto=compress,format&rect=0,0,312,270&w=328&h=284",
                    "id": "ZOTAtBEAACUAHA4y",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 1.0518518518518518,
                        "background": "transparent"
                    }
                }
            },
            "subscription_credit_calendar": {
                "dimensions": {
                    "width": 810,
                    "height": 448
                },
                "alt": null,
                "copyright": null,
                "url": "https://images.prismic.io/lunii/ccfb03a9-4856-47ff-a1bf-81c9406fc95d_subscription-top-page.png?auto=compress,format",
                "id": "ZOilkBAAACgAEDIZ",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "transparent"
                },
                "tablet": {
                    "dimensions": {
                        "width": 960,
                        "height": 531
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/ccfb03a9-4856-47ff-a1bf-81c9406fc95d_subscription-top-page.png?auto=compress,format&rect=0,0,810,448&w=960&h=531",
                    "id": "ZOilkBAAACgAEDIZ",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 1.1852678571428572,
                        "background": "transparent"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 328,
                        "height": 181
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/ccfb03a9-4856-47ff-a1bf-81c9406fc95d_subscription-top-page.png?auto=compress,format&rect=0,0,810,447&w=328&h=181",
                    "id": "ZOilkBAAACgAEDIZ",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 0.4049382716049383,
                        "background": "transparent"
                    }
                }
            },
            "multi_product_fah_picture": {
                "dimensions": {
                    "width": 320,
                    "height": 200
                },
                "alt": null,
                "copyright": null,
                "url": "https://images.prismic.io/lunii/3640a7f8-9727-4da1-9f58-088835146695_multi_product_fah_picture.png?auto=compress,format",
                "id": "ZPiI-BEAACQAt8zh",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "transparent"
                },
                "tablet": {
                    "dimensions": {
                        "width": 1280,
                        "height": 800
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/3640a7f8-9727-4da1-9f58-088835146695_multi_product_fah_picture.png?auto=compress,format&rect=0,0,320,200&w=1280&h=800",
                    "id": "ZPiI-BEAACQAt8zh",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 4,
                        "background": "transparent"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 960,
                        "height": 600
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/3640a7f8-9727-4da1-9f58-088835146695_multi_product_fah_picture.png?auto=compress,format&rect=0,0,320,200&w=960&h=600",
                    "id": "ZPiI-BEAACQAt8zh",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 3,
                        "background": "transparent"
                    }
                }
            },
            "multi_product_flam_picture": {
                "dimensions": {
                    "width": 320,
                    "height": 200
                },
                "alt": null,
                "copyright": null,
                "url": "https://images.prismic.io/lunii/be04e927-0a0c-40b2-adcb-3db4eff1c2f3_multi_product_flam_picture.png?auto=compress,format",
                "id": "ZPiI-BEAACgAt8zg",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "transparent"
                },
                "tablet": {
                    "dimensions": {
                        "width": 1280,
                        "height": 800
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/be04e927-0a0c-40b2-adcb-3db4eff1c2f3_multi_product_flam_picture.png?auto=compress,format&rect=0,0,320,200&w=1280&h=800",
                    "id": "ZPiI-BEAACgAt8zg",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 4,
                        "background": "transparent"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 960,
                        "height": 600
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/be04e927-0a0c-40b2-adcb-3db4eff1c2f3_multi_product_flam_picture.png?auto=compress,format&rect=0,0,320,200&w=960&h=600",
                    "id": "ZPiI-BEAACgAt8zg",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 3,
                        "background": "transparent"
                    }
                }
            },
            "my_empty_fah_audiobooks_illustration": {
                "dimensions": {
                    "width": 900,
                    "height": 320
                },
                "alt": null,
                "copyright": null,
                "url": "https://images.prismic.io/lunii/e9fade22-ed16-49e8-9de6-f47e67b9966d_my_empty_fah_audiobooks_illustration_desktop.png?auto=compress,format",
                "id": "ZP8EThEAACYA1ISS",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "transparent"
                },
                "tablet": {
                    "dimensions": {
                        "width": 1280,
                        "height": 455
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/e9fade22-ed16-49e8-9de6-f47e67b9966d_my_empty_fah_audiobooks_illustration_desktop.png?auto=compress,format&rect=0,0,900,320&w=1280&h=455",
                    "id": "ZP8EThEAACYA1ISS",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 1.4222222222222223,
                        "background": "transparent"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 960,
                        "height": 427
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/def7f0f5-db95-4690-8a5b-b16bbe924394_my_empty_fah_audiobooks_illustration_mobile.png?auto=compress,format&rect=0,0,719,320&w=960&h=427",
                    "id": "ZP8EVBEAACQA1ISt",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 1.334375,
                        "background": "transparent"
                    }
                }
            },
            "my_empty_fah_studio_illustration": {
                "dimensions": {
                    "width": 900,
                    "height": 320
                },
                "alt": null,
                "copyright": null,
                "url": "https://images.prismic.io/lunii/ddeb82c4-5b26-40de-a020-265386b2663e_my_empty_fah_studio_illustration_desktop.png?auto=compress,format",
                "id": "ZP8EbBEAACQA1IUY",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "transparent"
                },
                "tablet": {
                    "dimensions": {
                        "width": 1280,
                        "height": 455
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/ddeb82c4-5b26-40de-a020-265386b2663e_my_empty_fah_studio_illustration_desktop.png?auto=compress,format&rect=0,0,900,320&w=1280&h=455",
                    "id": "ZP8EbBEAACQA1IUY",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 1.4222222222222223,
                        "background": "transparent"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 960,
                        "height": 427
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/de0350d4-b761-4d14-b14c-b8eef1ca7e45_my_empty_fah_studio_illustration_mobile.png?auto=compress,format&rect=0,0,719,320&w=960&h=427",
                    "id": "ZP8EdBEAACgA1IU7",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 1.334375,
                        "background": "transparent"
                    }
                }
            },
            "my_empty_flam_audiobooks_illustration": {
                "dimensions": {
                    "width": 900,
                    "height": 320
                },
                "alt": null,
                "copyright": null,
                "url": "https://images.prismic.io/lunii/7d750216-afe9-4e1b-b664-8aed3a0048ae_my_empty_flam_audiobooks_illustration_desktop.png?auto=compress,format",
                "id": "ZP8EqhEAAPhC1IYu",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "transparent"
                },
                "tablet": {
                    "dimensions": {
                        "width": 1280,
                        "height": 455
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/7d750216-afe9-4e1b-b664-8aed3a0048ae_my_empty_flam_audiobooks_illustration_desktop.png?auto=compress,format&rect=0,0,900,320&w=1280&h=455",
                    "id": "ZP8EqhEAAPhC1IYu",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 1.4222222222222223,
                        "background": "transparent"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 960,
                        "height": 427
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/05806336-91f6-4002-a798-41d524599787_my_empty_flam_audiobooks_illustration_mobile.png?auto=compress,format&rect=0,0,719,320&w=960&h=427",
                    "id": "ZP8EtxEAACQA1IZn",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 1.334375,
                        "background": "transparent"
                    }
                }
            },
            "my_empty_flam_studio_illustration": {
                "dimensions": {
                    "width": 900,
                    "height": 320
                },
                "alt": null,
                "copyright": null,
                "url": "https://images.prismic.io/lunii/69161289-c02a-4932-beb9-d35e37adcc85_my_empty_flam_studio_illustration_desktop.png?auto=compress,format",
                "id": "ZP8E4REAAPhC1Icz",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "transparent"
                },
                "tablet": {
                    "dimensions": {
                        "width": 1280,
                        "height": 455
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/69161289-c02a-4932-beb9-d35e37adcc85_my_empty_flam_studio_illustration_desktop.png?auto=compress,format&rect=0,0,900,320&w=1280&h=455",
                    "id": "ZP8E4REAAPhC1Icz",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 1.4222222222222223,
                        "background": "transparent"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 960,
                        "height": 427
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/7c4f69f6-2c1e-4685-a8cf-a0b3f7fe0d0e_my_empty_flam_studio_illustration_mobile.png?auto=compress,format&rect=0,0,719,320&w=960&h=427",
                    "id": "ZP8E6REAACQA1IdY",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 1.334375,
                        "background": "transparent"
                    }
                }
            },
            "my_empty_fah_wishlist_illustration": {
                "dimensions": {
                    "width": 900,
                    "height": 320
                },
                "alt": null,
                "copyright": null,
                "url": "https://images.prismic.io/lunii/c05a842f-aed7-483c-94b2-7d8299d3aa06_my_empty_fah_wishlist_illustration_desktop.png?auto=compress,format",
                "id": "ZQG18hEAACgA4Ew0",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "transparent"
                },
                "tablet": {
                    "dimensions": {
                        "width": 1280,
                        "height": 455
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/c05a842f-aed7-483c-94b2-7d8299d3aa06_my_empty_fah_wishlist_illustration_desktop.png?auto=compress,format&rect=0,0,900,320&w=1280&h=455",
                    "id": "ZQG18hEAACgA4Ew0",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 1.4222222222222223,
                        "background": "transparent"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 960,
                        "height": 468
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/69f8e5f7-986a-404a-a0c8-4476b6616e9b_my_empty_fah_wishlist_illustration_mobile.png?auto=compress,format&rect=0,0,656,320&w=960&h=468",
                    "id": "ZQG2BBEAACYA4ExQ",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 1.4634146341463414,
                        "background": "transparent"
                    }
                }
            },
            "my_empty_flam_wishlist_illustration": {
                "dimensions": {
                    "width": 900,
                    "height": 320
                },
                "alt": null,
                "copyright": null,
                "url": "https://images.prismic.io/lunii/41c5a532-d0f5-4d8a-9373-eca23beaaa13_my_empty_flam_wishlist_illustration_desktop.png?auto=compress,format",
                "id": "ZQG2DxEAACgA4EyN",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "transparent"
                },
                "tablet": {
                    "dimensions": {
                        "width": 1280,
                        "height": 455
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/41c5a532-d0f5-4d8a-9373-eca23beaaa13_my_empty_flam_wishlist_illustration_desktop.png?auto=compress,format&rect=0,0,900,320&w=1280&h=455",
                    "id": "ZQG2DxEAACgA4EyN",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 1.4222222222222223,
                        "background": "transparent"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 960,
                        "height": 468
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/3265b8f0-8535-4689-be7d-9094e25951b6_my_empty_flam_wishlist_illustration_mobile.png?auto=compress,format&rect=0,0,656,320&w=960&h=468",
                    "id": "ZQG2GBEAACgA4Eyd",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 1.4634146341463414,
                        "background": "transparent"
                    }
                }
            },
            "checkout_empty_cart_v2": {
                "dimensions": {
                    "width": 592,
                    "height": 316
                },
                "alt": null,
                "copyright": null,
                "url": "https://images.prismic.io/lunii/a07f5db8-eae5-47b8-a3e2-edc423cf4e1e_basket-empty.png?auto=compress,format",
                "id": "ZRwg3RIAACIABp-1",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "transparent"
                },
                "tablet": {
                    "dimensions": {
                        "width": 1280,
                        "height": 683
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/a07f5db8-eae5-47b8-a3e2-edc423cf4e1e_basket-empty.png?auto=compress,format&rect=0,0,592,316&w=1280&h=683",
                    "id": "ZRwg3RIAACIABp-1",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 2.1621621621621623,
                        "background": "transparent"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 960,
                        "height": 512
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/a07f5db8-eae5-47b8-a3e2-edc423cf4e1e_basket-empty.png?auto=compress,format&rect=0,0,592,316&w=960&h=512",
                    "id": "ZRwg3RIAACIABp-1",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 1.6216216216216217,
                        "background": "transparent"
                    }
                }
            },
            "checkout_confirmation_image_v2": {
                "dimensions": {
                    "width": 728,
                    "height": 728
                },
                "alt": null,
                "copyright": null,
                "url": "https://images.prismic.io/lunii/0d3570d0-3509-4701-b95b-c79f7c508e55_checkout_confirmation_image_v2_desktop.png?auto=compress,format",
                "id": "ZQm3WhIAACQAOd-e",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "transparent"
                },
                "tablet": {
                    "dimensions": {
                        "width": 1280,
                        "height": 1280
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/0d3570d0-3509-4701-b95b-c79f7c508e55_checkout_confirmation_image_v2_desktop.png?auto=compress,format&rect=0,0,728,728&w=1280&h=1280",
                    "id": "ZQm3WhIAACQAOd-e",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 1.7582417582417582,
                        "background": "transparent"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 960,
                        "height": 539
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/cd8ee611-885e-4b58-be5b-781f2f8341c7_checkout_confirmation_image_v2_mobile.png?auto=compress,format&rect=0,0,720,404&w=960&h=539",
                    "id": "ZQm3bBIAACEAOd-6",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 1.3341584158415842,
                        "background": "transparent"
                    }
                }
            },
            "cart_no_fah_on_account": {
                "dimensions": {
                    "width": 456,
                    "height": 225
                },
                "alt": null,
                "copyright": null,
                "url": "https://images.prismic.io/lunii/caaf37e4-9fc7-44a0-98fc-a8a6ae9e8129_cart_no_fah_on_account.png?auto=compress,format",
                "id": "ZQqoBxIAACMAPE3B",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "transparent"
                },
                "tablet": {
                    "dimensions": {
                        "width": 1280,
                        "height": 632
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/caaf37e4-9fc7-44a0-98fc-a8a6ae9e8129_cart_no_fah_on_account.png?auto=compress,format&rect=0,0,456,225&w=1280&h=632",
                    "id": "ZQqoBxIAACMAPE3B",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 2.8088888888888888,
                        "background": "transparent"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 960,
                        "height": 474
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/caaf37e4-9fc7-44a0-98fc-a8a6ae9e8129_cart_no_fah_on_account.png?auto=compress,format&rect=0,0,456,225&w=960&h=474",
                    "id": "ZQqoBxIAACMAPE3B",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 2.1066666666666665,
                        "background": "transparent"
                    }
                }
            },
            "cart_no_flam_on_account": {
                "dimensions": {
                    "width": 456,
                    "height": 225
                },
                "alt": null,
                "copyright": null,
                "url": "https://images.prismic.io/lunii/42ae835e-be96-4611-9881-e9f4bab0aac3_cart_no_flam_on_account.png?auto=compress,format",
                "id": "ZQqoDxIAACIAPE3k",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "transparent"
                },
                "tablet": {
                    "dimensions": {
                        "width": 1280,
                        "height": 632
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/42ae835e-be96-4611-9881-e9f4bab0aac3_cart_no_flam_on_account.png?auto=compress,format&rect=0,0,456,225&w=1280&h=632",
                    "id": "ZQqoDxIAACIAPE3k",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 2.8088888888888888,
                        "background": "transparent"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 960,
                        "height": 474
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/42ae835e-be96-4611-9881-e9f4bab0aac3_cart_no_flam_on_account.png?auto=compress,format&rect=0,0,456,225&w=960&h=474",
                    "id": "ZQqoDxIAACIAPE3k",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 2.1066666666666665,
                        "background": "transparent"
                    }
                }
            },
            "cart_no_fah_and_flam_on_account": {
                "dimensions": {
                    "width": 456,
                    "height": 225
                },
                "alt": null,
                "copyright": null,
                "url": "https://images.prismic.io/lunii/73c75437-0642-45a9-8d68-69a8ba39e47d_cart_no_fah_and_flam_on_account.png?auto=compress,format",
                "id": "ZQqoFBIAACMAPE4A",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "transparent"
                },
                "tablet": {
                    "dimensions": {
                        "width": 1280,
                        "height": 632
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/73c75437-0642-45a9-8d68-69a8ba39e47d_cart_no_fah_and_flam_on_account.png?auto=compress,format&rect=0,0,456,225&w=1280&h=632",
                    "id": "ZQqoFBIAACMAPE4A",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 2.8088888888888888,
                        "background": "transparent"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 960,
                        "height": 474
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/73c75437-0642-45a9-8d68-69a8ba39e47d_cart_no_fah_and_flam_on_account.png?auto=compress,format&rect=0,0,456,225&w=960&h=474",
                    "id": "ZQqoFBIAACMAPE4A",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 2.1066666666666665,
                        "background": "transparent"
                    }
                }
            },
            "audiobooks_subscription_fah": {
                "dimensions": {
                    "width": 728,
                    "height": 150
                },
                "alt": null,
                "copyright": null,
                "url": "https://images.prismic.io/lunii/968b6261-1ae3-40e3-8a4a-f905fbf41e39_audiobooks_subscription_fah.png?auto=compress,format",
                "id": "ZRvPoRIAAJNSBTOh",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "transparent"
                },
                "tablet": {
                    "dimensions": {
                        "width": 1280,
                        "height": 264
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/968b6261-1ae3-40e3-8a4a-f905fbf41e39_audiobooks_subscription_fah.png?auto=compress,format&rect=1,0,727,150&w=1280&h=264",
                    "id": "ZRvPoRIAAJNSBTOh",
                    "edit": {
                        "x": -1,
                        "y": 0,
                        "zoom": 1.76,
                        "background": "transparent"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 960,
                        "height": 198
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/968b6261-1ae3-40e3-8a4a-f905fbf41e39_audiobooks_subscription_fah.png?auto=compress,format&rect=0,0,727,150&w=960&h=198",
                    "id": "ZRvPoRIAAJNSBTOh",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 1.32,
                        "background": "transparent"
                    }
                }
            },
            "audiobooks_subscription_flam": {
                "dimensions": {
                    "width": 728,
                    "height": 150
                },
                "alt": null,
                "copyright": null,
                "url": "https://images.prismic.io/lunii/077eca88-38e6-4af3-839d-5599673df818_audiobook_subscription_flam_v2.png?auto=compress,format",
                "id": "ZSVTihAAACEAjqyM",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "transparent"
                },
                "tablet": {
                    "dimensions": {
                        "width": 1280,
                        "height": 264
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/077eca88-38e6-4af3-839d-5599673df818_audiobook_subscription_flam_v2.png?auto=compress,format&rect=1,0,727,150&w=1280&h=264",
                    "id": "ZSVTihAAACEAjqyM",
                    "edit": {
                        "x": -1,
                        "y": 0,
                        "zoom": 1.76,
                        "background": "transparent"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 960,
                        "height": 198
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/077eca88-38e6-4af3-839d-5599673df818_audiobook_subscription_flam_v2.png?auto=compress,format&rect=0,0,727,150&w=960&h=198",
                    "id": "ZSVTihAAACEAjqyM",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 1.32,
                        "background": "transparent"
                    }
                }
            },
            "notfound_page_illustration": {
                "dimensions": {
                    "width": 728,
                    "height": 410
                },
                "alt": null,
                "copyright": null,
                "url": "https://images.prismic.io/lunii/34977a9c-1cb6-4c66-98e7-eb9035b37513_page404-illustration.png?auto=compress,format",
                "id": "ZS1f6BIAACcAL0Ii",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "transparent"
                },
                "tablet": {
                    "dimensions": {
                        "width": 1280,
                        "height": 721
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/34977a9c-1cb6-4c66-98e7-eb9035b37513_page404-illustration.png?auto=compress,format&rect=0,0,728,410&w=1280&h=721",
                    "id": "ZS1f6BIAACcAL0Ii",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 1.7585365853658537,
                        "background": "transparent"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 960,
                        "height": 541
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/34977a9c-1cb6-4c66-98e7-eb9035b37513_page404-illustration.png?auto=compress,format&rect=0,0,728,410&w=960&h=541",
                    "id": "ZS1f6BIAACcAL0Ii",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 1.3195121951219513,
                        "background": "transparent"
                    }
                }
            },
            "cart_discount": {
                "dimensions": {
                    "width": 96,
                    "height": 128
                },
                "alt": null,
                "copyright": null,
                "url": "https://images.prismic.io/lunii/21abdf46-aef6-49d0-bdda-d896b720c7f1_illustration.png?auto=compress,format",
                "id": "ZS52pRIAACgANBOv",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "transparent"
                },
                "tablet": {
                    "dimensions": {
                        "width": 1280,
                        "height": 1707
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/21abdf46-aef6-49d0-bdda-d896b720c7f1_illustration.png?auto=compress,format&rect=0,0,96,128&w=1280&h=1707",
                    "id": "ZS52pRIAACgANBOv",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 13.3359375,
                        "background": "transparent"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 960,
                        "height": 1280
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/21abdf46-aef6-49d0-bdda-d896b720c7f1_illustration.png?auto=compress,format&rect=0,0,96,128&w=960&h=1280",
                    "id": "ZS52pRIAACgANBOv",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 10,
                        "background": "transparent"
                    }
                }
            }
        }
    },
    "noel": {
        "images": {
            "login_image": {
                "dimensions": {
                    "width": 897,
                    "height": 633
                },
                "alt": null,
                "copyright": null,
                "url": "https://images.prismic.io/lunii/358d7947-be72-4b1f-a3d1-38b53681e9fc_12+-+A%CC%80+propos+-+Tablette+-+Inclusion%402x.png?auto=compress,format",
                "id": "X3jpoxAAACMAONUE",
                "edit": {
                    "x": 0,
                    "y": 0,
                    "zoom": 1,
                    "background": "transparent"
                },
                "tablet": {
                    "dimensions": {
                        "width": 960,
                        "height": 677
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/358d7947-be72-4b1f-a3d1-38b53681e9fc_12+-+A%CC%80+propos+-+Tablette+-+Inclusion%402x.png?auto=compress,format&rect=0,0,897,633&w=960&h=677",
                    "id": "X3jpoxAAACMAONUE",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 1.0702341137123745,
                        "background": "transparent"
                    }
                },
                "mobile": {
                    "dimensions": {
                        "width": 328,
                        "height": 231
                    },
                    "alt": null,
                    "copyright": null,
                    "url": "https://images.prismic.io/lunii/358d7947-be72-4b1f-a3d1-38b53681e9fc_12+-+A%CC%80+propos+-+Tablette+-+Inclusion%402x.png?auto=compress,format&rect=0,0,897,632&w=328&h=231",
                    "id": "X3jpoxAAACMAONUE",
                    "edit": {
                        "x": 0,
                        "y": 0,
                        "zoom": 0.3656633221850613,
                        "background": "transparent"
                    }
                }
            }
        }
    }
}
