import {useCallback, useContext} from 'react';
import {ProductTableNameEnum, tracking_context_value} from '../types';
import LuniiTrackingContext from '../contexts/LuniiTrackingContext';

function formatProductItemLevelExtras (types: any[], key: string): string {
    return types
        .filter(type => !!type)
        .filter(type => type.includes(key))
        .map(entry => entry.split('-').slice(1).join(' '))
        .join('-');
}

function formatPrice (price: number|undefined) {
    if (price === undefined) return undefined;
    if (price === null) return undefined;
    return isNaN(price) ? undefined : (price / 100);
}

type useLuniiEventTrackingCallback = (event: {
    categorie: string,
    action: string,
    libelle?: string,
    valeur?: number,
}, product?: any, pageModel?: string) => void

export function useLuniiEventTracking() : useLuniiEventTrackingCallback {
    const {dataLayer} = useContext<tracking_context_value>(LuniiTrackingContext);

    const ecommerceData = useCallback((product?) => {
        let data: any = undefined;
        if (product) {
            const {reference, name, price, priceExclTax, quantity, position, type, subtype, catalog, locale} = product;

            let produit_age: string | undefined;
            let produit_thematique: string | undefined;
            let produit_type_album: string | undefined;
            let produit_langue_etrangere = locale !== catalog ? locale : undefined;

            let produit_type = '';
            switch (type) {
                case ProductTableNameEnum.Packs:
                    produit_type = 'audio';
                    break;
                case ProductTableNameEnum.GiftCards:
                    produit_type = 'carte_cadeau';
                    break;
                case ProductTableNameEnum.Hardware:
                    produit_type = subtype === 'paperbook' ? 'papier' : 'materiel';
                    break;
                case 'subscription':
                    produit_type = 'abonnement';
                    break;
                case 'subscription_gift':
                    produit_type = 'abonnement_offert';
                default:
                    break;
            }

            if (product.__typename && (product.__typename === 'Lunii_DigitalAlbumProduct' || product.__typename === 'Lunii_HardwareProduct')) { // If the product is added from a product page
                const {types, themes, ageRecommendations} = product;
                produit_thematique = (themes || []).map((theme: any) => (theme?.name)).join('-').toLowerCase();
                produit_age = (ageRecommendations || []).map((age: any) => (age?.name)).join('-').toLowerCase();
                produit_type_album = types?.name?.toLowerCase();
            } else { // if the product is added using a listing
                const {types} = product;
                produit_age = types ? formatProductItemLevelExtras(types,'age-') : undefined;
                produit_thematique = types ? formatProductItemLevelExtras(types, 'theme-') : undefined;
                produit_type_album = types ? formatProductItemLevelExtras(types, 'type-') : undefined;
            }

            data = {
                produit_id: reference,
                produit_type: produit_type,
                produit_bundle: undefined, // TODO @bundles
                produit_nom: name,
                produit_prix_ht: formatPrice(priceExclTax),
                produit_prix_ttc: formatPrice(price),
                produit_thematique,
                produit_age,
                produit_type_album,
                produit_langue_etrangere,
                produit_quantite: quantity,
                produit_position: position,
            };
        }
        return data;
    }, []);

    return useCallback((event: {
        categorie: string,
        action: string,
        libelle?: string,
        valeur?: number,
    }, product?, pageModel?) => {

        dataLayer && dataLayer.push({
            event: 'ga_evenement',
            categorie: event.categorie,
            action: event.action,
            libelle: (
                event.libelle ?
                    `${event.libelle}` :
                    `${pageModel ? pageModel + ' - ' : ''}`
            ) + (
                product ?
                    `${event.libelle ? ' - ' : ''}${product.reference} - ${product.name}${product.bundle ? ' - ' + product.bundle : ''}` :
                    ''
            ),
            valeur: formatPrice(event.valeur),
            non_interaction: false,
            donnees_ecommerce: ecommerceData(product),
            eventCallback : function() {
                // @ts-ignore
                dataLayer && dataLayer.push({
                    donnees_ecommerce: undefined,
                    categorie: undefined,
                    action: undefined,
                    libelle: undefined,
                    valeur: undefined,
                });
            },
        });
    }, [dataLayer, ecommerceData]);
}

export default useLuniiEventTracking;
