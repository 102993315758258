import React from 'react';
import TextField, {TextFieldProps} from './TextField';

export function ZipCodeField(props: ZipCodeFieldProps) {
    return (
        <TextField name={'zipCode'} {...props} />
    );
}

export interface ZipCodeFieldProps extends TextFieldProps {}

export default ZipCodeField