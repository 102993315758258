export function getSupportedIcons() {
    return [
        'add',
        'arrow-down',
        'arrow-left',
        'arrow-right',
        'arrow-up',
        'back',
        'calendar',
        'catalog',
        'check',
        'close',
        'credit-card',
        'cut',
        'download',
        'edit',
        'export',
        'facebook',
        'fah',
        'file',
        'flag-be',
        'flag-de',
        'flag-en-gb',
        'flag-en-us',
        'flag-es',
        'flag-fr',
        'flag-it',
        'flag-nl',
        'flag-ru',
        'gift',
        'hide',
        'home',
        'instagram',
        'library',
        'like',
        'linkedin',
        'list',
        'manage',
        'microphone',
        'next',
        'package',
        'pause',
        'play',
        'promo',
        'recording',
        'redo',
        'refresh',
        'search',
        'share',
        'shopping-bag',
        'show',
        'soundwave',
        'stop',
        'trash',
        'twitter',
        'undo',
        'unlike',
        'user',
        'volume-high',
        'volume-low',
        'volume-medium',
        'volume-off',
        'wish',
        'youtube',
        'zoom-less',
        'zoom-more',
    ];
}

export default getSupportedIcons
