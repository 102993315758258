import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {cssText} from '@ohoareau/css-utils';
import clsx from 'clsx';
import {WithCreditCardItem} from '../types';
import SvgCreditCard from '../images/icons/CreditCard';
import {useLuniiTranslation, useLuniiPage} from '../hooks';
import {availableForSubscription} from '../../../configs/site';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flex: 1,
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'relative',
    },
    content: {
        display: 'flex',
        alignItems: 'center',
    },
    svgCreditCard: {
        marginRight: theme.spacing(2),
    },
    title: {
        ...cssText(theme, 'standard', 'body_thick'),
    },
    titleSpecs: {
        color: '#423F37',
    },
    text: {
        ...cssText(theme, 'standard', 'secondary_body'),
        color: props => props['expired'] ? '#C04020' : '#716C5E',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    separator: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    svg: {
        color: props => props['expired'] ? '#C04020' : props['onFormActive'] ? '#44320a' : 'inherit',
    },
    cardInfos: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    usedIn: {
        padding: theme.spacing(.5, 1),
        borderRadius: theme.spacing(1),
        ...cssText(theme, 'standard', 'secondary_body'),
        marginTop: theme.spacing(.5),
        flex: 0,
    },
    isDefault: {
        backgroundColor: '#ECF9FE',
        color: '#04384A',
    },
    usedInSubscription: {
        backgroundColor: '#E7FFF8',
        color: '#157B5C',
    },
}));

export function CreditCardMy({className = '', brand, expMonth, expYear, last4, name, expired = false, isDefault = false, usedInSubscription = false, hideCardSvg = false, onFormActive = false, hideCardTips = false, number}: CreditCardMyProps) {
    const classes = useStyles({expired, onFormActive});
    const {t} = useLuniiTranslation();
    const {...p} = useLuniiPage();
    const convertExpirationDate = (expMonth: number, expYear: number) => {
        const month = expMonth.toString().length === 1 ? `0${expMonth}` : `${expMonth}`;
        const year = expYear.toString().slice(2);
        return `${month}/${year}`;
    };
    return (
        <div className={clsx(classes.root, className)}>
            <div className={classes.content}>
                {!hideCardSvg && <SvgCreditCard className={clsx(classes.svg, classes.svgCreditCard)} width={24} height={24} color={'currentColor'}/>}
                <div className={classes.cardInfos}>
                    {<div className={clsx(classes.title, !expired && !onFormActive && classes.titleSpecs)}>{name || `${t('credit_card_default_name')}${number ? ` ${number}` : ''}`}</div>}
                    <div className={classes.text}>
                        <span>{`${brand} ****${last4}`}</span>
                        <span className={classes.separator}> | </span>
                        <span>{expired ? t('credit_card_expired') : `${t('credit_card_expire_date')} ${convertExpirationDate(expMonth, expYear)}`}</span>
                    </div>
                    {isDefault && !hideCardTips && <div className={clsx(classes.usedIn, classes.isDefault)}>{t('credit_card_default_card')}</div>}
                    {availableForSubscription(p?.locale) && usedInSubscription && !hideCardTips && <div className={clsx(classes.usedIn, classes.usedInSubscription)}>{t('credit_card_used_in_subscription')}</div>}
                </div>
            </div>
        </div>
    );
}

export interface CreditCardMyProps extends WithCreditCardItem {
    className?: string,
    expired?: boolean,
    hideCardSvg?: boolean,
    onFormActive?: boolean,
    number?: number,
    hideCardTips?: boolean,
}

export default CreditCardMy;
